<template>
	<div class="d-flex">
			<w-btn
				v-if="(value === null || (value && Object.values(value).every(x => !x))) && extension_selected"
				@click="dialog=true"
				text
				changes-form
				:color="trigger_btn_color"
				class="justify-start pl-0 my-auto"
			>
				<v-icon :color="trigger_btn_color">mdi-plus-circle</v-icon>  {{ l.t("call-flow.add-address", "Add address") }}
			</w-btn>
		<div v-if="format_address()" :style="{lineHeight: '14px'}" :class="{'my-auto' : !invalid_address}" class="w-body-2 mr-3 w-100">{{ format_address() }}</div>
		<AddressModal
			v-model="dialog"
			:_address="value"
			:confirm_btn_text="l.t('app.add-address', 'Add address')"
			@address_input="on_address_input"
			@validation_failed="$emit('validation_failed', $event)"
			:validation="validation"
			:key="extension_selected"
		>
			<template v-slot:title>
				{{ l.t('app.add-E911-address-for-this-device', 'Add E911 address for this device') }}
			</template>
		</AddressModal>
		<v-spacer v-if="!format_address()"></v-spacer>
		<div class="ma-auto">
			<MoreOptions :options="options"/>
		</div>
	</div>
</template>

<script>
	import l from '../../libs/lang';
	import Helpers from '../../libs/helpers';
	import MoreOptions from './MoreOptions.vue';
	import AddressModal from './modal/AddressModal.vue';

	export default {
		props: {
			value: {
				type: [Object, null],
			},
			validation: {
				type: Boolean,
				default: false,
			},
			trigger_btn_color: {
				type: String,
				default: 'primary',
			},
			invalid_address: {
				type: Boolean,
				default: false,
			},
			extension_selected: {
				type: Boolean,
				default: false,
			}
		},
		components: {
			MoreOptions,
			AddressModal,
		},
		data() {
			return {
				l,
				empty_address: {
                    line_1: '',
                    line_2: '',
                    city: '',
                    province: '',
                    postal_code: '',
                    country: 'US',
                },
				dialog: false,
			};
		},
		computed: {
			options() {
				let options = [];
				options.push({
					title: l.t('app.edit-e911-address', 'Edit E911 address'),
					icon: '$vuetify.icons.edit',
					cb: () => this.$data.dialog = true,
					});
				options.push({
					title: l.t('app.remove-device', 'Remove device'),
					icon: '$vuetify.icons.edit',
					cb: () => this.$emit('removed'),
					});
				if (!this.extension_selected) options = options.filter((x) => !x.title.includes('Edit E911 address'));
				return options;
			}
		},
		methods: {
			on_address_input(val) {
				this.$emit('input', val);
			},
			show_modal() {
				this.$data.dialog = true;
			},
			format_address() {
				if (!this.$props.value) return '';
				const address = [];
				for (const prop of Object.keys(this.$data.empty_address)) {
					if (this.$props.value[prop]) {
						if (prop === 'country') address.push(Helpers.country_name(this.$props.value[prop]));
						else address.push(this.$props.value[prop]);
					}
				}
				return address.length ? address.join(', ') : '';
			},
	},
};
</script>
