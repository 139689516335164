<template>
    <w-loader v-if="loading"/>
    <div v-else>
        <w-alert
            v-if="alert"
            :message="alert.message"
            :level="alert.level"
            class="mb-6"
        />
        <SectionTitle v-if="just_media_part" data-test-id="media-just-media-title">
          {{ l.t('media-loader.fill-the-form-to-update-all-selected-media', 'Fill the form to update all selected media') }}
        </SectionTitle>
        <w-form @submit="submitted" @changed="may_submit = true" data-discard="true">
            <template v-if="!just_media_part">
              <FormInput :label="l.t('app.name', 'Name')">
                <w-text-field v-model="media.name" hide-details='auto' :placeholder="l.t('media.new-greeting-or-music-name', 'Name your new greeting or music')" :rules="[$validation.required()]" data-test-id="media-name-input"/>
              </FormInput>
              <FormInput v-if="$route.name === 'media.create'" :label="l.t('media.type', 'Media type')">
                <w-radio-group
                  v-model="media.type"
                  @change="handleMediaTypeChange"
                  row
                  hide-details='auto'
                >
                  <v-radio
                    :label="l.t('app.greeting', 'Greeting')"
                    value="greeting"
                    data-test-id="media-greeting-radio"
                  ></v-radio>
                  <v-radio
                    :disabled="!!force_type"
                    :label="l.t('app.music-on-hold', 'Music on hold')"
                    value="hold_music"
                    data-test-id="media-hm-radio"
                  ></v-radio>
                </w-radio-group>
              </FormInput>
            </template>
            <FormInput v-if="$route.name === 'media.show' || ($route.name === 'media.create' && media.type && media.type ==='greeting')" :label="l.t('media.origin', 'Origin')">
              <w-radio-group
                v-model="media.origin"
                row
                hide-details='auto'
              >
                <v-radio
                  :label="l.t('media.tts', 'Text to Speech')"
                  value="tts"
                  data-test-id="media-tts-radio"
                ></v-radio>
                <v-radio
                 :label="l.t('app.upload-a-file', 'Upload a file')"
                  value="file"
                  data-test-id="media-file-radio"
                ></v-radio>
              </w-radio-group>
            </FormInput>
            <FormInput v-if="$route.name === 'media.music-on-hold-show' || ($route.name === 'media.create' && media.type && media.type ==='hold_music')" :label="l.t('media.origin', 'Origin')">
              <w-radio-group
                v-model="media.origin"
                row
                hide-details='auto'
              >
                <v-radio
                  :label="l.t('app.upload-a-file', 'Upload a file')"
                  value="file"
                  data-test-id="media-file-radio"
                ></v-radio>
              </w-radio-group>
            </FormInput>
            <template v-if="withPlayer && !just_media_part">
              <FormInput :label="l.t('media.existing-recording', 'Existing recording')">
                <Player
                    :url="`/media/${media['id']}/download`"
                    :playing="playing"
                    @status="playing = $event.playing"
                    data-test-id="media-player"
                />
              </FormInput>
            </template>
            <template v-if="media.origin === 'file'">
              <FormInput :label="l.t('app.upload-file', 'Upload file')">
                <w-file-input
                  @change="fileUploaded"
                  :label="l.t('media.media-file', 'Media file')"
                  data-test-id="media-file-input"
                  hide-details="auto"
                ></w-file-input>
              </FormInput>
            </template>
            <template v-else-if="media.origin === 'tts'">
              <FormInput :label="l.t('media.voice', 'Voice')">
                <w-select
                  v-if="voices"
                  v-model="media.tts_voice"
                  :rules="[$validation.required()]"
                  :items="voices.map((x) => {
                    return { value: x, text: format_voice_name(x) };
                  }).concat(deprecated_voice ? [{value: null, text: l.t('media.deprecated-voice', 'Deprecated voice')}] : [])"
                  hide-details="auto"
                  data-test-id="media-voice-selector"
                />
              </FormInput>
              <FormInput :label="l.t('app.text', 'Text')">
                <w-textarea
                  v-model="media.tts_text"
                  :placeholder="l.t('media.new-greeting-or-music-text', 'What will your greeting say?')"
                  :rules="[$validation.required()]"
                  auto-grow
                  rows="1"
                  row-height="15"
                  hide-details="auto"
                  data-test-id="media-text-input"
                ></w-textarea>
              </FormInput>
            </template>
            <template v-if="!just_media_part">
              <FormInput v-if="$route.name === 'media.music-on-hold-show' || ($route.name === 'media.create' && media.type && media.type ==='hold_music')" :label="l.t('media.random-start', 'Random start position')">
                <w-switch
                  v-model="media.randomized"
                  :label="media.randomized ? l.t('app.enabled-on', 'On') : l.t('app.enabled-off', 'Off')"
                  hide-details='auto'
                  data-test-id="media-random-start-switch"
                />
              </FormInput>
              <template v-if="!disable_extension_level">
                <FormInput :label="l.t('media.restrict-to-extension', 'Restrict to extension')">
                  <ExtensionSelector
                      :value="media.extension"
                      @changed="media.extension = $event.id"
                      data-test-id="media-restict-to-ext-selector"
                  />
                </FormInput>
              </template>
              <FormInput :label="l.t('app.notes', 'Notes')">
                <w-textarea
                  v-model="media.notes"
                  :placeholder="l.t('media.new-greeting-or-music-note', 'Add any notes you think may be helpful…')"
                  auto-grow
                  rows="3"
                  row-height="15"
                  hide-details="auto"
                  data-test-id="media-notes-input"
                ></w-textarea>
              </FormInput>
            </template>
            <template v-if="!just_media_part">
              <FormInput>
                <w-btn :disabled="!may_submit" type="submit" class="primary mt-5" data-test-id="media-submit-btn">{{ l.t("app.save", "Save") }}</w-btn>
              </FormInput>
            </template>
            <div v-if="just_media_part" class="mt-3">
              <w-btn @click="$emit('back')" type="button" class="info" data-test-id="media-back-btn">{{ l.t('app.back', 'Back') }}</w-btn>
              <w-btn :disabled="!may_submit" type="submit" color="error" class="ml-3" data-test-id="media-continue-btn">{{ l.t('app.continue', 'Continue') }}</w-btn>
            </div>
          </w-form>
    </div>
</template>

<script>
  import l from '../../libs/lang';
  import Media from '../../models/Media';
  import Helpers from '../../libs/helpers';
  import Player from '../elements/Player.vue';
  import FormInput from '../elements/form/FormInput.vue';
  import SectionTitle from '../elements/SectionTitle.vue';
  import ExtensionSelector from './ExtensionSelector.vue';
  import media_setup from '../../libs/resources-setups/media';

  export default {
    props: ['id', '_media', 'withPlayer', 'disable_extension_level', 'just_media_part', 'force_type'],
    components: {
      Player,
      FormInput,
      SectionTitle,
      ExtensionSelector,
    },
    data() {
      return {
        l,
        loading: false,
        alert: null,
        media: null,
        voices: media_setup.available_voices,
        playing: null,
        deprecated_voice: false,
        may_submit: false,
      };
    },
    async created() {
      if (this.voices) {
        this.voices = this.voices.sort((a, b) => {
          const aHasExt = a.includes('US');
          const bHasExt = b.includes('US');
          return bHasExt - aHasExt;
        });
      }
      if (this.$props._media) {
        this.$data.media = this.$props._media;
      } else if (this.$props.id) {
          this.loading = true;
          const media = new Media(this.$session, this.type, Helpers.emitter(this), Helpers.changeRoute(this));
          try {
            await media.getItem(this.$props.id);
            this.$data.media = media.item;
          } catch (err) {
            this.alert = media.alert;
          }
          this.loading = false;
      }
      if (this.$data.media.tts_voice) {
        const voice = this.$data.voices.find((x) => x.startsWith(this.$data.media.tts_voice));
        if (voice) {
          this.$data.media.tts_voice = voice;
        } else {
          this.$data.deprecated_voice = true;
          this.$data.media.tts_voice = null;
        }
      }
      if (this.$props.force_type) this.$data.media.type = this.$props.force_type;
      if (this.$route.name !== 'media.show' && this.$route.name !== 'media.create') this.media.origin = 'file';
      if (this.$route.name === 'media.create') {
        this.$data.media.tts_voice = 'Kimberly / English (US)';
      }
    },
    methods: {
      handleMediaTypeChange(e) {
        if (e === 'hold_music') {
          this.media.origin = 'file';
        }
        this.$forceUpdate();
      },
      async submitted(e) {
        if (e) e.preventDefault();
        const data = this.prepareData();
        if (data.origin === 'tts' && !data.tts_voice) {
          this.$data.alert = {
            level: 'error',
            message: l.t('media.please-choose-tts-voice', 'Please choose a Text To Speech voice.'),
          };
          return Helpers.hide_alert.call(this, 5);
        } if (data.origin === 'file' && !this.$data.media.file && this.$route.name !== 'media.show') {
          this.$data.alert = {
            level: 'error',
            message: l.t('media.please-upload-a-file', 'Please upload a media file.'),
          };
          return Helpers.hide_alert.call(this, 5);
        }
        if (data) this.$emit('submitted', data);

        return true;
      },
      prepareData() {
        const data = JSON.parse(JSON.stringify(this.$data.media));
        if (data.origin === 'tts') {
          data.filename = null;
          data.file = null;
        } else {
          data.tts_text = null;
          data.tts_voice = null;
        }
        return data;
      },
      async fileUploaded(file) {
        if (file) {
          this.$data.media.filename = file.name;
          this.$data.media.file = await Helpers.formFileToBase64(file);
        } else {
          this.$data.media = null;
        }
      },
      format_voice_name(value) {
        return `${value.split(' / ')[1]} - ${l.t('media.voice', 'Voice')}: ${value.split(' / ')[0]}`;
      },
    },
  };
</script>
