import Resource from './Resource';
import setup from '../libs/resources-setups/blocked-calls';

class BlockedCall extends Resource {
	constructor(session, emitter, changeRoute) {
		super(session, emitter, '/blocked-calls', changeRoute);
		this.setup = setup;
		this.item = {
			start_date: Math.floor(new Date().getTime() / 1000),
			type: 'incoming',
			pattern_type: 'equals',
			pattern: null,
			notes: '',
		};
		this.filters = {
			pattern_type: 'equals',
			pattern: null,
		};
		this._test_number = null;
		this.lastWeekCalls = [];
		this.lastWeekCacheKey = 'last-week-calls';
		this._show_test_number = false;
	}

	set show_test_number(val) {
		this._show_test_number = val;
		if (val) this.emitter('closeFilters');
	}

	get show_test_number() {
		return this._show_test_number;
	}

	set test_number(val) {
		if (val) {
			this.show_test_number = true;
		}
		this._test_number = val;
	}

	get test_number() {
		return this._test_number;
	}

	filtersToggled(val) {
		if (val && this.show_test_number) {
			this.show_test_number = false;
		}
	}

	async loadItems(params) {
		this.loading = true;
		try {
			const offset = this.checkIfUserDeletedItems(
				params.offset,
				params.page,
			);
			const items = await this.session.get_list(
				this.uri,
				params.limit,
				offset,
			);
			this.page = params.page;
			if (this.page > 1 && !items.items.length) {
				this.emitter('pageChanged', 1);
				return this.loadItems({
					limit: this.limit,
					offset: 0,
					page: 1,
				});
			}
			if (
				this.page === 1
				&& !items.items.length
				&& !this.filters_applied
				&& !this.test_number
			) {
				return this.changeRoute('blockedCalls.create');
			}
			this.items = items.items;
			this.finalizeLoadingItems(items);
			this.emitter('itemsLoaded', JSON.parse(JSON.stringify(items)));
		} catch (err) {
			this.validation_error(err);
		}
		this.loading = false;

		return this.items;
	}

	async apply_filters() {
		this.loading = true;
		try {
			this.clear_test_number();
			let uri = `${this.baseUri}?mode=full`;
			if (this.filters.pattern_type && this.filters['pattern']) {
				uri = `${uri}&filters[pattern_type]=${this.filters.pattern_type}`;
				uri = `${uri}&filters[pattern]=${encodeURIComponent(this.filters.pattern)}`;
			}
			this.uri = uri;
			this.filters_applied = true;
		} catch (err) {
			this.validation_error(err);
		}
		this.loading = false;
	}

	async do_test_number() {
		if (!this.test_number) return null;
		this.loading = true;
		this.clear_filters();
		this.keep_filters_open = false;
		try {
			let uri = `${this.baseUri}?mode=brief`;
			uri = `${uri}&filters[number]=${encodeURIComponent(this.test_number)}`;
			this.uri = uri;
		} catch (err) {
			this.validation_error(err);
		}
		this.loading = false;

		return this.uri;
	}

	clear_test_number() {
		this.show_test_number = false;
		this.test_number = null;
		this.uri = this.baseUri;
	}

	async create(call) {
		this.loading = true;
		this.item = call;
		try {
			await this.session.create_item(
				this.baseUri,
				this.item,
			);
			this.successfulCreation('blockedCalls.index');
		} catch (err) {
			this.validation_error(err);
		}

		this.loading = false;
	}

	async deleteItem(index) {
		try {
			const { id } = this.items[index];
			await this.session.delete_item(`${this.baseUri}/${id}`);
			this.items = this.items.filter((x) => x.id !== id);
			this.cachier.removeItem(this.lastWeekCacheKey);
		} catch (err) {
			this.validation_error(err);
		}
	}

	async deleteItemCallLogs(index) {
		try {
			await this.session.delete_item(`${this.baseUri}/${index}`);
			this.items = this.items.filter((x) => x.id !== index);
			this.cachier.removeItem(this.lastWeekCacheKey);
			return true;
		} catch (err) {
			this.validation_error(err);
		}
		return false;
	}

	removeDuplicates() {
		let equals = this.findBlockedType('equals');
		let startWiths = this.findBlockedType('starts_with');

		equals = BlockedCall.findRepeatingIds(equals);
		startWiths = BlockedCall.findRepeatingIds(startWiths);
		this.deleteRepeatingItems(equals);
		this.deleteRepeatingItems(startWiths);
	}

	findBlockedType(type) {
		return this.items
			// eslint-disable-next-line array-callback-return, consistent-return
			.map((x) => {
				if (x.pattern_type === type) return x;
			})
			.filter((x) => x);
	}

	deleteRepeatingItems(obj) {
		for (const item of this.items) {
			if (
				item.id
				&& obj[item.pattern]
				&& obj[item.pattern].includes(item.id)
			) {
				this.items = this.items.filter((x) => x.id !== item.id);
			}
		}
	}

	static findRepeatingIds(array) {
		const countingObj = {};
		array.map((x) => {
			if (!countingObj[x.pattern]) countingObj[x.pattern] = [];
			countingObj[x.pattern].push(x.id);
			return x;
		});
		for (const number of Object.keys(countingObj)) {
			countingObj[number].shift();
		}

		return countingObj;
	}

	async checkLastWeekCalls() {
		this.loading = true;
		const last_week_cache = this.cachier.getItem(this.lastWeekCacheKey);
		if (last_week_cache) {
			this.loading = false;
			this.lastWeekCalls = last_week_cache;
		} else {
			if (this.lastWeekCalls && this.lastWeekCalls.length) {
				this.loading = false;
				return null;
			}
			try {
				let filterDate = new Date();
				const weekAgo = filterDate.getDate() - 7;
				filterDate.setDate(weekAgo);
				filterDate = Math.floor(filterDate.getTime() / 1000);

				let calls = await this.session.get_list(
					`/call-logs?filters[start_time]=gte:${filterDate}&filters[direction]=in&limit=20`,
				);
				this.loading = false;

				calls = calls.items;
				if (calls.length) {
					calls = calls.map((x) => ({
							caller_id: x.caller_id,
							time: x.start_time_epoch,
						}));
					const existingNumbers = [];
					// eslint-disable-next-line array-callback-return, consistent-return
					calls = calls.map((x) => {
						if (
							!Number.isNaN(x.caller_id)
							&& !existingNumbers.includes(x.caller_id)
						) {
							existingNumbers.push(x.caller_id);
							return x;
						}
					});
					calls = calls.filter((x) => x);
					if (this.items.length) {
						const patterns = this.items.map((x) => x.pattern);
						calls = calls.filter(
							(x) => !patterns.includes(x.caller_id),
						);
					}
				}
				this.lastWeekCalls = calls;
				this.cachier.setItem(this.lastWeekCacheKey, this.lastWeekCalls);
				this.loading = false;
			} catch (err) {
				this.validation_error(err);
				this.loading = false;
			}
		}

		return true;
	}

	async bulkDelete() {
		await super.bulkDelete();
		this.cachier.removeItem(this.lastWeekCacheKey);
	}

	async deleteAll() {
		await super.deleteAll();
		this.cachier.removeItem(this.lastWeekCacheKey);
	}

	async addToBlocked(number, nts) {
		const notes = nts || '';
		try {
			const newBlock = await this.session.create_item(this.baseUri, {
				start_date: Math.floor(new Date().getTime() / 1000),
				type: 'incoming',
				pattern_type: 'equals',
				pattern: number.caller_id,
				notes,
			});
			this.items.unshift(newBlock);
			this.lastWeekCalls = this.lastWeekCalls.filter((x) => x !== number);
			const last_week_cache = this.cachier.getItem(this.lastWeekCacheKey);
			if (last_week_cache) {
				const calls = last_week_cache;
				this.cachier.setItem(this.lastWeekCacheKey, calls.filter((x) => x.caller_id !== number.caller_id));
			}

			return true;
		} catch (err) {
			this.validation_error(err);
		}

		return null;
	}
}

export default BlockedCall;
