<template>
  <w-loader v-if="trunk.loading"/>
  <div v-else class="show-page" data-test-id="trunks-show-page">
      <PageTitle class="nowrap">
        {{title()}}
      </PageTitle>
      <w-alert
        v-if="trunk.alert"
        :message="trunk.alert.message"
        :level="trunk.alert.level"
        :closable="trunk.enable_close_alert"
        @closed="trunk.alert = null"
        class="mb-6"
      ></w-alert>
      <TrunkFragment
        v-if="trunk.item"
        :_trunk="trunk.item"
        @submited="trunk.update($event)"
      />
  </div>
</template>

<script>
  import l from '../../../libs/lang';
  import Trunk from '../../../models/Trunk';
  import Helpers from '../../../libs/helpers';
  import TrunkFragment from '../../fragments/Trunk.vue';
  import PageTitle from '../../elements/PageTitle.vue';

  export default {
    props: ['id', '_trunk'],
    components: {
      TrunkFragment,
      PageTitle,
    },
    data() {
      return {
        l,
        last_title: '',
        trunk: new Trunk(this.$session, Helpers.emitter(this), Helpers.changeRoute(this)),
      };
    },
    async created() {
      if (this.$props._trunk || this.$route.params._trunk) {
        this.$data.trunk.item = this.$props._trunk || this.$route.params._trunk;
        await this.$data.trunk.checkIfTrunksAreEnabled();
      } else {
        await this.$data.trunk.checkIfTrunksAreEnabled();
        await this.$data.trunk.getItem(this.$props.id ? this.$props.id : this.$route.params.id);
      }
    },
    methods: {
      title() {
        let title = '';
        if (this.$data.trunk.item) {
          title += l.t('app.trunk', 'Trunk:');
          title += ` ${this.$data.trunk.item.name ? this.$data.trunk.item.name : ''}`;
          this.$data.last_title = title;
        } else {
          title = this.$data.last_title;
        }
        return title;
      },
    }
  };
</script>
