<template>
  <ConfirmationModal
    v-if="(vendor_id && vendor_id === 4) || ['avo', 'avo_master'].includes(this.$branding.data.code) || is_csr"
    v-bind="$attrs"
    v-on="$listeners"
    :title="l.t('lr.enable-script', 'Enable script')"
    :action_text="l.t('lr.continue', 'Continue')"
    width="400"
    data-test-id="lr-reactivate-modal"
  >
    <div>
        <div v-if="is_csr" class="font-weight-bold mb-5">
          {{l.t('after-you-re-enabled-the-script-for-the-customer-s-accounts', 'After you re-enabled the script for the customer\'s accounts, check if it was disabled and re-enabled in the same billing cycle. If it happened in the same billing cycle, you need to void a prorated charge for LR activation.')}}
        </div>
        <div>
            {{ l.t('lr.script-will-be-ready', 'Your script will be ready for use in your call handling rules. As soon as you apply the script to the call handling rules for any phone number, your live receptionist will begin answering calls.') }}
        </div>
        <div class="mt-5">
            {{ l.t('lr.are-you-sure-want', 'Are you sure you want to enable this script and begin incurring charges for your Receptionist service?') }}
        </div>
    </div>
  </ConfirmationModal>
  <DefaultModal v-else v-model="show_default_modal" v-bind="$attrs" v-on="$listeners" max-width="400">
    {{l.t('lr.to-reenable-this-script,-please-contact-customer-support-or-visit', 'To reenable this script, please contact customer support at 800.998.7087 or visit')}} <a href="https://support.phone.com/contact-us" target="_blank">support.phone.com</a> {{l.t('lr.to-chat-with-an-agent-or-submit-a-support-ticket', 'to chat with an agent or submit a support ticket')}}.
  </DefaultModal>
</template>

<script>
import l from '../../../libs/lang';
import Helpers from '../../../libs/helpers';
import ConfirmationModal from '../../elements/modal/ConfirmationModal.vue';
import DefaultModal from '../../elements/modal/DefaultModal.vue';

export default {
  props: {
      vendor_id: {
          type: Number,
          required: true,
      },
  },
  components: {
      DefaultModal,
      ConfirmationModal,
  },
	data() {
    return {
      l,
      show_default_modal: true,
      is_csr: false,
    };
	},
  async created() {
    this.$data.is_csr = await Helpers.is_csr(this.$session);
  },
};
</script>
