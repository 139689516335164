<template>
	<tr
	  :key="button.number"
	  data-test-id="menu-row"
	>
	    <td class="py-1 pl-0" data-test-id="number">
	        <PhoneButton
	            :number="button"
	            :active="active"
	            @click="$emit('button_click')"
	            :key="`${button}-${active}`"
	        />
	    </td>
	    <td @click="$emit('toggle')" class="list-link cursor-pointer" data-test-id="description">
	        {{ description }}
	    </td>
	    <td class="nowrap" data-test-id="presets-switcher">
	        <PresetSwitcher
				v-if="!is_preset || !active"
				@apply="$emit('apply_preset', $event)"
				@create="$emit('create_preset', $event)" />
	    </td>
	    <td class="nowrap" data-test-id="clear-options">
	        <div v-if="active" class="cursor-pointer" @click="on_clear">
	            <v-icon color="text lighten-1" small>mdi-close-circle</v-icon>
	            <span class="text--text text--lighten-1 ml-1">{{ l.t('menus.clear-actions', 'Clear actions') }}</span>
	        </div>
	    </td>
	    <td :class="active ? 'partially-bordered-td' : ''" data-test-id="triangle">
	        <v-icon
	            v-if="active"
	            @click="$emit('toggle')"
	            color="text"
	            :class="{'rotate-180': opened}"
	            class="cursor-pointer"
	            small
	        >
	            $vuetify.icons.triangle
	        </v-icon>
	    </td>
	</tr>
</template>

<script>
import l from '../../libs/lang';
import PhoneButton from '../elements/PhoneButton.vue';
import PresetSwitcher from '../elements/PresetSwitcher.vue';

export default {
	props: {
		button: {
			type: [String, Number],
			required: true,
		},
		active: {
			type: Boolean,
			default: false,
		},
		description: {
			type: String,
			required: true,
		},
		opened: {
			type: Boolean,
			default: false,
		},
		is_preset: {
			type: Boolean,
			default: false,
		}
	},
	components: { PhoneButton, PresetSwitcher, },
	data() {
		return { l, };
	},
	methods: {
		on_clear() {
			this.$emit('clear');
			this.$global_emitter.$emit('form_input_changed');
		}
	}
};
</script>
