import Add from '../../components/vuetify/icons/add.vue';
import Call from '../../components/vuetify/icons/call.vue';
import AVO from '../../components/vuetify/icons/avo.vue';
import Cart from '../../components/vuetify/icons/cart.vue';
import Menu from '../../components/vuetify/icons/menu.vue';
import Gear from '../../components/vuetify/icons/gear.vue';
import Dots from '../../components/vuetify/icons/dots.vue';
import Edit from '../../components/vuetify/icons/edit.vue';
import Play from '../../components/vuetify/icons/play.vue';
import Info from '../../components/vuetify/icons/info.vue';
import Stop from '../../components/vuetify/icons/stop.vue';
import Globe from '../../components/vuetify/icons/globe.vue';
import Arrow from '../../components/vuetify/icons/arrow.vue';
import Clock from '../../components/vuetify/icons/clock.vue';
import Alert from '../../components/vuetify/icons/alert.vue';
import Group from '../../components/vuetify/icons/group.vue';
import Block from '../../components/vuetify/icons/block.vue';
import Inbox from '../../components/vuetify/icons/inbox.vue';
import Check from '../../components/vuetify/icons/check.vue';
import Close from '../../components/vuetify/icons/close.vue';
import Hours from '../../components/vuetify/icons/hours.vue';
import Tailor from '../../components/vuetify/icons/tailor.vue';
import Idseal from '../../components/vuetify/icons/idseal.vue';
import Filter from '../../components/vuetify/icons/filter.vue';
import Reload from '../../components/vuetify/icons/reload.vue';
import Search from '../../components/vuetify/icons/search.vue';
import Avatar from '../../components/vuetify/icons/avatar.vue';
import Loader from '../../components/vuetify/icons/loader.vue';
import Hangup from '../../components/vuetify/icons/hangup.vue';
import Person from '../../components/vuetify/icons/person.vue';
import Screen from '../../components/vuetify/icons/screen.vue';
import OnCall from '../../components/vuetify/icons/on-call.vue';
import Company from '../../components/vuetify/icons/company.vue';
import Chevron from '../../components/vuetify/icons/chevron.vue';
import Unblock from '../../components/vuetify/icons/unblock.vue';
import Persons from '../../components/vuetify/icons/persons.vue';
import Enabled from '../../components/vuetify/icons/enabled.vue';
import TwoBars from '../../components/vuetify/icons/two-bars.vue';
import Headset from '../../components/vuetify/icons/head-set.vue';
import Disabled from '../../components/vuetify/icons/disabled.vue';
import Question from '../../components/vuetify/icons/question.vue';
import Download from '../../components/vuetify/icons/download.vue';
import Location from '../../components/vuetify/icons/location.vue';
import Triangle from '../../components/vuetify/icons/triangle.vue';
import TrashBin from '../../components/vuetify/icons/trashbin.vue';
import Calendar from '../../components/vuetify/icons/calendar.vue';
import Phonecom from '../../components/vuetify/icons/phone-com.vue';
import MoreVert from '../../components/vuetify/icons/more-vert.vue';
import TwoUsers from '../../components/vuetify/icons/two-users.vue';
import Headphone from '../../components/vuetify/icons/headphone.vue';
import Voicemail from '../../components/vuetify/icons/voicemail.vue';
import Transcript from '../../components/vuetify/icons/transcript.vue';
import Paperfile from '../../components/vuetify/icons/paper-file.vue';
import OpenedBox from '../../components/vuetify/icons/opened-box.vue';
import OpenInNew from '../../components/vuetify/icons/open-in-new.vue';
import AddCircled from '../../components/vuetify/icons/add-circle.vue';
import Attachment from '../../components/vuetify/icons/attachment.vue';
import HotInfotip from '../../components/vuetify/icons/infotip-hot.vue';
import Loudspeaker from '../../components/vuetify/icons/loudspeaker.vue';
import Landline from '../../components/vuetify/icons/landline-phone.vue';
import Exclamation from '../../components/vuetify/icons/exclamation.vue';
import PhoneRinging from '../../components/vuetify/icons/phone-ringing.vue';
import CallsHandling from '../../components/vuetify/icons/calls-handling.vue';
import DownloadCloud from '../../components/vuetify/icons/download-cloud.vue';
import InfoTipStylized from '../../components/vuetify/icons/infotip-stylized.vue';
import DropdownArrowDown from '../../components/vuetify/icons/dropdown-arrow-down.vue';
import ExclamationCircled from '../../components/vuetify/icons/exclamation-circled.vue';
import DownloadCloudInverted from '../../components/vuetify/icons/download-cloud-inverted.vue';

export default {
  avo: { component: AVO, },
  add: { component: Add, },
  add_circle: { component: AddCircled, },
  alert: { component: Alert, },
  arrow_forward: { component: Arrow, },
  attachment: { component: Attachment, },
  avatar: { component: Avatar, },
  block: { component: Block, },
  calendar: { component: Calendar, },
  call: { component: Call, },
  calls_handling: { component: CallsHandling, },
  cart: { component: Cart, },
  check: { component: Check, },
  chevron_forward: { component: Chevron, },
  clock: { component: Clock, },
  close: { component: Close, },
  company: { component: Company, },
  disabled: { component: Disabled, },
  download_cloud: { component: DownloadCloud, },
  dots: { component: Dots, },
  download: { component: Download, },
  download_cloud_inverted: { component: DownloadCloudInverted, },
  dropdown_arrow: { component: DropdownArrowDown, },
  edit: { component: Edit, },
  enabled: { component: Enabled, },
  exclamation: { component: Exclamation, },
  exclamation_circled: { component: ExclamationCircled, },
  filter: { component: Filter, },
  gear: { component: Gear, },
  hangup: { component: Hangup, },
  headphone: { component: Headphone, },
  headset: { component: Headset, },
  globe: { component: Globe, },
  group: { component: Group, },
  hours: { component: Hours, },
  idseal: { component: Idseal, },
  inbox: { component: Inbox, },
  info: { component: Info, },
  infotip_hot: { component: HotInfotip, },
  infotip_stylized: { component: InfoTipStylized, },
  landline: { component: Landline, },
  loader: { component: Loader, },
  location: { component: Location, },
  loudspeaker: { component: Loudspeaker, },
  menu: { component: Menu, },
  more_vert: { component: MoreVert, },
  on_call: { component: OnCall, },
  opened_box: { component: OpenedBox, },
  open_in_new: { component: OpenInNew, },
  paper_file: { component: Paperfile, },
  person: { component: Person, },
  persons: { component: Persons, },
  phone_com: { component: Phonecom, },
  phone_ringing: { component: PhoneRinging, },
  play: { component: Play, },
  question: { component: Question, },
  reload: { component: Reload, },
  screen: { component: Screen, },
  search: { component: Search, },
  stop: { component: Stop, },
  tailor: { component: Tailor, },
  transcript: { component: Transcript, },
  trash: { component: TrashBin, },
  triangle: { component: Triangle, },
  two_bars: { component: TwoBars, },
  two_users: { component: TwoUsers, },
  unblock: { component: Unblock, },
  voicemail: { component: Voicemail, },
  arrow_backward: {
    component: Arrow,
    props: {
      backward: true,
    },
  },
  chevron_backward: {
    component: Chevron,
    props: {
      backward: true,
    },
  },
  download_circled: {
    component: Download,
    props: {
      circle: true,
    },
  },
  dropdown_arrow_black: {
    component: DropdownArrowDown,
    props: {
      black: true,
    }
  },
  edit_circled: {
    component: Edit,
    props: {
      circle: true,
    },
  },
  spinning_loader: {
    component: Loader,
    props: {
      sppinning: true,
    },
  },
  trash_circled: {
    component: TrashBin,
    props: {
      circle: true,
    },
  },
};
