<template>
  <div class="schedules">
    <Submenu
      :routes="{
        'schedules.index': {
          title: l.t('app.schedules', 'Schedules'),
          subroutes: ['schedules.show']
          },
        'schedules.create': {
          title: l.t('schedules.add-schedules', 'Add schedules')
          },
      }"
      :key="`${l.detect()}${$route.name}`"
    />
    <SchedulesFragment
      v-if="$route.name === 'schedules.index'"
      @deletingStarted="deleting_in_progress = true"
      @deletingEnded="deleting_in_progress = false"
      data-test-id="schedules-index"
    />
    <SchedulesCreateFragment
      v-if="$route.name === 'schedules.create'"
      data-test-id="schedules-create"
    />
    <SchedulesShowFragment
      v-if="$route.name === 'schedules.show'"
      data-test-id="schedules-show"
    />
  </div>
</template>

<script>
import l from '../../../libs/lang';
import SchedulesFragment from './index.vue';
import SchedulesShowFragment from './show.vue';
import Submenu from '../../elements/Submenu.vue';
import SchedulesCreateFragment from './create.vue';

export default {
  components: {
    Submenu,
    SchedulesFragment,
    SchedulesShowFragment,
    SchedulesCreateFragment,
  },
  data() {
    return {
      l,
    };
  },
  beforeRouteLeave(to, from, next) {
    if (window.deleting_in_progress) {
      this.$emit('userRedirected', to, from, next);
    } else {
      next();
    }
  },
};
</script>
