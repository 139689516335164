<template>
  <v-hover
    v-slot="{ hover }"
    open-delay="100"
  >
    <v-card
      :elevation="hover ? 16 : 2"
      :class="{ 'on-hover': hover }"
      height="100%"
    >
      <v-card-text :class="{'text-right': !second }">
        <h3 class="mb-4">
          <span
            :class="{
              'primary--text':
                $options.filters.name(contact) === $options.filters.name(compared),
              'warning--text': contact.company !== compared.company,
            }"
            data-test-id="duplicate-name"
          >
            {{ contact | name }}
          </span>
        </h3>
        <h4 class="font-weight-bold mb-4" data-test-id="duplicate-id">{{ l.t("app.id", "ID") }}: {{ contact.id }}</h4>
        <h4 class="font-weight-bold">{{ l.t("app.company", "Company") }}:</h4>
        <p>
          <span
            :class="{
              'success--text':
                contact.company &&
                compared.company &&
                contact.company === compared.company,
              'warning--text': contact.company !== compared.company,
            }"
            data-test-id="duplicate-company"
          >
            {{ contact.company ? contact.company : "—" }}
          </span>
        </p>
        <h4 class="font-weight-bold">{{ l.t("app.phone-numbers", "Phone numbers") }}:</h4>
        <p data-test-id="duplicate-phone-numbers">
          <template v-if="contact.phone_numbers && contact.phone_numbers.length">
            <span
              v-for="number in contact.phone_numbers"
              :class="{
                'success--text': compared.phone_numbers.find(
                  (x) => x.number === number.number
                ),
                'warning--text': !compared.phone_numbers.find(
                  (x) => x.number === number.number
                ),
              }"
            >
              {{ number.number }} <br />
            </span>
          </template>
          <span v-else>—</span>
        </p>
        <h4 class="font-weight-bold">{{ l.t("app.emails", "Emails") }}:</h4>
        <p data-test-id="duplicate-emails">
          <template v-if="contact.emails && contact.emails.length">
            <span
              v-for="email in contact.emails"
              :class="{
                'success--text': compared.emails.find((x) => x.email === email.email),
                'warning--text': !compared.emails.find(
                  (x) => x.email === email.email
                ),
              }"
            >
              {{ email.email }} <br />
            </span>
          </template>
          <span v-else>—</span>
        </p>
        <h4 class="font-weight-bold">{{ l.t("app.group", "Group") }}:</h4>
        <p data-test-id="duplicate-group">
          <span
            :class="{
              'success--text':
                contact.group &&
                compared.group &&
                contact.group.id === compared.group.id,
              'warning--text':
                contact.group &&
                compared.group &&
                contact.group.id !== compared.group.id,
            }"
          >
            {{ contact.group ? contact.group.name : "—" }}
          </span>
        </p>
        <h4 class="font-weight-bold">{{ l.t("app.created-at", "Created at") }}:</h4>
        <p data-test-id="duplicate-created-at">
          {{ contact.created_at | time }}
        </p>
      </v-card-text>
    </v-card>
  </v-hover>
</template>

<script>
import l from '../../libs/lang';
import Helpers from '../../libs/helpers';

export default {
  props: ['contact', 'second', 'compared'],
  data() {
    return {
      l,
    };
  },
  filters: {
    name(contact) {
      let name = '';
      if (contact.first_name || contact.middle_name || contact.last_name) {
        if (contact.first_name) {
          name += `${contact.first_name} `;
        }
        if (contact.middle_name) {
          name += `${contact.middle_name} `;
        }
        if (contact.last_name) {
          name += `${contact.last_name} `;
        }
      } else {
        name = l.t('contacts.without-name', 'Without name');
      }

      return name.trim();
    },
    time(time) {
      return Helpers.format_time(time);
    },
  },
};
</script>
