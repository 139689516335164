<template>
    <div class="blocked-calls">
      <Submenu
        :routes="{
            'blockedCalls.index': {
              title: l.t('app.blocked-numbers', 'Blocked numbers')
            },
            'blockedCalls.create': {
              title: l.t('blocked-calls.block-numbers', 'Block numbers')
            },
            'blockedCalls.recent': {
              title:l.t('blocked-calls.recent-calls', 'Recent calls')
            },
        }"
        :key="`${l.detect()}${$route.name}`"
      />
      <BlockedCallsFragment
        v-if="$route.name === 'blockedCalls.index'"
        @deletingStarted="deleting_in_progress = true"
        @deletingEnded="deleting_in_progress = false"
        data-test-id="bc-index"
      />
      <BlockedCallsCreateFragment
        v-if="$route.name === 'blockedCalls.create'"
        data-test-id="bc-create"
      />
      <RecentCallsFragment
        v-if="$route.name === 'blockedCalls.recent'"
        data-test-id="bc-recent"
      />
    </div>
</template>

<script>
  import l from '../../../libs/lang';
  import BlockedCallsFragment from './index.vue';
  import RecentCallsFragment from './recent.vue';
  import BlockedCallsCreateFragment from './create.vue';
  import Submenu from '../../elements/Submenu.vue';

  export default {
    components: {
      Submenu,
      RecentCallsFragment,
      BlockedCallsFragment,
      BlockedCallsCreateFragment,
    },
    data() {
      return {
        l,
        deleting_in_progress: false,
      };
    },
    beforeRouteLeave(to, from, next) {
      if (window.deleting_in_progress) {
        this.$emit('userRedirected', to, from, next);
      } else {
        next();
      }
    },
  };
</script>
