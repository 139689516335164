<template>
    <w-autocomplete v-bind="$attrs" v-on="$listeners" :items="items" v-model="val" :placeholder="l.t('app.select-service-type', 'Select your service type')" />
</template>
<script>
import l from '../../../libs/lang';
import Resource from '../../../models/Resource';

export default {
    props: ['value'],
    data() {
        return {
            l,
            items: [],
            val: null,
        };
    },
    created() {
        const items = [];
        const vendors = [
            {
              'id': 1,
              'name': 'AVO - English',
              'number': '+883510080970',
              'screening': true,
              'codes': [
                {
                  'id': 474,
                  'code': 19074,
                  'type': 'periodic',
                  'retail': 0
                },
                {
                  'id': 475,
                  'code': 19075,
                  'type': 'periodic',
                  'retail': 0
                },
                {
                  'id': 476,
                  'code': 19076,
                  'type': 'periodic',
                  'retail': 0
                },
                {
                  'id': 547,
                  'code': 19077,
                  'type': 'periodic',
                  'retail': 0
                },
                {
                  'id': 548,
                  'code': 19078,
                  'type': 'periodic',
                  'retail': 0
                },
                {
                  'id': 549,
                  'code': 19079,
                  'type': 'periodic',
                  'retail': 0
                },
                {
                  'id': 550,
                  'code': 19080,
                  'type': 'periodic',
                  'retail': 0
                },
              ]
            },
            {
              'id': 4,
              'name': 'Onvego',
              'number': '+883510008279653',
              'screening': false,
              'codes': [
                {
                  'id': 552,
                  'code': 19087,
                  'type': 'periodic',
                  'retail': 0
                }
              ]
            }
        ];
        vendors.sort((a, b) => a['name'].localeCompare(b['name']));
        for (const vendor of vendors) {
            items.push({
                text: Resource.getServiceTypeName(vendor.id),
                value: vendor,
            });
        }
        this.items = items;
        if (this.$props.value) {
            const found_value = this.$data.items.find((x) => x.value.id === this.$props.value.id);
            this.$data.val = found_value ? found_value.value : null;
        }
    },
    watch: {
        val(v) {
            this.$emit('input', v);
        },
    }
};
</script>
