<template>
    <div data-test-id="reports-show-page">
        <SMS v-if="$route.params.type === 'sms'" data-test-id="reports-sms"></SMS>
        <Calls v-if="$route.params.type === 'calls'" data-test-id="reports-others"></Calls>
    </div>
</template>

<script>
  import SMS from './sms.vue';
  import Calls from './calls/index.vue';

  export default {
    components: {
      SMS,
      Calls,
    },
  };
</script>
