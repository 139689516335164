<template>
    <w-loader v-if="order.loading"/> <!-- TODO this page has no vuetify and data-test-id="" -->
    <div v-else class="order show-page" data-test-id="orders-show-page">
      <BackButton />
      <div class="d-flex justify-center w-h5 mb-1">
        {{l.t('app.order', 'Order')}} #{{ l.t('app.order-ID', 'Order ID') }}-{{item.id}}
      </div>
      <div class="d-flex justify-center w-caption">
        {{l.t('orders.tracking-number', 'Tracking number')}}:&nbsp;
        <a
          v-if="item.tracking_number"
          :href="`https://www.fedex.com/fedextrack/?tracknumbers=${item.tracking_number}`"
          target="_blank"
          class="w-subtitle-3"
        >
          {{item.tracking_number}}
        </a>
          <span v-else class="text--text text--lighten-1">{{l.t('orders.available-after-shipping', 'Available after shipping')}}</span>
      </div>
      <w-alert
        v-if="order.alert"
        :message="order.alert.message"
        :level="order.alert.level"
        :closable="order.enable_close_alert"
        @closed="order.alert = null"
        class="mb-6"
      />
      <div v-if="item" class="mt-9" :class="{'d-flex justify-center': $vuetify.breakpoint.mdAndUp}">
        <VerticalTable
          :items="[
            {text: l.t('app.voIP-id', 'VoIP ID'), value: item.voip_id},
            {text: l.t('app.status', 'Status'), value: order.status_translations[item.status] ? order.status_translations[item.status] : item.status},
            {text: l.t('orders.shipping-method', 'Shipping method'), value: order.formatShippingMethod(item.shipping_method)},
            {text: l.t('orders.shipping-address', 'Shipping address'), value: order.constructor.stringifyAddress(item.contact.address)},
            {text: l.t('app.name', 'Name'), value: item.contact.name},
            {text: l.t('app.phone', 'Phone'), value: format_phone_number(item.contact.phone)},
            {text: l.t('app.email-address', 'Email address'), value: item.contact.primary_email ? item.contact.primary_email : primary_email},
            {text: l.t('orders.signature-required', 'Signature required'), value: item.signature_required ? l.t('app.yes', 'Yes') : l.t('app.no', 'No')},
          ]"
          :title="l.t('orders.details-contact-information', 'Details & Contact information')"
          :style="{width: $vuetify.breakpoint.mdAndUp ? '350px' : '100%', marginRight: $vuetify.breakpoint.mdAndUp ? '37px' : 0}"
        />
        <VerticalTable
        :items="devices_items()"
        :title="l.t('app.devices', 'Devices')"
        :style="{width: $vuetify.breakpoint.mdAndUp ? '350px' : '100%', marginTop: !$vuetify.breakpoint.mdAndUp ? '40px' : 0}"
      />
      </div>
    </div>
</template>

<script>
  import l from '../../../../libs/lang';
  import Order from '../../../../models/Order';
  import Helpers from '../../../../libs/helpers';
  import BackButton from '../../../elements/BackButton.vue';
  import VerticalTable from '../../../elements/VerticalTable.vue';

  export default {
    props: ['id', '_order'],
    components: { BackButton, VerticalTable },
    data() {
      return {
        l,
        item: null,
        order: new Order(this.$session, Helpers.emitter(this), Helpers.changeRoute(this)),
        primary_email: null,
      };
    },
    async created() {
      this.$data.order.loading = true;
      const {billing_contact} = await Helpers.get_account_information(this.$session);
      this.$data.primary_email = billing_contact.primary_email;
      if (this.$props._order || this.$route.params._order) {
        this.$data.item = this.$props._order || this.$route.params._order;
      } else {
        const items = await this.$session.get_list_all('/orders');
        const item = items.items.find((x) => x.id === Number.parseInt(this.$route.params.id));
        if (item) {
          this.$data.item = item;
        } else {
          this.$data.order.alert = {
            level: 'error',
            message: l.t('app.not-found', 'Not found'),
          };
        }
      }
      await this.$data.order.loadShippingMethods();
      this.$data.order.loading = false;
    },
    methods: {
      devices_items() {
        let items = [];
        if (this.$data.item.devices) {
          const {devices} = this.$data.item;
          for (let i = 0; i < devices.length; i++) {
            const device = devices[i];
            const device_items = [
              {text: l.t('app.product-id-uppercase', 'PRODUCT ID'), value: `${device.id}`},
              {text: l.t('app.device', 'Device'), value: `${device.model.manufacturer} ${device.model.name}`},
              {text: l.t('app.type', 'Type'), value: device.type},
              {text: l.t('app.code', 'Code'), value: device.code},
              {text: l.t('app.device', ''), value: `${device.model.lines}`},
              {text: l.t('app.E911-address', 'E911 address'), value: Order.stringifyAddress(device.address)},
            ];
            items = items.concat(device_items);
            if (i < devices.length - 1) {
              items.push({divider: true});
            }
          }
        }

        return items;
      },
      format_phone_number(number) {
        if (!number) return null;
        return Helpers.format_phone_number(number);
      }
    },
    filters: {
      address: (data) => {
        if (!data) return '—';
        let lines = data.line_1;
        if (data.line_2) lines += ` ${data.line_2}`;
        lines = lines.trim();
        const parts = [
          lines,
          data.postal_code,
          data.city,
          data.province,
          data.country
        ].filter((x) => x);
        if (parts.length) return parts.join(', ');

        return '—';
      }
    }
  };
</script>
<style lang="scss">
  .order.show-page {
    table {
      tr {
        height: 25px;
        th {
          width: 15%;
          max-width: 15%;
          text-align: left !important;
        }
      }
    }
  }
</style>
