import Resource from './Resource';
import Helpers from '../libs/helpers';
import setup from '../libs/resources-setups/devices';

class Device extends Resource {
    constructor(session, emitter, changeRoute) {
        super(session, emitter, '/devices', changeRoute);
        this.setup = setup;
        this.filters = {
            name: null,
            type: null,
            sip_username: null,
            extension_name: null,
            extension_number: null,
        };
        this.item = {
            name: null,
            lines: [],
        };
    }

    async update_address(id, address) {
        this.deleting_item = id;
        try {
            const updated_item = await this.session.patch_item(`${this.baseUri}/${id}`, { address });
            const index = this.items.findIndex((x) => x.id === id);
            if (id > -1) {
                this.items[index] = updated_item;
            }
            this.updateCache(updated_item);
        } catch (err) {
            this.validation_error(err);
        }
        this.deleting_item = null;
    }

    async create(device) {
        this.loading = true;
        try {
            const new_device = await this.session.create_item(this.baseUri, device);
            this.successfulCreation('devices.index');
            this.item = null;

            return new_device;
        } catch (err) {
            return this.validation_error(err);
        }
    }

    async update(item) {
        this.loading = true;
        try {
            this.item = item;
            if (this.item.address) delete this.item.address;
            await this.session.replace_item(
                `${this.baseUri}/${this.item.id}`, this.item,
            );
            this.item = null;
            this.successfulUpdate('devices.index');
        } catch (err) {
            this.validation_error(err);
        }
        this.loading = false;
    }

    apply_filters() {
        this.clearMessages();
        this.filters_applied = true;

        try {
            let uri = `${this.baseUri}?mode=full`;
            if (this.filters.name) uri = `${uri}&filters[name]=contains:${this.filters.name}`;
            if (this.filters.type) uri = `${uri}&filters[type]=${this.filters.type}`;
            if (this.filters.sip_username) uri = `${uri}&filters[sip_username]=${this.filters.sip_username}`;
            if (this.filters.extension_name) uri = `${uri}&filters[extension_name]=contains:${this.filters.extension_name}`;
            if (this.filters.extension_number) uri = `${uri}&filters[extension_number]=${this.filters.extension_number}`;
            this.uri = uri;
        } catch (err) {
            this.emitter('failed', err);
        }
    }

    async loadItems(params) {
        this.loading = true;
        try {
            const offset = this.checkIfUserDeletedItems(params.offset, params.page);
            const items = await this.session.get_list(this.uri, params.limit, offset);
            this.page = params.page;
            if (this.page > 1 && !items.items.length) {
                this.emitter('pageChanged', 1);
                return this.loadItems({
                    limit: this.limit,
                    offset: 0,
                    page: 1,
                });
            }
            if (this.page === 1 && !this.filters_applied && !items.items.length) {
                return this.changeRoute('devices.create');
            }
            this.items = items.items;
            this.finalizeLoadingItems(items);
            this.emitter('itemsLoaded', JSON.parse(JSON.stringify(items)));
        } catch (err) {
            this.validation_error(err);
        }
        this.loading = false;

        return this.items;
    }

    async checkAll() {
        const nxt_user_extensions = await Helpers.nxt_user_extensions(this.session);
        super.checkAll();
        if (nxt_user_extensions && nxt_user_extensions.length) { // we must not delete device associated with user extension for nxt users
          this.items.map((x) => {
            if (!Device.device_is_deletable(x, nxt_user_extensions)) {
              x.selected = false;
            }
            return x;
          });
        }
    }

    async pre_delete_all_filter(itms) {
      let items = itms;
      const nxt_user_extensions = await Helpers.nxt_user_extensions(this.session);
      if (nxt_user_extensions && nxt_user_extensions.length) { // we must not delete device associated with user extension for nxt users
        items = items.filter((x) => Device.device_is_deletable(x, nxt_user_extensions));
      }

      return items;
    }

    static device_is_deletable(device, nxt_user_extensions) {
        if (device.lines && !device.lines.length) return true;
        return !nxt_user_extensions
          || (
            nxt_user_extensions
            && device.lines
            && device.lines.length
            && !device.lines
            .filter((x) => x.extension)
            .map((x) => x.extension.id)
            .find((x) => nxt_user_extensions.includes(x))
          );
    }
}

export default Device;
