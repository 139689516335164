import l from '../libs/lang';
import media_setup from '../libs/resources-setups/media';
import SystemMOH from '../libs/system-moh.json';
// eslint-disable-next-line import/no-cycle
import Resource from './Resource';
import Cachier from '../libs/Cachier';
import Helpers from '../libs/helpers';
import CountriesCodes from '../libs/geo/countries-pdc-codes.js';
import SelectorPromisesQueueSingleton from '../libs/SelectorPromisesQueueSingleton';

export default class Selector {
    /**
     * @param {string} mode - selector mode (phone_numbers, extensions, queues etc.)
     * @param {object} session - Phoenix object
     * @param {number} extension - extension id, so we will search on extension level
     * @param {object} conf - configuration parameters, it contains
     {
       "select_if_one": boolean - boolean,
       "value": number - id of preselected value,
       "reload": boolean - ignores cache, reloads items
     }
     * @param {function} emitter - method emitting components events
     */
    constructor(mode, session, extension, conf, emitter) {
        this.emitter = emitter;
        this.session = session;
        this.extension = extension;
        this.mode = this.chooseMode(mode);
        if (!this.mode) console.warn('Unsupported selector mode.');
        this.mode_name = mode;
        this.conf = conf;

        let value_v = null;
        if (this.conf.value) {
            value_v = this.conf.value;
        } else if (Object.prototype.hasOwnProperty.call(this.mode, 'default')) {
            value_v = this.mode['default'];
        }
        this.cachier = new Cachier(this.session.user.id);
        this.values = {};
        this.loading = true;
        this._value_v = value_v;
        if (this.mode.model) {
            this.mode.model.item = value_v ? {id: value_v} : null;
        }
        this.limit = this.mode_name === 'extensions' ? 200 : 100;
        this.limit_reached = false;
        this.search = false;
        this.search_term = null;
        this.filters_applied = false;
        this.selector_alert = false;
    }

    set value_v(val) {
        if (val === 'search_to_get_more') {
            this.search = true;
        } else {
            this._value_v = val;
            this.emit_change(val);
            this.check_events();
            if (this.mode.model) {
                this.mode.model.item = val ? {id: val} : null;
            }
        }
    }

    get value_v() {
        return this._value_v;
    }

    set baseUri(val) {
        this.mode.uri = val;
    }

    get baseUri() {
        return this.extension ? `/extensions/${this.extension}${this.mode['uri']}` : this.mode['uri'];
    }

    set searchUri(val) {
        this.mode.search_uri = val;
    }

    get searchUri() {
        const search_uri = this.mode['search_uri'] || this.baseUri;
        return this.extension ? `/extensions/${this.extension}${search_uri}` : search_uri;
    }

    emit_change(val) {
        let value;
        if (Array.isArray(val)) {
            value = val.map((x) => this.values[x]);
        } else {
            value = this.values[val];
        }
        this.emitter('changed', {
            id: val,
            value,
        });
    }

    cache_key() {
        let cache_key = `${this.mode_name}`;
        if (this.extension) cache_key += `_extension_${this.extension}`;
        return cache_key;
    }

    total_cache_key() {
        return `${this.cache_key()}-total`;
    }

    async load_items() {
        this.limit_reached = false;
        this.search = false;
        this.filters_applied = false;
        this.selector_alert = false;
        const cache_key = this.cache_key();
        await SelectorPromisesQueueSingleton.enqueue(this.mode_name, () => this.load_items_promise(cache_key));

        if (!this.conf.suppress_no_items_alert && !Object.keys(this.values).length) this.selector_alert = l.t('app.no-results-found', 'No results found');
    }

    async load_items_promise(cache_key) {
        if (!(await this.check_cache(cache_key))) {
            if (Object.prototype.hasOwnProperty.call(this.mode, 'custom')) {
                this.mode['custom'].call(cache_key);
            } else {
                const items = await this.retrieve_items(
                    this.baseUri,
                    cache_key,
                );
                this.values = {};
                this.addValues(items);
                this.finalize_loading(cache_key);
            }
        }
    }

    addValues(items) {
        if (this.mode.filter) {
            items = this.mode.filter(items);
        }
        for (const i in items) {
            if (Object.prototype.hasOwnProperty.call(items, i) && !items[i]._dont_cache) {
                this.values[items[i][this.mode.value_property || 'id']] = items[i];
            }
        }

        return true;
    }

    async retrieve_items(uri, cache_key) {
        let res = null;
        if (this.mode.load_all) {
            res = await this.session.get_list_all(uri);
        } else {
            res = await this.session.get_list(uri, this.limit);
        }

        if (this.mode.response_callback) {
            res = await this.mode.response_callback(res);
        }

        if (cache_key) {
            this.cachier.setItem(this.total_cache_key(), res.total);
        }
        this.check_limit(res.total);

        return res.items;
    }

    async check_cache() {
        if (!this.conf.reload) {
            const cached = this.cachier.getItem(this.cache_key());
            const cached_total = this.cachier.getItem(this.total_cache_key());
            if (cached) {
                this.values = cached;
                this.check_limit(cached_total);
                await this.post_process();
                return true;
            }
        }
        this.cachier.removeItem(this.cache_key());
        this.cachier.removeItem(this.total_cache_key());

        return false;
    }

    check_limit(total) {
        if (total > this.limit) this.limit_reached = true;
    }

    check_events() {
        if (
            this.conf.select_if_one
            && Object.keys(this.values).length === 1
        ) this.emitter('only_one_auto_selected');
    }

    async post_process() {
        if (this.conf.value) {
            if (this.mode.search_missing_value && !Object.keys(this.values).find((x) => x.toString() === this.conf.value.toString())) {
                if (!Number.isNaN(this.conf.value)) {
                    try {
                        const baseUri = this.baseUri.includes('?') ? this.baseUri.split('?')[0] : this.baseUri;
                        const passed_value = await this.session.get_item(
                            `${baseUri}/${this.conf.value}`,
                        );
                        this.values[passed_value['id']] = passed_value;
                    } catch (err) {
                        console.log('Default value not found.');
                    }
                }
            }
        }

        if (Object.prototype.hasOwnProperty.call(this.mode, 'static-values')) {
            // eslint-disable-next-line guard-for-in
            for (const i in this.mode['static-values']) this.values[i] = this.mode['static-values'][i];
        }

        this.loading = false;
        if (
            (this.conf.select_if_one && Object.keys(this.values).length === 1)
            || (this.conf.select_if_null
                && !this.value_v
                && Object.keys(this.values).length > 0)
        ) {
            this.value_v = Number.parseInt(Object.keys(this.values)[0]);
        } else {
             for (const i in this.values) {
                 if (this.values[i]['id'] === parseInt(this.value_v)) {
                    this.emit_change(this.values[i][this.mode.value_property || 'id']);
                }
            }
        }

        setTimeout(() => {
            this.check_events();
        }, 1);
    }

    finalize_loading(cache_key) {
        if (cache_key) this.cachier.setItem(cache_key, this.values);
        this.post_process();
    }

    async searchByName(event) {
        event.preventDefault();
        if (!this.search_term) return null;
        this.selector_alert = false;
        this.loading = true;
        try {
            let items = null;
            if (this.mode.load_all) {
                items = await this.searchByNameInItems();
            } else {
                this.values = {};
                items = await this.retrieve_items(
                    `${this.searchUri}${this.searchUri.includes('?') ? '&' : '?'}filters[name]=contains:${this.search_term}`,
                    false,
                );
                if (
                    this.mode_name === 'extensions'
                    && !Number.isNaN(this.search_term)
                    && Number.isInteger(parseFloat(this.search_term))
                ) {
                  const by_extension = await this.retrieve_items(
                      `${this.searchUri}${this.searchUri.includes('?') ? '&' : '?'}filters[extension]=${this.search_term}`,
                      false,
                  );
                  items = items.concat(by_extension);
                }
            }
            this.addValues(items);
            if (items.length) {
                this.search = false;
            } else {
                this.selector_alert = l.t('app.no-results-found', 'No results found');
            }

            this.filters_applied = true;
            this.finalize_loading(false);
        } catch (err) {
            console.log(err);
            this.selector_alert = l.t('app.generic-error', 'Something went wrong');
            this.loading = false;
            this.emitter('failed', err);
        }

        return true;
    }

    async searchByNameInItems() {
        await this.clearFilters();
        const values = Object.values(this.values);
        const res = {
            items: [],
            total: null,
        };
        const search_term = this.search_term.toLowerCase();
        for (const item of values) {
            if (Object.prototype.hasOwnProperty.call(item, 'name') && item.name.toLowerCase().includes(search_term)) res.items.push(item);
             else if (Object.prototype.hasOwnProperty.call(item, 'first_name') || Object.prototype.hasOwnProperty.call(item, 'middle_name') || Object.prototype.hasOwnProperty.call(item, 'last_name')) {
                const name = [item.first_name, item.middle_name, item.last_name].filter((x) => x).join(' ');
                if (name.toLowerCase().includes(search_term)) res.items.push(item);
            }
        }

        res.total = res.items.length ? res.items.length : null;

        this.check_limit(res.total);
        this.values = {};
        const { items } = res;
        this.addValues(items);

        return items;
    }

    async clearFilters() {
        this.loading = true;
        const items = await this.load_items();

        return items;
    }

    chooseMode(mode) {
        // ex. of possible values
        // {
            // uri: '/media?filters[type]=hold_music', => uri with filters to retrieve items
            // search_uri: '/routes', => uri for search functionality, ex. /routes?filters[name]=not-empty' retrieves presets, but will break the name search
            // name: (value) => `${value['id'] ? `#${value['id']} ` : ''}${value['name']}`, => display name cb
            // sort: (a, b) => (a['name'] === '(none)' ? -1 : a['name'] - b['name']), => sort cb
            // load_all: false, => careful. this will load all rss from the account
            // model: new Resource(this.session, this.emitter, '/media', null), => needed for edit modal
            // edit_route: 'media.show', // if route ius not allowed for the client, wont display the modal popup
            // placeholder: l.t('selector.select-a-music-on-hold', 'Select a music on hold'), => selectgor placeholder
            // playable: '/media', => for rss that can be played
            // custom: probably should be separate component, doesnt hit API but prints coded options, ex.greetings_langs
            // filter: (items) => items.filter((x) => !x.name.toLowerCase().startsWith('lrdn-')); => filters loaded items
            // title: () => string; => title shown on hover
            // value_property: property that should serve as selector value, default id. Check shipping_methods
            // search_missing_value: set as false, prevents send api requetst to search for the value of selector and putting it to cahce; can be set as condition: this.value_v > 24 - for hold music not to search for the system built in values
            // response_callback: async (api_response) => return api_response; => api response modifier, ex. if contact-groups are empty create default groups
        // }
        const media_title = () => {
            if (this.value_v) {
                const item = this.values[this.value_v];
                if (item) {
                    let title = '';
                    if (item['tts']) {
                        title = `${l.t('app.text', 'Text')}: "${item['tts']['text'].length > 100 ? `${item['tts']['text'].slice(0, 90)}...` : item['tts']['text']}"`;
                    }
                    if (item.notes) {
                     title = `${title} ${title ? ' / ' : ''}${l.t('app.notes', 'Notes')}: ${item.notes}`;
                    }

                    return title;
                    }
                }
            return false;
        };

        const phone_numbers_configuration = {
            uri: '/phone-numbers',
            search_uri: '/phone-numbers?filters[lrdn_numbers]=include',
            name: (value) => `${Helpers.format_phone_number(value['phone_number'])} / ${value['name']}`,
            sort: (a, b) => a['phone_number'].localeCompare(b['phone_number']),
            load_all: false,
            model: new Resource(this.session, this.emitter, '/phone-numbers', null),
            edit_route: 'phone-numbers.show',
            placeholder: l.t('selector.select-a-phone-number', 'Select a phone number'),
            search_missing_value: true,
        };

        const MODES_REF = {
            phone_numbers: phone_numbers_configuration,
            phone_numbers_as_numbers: {
                ...phone_numbers_configuration,
                value_property: 'phone_number',
                sort: (items) => items,
            },
            extensions: {
                uri: '/extensions',
                name: (value) => `${value['extension']}: ${value['name']}`,
                sort: (a, b) => a['extension'] - b['extension'],
                load_all: false,
                model: new Resource(this.session, this.emitter, '/extensions', null),
                edit_route: 'extensions.show',
                placeholder: l.t('selector.select-an-extension', 'Select an extension'),
                search_missing_value: true,
            },
            virtualExtensions: {
                uri: '/extensions?filters[virtual]=0',
                name: (value) => `${value['extension']}: ${value['name']}`,
                sort: (a, b) => a['extension'] - b['extension'],
                load_all: false,
                model: new Resource(this.session, this.emitter, '/extensions', null),
                edit_route: 'extensions.show',
                placeholder: l.t('selector.select-an-extension', 'Select an extension'),
                search_missing_value: true,
            },
            hold_music: {
                uri: '/media?filters[type]=hold_music',
                name: (value) => `${value.name} ${value['id'] ? `#${value['id']} ` : ''}`,
                sort: (a, b) => (a['name'] === '(none)' ? -1 : a['name'] - b['name']),
                load_all: false,
                model: new Resource(this.session, this.emitter, '/media', null),
                edit_route: 'media.show',
                placeholder: l.t('selector.select-a-music-on-hold', 'Select a music on hold'),
                playable: '/media',
                title: media_title,
                modal_title: l.t('app.music-on-hold', 'Music on hold'),
                search_missing_value: this.value_v > 24,
            },
            user_hold_music: {
                uri: '/media?filters[type]=hold_music&filters[ownership]=user',
                name: (value) => `${value.name} ${value['id'] ? `#${value['id']} ` : ''}`,
                sort: (a, b) => (a['name'] === '(none)' ? -1 : a['name'] - b['name']),
                load_all: false,
                model: new Resource(this.session, this.emitter, '/media', null),
                edit_route: 'media.show',
                placeholder: l.t('selector.select-a-music-on-hold', 'Select a music on hold'),
                playable: '/media',
                title: media_title,
                modal_title: l.t('app.music-on-hold', 'Music on hold'),
                search_missing_value: this.value_v > 24,
            },
            hold_music_premium: {
                name: (value) => value.name,
                sort: (a, b) => (a['name'] === '(none)' ? -1 : a['name'] - b['name']),
                custom: (cache_key) => {
                    this.values = {};
                    const premium_moh = SystemMOH.filter((x) => x.id > 11);
                    premium_moh.map((x) => this.values[x.id] = x);
                    this.finalize_loading(cache_key);
                },
                placeholder: l.t('selector.select-premium-music-on-hold', 'Select premium music on hold'),
                playable: '/media',
                title: () => l.t('app.premium-music-on-hold', 'Premium music on hold'),
                search_missing_value: false,
            },
            greetings: {
                uri: '/media?filters[type]=greeting&&filters[ownership]=user',
                name: (value) => `${value.name} ${value['id'] ? `#${value['id']} ` : ''}`,
                sort: (a, b) => a.name.localeCompare(b.name),
                load_all: false,
                model: new Resource(this.session, this.emitter, '/media', null),
                edit_route: 'media.show',
                placeholder: l.t('selector.select-a-greeting', 'Select a greeting'),
                playable: '/media',
                title: media_title,
                modal_title: l.t('app.greeting', 'Greeting'),
                search_missing_value: true,
            },
            greetings_langs: {
                name: (value) => `${value['name'].split(' / ')[1]} - ${l.t('media.voice', 'Voice')}: ${value['name'].split(' / ')[0]}`,
                sort: (a, b) => a['name'].split(' / ')[1].localeCompare(b['name'].split(' / ')[1]),
                custom: (cache_key) => {
                    this.values = {};
                    const eList = media_setup.available_voices;
                    for (const i in eList) {
                         if (Object.prototype.hasOwnProperty.call(eList, i) && eList[i].includes(' / ')) {
                            this.values[eList[i]] = {
                                id: eList[i],
                                name: eList[i],
                            };
                        }
                    }

                    this.finalize_loading(cache_key);
                },
                load_all: false,
                search_missing_value: true,
            },
            countries_with_codes: {
                name: (value) => `${value['name']}`,
                sort: (a, b) => a['name'].localeCompare(b['name']),
                custom: (cache_key) => {
                    this.values = {};
                    Object.keys(CountriesCodes).map((x) => this.values[x] = {id: Number.parseInt(x), ...CountriesCodes[x]});
                    this.finalize_loading(cache_key);
                },
                placeholder: l.t('selector.select-a-country', 'Select a country'),
                load_all: false,
                search_missing_value: true,
            },
            queues: {
                uri: '/queues',
                name: (value) => `${value['name']}`,
                sort: (a, b) => a['name'].localeCompare(b['name']),
                load_all: false,
                model: new Resource(this.session, this.emitter, '/queues', null),
                edit_route: 'queues.show',
                placeholder: l.t('selector.select-a-queue', 'Select a queue'),
                modal_title: l.t('app.queue', 'Queue'),
                search_missing_value: true,
            },
            menus: {
                uri: '/menus',
                name: (value) => `${value['name']}`,
                sort: (a, b) => {
                    if (!a['name']) {
                        return 0;
                    }
                    return a['name'].localeCompare(b['name']);
                },
                load_all: false,
                model: new Resource(this.session, this.emitter, '/menus', null),
                edit_route: 'menus.show',
                placeholder: l.t('selector.select-a-menu', 'Select a menu'),
                modal_title: l.t('app.menu', 'Menu'),
                search_missing_value: true,
            },
            presets: {
                uri: '/routes?filters[name]=not-empty',
                search_uri: '/routes',
                name: (value) => `${value['name'] || `#${value.id}`}`,
                sort: (a, b) => {
                    if (!a['name']) {
                        delete this.values[a['id']];
                        return -1;
                    }
                    return a['name'].localeCompare(b['name']);
                },
                load_all: false,
                model: new Resource(this.session, this.emitter, '/routes', null),
                edit_route: 'routes.show',
                placeholder: l.t('selector.select-a-preset', 'Select a preset'),
                modal_title: l.t('app.preset', 'Preset'),
                search_missing_value: true,
            },
            schedules: {
                uri: '/schedules',
                name: (value) => `${value['name']}`,
                sort: (a, b) => a['name'].localeCompare(b['name']),
                load_all: false,
                model: new Resource(this.session, this.emitter, '/schedules', null),
                edit_route: 'schedules.show',
                placeholder: l.t('selector.select-a-schedules', 'Select a schedule'),
                modal_title: l.t('app.schedule', 'Schedule'),
                search_missing_value: true,
            },
            live_answer: {
                uri: '/live-answer',
                name: (value) => {
                    let {name} = value;
                    if (!value.enabled) {
                        name += ` (${l.t('app.disabled', 'Disabled')})`;
                    }

                    return name;
                },
                sort: (a, b) => a['name'].localeCompare(b['name']),
                load_all: true,
                model: new Resource(this.session, this.emitter, '/live-answer', null),
                edit_route: 'live-answer.show',
                placeholder: l.t('selector.select-a-script', 'Select a script'),
                modal_title: l.t('app.receptionist-script', 'Receptionist script'),
                search_missing_value: true,
            },
            live_answer_vendors: {
                name: (value) => value['name'],
                sort: (a, b) => a['name'].localeCompare(b['name']),
                custom: (cache_key) => {
                    const items = [
                        {
                          'id': 1,
                          'name': 'AVO - English',
                          'number': '+883510080970',
                          'screening': true,
                          'codes': [
                            {
                              'id': 474,
                              'code': 19074,
                              'type': 'periodic',
                              'retail': 0
                            },
                            {
                              'id': 475,
                              'code': 19075,
                              'type': 'periodic',
                              'retail': 0
                            },
                            {
                              'id': 476,
                              'code': 19076,
                              'type': 'periodic',
                              'retail': 0
                            },
                            {
                              'id': 547,
                              'code': 19077,
                              'type': 'periodic',
                              'retail': 0
                            },
                            {
                              'id': 548,
                              'code': 19078,
                              'type': 'periodic',
                              'retail': 0
                            },
                            {
                              'id': 549,
                              'code': 19079,
                              'type': 'periodic',
                              'retail': 0
                            },
                            {
                              'id': 550,
                              'code': 19080,
                              'type': 'periodic',
                              'retail': 0
                            },
                            // { // AVO - English
                            //   'id': 551,
                            //   'code': 19086,
                            //   'type': 'periodic',
                            //   'retail': 0
                            // }
                          ]
                        },
                        {
                          'id': 4,
                          'name': 'Onvego',
                          'number': '+883510008279653',
                          'screening': false,
                          'codes': [
                            {
                              'id': 552,
                              'code': 19087,
                              'type': 'periodic',
                              'retail': 0
                            }
                          ]
                        }
                    ];
                    this.values = {};
                    items.map((x) => this.values[x.id] = x);
                    this.finalize_loading(cache_key);
                },
                placeholder: l.t('app.select-service-type', 'Select your service type'),
                search_missing_value: false,
            },
            contacts: {
                uri: '/contacts',
                name: (value) => `${[value['first_name'], value['middle_name'], value['last_name']].filter((x) => x).join(' ')}`,
                sort: (a, b) => a['id'] - b['id'],
                load_all: false,
                model: new Resource(this.session, this.emitter, '/contacts', null),
                edit_route: 'contacts.show',
                placeholder: l.t('selector.select-a-contact', 'Select a contact'),
                modal_title: l.t('app.contact', 'Contact'),
                search_missing_value: true,
            },
            groups: {
                uri: '/contact-groups',
                name: (value) => `${value['name']}`,
                sort: (a, b) => a['id'] - b['id'],
                load_all: false,
                model: new Resource(this.session, this.emitter, '/contact-groups', null),
                edit_route: 'groups.index',
                placeholder: l.t('selector.select-a-group', 'Select a group'),
                modal_title: l.t('app.group', 'Group'),
                search_missing_value: true,
                response_callback: async (res) => {
                    if (!res.items.length) {
                        res.items = await Helpers.create_default_contact_groups(this.session, this.extension);
                    }
                    return res;
                },
            },
        };

        if (this.session.user && this.session.user.account && this.session.user.account.features && this.session.user.account.features['trunks-enabled']) {
            MODES_REF['trunks'] = {
                uri: '/trunks',
                name: (value) => `${value['name']}`,
                sort: (a, b) => a['name'].localeCompare(b['name']),
                load_all: false,
                model: new Resource(this.session, this.emitter, '/trunks', null),
                edit_route: 'trunks.show',
                placeholder: l.t('selector.select-a-trunk', 'Select a trunk'),
                modal_title: l.t('app.trunk', 'Trunk'),
                search_missing_value: true,
            };
        }

        return MODES_REF[mode];
    }
}
