var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"messages"},[_c('Submenu',{key:`${_vm.l.detect()}${_vm.$route.name}`,attrs:{"routes":{
      'messages.index': {
        title: _vm.l.t('app.messages', 'Messages')
      },
      'messages.create': {
        title: _vm.l.t('messages.send-message', 'Send message')
      },
    }}}),(_vm.$route.name === 'messages.index')?_c('MessagesFragment',{attrs:{"type":"greetings","data-test-id":"messages-list"},on:{"deletingStarted":function($event){_vm.deleting_in_progress = true},"deletingEnded":function($event){_vm.deleting_in_progress = false}}}):_vm._e(),(_vm.$route.name === 'messages.create')?_c('AddMessagesFragment',{attrs:{"data-test-id":"messages-create"},on:{"deletingStarted":function($event){_vm.deleting_in_progress = true},"deletingEnded":function($event){_vm.deleting_in_progress = false}}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }