<template>
    <div>
        <div class="address-form">
            <w-autocomplete
                v-if="!all_countries"
                auto-select-first
                v-model="address.country"
                @change="on_country_change"
                :items="[{value: 'US', text: 'United States'}, {value: 'CA', 'text': 'Canada'}]"
                :placeholder="l.t('addressbook.country', 'Country')"
                :rules="[$validation.required()]"
                @input="$emit('input', address)"
                :disabled="disabled"
                class="w-100"
                autocomplete="new-password"
                data-test-id="acc-contact-country-selector"
            />
            <CountrySelector
                v-else
                v-model="address.country"
                @change="on_country_change"
                :items="[{value: 'US', text: 'United States'}, {value: 'CA', 'text': 'Canada'}]"
                :placeholder="l.t('addressbook.country', 'Country')"
                :rules="[$validation.required()]"
                @input="$emit('input', address)"
                :disabled="disabled"
                class="w-100"
                autocomplete="new-password"
                data-test-id="acc-contact-country-selector"
            />
            <w-text-field
                v-model="address.line_1"
                :placeholder="l.t('app.street-address', 'Street address')"
                :rules="[$validation.required()]"
                @input="$emit('input', address)"
                :disabled="disabled"
                class="w-100"
                autocomplete="street-address"
                required
            />
            <w-text-field
                v-model="address.line_2"
                :placeholder="l.t('app.apt-suite-etc', 'Apt, Suite, etc')"
                :disabled="disabled"
                @input="$emit('input', address)"
                class="w-100"
            />
            <div class="d-flex flex-wrap bottom-inputs">
                <w-text-field
                    v-model="address.city"
                    :placeholder="l.t('app.city', 'City')"
                    :rules="[$validation.required()]"
                    :disabled="disabled"
                    @input="$emit('input', address)"
                    class="city-input"
                    required
                />
                <USStatesSelector
                    v-if="(!all_countries && !address.country) || address.country === 'US'"
                    v-model="address.province"
                    :rules="[$validation.required()]"
                    :disabled="disabled"
                    class="state-input"
                    @input="$emit('input', address)"
                />
                <CanadaProvincesSelector
                    v-else-if="address.country === 'CA'"
                    v-model="address.province"
                    :rules="[$validation.required()]"
                    :disabled="disabled"
                    class="state-input"
                    @input="$emit('input', address)"
                />
                <w-text-field
                    v-else
                    v-model="address.province"
                    :rules="[$validation.required()]"
                    :disabled="disabled"
                    class="state-input"
                    :placeholder="l.t('app.state', 'State')"
                    @input="$emit('input', address)"
                />
                <USZipCode
                    v-if="(!all_countries && !address.country) || address.country === 'US'"
                    v-model="address.postal_code"
                    :validation="true"
                    :disabled="disabled"
                    @input="$emit('input', address)"
                    class="postal-code-input"
                    required
                />
                <CanaddianPostalCode
                    v-else-if="address.country === 'CA'"
                    v-model="address.postal_code"
                    :validation="true"
                    :disabled="disabled"
                    @input="$emit('input', address)"
                    class="postal-code-input"
                    required
                />
                <w-text-field
                    v-else
                    v-model="address.postal_code"
                    :validation="true"
                    :disabled="disabled"
                    @input="$emit('input', address)"
                    class="postal-code-input"
                    :placeholder="l.t('app.zip-code', 'Zip code')"
                    required
                />
            </div>
        </div>
    </div>
</template>
<script>
    import l from '../../../libs/lang';
    import USZipCode from '../address/USZipCode.vue';
    import USStatesSelector from '../address/USStatesSelector.vue';
    import CanaddianPostalCode from '../address/CanadianPostalCode.vue';
    import CanadaProvincesSelector from '../address/CanadaProvincesSelector.vue';
    import CountrySelector from '../CountrySelector.vue';

    export default {
        props: {
            value: {
                type: [Object, null],
            },
            disabled: {
                type: Boolean,
            },
            all_countries: {
                type: Boolean,
                default: false,
            }
        },
        components: {
            USZipCode,
            CountrySelector,
            USStatesSelector,
            CanaddianPostalCode,
            CanadaProvincesSelector,
        },
        data() {
            return {
                l,
                empty_address: {
                    line_1: '',
                    line_2: '',
                    city: '',
                    province: '',
                    postal_code: '',
                    country: 'US',
                },
                address: this.$props.value || {},
          };
        },
        methods: {
            on_country_change(val) {
                for (const key of Object.keys(this.$data.empty_address)) {
                    if (key !== 'country') {
                        this.$data.address[key] = '';
                    }
                }
                this.$data.address.country = val;
            },
        }
    };
</script>
