import Vue from 'vue';
import Alert from '../components/elements/Alert.vue';
import Loader from '../components/elements/LoaderBar.vue';
import Btn from '../components/vuetify/components/btn.vue';
import Form from '../components/vuetify/components/form.vue';
import Switch from '../components/vuetify/components/switch.vue';
import Select from '../components/vuetify/components/select.vue';
import Tooltip from '../components/vuetify/components/tooltip.vue';
import Stepper from '../components/vuetify/components/stepper.vue';
import Combobox from '../components/vuetify/components/combobox.vue';
import Checkbox from '../components/vuetify/components/checkbox.vue';
import Textarea from '../components/vuetify/components/textarea.vue';
import FileInput from '../components/vuetify/components/file-input.vue';
import TextField from '../components/vuetify/components/text-field.vue';
import RadioGroup from '../components/vuetify/components/radio-group.vue';
import Autocomplete from '../components/vuetify/components/autocomplete.vue';

Vue.component('w-btn', Btn);
Vue.component('w-form', Form);
Vue.component('w-alert', Alert);
Vue.component('w-loader', Loader);
Vue.component('w-switch', Switch);
Vue.component('w-select', Select);
Vue.component('w-tooltip', Tooltip);
Vue.component('w-stepper', Stepper);
Vue.component('w-textarea', Textarea);
Vue.component('w-checkbox', Checkbox);
Vue.component('w-combobox', Combobox);
Vue.component('w-text-field', TextField);
Vue.component('w-file-input', FileInput);
Vue.component('w-radio-group', RadioGroup);
Vue.component('w-autocomplete', Autocomplete);
