<template>
    <w-loader v-if="loading" :message="l.t('inbox.sending', 'Sending...')"/>
    <w-form v-else @submit="send" @changed="may_submit = true" data-discard="true" data-test-id="send-mess">
        <w-alert v-if="alert" :message="alert.message" :level="alert.level" :closable="!!alert.closable" class="mb-3"/>
        <div v-if="!!to && !!from">
          <w-text-field
            :rules="media.length === 0 ? [$validation.required()] : []"
            type="text"
            class="fs-exclude"
            v-model="text"
            :placeholder="l.t('inbox.input-placeholder', 'Write a message...')"
            :label="from ? $options.filters.format_phone_number(from_v['phone_number']) : ''"
            hide-details='auto'
            data-test-id="send-mess-message-input"
          />
          <div class="d-flex justify-space-between mt-3">
            <w-file-input @change="fileUploaded" multiple hide-details="auto" data-test-id="send-mess-file-input"/>
            <w-btn type="submit" color="primary" data-test-id="send-mess-submit-btn">{{ l.t("app.send", "Send") }}</w-btn>
          </div>
        </div>
        <div v-else>
          <FormInput :label="l.t('app.from', 'From')">
            <div v-if="from" class="mt-md-4">
              {{ from | format_phone_number }}
            </div>
            <APIAutocomplete
              v-else
              mode="phone_numbers"
              v-model="from_v"
              :return_object="true"
              data-test-id="send-mess-from-selector"
            />
          </FormInput>
          <FormInput :label="l.t('app.to', 'To')">
            <Tel
              v-for="(t, i) in to_v"
              @changed="to_v[i] = $event.clean_number"
              :closable="to_v.length > 1"
              @closed="$delete(to_v, i)"
              :key="`to-${i + Math.random()}`"
              :value="t"
              :validation="true"
              :required="true"
              data-test-id="send-mess-to-input"
            />
            <AddToListButton @click="to_v.push('')" data-test-id="send-mess-add-phone-btn">
              {{ l.t("app.phone-add", "Add phone number") }}
            </AddToListButton>
          </FormInput>
          <FormInput :label="l.t('app.text', 'Text')">
            <w-text-field
              v-model="text"
              :placeholder="l.t('inbox.input-placeholder', 'Write a message...')"
              hide-details='auto'
              class="fs-exclude"
              :rules="media && !media.length ? [$validation.required(null, l.t('app.required-if-media-empty', 'The field must contain at least one non-whitespace character unless media is included'))] : []"
              data-test-id="send-mess-message-input"
            ></w-text-field>
          </FormInput>
          <FormInput>
            <w-file-input
              @change="fileUploaded"
              @click:append="media = []"
              :label="l.t('app.attach-files', 'Attach files')"
              small-chips
              multiple
              hide-details="auto"
              class="fs-exclude"
              data-test-id="send-mess-files-input"
            ></w-file-input>
          </FormInput>
          <FormInput>
            <w-btn type="submit" :disabled="!may_submit" color="primary" data-test-id="send-mess-submit-btn">{{ l.t("app.send", "Send") }}</w-btn>
          </FormInput>
        </div>
    </w-form>
</template>

<script>
  import l from '../../libs/lang';
  import Helpers from '../../libs/helpers';
  import Tel from '../elements/Tel.vue';
  import FormInput from '../elements/form/FormInput.vue';
  import AddToListButton from '../elements/AddToListButton.vue';
import APIAutocomplete from '../elements/form/APIAutocomplete.vue';

  export default {
    props: ['extension_id', 'from', 'to'],
    components: {
    Tel,
    FormInput,
    AddToListButton,
    APIAutocomplete
},
    data() {
      return {
        l,
        loading: false,
        alert: null,
        text: '',
        may_submit: false,
        from_v: { phone_number: this.$props.from } || null,
        to_v: this.$props.to || [''],
        media: [],
      };
    },
    methods: {
      async fileUploaded(files) {
        try {
          if (files.length) {
            for (const file of files) {
              const filename = file.name;
              const data = await Helpers.formFileToBase64(file);
              this.$data.media = [];
              this.$data.media.push({
                filename, data,
              });
            }
          } else {
            this.$data.media = [];
          }
        } catch (err) {
          this.$data.alert = {
            message: l.t('validation.error-reading-file', 'Error reading file.'),
            level: 'error',
          };
        }
      },
      async send(e) {
        if (e) e.preventDefault();
        try {
          // TODO to - parameter format
          this.$data.loading = true;
          let to = this.$props.to ? this.$props.to : this.$data.to_v;
          to = [...new Set(to)];
          const res = await this.$session.create_item(
            `/extensions/${this.$props.extension_id}/messages`,
            {
              from: this.$props.from
                ? this.$props.from
                : this.$data.from_v['phone_number'],
              to,
              text: this.$data.text,
              media: this.$data.media,
            },
          );

          this.$data.text = '';
          this.$emit('sent', res);
          this.$data.loading = false;
        } catch (err) {
          this.$data.loading = false;
          Helpers.validation_error.bind(this)(err);
          if (this.$data.alert) this.$data.alert.closable = err.status && err.status <= 500 && ![404, 201].includes(err.status);
        }
      },
    },
  };
</script>
