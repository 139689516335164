import Resource from './Resource';
import setup from '../libs/resources-setups/queues';

export default class Queue extends Resource {
    constructor(session, emitter, changeRoute) {
        super(session, emitter, '/queues', changeRoute);
        this.selector_mode = ['queues'];
        this.setup = setup;
        this.filters = {
            name: null,
        };
        this.item = {
            name: null,
            greeting: null,
            hold_music: null,
            max_hold_time: 300,
            caller_id_type: 'calling_number',
            ring_time: 5,
            members: [],
        };
    }

    async create(queue) {
        this.loading = true;
        try {
            const newQueue = await this.session.create_item('/queues', queue);
            this.addToCache(newQueue);
            this.successfulCreation('queues.index', null, null, newQueue);
            this.item = null;
        } catch (err) {
            this.validation_error(err);
        }
        this.loading = false;
    }

    async update() {
        this.loading = true;
        try {
            this.item = await this.session.replace_item(
                `${this.baseUri}/${this.item.id}`, this.item,
            );
            this.updateCache(this.item);
            this.successfulUpdate('queues.index');
            this.item = null;
        } catch (err) {
            this.validation_error(err);
        }
        this.loading = false;
    }

    async loadItems(params) {
        this.loading = true;
        try {
            const offset = this.checkIfUserDeletedItems(params.offset, params.page);
            const items = await this.session.get_list(
                this.uri, params.limit, offset,
            );

            this.page = params.page;
            if (this.page > 1 && !items.items.length) {
                this.emitter('pageChanged', 1);
                return this.loadItems({
                    limit: this.limit,
                    offset: 0,
                    page: 1,
                });
            }
            if (this.page === 1 && !this.filters_applied && !items.items.length) {
                return this.changeRoute('queues.create');
            }

            this.items = items.items;
            this.finalizeLoadingItems(items);
            this.emitter('itemsLoaded', JSON.parse(JSON.stringify(items)));
        } catch (err) {
            this.validation_error(err);
        }
        this.loading = false;

        return this.items;
    }

    apply_filters() {
        this.loading = true;
        this.filters_applied = true;
        try {
            let uri = `${this.baseUri}?mode=full`;
            if (this.filters.name) uri = `${uri}&filters[name]=contains:${this.filters.name}`;
            this.uri = uri;
        } catch (err) {
            this.validation_error(err);
        }
        this.loading = false;
    }
}
