<template>
	<v-item-group :class="$props.root_class || 'd-flex justify-space-between'">
		<v-item
			v-for="(item, i) in items"
			v-slot="{ active, toggle }"
			class="cursor-pointer"
			:key="`tab-as-image-${i}`"
			:data-test-id="`tab-as-image-tab-${item.value}`"
		>
			<!-- <v-card
				:color="(value === item.value || (!value && !item.value)) ? 'white' : colors[i] || colors[0]"
				class="d-flex mx-1 px-4"
				:class="{
					'ml-0': i === 0,
					'mr-0': i === items.length - 1,
					'active-tab': (value === item.value || (!value && !item.value)),
					'inactive-tab': !(value === item.value || (!value && !item.value)),
				}"
				dark
				@click="tab_changed(item.value, toggle)"
				:style="{width: `${100/items.length}%`}"
				:value="item.value"
				:max-width="100"
				:max-height="100"
			> -->
				<v-scroll-y-transition>
					<div :class="{'border' : (i < (items.length-1))}">
					<div class="text-center aligment mx-1" @click="tab_changed(item.value, toggle)">
						<v-icon
						size="30"
							large
							:color="(value === item.value || (!value && !item.value)) ? '#000' : 'primary'"
							class="ml-2 mt-n1 pr-1"
							:data-test-id="`tab-as-image-tab-${item.pattern}-icon`"
						>
							{{ item.icon }}
						</v-icon>
						<div class="tab-title mr-2 ml-1 my-auto" :class="(value === item.value || (!value && !item.value)) ? 'font-text' : 'font-primary'" :data-test-id="`tab-as-image-tab-${item.value}-title`">
							{{ item.text }}
						</div>
					</div>
					</div>
				</v-scroll-y-transition>
			<!-- </v-card> -->
		</v-item>
	</v-item-group>
</template>

<script>
export default {
	props: ['items', 'value', 'root_class'],
	data() {
		return {
			colors: [
				'#217189',
				'#23758E',
				'#257C95',
				'#28839D',
				'#2A8BA4',
				'#2D91AC',
				'#2F98B4',
				'#329FBC',
			],
		};
	},
	methods: {
		tab_changed(val, cb) {
			this.$emit('change', val);
			cb();
		}
	}
};
</script>

<style scoped>
	.tab-title {
		text-align: left;
		font-size: 11px;
		font-weight: 500;
		line-height: 15px;
		letter-spacing: 0.1px;
		color: var(--v-primary) !important;
	}
	.border{
        border-right: 1px rgba(133, 146, 154, 0.65) solid;
	}
	.font-text{
		color: var(--v-text-base) !important;
	}
	.font-primary{
		color:#29921B !important;
	}
	.aligment {
	    display: flex;
        width: 115px;
		height: 30px !important;
	}
	.active-tab {
		background: #FFFFFF;
		box-shadow: 0px -5px 15px rgba(0, 0, 0, 0.3) !important;
		color:  var(--v-text-base) !important;
		position: relative;
		background-color:black;
	}
</style>
