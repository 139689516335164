import l from '../../libs/lang';
import SidebarRouter from '../../libs/sidebar-router';

export default class ReportConf {
	constructor(allowed_fields) {
		this.allowed_fields = allowed_fields;
		this.has_deleted_extensions = false;
		this._fields = {
			min_call_duration: {
				type: 'duration',
				translation: l.t('calls.min_call_duration-h-m-s-ds', 'Minimum duration (Hours : Minutes : Seconds : Tenth of seconds)'),
			},
			avg_call_duration: {
				type: 'duration',
				translation: l.t('calls.avg_call_duration-h-m-s-ds', 'Averege duration (Hours : Minutes : Seconds : Tenth of seconds)'),
			},
			max_call_duration: {
				type: 'duration',
				translation: l.t('calls.max_call_duration-h-m-s-ds', 'Maximum duration (Hours : Minutes : Seconds : Tenth of seconds)'),
			},
			min_amount: {
				type: 'price',
				translation: l.t('calls.min_amount', 'Minimal amount'),
			},
			avg_amount: {
				type: 'price',
				translation: l.t('calls.avg_amount', 'Average amount'),
			},
			max_amount: {
				type: 'price',
				translation: l.t('calls.max_amount', 'Maximal amount'),
			},
			total_amount: {
				type: 'price',
				translation: l.t('calls.estimated-cost', 'Estimated cost'),
			},
			first_time: {
				type: 'time',
				translation: l.t('calls.first_time', 'First call'),
			},
			last_time: {
				type: 'time',
				translation: l.t('calls.last_time', 'Last call'),
			},
			count_incoming: {
				type: 'number',
				translation: l.t('app.incoming-calls', 'Incoming calls'),
			},
			count_outgoing: {
				type: 'number',
				translation: l.t('app.outgoing-calls', 'Outgoing calls'),
			},
			total: {
				type: 'number',
				translation: l.t('reports.total-calls', 'Total calls'),
			},
		};
		this.logarithmic = true;
	}

	get fields() {
		const fields = {};
		for (const prop of this.allowed_fields) {
			if (this._fields[prop]) {
				fields[prop] = this._fields[prop];
			}
		}
		return fields;
	}

	static get_title(type, category) {
		const sidebar_router = new SidebarRouter();
		let reports = sidebar_router.default_routes.categories.reports.routes;
		if (type) {
			reports = reports.filter((x) => x.params && x.params.type === type);
		}
		if (category) {
			reports = reports.filter((x) => x.params && x.params.category === category);
		}
		if (reports.length === 1) {
			return reports[0].long_title;
		}

		return category;
	}

	formatTime(timestamp) {
		if (this.filters && ['npa', 'direction', 'extension'].includes(this.filters.group_by)) return timestamp;
		const time = new Date(timestamp * 1000);
		switch (this.filters.group_by) {
			case 'hour':
				return time.toLocaleString();
			case 'minute':
				return time.toLocaleString();
			default:
				return time.toLocaleDateString();
		}
	}

	// eslint-disable-next-line class-methods-use-this
	logarithmic_values(items) {
		// eslint-disable-next-line prefer-spread
		const max = Math.log(Math.max(...items));
		const logs = {};
		for (let i = 0; i < items.length; i++) {
			// eslint-disable-next-line no-continue
			if (logs[items[i]]) continue;
			if (items[i]) {
				const log = Math.log(items[i]);
				// if (max === log) {
				// 	logs[items[i]] = 100;
				// } else {
					logs[items[i]] = log / max;
				// }
			} else {
				logs[items[i]] = 0;
			}
		}
		return logs;
	}
}
