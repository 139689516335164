<template>
  <div class="vertical-table" v-bind="$attrs" v-on="$listeners">
    <div v-if="title" class="w-body-1 info--text text--darken-1 vertical-table-row">{{title}}</div>
    <div v-for="item of items" class="d-flex justify-evenly" :class="{'vertical-table-row items': !item.divider}">
      <div v-if="item.divider" class="vertical-table-divider"></div>
      <template v-else>
        <div class="w-subtitle-3 font-weight-500 info--text text--darken-1 vertical-table-title" :style="{width: '50%'}">{{item.text}}</div>
        <div class="w-caption" :style="{width: '50%'}">{{item.value}}</div>
      </template>
    </div>
    <div v-if="additional_rows" class="d-flex justify-evenly vertical-table-row items">
      <div
        v-for="slot in Object.keys(additional_rows)"
        :class="{
          'w-subtitle-3 font-weight-500 info--text text--darken-1 vertical-table-title': slot.includes('text'),
          'w-caption': slot.includes('value'),
        }"
        :style="{width: '50%'}"
      >
        <slot :name="slot" />
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    props: {
        items: {
            required: true,
            type: Array,
            validator: (val) => val.every((x) => x.divider || (Object.prototype.hasOwnProperty.call(x, 'text') && Object.prototype.hasOwnProperty.call(x, 'value'))),
        },
        title: {
            type: String,
        }
    },
    data() {
      return {
      };
    },
    computed: {
      additional_rows() {
        const additional_rows_keys = Object.keys(this.$slots).filter((x) => x.startsWith('additional_row_'));
        if (additional_rows_keys.length) {
          const slots = {};
          additional_rows_keys.map((x) => slots[x] = this.$slots[x]);

          return slots;
        }
        return null;
      },
    }
  };
</script>

<style lang="scss" scoped>
    .vertical-table {
        box-shadow: 0px -2px 0px var(--v-info-base);
        word-wrap: break-word;
        .vertical-table-row {
            padding: 8px 0px;
            align-items: center;
        }
        .items {
          box-shadow: inset 0px -1px 0px rgba(133, 146, 154, 0.2);
          height: 50px;
        }
        .vertical-table-divider {
          box-shadow: inset 0px -1px 0px var(--v-info-lighten1);
          width: 100%;
          margin-top: -1px;
        }
    }
</style>
