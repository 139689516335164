<template>
    <div class="group-contacts mt-3">
        <div data-test-id="groups-contacts">
            <SectionTitle>
              {{ l.t('groups.group-contact', 'Contacts for group') }} <span v-if="groupName">{{ groupName }}</span><span v-else>...</span>
            </SectionTitle>
            <w-alert
              v-if="contact.alert"
              :message="contact.alert.message"
              :level="contact.alert.level"
              :closable="contact.enable_close_alert"
              @closed="contact.alert = null"
            />
            <template v-else>
                <w-alert
                  v-if="contact.successful_deletions"
                  :message="contact.successful_deletions.message"
                  level="success"
                  :closable="true"
                />
                <w-alert
                  v-if="contact.failed_deletions"
                  :message="contact.failed_deletions.message"
                  level="error"
                  :closable="true"
                />
            </template>
            <div v-if="contact.loading || group.loading">
              <w-loader v-if="!contact.deleting"/>
              <ProgressBar
                v-else
                :key="contact.deleting.status.lenfth"
                :processing="contact.deleting"
                @stopProcess="contact.stopDeletion = true"
                data-test-id="groups-progressbar"
              />
            </div>
            <div v-else-if="contact.csv_downloader" data-test-id="groups-csv-downloader">
              <CsvDownloader :downloader="contact.csv_downloader"/>
            </div>
            <div v-else>
              <Filters
                v-if="!contact.hide_filters"
                @filtersApplied="applyFilters"
                @clearFilters="clearFilters"
                :showFilters="contact.filters_applied || contact.keep_filters_open"
                data-test-id="groups-filters"
              >
                <template v-slot:fields>
                  <FormInput :label="l.t('app.name', 'Name')">
                    <w-text-field v-model="contact.filters.name" hide-details='auto' data-test-id="groups-filters-name-input"/>
                    <w-switch v-model="contact.filters.exact" :label="l.t('app.exact-match', 'Exact match')" hide-details='auto' data-test-id="groups-filters-exact-switch"/>
                  </FormInput>
                  <FormInput :label="l.t('app.phone-number', 'Phone number')">
                    <w-text-field v-model="contact.filters.phone" type="number" hide-details='auto' data-test-id="groups-filters-phone-input"/>
                  </FormInput>
                </template>
                <template v-slot:additionalButtons>
                    <w-btn @click="contact.generate_csv()" type="button" color="primary" class="primary--text text--darken-1 primary lighten-5 mr-5" data-test-id="groups-filters-get-csv-btn">
                      {{ l.t("app.get-csv", "Get CSV") }}
                    </w-btn>
                </template>
              </Filters>
              <div v-if="contact.items !== null && contact.items.length">
                <v-simple-table class="list-table" data-test-id="groups-table">
                    <thead data-test-id="table-head">
                    <tr data-test-id="head-row">
                        <th data-test-id="checkbox">
                            <br/>
                        </th>
                        <th data-test-id="id">{{ l.t("app.id", "ID") }}</th>
                        <th data-test-id="name">{{ l.t("app.name", "Name") }}</th>
                        <th data-test-id="phones">
                            {{ l.t("app.phone-numbers", "Phone numbers") }}
                        </th>
                        <th data-test-id="company">{{ l.t("app.company", "Company") }}</th>
                    </tr>
                    </thead>
                    <tbody data-test-id="table-body">
                    <tr
                      v-for="(item, i) in contact.items"
                      :key="item['id']"
                      :class="{ selected: item['selected'] }"
                      :data-test-id="item.id"
                    >
                        <td data-test-id="checkbox">
                          <w-checkbox
                            v-model="item.selected"
                            @change="contact.checkSubmission()"
                            :key="`groups-item-${item.id}-${item.selected}`"
                            hide-details='auto'
                            :data-test-id="`groups-item-${i}-checkbox`"
                          />
                        </td>
                        <td data-test-id="id">
                          {{ item.id }}
                        </td>
                        <td data-test-id="link">
                            <router-link
                              v-if="$branding.data.routes.includes('contacts.show')"
                              :to="{
                                name: 'contacts.show',
                                params: { extension_id: contact.extension, id: item.id },
                              }"
                              :data-test-id="`groups-item-${i}-link`"
                            >
                              {{item | name}}
                            </router-link>
                            <template v-else>
                              {{item | name}}
                            </template>
                        </td>
                        <td v-if="item.phone_numbers.length" data-test-id="phones">
                            <template v-for="phone in item.phone_numbers">
                                {{ phone.type }}: {{ phone.number | format_phone_number }} <br/>
                            </template>
                        </td>
                        <td v-else data-test-id="empty-phones">
                            &mdash;
                        </td>
                        <td v-if="item.company" data-test-id="company">{{ item.company }}</td>
                        <td v-else data-test-id="empty-company">&mdash;</td>
                    </tr>
                    </tbody>
                </v-simple-table>
                <MassOperationButtons v-if="contact.items.length" @selected="contact.bulkDelete()" @all="contact.deleteAll()" :selected="!contact.disable_delete_button" :filters_applied="contact.filters_applied" data-test-id="groups-mas-operations">
                    <w-checkbox
                      v-model="contact.check_all"
                      :label="l.t('app.select-all', 'Select all')"
                      class="pl-4 select-all"
                      hide-details="auto"
                    />
                </MassOperationButtons>
              </div>
              <NothingFound
                v-else
                :alert="contact.alert"
                :filters_applied="contact.filters_applied"
                :everythingDeleted="contact.everythingDeleted"
                :create_route="{name: 'contacts.create'}"
                data-test-id="groups-nothing-found"
              >
              </NothingFound>
            </div>
            <NumericPagination
              v-if="contact.uri"
              :limit="contact.limit"
              :key="contact.uri"
              @paramsPrepared="contact.loadItems($event)"
              data-test-id="groups-paginations"
            />
        </div>
    </div>
</template>

<script>
  import l from '../../../libs/lang';
  import Group from '../../../models/Group';
  import Helpers from '../../../libs/helpers';
  import Contact from '../../../models/Contact';
  import Filters from '../../elements/Filters.vue';
  import ProgressBar from '../../elements/ProgressBar.vue';
  import FormInput from '../../elements/form/FormInput.vue';
  import NothingFound from '../../elements/NothingFound.vue';
  import SectionTitle from '../../elements/SectionTitle.vue';
  import CsvDownloader from '../../elements/CsvDownloader.vue';
  import NumericPagination from '../../elements/NumericPagination.vue';
  import MassOperationButtons from '../../elements/MassOperationButtons.vue';

  export default {
    components: {
      Filters,
      FormInput,
      ProgressBar,
      SectionTitle,
      NothingFound,
      CsvDownloader,
      NumericPagination,
      MassOperationButtons,
    },
    data() {
      return {
        l,
        groupName: null,
        group: new Group(this.$session, Helpers.emitter(this), Helpers.changeRoute(this)),
        contact: new Contact(this.$session, Helpers.emitter(this), Helpers.changeRoute(this)),
        one_extension: false,
      };
    },
    async created() {
      this.$data.group.loading = true;
      this.$data.group.extension = this.$route.params.extension_id;
      const group = await this.$data.group.getItem(this.$route.params.id);
      this.$data.groupName = group.name;
      this.$data.contact.extension = this.$route.params.extension_id;
      this.$data.contact.filters.group_id = this.$route.params.id;
      this.$data.contact.uri = `/extensions/${this.$route.params.extension_id}/contacts?filters[group_id]=${this.$route.params.id}`;
    },
    mounted() {
      this.$parent.$on('userRedirected', (to, from, next) => {
        this.$data.contact.handleUserRedirect(to, from, next);
      });
    },
    methods: {
      applyFilters() {
        this.$data.contact.filters.group_id = this.$route.params.id;
        this.$data.contact.apply_filters();
      },
      clearFilters() {
        this.$data.contact.clear_filters();
        this.$data.contact.uri = `/extensions/${this.$route.params.extension_id}/contacts?filters[group_id]=${this.$route.params.id}`;
        this.$data.contact.filters.group_id = this.$route.params.id;
      }
    },
    filters: {
      name(contact) {
        let name = '';
        if (contact.first_name || contact.middle_name || contact.last_name) {
          if (contact.first_name) {
            name += `${contact.first_name} `;
          }
          if (contact.middle_name) {
            name += `${contact.middle_name} `;
          }
          if (contact.last_name) {
            name += `${contact.last_name} `;
          }
        } else {
          name = '—';
        }

        return name.trim();
      }
    }
  };
</script>
