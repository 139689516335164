<template>
    <w-loader v-if="user.loading"/>
    <div v-else class="create-page" data-test-id="video-users-create-page">
        <w-alert
          v-if="user.alert"
          :message="user.alert.message"
          :level="user.alert.level"
          :closable="user.enable_close_alert"
          @closed="user.alert = null"
          class="mb-6"
        />
        <VideoUserFragment
          v-if="user.alert === null || (user.alert && user.alert.level !== 'success')"
          :_user="user.item"
          @submitted="user.create($event)"
        />
    </div>
</template>

<script>
  import l from '../../../../libs/lang';
  import Helpers from '../../../../libs/helpers';
  import VideoUser from '../../../../models/VideoUser';
  import VideoUserFragment from '../../../fragments/VideoUser.vue';

  export default {
    components: {
      VideoUserFragment,
    },
    data() {
      return {
        l,
        user: new VideoUser(this.$session, Helpers.emitter(this), Helpers.changeRoute(this)),
      };
    },
  };
</script>
