var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"address-book"},[_c('Submenu',{key:`${_vm.l.detect()}${_vm.$route.name}`,attrs:{"routes":{
      'contacts.index': {
        title: _vm.l.t('app.address-book', 'Address book'),
        subroutes: ['contacts.show']
      },
      'contacts.create': {
        title: _vm.l.t('contacts.add-contact', 'Add contact')
      },
      'contacts.import': {
        title: _vm.l.t('contacts.import-contacts', 'Import contacts')
      },
      'groups.index': {
        title: _vm.l.t('app.contact-groups', 'Contact groups'),
        subroutes: ['groups.contacts'],
      },
      'contacts.duplications': {
        title: _vm.l.t('contacts.duplications', 'Duplications')
      },
    }}}),(_vm.$route.name === 'contacts.index')?_c('ContactsScreen',{attrs:{"data-test-id":"contacts-index"},on:{"deletingStarted":function($event){_vm.deleting_in_progress = true},"deletingEnded":function($event){_vm.deleting_in_progress = false}}}):_vm._e(),(_vm.$route.name === 'groups.index')?_c('GroupsScreen',{attrs:{"data-test-id":"groups-index"},on:{"deletingStarted":function($event){_vm.deleting_in_progress = true},"deletingEnded":function($event){_vm.deleting_in_progress = false}}}):_vm._e(),(_vm.$route.name === 'contacts.import')?_c('ContactsImportScreen',{attrs:{"data-test-id":"contacts-import"}}):_vm._e(),(_vm.$route.name === 'contacts.create')?_c('ContactsCreateScreen',{attrs:{"data-test-id":"contacts-create"}}):_vm._e(),(_vm.$route.name === 'contacts.show')?_c('ContactsShowScreen',{attrs:{"data-test-id":"contacts-show"}}):_vm._e(),(_vm.$route.name === 'contacts.duplications')?_c('DuplicaionsScreen',{attrs:{"data-test-id":"contacts-duplications"}}):_vm._e(),(_vm.$route.name === 'groups.contacts')?_c('GroupContactsScreen',{attrs:{"data-test-id":"contacts-group-contact"}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }