var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"menus"},[_c('Submenu',{key:`${_vm.l.detect()}${_vm.$route.name}`,attrs:{"routes":{
      'menus.index': {
        title: _vm.l.t('app.menus', 'Menus'),
        subroutes: ['menus.show']
      },
      'menus.create': {
        title: _vm.l.t('menu.add-menu', 'Add menu')
      },
    }}}),(_vm.$route.name === 'menus.index')?_c('MenusFragment',{attrs:{"data-test-id":"menus-index"},on:{"deletingStarted":function($event){_vm.deleting_in_progress = true},"deletingEnded":function($event){_vm.deleting_in_progress = false}}}):_vm._e(),(_vm.$route.name === 'menus.create')?_c('MenusCreateFragment',{attrs:{"data-test-id":"menus-create"}}):_vm._e(),(_vm.$route.name === 'menus.show')?_c('MenusShowFragment',{attrs:{"data-test-id":"menus-show"}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }