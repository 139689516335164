<template>
    <div class="address-book">
      <Submenu
        :routes="{
          'contacts.index': {
            title: l.t('app.address-book', 'Address book'),
            subroutes: ['contacts.show']
          },
          'contacts.create': {
            title: l.t('contacts.add-contact', 'Add contact')
          },
          'contacts.import': {
            title: l.t('contacts.import-contacts', 'Import contacts')
          },
          'groups.index': {
            title: l.t('app.contact-groups', 'Contact groups'),
            subroutes: ['groups.contacts'],
          },
          'contacts.duplications': {
            title: l.t('contacts.duplications', 'Duplications')
          },
        }"
        :key="`${l.detect()}${$route.name}`"
      />
      <ContactsScreen
        v-if="$route.name === 'contacts.index'"
        @deletingStarted="deleting_in_progress = true"
        @deletingEnded="deleting_in_progress = false"
        data-test-id="contacts-index"
      />
      <GroupsScreen
        v-if="$route.name === 'groups.index'"
        @deletingStarted="deleting_in_progress = true"
        @deletingEnded="deleting_in_progress = false"
        data-test-id="groups-index"
      />
      <ContactsImportScreen
        v-if="$route.name === 'contacts.import'"
        data-test-id="contacts-import"
      />
      <ContactsCreateScreen
        v-if="$route.name === 'contacts.create'"
        data-test-id="contacts-create"
      />
      <ContactsShowScreen
        v-if="$route.name === 'contacts.show'"
        data-test-id="contacts-show"
      />
      <DuplicaionsScreen
        v-if="$route.name === 'contacts.duplications'"
        data-test-id="contacts-duplications"
      />
      <GroupContactsScreen
        v-if="$route.name === 'groups.contacts'"
        data-test-id="contacts-group-contact"
      />
    </div>
</template>

<script>
  import l from '../../../libs/lang';
  import ContactsScreen from './index.vue';
  import ContactsShowScreen from './show.vue';
  import GroupsScreen from '../groups/index.vue';
  import ContactsCreateScreen from './create.vue';
  import ContactsImportScreen from './import.vue';
  import Submenu from '../../elements/Submenu.vue';
  import DuplicaionsScreen from './duplications.vue';
  import GroupContactsScreen from '../groups/contacts.vue';

  export default {
    components: {
      Submenu,
      GroupsScreen,
      ContactsScreen,
      DuplicaionsScreen,
      ContactsShowScreen,
      GroupContactsScreen,
      ContactsImportScreen,
      ContactsCreateScreen,
    },
    data() {
      return {
        l,
        deleting_in_progress: false,
      };
    },
    beforeRouteLeave(to, from, next) {
      if (window.deleting_in_progress) {
        this.$emit('userRedirected', to, from, next);
      } else {
        next();
      }
    },
  };
</script>
