<template>
    <div class="homepage" data-test-id="homepage">
      <w-loader v-if="loading"/>
      <v-container v-else fluid class="homepage-container">
        <v-row>
          <v-col lg="5" md="5" sm="12" class="pr-0">
            <v-spacer></v-spacer>
            <div class="image-container float-lg-right float-md-right" :class="{'mx-auto': $vuetify.breakpoint.smAndUp}" data-test-id="homepage-left-image"></div>
          </v-col>
          <v-col lg="7" md="7" sm="12" class="pl-lg-0 pl-md-0">
            <div class="information-container text-sm-center float-lg-left text-md-left" :class="{'mx-auto': $vuetify.breakpoint.md}">
              <div class="main-information pb-5" data-test-id="homepage-main-info">
                <div v-if="!use_token && $session && !$session.user" @click="use_token = true" class="sign-in-token d-block font-weight-600 cursor-pointer" data-test-id="use-token-link">
                    {{ l.t("app.sign-in-using-access-token", "Sign in using access token") }}
                </div>
                 <div v-if="use_token && $session" @click="use_token = false" class="sign-in-token d-block font-weight-600 cursor-pointer" data-test-id="use-token-link">
                    {{ l.t("app.sign-in-using-email-or-username", "Sign in using email or username") }}
                </div>
                <div class="w-body-1" data-test-id="homepage-configure">
                  {{ l.t('homepage.configure-phone-system', ' Configure your phone system') }}
                </div>
                <div class="main-title" data-test-id="homepage-main-title">
                  {{ l.t('app.advanced-settings', 'Advanced settings') }}
                </div>
                <div v-if="$session && $session.user && $session.user.account" class="user-details caption font-weight-medium" data-test-id="homepage-user-details">
                  <div data-test-id="homepage-account-name">
                    {{ $session.user.account.name }}
                  </div>
                  <div data-test-id="homepage-account-username">
                    {{ $session.user.account.username }}
                  </div>
                  <div v-if="$session.user.is_subaccount" data-test-id="homepage-switch-to-main-account">
                    <a @click="$global_emitter.$emit('use_main_account')">{{ l.t('app.switch-to-main-account', 'Switch to main account') }}</a>
                  </div>
                  <div v-if="$session.user.subaccounts_owner_is_subaccount" data-test-id="homepage-switch-to-main-account">
                    <a @click="$global_emitter.$emit('subaccounts_owner_user_main_account')">{{ l.t('app.switch-to-main-account', 'Switch to main account') }}</a>
                  </div>
                </div>
                <div v-else class="auth-container mt-2 pr-7" :class="{'mx-auto': $vuetify.breakpoint.xs}" data-test-id="homepage-login-container">
                  <div>
                    <w-loader v-if="loading" size="small" :message="loading_message ? loading_message : null"/>
                    <form
                      v-else-if="use_token"
                      @submit.prevent="$emit('token_submitted')"
                      class="pa-0 ma-0 fixedHeight"
                      data-test-id="use-token-form"
                    >
                       <w-text-field
                         v-model="token"
                         :placeholder="l.t('app.access-token', 'Access token')"
                         class="tight-input fs-exclude"
                         hide-details='auto'
                         data-test-id="token-input"
                       />
                       <div class="d-flex mt-3">
                         <w-btn type="submit" class="white--text px-8 mt-2 font-weight-600" color="text" small width="150" data-test-id="submit-token">{{ l.t("app.sign-in", "Sign in") }}</w-btn>
                       </div>
                    </form>
                    <div class="text-center sing-in-container" v-else>
                      <w-btn color="text" :href="oauth_url" class="white--text mt-4 px-12 font-weight-600" small width="150" data-test-id="oauth-sign-in">
                        {{ l.t('app.sign-in', 'Sign in') }}
                      </w-btn>
                    </div>
                  </div>
                </div>
              </div>
              <div id="version-and-powered" :class="{'mx-auto': $vuetify.breakpoint.sm}" class="d-flex mt-3">
                <div>{{ l.t('app.powered-by', 'Powered by') }} <span class="pdc">Phone.com</span></div>
                <div id="app-version" class="ml-5">{{ version }}</div>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
</template>

<script>
  import l from '../../libs/lang';
  import Helpers from '../../libs/helpers';

  export default {
    props: ['oauth_url'],
    data() {
      return {
        l,
        loading: false,
        loading_message: null,
        use_token: false,
        token: null,
        version: '',
      };
    },
    created() {
      this.$data.loading = true;
      const version = Helpers.get_app_version();
      if (version) this.$data.version = version.long;
      this.$data.loading = false;
    },
    watch: {
      token(val) {
        this.$emit('token_changed', val);
      },
    },
    filters: {
      phone_format(val) {
        return Helpers.format_phone_number(val);
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import '~vuetify/src/styles/settings/_variables';
    .fixedHeight.v-input__slot {
      height: 40px !important;
      max-height: 40px !important;
    }
  .sign-in-token {
    color: #29921B !important;
    float:right;
    margin-top: -30px !important;
    margin-right: 30px !important;
  }
  .tight-input {
    width: 250px;
  }
  .homepage {
    .v-text-field {
      margin-top: 0;
    }
    .homepage-container{
      margin-top: 143px;
      display: flex;
      background: #FFF;
      .image-container {
        width: 330px;
        height: 320px;
        border-radius: 24px;
        background: url('./../../assets/images/girl-with-phone.png');
        background-repeat: no-repeat;
        background-size: cover;
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        background-position: center;
      }
      .information-container {
          margin-top: 35px;
          margin-bottom: 5px;
          display: flex;
          width: 100%;
          justify-content: center;
          flex-direction: column;
          margin-left: 10px;
          width: 572px;
        #version-and-powered {
          padding-left: 90px;
          font-size: 10px !important;
          color: var(--v-text-lighten1);
          line-height: 16px;
          .pdc {
            color: #29921b;
          }
        }
        .main-information {
          height: 250px;
          min-height: 250px;
          border-radius: 24px;
          width: 100%;
          height: 100%;
          background-image: url('./../../assets/images/background-white.png');
          background-size: cover;
          padding-left: 90px;
          padding-top: 52px;
          .main-title {
            font-size: 30px;
          }
          .user-details {
            margin-top: 30px;
          }
        }
        .auth-container {
          height: 50px;
        }
        .sing-in-container {
          width: 150px !important;
        }
      }
    }
  }
  @media only screen and (max-width: #{map-get($grid-breakpoints, 'md')}) {
     .sign-in-token {
      float:none;
    }
    .fixedHeight.v-input__slot {
      height: 40px;
      max-height: 40px;
    }
    .homepage {
      .homepage-container{
        margin-top: 15px;
        .image-container {
          width: 300px;
          height: 320px;
        }
        .information-container {
          margin-top: 0;
          width: 100%;
          #version-and-powered {
            padding: 5px;
            font-size: 10px !important;
            color: var(--v-text-lighten1);
            line-height: 16px;
          }
          .main-information {
            background-image: none !important;
            padding: 5px;
            margin: 0 auto;
          }
        }
      }
    }
  }
</style>
