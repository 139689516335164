<template>
  <DefaultModal max-width="500" v-bind="$attrs" v-on="$listeners" @close="on_close">
    <template v-if="!call_an_expert" v-slot:title>
        {{l.t('lr.confirm-additional-charges', 'Confirm additional charges')}}
    </template>
    <div v-if="!call_an_expert">
        <div class="d-flex justify-space-between mt-4">
            <div class="w-body-2 info--text text--darken-2">
                1 x {{ l.t('lr.additional-numbers-monthly-fee', 'Additional numbers - monthly fee') }}
            </div>
            <div>
                ${{code.price}}
            </div>
        </div>
        <v-divider class="light-divider my-5"></v-divider>
        <div class="w-body-2">
            <div>{{l.t('lr.your-script-will-be-ready', 'Your script will be ready for use in your call handling rules. As soon as you apply the script to the call handling rules for any phone number, your live receptionist will begin answering calls.')}}</div>
            <div class="mt-4 font-weight-600">
                {{l.t('lr.are-you-sure-enable-script-and-begin', 'Are you sure you want to enable this script and begin incurring charges for your Receptionist service?')}}
            </div>
            <div class="mt-4">
                {{l.t('lr.if-your-account-is-set-up-for-annual-billing', 'If your account is set up for annual billing, you will be invoiced for the prorated amount for this month and the number of months remaining in your billing cycle.')}}
            </div>
        </div>
        <div class="d-flex justify-center">
            <w-btn @click="on_expert" color="primary" class="my-7" text>{{l.t('lr.Id-like-an-expert', 'I’d like an expert to call me and tell me more')}}</w-btn>
        </div>
        <div class="d-flex justify-center">
            <w-btn @click="on_close" color="secondary" class="mr-5">{{l.t('app.cancel', 'Cancel')}}</w-btn>
            <w-btn @click="on_continue" color="primary">{{l.t('app.save&continue', 'Save & continue')}}</w-btn>
        </div>
    </div>
    <div class="d-flex flex-column align-center justify-center call-expert-container" v-else>
        <v-icon size="46" color="primary">$vuetify.icons.phone_com</v-icon>
        <div class="mt-3 w-body-2">
            {{l.t('lr.thanks-will-be-in-touch', 'Thank you! We will be in touch with you shortly.')}}
        </div>
    </div>
  </DefaultModal>
</template>
<script>
import l from '../../../libs/lang';
import Helpers from '../../../libs/helpers';
import LiveAnswer from '../../../models/LiveAnswer';
import InfoLink from '../../elements/InfoLink.vue';
import DefaultModal from '../../elements/modal/DefaultModal.vue';

  export default {
    props: {
        item: {
            type: Object,
            required: true,
        },
    },
    components: {
        InfoLink,
        DefaultModal,
    },
    data() {
      return {
        l,
        call_an_expert: false,
      };
    },
    methods: {
        async on_expert() {
            const FORM_ID = '38c92250-47e6-4ba8-a35a-f974f30ed72e';
            await Helpers.submit_hubspot_form(this.$session.user.account, FORM_ID);
            this.$data.call_an_expert = true;
            this.$emit('call_an_expert', true);
            setTimeout(() => {
                this.$data.call_an_expert = false;
            }, 3000);
        },
        async on_close() {
            this.$emit('close');
            const FORM_ID = 'fa575100-109d-461a-a7d4-27a311948277';
            await Helpers.submit_hubspot_form(this.$session.user.account, FORM_ID);
        },
        async on_continue() {
            this.$emit('continue');
            const FORM_ID = '5b7eed00-05a3-4cce-ab88-15edcc36b7d6';
            await Helpers.submit_hubspot_form(this.$session.user.account, FORM_ID);
        },
    },
    computed: {
        code() {
            if (!this.item.code) return false;
            const la = new LiveAnswer(this.$session);
            return la.codes_translations[this.item.code];
        }
    }
  };
</script>
<style lang="scss">
.call-expert-container {
    min-height: 350px;
}
</style>
