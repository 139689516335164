<template>
  <v-switch
    v-model="val"
    v-bind="$attrs"
    color="primary"
    v-on="$listeners"
    class="custom-switch"
    :ripple="false"
    inset
    dense
    hide-details="auto"
    @change="changed"
  >
    <!-- Pass on all named slots -->
    <slot v-for="slot in Object.keys($slots)" :name="slot" :slot="slot"/>
    <!-- Pass on all scoped slots -->
    <template v-for="slot in Object.keys($scopedSlots)" :slot="slot" slot-scope="scope"><slot :name="slot" v-bind="scope"/></template>
  </v-switch>
</template>
<script>
  import EventBus from '../../../libs/EventBus';

  export default {
    props: ['value'],
    data() {
      return {
        val: !!this.$props.value,
      };
    },
    watch: {
      val() {
        this.$emit('input', this.$data.val);
        this.$emit('change', this.$data.val);
      },
  },
  methods: {
    changed() {
      EventBus.$emit('form_input_changed');
    }
  }
  };
</script>

<style lang="scss">
.custom-switch {
      $thumb-px: 13px;
      $original-track-width: 44px;
      $original-track-height: 22px;
      $custom-track-width: 30px;
      $custom-track-height: 15px;
      max-width: fit-content !important;
      .v-input--switch__track {
        opacity: 1;
      }
      .v-label{
        // margin-left: $custom-track-width - $original-track-width;
        margin-left: -25px;
      }
      .v-input--switch__thumb.theme--light.primary--text {
        color: #FFF !important;
      }
      .theme--light.v-input--switch .v-input--switch__track:not(.primary--text) {
        color: var(--v-secondary-base) !important;
      }
      .v-input--switch__track {
        height: $custom-track-height !important;
        width: $custom-track-width !important;
        margin-top: 4px;
      }
      .v-input--switch__thumb {
        height: $thumb-px !important;
        width: $thumb-px !important;
      }
      .v-input--switch__thumb {
        margin-top: 4px;
        margin-left: -1px;
        top: calc(50% - $original-track-height/2);
        &.primary--text {
          margin-left: -8px;
        }
      }
  }
</style>
