import l from '../libs/lang';
import Helpers from '../libs/helpers';

export default class PDFExporter {
	constructor(element_id, filename, title, session, document_note) {
		this.element_id = element_id || 'pdf-export';
		this.name = filename;
		this.title = title;
		this.styleTag = this.generateDocumentStyle();
		this.session = session;
		this.document_note = document_note;
		this.documentTitle = window.document.title;
	}

	async printSaveWindowAsPdf() {
		const account = await Helpers.get_account_information(this.session);
		const our_html = document.getElementById(this.element_id);
		if (!our_html) {
			throw l.t('reports.tag-not-found', `Element with the ID ${this.element_id}  could not be found`);
		}
		const element = our_html.cloneNode(true);
		element.querySelectorAll('.dont-export-in-pdf').forEach((e) => e.remove());
		const pdf = element.innerHTML;
        let win = window.open('', '', 'height=700,width=700');
        win.document.write(
			`<html>
        		<head>
        			<title>
        				${account.name}/#${account.id} - 
        				${this.name}
        			</title>
        			<style>${this.styleTag}</style>
        		</head>
        		<body>
        		<body>
        			<div id="app">
        				<div id="main-container">
        					<main id="exporter-main" style="background:#ffffff;">
        						${this.title ? `<h1>${this.title}</h1>` : ''}
        						${this.document_note ? `<p class="help">${this.document_note}</p>` : ''}
        						${pdf}
							</main>
						</div>
					</div>
				</body>
			</html>`
        );
        win.print();
        win.document.close();
        win.onafterprint = () => {
			win.close();
			win = undefined;
        };
	}

	async printWindowAsPDF() {
		try {
			const account = await Helpers.get_account_information(this.session);
			window.document.title = `${account.name}/#${account.id} - ${this.name}`;
			const doc_title = document.createElement('h1');
			doc_title.textContent = this.name;
			doc_title.style.marginLeft = '5px';
			const main = window.document.getElementsByTagName('main')[0];
			main.appendChild(doc_title);
			doc_title.setAttribute('id', 'pdf-doc-title');
			main.prepend(doc_title);
	        window.print();
			window.document.title = this.documentTitle;
			document.getElementById('pdf-doc-title').remove();
		} catch (err) {
			window.document.title = this.documentTitle;
			document.getElementById('pdf-doc-title').remove();
			throw err;
		}
	}

	generateDocumentStyle() {
		if (this.styleTag) return this.styleTag;

		let style = [...document.styleSheets];
		try {
			style = style.map((styleSheet) => {
			    try {
			      return [...styleSheet.cssRules]
			        .map((rule) => rule.cssText)
			        .join('');
			    } catch (e) {
			      console.log('Access to stylesheet %s is denied. Ignoring...', styleSheet.href);
			    }

			    return false;
			  })
			  .filter((x) => x)
			  .join('\n');
		} catch (err) {
			console.log(err);
		}
	    this.styleTag = style;

	    return this.styleTag;
	}
}
