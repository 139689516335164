<template>
  <IndexPage
    :resource="order"
    :disable_add_new="true"
    :disable_filters="true"
    :disable_mass_operations="true"
    class="list-page orders"
    data-test-id="orders-list"
  >
    <template v-slot:list>
      <div class="w-h5 d-flex justify-center mb-7">{{l.t('orders.order-history', 'Order history')}}</div>
      <v-simple-table class="list-table" data-test-id="orders-table">
        <thead data-test-id="table-head">
          <tr data-test-id="head-row">
            <th data-test-id="order-number">{{l.t('orders.order-number', 'Order number')}}</th>
            <th data-test-id="status">{{l.t('orders.status', 'Status')}}</th>
            <th data-test-id="shipping-method">{{l.t('app.shipping-method', 'Shipping method')}}</th>
            <th data-test-id="contact">{{l.t('app.contact', 'Contact')}}</th>
            <th data-test-id="number-of-devices">{{l.t('orders.items', 'Items')}}</th>
            <th data-test-id="tracking-number">{{l.t('orders.tracking-number', 'Tracking number')}}</th>
          </tr>
        </thead>
        <tbody data-test-id="table-body">
          <tr v-for="(item, i) in order.items" :key="item.id" :data-test-id="item.id">
            <td data-test-id="link">
              <router-link
                tag="a"
                :to="{
                  name: 'devices.my-orders.show',
                  params: { id: item.id, _order: item }
                }"
                class="list-link"
                :data-test-id="`orders-item-${i}-link`"
              >
                {{ item.id }}
              </router-link>
            </td>
            <td data-test-id="status">
              {{order.status_translations[item.status] || item.status}}
              &nbsp;
              <span class="text--text text--lighten-1">{{format_date(item.status_date)}}</span>
            </td>
            <td data-test-id="shipping-method">
              {{ order.formatShippingMethod(item.shipping_method) }}
            </td>
            <td data-test-id="contact">
              {{ item.contact ? item.contact.name : '—' }}
            </td>
            <td data-test-id="devices">{{ item.devices ? item.devices.length : 0 }}</td>
            <td data-test-id="tracking-number">
              <a
                v-if="item.tracking_number"
                :href="`https://www.fedex.com/fedextrack/?tracknumbers=${item.tracking_number}`"
                target="_blank"
                class="w-body-2"
                data-test-id="tracking-number-link"
              >
                {{item.tracking_number}}
              </a>
              <span v-else class="w-body-2 text--text text--lighten-1" data-test-id="available-after-shipping">
                {{l.t('orders.available-after-shipping', 'Available after shipping')}}
              </span>
            </td>
          </tr>
        </tbody>
      </v-simple-table>
      <div class="text-center w-caption mt-8">
        {{l.t('orders.to-make-changes', 'To make any changes to your order, please contact customer support.')}}
      </div>
    </template>
  </IndexPage>
</template>

<script>
  import l from '../../../../libs/lang';
  import Order from '../../../../models/Order';
  import Helpers from '../../../../libs/helpers';
  import IndexPage from '../../../elements/IndexPage.vue';

  export default {
    components: {
      IndexPage,
    },
    data() {
      return {
        l,
        order: new Order(this.$session, Helpers.emitter(this), Helpers.changeRoute(this)),
      };
    },
    async mounted() {
      await this.$data.order.loadShippingMethods();
      this.$parent.$on('userRedirected', (to, from, next) => {
        this.$data.order.handleUserRedirect(to, from, next);
      });
    },
    methods: {
      format_date(val) {
        const date = new Date(val * 1000);
        return date.toLocaleString('en-US', {
            day: 'numeric',
            month: 'long',
            year: 'numeric'
        });
      }
    },
  };
</script>
