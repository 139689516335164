<template>
    <w-autocomplete
        v-model="val"
        v-bind="$attrs"
        v-on="$listeners"
        :items="ringing_time_items"
        update:search-input
        class="mb-2"
        hide-details="auto"
    />
</template>
<script>
import l from '../../../libs/lang';

export default {
    props: {
        value: {
            type: [Number, null],
            default: null,
        }
    },
    data() {
        const one_ring_in_seconds = 6;
        return {
            val: null,
            one_ring_in_seconds,
        };
    },
    created() {
        this.$data.val = (this.$props.value && !(this.$props.value < 30)) ? this.$props.value : 30;
    },
    methods: {
        rings_to_seconds(num) {
            // A ring is about 6 sec
            const ring = 6;
            if (num <= ring) return 1;
            return Math.round(num / ring);
        },
        ringing_time_with_seconds(start, end, step) {
            if (start > end) return [];
            if (start >= end) {
                return [{
                'value': start,
                'text': `${start} ${l.t('app.sec', 'sec')} (${this.rings_to_seconds(start)} ${l.t('app.rings', 'rings')})`,
                }];
            }
            const arr = [];
            for (let i = start; i <= end; i += step || 1) {
                const rings = this.rings_to_seconds(i);
                arr.push({
                'value': i,
                'text': `${i} ${l.t('app.sec', 'sec')} (${rings} ${rings === 1 ? l.t('app.ring', 'ring') : l.t('app.rings', 'rings')})`,
                });
            }
            return arr;
        },
    },
    computed: {
        ringing_time_items() {
            const current = this.$data.val;
            const rings = this.ringing_time_with_seconds(30, 90, this.$data.one_ring_in_seconds);
            if (current && !rings.find((x) => x.value === current)) {
                const current_rings = this.rings_to_seconds(current);
                const new_timeout_value = current_rings * this.$data.one_ring_in_seconds;
                this.$data.val = new_timeout_value;
                if (!rings.find((x) => x.value === new_timeout_value)) {
                    rings.push({
                    'value': new_timeout_value,
                    'text': `${new_timeout_value} ${l.t('app.sec', 'sec')} (${current_rings} ${current_rings === 1 ? l.t('app.ring', 'ring') : l.t('app.rings', 'rings')})`,
                    });
                    rings.sort((a, b) => a.value - b.value);
                }
            }
            return rings;
      },
    },
};
</script>
