<template>
	<div>
		 <div class="w-h5 mb-3 d-flex justify-center step-title">
            <p>{{ l.t('app.configure-the-phones-youre-ordering', 'Configure the phones you\'re ordering') }}</p>
        </div>
		<w-alert v-if="!current_cart.length" class="mt-10" level="error" :message="l.t('devices.please-select-devides-first', 'Please select your devices')" />
		<v-simple-table v-else class="w-100">
			<thead>
				<tr>
					<th class="w-30">
						{{ l.t('app.model', 'Model') }}
					</th>
					<th class="w-30">
						{{ l.t('devices.ext-assigned-to', 'Ext / Assigned to') }}
					</th>
					<th>
						{{ l.t('devides.emergency-e911-address', 'Emergency E911 address') }}
					</th>
				</tr>
			</thead>
			<tbody>
				<tr v-for="(device, i) of current_cart" :key="`device-${device._original_index}-${device.id}`">
					<td>
						<div class="d-flex">
							<div :style="{width: '45px'}">
								<v-img
								  :src="`https://control.phone.com/images/lang/icon_voip_device_${device.code}.jpg`" alt=""
								  height="32"
								  width="40"
								  contain
								  :data-test-id="`buy-device-item-${i}-image`"
								/>
							</div>
							<div class="my-auto">
								{{ device.manufacturer }} {{ device.name }}
							</div>
						</div>
					</td>
					<td>
						<w-select
							:items="filter_extensions(i)"
							:placeholder="l.t('selector.select-user-extension', 'Select User/Extension')"
							:value="device._custom_setup.lines.length ? device._custom_setup.lines[0].extension.id : null"
							 @change="add_extension_if_not_assigned(i, $event)"
							:data-test-id="`device-buy-setup-phones-${i}-extensions-selector`"
							:key="`ext-select-input-${!!confirm_extension_assignment}`"
						/>
					</td>
					<td>
						<E911AddressModal
							v-model="device._custom_setup.address"
							:ref="`address_modal_${i}`"
							:validation="true"
							:extension_selected="!device.address && device._custom_setup.lines.length > 0"
							:invalid_address="(device.errors && Object.values(device.errors).length > 0)"
							:trigger_btn_color="device.errors ? 'error' : undefined"
							@validation_failed="address_validation_failed(i, $event)"
							@input="address_input(i)"
							@removed="remove_item(i)"
							@closed="force_enable_next = true"
						/>
						<div  class="validation mt-n1" v-if="device.errors">
							<ValidationError v-for="error of Object.values(device.errors)">
								{{ error }}
							</ValidationError>
						</div>
					</td>
				</tr>
				<v-divider/>
			</tbody>
		</v-simple-table>
		<StepperButtons
			:disable_continue="disable_continue()"
			@continue="submitted"
			@back="back"
			:key="`submit-${current_cart.length}`"
		/>
		<DefaultModal v-if="confirm_extension_assignment" :value="true" @close="confirmation_modal_closed" max-width="500">
			<template v-slot:title>{{l.t('devices.assign-phone-to', 'Assign phone to {}', [confirm_extension_assignment.name])}}</template>
			{{l.t('devices-already-0has-phone-assigned-by-continuing', '{} already has a phone assigned. By continuing, the current phone will no longer be operational. While waiting for the new device to arrive, users can always make and receive calls on my.phone.com, or via the Phone.com iOS or Android apps.', [confirm_extension_assignment.name])}}
			<template v-slot:buttons>
				<w-btn @click="confirmation_modal_closed" color="secondary">{{l.t('app.cancel', 'Cancel')}}</w-btn>
				<w-btn @click="assignment_confirmed" color="primary">{{l.t('app.assign-phone', 'Assign phone')}}</w-btn>
			</template>
		</DefaultModal>
	</div>
</template>

<script>
import l from '../../../../libs/lang';
import E911AddressModal from '../../../elements/E911AddressModal.vue';
import StepperButtons from '../../../elements/StepperButtons.vue';
import DefaultModal from '../../../elements/modal/DefaultModal.vue';
import Cachier from '../../../../libs/Cachier';
import ValidationError from '../../../elements/form/FieldValidationError.vue';

export default {
	props: {
		cart: {
			required: true,
			type: Array,
		},
		extensions: {
			required: true,
			type: Array,
		}
	},
	components: {
		E911AddressModal,
		DefaultModal,
		StepperButtons,
		ValidationError
	},
	data() {
		return {
			l,
			type: null,
			current_cart: [],
			address_modal: null,
			confirm_extension_assignment: null,
			cachier: new Cachier(this.$session.user.id),
			force_enable_next: false,
		};
	},
	created() {
		this.put_keys_on_items(this.$props.cart);
	},
	methods: {
		options(i) {
			return [
				{
				cb: () => this.remove_item(i),
				title: l.t('app.remove-device', 'Remove device'),
				}
			];
		},
		put_keys_on_items(c) {
			const cart = [...c];
			for (let i = 0; i < cart.length; i++) {
				cart[i]._original_index = i.toString(); // because of deleting same device with different extensions
			}
			this.$data.current_cart = cart;
		},
		back() {
			this.$emit('back', this.clean_cart());
		},
		disable_continue() {
			if (this.$data.force_enable_next) return false;
			if (!this.$data.current_cart.length) return true;
			if (this.$data.current_cart.find((x) => x.errors && Object.keys(x.errors).length)) return true;
			if (this.$data.current_cart.find((x) => !x._custom_setup.address || !x._custom_setup.lines.length)) return true;
			return false;
		},
		submitted() {
			if (this.$data.current_cart.find((x) => x.errors && Object.keys(x.errors).length)) {
				this.$data.force_enable_next = false;
				return null;
			}
			const valid_form = this.validate_form();
			if (valid_form) {
				this.$emit('submitted', this.clean_cart());
			}
			return this.$forceUpdate();
		},
		validate_form() {
			let valid_form = true;
			for (const device of this.$data.current_cart) {
				device.errors = {};
				const lines_validation = this.validate_lines(device._custom_setup.lines);
				if (lines_validation) device.errors.lines = lines_validation;

				const address_validation = this.validate_address(device._custom_setup.address);
				if (address_validation) device.errors.address = address_validation;

				if (Object.keys(device.errors).length) {
					valid_form = false;
					device.error = l.t('app.enter-valid-address', 'Enter valid address');
				}
			}

			return valid_form;
		},
		remove_item(index) {
			if (index > -1) {
				this.$data.current_cart.splice(index, 1);
				// const addresses = [];
				// this.$data.current_cart.map((cart) => addresses.push(
				// 	{...cart._custom_setup.address}
				// ));
				// this.$data.cachier.setItem('step_two_address', addresses);
				this.$emit('updated', this.clean_cart());
				if (!this.$data.current_cart.length) {
					this.$data.force_enable_next = false;
				}
				this.put_keys_on_items(this.$data.current_cart);
			}
		},
		clean_cart() {
			const clean_cart = this.$data.current_cart.map((x) => {
				delete x._original_index;
				delete x.errors;
				return x;
			});

			return clean_cart;
		},
		validate_address(address) {
			const error = l.t('app.enter-valid-address', 'Enter valid address');
			if (!address) {
				return error;
			}
			if (['line_1', 'city', 'postal_code', 'country'].find((x) => !address[x])) {
				return error;
			}

			return false;
		},
		validate_lines(lines) {
			const error = l.t('app.assign-phone-to-user', 'Assign the phone to the user');
			if (!lines) return error;
			if (!lines.length) return error;
			if (!lines[0].extension) return error;
			if (!lines[0].extension.id) return error;

			return false;
		},
		add_extension_if_not_assigned(index, id) {
			const extension = this.$props.extensions.find((x) => x.id === id);
			if (
				extension
				&& extension.device_membership
				&& extension.device_membership.device
				&& extension.device_membership.device.type !== 'generic'
			) {
				this.$data.confirm_extension_assignment = {
					id,
					index,
					name: extension.name
				};
			} else {
				this.assign_extension(index, id);
				setTimeout(() => {
				if (!this.$data.current_cart[index]._custom_setup.address) {
					const ref = `address_modal_${index}`;
					if (this.$refs[ref] && this.$refs[ref].length) this.$refs[ref][0].show_modal();
				}
			}, 100);
			}
			return true;
		},
		assign_extension(index, id) {
			this.$data.current_cart[index]._custom_setup.lines = [];
			this.$data.current_cart[index]._custom_setup.lines[0] = {
				'line': 1,
				'extension': { id },
			};
			if (this.$data.current_cart[index].errors) {
				delete this.$data.current_cart[index].errors.lines;
			}
			this.$forceUpdate();
		},
		confirmation_modal_closed() {
			this.$data.confirm_extension_assignment = null;
			this.$forceUpdate();
		},
		assignment_confirmed() {
			this.assign_extension(this.$data.confirm_extension_assignment.index, this.$data.confirm_extension_assignment.id);
			if (!this.$data.current_cart[this.$data.confirm_extension_assignment.index]._custom_setup.address) {
				const ref = `address_modal_${this.$data.confirm_extension_assignment.index}`;
				if (this.$refs[ref] && this.$refs[ref].length) this.$refs[ref][0].show_modal();
			}
			this.$data.confirm_extension_assignment = null;
		},
		address_input(index) {
			const addresses = [];
			// eslint-disable-next-line array-callback-return
			this.$data.current_cart.map((item) => {
			if (item._custom_setup.address && Object.values(item._custom_setup.address).length) addresses.push({...item._custom_setup.address});
			});
			const uniqueArray = addresses.filter((value, i) => {
				const _value = JSON.stringify(value);
				return i === addresses.findIndex((obj) => JSON.stringify(obj) === _value);
			});
			this.$data.cachier.setItem('step_two_address', uniqueArray);
			if (this.$data.current_cart[index].errors) {
				delete this.$data.current_cart[index].errors.address;
				this.$forceUpdate();
			}
		},
		filter_extensions(index) {
			let extensions = this.$props.extensions.map((x) => ({ value: x.id, text: `${x['extension']}: ${x['name']}` }));
			const in_use = this.$data.current_cart.map((x, i) => {
				if (x && i !== index && x._custom_setup && x._custom_setup.lines.length) {
					return x._custom_setup.lines[0].extension.id;
				}

				return null;
			}).filter((x) => x);

			if (in_use.length) {
				extensions = extensions.filter((x) => !in_use.includes(x.value));
			}
			return extensions;
		},
		address_validation_failed(index) {
			this.$data.current_cart[index].errors = this.$data.current_cart[index].errors || {};
			this.$data.current_cart[index].errors.address = l.t('app.enter-valid-address', 'Enter valid address');
			this.$emit('validation_failed');
			this.$forceUpdate();
		},
	},
	filters: {
		price(val) {
			return (val / 100).toFixed(2);
		},
	},
};
</script>

<style lang="scss" scoped>
  .validation{
	position: absolute;
  }
</style>
