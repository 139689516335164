import Resource from './Resource';

export default class UserSchedule extends Resource {
    constructor(user, session, emitter, changeRoute) {
        super(session, emitter, '/video-conferences', changeRoute);
        this.user = user;
        this.extension = user.extension;
        this.item = {
            subject: '',
            agenda: '',
            start: null,
            time_zone: null,
            duration: 60,
            auto_record: 'none',
            one_time_access_code: false,
            auto_transcribe: false,
            mute_mode: 'conversation',
            participants: [],
        };
    }

    async loadItems(params) {
        if (!this.extension) return null;
        this.loading = true;
        try {
            const offset = this.checkIfUserDeletedItems(params.offset, params.page);
            const items = await this.session.get_list(
                this.baseUri, params.limit, offset,
            );

            this.page = params.page;
            if (this.page > 1 && !items.items.length) {
                this.emitter('pageChanged', 1);
                return this.loadItems({
                    limit: this.limit,
                    offset: 0,
                    page: 1,
                });
            }

            this.items = items.items;
            this.finalizeLoadingItems(items);
        } catch (err) {
            this.validation_error(err);
        }
        this.loading = false;

        return this.items;
    }

    async create(schedule) {
        this.loading = true;
        try {
            await this.session.create_item(this.baseUri, schedule);
            this.item = null;
            this.successfulCreation('video.users.schedules.index');
        } catch (err) {
            this.validation_error(err);
        }
        this.loading = false;
    }
}
