<template>
    <div class="phone-numbers-mass-delete" data-test-id="phone-numbers-mass-delete-page">
        <div v-if="phone.loading">
            <w-loader v-if="!phone.deleting"/>
            <ProgressBar
              v-else
              :key="phone.deleting.status.length"
              :processing="phone.deleting"
              @stopProcess="phone.stopDeleting = true"
              data-test-id="phone-numbers-mass-delete-progressbar"
            />
        </div>
        <div v-else>
            <w-alert
              v-if="phone.alert"
              :message="phone.alert.message"
              :level="phone.alert.level"
              :closable="phone.enable_close_alert"
              @closed="phone.alert = null"
              class="mb-3"
            />
            <div v-if="phone.numbersToBeDeleted && phone.numbersToBeDeleted.length">
              <v-simple-table data-test-id="phone-numbers-mass-delete-number-to-be-deleted-table">
                  <thead data-test-id="table-head">
                    <tr data-test-id="head-row">
                      <th data-test-id="phone-numebr">{{ l.t('app.phone-number', 'Phone number') }}</th>
                      <th data-test-id="status">{{ l.t('app.status', 'Status') }}</th>
                    </tr>
                  </thead>
                  <tbody data-test-id="table-body">
                    <tr v-for="phone_number in phone.numbersToBeDeleted" :data-test-id="`phone-numbers-mass-delete-${phone_number.phone_number}`">
                      <td :data-test-id="`phone-numbers-mass-delete-${phone_number.phone_number}-formated`">{{ phone_number.phone_number | format_phone_number}}</td>
                      <td :data-test-id="`phone-numbers-mass-delete-${phone_number.phone_number}-message`">{{ phone_number.message }}</td>
                    </tr>
                  </tbody>
              </v-simple-table>
              <div class="d-flex mt-3">
                <w-btn
                  v-if="phone.numbersToBeDeleted.find(x => x.id)"
                  @click="phone.delete_from_csv()"
                  class="float-right error--text error-bg"
                  data-test-id="phone-numbers-mass-delete-phone-nums-btn"
                >
                  <v-icon color="error" class="mr-1">$vuetify.icons.trash</v-icon>{{ l.t('phone-numbers.delete-phone-numbers', 'Delete phone numbers') }}
                </w-btn>
                <w-btn
                  v-else
                  @click="phone.resetDeletingConf()"
                  data-test-id="phone-numbers-mass-delete-reset-btn"
                >
                  {{ l.t('app.upload-other-file', 'Upload other file') }}
                </w-btn>
              </div>
            </div>
            <div v-else-if="!phone.deleting_finished">
              <FileUploader
                @submitted="phone.retrieve_numbers_from_csv()"
                @uplaoded="phone.csvFileUploaded($event, 'file')"
                @removed="phone.file = null"
                :button_text="l.t('phone-numbers.search-for-numbers', 'Search for phone numbers')"
                data-test-id="phone-numbers-mass-delete-file-uploader"
              >
                {{ l.t("phone-numbers.delete-csv-help", "Please upload a .csv file that you want to delete phone numbers from.") }}
              </FileUploader>
            </div>
            <div v-else>
              <v-simple-table data-test-id="phone-numbers-mass-delete-uploaded-table">
                <thead data-test-id="table-head">
                  <tr data-test-id="head-row">
                    <th data-test-id="phone-number">{{ l.t('app.phone-number', 'Phone number') }}</th>
                    <th style="width:100%" data-test-id="status">{{ l.t('app.status', 'Status') }}</th>
                  </tr>
                </thead>
                <tbody data-test-id="table-body">
                  <tr v-for="(status, i) in phone.deleting.status" :data-test-id="i">
                    <td :data-test-id="`phone-numbers-mass-delete-uploaded-table-item-${i}`">{{ status.name | format_phone_number }}</td>
                    <td :data-test-id="`phone-numbers-mass-delete-uploaded-table-item-status-${i}`">{{ status.message }}</td>
                  </tr>
                </tbody>
              </v-simple-table>
              <div>
                <w-btn @click="phone.download_deleting_results()" color="success" data-test-id="phone-numbers-mass-delete-download-results">
                  {{ l.t('phone-numbers.download-results', 'Download results') }}
                </w-btn>
                <w-btn class="ml-3" @click="phone.resetDeletingConf()" data-test-id="phone-numbers-mass-delete-reset-configuration">
                  {{ l.t('phone-numbers.delete-other', 'Delete other') }}
                </w-btn>
              </div>
            </div>
        </div>
    </div>
</template>

<script>
  import l from '../../../libs/lang';
  import Helpers from '../../../libs/helpers';
  import PhoneNumber from '../../../models/PhoneNumber';
  import ProgressBar from '../../elements/ProgressBar.vue';
  import FileUploader from '../../elements/form/FileUploader.vue';

  export default {
    components: {
      ProgressBar,
      FileUploader,
    },
    data() {
      return {
        l,
        phone: new PhoneNumber(this.$session, Helpers.emitter(this), Helpers.changeRoute(this), () => this.$forceUpdate()),
        isSelecting: false,
      };
    },
  };
</script>

<style lang="scss" scoped>
  .theme--light.v-file-input .v-file-input__text {
    color: white !important;
  }
  .v-text-field__slot input {
     color: red
   }
  .v-file-input {
    max-width: 100px;
    border-bottom: 0;
  }
  #file {}
</style>
