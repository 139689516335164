import { render, staticRenderFns } from "./setup-your-phones.vue?vue&type=template&id=521e8b06&scoped=true&"
import script from "./setup-your-phones.vue?vue&type=script&lang=js&"
export * from "./setup-your-phones.vue?vue&type=script&lang=js&"
import style0 from "./setup-your-phones.vue?vue&type=style&index=0&id=521e8b06&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "521e8b06",
  null
  
)

export default component.exports