var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.loading)?_c('w-loader'):_c('div',{attrs:{"id":"pdf-export"}},[_c('v-simple-table',{attrs:{"id":"stats-table","data-test-id":"per-ext-graph-table"}},[_c('thead',{attrs:{"data-test-id":"table-head"}},[_c('tr',{attrs:{"data-test-id":"head-row"}},[_c('th',{attrs:{"data-test-id":"extension"}},[_vm._v(_vm._s(_vm.l.t('app.extension', 'Extension')))]),_vm._l((Object.keys(_vm.items)),function(prop,i){return _c('th',{attrs:{"data-test-id":i}},[_vm._v(" "+_vm._s(_vm.call.category_conf.fields[prop].translation)+" ")])})],2)]),_c('tbody',{attrs:{"data-test-id":"table-body"}},[_vm._l((_vm.call.category_conf.extensions),function(extension,i){return _c('tr',{attrs:{"data-test-id":`per-ext-graph-item-${i}-row`}},[_c('td',{class:{ disabled: extension && typeof extension !== 'object' },attrs:{"title":false,"data-test-id":"extension"}},[_vm._v(" "+_vm._s(_vm._f("extensionName")(extension))+" ")]),_vm._l((Object.keys(_vm.items)),function(prop){return _c('td',{attrs:{"data-test-id":prop}},[_c('HorizontalBar',{attrs:{"color":_vm.colors[_vm.findExtensionId(extension)],"item":_vm.items[prop].find(
                        (x) => x.extension === extension
                    ),"title":_vm.title(_vm.items[prop].find(
                        (x) => x.extension === extension
                    ), prop),"max":Math.max.apply(
                        Math,
                        _vm.items[prop].map((x) => x.display_value)
                    ),"data-test-id":`per-ext-graph-item-${i}-bar`}})],1)})],2)}),_c('tr',{attrs:{"data-test-id":"per-ext-bottom-graph"}},[_c('td',{attrs:{"data-test-id":"empty-cell"}},[_c('br')]),_vm._l((Object.keys(_vm.items)),function(prop){return _c('td',{attrs:{"data-test-id":prop}},[_c('div',{staticClass:"d-flex justify-space-between"},[_c('span',{staticClass:"min"},[_vm._v(" "+_vm._s(Math.round( _vm.call.formatters[_vm.call.category_conf.fields[prop]['type']]( Math.min.apply( Math, _vm.items[prop].map((x) => x.value) ) ) ))+" ")]),_vm._v(" "+_vm._s(_vm.l.t('app.calls-lowercase', 'calls'))+" "),_c('span',{staticClass:"max"},[_vm._v(" "+_vm._s(Math.round( _vm.call.formatters[_vm.call.category_conf.fields[prop]['type']]( Math.max.apply( Math, _vm.items[prop].map((x) => x.value) ) ) ))+" ")])])])})],2)],2)]),_c('v-simple-table',{attrs:{"data-test-id":"per-ext-table"}},[_c('thead',{attrs:{"data-test-id":"table-head"}},[_c('tr',{attrs:{"data-test-id":"head-row"}},[_c('th',{attrs:{"data-test-id":"extension"}},[_vm._v(_vm._s(_vm.l.t('app.extension', 'Extension')))]),_vm._l((Object.keys(_vm.items)),function(prop,i){return _c('th',{attrs:{"data-test-id":prop}},[_vm._v(" "+_vm._s(_vm.call.category_conf.fields[prop].translation)+" ")])})],2)]),_c('tbody',{attrs:{"data-test-id":"table-body"}},_vm._l((_vm.call.category_conf.extensions),function(extension,i){return _c('tr',{attrs:{"data-test-id":i}},[_c('td',{class:{ disabled: extension && typeof extension !== 'object'},attrs:{"title":false,"data-test-id":"extension"}},[_vm._v(" "+_vm._s(_vm._f("extensionName")(extension))+" ")]),_vm._l((Object.keys(_vm.items)),function(prop){return _c('td',{attrs:{"data-test-id":prop}},[_c('div',[_vm._v(" "+_vm._s(Math.round( _vm.items[prop].find((x) => x.extension === extension).value ))+" ")])])})],2)}),0)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }