<template>
  <div>
    <Header :is_large_page="false">
      <v-container>
        <w-btn @click="$emit('sign_out')">{{l.t('app.sign-out', 'Sign out')}}</w-btn>
      </v-container>
    </Header>
    <v-container id="subaccounts-owner-page-container">
      <SubaccountsList
          v-if="master_account_id"
          :subaccounts_owner_mode="true"
      />
    </v-container>
  </div>
</template>
<script>
  import l from '../../libs/lang';

  import Helpers from '../../libs/helpers';
  import Header from '../elements/Header.vue';
  import SubaccountsList from './subaccounts/index.vue';

  export default {
    components: {
      Header,
      SubaccountsList,
    },
    data() {
      return {
        l,
        master_account_id: null,
      };
    },
    async created() {
        const token_details = await Helpers.token_details(this.$session);
        this.$data.master_account_id = token_details.scope.split(':')[1];
        this.$session.user.id = this.$data.master_account_id;
    },
  };
</script>
