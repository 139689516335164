<template>
    <router-link
        v-if="route"
        v-bind="$attrs"
        v-on="$listeners"
        :to="{ name: route.name }"
        class="main-tab w-body-1 nowrap"
        tag="div"
    >
        <CategoryTitle @click="$emit('category_click')" :icon="route.icon" :title="route.title"/>
    </router-link>
    <div
        v-else
        @click="open_link"
        v-bind="$attrs"
        v-on="$listeners"
        class="main-tab w-body-1"
    >
        <slot />
    </div>
</template>
<script>
import CategoryTitle from './CategoryTitle.vue';

  export default {
    props: {
        route: {
            type: Object,
        },
        href: {
            type: String,
            default: null
        }
    },
    components: {CategoryTitle},
    data() {
      return {
      };
    },
    methods: {
        open_link() {
            if (this.$props.href) {
                window.open(this.$props.href, '_blank');
            }
        }
    }
  };
</script>
