<template>
  <div class="create-page" data-test-id="faxes-send-page">
      <w-alert
        v-if="fax.alert"
        :message="fax.alert.message"
        :level="fax.alert.level"
        :closable="fax.enable_close_alert"
        @closed="fax.alert = null"
        class="mt-3 mb-5"
      />
      <w-loader v-if="fax.loading"/>
      <w-form v-else @submit.prevent="fax.send()" @changed="may_submit=true" data-discard="true">
        <FormInput v-if="!one_extension" :label="l.t('app.extension', 'Extension')">
          <ExtensionSelector
            :select_if_one="true"
            @changed="fax.extension = $event.id"
            @only_one_auto_selected="one_extension = true"
          />
        </FormInput>
        <FormInput :label="l.t('fax.send-to', 'Send to')">
          <Tel :value="fax.send_to" :validation="true" @changed="fax.send_to = $event.clean_number" :required="true"/>
        </FormInput>
        <FormInput :label="l.t('fax.send-cover', 'Cover text')">
          <w-textarea
            v-model="fax.send_cover_text"
            :rules="fax.send_file ? [] : [$validation.required(null, l.t('fax.required-if-file-missing', 'This field is required if PDF file is not provided'))]"
            class="fs-exclude"
            auto-grow
            rows="1"
            hide-details="auto"
          ></w-textarea>
        </FormInput>
        <FormInput :label="l.t('fax.pdf-file', 'PDF file')">
            <w-file-input
              @change="fax.handle_file_upload($event)"
              :rules="fax.send_cover_text ? [] : [$validation.required(null, l.t('fax.required-if-cover-tex-missing', 'This field is required if cover text is not provided'))]"
              class="fs-exclude"
              hide-details="auto"
            />
        </FormInput>
        <FormInput>
          <w-alert v-if="fax.send_alert" :message="fax.send_alert.message" :level="fax.send_alert.level"/>
          <w-btn v-else :disabled="!may_submit" type="submit" color="primary">{{ l.t('app.send', 'Send') }}</w-btn>
        </FormInput>
      </w-form>
  </div>
</template>

<script>
  import l from '../../../libs/lang';
  import Fax from '../../../models/Fax';
  import Helpers from '../../../libs/helpers';
  import Tel from '../../elements/Tel.vue';
  import FormInput from '../../elements/form/FormInput.vue';
  import ExtensionSelector from '../../fragments/ExtensionSelector.vue';

  export default {
    props: ['page'],
    components: {
      Tel,
      FormInput,
      ExtensionSelector,
    },
    data() {
      return {
        l,
        'one_extension': false,
        fax: new Fax(this.$session, Helpers.emitter(this), Helpers.changeRoute(this)),
        may_submit: false,
      };
    },
  };
</script>
