<template>
    <w-loader v-if="loading" size="small" :message="false"/>
    <div v-else class="greetings-selector" data-test-id="greetings-selector-selector">
        <div class="input-with-text">
            <div class="input-holder">
                <APIAutocomplete
                  :value="greeting"
                  @input="greeting_changed"
                  :return_object="true"
                  :before_list="before_list"
                  :no_play="true"
                  mode="greetings"
                  :key="`greetings-${show_form}`"
                  :reload="reload"
                  :rules="required && !show_form && !nullable ? [$validation.required()] : []"
                  :suppress_no_items_alert="true"
                  data-test-id="greetings-selector-selector-with-create"
                />
                <Player
                  v-if="greeting && greeting.id"
                  :url="`/media/${greeting.id}/download`"
                  :gray="true"
                  :icon_size="18"
                  :btn_size="32"
                  download_icon="$vuetify.icons.download_cloud_inverted"
                  :key="greeting.id"
                  class="mt-3"
                  data-test-id="selector-player"
                />
            </div>
        </div>
        <w-alert v-if="alert" :message="alert.message" :level="alert.level"/>
        <div v-if="show_form">
            <div>
              <MediaLanguageSelector
                v-model="new_greeting_lang"
                class="mt-2"
                data-test-id="greetings-selector-lang-selector"
              />
                <div class="d-flex">
                  <w-textarea
                      v-model="new_greeting_text"
                      :placeholder="l.t('greeting-selector.greeting-message-here', 'Enter your greeting message here...')"
                      :rules="required ? [$validation.required()]: []"
                      rows="4"
                      class="mt-3"
                      hide-details='auto'
                      data-test-id="greetings-selector-new-greeting-txt-input"
                      persistant-placeholder
                  />
                  <w-btn v-if="new_greeting_text" :disabled="!may_submit" @click="save" small color="primary" class="mt-3 ml-3" data-test-id="greetings-selector-create">
                    {{ l.t("app.create", "Create") }}
                  </w-btn>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
  import l from '../../libs/lang';
  import Helpers from '../../libs/helpers';
  import Media from '../../models/Media';
  import Player from '../elements/Player.vue';
  import APIAutocomplete from '../elements/form/APIAutocomplete.vue';
  import MediaLanguageSelector from '../elements/form/MediaLanguageSelector.vue';

  export default {
  props: {
      id: {
        type: Number,
        requried: false,
      },
      value: {
        type: Object,
        required: false,
      },
      required: {
        type: Boolean,
        default: false,
      },
      nullable: {
        type: Boolean,
        default: false,
      }
    },
    components: {
      Player,
      APIAutocomplete,
      MediaLanguageSelector,
  },
    data() {
      return {
        l,
        alert: null,
        loading: false,
        greeting: this.$props.value,
        new_greeting_lang: 'Joanna / English (US)',
        new_greeting_text: '',
        reload: false,
        may_submit: false,
        before_list: [],
        show_form: true,
        create_new_greeting: this.$props.nullable ? '_create_new_greeting' : null,
      };
    },
    created() {
      this.$data.before_list = [
        { value: this.$data.create_new_greeting, text: l.t('greeting-selector.new', 'Create a new greeting') }
      ];
      if (this.$props.nullable) {
        this.$data.before_list.unshift({ value: null, text: l.t('app.none', 'None') });
        this.$data.show_form = false;
      }
    },
    watch: {
      greeting(value) {
        this.$emit('changed', { value });
      },
      new_greeting_lang() {
        this.$data.may_submit = true;
      },
      new_greeting_text() {
        this.$data.may_submit = true;
      }
    },
  methods: {
      greeting_changed(data) {
        if (data && data.id === this.$data.create_new_greeting) {
          this.$data.greeting = null;
          this.$data.show_form = true;
        } else if (data && typeof data === 'object') {
          this.$data.greeting = data;
          this.$data.show_form = false;
        } else {
          this.$data.greeting = null;
          this.$data.show_form = false;
        }
      },
      save(e) {
        if (e) e.preventDefault();
        this.$data.loading = true;
        this.$session
          .create_item('/media', {
            type: 'greeting',
            name: `G-${Math.floor(
              Math.random() * 1000000,
            )} ${this.$data.new_greeting_text.slice(0, 60)}${
              this.$data.new_greeting_text.length > 60 ? '...' : ''
              }`,
            origin: 'tts',
            tts_text: this.$data.new_greeting_text,
            tts_voice: this.$data.new_greeting_lang,
          })
          .then((value) => {
            const Greeting = new Media(this.$session, 'greetings');
            Greeting.addToCache(value, 'greetings');

            this.$data.new_greeting_text = '';
            this.$data.show_form = false;
            this.$data.loading = false;
            this.$data.reload = true;
            this.$data.greeting = value;
          })
          .catch(Helpers.show_generic_error.bind(this));
      },
  },
};
</script>
