var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"blocked-calls"},[_c('Submenu',{key:`${_vm.l.detect()}${_vm.$route.name}`,attrs:{"routes":{
        'blockedCalls.index': {
          title: _vm.l.t('app.blocked-numbers', 'Blocked numbers')
        },
        'blockedCalls.create': {
          title: _vm.l.t('blocked-calls.block-numbers', 'Block numbers')
        },
        'blockedCalls.recent': {
          title:_vm.l.t('blocked-calls.recent-calls', 'Recent calls')
        },
    }}}),(_vm.$route.name === 'blockedCalls.index')?_c('BlockedCallsFragment',{attrs:{"data-test-id":"bc-index"},on:{"deletingStarted":function($event){_vm.deleting_in_progress = true},"deletingEnded":function($event){_vm.deleting_in_progress = false}}}):_vm._e(),(_vm.$route.name === 'blockedCalls.create')?_c('BlockedCallsCreateFragment',{attrs:{"data-test-id":"bc-create"}}):_vm._e(),(_vm.$route.name === 'blockedCalls.recent')?_c('RecentCallsFragment',{attrs:{"data-test-id":"bc-recent"}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }