var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"devices"},[_c('Submenu',{key:`${_vm.l.detect()}${_vm.$route.name}`,attrs:{"routes":{
      'devices.index': {
        title: _vm.l.t('app.devices', 'Devices'),
        subroutes: ['devices.show']
      },
      'devices.create': {
        title: _vm.l.t('menu.add-generic-device', 'Add generic device')
      },
      'devices.buy': {
        title: _vm.l.t('menu.buy-device', 'Buy device')
      },
      'devices.my-orders': {
        title: _vm.l.t('menu.my-orders', 'My orders'),
        subroutes: ['devices.my-orders.show']
      },
    }}}),(_vm.$route.name === 'devices.index')?_c('DevicesFragment',{attrs:{"data-test-id":"devices-list"},on:{"deletingStarted":function($event){_vm.deleting_in_progress = true},"deletingEnded":function($event){_vm.deleting_in_progress = false}}}):_vm._e(),(_vm.$route.name === 'devices.create')?_c('DevicesCreateFragment',{attrs:{"data-test-id":"devices-create"}}):_vm._e(),(_vm.$route.name === 'devices.show')?_c('DevicesShowFragment',{attrs:{"data-test-id":"devices-show"}}):_vm._e(),(_vm.$route.name === 'devices.buy')?_c('DevicesBuyFragment',{attrs:{"data-test-id":"devices-buy"}}):_vm._e(),(_vm.$route.name === 'devices.my-orders')?_c('DevicesMyOrdersFragment',{attrs:{"data-test-id":"devices-orders"}}):_vm._e(),(_vm.$route.name === 'devices.my-orders.show')?_c('DevicesOrderFragment',{attrs:{"data-test-id":"devices-orders-show"}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }