import l from '../../libs/lang';
import ReportConf from './ReportConf';
import US_STATES from '../../libs/geo/us_states.json';

export default class GeoDistribution extends ReportConf {
	constructor(api_items) {
		super(['total']);
		this.api_items = api_items;
		this.title = ReportConf.get_title('calls', 'geo-distribution');
		this.group_by_param = 'npa';
		this.logarithmic = true;
	}

	get headers() {
		const headers = {
			state: l.t('app.state', 'State'),
			npa: l.t('app.npa', 'NPA'),
		};
		Object.keys(this.fields).map(
			(x) => headers[x] = this.fields[x].translation
		);

		return headers;
	}

	prepare_items(rss) {
		let items = rss || JSON.parse(JSON.stringify(this.api_items));
		let us = {};
		const all = {};
		for (const item of items) {
			if (item.npa === '000') {
				const unknown = l.t('reports.unknown-npa', 'Unknown NPA');
				item.state_name = unknown;
				item.npa = [];

				all[unknown] = item;
				// eslint-disable-next-line no-continue
				continue;
			}
			item.npa = parseInt(item.npa);
			const state = US_STATES.find((x) => x.npa.includes(item.npa));
			if (state) {
				const formated_code = state.code.toLowerCase();
				if (!us[formated_code]) us[formated_code] = 0;
				us[formated_code] += item.total;
				if (!all[formated_code]) {
					item.state_name = state.name;
					item.state = state.code;
					all[formated_code] = item;
					all[formated_code].npa = [item.npa];
				} else {
					all[formated_code].total += item.total;
					all[formated_code].npa.push(item.npa);
				}
			} else if (!all[l.t('app.others', 'Others')]) {
				item.npa = `${item.npa}`;
				item.state_name = l.t('app.others', 'Others');
				item.state = '—';
				all[l.t('app.others', 'Others')] = item;
				all[l.t('app.others', 'Others')].npa = [item.npa];
			} else {
				all[l.t('app.others', 'Others')]['total'] += item.total;
			}
		}
		items = {};
		const keys = Object.keys(all).sort();
		keys.map((x) => (items[x] = all[x]));
		us = this.logarithmic ? this.constructor.logarithmicRepresentation(us) : us;
		return {
			items,
			us,
		};
	}

	static logarithmicRepresentation(states) {
		// eslint-disable-next-line prefer-spread
		const max = Math.log10(Math.max.apply(Math, Object.values(states)));
		for (const state of Object.keys(states)) {
			if (states[state]) {
				let log = Math.log10(states[state]);
				if (log === 0) log = 0.01;
				if (max === log) {
					states[state] = 100;
				} else {
					states[state] = (log * 100) / max;
				}
			}
		}
		return states;
	}

	prepare_csv_values(items) {
		const data = Object.values(items.items).map((x) => {
			const item = {};
			for (const field of Object.keys(this.fields)) item[field] = x[field];
			const npa = x.npa.length ? x.npa.join(' / ') : '—';
			item.npa = npa.replace(/,/g, ' ');
			item.state = x.state_name.replace(/,/g, ' ');
			return item;
		});

		return data;
	}
}
