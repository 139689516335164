<template>
    <router-link
    v-bind="$attrs"
    v-on="$listeners"
    :to="{name: route.name, params: route.params}"
    tag="div"
    class="w-caption sidebar-link"
    :key="route.name"
    :data-test-id="`navigation-route-${route.name}`"
  >
    {{ route.title }}
  </router-link>
</template>
<script>
  export default {
    props: {
        route: {
            type: Object,
            required: true,
        }
    },
    data() {
      return {
      };
    },
  };
</script>
