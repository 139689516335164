<template>
    <v-form v-bind="$attrs" @submit.prevent="on_submit" v-model="is_form_valid" @input="(v) => $emit('input', v)" ref="form">
        <transition name="collapsable">
            <InvalidFormTitle v-if="!fields_are_valid" />
            <FormValidationError v-if="form_validation_error" @closed="form_validation_error = null" class="form-validation-main-error">
                {{form_validation_error}}
            </FormValidationError>
        </transition>
        <!-- Pass on all named slots -->
        <slot v-for="slot in Object.keys($slots)" :name="slot" :slot="slot" />
        <!-- Pass on all scoped slots -->
        <template v-for="slot in Object.keys($scopedSlots)" :slot="slot" slot-scope="scope">
            <slot :name="slot" v-bind="scope" />
        </template>
    </v-form>
</template>
<script>
import l from '../../../libs/lang';
import EventBus from '../../../libs/EventBus';
import FormValidationError from '../../elements/form/FormValidationError.vue';
import InvalidFormTitle from '../../elements/form/InvalidFormTitle.vue';

export default {
    props: {
        value: {
            type: Boolean,
            default: true,
        },
        validation: {
            type: Array,
            required: false,
        },
    },
    components: {
        InvalidFormTitle,
        FormValidationError,
    },
    data() {
        return {
            l,
            fields_are_valid: true,
            is_form_valid: this.$props.value,
            form_validation_error: null,
        };
    },
    created() {
        EventBus.$on('form_input_changed', () => {
            this.$emit('changed');
            this.$data.form_validation_error = null;
        });
    },
    methods: {
        on_submit(e) {
            if (e) e.preventDefault();
            if (this.$refs.form.validate()) {
                this.$data.fields_are_valid = true;
            } else {
                this.$data.fields_are_valid = false;
                window.scrollTo({ top: 0, behavior: 'smooth' });
                return null;
            }
            const form_validation_error = this.custom_validation();
            if (form_validation_error !== true) {
                window.scrollTo({ top: 0, behavior: 'smooth' });
                this.$data.form_validation_error = form_validation_error;
                return null;
            }
            this.$data.form_validation_error = null;
            this.$data.fields_are_valid = true;

            this.$emit('submit', e);
            return true;
        },
        custom_validation() {
            if (Array.isArray(this.$props.validation) && this.$props.validation.length) {
                for (let i = 0; i < this.$props.validation.length; i++) {
                    const is_valid = this.$props.validation[i]();
                    if (is_valid !== true) {
                        this.$emit('invalid_form', i);
                        return is_valid;
                    }
                }
            }
            return true;
        }
    },
    watch: {
        is_form_valid(v) {
            if (v) {
                this.$data.fields_are_valid = true;
            }
            this.$emit('input', v);
        },
        form_validation_error(v) {
            this.$emit('custom_validation', !!v);
        },
    }
};
</script>

<style lang="scss" scoped>
.form-validation-main-error {
    margin-bottom: 28px;
}
</style>
