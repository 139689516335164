var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"phone-numbers"},[(_vm.$route.name !== 'phone-numbers.visualizer')?_c('Submenu',{key:`${_vm.l.detect()}${_vm.$route.name}`,attrs:{"routes":{
      'phone-numbers.index': {
        title: _vm.l.t('app.phone-numbers', 'Phone numbers')
      },
      'phone-numbers.create': {
        title: _vm.l.t('app.phone-add', 'Add phone number')
      },
      'phone-numbers.delete-csv': {
        title: _vm.l.t('phone-numbers.delete-phone-numbers', 'Delete phone numbers')
      },
       'phone-numbers.visualizer': {
        title: _vm.l.t('phone-numbers.sisualizer', 'Visualizer')
      },
    }}}):_vm._e(),(_vm.$route.name === 'phone-numbers.index')?_c('PhoneNumbersFragment',{ref:"indexPage",attrs:{"data-test-id":"phone-numbers-index"}}):_vm._e(),(_vm.$route.name === 'phone-numbers.create')?_c('PhoneNumbersCreateFragment',{attrs:{"data-test-id":"phone-numbers-create"}}):_vm._e(),(_vm.$route.name === 'phone-numbers.delete-csv')?_c('PhoneNumbersDeleteCsvFragment',{attrs:{"data-test-id":"phone-numbers-delete-csv"}}):_vm._e(),(_vm.$route.name === 'phone-numbers.visualizer')?_c('Visualizer',{attrs:{"data-test-id":"phone-numbers-visualizer"}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }