<template>
    <w-loader v-if="order.loading"/>
    <div v-else class="buy-device" data-test-id="buy-device-list">
      <w-alert
        v-if="order.alert"
        :message="order.alert.message"
        :level="order.alert.level"
        class="mb-6"
      />
      <w-stepper v-if="stepper.items.length" v-model="stepper.active" alt-labels>
        <v-stepper-header :style="{'maxWidth': '746px'}" class="mx-auto">
          <template v-for="(step, index) of stepper.items">
            <v-stepper-step
              :complete="max_step >= index + 1 && previous_steps_completed(index, step) && step.completed() && stepper.active !== index + 1"
              complete-icon="$vuetify.icons.check"
              :step="index + 1"
              @click="step_on_click(index, step)"
              class="cursor-pointer"
            >
              {{ step.title }}
            </v-stepper-step>

            <v-divider v-if="index < stepper.items.length - 1"></v-divider>
          </template>
        </v-stepper-header>
        <v-stepper-items>
          <v-stepper-content step="1">
            <ChooseYourPhonesStep
              v-if="stepper.active === 1"
              :devices="order.devices"
              :types="[{value: null, text: l.t('orders.all-devices', 'All devices')}].concat(order.devices_types)"
              :_cart="cart"
              :available_users="virtual_extensions.length"
              @submitted="devices_choosen"
              @added="device_added"
              @removed="device_removed"
            />
          </v-stepper-content>
          <v-stepper-content step="2">
            <SetupYourPhones
              v-if="stepper.active === 2"
              :cart="cart"
              :extensions="virtual_extensions"
              @submitted="phones_set_up($event, true)"
              @updated="phones_set_up($event, false)"
              @back="back_to_choose_device"
              @validation_failed="max_step = 2"
              :key="`2-${stepper.active}`"
            />
          </v-stepper-content>

          <v-stepper-content step="3">
            <ShippingDetails
              v-if="stepper.active === 3"
              :cart="cart"
              :order="order"
              @submitted="shipping_details_submitted($event, true)"
              @updated="shipping_details_submitted($event, false)"
              @back="stepper.active = 2"
              :shipping_address_contact="shipping_address"
              :shipping="shipping"
            />
          </v-stepper-content>

          <v-stepper-content step="4">
            <PaymentMethods
                v-model="payment_method"
                @continue="stepper.active = 5"
                @back="stepper.active = 3"
              />
          </v-stepper-content>
          <v-stepper-content step="5">
            <FinalTable
              v-if="stepper.active === 5"
              :cart="order.transform_cart_array_to_object(cart)"
              :shipping="shipping"
              :shipping_address="shipping_address"
              :payment_method="payment_method"
            />
            <StepperButtons
              :disable_continue="!cart.length"
              @continue="submit_order"
              @back="stepper.active = 4"
              :continue_text="l.t('devices.place-order', 'Place order')"
            />
            <DefaultModal
              v-if="confirm_question"
              :value="true"
              @close="confirm_question = null"
              max-width="500px"
              :with_x="false"
              :message="confirm_question"
              data-test-id="buy-device-confirm-confirmation-modal"
            >
              <div class="w-body-1">{{ confirm_question }}</div>
              <template v-slot:buttons>
                <w-btn @click="confirm_question = null" color="secondary" class="mr-5">{{ l.t('app.cancel', 'Cancel') }}</w-btn>
                <w-btn @click="confirmed" color="primary">{{l.t('app.confirm', 'Confirm')}}</w-btn>
              </template>
            </DefaultModal>
          </v-stepper-content>
        </v-stepper-items>
      </w-stepper>
    </div>
</template>

<script>
  import l from '../../../libs/lang';
  import Order from '../../../models/Order';
  import Helpers from '../../../libs/helpers';
  import FinalTable from './steps/final-table.vue';
  import PaymentMethods from './steps/payment-methods.vue';
  import ShippingDetails from './steps/shipping-details.vue';
  import SectionTitle from '../../elements/SectionTitle.vue';
  import SetupYourPhones from './steps/setup-your-phones.vue';
  import StepperButtons from '../../elements/StepperButtons.vue';
  import DefaultModal from '../../elements/modal/DefaultModal.vue';
  import ChooseYourPhonesStep from './steps/choose-your-phones.vue';
  import Cachier from '../../../libs/Cachier';

  export default {
    props: ['id', '_device'],
    components: {
      FinalTable,
      DefaultModal,
      SectionTitle,
      StepperButtons,
      PaymentMethods,
      ShippingDetails,
      SetupYourPhones,
      ChooseYourPhonesStep,
    },
    data() {
      return {
        l,
        item: null,
        virtual_extensions: [],
        order: new Order(this.$session, Helpers.emitter(this), Helpers.changeRoute(this)),
        cart: [],
        stepper: {
          active: 1,
          items: [],
        },
        shipping: {
          'value': 'FEDEXGROUND',
          'name': 'Standard Shipping',
          'code': 30100,
          'code_name': 'SHIPPING',
          'description': 'Up to 5 business days based on distance to destination',
          'total': 0,
          'price': 0,
          'taxes': 0,
				},
        shipping_address: null,
        payment_method: null,
        confirm_question: null,
        tab: 'list',
        max_step: 1,
        cachier: new Cachier(this.$session.user.id),
      };
    },
    async created() {
      const cart_items = this.$data.cachier.getItem('global_cart');
      if (cart_items) {
        this.$data.cart = cart_items;
      }
      this.$data.stepper.items = [
        {
          title: l.t('devices.choose-your-phones', 'Choose your phones'),
          completed: () => this.$data.cart.length > 0,
        },
        {
          title: l.t('devices.setup-your-phones', 'Set up your phones'),
          completed: () => this.$data.cart.length > 0 && this.$data.cart.every((x) => x._custom_setup.address),
        },
        {
          title: l.t('devices.shipping-details', 'Shipping details'),
          completed: () => this.$data.shipping && this.shipping_address,
        },
        {
          title: l.t('billing.payment-methods', 'Payment methods'),
          completed: () => !!this.$data.payment_method,
        },
        {
          title: l.t('devices.confirm-your-order', 'Confirm your order'),
          completed: () => false,
        },
      ];
      this.$data.order.loading = true;
      const promises = await Promise.all([
        this.$data.order.loadDevicesPriceList(),
        this.load_extensions(),
      ]);

      this.$data.virtual_extensions = promises[1];
      this.$data.order.loading = false;
    },
    methods: {
      async load_extensions() {
        try {
          let uri = '/extensions?filters[eligible_for_device]=1';
          if (await Helpers.is_nxt(this.$session)) {
            uri = `${uri}&filters[nxt_extensions]=1`;
          }
          let extensions = await this.$session.get_list_all(uri);
          extensions = extensions.items;
          if (await Helpers.is_nxt(this.$session)) {
            const nxt_details = await Helpers.nxt_details(this.$session);
            if (nxt_details.company_inbox_extension) {
              extensions = extensions.filter((x) => x.id !== nxt_details.company_inbox_extension);
            }
          }

          return extensions;
       } catch (err) {
        this.$data.order.validation_error(err);
       }

       return null;
      },
      previous_steps_completed(index) {
        for (let i = 0; i < index; i++) {
          if (!this.stepper.items[i].completed()) return false;
        }
        return true;
      },
      step_on_click(index) {
        if (
          this.$data.max_step >= index + 1
          && (this.$data.cart.length || (!this.$data.cart.length && index === 0))
          && this.previous_steps_completed(index)
        ) {
          this.$data.stepper.active = index + 1;
        }
      },
      device_added(device) {
        this.$data.cart.push({
          ...device,
          _custom_setup: {
            lines: [],
            address: null,
          },
        });
        this.max_step = 1;
        this.$data.cachier.setItem('global_cart', this.$data.cart);
      },
      device_removed(device) {
        let index = this.cart.findIndex((x) => x.id === device.id && x._custom_setup && (!x._custom_setup.address || !x._custom_setup.lines.length));
        if (index === -1) {
          index = this.cart.findIndex((x) => x.id === device.id);
        }
        if (index > -1) this.$data.cart.splice(index, 1);
        this.$data.cachier.setItem('global_cart', this.$data.cart);
      },
      devices_choosen() {
        this.$data.cachier.setItem('global_cart', this.$data.cart);
        this.$data.stepper.active = 2;
        this.max_step = 2;
      },
      back_to_choose_device(cart) {
        this.$data.cart = cart;
        this.$data.stepper.active = 1;
      },
      async phones_set_up(cart, update_stepper = false) {
        this.$data.cart = cart;
        if (update_stepper) this.$data.stepper.active = 3;
        this.max_step = 3;
        this.$data.cachier.setItem('global_cart', this.$data.cart);
      },
      shipping_details_submitted(data, update_stepper) {
        this.$data.shipping_address = data.shipping_address;
        this.$data.shipping = data.shipping;
        if (update_stepper) this.$data.stepper.active++;
        this.max_step = 4;
      },
      async submit_order() {
        const price = this.cartTotalPrice();
        const devices_count = this.$data.cart.length;
        if (!devices_count || !this.$data.shipping_address || !this.$data.shipping) {
          const alert = {
            'level': 'error',
            'message': l.t('orders.validation-failed', 'Validation failed'),
          };
          alert.message += ':';
          if (!devices_count) alert.message += `\n ${l.t('orders.no-devices-selected', 'Devices not selected.')}`;
          if (!this.$data.shipping_address) alert.message += `\n ${l.t('orders.no-contact-selected', 'Address not selected.')}`;
          if (!this.$data.shipping) alert.message += `\n ${l.t('orders.no-shipping', 'Shipping not selected.')}`;
          this.$data.order.alert = alert;
          this.$data.order.hide_alert(5);
          return null;
        }
        const devices_translation = devices_count === 1 ? l.t('orders.device', 'device') : l.t('orders.devices', 'devices');
        const question = l.t(
          'orders.confirm-order-question',
          'Are you sure you want to buy {} {} for ${}?',
          [devices_count, devices_translation, this.$options.filters.price(price)]
        );
        this.$data.confirm_question = question;
        return true;
      },
      sort_cart(cart) {
        cart.sort((a, b) => a.manufacturer.localeCompare(b.manufacturer) || a.price.total - b.price.total);
        return cart;
      },
      cartTotalPrice() {
        let price = 0;
        for (const item of this.$data.cart) {
          price += item.price.total;
          if (this.$data.shipping) {
            price += this.$data.shipping.total || 0;
          }
        }

        return price;
      },
      async confirmed() {
        this.$data.confirm_question = null;
        const devices = [];
        this.$data.cart.map((x) => {
            devices.push({
              'code': x.code,
            });
          return x;
        });
        try {
          this.$data.shipping_address = await this.$session.create_item('/contacts', this.$data.shipping_address);
          const data = {
            'contact_id': this.$data.shipping_address.id,
            'shipping_method': this.$data.shipping.value,
            'payment_method_id': this.$data.payment_method.id,
            devices,
          };
          const order = await this.$data.order.create(data);
          this.cachier.removeItem('global_cart');
          const result = await this.$data.order.sync_devices_with_setup(this.$data.cart, order);
          this.$data.order.alert = this.$data.order.generate_create_order_alert(result);
          this.$emit('created', order);
        } catch (err) {
          this.$data.order.validation_error(err);
        }
        this.$data.stepper.items = [];
        setTimeout(() => this.$data.order.changeRoute('devices.my-orders'), this.$data.order.timeUntilRedirects);
      },
    },
    filters: {
      upperFirstCase(val) {
       return val.charAt(0).toUpperCase() + val.slice(1).split('_').join(' ').split('-')
       .join(' ');
     },
     price(val) {
      return (val / 100).toFixed(2);
     }
   },
  };
</script>
<style lang="scss">
  .step-title {
    color: var(--v-text-base);
  }
  .buy-device {
    .w-200 .v-input__slot, .w-200 .v-text-field{
      width: 200px !important;
    }
  }
</style>
