<template>
  <div class="live-answer">
    <Submenu
      :routes="{
      'live-answer.index': {
        title: l.t('menu.receptionist-scripts', 'Receptionist scripts'),
        subroutes: ['live-answer.show']
      },
      'live-answer.create': {
          title: l.t('app.add-receptionist-script', 'Create a new receptionist script'),
        },
      }"
      :key="`${l.detect()}${$route.name}`"
    />
    <LiveAnswerListFragment
      v-if="$route.name === 'live-answer.index'"
      @deletingStarted="deleting_in_progress = true"
      @deletingEnded="deleting_in_progress = false"
      data-test-id="live-answer-index"
    />
    <LiveAnswerCreateFragment
      v-if="$route.name === 'live-answer.create'"
      data-test-id="live-answer-create"
    />
    <LiveAnswerShowFragment
      v-if="$route.name === 'live-answer.show'"
      data-test-id="live-answer-show"
    />
  </div>
</template>

<script>
  import l from '../../../libs/lang';
  import LiveAnswerListFragment from './index.vue';
  import LiveAnswerShowFragment from './show.vue';
  import Submenu from '../../elements/Submenu.vue';
  import LiveAnswerCreateFragment from './create.vue';

  export default {
    components: {
      Submenu,
      LiveAnswerShowFragment,
      LiveAnswerListFragment,
      LiveAnswerCreateFragment,
    },
    beforeRouteLeave(to, from, next) {
      if (window.deleting_in_progress) return this.$emit('userRedirected', to, from, next);
      next();
      return true;
    },
    data() {
      return {
        l,
        deleting_in_progress: false,
      };
    },
  };
</script>

<style scoped>

</style>
