import Resource from './Resource';
import VideoConference from './VideoConference';
import setup from '../libs/resources-setups/video-users';

export default class VideoUser extends Resource {
    constructor(session, emitter, changeRoute) {
        super(session, emitter, '/video/users', changeRoute);
        this.setup = setup;
        this.item = {
            extension_id: null,
            name: null,
            email: null,
            // plan_code: null,
        };
        this.extensions = {};
        this.video = null;
    }

    async getVideo() {
        this.loading = true;
        try {
            const video = new VideoConference(this.session, this.emitter, this.changeRoute);
            this.video = await video.getItem();
        } catch (err) {
            this.validation_error(err);
        }
        this.loading = false;
        return this.video;
    }

    async getList() {
        this.loading = true;
        try {
            const items = await this.session.get_list_all(this.baseUri);
            this.items = items.items;
            const ext_ids = this.items.map((x) => x.voip_phone_id);
            const extensions = await this.session.get_list_all(
                `/extensions?filters[id]=in:${ext_ids.join(',')}`,
            );
            this.extensions = {};
            for (const extension of extensions.items) {
                this.extensions[extension.id] = extension;
            }
        } catch (err) {
            this.validation_error(err);
        }
        this.loading = false;
    }

    async create(user) {
        this.loading = true;
        try {
            await this.session.create_item(this.baseUri, user);
            this.successfulCreation('video.users.index');
        } catch (err) {
            this.validation_error(err);
        }
        this.loading = false;
    }

    async getItem(id) {
        this.loading = true;
        try {
            const item = await this.session.get_item(`${this.baseUri}/${id}`);
            item.extension = item.voip_phone_id;
            this.item = item;
        } catch (err) {
            this.validation_error(err);
        }

        this.loading = false;
    }

    async getExtension(id) {
        this.loading = true;
        try {
            const extension = await this.session.get_item(`/extensions/${id}`);
            this.loading = false;
		    return extension;
        } catch (err) {
            this.validation_error(err);
        }
        this.loading = false;
        return true;
    }
}
