<template>
  <div class="vue-block" @mouseover="entered = true" @mouseleave="entered = false" :class="{selected: selected}" :style="blockStyle" @click="doubleClick()" @touchend="doubleClick()">
		<!-- <w-tooltip v-model="tooltip_activator" class="cursor-pointer tooltip" :nudge-top="8" :nudge-left="options.scale*(-115)" top :max-width="250">
			Double click to open item's modal.
      <v-icon color="white">mdi-gesture-double-tap</v-icon>
      <br>
      Click and hold on an item to move it.
      <v-icon color="white">mdi-cursor-move</v-icon>
      <br>
      Click and hold on the background to move the whole visual.
      <br>
      Scroll up and down to zoom in and out.
		</w-tooltip> -->
    <header class="headerStyle">
      <div class="numberAndExtensionTitle">
        {{ title }}
      </div>
    </header>
    <header v-if="objectType !== 'Call flow'" class="numberAndExtensionHeader">
      <div class="nameHolder">
        {{ name.charAt(0).toUpperCase() + name.slice(1).toLowerCase() }}
      </div>
    </header>
    <div class="inputs heightInput">
      <div class="input" v-for="(slot, index) in inputs" :key="index" :style="autoHeight">
        <div class="circle inputColor" v-if="slot.active" :class="{active: slot.active}"
             @mouseup="slotMouseUp($event, index)"
             @mousedown="slotBreak($event, index)">
        </div>
        <div class="inputLabel">{{slot.label}}</div>
      </div>
    </div>
    <div class="outputs outputsHeight">
      <div class="output" v-for="(slot, index) in outputs" :key="index">
        <div v-if="slot.used" class="circle" :class="{active: slot.active}"
              @mousedown="slotMouseDown($event, index)">
        </div>
        <div v-if="slot.backLink" class="outputName">{{ backLink }}</div>
        <div class="outputLabel">
          <div v-if="objectType === 'menu' || objectType === 'queue'" class="menuRow">
            <span class="actionName menuNameHolder"> {{ slot.actionName }} </span>
            <span class="actionKey"> {{ slot.key }} </span>
          </div>
          <div v-else class="nameHolder">
            <!-- <div v-if="!slot.actionType && index" class="line"></div> -->
            <template v-if="slot.actionType">
              <pickSvg class="svgIcon" :name="slot.actionType.toLowerCase()"></pickSvg>
              <span v-if="slot.actionType.toLowerCase() !== 'disconnect'" class="actionType"> {{ slot.actionType }} {{ !slot.actionType.toLowerCase().includes('disconnect') ? ':' : '' }} </span>
              <span class="actionName"> {{ slot.actionName }} </span>
            </template>
            <template v-else>
              <div v-if="slot.label === 'Preset'" class="filterName"> {{ slot.label }} </div>
              <div v-else class="filterName leftSideLabel"> {{ slot.label === 'All Calls' ? `Group: ${slot.label}` : slot.label }} </div>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import pickSvg from '../svgs/pickSvg.vue';

  export default {
    name: 'VueBlock',
    components: {
      pickSvg,
    },
    props: {
      x: {
        type: Number,
        default: 0,
        validator(val) {
          return typeof val === 'number';
        }
      },
      y: {
        type: Number,
        default: 0,
        validator(val) {
          return typeof val === 'number';
        }
      },
      selected: Boolean,
      title: {
        type: String,
        default: 'Title'
      },
      inputs: Array,
      outputs: Array,
      color: {
        type: String,
        default: ''
      },
      used: {
        type: Boolean,
        default: false,
      },
      backLink: {
        type: String,
        default: '',
      },
      options: {
        type: Object
      },
      actionType: {
        type: String,
        default: ''
      },
      actionName: {
        type: String,
        default: ''
      },
      objectType: {
        type: String,
        default: ''
      },
      name: {
        type: String,
        default: '',
      },
      actionID: {
        type: Number,
        default: -1
      }
    },
    created() {
      this.mouseX = 0;
      this.mouseY = 0;

      this.lastMouseX = 0;
      this.lastMouseY = 0;

      this.linking = false;
      this.dragging = false;
    },
    mounted() {
      document.documentElement.addEventListener('mousemove', this.handleMove, true);
      document.documentElement.addEventListener('mousedown', this.handleDown, true);
      document.documentElement.addEventListener('mouseup', this.handleUp, true);
      document.documentElement.addEventListener('touchstart', this.handleDown, { passive: false });
      document.documentElement.addEventListener('touchmove', this.handleMove, { passive: false });
      document.documentElement.addEventListener('touchend', this.handleUp, { passive: false });
      document.documentElement.addEventListener('contextmenu', this.handleContextmenu, true);
    },
    beforeDestroy() {
      document.documentElement.removeEventListener('mousemove', this.handleMove, true);
      document.documentElement.removeEventListener('mousedown', this.handleDown, true);
      document.documentElement.removeEventListener('mouseup', this.handleUp, true);
      document.documentElement.removeEventListener('touchstart', this.handleDown, { passive: false });
      document.documentElement.removeEventListener('touchmove', this.handleMove, { passive: false });
      document.documentElement.removeEventListener('touchend', this.handleUp, { passive: false });
      document.documentElement.removeEventListener('contextmenu', this.handleContextmenu, true);
    },
    data() {
      return {
        tooltip_activator: false,
        entered: false,
        moving: false,
        width: this.options.width,
        hasDragged: false,
        dragging: false,
        counter: 0,
        timer: null
      };
    },
    watch: {
      counter() {
        if (this.counter > 1) {
          this.openModal();
        }
      },
      // entered() {
      //   if (this.entered && !this.dragging) {
      //     this.tooltip_activator = true;
      //   } else {
      //     this.tooltip_activator = false;
      //   }
      // },
      // dragging() {
      //   if (this.entered && !this.dragging) {
      //     this.tooltip_activator = true;
      //   } else {
      //     this.tooltip_activator = false;
      //   }
      // },
    },
    methods: {
      handleMove(e) {
        if (e.type === 'touchstart' || e.type === 'touchmove' || e.type === 'touchend' || e.type === 'touchcancel') {
          const touch = e.changedTouches[0];
          this.mouseX = touch.pageX + document.documentElement.scrollLeft;
          this.mouseY = touch.pageY + document.documentElement.scrollTop;
        } else {
          this.mouseX = e.clientX + document.documentElement.scrollLeft;
          this.mouseY = e.clientY + document.documentElement.scrollTop;
        }

        if (this.dragging && !this.linking) {
          const diffX = this.mouseX - this.lastMouseX;
          const diffY = this.mouseY - this.lastMouseY;

          this.lastMouseX = this.mouseX;
          this.lastMouseY = this.mouseY;

          this.moveWithDiff(diffX, diffY);

          this.hasDragged = true;
        }
      },
      handleDown(e) {
        if (e.type === 'touchstart' || e.type === 'touchmove' || e.type === 'touchend' || e.type === 'touchcancel') {
          const touch = e.changedTouches[0];
          this.mouseX = touch.pageX + document.documentElement.scrollLeft;
          this.mouseY = touch.pageY + document.documentElement.scrollTop;
        } else {
          this.mouseX = e.clientX + document.documentElement.scrollLeft;
          this.mouseY = e.clientY + document.documentElement.scrollTop;
        }

        this.lastMouseX = this.mouseX;
        this.lastMouseY = this.mouseY;

        const target = e.target || e.srcElement;
        if (this.$el.contains(target)) {
          this.dragging = true;

          this.$emit('select');

          if (e.preventDefault) e.preventDefault();
        }
      },
      handleContextmenu(e) {
        // e.preventDefault();
        this.mouseX = e.pageX || e.clientX + document.documentElement.scrollLeft;
        this.mouseY = e.pageY || e.clientY + document.documentElement.scrollTop;

        this.lastMouseX = this.mouseX;
        this.lastMouseY = this.mouseY;

        const target = e.target || e.srcElement;
        if (this.$el.contains(target) && e.which === 1) {
          this.dragging = true;

          this.$emit('blockRightClick');

          if (e.preventDefault) e.preventDefault();
        }
      },
      openModal() {
        if (this.actionID >= 0) {
          this.$emit('openModal', {actionID: this.actionID, modal_type: this.objectType});
        }
      },
      doubleClick() {
        this.counter++;
        if (this.counter) {
          this.timer = setTimeout(() => { this.counter = 0; }, 700);
        } else {
          clearTimeout(this.timer);
        }
      },
      handleUp() {
        if (this.dragging) {
          this.dragging = false;

          if (this.hasDragged) {
            this.save();
            this.hasDragged = false;
          }
        }

        if (this.linking) {
          this.linking = false;
        }
      },
      // Slots
      slotMouseDown(e, index) {
        this.linking = true;

        this.$emit('linkingStart', index);
        if (e.preventDefault) e.preventDefault();
      },
      slotMouseUp(e, index) {
        this.$emit('linkingStop', index);
        if (e.preventDefault) e.preventDefault();
      },
      slotBreak(e, index) {
        this.linking = true;

        this.$emit('linkingBreak', index);
        if (e.preventDefault) e.preventDefault();
      },
      save() {
        this.$emit('update');
      },
      deleteBlock() {
        this.$emit('delete');
      },
      moveWithDiff(diffX, diffY) {
        const left = this.x + diffX / this.options.scale;
        const top = this.y + diffY / this.options.scale;

        this.$emit('update:x', left);
        this.$emit('update:y', top);
      },
      objectStroke() {
        if (this.objectType === 'menu' || this.objectType === 'queue') {
          return '1px solid rgba(181, 211, 219, 1)';
        } if (this.objectType === 'preset') {
          return '1px solid rgb(70, 112, 53, 0.25)';
        } if (this.objectType === 'extension') {
          return '1px solid rgba(133, 146, 154, 0.25)';
        } if (this.objectType === 'number') {
          return '1px solid rgb(133, 131, 114, 0.25)';
        }
        return '1px solid rgba(232, 208, 205, 1)';
      }
    },
    computed: {
      blockStyle() {
        return {
          top: `${this.options.center.y + this.y * this.options.scale}px`,
          left: `${this.options.center.x + this.x * this.options.scale}px`,
          width: `${this.width}px`,
          transform: `scale(${this.options.scale})`,
          'background': this.color || 'rgba(234, 245, 248, 0.8)',
          'border': this.objectStroke(),
          transformOrigin: 'top left'
        };
      },
      autoHeight() {
        let inputHeight = 32;
        if (this.objectType === 'menu') {
          inputHeight = 28;
        } else if (this.objectType === 'Call flow') {
          inputHeight = 0;
        }
        const outputs = this.outputs.length ? this.outputs.length * 21 : 21;
        return {
          'height': `${outputs + inputHeight}px`,
        };
      },
    }
  };
</script>

<style lang="less" scoped>
  @blockBorder: 0px;

  @ioPaddingInner: 2px 0;
  @ioHeight: 21px;
  @ioFontSize: 11px;

  @circleBorder: 4px;
  @circleSize: 12px;
  @circleMargin: 2px; // left/right

  @circleNewColor: #364047;;
  @circleRemoveColor: #FF0000;
  @circleConnectedColor: #FFFF00;

  .vue-block {
    position: absolute;
    box-sizing: border-box;
    border: @blockBorder solid black;
    border-radius: 9px;
    color: white;
    border: 1px solid transparent;
    background: white;
    z-index: 1;
    opacity: 0.9;
    cursor: grab;

    &.selected {
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
      z-index: 2;
    }

    > header {
      text-align: center;

      > .delete {
        color: white;
        cursor: pointer;
        float: right;
        position: absolute;
        right: 5px;
        text-decoration: none;
      }
    }
    .inputs, .outputs {
      padding: @ioPaddingInner;
      color: #364047;
      display: block;
      width: 50%;

      > * {
        width: 100%;
      }
    }

    .circle {
      box-sizing: border-box;
      margin-top: @ioHeight / 2 - @circleSize / 2 - 2;

      width: @circleSize;
      height: @circleSize;

      border: @circleBorder solid rgba(54, 64, 71, 1);
      border-radius: 100%;

      cursor: crosshair;
      background-color: white;
    }

    .inputs {
      float: left;
      text-align: left;
      width: 5% !important;
      margin-left: -(@circleSize/2 + @blockBorder);
    }

    .input, .output {
      height: @ioHeight;
      overflow: hidden;
      font-size: @ioFontSize;
    }

    .input {
      float: left;
      display: flex;
      align-items: center;

      .circle {
        float: left;
        margin-right: @circleMargin;
        margin-left: 2px !important;
        margin-top: 0px !important;
        width: 5px !important;
        height: 5px !important;

        &:hover {
          background: @circleNewColor;

          &.active {
            background: @circleRemoveColor !important;
          }
        }
      }
    }

    .outputs {
      float: right;
      text-align: right;
      width: 95% !important;
      display: flex;
      flex-direction: column;
      margin-right: -7px;
    }

    .output {
      float: right;

      .outputLabel {
        margin-right: 19px;
        max-height: 20px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .circle {
        float: right;
        margin-left: @circleMargin;

        &:hover {
          background: @circleNewColor;
        }
      }
    }
  }
  .actionKey {
    display: inline-flex;
    background: #2A9BB7;
    border-radius: 3px;
    width: 18px;
    justify-content: center;
    color: #FFFFFF;
  }
  .svgIcon {
    vertical-align: middle;
    float: left;
    width: 15px;
    height: 15px;
    margin-bottom: 1px;
    margin-right: 5px;
    margin-left: 8px;
    color: #364047;
  }
  .nameHolder {
    // height: 20px !important;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .menuNameHolder {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin-right: 7px;
    max-width: 85%;
  }
  .menuRow {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }
  .numberAndExtensionTitle {
    margin-top: -21px;
    margin-left: 4px;
    font-size: 10px;
  }
  .numberAndExtensionHeader {
    color: #364047;
    background-color: transparent !important;
    font-weight: 500;
    font-size: 16px;
    width: 93% !important;
    padding-top: 6px !important;
    padding-bottom: 6px !important;
    height: 32px !important;
    display: flex;
    justify-content: flex-end;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    align-items: center;
    float: right;
    border-bottom: none !important;
    margin-right: 9px;
    position: unset !important;
  }
  // .heightOutput {
  //   height: 21px !important
  // }
  .outputsHeight {
    padding-top: 6px !important;
    padding-bottom: 6px !important;
  }
  .heightInput {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-bottom: 10px;
  }
  .inputColor {
    background-color: #8C98A0 !important;
    border: none !important;
  }
  .headerStyle {
    font-weight: 700;
    color: #364047;
    font-size: 16px;
    display: flex;
    align-items: center;
    height: auto !important;
    border-bottom: none !important;
    text-align: center !important;
    background-color: transparent !important;
    position: unset !important;
  }
  // .line {
  //   height: 1px;
  //   background: rgba(133, 146, 154, 0.5);
  // }
  .filterName {
    font-weight: 700;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    // margin-top: 4px
  }
  .leftSideLabel {
    text-align: left;
  }
  .outputName {
    border: 0.5px solid #8c98a0;
    position: absolute;
    width: max-content;
    padding-left: 3px;
    padding-right: 3px;
    max-width: 95px;
    max-height: 18px;
    border-radius: 14px 4px 4px 14px;
    margin-left: 218px;
    text-align: left;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
</style>
