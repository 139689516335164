<template>
	<div v-if="contact">
	    <v-col v-if="title" md="12" class="pl-0">
	        <SectionTitle data-test-id="acc-contact-title">
	            {{ title }}
	        </SectionTitle>
	    </v-col>
		<template v-if="!address_only">
			<FormInput :label="l.t('app.name', 'Name')">
				<w-text-field
					v-model="contact.name"
					:disabled="!!disabled"
					:rules="[$validation.required()]"
					hide-details='auto'
					data-test-id="acc-contact-name-input"
				></w-text-field>
			</FormInput>
			<FormInput :label="l.t('app.company', 'Company')">
				<w-text-field
					v-model="contact.company"
					:disabled="!!disabled"
					hide-details='auto'
					data-test-id="acc-contact-company-input"
				></w-text-field>
			</FormInput>
		</template>
		<FormInput :label="l.t('addressbook.country', 'Country')">
			<CountrySelector
				v-model="contact.address.country"
				:available_countries="countries"
				@change="on_country_change"
				data-test-id="acc-contact-country-selector"
			></CountrySelector>
		</FormInput>
		<FormInput :label="l.t('app.street-address', 'Street address')">
			<w-text-field
				v-model="contact.address.line_1"
				:disabled="!!disabled"
				:rules="[$validation.required()]"
				hide-details='auto'
				data-test-id="acc-contact-street-add-input"
			></w-text-field>
		</FormInput>
		<FormInput :label="l.t('app.secondary-street-address', 'Secondary street address')">
			<w-text-field
				v-model="contact.address.line_2"
				:disabled="!!disabled"
				hide-details='auto'
				data-test-id="acc-contact-street-add-2-input"
			></w-text-field>
		</FormInput>
		<FormInput :label="l.t('app.city', 'City')">
			<w-text-field
				v-model="contact.address.city"
				:disabled="!!disabled"
				hide-details='auto'
				:rules="[$validation.required()]"
				data-test-id="acc-contact-city-input"
			></w-text-field>
		</FormInput>
		<FormInput :label="province_label">
			<template v-if="contact.address && ['US', 'CA'].includes(contact.address.country)">
				<USStatesSelector
					v-if="contact.address.country === 'US'"
					v-model="contact.address.province"
					required
				/>
				<CanadaProvincesSelector
					v-else
					v-model="contact.address.province"
					required
				/>
			</template>
			<w-text-field
				v-else
				v-model="contact.address.province"
				:disabled="!!disabled"
				hide-details='auto'
				data-test-id="acc-contact-province-input"
			></w-text-field>
		</FormInput>
		<FormInput :label="postal_code_label">
			<template v-if="contact.address && ['US', 'CA'].includes(contact.address.country)">
				<USZipCode
					v-if="contact.address.country === 'US'"
					v-model="contact.address.postal_code"
					:validation="true"
					required
				/>
				<CanadianPostalCode
					v-else-if="contact.address.country === 'CA'"
					v-model="contact.address.postal_code"
					:validation="true"
					required
				/>
			</template>
			<w-text-field
				v-else
				v-model="contact.address.postal_code"
				min="1"
				:disabled="!!disabled"
				:rules="[$validation.required()]"
				hide-details='auto'
				data-test-id="acc-contact-postal-code-input"
			></w-text-field>
		</FormInput>
		<FormInput :label="l.t('app.phone-number', 'Phone number')">
			<Tel
				:value="contact.phone"
				:disabled="!!disabled"
				@changed="contact.phone = $event.clean_number"
				:required="false"
				data-test-id="acc-contact-phone-input"
			/>
		</FormInput>
		<template v-if="!address_only">
			<FormInput :label="l.t('app.fax', 'Fax')">
				<Tel
					:value="contact.fax"
					:disabled="!!disabled"
					@changed="contact.fax = $event.clean_number"
					:required="false"
					data-test-id="acc-contact-fax-input"
				/>
			</FormInput>
			<FormInput :label="l.t('app.email', 'Email')">
				<w-text-field
					type="email"
					v-model="contact.primary_email"
					:disabled="!!disabled"
					:rules="[$validation.required(), $validation.email()]"
					hide-details='auto'
					data-test-id="acc-contact-email-input"
				></w-text-field>
			</FormInput>
			<FormInput :label="l.t('app.secondary-email', 'Secondary email')">
				<w-text-field
					type="email"
					v-model="contact.alternate_email"
					:disabled="!!disabled"
					:rules="[$validation.email()]"
					hide-details='auto'
					data-test-id="acc-contact-secondary-email-input"
				></w-text-field>
			</FormInput>
		</template>
		<template v-if="submittable">
			<FormInput>
				<w-btn
				   depressed
				   color="primary"
				   @click="$emit('submitted', contact)"
				   :disabled="!!disabled"
				   data-test-id="acc-contact-submit-btn"
				>
					{{ l.t('app.submit', 'Submit') }}
				</w-btn>
			</FormInput>
		</template>
	</div>
</template>

<script>
import l from '../../libs/lang';
import Tel from './Tel.vue';
import FormInput from './form/FormInput.vue';
import SectionTitle from './SectionTitle.vue';
import USZipCode from './address/USZipCode.vue';
import CountrySelector from './CountrySelector.vue';
import USStatesSelector from './address/USStatesSelector.vue';
import CanadianPostalCode from './address/CanadianPostalCode.vue';
import CanadaProvincesSelector from './address/CanadaProvincesSelector.vue';

export default {
	props: ['_contact', 'title', 'address_only', 'disabled', 'submittable', 'countries', 'reset_address_on_country_change'],
	components: {
		Tel,
		USZipCode,
		FormInput,
		SectionTitle,
		CountrySelector,
		USStatesSelector,
		CanadianPostalCode,
		CanadaProvincesSelector,
	},
	created() {
		this.$data.contact = this.$props._contact;
	},
	data() {
		return {
			l,
			contact: null,
		};
	},
	methods: {
		on_country_change(data) {
			if (data !== this.$data.contact.address.country) {
				for (const key of Object.keys(this.$data.contact.address)) {
					this.$data.contact.address[key] = '';
				}
			}
			this.$data.contact.address.country = data;
		}
	},
	computed: {
		province_label() {
			let label;
			if (this.$data.contact && this.$data.contact.address && this.$data.contact.address.country === 'US') {
				label = l.t('app.state', 'State');
			} else {
				label = l.t('app.province', 'Province');
			}

			return label;
		},
		postal_code_label() {
			let label;
			if (this.$data.contact && this.$data.contact.address && this.$data.contact.address.country === 'US') {
				label = l.t('app.zip-code', 'Zip code');
			} else {
				label = l.t('app.postal-code', 'Postal code');
			}

			return label;
		}
	},
	watch: {
		contact: {
			deep: true,
			handler(value) {
				const val = JSON.parse(JSON.stringify(value));
				if (!val.address.postal_code) val.address.postal_code = null;
				if (!val.alternate_email) val.alternate_email = null;

				this.$emit('changed', val);
			},
		},
	},
};
</script>
