<template>
    <v-dialog v-model="show" width="350" @click:outside="$emit('closed')" v-bind="$attrs" v-on="$listeners" @keydown.esc="$emit('closed')" data-test-id="discard-changes-modal">
        <v-card class="discard-changes-content">
            <div class="discard-changes-title w-subtitle-1">
                {{ l.t('app.are-you-sure-you-want-to-leave-this-page', 'Are you sure you want to leave this page') }}?
            </div>
            <div class="discard-changes-body">
                {{ l.t('app.you-have-unsaved-changes', 'You have unsaved changes that will be lost.')}}
            </div>
            <div>
                <div class="">
                    <w-btn @click="$emit('closed')" color="grey" class="white--text"
                        data-test-id="discard-changes-modal-cancel-btn">
                        {{ l.t('app.cancel', 'Cancel') }}
                    </w-btn>

                    <w-btn @click="$emit('discard')" class="white--text confirm-btn" color="error"
                        data-test-id="discard-changes-modal-btn">
                        {{ l.t('app.discard-changes', 'Discard changes') }}
                    </w-btn>
                </div>
            </div>
        </v-card>
    </v-dialog>
</template>
<script>
import l from '../../../libs/lang';

  export default {
    props: [],
    data() {
        return {
            l,
            show: true,
        };
    },
  };
</script>
<style lang="scss" scoped>
.discard-changes-content {
        padding: 40px 30px;
        .discard-changes-title {
            margin-bottom: 15px;
        }
        .discard-changes-body {
            margin-bottom: 30px;
        }
        .confirm-btn {
            margin-left: 15px;
        }
    }
</style>
