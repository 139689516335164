<template>
    <w-loader v-if="loading_global"/>
    <div v-else data-test-id="audio-conferencing-index-page">
        <PageTitle>{{ l.t('app.audio-conferencing', 'Audio conferencing') }}</PageTitle>
        <div>
            <FormInput v-if="!auto_selected_extension" :label="l.t('app.extension', 'Extension')">
                <ExtensionSelector
                    :select_if_one="true"
                    @changed="mobile_extension = $event.id"
                    @only_one_auto_selected="auto_selected_extension = true"
                    data-test-id="audio-conferencing-extension-selector"
                />
            </FormInput>
            <w-loader v-if="loading"/>
            <div v-else-if="mobile_extension_data">
                <div v-if="mobile_extension_data['conference']">
                    <SectionTitle data-test-id="audio-conferencing-credentials-title">
                        {{ l.t("mobile-apps.credentials", "Credentials") }}
                    </SectionTitle>
                    <v-container fluid>
                        <v-row>
                            <v-col md="3" class="pl-0">
                                <div class="py-3">{{ l.t('app.phone-number', 'Phone number') }}</div>
                            </v-col>
                            <v-col md="9">
                                <div class="w-body-2 py-3" data-test-id="audio-conferencing-phone-number">{{ mobile_extension_data["conference"]["bridge_number"] | format_phone_number }}</div>
                            </v-col>
                            <v-col md="3" class="pl-0">
                                <div class="py-3">{{ l.t('conferencing.conference_id', 'Conference ID') }}</div>
                            </v-col>
                            <v-col md="9">
                                <div class="w-body-2 py-3" data-test-id="audio-conferencing-conference-id">{{ mobile_extension_data["conference"]["conference_id"] }}</div>
                            </v-col>
                            <v-col md="3" class="pl-0">
                                <div class="py-3">{{ l.t('conferencing.moderator_pin', 'Moderator PIN') }}</div>
                            </v-col>
                            <v-col md="9">
                                <div class="w-body-2 py-3 fs-exclude" data-test-id="audio-conferencing-moderator-pin">
                                    <a v-if="hide_password" @click="hide_password = false" data-test-id="audio-conferencing-show-pin">{{ l.t("app.show", "Show") }}</a>
                                    <span v-else data-test-id="audio-conferencing-pin">{{ mobile_extension_data["conference"]["moderator_pin"] }}</span>
                                </div>
                            </v-col>
                        </v-row>
                    </v-container>
                </div>
                <div v-else class="mt-8">
                    <w-alert
                      level="warning"
                      :message="l.t('conferencing.not-enabled', 'Conferencing is not enabled for this extension')"
                      :closable="false"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
  import l from '../../libs/lang';
  import PageTitle from '../elements/PageTitle.vue';
  import FormInput from '../elements/form/FormInput.vue';
  import SectionTitle from '../elements/SectionTitle.vue';
  import ExtensionSelector from '../fragments/ExtensionSelector.vue';

  export default {
    components: {
      PageTitle,
      FormInput,
      SectionTitle,
      ExtensionSelector,
    },
    data() {
      return {
        l,
        loading_global: false,
        loading: false,
        mobile_extension: null,
        mobile_extension_data: null,
        auto_selected_extension: false,
        hide_password: true,
      };
    },
    watch: {
      mobile_extension(value) {
        if (value) {
          this.$data.loading = true;
          this.$session.get_item(`/extensions/${value}`).then((res) => {
            this.$data.hide_password = true;
            this.$data.mobile_extension_data = res;
            this.$data.loading = false;
          });
        } else {
          this.$data.mobile_extension_data = null;
        }
      },
    },
  };
</script>
