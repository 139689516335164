<template>
  <IndexPage
    :resource="message"
    :withExtensionSelector="true"
    :nullable_extension="false"
    :extensionCb="'load_saved_filters'"
    class="list-page messages"
    :label_cols="label_cols"
    data-test-id="messages-list-page"
  >
    <template v-slot:filters>
      <SmartFilters :log="message" :key="JSON.stringify(message.client_config)" :label_cols="label_cols"/>
      <FormInput :label="l.t('app.only', 'Only')" :label_cols="label_cols">
        <div class="d-flex">
          <w-switch
            v-model="message.filters.deleted"
            @change="message.restartSavedFilterName()"
            :label="l.t('app.deleted', 'Deleted')"
            class="mr-3"
            hide-details='auto'
          />
          <w-switch
            :label="l.t('messages.read', 'Read')"
            v-model="message.filters.read"
            @change="message.restartSavedFilterName()"
            hide-details='auto'
          />
        </div>
      </FormInput>
      <FormInput :label="l.t('app.direction', 'Direction')" :label_cols="label_cols">
        <w-radio-group
          v-model="message.filters.direction"
          row
          @change="message.restartSavedFilterName()"
          hide-details='auto'
          :key="message.saved_filter_name"
        >
          <v-radio
            :label="l.t('app.in', 'In')"
            value="in"
            hide-details='auto'
          ></v-radio>
          <v-radio
            :label="l.t('app.out', 'Out')"
            value="out"
            hide-details='auto'
          ></v-radio>
          <v-radio
            :label="l.t('app.both', 'Both')"
            :value="null"
            hide-details='auto'
          ></v-radio>
        </w-radio-group>
      </FormInput>
      <FormInput :label="l.t('app.from', 'From')" :label_cols="label_cols">
        <Tel
          :key="message.saved_filter_name"
          :value="message.filters.from"
          @changed="message.filter_param_changed('from', $event.clean_number)"
          :allow_short_code="true"
        />
      </FormInput>
      <FormInput :label="l.t('app.to', 'To')" :label_cols="label_cols">
        <Tel
          :key="message.filters.to"
          :value="message.filters['to']"
          @changed="message.filter_param_changed('to', $event.clean_number)"
          :allow_short_code="true"
        />
      </FormInput>
    </template>
    <template v-slot:additionalFilterButtons v-if="message.items.length">
      <SaveFilter
        v-if="message.filters_applied && !message.saved_filter_name"
        :generate_name_callback="message.generateNewFilterName.bind(message)"
        @confirmed="message.save_filters(null, $event)"
      />
      <w-btn
        type="button"
        @click="message.generate_csv()"
        class="primary--text text--darken-1 primary lighten-5 mr-5"
        color="primary"
      >
        {{ l.t("app.get-csv", "Get CSV") }}
      </w-btn>
    </template>
    <template v-slot:list>
      <v-simple-table class="list-table" data-test-id="messages-table">
        <thead data-test-id="table-head">
          <tr data-test-id="head-row">
            <th class="select-table-item" data-test-id="checkbox"><br /></th>
            <th data-test-id="received-at">{{ l.t("app.received-at", "Received at") }}</th>
            <th data-test-id="type">{{ l.t("app.type", "Type") }}</th>
            <th data-test-id="from">{{ l.t("app.from", "From") }}</th>
            <th data-test-id="to">{{ l.t("app.to", "To") }}</th>
            <th data-test-id="text">{{ l.t("app.text", "Text") }}</th>
            <th data-test-id="media">{{ l.t('app.media', 'Media') }}</th>
            <th data-test-id="delete"><br/></th>
          </tr>
        </thead>
        <tbody data-test-id="table-body">
          <tr v-for="(m, index) in message.items" :key="m['id']" :class="{'selected-row': m.selected}" :data-test-id="m.id">
            <td v-if="message.deleting_item_id === m.id" colspan="8" data-test-id="loader">
              <w-loader size="small" :no_margins="true"/>
            </td>
            <template v-else>
              <td class="select-table-item" data-test-id="checkbox">
                <w-checkbox
                  v-model="m.selected"
                  @change="message.checkSubmission()"
                  :key="`extensions-item-${m.id}-${m.selected}`"
                  hide-details="auto"
                  class="multiple-select-checkbox"
                />
              </td>
              <td data-test-id="created-at">
                {{ new Date(m["created_at"] * 1000).toLocaleString() }}
              </td>
              <td data-test-id="direction">
                {{ m["direction"] === 'out' ? 'Outbound' : 'Inbound' }} {{ m["type"].toUpperCase() }}
              </td>
              <td class="nowrap" data-test-id="from">{{ m["from"] | format_phone_number }}</td>
              <td class="nowrap" data-test-id="to">
                <template v-for="(number, index) in m['to']">
                  {{ number["number"] | format_phone_number }}<template v-if="m['to'].length > 1 && index !== m['to'].length-1">, <br></template>
                </template>
              </td>
              <td class="long-line fs-exclude" :key="m.id+!!m.read_all" data-test-id="text">
                <template v-if="m['text'].length > 50 && !m.read_all">
                  {{ m["text"].slice(0, 47) }}... <a @click="message.readMore(index)" data-test-id="read-more">{{ l.t("messages.more", "More") }}</a>
                </template>
                <template v-else-if="m.read_all">
                  {{ m["text"] }} <a @click="message.readLess(index)">{{ l.t("messages.hide", "Hide") }}</a>
                </template>
                <template v-else>
                  {{ m["text"] }}
                </template>
              </td>
              <td class="fs-exclude ellipsis-text" data-test-id="media">
                <template v-if="(m['media'] && m['media'].length)">
                  <a
                    v-for="mm in m['media']"
                    :href="mm['url']"
                    :key="mm['url']"
                    target="_blank"
                    class="list-link d-flex"
                  >
                    <v-icon class="list-link mr-2">$vuetify.icon.attachment</v-icon>
                    <span class="my-auto ellipsis-text">
                      {{ mm["filename"] }}
                    </span>
                  </a>
                </template>
                <span v-else>&mdash;</span>
              </td>
              <td data-test-id="delete">
                <v-icon @click="confirmation_modal = m.id" color="error">$vuetify.icons.trash_circled</v-icon>
                <ConfirmationModal
                  :key="m['id']"
                  v-if="confirmation_modal === m.id"
                  @confirmed="confirmed(m['id'])"
                  @close="confirmation_modal = false"
                  :message="l.t('app.are-you-sure-delete-message','Are you sure you want to remove this message? You will not be able to access it after removing.')"
                />
              </td>
            </template>
          </tr>
        </tbody>
      </v-simple-table>
    </template>
  </IndexPage>
</template>

<script>
import l from '../../../libs/lang';
import Helpers from '../../../libs/helpers';
import Message from '../../../models/Message';
import Tel from '../../elements/Tel.vue';
import IndexPage from '../../elements/IndexPage.vue';
import FormInput from '../../elements/form/FormInput.vue';
import SmartFilters from '../../elements/SmartFilters.vue';
import SaveFilter from '../../elements/modal/SaveFilter.vue';
import ConfirmationModal from '../../elements/modal/ConfirmationModal.vue';

export default {
  components: {
    Tel,
    IndexPage,
    FormInput,
    SaveFilter,
    SmartFilters,
    ConfirmationModal,
  },
  data() {
    return {
      l,
      message: new Message(this.$session, Helpers.emitter(this), Helpers.changeRoute(this), () => this.$emit('forceUpdate')),
      confirmation_modal: null,
      label_cols: 2,
    };
  },
  created() {
    this.$data.message.loading = true;
  },
  mounted() {
    this.$parent.$on('userRedirected', (to, from, next) => {
      this.$data.message.handleUserRedirect(to, from, next);
    });
  },
  methods: {
    async confirmed(id) {
      this.$data.confirmation_modal = false;
      await this.$data.message.delete_m(id);
    }
  }
};
</script>

<style lang="scss" scoped>

tr.unread {
  font-weight: bold;
}
.long-line {
  max-width: 30%;
}
</style>
