<template>
    <div class="mt-3" data-test-id="contacts-import-page">
        <div v-if="contact.loading">
            <w-loader v-if="!contact.importing"/>
            <ProgressBar
              v-else
              :key="contact.importing.status.length"
              :processing="contact.importing"
              :label="l.t('contacts.total-contacts-imported', 'Total contacts to be imported')"
              @stopProcess="contact.stopImporting = true"
              data-test-id="contacts-import-progressbar"
            />
        </div>
        <div v-else>
            <FormInput v-if="!one_extension" :label="l.t('app.extension', 'Extension')" class="mb-3" :tight="true">
                <ExtensionSelector
                  :select_if_one="true"
                  @changed="extension = $event.id"
                  @only_one_auto_selected="one_extension = true"
                  data-test-id="contacts-import-extensions-selector"
                />
            </FormInput>
            <w-alert
              v-if="contact.alert"
              :message="contact.alert.message"
              :level="contact.alert.level"
              :closable="contact.enable_close_alert"
              @closed="contact.alert = null"
            />
            <w-stepper v-model="contact.importing_wizard">
                <v-stepper-header>
                  <v-stepper-step
                    :complete="contact.importing_wizard > 1"
                    step="1"
                    data-test-id="contacts-import-step-1-title"
                  >
                    {{ l.t('app.upload-a-file', 'Upload a file') }}
                  </v-stepper-step>

                  <v-divider></v-divider>

                  <v-stepper-step
                    :complete="contact.importing_wizard > 2"
                    step="2"
                    data-test-id="contacts-import-step-2-title"
                  >
                    {{ l.t('contacts.review-contacts', 'Review contacts') }}
                  </v-stepper-step>

                  <v-divider></v-divider>

                  <v-stepper-step step="3" data-test-id="contacts-import-step-3-title">
                    {{ l.t('contacts.review-results', 'Review results') }}
                  </v-stepper-step>
                </v-stepper-header>

                <v-stepper-items>
                  <v-stepper-content step="1" data-test-id="contacts-import-step-1-content">
                      <FileUploader
                        @submitted="contact.import_csv()"
                        @uplaoded="contact.csvFileUploaded($event, 'file')"
                        @removed="contact.file = null"
                        :button_text="l.t('addressbook.import-from-csv', 'Import contacts from .csv file')"
                        data-test-id="contacts-import-step-1-file-uploader"
                      >
                        <p class="help space-top" data-test-id="contacts-import-step-1-help-text">
                          <div class="font-weight-bold">
                            {{ l.t('addressbook.help-importing-1', 'Note: Unavailable values in the file might be empty strings. Not all fields are used by Phone.com.') }}
                          </div>
                          <div>
                            {{ l.t('addressbook.compatible-import', 'Compatible import file formats include: [Phone.com, LA Pro]') }}
                          </div>
                          <div>
                            {{ l.t('addressbook.depicted-files', 'The Import CSV file must be in the format depicted in the downloadable templates below.') }}
                          </div>
                        </p>
                        <div>
                            <a
                              href="/files/phonecom-contacts-csv-example.csv"
                              download
                              class="mr-5"
                              data-test-id="contacts-import-step-1-phonecom-download-template"
                            >
                              {{ l.t('app.download-phonecom-template-file', 'Phone.com template file') }}
                            </a>
                            <a
                              href="/files/lapro-contacts-csv-example.csv"
                              download
                              data-test-id="contacts-import-step-1-lapro-download-template"
                            >
                              {{ l.t('app.download-lapro-template-file', 'LA Pro template file') }}
                            </a>
                        </div>
                    </FileUploader>
                  </v-stepper-content>

                  <v-stepper-content step="2" data-test-id="contacts-import-step-2-content">
                    <CsvValidationErrors
                        :errors="contact.csv_incorrect_lines"
                        :key="contact.csv_incorrect_lines.length"
                        data-test-id="contacts-import-step-2-csv-errors"
                    />
                    <LongTable :items="contact.items" data-test-id="contacts-import-step-2-long-table">
                        <template v-slot:header>
                            <th>
                                <w-checkbox v-model="contact.check_all" hide-details='auto' data-test-id="contacts-import-step-2-check-all"/>
                            </th>
                            <th>{{ l.t("app.name", "Name") }}</th>
                            <th>
                                {{ l.t("app.phone-numbers", "Phone numbers") }}
                            </th>
                            <th>{{ l.t("app.company", "Company") }}</th>
                            <th>{{ l.t("app.group", "Group") }}</th>
                            <th><br/></th>
                        </template>
                        <template v-slot:body>
                            <template
                                v-for="(item, c_index) of contact.items"
                            >
                                <tr
                                    :class="{ selected: item['selected'] }"
                                    :key="item['id']"
                                    @click="toggleOpened($event, c_index)"
                                    :data-test-id="`contacts-import-step-2-table-row-${c_index}`"
                                >
                                    <td class="prevent-toggle">
                                        <w-checkbox
                                          v-model="item.selected"
                                          class="prevent-toggle"
                                          @change="contact.checkSubmission()"
                                          :key="`contact-item-${item.id}-${item.selected}`"
                                          hide-details='auto'
                                          :data-test-id="`contacts-import-step-2-${c_index}-checkbox`"
                                        />
                                    </td>
                                    <td>
                                        {{ contact.constructor.assemble_display_name(item) }}
                                    </td>
                                    <td v-if="item.phone_numbers && item.phone_numbers.length">
                                        <template v-for="phone in item.phone_numbers">
                                            {{ phone.type }}: {{ phone.number | format_phone_number }} <br/>
                                        </template>
                                    </td>
                                    <td v-else>
                                        &mdash;
                                    </td>
                                    <td v-if="item.company">{{ item.company }}</td>
                                    <td v-else>&mdash;</td>
                                    <td v-if="item.group" :title="item.group.id ? l.t('contacts.existing-group', 'Existing group') : l.t('contacts.group-will-be-created', 'Group will be created')">
                                        <router-link
                                          v-if="item.group.id && $branding.data.routes.includes('groups.contacts')"
                                          class="prevent-toggle"
                                          :to="{
                                              'name': 'groups.contacts',
                                               'params': {
                                                  'id':item.group.id,
                                                  'extension_id': contact.extension,
                                              }
                                          }"
                                          :data-test-id="`contacts-import-step-2-${c_index}-table-item-link`"
                                        >
                                                {{ item.group.name }}
                                        </router-link>
                                        <span v-else>{{ item.group.name }}</span>
                                    </td>
                                    <td v-else>&mdash;</td>
                                </tr>
                                <tr
                                    v-if="c_index === opened_contact"
                                    :key="item['id']"
                                    :data-test-id="`contacts-import-step-2-${c_index}-subtable-row`"
                                >
                                  <td colspan="8">
                                    <v-simple-table :data-test-id="`contacts-import-step-2-${c_index}-subtable`">
                                      <tbody data-test-id="table-body">
                                        <tr v-for="key of Object.keys(item).filter(x => x !== 'selected')" :data-test-id="item.id">
                                          <th data-test-id="key">{{ key | prepareKeyText }}</th>
                                          <td v-if="Array.isArray(item[key]) && item[key].length" data-test-id="details">
                                            <v-simple-table data-test-id="details-table">
                                              <thead data-test-id="table-head">
                                                <tr data-test-id="head-row">
                                                  <th data-test-id="index">#</th>
                                                  <th
                                                    v-for="sub_key of Object.keys(item[key][0])"
                                                    v-bind:key="sub_key"
                                                    :data-test-id="sub_key"
                                                  >
                                                    {{ sub_key | prepareKeyText }}
                                                  </th>
                                                </tr>
                                              </thead>
                                              <tbody data-test-id="table-body">
                                                <tr
                                                  v-for="(detail, index) of item[key]"
                                                  v-bind:key="index"
                                                  :data-test-id="index"
                                                >
                                                  <td data-test-id="index">{{ index + 1 }}</td>
                                                  <td
                                                    v-for="sub_key of Object.keys(item[key][index])"
                                                    v-bind:key="sub_key"
                                                    :data-test-id="sub_key"
                                                  >
                                                    {{ detail[sub_key] }}
                                                  </td>
                                                </tr>
                                              </tbody>
                                            </v-simple-table>
                                          </td>
                                          <td v-else-if="Array.isArray(item[key]) && !item[key].length" data-test-id="empty">
                                            —
                                          </td>
                                          <td v-else-if="key === 'group' && item[key]" data-test-id="group">
                                            <span v-if="item[key].id">#{{ item[key].id }} </span>{{ item[key].name }}
                                          </td>
                                          <td v-else data-test-id="empty-group">
                                            {{ item[key] || '—' }}
                                          </td>
                                        </tr>
                                      </tbody>
                                    </v-simple-table>
                                  </td>
                                </tr>
                            </template>
                        </template>
                    </LongTable>
                    <w-btn text @click="contact.reset_importing()" data-test-id="contacts-import-step-2-reset-btn">
                        {{ l.t('app.cancel', 'Cancel') }}
                    </w-btn>

                    <w-btn
                      color="primary ml-3"
                      :disabled="contact.disable_delete_button" @click="contact.do_import_contacts()"
                      data-test-id="contacts-import-step-2-import-btn"
                    >
                      {{ l.t('contacts.import-contacts', 'Import contacts') }}
                    </w-btn>
                  </v-stepper-content>

                  <v-stepper-content step="3" data-test-id="contacts-import-step-3-content">
                    <CsvImportedTable
                        v-if="contact.importing_wizard === 3"
                        :items="contact.importing.status"
                        data-test-id="contacts-import-step-3-csv-imported-table"
                    >
                        <template v-slot:commands>
                            <w-btn color="primary mr-3" @click="contact.reset_importing()" data-test-id="contacts-import-step-3-back-btn">
                                {{ l.t('app.back-to-import-page', 'Back to import page') }}
                            </w-btn>
                            <router-link :to="{ name: 'contacts.index' }" tag="w-btn" data-test-id="contacts-import-step-3-list-btn">
                                {{ l.t('app.list-all-contacts', 'List all contacts') }}
                            </router-link>
                        </template>
                    </CsvImportedTable>
                  </v-stepper-content>
                </v-stepper-items>
              </w-stepper>
        </div>
    </div>
</template>

<script>
  import l from '../../../libs/lang';
  import Helpers from '../../../libs/helpers';
  import Contact from '../../../models/Contact';
  import LongTable from '../../elements/LongTable.vue';
  import ProgressBar from '../../elements/ProgressBar.vue';
  import FormInput from '../../elements/form/FormInput.vue';
  import FileUploader from '../../elements/form/FileUploader.vue';
  import CsvImportedTable from '../../elements/CsvImportedTable.vue';
  import ExtensionSelector from '../../fragments/ExtensionSelector.vue';
  import CsvValidationErrors from '../../elements/CsvValidationErrors.vue';

  export default {
    components: {
      LongTable,
      FormInput,
      ProgressBar,
      FileUploader,
      CsvImportedTable,
      ExtensionSelector,
      CsvValidationErrors,
    },
    watch: {
      async extension(value) {
        this.$data.contact.extensionChanged(value);
      },
    },
    data() {
      return {
        l,
        contact: new Contact(this.$session, Helpers.emitter(this), Helpers.changeRoute(this)),
        extension: null,
        one_extension: false,
        opened_contact: null,
      };
    },
    methods: {
        toggleOpened(event, contact_id) {
            const element = event.target;
            if (
              element.classList
              && (element.classList.contains('prevent-toggle')
                || element.classList.contains('v-input--selection-controls__ripple')
              )
            ) return false;

            this.opened_contact = this.opened_contact !== contact_id ? contact_id : null;
            return true;
        },
    },
    filters: {
      prepareKeyText(t) {
        let text = t
          .split('_')
          .map((v) => {
            if (['id', 'cp', 'url'].includes(v)) {
              return v.toUpperCase();
            }
              return v;
          })
          .join(' ');
        if (text.length) {
          text = text[0].toUpperCase() + text.slice(1);
        }
        return text;
      },
    },
  };
</script>
