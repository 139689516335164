var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"menus list-page",attrs:{"data-test-id":"menus-list-page"}},[_c('IndexPage',{attrs:{"resource":_vm.menu},scopedSlots:_vm._u([{key:"filters",fn:function(){return [_c('FormInput',{attrs:{"label":_vm.l.t('app.name', 'Name')}},[_c('w-text-field',{attrs:{"hide-details":"auto","data-test-id":"menus-filters-name-input"},model:{value:(_vm.menu.filters.name),callback:function ($$v) {_vm.$set(_vm.menu.filters, "name", $$v)},expression:"menu.filters.name"}})],1)]},proxy:true},{key:"list",fn:function(){return [_c('v-simple-table',{staticClass:"list-table",attrs:{"data-test-id":"menus-table"}},[_c('thead',{attrs:{"data-test-id":"table-head"}},[_c('tr',{attrs:{"data-test-id":"head-row"}},[_c('th',{staticClass:"select-table-item",attrs:{"data-test-id":"checkbox"}},[_c('br')]),_c('th',{attrs:{"data-test-id":"name"}},[_vm._v(_vm._s(_vm.l.t('app.menu', 'Menu')))]),_c('th',{attrs:{"data-test-id":"description"}},[_vm._v(_vm._s(_vm.l.t('app.description', 'Description')))]),_c('th',{attrs:{"data-test-id":"more-options"}})])]),_c('tbody',{attrs:{"data-test-id":"table-body"}},_vm._l((_vm.menu.items),function(item,i){return _c('tr',{key:item['id'],class:{'selected-row': item.selected},attrs:{"data-test-id":`menus-item-${item.id}-row`}},[(_vm.menu.deleting_item !== item.id)?[_c('td',{staticClass:"select-table-item",attrs:{"data-test-id":"checkbox"}},[_c('w-checkbox',{key:`menus-item-${item.id}-${item.selected}`,attrs:{"hide-details":"auto","data-test-id":`menus-item-${item.id}-checkbox`},on:{"click":function($event){return _vm.checked($event, item)}},model:{value:(item.selected),callback:function ($$v) {_vm.$set(item, "selected", $$v)},expression:"item.selected"}})],1),_c('td',{staticClass:"nowrap overflow-hidden",attrs:{"data-test-id":"description"}},[_c('IndexListItem',{key:item['id'],attrs:{"route":{
                    name: 'menus.show',
                    params: {
                      id: item.id,
                      _menu: item,
                    },
                  },"item":item,"title":item.name,"data-test-id":`menus-item-${item.id}-title`}})],1),_c('td',{staticClass:"w-100",attrs:{"data-test-id":`menus-item-${item.id}-options`}},[_vm._v(" #"+_vm._s(item['id'])+" - "+_vm._s(_vm._f("options")(item))+" ")]),_c('td',[_c('MoreOptions',{attrs:{"options":_vm.more_options(item)}})],1)]:_c('td',{attrs:{"colspan":"4","data-test-id":"loader"}},[_c('w-loader',{attrs:{"size":"small"}})],1)],2)}),0)])]},proxy:true}])}),_c('ConfirmDeletion',{attrs:{"value":!!_vm.deleting_item_id},on:{"closed":function($event){_vm.deleting_item_id = null},"confirmed":function($event){return _vm.menu.delete_item(_vm.deleting_item_id)}}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }