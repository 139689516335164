<template>
  <w-autocomplete
	v-model="val"
	v-bind="$attrs"
	v-on="$listeners"
	:loading="loading"
    :items="items"
    :search-input.sync="search"
    :error="!!error"
    hide-details
  >
	<template v-slot:no-data>
		<div class="px-4 py-3">
			<w-loader v-if="loading" size="small" />
			<div v-else-if="!searched">
				{{ l.t('app.type-name-or-family-name', 'Type contact first name or last name') }}
			</div>
			<div v-else>
				{{ l.t('app.no-contacts-for-your-search', 'No contacts available for your search') }}
			</div>
		</div>
	</template>
  </w-autocomplete>
</template>

<script>
  import l from '../../libs/lang';
  import Contact from '../../models/Contact';
  import Helpers from '../../libs/helpers';

  export default {
    props: {
		value: {
			type: Object,
			required: false,
		},
		extension_id: {
			type: Number,
			required: true,
		},
    },
    data() {
      return {
		l,
		search: '',
		loading: false,
		items: [],
        contact: new Contact(this.$session, Helpers.emitter(this), Helpers.changeRoute(this)),
        timeout: null,
        error: null,
        val: this.$props.value,
        searched: false,
      };
    },
    async mounted() {
		if (this.$props.value) {
			this.$data.items = this.prepare_items([this.$props.value]);
		}
    },
    methods: {
		async search_contacts_by_name() {
			this.$data.loading = true;
			try {
				const items = await this.$session.get_list(
					`/extensions/${this.$props.extension_id}/contacts?mode=full&filters[name]=contains:${this.$data.search}`,
					500
				);
				this.$data.items = this.prepare_items(items.items);
			} catch (err) {
				this.$data.error = l.t('app.something-went-wrong-loading-contacts', 'Something went wrong loading your contacts');
			}
			this.$data.searched = true;
			this.$data.loading = false;
		},
		prepare_items(items) {
			const values = [];
			items.map((x) => {
				if (x.phone_numbers && x.phone_numbers.length) {
					values.push({
						text: Contact.assemble_display_name(x),
						value: x,
					});
				}
				return x;
			});

			return values;
		}
    },
    watch: {
		val(val) {
			this.$emit('input', val);
		},
		search(val) {
			this.$data.searched = false;
			if (val
				&& this.$data.val
			) {
				const item = this.items.find((x) => x.value === this.$data.val);
				if (item && item.text === val) {
					return null;
				}
			}
			this.$data.searched = false;
			this.$data.error = null;
			if (this.$data.timeout) clearTimeout(this.$data.timeout);
			if (!val) {
				this.$data.timeout = null;
				return this.$data.items = [];
			}

			this.$data.timeout = setTimeout(this.search_contacts_by_name, 1000);
			return true;
		}
    }
  };
</script>

<style scoped>

</style>
