const sortFilters = function (rules) {
    const sorted = rules.slice(0, rules.findIndex((r) => !r.filter) + 1);
    return sorted;
};

const getFilter = function (filter) {
    let filterType = '';
    let filterName = '';
    if (filter && filter.type) {
        if (filter.type === 'schedule') {
            filterType = 'Schedule: ';
            filterName = filter.schedule && filter.schedule.name ? filter.schedule.name : '';
        } else if (filter.type === 'group' || filter.type === 'contact_group') {
            filterType = 'Group: ';
            filterName = filter.group.name;
        } else if (filter.type === 'contact') {
            filterType = 'Contact: ';
            filterName = `${
                filter.contact.first_name ? filter.contact.first_name : ''
            } ${filter.contact.last_name ? filter.contact.last_name : ''}`;
        } else if (filter.type === 'unknown') {
            filterType = 'No valid caller ID';
            filterName = '';
        }
    } else {
        filterType = 'All Calls';
        filterName = '';
    }
    return `${filterType}${filterName}`;
    // return {filterType, filterName};
};

const formatNumber = function (phone) {
    return phone.replace(/\D+/g, '').replace(/(\d{1})(\d{3})(\d{3})(\d{4})/, '($2) $3-$4'); // '+$1 ($2) $3-$4' for international code
};

const getAction = function (action) {
    let actionType = '';
    let actionName = '';
    let name = `${action.action ? action.action : ''}`;
    let extension_id = null;
    let menu_id = null;
    let itemsLength = 0;
    let type = null;

    if (action.action === 'forward') {
        actionType = 'Forward call';
        if (action.items && action.items.length) {
            const item = action.items[0];
            itemsLength = action.items.length;

            if (item && item.number) {
                actionName = ` ${formatNumber(item.number)}`;
                name = `Forward${actionName}`;
            } else if (item && item.extension && item.extension.extension) {
                extension_id = item.extension.id;
                type = 'extension';
                actionName = `${item.extension.name ? ` ${item.extension.name}` : ''}: Ext ${item.extension.extension}`;
                name = `Forward:${actionName}`;
            } else {
                actionName = ' (Unknown?)';
                name = `Forward:${actionName}`;
            }
        } else {
            actionName = ' (Unknown?)';
            name = `Forward:${actionName}`;
        }
    } else if (action.action === 'live_answer') {
        actionName = '';
        if (action.script && action.script.name) {
            actionType = 'Script';
            actionName = `${action.script.name}`;
        } else {
            actionType = 'Script';
        }
        name = actionName;
    } else if (action.action === 'voicemail') {
        actionType = 'Voicemail';
        actionName = ` ${(action.extension && action.extension.name) ? ` ${action.extension.name}` : ''} ${(action.extension && action.extension.extension) ? ` | Ext ${action.extension.extension}` : ''}`;
        name = `Voicemail: ${(action.extension && action.extension.name) ? ` ${action.extension.name}` : ''} ${(action.extension && action.extension.extension) ? ` | Ext ${action.extension.extension}` : ''}`;
        extension_id = action.extension ? action.extension.id : null;
    } else if (action.action === 'greeting') {
        actionType = 'Greeting';
        actionName = action.greeting.name ? ` ${action.greeting.name}` : '';
        if (action.greeting.id) {
            actionName += ` #${action.greeting.id}`;
        }
        name = `Greeting: ${action.greeting.name ? action.greeting.name : ''}`;
    } else if (action.action === 'menu') {
        actionType = 'Menu';
        actionName = (action.menu && action.menu.name) ? ` ${action.menu.name}` : '';
        name = actionName;
        menu_id = (action.menu && action.menu.id) ? action.menu.id : null;
        type = 'menu';
    } else if (action.action === 'queue') {
        actionType = 'Queue';
        actionName = (action.queue && action.queue.name) ? ` ${action.queue.name}` : '';
        name = actionName;
    } else if (action.action === 'hold') {
        actionType = 'Hold Music';
        if (action && action.hold_music && action.hold_music.name) {
            actionName = `${action.hold_music.name}`;
            name += `${action.hold_music.name}`;
        } else if (!action.hold_music) {
            actionName = 'Default Ringtone';
        }
    } else if (action.action === 'fax') {
        actionType = 'Fax';
        if (action && action.extension && action.extension.name && action.extension.extension) {
            actionName = ` ${action.extension.name} ${action.extension.extension ? `(${action.extension.extension})` : ''}`;
            name = `Fax: ${action.extension.name} ${action.extension.extension ? `(${action.extension.extension})` : ''}`;
            extension_id = action.extension.id;
        } else {
            if (action.extension.name) {
                if (action.extension.extension) {
                    actionName = ` ${action.extension.name} (${action.extension.extension})`;
                    name = `Fax: ${action.extension.name} (${action.extension.extension})`;
                } else {
                    actionName = `${action.extension.name}`;
                    name = `Fax: ${action.extension.name}`;
                }
            }
            extension_id = action.extension.id;
        }
    } else if (action.action === 'disconnect') {
        actionType = 'Disconnect';
        actionName = 'Disconnect';
        name = 'Disconnect';
    } else if (action.action === 'trunk') {
        actionType = 'Trunk';
        if (action.trunk && action.trunk.name) {
            actionName = `${action.trunk.name}`;
            name = actionName;
        }
    }

    return {
      name, actionType, actionName, extension_id, menu_id, itemsLength, type
    };
};

const getForwardItems = function (action) {
    const items = [];
    let i = 0;
    for (const item of action.items) {
      if (item && item.number) {
        items.push({actionName: formatNumber(item.number), actionType: 'Forward call', type: 'number'});
      } else if (item && item.extension && item.extension.extension) {
        items.push(
          {
            actionName: `${item.extension.name ? ` ${item.extension.name}` : ''}: Ext ${item.extension.extension}`,
            actionType: 'Forward call',
            type: 'extension',
            id: i,
            extension_id: item.extension.id
          }
        );
      } else {
        items.push({actionName: ' (Unknown?)', actionType: 'Forward call'});
      }
      ++i;
    }
    return items;
};

const getBlockOutputs = function (outputs) {
    let id = 0;
    const fields = [];
    outputs.forEach((output) => {
      const filterName = output.filter ? getFilter(output.filter) : 'All Calls';
      fields.push({
        name: filterName, filter: true, attr: 'output', id
      });
      id++;

      for (const action of output.actions) {
        const f = getAction(action);
        if (f.itemsLength > 1) {
          const items = getForwardItems(action);

          for (const item of items) {
            fields.push({
              name: item.name,
              actionType: item.actionType,
              id,
              actionName: item.actionName,
              attr: 'output',
              extension_id: item.extension_id,
              type: item.type
            });
            id++;
          }
        } else {
          fields.push({
            name: f.name,
            actionType: f.actionType,
            id,
            actionName: f.actionName,
            attr: 'output',
            extension_id: f.extension_id,
            menu_id: f.menu_id,
            type: f.type
          });
          id++;
        }
      }
    });
    return fields;
};

const getFullRoute = async function (response, extID = null) {
    if (response && response.rules && response.rules.length) {
        for (const rule of response.rules) {
            for (let action of rule.actions) {
                const obj = getAction(action);

                if (obj.extension_id && obj.actionType.toLowerCase().includes('forward') && extID !== obj.extension_id) {
                    this.currentExtension = obj.extension_id;
                } else if (obj.menu && obj.menu !== this.$data.currentMenu) {
                    const menu_id = obj.menu;
                    let menu = this.$data.menus.find((m) => m.id === menu_id);
                    if (!menu) {
                        menu = await this.$session.get_item(`/menus/${menu_id}`);
                    }

                    this.$data.currentMenu = menu_id;

                    for (const option of menu.options) {
                        if (!option.route.rules && option.route.id) {
                            const route = await this.$session.get_item(`/routes/${option.route.id}`);
                            option.route = route;
                        }
                        if (option.route && option.route.rules && option.route.rules.length) {
                            const route = await getFullRoute(option.route);
                            option.route = route;
                        }
                    }
                    if (menu && menu.timeout_handler && menu.timeout_handler.id) {
                        const timeoutResponse = await this.$session.get_item(`/routes/${menu.timeout_handler.id}`);

                        if (menu.timeout_handler && menu.timeout_handler.rules && menu.timeout_handler.rules.length) {
                            const route = await getFullRoute(menu.timeout_handler);
                            menu.timeout_handler = route;
                        } else {
                            menu.timeout_handler = timeoutResponse;
                        }
                    }

                    this.$data.menus.push(menu);
                    action = {action: 'menu', menu};
                }
            }
        }
    }

    return response;
};

export default {
    sortFilters,
    formatNumber,
    getAction,
    getBlockOutputs,
    getFullRoute
};
