<template>
  <div class="call-logs list-page" data-test-id="cl-list-page">
    <PageTitle>{{ l.t('app.call-logs', 'Call logs') }}</PageTitle>
    <IndexPage
      :resource="log"
      :withExtensionSelector="true"
      :nullable_extension="false"
      :extensionCb="'extensionChanged'"
      :label_cols="label_cols"
      :disable_delete_all="true"
      :disable_mass_operations="true"
      :disable_add_new="true"
      data-test-id="calllogs-list-page"
      class="mt-n3"
    >
      <template v-slot:filters>
        <SmartFilters
          :log="log"
          :key="JSON.stringify(log.client_config)"
          :label_cols="label_cols"
          class="mb-3"
          data-test-id="cl-smart-filters"
        />
        <FormInput
          :label="l.t('reports.call-direction', 'Call direction')"
          :label_cols="label_cols"
        >
          <w-select
            v-model="log.filters.direction"
            @change="log.restartSavedFilterName()"
            :items="[
              {value: null,text: l.t('app.all-calls', 'All calls')},
              {value: 'in',text: l.t('app.inbound', 'Inbound')},
              {value: 'out',text: l.t('app.outbound', 'Outbound')},
            ]"
            hide-details="auto"
            data-test-id="call-logs-reports-filters-group-bu-select"
          />
        </FormInput>
        <FormInput :label="l.t('app.duration', 'Duration')" :label_cols="label_cols">
          <div class="d-flex">
            <w-select
              v-model="log.filters.duration_type"
              :items="[
                {value: 'gt', text: l.t('app.more-than', 'More than')},
                {value: 'lt', text: l.t('app.less-than', 'Less than')},
              ]"
              class="mr-3 w-100"
              hide-details="auto"
              data-test-id="cl-filters-above-less-than-select"
            />
            <SecondsNumberInput
              :value="log.filters.duration"
              @change="log.filters.duration = $event"
              @keydown="log.restartSavedFilterName()"
              class="w-100"
              data-test-id="cl-fitlers-duration-input"
            />
          </div>
        </FormInput>
        <FormInput :label="l.t('app.from', 'From')" :label_cols="label_cols">
          <Tel
            @changed="log.filters.caller = $event.clean_number"
            :value="log.filters.caller"
            data-test-id="cl-filters-from-input"
          />
        </FormInput>
        <FormInput :label="l.t('app.to', 'To')" :label_cols="label_cols">
          <Tel
            @changed="log.filters.called_number = $event.clean_number"
            :value="log.filters.called_number"
            data-test-id="cl-filters-to-input"
          />
        </FormInput>
        <FormInput
          v-if="log.extension"
          :label="l.t('app.name', 'Name')"
          :label_cols="label_cols"
          data-test-id="cl-filters-name-input"
        >
          <ContactNumberSearch
            v-model="log.filters.contact_with_numbers"
            :extension_id="log.extension"
          />
        </FormInput>
        <FormInput :label="l.t('app.id', 'ID')" :label_cols="label_cols">
          <w-text-field
            v-model="log.filters.id"
            hide-details
            data-test-id="cl-filters-id-input"
          />
        </FormInput>
      </template>
      <template v-slot:additionalFilterButtons>
        <SaveFilter
          v-if="log.filters_applied && !log.saved_filter_name"
          :generate_name_callback="log.generateNewFilterName.bind(log)"
          @confirmed="log.save_filters(null, $event)"
          data-test-id="cl-save-filters"
        />
        <w-btn type="button" @click="log.generate_csv()" color="primary" class="primary--text text--darken-1 primary lighten-5 mr-5" data-test-id="cl-get-csv-btn">
          {{ l.t("app.get-csv", "Get CSV") }}
        </w-btn>
      </template>
      <template v-slot:list>
        <v-simple-table v-if="log.items && log.items.length" class="list-table nowrap" data-test-id="cl-table">
          <thead data-test-id="table-head">
            <tr data-test-id="head-row">
              <th data-test-id="type">{{ l.t('app.type', 'Type'), }}</th>
              <th data-test-id="started-at">{{ l.t('calls-history.started_at', 'Started at'), }}</th>
              <th data-test-id="duration">{{ l.t('app.duration', 'Duration'), }}</th>
              <th data-test-id="direction">{{ l.t('app.direction', 'Direction'), }}</th>
              <th data-test-id="add-to-blocked"><br/></th>
              <th data-test-id="from">{{ l.t('app.from', 'From'), }}</th>
              <th data-test-id="to">{{ l.t('app.to', 'To'), }}</th>
              <th data-test-id="player"><br/></th>
              <th data-test-id="open"><br/></th>
            </tr>
          </thead>
          <tbody>
            <template v-for="(call, index) in log.items">
              <tr
                @click="log.toggleOpened($event, call['id'])"
                :class="{ opened: call['id'] === log.opened_call }"
                :key="index+call['id']"
                :data-test-id="`cl-item-${index}-row`"
              >
                <td
                  :data-test-id="`cl-item-type-td-${index}`"
                  >
                  {{ format_table_value(call, 'type') }}
                </td>
                <td data-test-id="start-time">
                  {{format_table_value(call, 'start_time_epoch')}}
                </td>
                <td data-test-id="duration">{{ call.call_duration_minutes }} {{ call.call_duration_minutes === 1 ? l.t('app.minute', 'minute') : l.t('app.minutes', 'minutes') }}</td>
                <td data-test-id="direction">{{ format_table_value(call, 'direction') }}</td>
                <td class="prevent-toggle minimal-width px-0" data-test-id="add-to-blocked">
                   <w-tooltip
                    v-if="
                     call.direction === 'in'
                     && !log.blocked.includes(call.caller_id)
                     && !log.loading_blocked
                     && log.number_in_process !== call.caller_id
                   "
                    top
                    no-carret
                    small
                    class="prevent-toggle"
                   >
                      <template v-slot:activator>
                        <v-icon
                          v-if="
                            call.direction === 'in'
                            && !log.blocked.includes(call.caller_id)
                            && !log.loading_blocked
                          "
                          @click="log.addToBlocked(call)"
                          color="error"
                          class="prevent-toggle"
                        >
                          $vuetify.icons.block
                        </v-icon>
                   </template>
                   <span>{{l.t('app.block', 'Block')}}</span>
                   </w-tooltip>
                   <w-tooltip top no-carret small v-if="
                      call.direction === 'in'
                      && log.blocked.includes(call.caller_id)
                      && log.number_in_process !== call.caller_id
                   ">
                      <template v-slot:activator>
                  <v-icon
                    v-if="
                      call.direction === 'in'
                      && log.blocked.includes(call.caller_id)
                    "
                    @click="log.removeFromBlocked(call)"
                    class="prevent-toggle w-body-2"
                    color="primary"
                  >
                       $vuetify.icons.unblock
                  </v-icon>
                   </template>
                   <span>{{l.t('app.unblock', 'Unblock')}}</span>
                  </w-tooltip>
                </td>
                <td
                  :class="{
                    'minimal-width': log.items.find((x) => x.call_direction === 'in')
                  }"
                  class="px-0"
                  data-test-id="from"
                >
                  <w-loader
                    v-if="log.loading_id === call.id"
                    size='small'
                    :no_margins="true"
                    :message="false"
                  />
                  <span
                    v-else
                    :class="{
                      'text-decoration-line-through':
                        call.direction === 'in'
                        && log.blocked.includes(call.caller_id)
                    }"
                  >
                    {{ call.caller_id | format_phone_number }}
                  </span>
                </td>
                <td data-test-id="to">
                  {{ call.called_number | format_phone_number }}
                </td>
                <td data-test-id="player">
                  <Player
                    v-if="call['call_recording_cp_url'] || call['voicemail_cp_url']"
                    :url="call['call_recording_cp_url'] || call['voicemail_cp_url']"
                    class="prevent-toggle"
                    :data-test-id="`cl-item-${index}-player`"
                  />
                  <br v-else />
                </td>
                <td data-test-id="open">
                  <v-icon
                    :class="{'rotate-180': call.id === log.opened_call}"
                    small
                    color="text"
                    :data-test-id="`cl-item-${index}-open-icon`"
                  >
                    $vuetify.icons.triangle
                  </v-icon>
                </td>
              </tr>
              <tr v-if="call['id'] === log.opened_call" data-test-id="opened-call">
                <td colspan="10" class="pa-0">
                  <v-simple-table :data-test-id="`cl-item-${index}-subtable`" dense class="pa-0">
                    <tbody data-test-id="table-body">
                      <tr v-for="(cl_value, cl_key) in clean_call_fields(call)" :data-test-id="cl_key">
                        <th data-test-id="cl-key">{{ cl_key | prepareKeyText }}</th>
                        <td v-if="Array.isArray(cl_value)" class="pa-0">
                          <v-simple-table class="details-table" :fixed-header="true" dense>
                            <thead data-test-id="table-head">
                              <tr data-test-id="head-row">
                                <th data-test-id="index">#</th>
                                <th
                                  v-for="cld_key in allKeysOfArraysObject(cl_value)"
                                  v-bind:key="cld_key"
                                  class="pa-0"
                                  :data-test-id="cld_key"
                                >
                                  <template v-if="details_table_headers[cld_key]">{{details_table_headers[cld_key]}}</template>
                                  <template v-else>{{ cld_key | prepareKeyText }}</template>
                                </th>
                              </tr>
                            </thead>
                            <tbody data-test-id="table-body">
                              <tr
                                v-for="(detail, index) in cl_value"
                                v-bind:key="index"
                                :data-test-id="index"
                              >
                                <td data-test-id="index">{{ index + 1 }}</td>
                                <td
                                  v-for="cld_key in allKeysOfArraysObject(cl_value)"
                                  :class="{'modal-trigger': call_type(detail, cld_key)}"
                                  v-bind:key="cld_key"
                                  class="pa-0"
                                  :data-test-id="cld_key"
                                >
                                  <!-- <ModalWithTrigger
                                    v-if="call_type(detail, cld_key)"
                                    max-width="1000"
                                  >
                                    <template v-slot:activator_text>
                                      <span class="list-link">
                                        {{detail[cld_key]}}
                                      </span>
                                    </template>
                                    <LiveAnswer
                                      v-if="call_type(detail, cld_key) === 'live_answer'"
                                      :id="detail[cld_key]"
                                      @updated="close_modal"
                                    />
                                    <Media
                                      v-if="call_type(detail, cld_key) === 'media'"
                                      :id="detail[cld_key]"
                                      :withPlayer="true"
                                      @updated="close_modal"
                                    ></Media>
                                    <Extension
                                      v-else-if="call_type(detail, cld_key) === 'extension'"
                                      :id="detail[cld_key]"
                                      :ignore_routes="true"
                                      @updated="close_modal"
                                    ></Extension>
                                    <Menu
                                      v-else-if="call_type(detail, cld_key) === 'menu'"
                                      :id="detail[cld_key]"
                                      :full_menu="true"
                                      @updated="close_modal"
                                    />
                                    <Queue
                                      v-else-if="call_type(detail, cld_key) === 'queue'"
                                      :id="detail[cld_key]"
                                      @updated="close_modal"
                                    />
                                  </ModalWithTrigger> -->
                                  <!-- <template v-else>{{ detail[cld_key] }}</template> -->
                                  {{ detail[cld_key] }}
                                </td>
                              </tr>
                            </tbody>
                          </v-simple-table>
                        </td>
                        <td v-else-if="cl_key === 'extension'" data-test-id="extension">
                          {{ cl_value.extension }}: {{ cl_value.name }} (#{{ cl_value.id }})
                        </td>
                        <td v-else :class="{'long-line': ['call_recording_cp_url', 'voicemail_cp_url', 'voicemail_transcript'].includes(cl_key)}" data-test-id="long-line">
                          {{ cl_value }}
                        </td>
                      </tr>
                    </tbody>
                  </v-simple-table>
                </td>
              </tr>
            </template>
          </tbody>
        </v-simple-table>
      </template>
      <template v-if="log.items && log.items.length" v-slot:additionalData>
        <w-btn text class="float-right primary--text mt-5" @click="log.download_all()" data-test-id="download-all">
          <v-icon>$vuetify.icons.download_cloud_inverted</v-icon>
          {{l.t('app.download-all-recordings', 'Download all recordings')}}
        </w-btn>
      </template>
    </IndexPage>
  </div>
</template>

<script>
import l from '../../libs/lang';
import Helpers from '../../libs/helpers';
import CallLog from '../../models/CallLog';
import Menu from './menus/show.vue';
import Media from './media/show.vue';
import Queue from './queues/show.vue';
import Tel from '../elements/Tel.vue';
import Player from '../elements/Player.vue';
import Extension from './extensions/show.vue';
import LiveAnswer from './live-answer/show.vue';
import IndexPage from '../elements/IndexPage.vue';
import PageTitle from '../elements/PageTitle.vue';
import FormInput from '../elements/form/FormInput.vue';
import SmartFilters from '../elements/SmartFilters.vue';
import SaveFilter from '../elements/modal/SaveFilter.vue';
import ContactNumberSearch from '../elements/ContactNumberSearch.vue';
import ModalWithTrigger from '../elements/modal/ModalWithTrigger.vue';
import SecondsNumberInput from '../elements/form/SecondsNumberInput.vue';

export default {
  components: {
    Tel,
    Menu,
    Media,
    Queue,
    Player,
    Extension,
    PageTitle,
    FormInput,
    IndexPage,
    SaveFilter,
    LiveAnswer,
    SmartFilters,
    ModalWithTrigger,
    SecondsNumberInput,
    ContactNumberSearch,
  },
  data() {
    return {
      l,
      log: new CallLog(this.$session, Helpers.emitter(this), Helpers.changeRoute(this), () => this.$forceUpdate()),
      fields_to_hide: ['call_recording_url', 'voicemail_url', 'call_number', 'created_at', 'created_at_epoch'],
      label_cols: 2,
      details_table_headers: {
        id_value: l.t('calllogs.action-ID', 'Action ID'),
        type: l.t('app.action', 'Action'),
      },
      call_types: {
        extension: ['dial_out', 'voicemail', 'voicemail_received', 'phone'],
        live_answer: ['live_answer'],
        menu: ['menu', 'menu_item'],
        queue: ['queue'],
        media: ['play', 'mandatory_hold'],
      },
    };
  },
  created() {
    this.$data.log.apply_filters();
  },
  methods: {
    call_type(detail, cld_key) {
      if (cld_key !== 'id_value' || !detail['id_value']) return false;
      for (const type of Object.keys(this.$data.call_types)) {
        // extension typ can have phone as id_value that starts with +
        if (type === 'extension' && this.$data.call_types.extension.includes(detail.type)) {
          if (!['number', 'string'].includes(typeof detail.id_value)) return false;
          let value = detail['id_value'];
          if (typeof value === 'number') value = value.toString();
          return value.startsWith('+') ? false : 'extension';
        }
        if (this.$data.call_types[type].includes(detail['type'])) return type;
      }
      return false;
    },
    allKeysOfArraysObject(array_value) {
      return CallLog.allKeysOfArraysObject(array_value);
    },
    close_modal() {
      setTimeout(() => this.$emit('close_modal'), 1000);
    },
    clean_call_fields(call) {
      if (call.start_time_epoch) call.start_time = Helpers.format_time(call.start_time_epoch);
      if (call.details) {
        call.details.map((detail) => {
            detail.start_time = Helpers.format_time(detail.start_time);
            return detail;
        });
      }
      for (const key of Object.keys(call)) {
        if (this.$data.fields_to_hide.includes(key)) delete call[key];
      }
      return call;
    },
    format_table_value(call, prop) {
      if (Object.prototype.hasOwnProperty.call(this.$data.log.functions, prop)) {
        return this.$data.log.functions[prop](call);
      }

      return call[prop];
    }
  },
  filters: {
    prepareKeyText(t) {
      if (t === 'call_duration') return l.t('app.call-duration-seconds', 'Call duration seconds');
      let text = t
        .split('_')
        .map((v) => {
          if (['id', 'cp', 'url'].includes(v)) {
            return v.toUpperCase();
          }
            return v;
        })
        .join(' ');
      if (text.length) {
        text = text[0].toUpperCase() + text.slice(1);
      }
      return text;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../../assets/styles/scss/vars.scss";
table{
  tbody tr {
    cursor: pointer;
  }
  .opened {
    background: $bg;
  }
  .subtable-holder {
    .subtable {
      th{
        white-space: nowrap;
      }
      td {
        word-break: break-all;
      }
      a {
        color: #FFF;
      }
    }
  }
  .long-line {
    word-wrap: break-word;
    word-break: break-all;
    white-space: normal;
    overflow-wrap: break-word;
  }
}
</style>
