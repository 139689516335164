var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"show-page",attrs:{"data-test-id":"phone-numbers-show-page"}},[((_vm.$route.name !== 'phone-numbers.visualizer' && _vm.$route.name !== 'extensions.visualizer'))?_c('Submenu',{key:`${_vm.l.detect()}${_vm.$route.name}`,attrs:{"routes":{
      'phone-numbers.show': {
        title: _vm.l.t('app.settings', 'Settings')
        },
      'phone-numbers.call-flow': {
        title: _vm.l.t('app.call-handling', 'Call handling')
        },
      'phone-numbers.make-call': {
        title: _vm.l.t('make-call.make-a-call', 'Make a call'),
        subroutes: ['phone-numbers.wizard']
        }
    }}}):_vm._e(),(_vm.phone.loading)?_c('w-loader'):(_vm.phone.item)?_c('div',[(_vm.title)?_c('PageTitle',{staticClass:"nowrap"},[_vm._v(" "+_vm._s(_vm.title)+" ")]):_vm._e(),(_vm.phone.alert)?_c('w-alert',{staticClass:"mb-6",attrs:{"level":_vm.phone.alert.level,"message":_vm.phone.alert.message,"closable":_vm.phone.enable_close_alert},on:{"closed":function($event){_vm.phone.alert = null}}}):_vm._e(),_c('div',[((_vm.$route.name === 'phone-numbers.visualizer' || _vm.$route.name === 'extensions.visualizer'))?_c('CallHandlingRules',{attrs:{"_route":_vm.phone.item['route'],"id":_vm.phone.item['route'] ? _vm.phone.item['route']['id'] : null,"data-test-id":"extensions-call-flow"},on:{"updated":_vm.on_route_update}}):_vm._e(),((_vm.$route.name === 'phone-numbers.show' || _vm.$route.name === 'phone-numbers.visualizer' || _vm.$route.name === 'extensions.visualizer') && _vm.phone.item)?_c('PhoneNumberSettings',{attrs:{"_phone_number":_vm.phone.item,"data-test-id":"phone-numbers-show-settings"},on:{"submitted":function($event){return _vm.phone.update($event)}}}):(_vm.$route.name === 'phone-numbers.call-flow')?_c('CallHandlingRules',{attrs:{"_route":_vm.phone.item['route'],"id":_vm.phone.item['route'] ? _vm.phone.item['route']['id'] : null,"data-test-id":"extensions-call-flow"},on:{"updated":_vm.on_route_update}}):(_vm.$route.name === 'phone-numbers.make-call')?_c('MakeCall',{attrs:{"caller_id":_vm.phone.item['phone_number'],"data-test-id":"phone-numbers-show-make-call"}}):(_vm.$route.name === 'phone-numbers.wizard')?_c('Wizard',{attrs:{"data-test-id":"phone-numbers-show-wizard"}}):_vm._e()],1)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }