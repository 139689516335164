<template>
    <div class="trunks">
      <Submenu
        :routes="{
          'trunks.index': {
            title: l.t('app.trunks', 'Trunks'),
            subroutes: ['trunks.show']
            },
          'trunks.create': {
            title: l.t('trunks.add-trunk', 'Add trunk')
            },
        }"
        :key="`${l.detect()}${$route.name}`"
      />
      <TrunksFragment
        v-if="$route.name === 'trunks.index'"
        @deletingStarted="deleting_in_progress = true"
        @deletingEnded="deleting_in_progress = false"
        data-test-id="trunks-index"
      />
      <TrunksCreateFragment
        v-if="$route.name === 'trunks.create'"
        data-test-id="trunks-create"
      />
      <TrunksShowFragment
        v-if="$route.name === 'trunks.show'"
        data-test-id="trunks-show"
      />
    </div>
</template>

<script>
  import l from '../../../libs/lang';
  import TrunksFragment from './index.vue';
  import TrunksShowFragment from './show.vue';
  import TrunksCreateFragment from './create.vue';
  import Submenu from '../../elements/Submenu.vue';

  export default {
    components: {
      Submenu,
      TrunksFragment,
      TrunksCreateFragment,
      TrunksShowFragment,
    },
    data() {
      return {
        l,
        deleting_in_progress: false,
      };
    },
    beforeRouteLeave(to, from, next) {
      if (window.deleting_in_progress) {
        this.$emit('userRedirected', to, from, next);
      } else {
        next();
      }
    },
  };
</script>
