import l from '../libs/lang';
import Resource from './Resource';
import setup from '../libs/resources-setups/groups';
import Helpers from '../libs/helpers';

class Group extends Resource {
    constructor(session, emitter, changeRoute) {
        super(session, emitter, '/contact-groups', changeRoute);
        this.selector_mode = ['groups'];
        this.setup = setup;
        this.filters = {
            name: null,
        };
        this.loading = false;
    }

    async loadItems(params) {
        if (!this.extension) return null;
        this.loading = true;
        try {
            const offset = this.checkIfUserDeletedItems(params.offset, params.page);
            this.check_all = false;
            const items = await this.session.get_list(this.uri, params.limit, offset);

            this.page = params.page;
            if (this.page === 1 && !items.items.length) {
                items.items = await Helpers.create_default_contact_groups(this.session, this.extension);
                const cache_key = this.cache_key('groups'); // selector cache
                const groups = {};
                for (const g of items.items) {
                    groups[g.id] = g;
                }
                this.cachier.setItem(cache_key, groups);
            }
            if (this.page > 1 && !items.items.length) {
                this.emitter('pageChanged', 1);
                return this.loadItems({
                    limit: this.limit,
                    offset: 0,
                    page: 1,
                });
            }

            this.items = items.items;
            this.finalizeLoadingItems(items);
            this.emitter('itemsLoaded', JSON.parse(JSON.stringify(items)));
        } catch (err) {
            return this.validation_error(err);
        }
        this.loading = false;
        return true;
    }

    addToCorrected(group) {
        if (group.id) {
            const found = this.items.find(
                (x) => x.id === group.id
            );
            if (found) found.corrected = true;
        }
        this.disable_submit = true;
    }

    async updateGroup(data) {
        await super.update(data);
        this.updateCache(data, 'groups');
    }

    async update() {
        this.loading = true;
        const corrected = this.items.filter(
            (x) => x.corrected && !x.newly_added,
        );
        const newlyAdded = this.items.filter((x) => x.newly_added);
        if (!corrected.length && !newlyAdded.length) {
            this.loading = false;
            this.alert = {
                message: l.t('groups.no-corrected', 'There is nothing to update.'),
                level: 'error',
            };

            return this.hide_alert(3);
        }
        try {
            for (const group of corrected) {
                const updatedGroup = await this.session.replace_item(`${this.baseUri}/${group.id}`, group);
                this.updateCache(updatedGroup);

                group.corrected = false;
            }
            for (let group of newlyAdded) {
                const newGroup = await this.session.create_item(this.baseUri, group);
                this.addToCache(newGroup);
                group = newGroup.id;
                for (const i in this.items) {
                    if (this.items[i].name === newGroup.name) {
                        this.items[i] = newGroup;
                        this.items[i].selected = false;
                        this.items[i].corrected = false;
                        break;
                    }
                }
            }

            window.scrollTo({
                top: 0,
            });
            let message;
            if (newlyAdded.length + corrected.length === 1) {
                message = l.t('groups.successfully-updated-one', 'Group successfully updated');
            } else {
                message = l.t(
                    'groups.multiple-groups-successfully-updated',
                    '{} group(s) successfully updated', [newlyAdded.length + corrected.length],
                );
            }
            this.alert = {
                message,
                level: 'success',
            };
            this.hide_alert(5);
        } catch (err) {
            return this.validation_error(err);
        }
        this.loading = false;
        this.disable_submit = false;

        return true;
    }

    addNew() {
        this.items.push({
            name: '',
            corrected: false,
            newly_added: true,
        });
    }

    apply_filters() {
        let uri = `${this.baseUri}?mode=full`;
        if (this.filters.name) {
            uri = `${uri}&filters[name]=contains:${this.filters.name}`;
        }
        this.uri = uri;
        this.filters_applied = true;
    }

    async bulkDelete() {
        await super.bulkDelete();
        this.disable_delete_button = true;
    }

    async deleteAll() {
        await super.deleteAll();
        this.disable_delete_button = true;
    }
}

export default Group;
