<template>
  <div class="selector-wrapper">
      <w-loader v-if="selector.loading" size="large" :message="false" :no_margins="true"/>
      <template v-else>
        <template v-if="!selector.search">
          <div class="d-flex">
            <w-select
              v-if="items"
              :items="items"
              v-model="selector.value_v"
              :rules="rules"
              :title="selector.mode.title ? selector.mode.title() : `#${selector.value_v}`"
              :disabled="!!disabled"
              :placeholder="placeholder_text"
              :label="label || undefined"
              :append-outer-icon="closable ? '' : undefined"
              @click:append-outer=""
              :multiple="!!multiple"
              hide-details="auto"
              data-test-id="selector-select"
              class="selector"
            />
            <slot></slot>
            <MoreOptions
              v-if="options.length > 1"
              :options="options"
              :activator_classes="helper_buttons_classes"
              data-test-id="selector-actions"
              :key="`selector-actions-${!!selector.search}`"
            />
            <v-btn
              v-else-if="options.length === 1"
              :class="`${helper_buttons_classes} ${options[0].icon !== '$vuetify.icons.trash' ? `${helper_buttons_color}` : ''}`"
              :height="helper_button_size"
              :width="helper_button_size"
              icon
              @click="options[0].cb()"
              :key="`selector-single-action-${!!selector.search}`"
            >
              <v-icon :color="options[0].icon === '$vuetify.icons.trash' ? 'error' : 'text'" size="18">{{options[0].icon}}</v-icon>
            </v-btn>
            <Player
              v-if="selector.value_v && selector.mode.playable && !no_play && selector.value_v !== 11"
              :just_player="true"
              :url="`${selector.mode.playable}/${selector.value_v}/download`"
              :key="selector.value_v"
              :gray="true"
              :btn_size="32"
              :icon_size="18"
              class="my-auto"
              data-test-id="selector-player"
            />
            <DefaultModal
              v-if="user_may_edit_resource"
              v-model="show_modal"
              :with_x="true"
              @close="show_modal = false"
              width="1000"
              :key="modal_key"
            >
              <div>
                <Media v-if="['hold_music', 'greetings', 'user_hold_music'].includes(selector.mode_name) && selector.mode.model.item"
                  :id="selector.value_v" @updated="update_screen" :withPlayer="true"
                  data-test-id="selector-edit-media"></Media>
                <Extension v-if="['extensions', 'virtualExtensions'].includes(selector.mode_name) && selector.values[selector.value_v]"
                  :_extension="selector.values[selector.value_v]" :ignore_routes="true" @updated="update_screen"
                  data-test-id="selector-edit-extension"></Extension>
                <Trunk v-if="selector.mode_name === 'trunks' && selector.values[selector.value_v]"
                  :id="selector.value_v" @updated="update_screen" data-test-id="selector-edit-trunk"></Trunk>
                <Queue v-if="selector.mode_name === 'queues' && selector.values[selector.value_v]" :id="selector.value_v"
                  @updated="update_screen" data-test-id="selector-edit-queue" />
                <Schedule v-if="selector.mode_name === 'schedules' && selector.values[selector.value_v]" :size="'medium'"
                  :_schedule="selector.values[selector.value_v]" @updated="update_screen"
                  data-test-id="selector-edit-schedule" />
                <Menu v-if="selector.mode_name === 'menus' && selector.values[selector.value_v]"
                  :id="selector.value_v" @updated="update_screen" :full_menu="true" @cancel="show_modal = false" data-test-id="selector-edit-menu" />
                <Route v-if="['routes', 'presets'].includes(selector.mode_name) && selector.values[selector.value_v]"
                  :id="selector.value_v" :disable_switcher="true" @updated="update_screen"  @cancel="show_modal = false" data-test-id="selector-edit-route" />
                <PhoneNumber v-if="selector.mode_name === 'phone_numbers' && selector.values[selector.value_v]"
                  :_phone_number="selector.values[selector.value_v]" @submitted="update" data-test-id="selector-edit-phone-num" />
                <LiveAnswer v-if="selector.mode_name === 'live_answer' && selector.values[selector.value_v]"
                  :_id="selector.value_v" @updated="update_screen" data-test-id="selector-edit-lr" @cancel="show_modal = false"/>
                <Contact v-if="selector.mode_name === 'contacts' && selector.values[selector.value_v] && selector.extension"
                  :id="selector.values[selector.value_v]['id']" :extension_id="selector.extension"
                  @updated="update_screen" data-test-id="selector-edit-contact" />
                <ContactGroup v-if="selector.mode_name === 'groups' && selector.values[selector.value_v] && selector.extension"
                  :id="selector.values[selector.value_v]['id']" :extension_id="selector.extension"
                  @updated="update_screen" data-test-id="selector-edit-group" />
              </div>
            </DefaultModal>
          </div>
        </template>
        <div v-else class="d-flex">
          <w-text-field
            v-model="selector.search_term"
            :placeholder="`${l.t('app.type-the-name', 'Type the name')}${selector.mode_name === 'extensions' ? ` ${l.t('selector.or-extension-number', 'or extension number')}` : ''}`"
            :autofocus="true"
            type="text"
            class="selector-search-field"
            append-icon="$vuetify.icons.search"
            @keydown.enter="search_by_name"
            @click:append="search_by_name"
            hide-details='auto'
            data-test-id="selector-search-input"
          />
          <MoreOptions
            v-if="search_options.length > 1"
            :options="search_options"
            :activator_classes="helper_buttons_classes"
          />
          <v-btn
            v-else-if="search_options.length === 1"
            :class="`${helper_buttons_classes} ${search_options[0].icon !== '$vuetify.icons.trash' ? `${helper_buttons_color}` : ''}`"
            :height="helper_button_size"
            :width="helper_button_size"
            icon
            @click="search_options[0].cb()"
          >
            <v-icon color="text" size="18">{{search_options[0].icon}}</v-icon>
          </v-btn>
        </div>
        <p
          v-if="selector.selector_alert && !selector.loading"
          class="error--text mt-2 w-helper-text"
          data-test-id="selector-alert"
        >
          {{ selector.selector_alert }}
        </p>
        <p
          v-if="alert && !selector.loading"
          class="mt-2 w-helper-text"
          :class="{'error--text': alert.level === 'error', 'primary--text': alert.level === 'success'}"
          data-test-id="selector-component-alert"
        >
            {{ alert.message }}
        </p>
      </template>
  </div>
</template>

<script>
import l from '../../libs/lang';
import Helpers from '../../libs/helpers';
import Selector from '../../models/Selector';
import Resource from '../../models/Resource';
import Player from './Player.vue';
import MoreOptions from './MoreOptions.vue';
import DefaultModal from './modal/DefaultModal.vue';

export default {
  props: [
    'value',
    'mode',
    'select_if_one',
    'reload',
    'disabled',
    'extension',
    'closable',
    'no_update',
    'required',
    'label',
    'placeholder',
    'no_play',
    'before_list',
    'after_list',
    'suppress_no_items_alert',
    'additional_options',
    'filtered_values',
    'multiple'
  ],
components: {
    Player,
    MoreOptions,
    DefaultModal,
    Menu: () => import('../screens/menus/show.vue'),
    Media: () => import('../screens/media/show.vue'),
    Trunk: () => import('../screens/trunks/show.vue'),
    Queue: () => import('../screens/queues/show.vue'),
    Route: () => import('../screens/routes/show.vue'),
    Contact: () => import('../screens/contacts/show.vue'),
    Schedule: () => import('../screens/schedules/show.vue'),
    ContactGroup: () => import('../screens/groups/show.vue'),
    Extension: () => import('../screens/extensions/show.vue'),
    LiveAnswer: () => import('../screens/live-answer/show.vue'),
    PhoneNumber: () => import('../fragments/PhoneNumberSettings.vue'),
  },
  data() {
    return {
      l,
      alert: null,
      items: null,
      rules: [],
      selector: new Selector(
        this.$props.mode,
        this.$session,
        this.$props.extension,
        {
          multiple: !!this.$props.multiple,
          select_if_one: this.$props.select_if_one,
          value: this.$props.value,
          reload: this.$props.reload,
          suppress_no_items_alert: this.$props.suppress_no_items_alert,
        },
        Helpers.emitter(this),
      ),
      show_modal: false,
      modal_key: Date.now(),
      helper_buttons_classes: 'ml-3 mt-2 selector-helper-btn',
      helper_buttons_color: 'action lighten-1',
      helper_button_size: 32,
    };
  },
  async created() {
    try {
      await this.$data.selector.load_items();
      this.build_items();
    } catch (e) {
      console.log(e);
      this.$data.selector.loading = false;
      this.$data.alert = {
        level: 'error',
        message: l.t('app.generic-error', 'Something went wrong'),
      };
    }
  },
  methods: {
    build_items(search_results_items = false) {
      try {
        if (this.$props.mode === 'live_answer_vendors') {
          // eslint-disable-next-line guard-for-in
          for (const x in this.selector.values) {
            this.selector.values[x].name = Resource.getServiceTypeName(this.selector.values[x].id);
          }
        }
        this.$data.items = [];
        const actual_values = Object.values(this.$data.selector.values)
          .sort(this.$data.selector.mode['sort'])
          .map((x) => ({
            value: this.$data.selector.mode.value_property ? x[this.$data.selector.mode.value_property] : x.id,
            text: this.$data.selector.mode['name'](x)
          }));
        this.$data.items = this.$data.items.concat(actual_values);
        let is_required = true;
        if (Array.isArray(this.$props.before_list)) {
          is_required = !this.$props.before_list.find((x) => !x.value);
          this.$data.items = this.$props.before_list.concat(this.$data.items);
        }
        if (Array.isArray(this.$props.after_list)) {
          this.$data.items = this.$data.items.concat(this.$props.after_list);
        }
        if (is_required || this.$props.required) {
          this.$data.rules = [this.$validation.required()];
        }
        if (!search_results_items && this.$data.selector.limit_reached) {
          this.$data.items.push({divider: true});
          this.$data.items.push({ 'value': 'search_to_get_more', 'text': l.t('selector.search-to-get-more', 'Search to get more') });
        }
        if (Array.isArray(this.$props.filtered_values) && this.$props.filtered_values.length) {
          this.$data.items = this.$data.items.filter((x) => !this.$props.filtered_values.includes(x.value));
        }
      } catch (e) {
        console.log(e);
        this.$data.selector.loading = false;
        this.$data.alert = {
          level: 'error',
          message: l.t('app.generic-error', 'Something went wrong'),
        };
      }
    },
    async search_by_name(data) {
      await this.$data.selector.searchByName(data);
      this.build_items(true);
    },
    async clear_search_filters() {
      await this.$data.selector.clearFilters();
      this.build_items();
    },
    async update(data) {
      this.$data.selector.loading = true;
      this.$data.show_modal = false;
      if (this.selector.extension) this.selector.mode.model.extension = this.selector.extension;
      await this.selector.mode.model.update(data);
      if (this.selector.mode.model.alert) {
        this.$data.selector.loading = false;
        this.alert = this.selector.mode.model.alert;
        setTimeout(() => this.alert = null, 3000);
        if (this.alert.level === 'error') {
          this.$data.selector.loading = false;
          return false;
        }
      }
      this.update_screen(data);
      return this.$data.selector.loading = false;
    },
    update_screen(data) {
      if (data) {
        if (this.selector.values[data.id]) this.selector.values[data.id] = data;
        this.selector.mode.model.updateCache(data, this.selector.mode_name);
      }
      setTimeout(() => {
        this.$data.show_modal = false;
      }, 1000);
      this.build_items();
    },
    search_key_down(e) {
      if (e.keyCode === 13) {
        e.preventDefault();
        this.search_by_name(e);
      }
    },
  },
  computed: {
    options() {
      let options = [];
      if (this.user_may_edit_resource) {
        options.push({
          title: l.t('app.edit', 'Edit'),
          icon: '$vuetify.icons.edit',
          cb: () => this.$data.show_modal = true,
        });
      }
      if (this.$data.selector.limit_reached && !this.$data.selector.selector_alert) {
        options.push({
          title: `${l.t('app.search-by-name', 'Search by name')}${this.$data.selector.mode_name === 'extensions' ? ` ${l.t('selector.or-extension-number', 'or extension number')}` : ''}`,
          icon: '$vuetify.icons.search',
          cb: () => this.$data.selector.search = true,
        });
      }
      if (this.$data.selector.search_term && !this.$data.selector.selector_alert) {
        options.push({
          title: l.t('selector-return-to-regular-list', 'Return to regular list'),
          icon: 'mdi-view-list',
          cb: () => {
            this.clear_search_filters();
            this.$data.selector.search_term = '';
          },
        });
      }
      if (Array.isArray(this.$props.additional_options)) {
        options = [
          ...options,
          ...this.$props.additional_options
        ];
      }
      if (this.$props.closable) {
        options.push({
          title: l.t('app.remove', 'Remove'),
          icon: '$vuetify.icons.trash',
          cb: () => this.$emit('closed'),
        });
      }
      return options;
    },
    search_options() {
      const options = [];
      if (this.$data.selector.limit_reached && !this.$data.selector.selector_alert) {
        options.push({
          title: l.t('selector-return-to-list', 'Return to list'),
          icon: 'mdi-view-list',
          cb: () => {
            this.$data.selector.search = false;
            this.$data.selector.search_term = '';
          }
        });
      }
      if (this.$data.selector.filters_applied) {
        options.push({
          title: l.t('app.clear-search-results', 'Clear search results'),
          icon: 'mdi-close',
          cb: () => {
            this.clear_search_filters();
            this.$data.selector.search_term = '';
          },
        });
      }
      if (this.$props.closable) {
        options.push({
          title: l.t('app.remove', 'Remove'),
          icon: '$vuetify.icons.trash',
          cb: () => this.$emit('closed'),
        });
      }
      return options;
    },
    user_may_edit_resource() {
     const may_edit = !this.$props.no_update
       && !this.$props.multiple
       && !this.$props.disabled
       && this.$data.selector.mode.edit_route
       && this.$branding.data.routes.includes(this.$data.selector.mode.edit_route)
       && this.$data.selector.mode.model
       && this.$data.selector.mode.model.item;

       if (['hold_music', 'greetings', 'user_hold_music'].includes(this.$data.selector.mode_name)) {
          return may_edit && this.selector.value_v && this.selector.value_v > 24;
       }
       return may_edit && this.selector.value_v !== 'create';
   },
    placeholder_text() {
      if (
        !this.$props.label
        && (this.$props.placeholder || this.$data.selector.mode.placeholder)
        && this.items.length
      ) {
        if (this.$props.placeholder && typeof this.$props.placeholder === 'string') return this.$props.placeholder;

        return this.$data.selector.mode.placeholder;
      }

      return '';
    }
},
watch: {
  show_modal() {
    this.$data.modal_key = Date.now();
    }
  }
};
</script>
