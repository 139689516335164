<template>
    <w-loader v-if="contact.loading"/>
    <div class="create-page mt-3" v-else data-test-id="contacts-create-page">
        <w-alert
          v-if="contact.alert"
          :message="contact.alert.message"
          :level="contact.alert.level"
          :closable="contact.enable_close_alert"
          @closed="contact.alert = null"
          class="mb-6"
        />
        <div v-if="!one_extension && contact.item">
          <FormInput
            :label="l.t('app.extension', 'Extension')"
          >
            <ExtensionSelector
              :select_if_one="true"
              @changed="extension_id = $event.id"
              @only_one_auto_selected="one_extension = true"
            />
          </FormInput>
        </div>
        <ContactFragment
          v-if="contact.item && extension_id"
          :_contact="contact.item"
          :extension_id="extension_id"
          @contactSubmited="contact.create($event)"
        />
    </div>
</template>

<script>
  import l from '../../../libs/lang';
  import Helpers from '../../../libs/helpers';
  import Contact from '../../../models/Contact';
  import FormInput from '../../elements/form/FormInput.vue';
  import ContactFragment from '../../fragments/Contact.vue';
  import ExtensionSelector from '../../fragments/ExtensionSelector.vue';

  export default {
    components: {
      FormInput,
      ContactFragment,
      ExtensionSelector,
    },
    data() {
      return {
        l,
        extension_id: null,
        contact: new Contact(this.$session, Helpers.emitter(this), Helpers.changeRoute(this)),
        one_extension: false,
      };
    },
    watch: {
      async extension_id(value) {
        await this.$data.contact.extensionChanged(value);
        this.$forceUpdate();
      },
    },
  };
</script>
