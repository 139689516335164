<template>
  <div class="listener-filters">
    <FormInput :label="l.t('listeners.narrow-your-auto-responses', 'Narrow your auto-responses')"
      :key="`apply-filters-${apply_filters}`">
      <w-switch v-model="apply_filters" data-test-id="apply-filters-switch" />
    </FormInput>
    <div v-if="apply_filters" class="listener-filters-wrapper">
      <div v-for="(filter, f) in values">
        <FormInput>
          <template v-slot:label>
            <w-select :value="filter.filter_type" @input="(v) => on_filter_type_change(v, f)" :items="[
              { text: l.t('app.phone-number', 'Phone number'), value: 'number' },
              { text: l.t('app.schedule', 'Schedule'), value: 'schedule' },
            ]" data-lpignore="true" :data-test-id="`filter-type-select-${f}`" />
          </template>
          <div v-if="filter.filter_type === 'number'" :key="`number-${f}-filter}`">
            <div v-for="(key, i) in Object.keys(filter.filter_value)" class="d-flex" :key="`number-${i}`">
              <w-select :value="key" :items="number_filter_value_keys_items(f, i)"
                @input="(v) => on_filter_value_key_change(v, f, key)" class="number-filter-type" data-lpignore="true"
                :data-test-id="`filter-values-select-${f}-${i}`" />
              <div class="w-100 ml-5 d-flex" :key="`${i}-${key}`">
                <APIAutocomplete
                  v-if="key === 'in'"
                  :key="values.length + f"
                  v-model="filter.filter_value.in"
                  :rules="[$validation.required(), $validation.minlength(1)]"
                  @change="(v) => is_add_filter_disabled(v)"
                  value_prop="phone_number"
                  mode="phone_numbers"
                  multiple
                  class="w-100"
                  data-lpignore="true"
                  :data-test-id="`phone-number-autocomplete-${f}-${i}`"
                />
                <APIAutocomplete
                  v-if="key === 'eq'"
                  v-model="filter.filter_value.eq"
                  :rules="[$validation.required()]"
                  @change="(v) => is_add_filter_disabled(v)"
                  value_prop="phone_number"
                  mode="phone_numbers"
                  class="w-100"
                  data-lpignore="true"
                  :data-test-id="`phone-number-autocomplete-${f}-${i}`"
                />
              </div>
            </div>
          </div>
          <div v-else-if="filter.filter_type === 'schedule'">
            <APIAutocomplete
              v-model="filter.filter_value.id"
              :key="values.length + f"
              :rules="[$validation.required()]"
              @change="(v) => is_add_filter_disabled(v)"
              mode="schedules"
              class="w-100"
              data-lpignore="true"
              :data-test-id="`schedules-autocomplete-${f}`"
            />
          </div>
        </FormInput>
        <div class="d-flex justify-space-between" :class="f !== values.length - 1 ? 'my-5' : 'mt-5'">
          <w-btn v-if="f === values.length - 1" :disabled="disable_add_fitler_btn" @click="add_filter(f)" color="primary"
            class="font-weight-bold pl-0 add-new-destination-btn" text data-test-id="add-filter-btn">
            <v-icon class="mr-1">mdi-plus-circle</v-icon> {{ l.t('app.add-another-filter', 'Add another filter') }}
          </w-btn>
          <v-spacer v-else />
          <span @click="on_delete_filter(f)"
            class="my-auto cursor-pointer w-btn-medium error--text w-letter-spacing-medium"
            :data-test-id="`delete-filter-btn-${f}`">
            {{ l.t('app.delete-filter', 'Delete filter') }}
          </span>
        </div>
        <v-divider v-if="f < values.length - 1" class="mb-5" />
      </div>
    </div>
  </div>
</template>
<script>
import l from '../../../libs/lang';
import Tel from '../../elements/Tel.vue';
import Selector from '../../../models/Selector';
import FormInput from '../../elements/form/FormInput.vue';
import AddToListButton from '../../elements/AddToListButton.vue';
import APIAutocomplete from '../../elements/form/APIAutocomplete.vue';

export default {
  props: {
    value: {
      required: true,
    }
  },
  components: {
    Tel,
    Selector,
    FormInput,
    AddToListButton,
    APIAutocomplete,
  },
  data() {
    return {
      l,
      apply_filters: false,
      values: [],
      disable_add_fitler_btn: false,
    };
  },
  created() {
    this.$data.values = this.$props.value;
    this.apply_filters = !!this.values.length;
    this.is_add_filter_disabled();
  },
  methods: {
    is_add_filter_disabled(v) {
      this.$data.disable_add_fitler_btn = !this.$data.values.every((x) => x.filter_type && x.filter_value && Object.values(x.filter_value).every((y) => {
        if (v) {
            if (Array.isArray(v)) {
            return v.length;
            }
            return v;
        }
        if (Array.isArray(y)) {
          return y.length;
        }
        return y;
      }));
    },
    on_filter_type_change(value, filter_index) {
      let filter_value;
      if (value === 'schedule') {
        filter_value = { id: null };
      } else {
        filter_value = {
          in: [],
        };
      }
      this.values[filter_index].filter_type = value;
      this.values[filter_index].filter_value = filter_value;
      this.$forceUpdate();
      this.$emit('input', this.values);
      this.is_add_filter_disabled();
    },
    on_delete_filter(f) {
      this.values.splice(f, 1);
      if (!this.values.length) this.$data.apply_filters = false;
      this.$forceUpdate();
      this.$emit('input', this.values);
      this.is_add_filter_disabled();
    },
    number_filter_value_keys_items(filter_index, index) {
      if (this.values[filter_index].filter_type !== 'number') return [];
      let items = [
        { text: l.t('app.in', 'In'), value: 'in' },
        { text: l.t('app.equal', 'Equal'), value: 'eq' },
      ];
      const current_value = items.find((x) => x.value === Object.keys(this.values[filter_index].filter_value)[index]);
      if (this.values[filter_index].filter_value) {
        const keys = Object.keys(this.values[filter_index].filter_value);
        items = items.filter((x) => !keys.includes(x.value));
        if (current_value) items.unshift(current_value);
      }

      return items;
    },
    add_new_number_filter_value(new_value, filter_index) {
      this.values[filter_index].filter_value[new_value] = new_value === 'in' ? [] : '';
      this.$emit('input', this.values);
      this.is_add_filter_disabled();
    },
    on_filter_value_key_change(new_value, filter_index, old_value) {
      delete this.values[filter_index].filter_value[old_value];
      this.add_new_number_filter_value(new_value, filter_index);
      this.$forceUpdate();
      this.$emit('input', this.values);
      this.is_add_filter_disabled();
    },
    add_filter() {
      this.$data.values.push(this.default_filter());
      this.is_add_filter_disabled();
    },
    default_filter() {
      return JSON.parse(JSON.stringify({
        filter_type: 'number',
        filter_value: { in: [] },
      }));
    },
  },
  watch: {
    apply_filters(v) {
      if (!v) {
        this.$emit('input', []);
      } else {
        if (!this.$data.values || (this.$data.values && !this.$data.values.length)) {
          this.$data.values = [this.default_filter()];
        }
        this.$emit('input', this.$data.values);
      }
      this.is_add_filter_disabled();
    }
  }
};
</script>
<style scoped lang="scss">
.listener-filters {
  .listener-filters-wrapper {
    border: 1px solid rgba(133, 146, 154, 0.65);
    border-radius: 10px;
    padding: 15px;
    margin-bottom: 15px;
  }

  .number-filter-type {
    width: 199px;
  }
}
</style>
