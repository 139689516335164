var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"schedules"},[_c('Submenu',{key:`${_vm.l.detect()}${_vm.$route.name}`,attrs:{"routes":{
      'schedules.index': {
        title: _vm.l.t('app.schedules', 'Schedules'),
        subroutes: ['schedules.show']
        },
      'schedules.create': {
        title: _vm.l.t('schedules.add-schedules', 'Add schedules')
        },
    }}}),(_vm.$route.name === 'schedules.index')?_c('SchedulesFragment',{attrs:{"data-test-id":"schedules-index"},on:{"deletingStarted":function($event){_vm.deleting_in_progress = true},"deletingEnded":function($event){_vm.deleting_in_progress = false}}}):_vm._e(),(_vm.$route.name === 'schedules.create')?_c('SchedulesCreateFragment',{attrs:{"data-test-id":"schedules-create"}}):_vm._e(),(_vm.$route.name === 'schedules.show')?_c('SchedulesShowFragment',{attrs:{"data-test-id":"schedules-show"}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }