<template>
    <w-text-field
        v-model="val"
        @keydown="auto_correct"
        :rules="validation ? [v => validate(v) || l.t('app.invalid-zip-code', 'Invalid zip code')] : undefined"
        :placeholder="l.t('app.zip-code', 'Zip code')"
        :disabled="disabled"
        hide-details='auto'
        data-test-id="zip-code-input"/>
</template>
<script>
  import l from '../../../libs/lang';
  import Helpers from '../../../libs/helpers';

  export default {
    props: {
        validation: {
            type: Boolean,
            default: false,
        },
        value: {
            type: String,
            default: '',
        },
        disabled: {
            type: Boolean,
        },
    },
    created() {
        this.$data.val = this.$props.value;
    },
    data() {
        return {
            l,
            val: '',
      };
    },
    methods: {
        validate(v) {
            if (v) {
                const postal_regex = /^([0-9]{5})(?:[-\s]*([0-9]{4}))?$/;
                return postal_regex.test(v);
            }
            if (Object.prototype.hasOwnProperty.call(this.$attrs, 'required')) {
                return !!v;
            }

            return true;
        },
        auto_correct(e) {
            const always_allowed = Helpers.always_allowed_keys;
            if (
                !e.ctrlKey
                && !e.altKey
                && !e.shiftKey
                && !e.metaKey
                && !always_allowed.includes(e.key)
            ) {
                e.preventDefault();
                const selectedText = document.activeElement.value.substring(
                    document.activeElement.selectionStart,
                    document.activeElement.selectionEnd
                );
                const value = selectedText !== '' ? e.key : (this.$data.val || '') + e.key;
                if (!value.length) return this.$emit('input', '');

                const index = value.length - 1;
                if (index >= 9) {
                    return null;
                }
                const last_char = value[index];
                const number_regex = /\d+/i;
                const second_part_regex = /(?:[-\s]*([0-9]{4}))/i;
                if (index <= 4 && !number_regex.test(last_char)) {
                    return null;
                }
                if (index > 4 && second_part_regex.test(last_char)) {
                    return null;
                }
                this.$data.val = value;
                this.$emit('input', this.$data.val);
            }

            return true;
        },
    },
    watch: {
        val(v) {
            this.$emit('input', v);
        }
    },
};
</script>
