<template>
    <w-autocomplete
        v-if="states"
        v-bind="$attrs"
        v-on="$listeners"
        :items="states"
        name="us-states-selector"
        :placeholder="l.t('app.state', 'State')"
        :filter="filter_object"
        hide-details="auto"
        autocomplete="new-password"
        data-test-id="us-states-selector">
    </w-autocomplete>
</template>
<script>
import STATES from '../../../libs/geo/us_states.json';
import l from '../../../libs/lang';

export default {
    props: [],
    data() {
        return {
            l,
            states: null,
        };
    },
    created() {
        this.$data.states = STATES.map((x) => ({ value: x.code, text: x.name }));
    },
    methods: {
        filter_object(item, queryText, itemText) {
            return itemText.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1
            || item.value.toLowerCase() === queryText.toLowerCase();
        }
    },
};
</script>

<style scoped>

</style>
