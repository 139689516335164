<template>
    <w-loader v-if="loading"/>
    <div v-else>
      <w-alert v-if="alert" :message="alert.message" :level="alert.level" :closable="true" class="mb-3"/>
      <w-stepper v-model="step">
          <v-stepper-header>
            <template
              v-for="(s, i) in steps"
              :class="{ done: i < step, current: i === step }"
            >
              <v-stepper-step
                @click="step = Math.min(i, step)"
                :complete="step > i"
                :step="i+1"
                :data-test-id="`wizard-${i}`"
              >
              {{ s }}
              </v-stepper-step>
              <v-divider></v-divider>
            </template>
          </v-stepper-header>
          <v-stepper-items>
            <v-stepper-content step="0" data-test-id="wizard-step-0">
              <w-loader v-if="loading_step" :message="loading_message" size="small"/>
              <form v-else @submit="initial_configuration" class="text-center">
                <div class="text-h6" data-test-id="wizard-step-0-intro-text">
                  <p>
                      {{ l.t("wizard.introduction-text-1", "Let’s configure your new phone number") }}
                      <span style="white-space: nowrap;">{{ phone_number.phone_number | format_phone_number }}</span>
                  </p>
                  <p>
                      {{ l.t("wizard.introduction-text-2", "Current configuration will be overridden") }}
                  </p>
                </div>
                <w-btn type="submit" color="primary" class="float-right" data-test-id="wizard-step-0-next">{{ l.t("wizard.next", "Next") }}</w-btn>
              </form>
            </v-stepper-content>
            <v-stepper-content step="1" data-test-id="wizard-step-1">
              <w-loader v-if="loading_step" :message="loading_message" size="small"/>
              <form
                v-else
                @submit="setup_calls_forwarding"
              >
                <FormInput :label="l.t('wizard.forwarding-calls-text', 'Please, provide a number for call forwarding')">
                  <Tel
                    :value="forwarding_phone_number"
                    @changed="forwarding_phone_number = $event.value"
                    :required="true"
                    data-test-id="wizard-step-1-tel-input"
                  />
                </FormInput>
                <FormInput>
                  <w-switch v-model="one_number_for_forwarding" :label="l.t('wizard.use-the-same-number-for-sms', 'Use the same number for SMS')" hide-details='auto' data-test-id="wizard-step-1-use-the-same-for-sms-switch"/>
                </FormInput>
                <div class="d-flex justify-space-between">
                    <w-btn type="button" @click="next_step" color="info" data-test-id="wizard-step-1-skip-btn">
                        {{ l.t("app.skip", "Skip") }}
                    </w-btn>
                    <w-btn type="submit" color="primary" data-test-id="wizard-step-1-next-btn">{{ l.t("wizard.next", "Next") }}</w-btn>
                </div>
              </form>
            </v-stepper-content>
            <v-stepper-content step="2" data-test-id="wizard-step-2">
              <w-loader v-if="loading_step" :message="loading_message" size="small"/>
              <form
                v-else
                @submit="setup_messages_forwarding"
              >
                <FormInput :label="l.t('wizard.forwarding-messages-text', 'Please, provide a number for message forwarding')">
                  <Tel
                    :value="messaging_phone_number"
                    @changed="messaging_phone_number = $event.value"
                    :required="true"
                    data-test-id="wizard-step-2-tel-input"
                  />
                </FormInput>
                <div class="d-flex justify-space-between">
                  <w-btn type="button" @click="next_step" color="info" data-test-id="wizard-step-2-skip-btn">
                      {{ l.t("app.skip", "Skip") }}
                  </w-btn>
                  <w-btn type="submit" color="primary" data-test-id="wizard-step-2-next-btn">{{ l.t("wizard.next", "Next") }}</w-btn>
                </div>
              </form>
            </v-stepper-content>
            <v-stepper-content step="3" data-test-id="wizard-step-3">
              <w-loader v-if="loading_step" :message="loading_message" size="small"/>
              <form
                v-else
                @submit="setup_notifications"
              >
                <FormInput :label="l.t('wizard.notifications-text', 'Provide email to receive SMS, FAX and Voicemails notifications')">
                  <Email
                    v-model="notifications_email"
                    :required="true"
                    data-test-id="wizard-step-3-email-input"
                  />
                </FormInput>
                <div class="d-flex justify-space-between">
                  <w-btn type="button" @click="next_step" color="info" data-test-id="wizard-step-3-skip-btn">
                      {{ l.t("app.skip", "Skip") }}
                  </w-btn>
                  <w-btn type="submit" color="primary" data-test-id="wizard-step-3-next-btn">{{ l.t("wizard.next", "Next") }}</w-btn>
                </div>
              </form>
            </v-stepper-content>
            <v-stepper-content step="4" data-test-id="wizard-step-4">
              <w-loader v-if="loading_step" :message="loading_message" size="small"/>
              <template v-else>
                <form
                  v-if=" app_step === 0"
                  @submit="next_step"
                >
                  <div class="text-h6 text-center" data-test-id="wizard-step-4-text">
                    <p>
                        {{ l.t("wizard.lr-text-1", "We are almost done. Just one more question.") }}
                    </p>
                    <p>
                        {{ l.t("wizard.apps-text", "Do you want to download our app?") }}
                    </p>
                  </div>
                  <div class="d-flex justify-space-between" data-test-id="wizard-step-4-btns">
                    <w-btn type="button" @click="finish_wizard" color="info" data-test-id="wizard-step-4-no">{{ l.t("app.no", "No") }}</w-btn>
                    <w-btn type="button" @click="app_step = 1" color="primary" data-test-id="wizard-step-4-yes">
                        {{ l.t("app.yes", "Yes") }}
                    </w-btn>
                  </div>
                </form>
                <form
                  v-else-if="app_step === 1"
                  @submit="next_step"
                  data-test-id="wizard-app-step-1"
                >
                  <FormInput :label="l.t('wizard.apps-text-2', 'We will send an SMS message with link and credentials to your phone number')">
                    <Tel
                      :value="credentials_phone_number"
                      @changed="credentials_phone_number = $event.value"
                      :required="true"
                      data-test-id="wizard-app-step-1-tel-input"
                    />
                  </FormInput>
                  <div class="d-flex justify-space-between">
                    <w-btn @click="finish_wizard" data-test-id="wizard-app-step-1-skip-btn">{{ l.t("app.skip", "Skip") }}</w-btn>
                    <div>
                      <w-btn @click="send_app_credentials('android')" type="button" color="#9FC137" data-test-id="wizard-app-step-1-android-btn">
                        <v-icon>mdi-android</v-icon>
                        {{ l.t("app.android", "I use Android") }}
                      </w-btn>
                      <w-btn @click="send_app_credentials('ios')" type="button" class="ml-3" color="#C9D0D8" data-test-id="wizard-app-step-1-apple-btn">
                        <v-icon>mdi-apple</v-icon>
                        {{ l.t("app.ios", "I use iPhone") }}
                      </w-btn>
                    </div>
                  </div>
                </form>
              </template>
            </v-stepper-content>
          </v-stepper-items>
        </w-stepper>
    </div>
</template>

<script>
  import l from '../../libs/lang';
  import Helpers from '../../libs/helpers';
  import Tel from '../elements/Tel.vue';
  import Email from '../elements/Email.vue';
  import FormInput from '../elements/form/FormInput.vue';

  export default {
    props: ['_phone_number'],
    components: {
      Tel,
      Email,
      FormInput,
    },
    data() {
      return {
        l,
        alert: null,
        loading: true,
        loading_message: '',
        loading_step: false,
        phone_number: null,
        extension: null,
        step: 0,
        app_step: 0,
        steps: [
          l.t('wizard.introduction', 'Introduction'),
          l.t('wizard.phone-calls', 'Phone calls'),
          l.t('app.messages', 'Messages'),
          l.t('app.notifications', 'Notifications'),
          l.t('wizard.finish', 'Finish'),
        ],
        one_number_for_forwarding: true,
        forwarding_phone_number: '',
        messaging_phone_number: '',
        credentials_phone_number: '',
        notifications_email: '',
      };
    },
    async created() {
      if (this.$props._phone_number) {
        this.$data.phone_number = this.$props._phone_number;
        this.$data.loading = false;
      } else {
        this.$data.loading = true;
        try {
          await this.load_data();
        } catch (err) {
          this.something_went_wrong();
        }
        this.$data.loading = false;
      }
    },
    methods: {
      something_went_wrong() {
        Helpers.show_generic_error.call(this);
        this.$data.loading_step = false;
        this.$data.loading_message = null;
      },
      async load_data(callback) {
        try {
          this.$data.phone_number = await this.$session.get_item(`/phone-numbers/${this.$route.params.id}`);
          const extensions = await this.$session.get_list('/extensions');
          this.$data.extension = extensions.items[0];
          if (callback) callback();
        } catch (err) {
          this.something_went_wrong();
        }
      },
      next_step(e) {
        if (e) e.preventDefault();
        // eslint-disable-next-line operator-assignment
        this.$data.step = this.$data.step + 1;
        this.$data.loading_message = null;
      },
      initial_configuration(e) {
        if (e) e.preventDefault();
        this.next_step();
      },
      async setup_calls_forwarding(e) {
        if (e) e.preventDefault();
        this.$data.loading_message = l.t('wizard.configuring-calls', 'Configuring calls forwarding...');
        this.$data.loading_step = true;
        const route = {
          rules: [
            {
              actions: [
                {
                  action: 'forward',
                  timeout: 20,
                  hold_music: null,
                  items: [
                    {
                      type: 'phone_number',
                      number: this.$data.forwarding_phone_number,
                    },
                  ],
                },
              ],
            },
          ],
        };
        try {
          this.$data.phone_number['route'] = await this.$session.create_item('/routes', route);
          this.$data.phone_number['sms_forwarding'] = {
            type: 'extension',
            extension: this.$data.extension['id'],
          };
          await this.$session.replace_item(
            `/phone-numbers/${this.$data.phone_number['id']}`,
            this.$data.phone_number,
          );
          this.next_step();
          if (this.$data.one_number_for_forwarding) {
            this.$data.messaging_phone_number = this.$data.forwarding_phone_number;
            await this.setup_messages_forwarding();
          } else {
            this.$data.loading_step = false;
          }
        } catch (err) {
          this.something_went_wrong();
        }
      },
      async setup_messages_forwarding(e) {
        if (e) e.preventDefault();
        this.$data.loading_message = l.t('wizard.configuring-messages', 'Configuring messages forwarding...');
        this.$data.loading_step = true;
        if (!this.$data.extension['voicemail']['notifications']) this.$data.extension['voicemail']['notifications'] = {};
        this.$data.extension['voicemail']['notifications'][
          'sms'
          ] = this.$data.messaging_phone_number;
        try {
          await this.$session.replace_item(
            `/extensions/${this.$data.extension['id']}`,
            this.$data.extension,
          );
          this.next_step();
          this.$data.credentials_phone_number = this.$data.messaging_phone_number;
          this.$data.loading_step = false;
        } catch (err) {
          this.something_went_wrong();
        }
      },
      async setup_notifications(e) {
        if (e) e.preventDefault();
        this.$data.loading_message = l.t('wizard.configuring-notifications', 'Configuring notifications...');
        this.$data.loading_step = true;
        this.$data.extension['voicemail']['notifications']['email'] = [
          this.$data.notifications_email,
        ];
        try {
          await this.$session.replace_item(
            `/extensions/${this.$data.extension['id']}`,
            this.$data.extension,
          );
          this.next_step();
          this.$data.loading_step = false;
        } catch (err) {
          this.something_went_wrong();
        }
      },
      finish_wizard(e) {
        if (e) e.preventDefault();
        // this.wizard_completed();
        this.$router.push({
          name: 'phone-numbers.show',
          params: {
            id: this.$data.phone_number['id'],
          },
        });
      },
      async send_app_credentials(platform) {
        this.$data.loading_message = l.t('wizard.sending-sms-with-credentials', 'Sending SMS with credentials...');
        this.$data.loading_step = true;
        const link = this.$branding.data.mobile[platform];
        try {
          await this.$session.create_item('/sms', {
            from: this.$data.phone_number['phone_number'],
            to: this.$data.credentials_phone_number,
            text: [
              `${l.t('mobile-apps.send-link-sms', 'Application link')}: ${link}\n`,
              `${l.t('app.phone-number', 'Phone number')}: ${
                this.$data.phone_number['phone_number']
                }`,
              `${l.t('app.extension', 'Extension')}: ${
                this.$data.extension['extension']
                }`,
              `${l.t('app.password', 'Password')}: ${
                this.$data.extension['voicemail']['password']
                }`,
            ].join('\n'),
          });
          this.$data.loading_step = false;
          this.next_step();
        } catch (err) {
          this.something_went_wrong();
        }
      },
    },
  };
</script>
