<template>
  <w-loader v-if="blocked.loading"/>
  <div v-else class="create-page" data-test-id="bc-create-page">
      <w-alert
        v-if="blocked.alert"
        :message="blocked.alert.message"
        :level="blocked.alert.level"
        :closable="blocked.enable_close_alert"
        @closed="blocked.alert = null"
        class="mb-6"
      />
        <BlockedCallsFragment
          v-if="!blocked.alert || (blocked.alert && blocked.alert.level !== 'success')"
          :_call="blocked.item"
          @submited="blocked.create($event)"
        />
  </div>
</template>

<script>
  import Helpers from '../../../libs/helpers';
  import BlockedCall from '../../../models/BlockedCall';
  import BlockedCallsFragment from '../../fragments/BlockedCall.vue';

  export default {
    components: {
      BlockedCallsFragment,
    },
    data() {
      return {
        blocked: new BlockedCall(this.$session, Helpers.emitter(this), Helpers.changeRoute(this)),
      };
    },
  };
</script>
