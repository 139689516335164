<template>
  <div id="app" :class="`${$branding.data.code}-branding`" :data-lang="l.detect()">
    <v-app v-if="account_loaded && session_exists() && $session.user && subaccounts_owner_mode"
      data-test-id="v-app-subaccounts-owner">
      <SubaccountsOwner @sign_out="sign_out" />
    </v-app>
    <v-app v-else data-test-id="v-app">
      <!-- <SplashScreen v-if="account_loaded && session_exists() && $session.user && branding_exists() && !['avo', 'avo_master'].includes($branding.data.code)" /> -->
      <Header :is_large_page="is_large_page" @sign_out="sign_out" @language_changed="changeLanguage($event)"
        :key="`header-${account_loaded}`" />
      <v-container v-if="loading" fill-height data-test-id="app-loader-container">
        <div v-if="loading" class="w-100">
          <w-loader size="small" :message="loading_message ? loading_message : null" />
        </div>
      </v-container>
      <v-container v-else-if="error" fill-height data-test-id="app-error-container">
        <w-alert level="error" :message="error" class="my-auto" @closed="alert_closed" />
      </v-container>
      <v-container v-else-if="session_exists() && $session.user" id="main-container" fluid>
        <v-row>
          <v-col md="12" class="pt-0" id="authorized-screen">
            <div id="sidebar-container" class="do-not-print">
              <Sidebar v-if="branding_exists()" @sign_out="sign_out" @language_changed="changeLanguage($event)"
                @account="account_loaded = true" :key="`${l.detect()}-${sidebarRenderKey}`" data-test-id="sidebar" />
            </div>
            <div id="page-container">
              <div id="page-content" :class="{ 'large-page-content': is_large_page }" class="mx-auto page-content"
                data-test-id="page-content">
                <main data-test-id="main">
                  <router-view id="main-content" :key="`${$route.fullPath}-${language}`" @failed="failed"
                    @language_changed="changeLanguage($event)" @accountUpdated="sidebarRenderKey = Date.now()"
                    data-test-id="router-view"></router-view>
                </main>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-container>
      <v-container v-else fill-height id="auth-screen" data-test-id="auth-screen">
        <Homepage :oauth_url="$session.oauth_url" @token_changed="token = $event" @token_submitted="tryToken"
          @language_changed="changeLanguage($event)" class="mx-auto" />
      </v-container>
      <DiscardChangesModal v-if="discard_changes_callback" @closed="discard_changes_callback = null"
        @discard="do_discard_changes" />
    </v-app>
  </div>
</template>

<script>
import Vue from 'vue';
import VueRouter from 'vue-router';
import Session from 'phoenix-api-js-client';
import FeatureToggle from '@dev.phone.com/pdc-feature-toggle-js';

import l from './libs/lang';
import router from './router';
import Helpers from './libs/helpers';
import Cachier from './libs/Cachier';
import Branding from './libs/branding/setup';
import ValidationRules from './libs/validation/rules';

import Header from './components/elements/Header.vue';
import Sidebar from './components/elements/Sidebar.vue';
import Homepage from './components/screens/Homepage.vue';
import SplashScreen from './components/elements/SplashScreen.vue';
import SubaccountsOwner from './components/screens/SubaccountsOwner.vue';
import DiscardChangesModal from './components/elements/modal/DiscardChanges.vue';

Vue.use(VueRouter);

const Router = new VueRouter({
  mode: 'history',
  routes: router,
});
Router.beforeEach((to, from, next) => {
  window.scrollTo({ top: 0 });
  window.dataLayer.push({
    event: 'pdc_MINI15_route',
    route_name: to.name,
    route_uri: to.path,
  });
  if (window.successfulAlertTimeout) clearTimeout(window.successfulAlertTimeout);
  next();
});

export default {
  name: 'app',
  components: {
    Header,
    Sidebar,
    Homepage,
    SplashScreen,
    SubaccountsOwner,
    DiscardChangesModal,
  },
  router: Router,
  data() {
    return {
      l,
      error: null,
      loading: false,
      loading_message: null,
      language: l.detect(),
      use_token: false,
      token: null,
      sidebarRenderKey: Date.now(),
      account_loaded: false,
      dev_feature: 'console.show_developer_feature',
      discard_changes_callback: null,
      form_input_changed: false,
      cachier: new Cachier('console.phone.com'),
      uses_token_to_login: 'uses-token',
      subaccounts_owner_mode: false,
    };
  },
  beforeCreate() {
    const session = new Session({
      client_id: process.env.VUE_APP_CLIENT_ID,
      scope: ['account-owner', 'scheduled-requests', 'billing-api'],
      session_name: 'mini-cp',
      id_token_sign_out: true,
      ignore_state: true,
      session_scope: 'browser',
      oauth_api_url: process.env.VUE_APP_OAUTH_API_URL,
      accounts_url: process.env.VUE_APP_ACCOUNTS_URL,
      phoenix_url: process.env.VUE_APP_PHOENIX_URL,
    });
    Vue.prototype.$session = session;
    l.choose_language(l.detect());
  },
  mounted() {
    const logging_out_message = l.t('app.signing-out', 'Signing out...');
    const session_expired_message = l.t('app.session-expired-sign-out', 'Session expired. Signing out...');
    this.$session.on('logging-out', () => {
      this.$data.loading_message = this.$session._session_expired() ? session_expired_message : logging_out_message;
      this.$data.account_loaded = false; // this will close the account box popup
      this.$data.loading = true;
    });
    this.$session.on('logged-out', () => {
      this.logged_out_listener(logging_out_message);
    });
    this.$session.on('session-expired', () => {
      this.logged_out_listener(session_expired_message);
    });
    // this.$session.on('error', (err) => {
    //   this.error_listener(err);
    // });
  },
  async created() {
    this.$data.loading = true;
    this.$data.loading_message = null;
    try {
      this.sync_branding_data();
      this.register_global_events();
      Vue.prototype.$validation = ValidationRules;

      this.put_favicon_icon();
      this.check_redirect_and_old_user();
      await this.$session.init_user();
      await this.post_sign_in_process();
      if (!await this.user_can_access_page_middleware(this.$route)) {
        this.$router.push({ name: 'not-found' });
      }
      this.$router.beforeEach(async (to, from, next) => {
        if (!await this.user_can_access_page_middleware(to)) {
          return this.$router.push({ name: 'not-found' });
        }
        if (this.$data.form_input_changed && document.querySelector('form[data-discard="true"]')) {
          return this.$data.discard_changes_callback = () => {
            this.$data.form_input_changed = false;
            next();
          };
        }
        next();

        return true;
      });
      this.$router.afterEach(() => {
        this.$data.form_input_changed = false;
      });

      this.$data.loading = false;
    } catch (err) {
      await this.force_end_session(err);
    }
  },
  methods: {
    async post_sign_in_process() {
      if (this.$session.user) {
        const token_data = await Helpers.token_details(this.$session);
        if (token_data.scope.startsWith('subaccounts-owner:')) {
          this.$data.subaccounts_owner_mode = true;
        } else {
          this.$session.user.account = await Helpers.get_account_information(this.$session, true);
        }
      }
      if (!this.$data.subaccounts_owner_mode) {
        this.sync_branding_data();
        await this.create_global_feature_toggle();
        if (!await this.user_can_access_page_middleware(this.$route)) {
          this.$router.push({ name: 'not-found' });
        }
        this.$data.account_loaded = true;
        this.sendDataToDataLayer();
      }
    },
    async tryToken() {
      try {
        this.$data.account_loaded = false;
        this.$data.loading_message = l.t('app.singing-in', 'Signing in...');
        this.$data.loading = true;
        if (this.$data.token.startsWith('Bearer ')) this.$data.token = this.$data.token.slice(7).trim();

        await this.$session._load_user(`Bearer ${this.$data.token}`, true);
        await this.post_sign_in_process();
        this.$data.cachier.setItem(this.uses_token_to_login, true);
        this.$data.token = null;
        this.$data.use_token = false;
        this.$data.loading_message = null;
        this.$data.loading = false;
      } catch (err) {
        await this.force_end_session(err);
      }
    },
    async force_end_session(err) {
      if (this.$session.user && err.status === 404) {
        await this.sign_out();
      }
      this.$data.error = l.t('app.generic-error', 'Something went wrong');
      setTimeout(async () => {
        if (this.$session.user) await this.sign_out();
      }, 2500);
      console.log(err);
      this.$data.loading = false;
      this.$data.loading_message = null;
    },
    sync_branding_data() {
      const branding = new Branding(this.$session);
      Vue.prototype.$branding = branding;
      const { light } = branding.data.theme;
      Object.keys(light).forEach((i) => {
        this.$vuetify.theme.themes.light[i] = light[i];
      });
      // TODO here we should filter routes when upgraded to router v4. For now we have check in the user_can_access_page_middleware
      this.put_favicon_icon();
    },
    register_global_events() {
      this.$global_emitter.$on('form_input_changed', () => this.$data.form_input_changed = true);
      this.$global_emitter.$on('item_updated', () => this.$data.form_input_changed = false);
      this.$global_emitter.$on('use_subaccount', (item) => this.use_subaccount(item));
      this.$global_emitter.$on('use_main_account', (item) => this.use_main_account(item));
      this.$global_emitter.$on('subaccounts_owner_use_subaccount', (sub) => this.subaccounts_owner_use_subaccount(sub));
      this.$global_emitter.$on('subaccounts_owner_user_main_account', () => this.subaccounts_owner_use_main_account());
    },
    async create_global_feature_toggle() {
      let feature_toggle = null;
      if (this.$session && this.$session.user) {
        this.$data.loading = true;
        const ft_options = {
          session_token: this.$session && this.$session.user ? this.$session.user.token : '',
          voip_id: this.$session && this.$session.user ? this.$session.user.id : '',
          url: process.env.VUE_APP_PHOENIX_URL,
        };
        feature_toggle = new FeatureToggle(ft_options);
        this.$init_feature_toggle(ft_options);
      }
      Vue.prototype.$feature_toggle = feature_toggle;
      if (this.$feature_toggle) {
        await this.$feature_toggle.isFeatureEnabled('conversations'); // loads example feature so we'd cache the response
      }
      return true;
    },
    async sign_out(message) {
      const session_has_id_token = !!this.$session.id_token;
      this.$data.loading_message = message || l.t('app.signing-out', 'Signing out...');
      this.$data.loading = true;
      await this.$session.sign_out();
      if (this.$session.uses_token || !session_has_id_token) this.$data.loading = false;
    },
    alert_closed() {
      window.location.reload();
    },
    do_discard_changes() {
      if (this.$data.discard_changes_callback) this.$data.discard_changes_callback();
      this.$data.discard_changes_callback = null;
    },
    check_redirect_and_old_user() {
      const { session_name } = this.$session.options;
      if (
        document.location.hash.includes('#access_token=')
        && document.location.hash.includes('token_type=Bearer')
        && this.$session._getItem(session_name)
      ) {
        this.$session._removeItem(session_name);
      }
    },
    // logged_out_listener(message) {
    logged_out_listener() {
      try {
        // if (!this.$data.cachier.getItem(this.uses_token_to_login)) {
        //   this.$data.loading_message = message;
        //   this.$data.loading = true;
        // }
        this.$data.cachier.purgeCache();
        this.loading = false;
      } catch (err) {
        console.log('An error occured during the process of signing out. Force removing the session user.', err);
        this.id_token = null;
        this.decoded_id_token = null;
        this.user = null;
        this.$session.reset_cache();
        this.loading = false;
      }
    },
    // async error_listener(err) {
    //   if (err.status === 401 && this.$session.user) {
    //     await this.sign_out();
    //   }
    // },
    async user_can_access_page_middleware(route) {
      return Helpers.user_can_access_page_middleware(route, this.$feature_toggle, this.$branding);
    },
    failed(e) {
      this.$data.error = e.message;
    },
    changeLanguage(val) {
      this.$data.l.choose_language(val, true);
      this.$data.language = l.detect();
      this.$data.sidebarRenderKey = Date.now();
      this.$forceUpdate();
    },
    async subaccounts_owner_use_subaccount(item) {
      this.$data.loading = true;
      this.$data.account_loaded = false;
      this.$session = Helpers.use_subaccount(this.$session, item, true);
      this.$data.subaccounts_owner_mode = false;
      if (this.$session.user) {
        this.$session.user.account = await Helpers.get_account_information(this.$session, true);
      }
      this.sync_branding_data();
      await this.create_global_feature_toggle();
      if (!await this.user_can_access_page_middleware(this.$route)) {
        this.$router.push({ name: 'not-found' });
      }
      this.$data.sidebarRenderKey = Date.now();
      window.scroll({ top: 0 });
      this.sync_branding_data();
      this.$router.push({ name: 'homepage' });
      this.$data.account_loaded = true;
      this.$data.loading = false;
    },
    async use_subaccount(item) {
      this.$data.account_loaded = false;
      this.$session = Helpers.use_subaccount(this.$session, item);
      if (this.$session.user) {
        this.$session.user.account = await Helpers.get_account_information(this.$session, true);
      }
      this.$data.sidebarRenderKey = Date.now();
      window.scroll({ top: 0 });
      this.sync_branding_data();
      this.$router.push({ name: 'homepage' });
      this.$data.account_loaded = true;
    },
    async subaccounts_owner_use_main_account() {
      this.$data.subaccounts_owner_mode = true;
      this.$session = Helpers.use_main_account(this.$session);
      this.$data.sidebarRenderKey = Date.now();
      window.scroll({ top: 0 });
      this.sync_branding_data();
      this.$router.push({ name: 'homepage' });
    },
    use_main_account() {
      this.$session = Helpers.use_main_account(this.$session);
      this.$data.sidebarRenderKey = Date.now();
      window.scroll({ top: 0 });
      this.sync_branding_data();
      this.$router.push({ name: 'homepage' });
    },
    sendDataToDataLayer() {
      if (this.$session && this.$session.user) {
        window.dataLayer.push({
          event: 'pdc_MINI15_signin',
          pdc_account_id: this.$session.user.id,
        });
      }
    },
    put_favicon_icon() {
      const branding = this.$branding || new Branding(this.$session);
      document
        .getElementById('favicon')
        .setAttribute(
          'href',
          `/icon/${branding.data.icon}.ico`,
        );
    },
    branding_exists() {
      return !!this.$branding;
    },
    session_exists() {
      return !!this.$session;
    },
  },
  computed: {
    is_large_page() {
      for (const route of ['api-requests.', 'devices.', 'faxes.', 'media.', 'messages.', 'extensions.']) {
        if (this.$route.name.startsWith(route)) {
          return true;
        }
      }
      // if (this.$route.name.startsWith('devices.') && !this.$route.name.includes('.buy')) {
      //   return true;
      // }
      return [
        'call-logs',
        'live-answer.index',
        'live-answer.show',
        'live-answer.create',
        'reports.index',
        'reports.show',
        'subaccounts.index',
        'voicemail',
        // 'api-requests.index',
        // 'devices.buy',
        // 'devices.index',
        // 'fax.index',
        // 'media.index',
        // 'media.hold-music',
        // 'messages.index',
      ].includes(this.$route.name)
        || [].includes(this.$route.fullPath);
    },
  },
};
</script>

<style lang="scss">
@import "./assets/styles/scss/styles.scss";
</style>
