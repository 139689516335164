import l from '../libs/lang';
import Helpers from '../libs/helpers';

export default class RouteAnalyzer {
	constructor(route) {
		this.route = route;
		this.describing_phrases = [];
		this.description = null;
		this.weaknesses = [];
		this.vulnerabilities = null;
		this.described = false;

		this.translations = {
			schedule: l.t('routes.received-during', 'Received during'),
			forward: l.t('routes.forward-call', 'Forward call'),
			hold_music: l.t('app.hold-music', 'Hold music'),
			hold: l.t('app.play-hold-music', 'Play hold music'),
			extension: l.t('app.extension', 'Extension'),
			contact: l.t('app.contact', 'Contact'),
			group: l.t('app.contact-group', 'Contact group'),
			menu: l.t('app.menu', 'Menu'),
			greeting: l.t('app.greeting', 'Greeting'),
			queue: l.t('app.queue', 'Queue'),
			trunk: l.t('app.trunk', 'Trunk'),
			fax: l.t('app.fax', 'Fax'),
			unknown: l.t('routes.no-valid-caller-id', 'No valid caller ID'),
			disconnect: l.t('app.disconnect', 'Disconnect'),
			voicemail: l.t('app.voicemail', 'Voicemail'),
			directory: l.t('routes.dial-by-directory', 'Dial by directory'),
			live_answer: l.t('app.receptionist-service', 'Receptionist service'),
		};
		this.analyze();
	}

	analyze() {
		if (!this.route) {
			this.description = l.t('routes.call-flow-empty', 'Call flow is not configured');
		} else if (this.route.rules && !this.route.rules.length) {
			this.description = l.t('routes.call-flow-empty', 'Call flow is not configured');
		} else if (this.route.name) {
			this.description = `${l.t('app.preset', 'Preset')}: ${this.route.name}`;
		} else {
			for (const rule of this.route.rules) {
				if (rule) {
					this.analyzeFilter(rule.filter);
					this.analyzeActions(rule.actions);
					this.described = true;
				}
			}

			this.description = this.prepareDescription();
			this.vulnerabilities = this.prepareVulberabilities();
		}
	}

	prepareDescription() {
		let description = this.describing_phrases.join(' ');
		if (this.route && this.route.rules && this.route.rules.length > 1) {
			description += '...';
		}

		return description;
	}

	prepareVulberabilities() {
		let data = null;
		if (this.weaknesses.length) {
			data = {};
			data.text = `${l.t('routes.deleted-resources', 'Deleted resources:')} \n`;
			data.html = `${l.t('routes.deleted-resources', 'Deleted resources:')} <br/>`;
			for (const v of [...new Set(this.weaknesses)]) {
				data.text += `${v}: ${this.weaknesses.filter((x) => x === v).length} \n`;
				data.html += `${v}: ${this.weaknesses.filter((x) => x === v).length} <br/>`;
			}
			data.count = this.weaknesses.length;
		}

		return data;
	}

	analyzeFilter(filter) {
		if (filter) {
			if (filter.type === 'contact_group') filter.type = 'group';
			this.describe(`${this.translations[filter.type]}`);
			if (filter[filter.type]) {
				if (filter[filter.type]['name']) {
					this.describe(`(${filter[filter.type]['name']})`);
				} else if (filter.type === 'contact') {
					const { contact } = filter;
					const name = [contact['first_name'], contact['middle_name'], contact['last_name']].filter((x) => x).join(' ') || '—';
					this.describe(`(${name})`);
				}
			} else if (filter.type !== 'unknown') {
				this.describe(`(${l.t('app.deleted', 'Deleted')})`);
				if (filter.type !== 'unknown') {
					if (filter.type === 'schedule') {
						this.weaknesses.push(l.t('app.schedule', 'Schedule'));
					} else {
						this.weaknesses.push(this.translations[filter.type]);
					}
				}
			}
		} else {
			this.describe(`${l.t('app.all-calls', 'All calls')}:`);
		}
	}

	analyzeActions(actions) {
		if (actions && actions.length) {
			let first = true;
			for (const action of actions) {
				this.describe(`${first ? '' : ', '}${this.translations[action.action]}`);
				first = false;
				if (action.action === 'fax' || action.action === 'voicemail') {
					if (action.extension) {
						this.describe(`(${l.t('app.extension', 'Extension')}) (${action.extension.extension}: ${action.extension.name})`);
					} else {
						this.describe(`(${l.t('app.deleted', 'Deleted')})`);
						this.weaknesses.push(l.t('app.extension', 'Extension'));
					}
				} else if (action.items && action.items.length) {
					this.analyzeItems(action.items);
				} else if (['queue', 'menu', 'trunk'].includes(action.action)) {
					if (action[action.action]) {
						this.describe(`(${action[action.action]['name']})`);
					} else {
						this.describe(`(${l.t('app.deleted', 'Deleted')} ${this.translations[action.action].toLowerCase()})`);
						this.weaknesses.push(this.translations[action.action]);
					}
				} else if (['greeting', 'hold'].includes(action.action)) {
					this.analyzeMedia(action);
				} else if (action.action === 'live_answer') {
					if (action.script && action.script.name) {
						this.describe(`(${action.script.name})`);
					} else {
						this.describe(`(${l.t('app.deleted', 'Deleted')})`);
						this.weaknesses.push(l.t('app.receptionist-service', 'Receptionist service'));
					}
				}
			}
		} else {
			this.describe(`${l.t('routes.no-actions-provided', 'No actions provided')}`);
		}
	}

	analyzeItems(items) {
		for (const item of items) {
			if (item.type === 'extension') {
				if (item.extension) {
					this.describe(`(${l.t('app.extension', 'Extension')}) 
						(${item.extension.extension}: ${item.extension.name})`);
				} else {
					this.describe(`(${l.t('app.deleted', 'Deleted')})`);
					this.weaknesses.push(l.t('app.extension', 'Extension'));
				}
			}
			if (item.type === 'phone_number') {
				if (item.number) {
					this.describe(`(${l.t('app.number', 'Number')}) (${Helpers.format_phone_number(item.number)})`);
				} else {
					this.describe(`(${l.t('app.deleted', 'Deleted')})`);
					this.weaknesses.push(l.t('app.phone-number', 'Phone number'));
				}
			}
		}
	}

	analyzeMedia(action) {
		if (action.action === 'greeting') {
			if (action.greeting) {
				this.describe(`(${action.greeting.name})`);
			} else {
				this.describe(`(${l.t('app.deleted', 'Deleted')} ${this.translations[action.action].toLowerCase()})`);
				this.weaknesses.push(this.translations[action.action]);
			}
		} else if (action.hold_music) {
				this.describe(`(${action.hold_music.name})`);
		} else if (action.action === 'hold' && !action.hold_music) {
			this.describe(l.t('hold-music.default', 'Default ringtone'));
		} else {
				this.describe(`(${l.t('app.deleted', 'Deleted')} ${this.translations[action.action].toLowerCase()})`);
				this.weaknesses.push(this.translations[action.action]);
			}
	}

	describe(text) {
		if (!this.described) this.describing_phrases.push(text);
	}
}
