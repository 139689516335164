<template>
    <ConfirmationModal
        v-bind="$attrs"
        v-on="$listeners"
        :action_text="l.t('app.disable-script', 'Disable script')"
        :title="l.t('app.disable-script', 'Disable script')"
        width="400"
        confirm_color="error base text--darken-1"
        data-test-id="lr-deactivate-modal"
    >
        {{ l.t('lr.deactivation-modal-help-text', 'Your receptionist will stop answering. Be sure you have an alternative call handling rule in place.') }}
        <template v-if="(vendor_id && vendor_id !== 4) || !['avo', 'avo_master'].includes(this.$branding.data.code)">
            <br/>
            <br/>
            {{l.t('lr.to-enable-this-script-in-the-future-you-will-need-our-customer-support', 'If you need to enable this script in the future, you will need our customer support team to assist you. Are you sure you want to proceed with disabling this script?')}}
        </template>
    </ConfirmationModal>
</template>
<script>
import l from '../../../libs/lang';
import ConfirmationModal from '../../elements/modal/ConfirmationModal.vue';

export default {
    props: {
        vendor_id: {
            type: Number,
        },
    },
	components: {
        ConfirmationModal,
    },
	data() {
		return {
      l,
    };
	},
};
</script>
