import l from '../libs/lang';
import Invoice from './Invoice';
import Resource from './Resource';
import Helpers from '../libs/helpers';
import PDFExporter from './PDFExporter';

export default class Billing extends Resource {
	constructor(session, emitter, changeRoute, forceUpdate) {
		super(session, emitter, '/invoices', null, changeRoute);
		this.invoices = []; // invoices
		this.payment_methods = [];
		this.forceUpdate = forceUpdate;
		this.loading_invoice = false;
		this.active_list_tab = 0;
		this.invoices_cache_key = 'billing-invoices';
	}

	async getPaymentMethods() {
		this.loading = true;
		try {
			const methods = await this.session.get_list_all('/payment-methods');

			this.payment_methods = methods.items;
	  } catch (err) {
			this.validation_error(err);
	  }
	  this.loading = false;
	  return this.payment_methods;
	}

	async loadInvoices() {
		const cached_invoices = this.cachier.getItem(this.invoices_cache_key);
		if (cached_invoices) {
			this.invoices = cached_invoices;
		} else {
			this.loading = true;
			try {
				const invoices = await this.session.get_list_all(this.uri);
				this.cachier.setItem(this.invoices_cache_key, invoices.items);
				this.invoices = invoices.items;
			} catch (err) {
				this.validation_error(err);
			}
			this.loading = false;
		}
		return this.invoices;
	}

	async setStatus(id, status) {
	  this.loading = true;
	  try {
	    await this.session.patch_item(`/payment-methods/${id}`, {status});
	    this.payment_methods = await this.getPaymentMethods();
	  } catch (err) {
	    this.validation_error(err);
	  }
	  this.loading = false;
	  return true;
	}

	async showInvoice(inv) {
		this.active_list_tab = this.invoices.findIndex((x) => x.id === Number.parseInt(inv));
		if (this.loading_invoice) return false;
		this.loading_invoice = true;
		try {
			this.invoices = this.invoices.map((x) => {
				x.opened_tab = inv.id === x.id;
				return x;
			});
			if (inv && typeof inv !== 'object') {
				inv = await this.session.get_item(`/invoices/${inv}`);
			}
			const data = await this.session.get_list_all(`/invoices/${inv.id}/transactions`);
			this.invoice = new Invoice(inv, data.items);
		} catch (e) {
			this.validation_error(e, true);
		}
		this.loading_invoice = false;
		if (this.forceUpdate) this.forceUpdate();
		return this.invoice;
	}

	async exportPdf() {
		try {
			const filename = this.generateFileName();
			const exporter = new PDFExporter(
				'pdf-export',
				filename,
				filename,
				this.session,
			);
			await exporter.printWindowAsPDF();
		} catch (err) {
			this.alert = {
				message: err,
				level: 'error',
			};
			this.hide_alert(3);
			window.scrollTo(0, 0);
			return false;
		}

		return true;
	}

	generateFileName() {
		const title = `${l.t('app.invoice', 'Invoice')} #${this.invoice.data.id}`;
		if (this.invoice.data.status === 'closed') {
			return `${title} ${l.t('billing.closed-at', 'closed at')} ${Helpers.format_date(this.invoice.data.closed_at)}`;
		// eslint-disable-next-line no-else-return
		} else if (this.invoice.data.status === 'deleted') {
			return `${title} (${l.t('app.deleted', 'Deleted')})`;
		}

		return `${title} (${l.t('billing.active', 'Active')})`;
	}
}
