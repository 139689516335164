var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('IndexPage',{attrs:{"resource":_vm.schedule,"calss":"schedules list-page","data-test-id":"schedules-list-page"},scopedSlots:_vm._u([{key:"filters",fn:function(){return [_c('FormInput',{attrs:{"label":_vm.l.t('app.name', 'Name')}},[_c('w-text-field',{attrs:{"hide-details":"auto","data-test-id":"schedules-filters-name-input"},model:{value:(_vm.schedule.filters.name),callback:function ($$v) {_vm.$set(_vm.schedule.filters, "name", $$v)},expression:"schedule.filters.name"}})],1)]},proxy:true},{key:"list",fn:function(){return [_c('v-simple-table',{staticClass:"list-table",attrs:{"data-test-id":"schedules-table"}},[_c('thead',{attrs:{"data-test-id":"table-head"}},[_c('tr',{attrs:{"data-test-id":"head-row"}},[_c('th',{attrs:{"data-test-id":"checkbox"}}),_c('th',{attrs:{"data-test-id":"name"}},[_vm._v(_vm._s(_vm.l.t('app.name', 'Name')))]),_c('th',{attrs:{"data-test-id":"id"}},[_vm._v(_vm._s(_vm.l.t('app.ID', 'ID')))]),_c('th',{attrs:{"data-test-id":"more-options"}})])]),_c('tbody',{attrs:{"data-test-id":"table-body"}},_vm._l((_vm.schedule.items),function(item,i){return _c('tr',{key:item['id'],class:{'selected-row': item.selected},attrs:{"data-test-id":`schedules-item-${i}-row`}},[_c('td',{staticClass:"select-table-item",attrs:{"data-test-id":"checkbox"}},[_c('w-checkbox',{key:`schedules-item-${item.id}-${item.selected}`,attrs:{"hide-details":"auto","data-test-id":`schedules-item-${i}-checkbox`},on:{"click":function($event){return _vm.checked($event, item)}},model:{value:(item.selected),callback:function ($$v) {_vm.$set(item, "selected", $$v)},expression:"item.selected"}})],1),_c('td',{staticClass:"nowrap overflow-hidden",attrs:{"data-test-id":"link"}},[_c('IndexListItem',{key:item['id'],attrs:{"route":{
                  name: 'schedules.show',
                  params: {
                    id: item.id,
                    _schedule: item,
                  },
                },"item":item,"title":item.name,"data-test-id":`schedules-item-${i}-title`}})],1),_c('td',{staticClass:"w-100",attrs:{"data-test-id":`schedules-item-${i}-id`}},[_vm._v(" #"+_vm._s(item['id'])+" ")]),_c('td',{attrs:{"data-test-id":"more-options"}},[_c('MoreOptions',{attrs:{"options":_vm.more_options(item)}})],1)])}),0)])]},proxy:true}])}),_c('ConfirmDeletion',{attrs:{"value":!!_vm.deleting_item_id},on:{"closed":function($event){_vm.deleting_item_id = null},"confirmed":function($event){return _vm.schedule.delete_item(_vm.deleting_item_id)}}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }