<template>
    <div id="sidebar" class="do-not-print font-weight-medium">
      <v-toolbar
        color="white"
        class="hidden-md-and-up"
        fixed
        id="md-and-down-header"
        data-test-id="mobile-menu-header"
      >
        <v-app-bar-nav-icon
          class="hidden-md-and-up pt-0"
          @click.stop="mobile_menu_clicked"
          data-test-id="mobile-navigation-button"
        ></v-app-bar-nav-icon>
        <CsrWarning/>
        <v-spacer></v-spacer>
        <router-link :to="{name: 'homepage'}">
          <LogoIcon/>
        </router-link>
      </v-toolbar>
      <v-navigation-drawer
        app
        fixed
        v-model="drawer"
        :permanent="$vuetify.breakpoint.mdAndUp"
        :width="$vuetify.breakpoint.mdAndUp ? '226px' : undefined"
        id="main-navigation"
        left
        data-test-id="mobile-navigation"
      >
        <template v-if="$vuetify.breakpoint.smAndDown">
          <w-loader v-if="loading" class="account-loader" :message="false" size="small"/>
          <AccountBox
            v-if="!loading && $session.user && $session.user.account"
            v-bind:key="`${$session.user.account}${$session.user.account.id}`"
            @signOut="$emit('sign_out')"
            @language_changed="$emit('language_changed', $event)"
            data-test-id="sidebar-account-box"
          />
          <v-divider class="do-not-print"/>
        </template>
        <div class="navigation-container d-flex flex-column">
          <div
          id="top-navigation"
          data-test-id="sidebar-top-navigation"
        >
          <MainTab
            v-for="(category, name) in categories"
            :key="name"
            :data-test-id="`navigation-category-${name}`"
          >
            <CategoryTitle @click="toggleCategory(name)" :icon="category.icon" :title="category.title"/>
            <transition name="collapsable_menu">
              <div v-if="activeCategory === name">
                <InternalLink
                  v-for="(route, i) in category.routes"
                  :route="route"
                  :class="{'active-item': checkActiveTab(category, route)}"
                />
              </div>
            </transition>
          </MainTab>
          <MainTab
            v-for="route in single_routes"
            :route="route"
            @category_click="activeCategory = null"
            :class="{'active-item': checkActiveTab(null, route)}"
            :key="route.name"
          />
        </div>
        <div
          id="bottom-navigation"
          class="mt-auto"
        >
          <MainTab
            v-for="route in bottom_routes"
            :route="route"
            :class="{'active-item': checkActiveTab(null, route)}"
            @category_click="activeCategory = null"
            :key="route.name"
          />
          <ClassicOrNxt
            user="nxt"
            data-test-id="bottom-navigation-nxt-configure-link"
          >
          <MainTab  :href="configure_link" class="submenu-category-title" data-test-id="configure-link">
            <v-icon class="submenu-list-icon" color="text">$vuetify.icons.gear </v-icon>{{ l.t('app.configure', 'Configure') }}
            </MainTab>
          </ClassicOrNxt>
          <MainTab
            v-if="!['avo', 'avo_master'].includes($branding.data.code)"
            class="submenu-category-title"
            data-test-id="bottom-navigation-pdc-link"
            :href="my_pdc_link"
          >
            <v-icon class="submenu-list-icon" color="text"> $vuetify.icons.phone_com </v-icon> My.phone.com
          </MainTab>
          <HelpLinks />
          <div
            v-if="$branding.data.powered_by"
            class="powered-by submenu-category-title"
          >
            {{l.t('app.powered-by', 'Powered by')}}
            <v-icon size="16" color="#29921b">$vuetify.icons.phone_com</v-icon>&nbsp;
            <span class="pdc w-caption">Phone.com</span>
          </div>
        </div>
        </div>
      </v-navigation-drawer>
    </div>
</template>

<script>
  import l from '../../libs/lang';
  import SidebarRouter from '../../libs/sidebar-router';
  import LogoIcon from './LogoIcon.vue';
  import CsrWarning from './CsrWarning.vue';
  import MainTab from './sidebar/MainTab.vue';
  import ClassicOrNxt from './ClassicOrNxt.vue';
  import HelpLinks from './sidebar/HelpLinks.vue';
  import AccountBox from '../fragments/AccountBox.vue';
  import InternalLink from './sidebar/InternalLink.vue';
  import CategoryTitle from './sidebar/CategoryTitle.vue';

  export default {
    components: {
    MainTab,
    LogoIcon,
    HelpLinks,
    CsrWarning,
    AccountBox,
    ClassicOrNxt,
    InternalLink,
    CategoryTitle,
},
    data() {
      return {
        l,
        sidebar_router: new SidebarRouter(this.$session, this.$feature_toggle, this.$branding),
        drawer: null,
        mobile_btn_active: false,
        loading: false,
        hasTrunksEnabled: false,
        hasSubaccountsEnabled: false,
        activeCategory: null,
        categories: {},
        bottom_routes: [],
        single_routes: [],
      };
    },
    async created() {
      this.$data.loading = true;
      await this.get_routes();
      this.checkActiveCategory();
      window.addEventListener('resize', () => {
        if (this.$vuetify.breakpoint.smAndDown && !this.$data.mobile_btn_active) {
          this.$data.drawer = false;
        }
        if (!this.$vuetify.breakpoint.smAndDown) {
          this.$data.drawer = true;
          this.$data.mobile_btn_active = false;
        }
      });
      this.$data.loading = false;
      this.$forceUpdate();
    },
    methods: {
      async get_routes() {
        try {
          const {categories, single_routes, bottom_routes} = await this.$data.sidebar_router.get_routes();
          this.$emit('account', this.$session.user.account);
          this.$data.categories = categories;
          this.$data.single_routes = single_routes;
          this.$data.bottom_routes = bottom_routes;
        } catch (err) {
          console.log('Error while loading routes', err);
        }
      },
      mobile_menu_clicked() {
        this.$data.drawer = !this.$data.drawer;
        this.$data.mobile_btn_active = !this.$data.mobile_btn_active;
      },
      sign_out() {
        this.$session.sign_out();
      },
      checkActiveCategory() {
        if (!this.$route || (!this.$route && !this.$route.name)) return null;
        if (this.$route.name === 'not-found') return null;
        const currentRouteName = this.$route.name.split('.')[0];
        for (const c in this.$data.categories) {
          if (this.$data.categories[c]) {
            if (this.$data.categories[c].submenusRoutes && this.$data.categories[c].submenusRoutes.length) {
              if (this.$data.categories[c].submenusRoutes.find((x) => x.startsWith(currentRouteName))) {
                this.$data.activeCategory = c;
                return this.$forceUpdate();
              }
            }
            const routes = this.$data.categories[c].routes.map((x) => x.name);
            if (routes.some((x) => x.startsWith(currentRouteName))) {
              this.$data.activeCategory = c;
              return this.$forceUpdate();
            }
          }
        }
        if (currentRouteName.startsWith('groups')) {
          this.$data.activeCategory = 'inbox';
          return this.$forceUpdate();
        }

        return true;
      },
      checkActiveTab(category, route) {
        const currentRouteName = this.$route.name;
        const routeGroup = `${currentRouteName.split('.')[0]}`;
        if (route.params && this.$route.params) {
          if (Object.keys(route.params).every((x) => route.params[x] === this.$route.params[x])) {
            return true;
          }

          return false;
        }
        if (route.name.startsWith(routeGroup)) return true;
        if (currentRouteName.startsWith('groups') && route.name === 'contacts.index') return true;
        return false;
      },
      toggleCategory(name) {
        if (this.$data.activeCategory === name) {
          this.$data.activeCategory = null;
        } else {
          this.$data.activeCategory = name;
        }
        this.$forceUpdate();
      }
    },
    computed: {
      configure_link() {
        const url = `https://${this.is_preprod ? 'preprod.' : ''}configure.phone.com`;
        const token = this.$session.user.token.slice(7);
        return `${url}/#access_token=${token}&token_type=Bearer`;
      },
      my_pdc_link() {
        const url = `https://${this.is_preprod ? 'preprod.' : ''}my.phone.com`;
        const token = this.$session.user.token.slice(7);
        return `${url}/#access_token=${token}&token_type=Bearer`;
      },
      is_preprod() {
        return process.env.VUE_APP_STAGE === 'preprod';
      },
    }
  };
</script>

<style lang="scss">
  @import "./../../assets/styles/scss/vars.scss";
  #sidebar {
    #main-navigation {
      background: $frame-background !important;
      letter-spacing: 0.1px;
      .v-navigation-drawer__content {
        overflow: hidden !important;
      }
      .v-navigation-drawer__content:hover {
        overflow: auto !important;
      }
      $main-tab-left-margin: 20px;
      $background: rgba(133, 146, 154, 0.2);
      $active-background: var(--v-secondary-lighten1);
      $transition-time: 0.3s;
      .w-caption {
        letter-spacing: 0px;
      }
      .main-tab {
        padding-left: $main-tab-left-margin;
        cursor: pointer;
        .sidebar-link:first-child {
          margin-top: -6px;
        }
      }
      .submenu-list-icon {
        margin-right: 13px;
      }
      .active-item {
        background: $active-background;
        color: var(--v-text-base) !important;
        border-radius: 0;
      }
      .sidebar-link {
        cursor: pointer;
        margin-left: -$main-tab-left-margin;
        padding-left: $main-tab-left-margin + 35px;
        padding-top: 6px;
        padding-bottom: 6px;
      }
      .sidebar-link:hover:not(.router-link-active), .submenu-category-title:hover {
        background: $background;
      }
      .submenu-category-title {
        padding: 6px 12px;
        margin-left: -$main-tab-left-margin;
        padding-left: $main-tab-left-margin;
        padding-top: 14px;
        padding-bottom: 14px;
      }
      .powered-by {
        font-weight: 500;
        font-size: 10px;
        line-height: 16px;
        display: flex;
        justify-content: center;
        padding-top: 16px;
        padding-bottom: 20px;
        border-top: 1px solid rgba(133, 146, 154, 0.25);
        .v-icon {
          margin-left: 4px;
        }
        .pdc {
          color: #29921b;
        }
        &:hover {
          background: none;
        }
      }
      #bottom-navigation {
        .submenu-category-title {
          margin-left: 0;
        }
      }
    }
  }
</style>
