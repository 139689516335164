<template>
	<div v-if="devices && devices.length" class="mt-3 step-1">
	  <div id="type-filters">
	    <div class="d-md-flex justify-start">
	      <div class="w-body-2 mt-4 px-2">
	        {{ l.t('devices.which-devices-suit-you', 'Which devices suit you best?') }} <!-- TODO grey line -->
	      </div>
	      <div class="text-field mx-2">
			<w-select
				v-model="type"
				:items="types"
				id="phone-type-select"
				class="bg-white"
				hide-details="auto"
				data-test-id="buy-device-which-suits-you-select"
			/>
		  </div>
		  <v-spacer></v-spacer>
		  <div ref="cart_container" class="text--text text--lighten-1 cart-container" :class="{ 'mt-3': $vuetify.breakpoint.smAndDown}">
			<div @click="toggle_cart_modal" class="d-flex justify-center" :class="{'cursor-pointer': current_cart.length}">
				<div class="cart-with-badge">
					<transition name="slide-fade-right-left">
						<span v-if="current_cart.length" id="cart-items-number">{{ current_cart.length }}</span>
					</transition>
					<v-icon size="30" color="#29921B">$vuetify.icons.cart</v-icon>
				</div>
				<div>
					<span class="w-body-2 nowrap text--text ml-3">{{l.t('devices.users-available', 'Users available')}}:&nbsp;</span>
					<span class="text--text display-available-users mr-8">{{ available_users_number() }}</span>
					<div class="text--text text--lighten-1 ml-3 cursor-pointer">
						<w-tooltip :activator="tooltip_activator" class="cursor-pointer tooltip" top :max-width="250">
							<template v-slot:activator class="pb-5 mt-n5">
								{{ l.t('app.what-is-this', 'What is this?') }}
							</template>
							{{ l.t('devices.available-devices-infotip', 'You can only purchase new phones if you have available users or extensions. Available users must be on a Pro or Plus plan and not have any phones assigned. Available extensions should be device enabled extensions.')}}
						</w-tooltip>
				</div>
			</div>
			</div>
		  </div>
	    </div>
	  </div>
	  <InfoAlert :value="!available_users_number()" :closable="false">
		{{l.t('devices.to-buy-additional-devices', 'To buy additional devices, please add more users or extensions to your account. You can then purchase and assign the device you’re interested in here.')}}
	  </InfoAlert>
	  <div class="products-container" :class="{'justify-space-between' : (!type || filtered_devices >= 3)}">
		<div
			v-for="(device, i) of devices"
			v-if="!type || type === device.type"
			class="order-product"
			:class="{'mx-4' : (type && filtered_devices < 3)}"
		>
			<transition name="slide-fade" mode="out-in">
				<div>
					<v-img
						:src="`https://control.phone.com/images/lang/icon_voip_device_${device.code}.jpg`" alt=""
						height="120"
						contain
						:data-test-id="`buy-device-item-${i}-image`"
					/>
					<div
						class="w-subtitle-1 info--text text--darken-1 mt-5"
						:data-test-id="`buy-device-item-${i}-title`"
					>
						{{generate_device_name(device)}}
					</div>
					<span
						class="w-subtitle-1"
						:data-test-id="`buy-device-item-${i}-price`"
					>
						${{ device.price.price | price }}
					</span>
					<p v-if="device.description" class="product-description w-caption">{{ device.description }}</p>
					<w-btn
						@click="show_details(device)"
						text
						changes-form
						:color="'primary'"
						class="product-details-btn"
					>
					{{ l.t("call-flow.see-details", "See details") }} <v-icon size="16" color="primary">$vuetify.icons.chevron_forward</v-icon>
					</w-btn>
					<div class="d-flex mx-auto bottom-controls">
						<w-btn
							@click="substract_device(device)"
							:disabled="!current_cart.filter((x) => x.id === device.id).length"
							color="primary"
							class="my-auto"
							icon
						>
							<v-icon :data-test-id="`buy-device-item-${i}-minus`">mdi-minus-circle</v-icon>
						</w-btn>
						<w-text-field
							disabled
							:value="current_cart.filter((x) => x.id === device.id).length"
							@input="check_items_number(device, $event)"
							type="number"
							class="devices-quanity-input centered-input hide-arrows small-input"
							hide-details="auto"
							:key="input_key"
							:data-test-id="`buy-device-item-${i}-quantity-input`"
						/>
						<w-btn
							@click="add_device(device)"
							:disabled="!available_users_number()"
							color="primary"
							class="my-auto"
							icon
						>
							<v-icon :data-test-id="`buy-device-item-${i}-plus`">
							mdi-plus-circle
							</v-icon>
						</w-btn>
						</div>
				</div>
			</transition>
		</div>
	  </div>
	  <StepperButtons
	    @continue="submitted"
		:disable_continue="!current_cart.length"
	    :no_back="true"
	  />
	  <DefaultModal v-if="show_details_modal" v-model="show_details_modal" @close="show_cart_modal = false" max-width="450">
		<div class="cart-modal-content feature-container">
			<v-row class="item_direction">
			<v-col md="5" lg="5">
				<v-img
	              :src="`https://control.phone.com/images/lang/icon_voip_device_${this.current_device.code}.jpg`" alt=""
	              height="120"
	              contain
	            />
			</v-col>
			<v-col md="7" lg="7">
				<div
					class="w-subtitle-1 my-3 info--text text--darken-1"
				>
	              {{generate_device_name(current_device)}}
				</div>
				<span class="w-subtitle-1 font-weight-bold">${{ current_device.price.price | price }}</span>
				<div class="device-features">
					<p class="pb-0 caption" v-if="this.current_device.description">{{ this.current_device.description }}</p>
					<p class="mb-1 caption" v-for="feature in device_features">{{ feature.name }} <span class="w-caption text--text text--lighten-1">{{ feature.value }}</span></p>
					<a
						v-if="current_device.features.user_guide_link"
						:href="current_device.features.user_guide_link"
						:color="'primary'"
						target="_blank"
						class="w-subtitle-3 pl-0"
					>
					    {{ l.t("call-view-user-guide", "View user guide") }} <v-icon size="16" color="primary">$vuetify.icons.chevron_forward</v-icon>
                    </a>
				</div>
			</v-col>
		</v-row>
		</div>
	  </DefaultModal>
	  <DefaultModal v-model="show_cart_modal" @close="show_cart_modal = false" max-width="550">
		<div class="cart-modal-content">
			<div class="w-h5 info--text text--darken-1 cart-modal-title">{{l.t('app.cart', 'Cart')}}</div>
			<div v-for="code in Object.keys(current_cart_object)" class="cart-modal-item d-flex" v-if="current_cart_object[code].quantity">
				<v-img
	                :src="`https://control.phone.com/images/lang/icon_voip_device_${current_cart_object[code].device.code}.jpg`" alt=""
	                max-width="65"
	                contain
					class="cart-modal-item-img"
	                :data-test-id="`buy-device-cart-modal-item-${code}-image`"
	              />
				  <div>
					<div class="w-body-1">{{ generate_device_name(current_cart_object[code].device) }}</div>
					<div class="w-body-2">{{l.t('app.qty', 'Qty')}}: {{current_cart_object[code].quantity}}</div>
				  </div>
				  <v-spacer/>
				  <div class="cart-modal-prices">
					<div class="w-subtitle-2 mb-1">${{ current_cart_object[code].device.price.price * current_cart_object[code].quantity | price }}</div>
					<div class="text--text text--lighten-1">(${{ current_cart_object[code].device.price.price | price }} {{l.t('app.each-lowercase', 'each')}})</div>
				  </div>
			</div>
			<div class="cart-modal-total">
				{{l.t('devices.sub-total', 'Sub-total (#{} devices)', [current_cart.length])}}: (${{total_price() | price}})
			</div>
			<div class="cart-modal-buttons">
				<w-btn @click="show_cart_modal=false" text color="primary" class="px-0">
					{{l.t('app.continue-choosing-your-phones', 'Continue choosing your phones')}}
				</w-btn>
				<w-btn @click="submitted" color="primary">
					{{l.t('devices.set-up-your-phones', 'Set up your phones')}}
					<v-icon size="18">$vuetify.icons.chevron_forward</v-icon>
				</w-btn>
			</div>
		</div>
	  </DefaultModal>
	</div>
</template>

<script>
import l from '../../../../libs/lang';
import InfoLink from '../../../elements/InfoLink.vue';
import InfoAlert from '../../../elements/InfoAlert.vue';
import StepperButtons from '../../../elements/StepperButtons.vue';
import DefaultModal from '../../../elements/modal/DefaultModal.vue';

export default {
	props: {
		types: {
			required: true,
			type: Array,
		},
		devices: {
			required: true,
			type: Array,
		},
		_cart: {
			required: true,
			type: Array,
		},
		available_users: {
			required: true,
			type: Number,
		}
	},
	components: {
		InfoLink,
		InfoAlert,
		DefaultModal,
		StepperButtons,
	},
	data() {
		return {
			l,
			type: null,
			current_cart: [],
			show_cart_modal: false,
			debouncer: null,
			input_key: Date.now(),
			show_details_modal: false,
			current_device: null,
			tooltip_activator: null
		};
	},
	mounted() {
		this.tooltip_activator = this.$refs.cart_container;
	},
	created() {
		this.$data.current_cart = this.$props._cart || [];
	},
	methods: {
		show_details(device) {
			this.current_device = device;
			this.show_details_modal = true;
		},
		toggle_cart_modal() {
			if (this.$data.current_cart && this.$data.current_cart.length) {
				this.$data.show_cart_modal = true;
			}
		},
		total_price() {
			return this.$data.current_cart.reduce((a, c) => {
				a += c.price.price;
				return a;
			}, 0);
		},
		generate_device_name(device) {
			return `${device.manufacturer} ${device.name}`;
		},
		substract_device(device) {
			this.$emit('removed', device);
		},
		add_device(device) {
			this.$emit('added', device);
		},
		absolute_integer(num) {
			return Math.abs(num ? Number.parseInt(num) : 0);
		},
		check_items_number(device, num) {
			if (this.$data.debouncer) {
				clearTimeout(this.$data.debouncer);
			}
			this.$data.debouncer = setTimeout(() => {
				let entered_number = this.absolute_integer(num);
				const devices = this.$data.current_cart.filter((x) => x.id === device.id);
				if (devices.length > entered_number) {
					for (let i = 1; i <= (devices.length - entered_number); i++) {
						this.substract_device(device);
					}
				} else if (devices.length < entered_number) {
					entered_number -= devices.length;
					const available_users = this.$props.available_users - this.$data.current_cart.length;
					if (available_users > 0) {
						if (entered_number > available_users) {
							entered_number = available_users;
						}
						if (entered_number > 0) {
							for (let i = 1; i <= entered_number; i++) {
								this.add_device(device);
							}
						}
					}
				}
				this.$data.input_key = Date.now();
				this.$forceUpdate();
			}, 500);
		},
		submitted() {
			this.$emit('submitted', this.$data.current_cart);
		},
		available_users_number() {
			const available_users = this.$props.available_users - this.$data.current_cart.length;
			return available_users < 0 ? 0 : available_users;
		},
		cart_items() {
			const items = this.$data.current_cart.length;
			return items;
		},
	},
	computed: {
		current_cart_object() {
			const obj = {};
			for (const item of this.$data.current_cart) {
				if (!obj[item.id]) {
					obj[item.id] = {
						quantity: 0,
						device: item,
					};
				}
				obj[item.id].quantity++;
			}
			return obj;
		},
		filtered_devices() {
            return this.devices.filter((x) => x.type === this.type).length;
        },
		device_features() {
            const filtered_features = [];
            const features = [
                {name: l.t('devices.supported-until', 'Supported Until: '), value: this.current_device.features.supported_until},
                {name: l.t('devices.line-key-count', 'Line Key Count: '), value: this.current_device.features.line_key_count},
                {name: l.t('devices.display-size', 'Display Size: '), value: `${this.current_device.features.display_size} ${l.t('devices.inches', ' inches')}`},
                {name: l.t('devices.backlit-display', 'Backlit Display: '), value: this.current_device.features.display_backlit},
                {name: l.t('devices.color-display', 'Color Display: '), value: this.current_device.features.display_color},
                {name: l.t('devices.touchscreen-display', 'Touchscreen Display: '), value: this.current_device.features.display_touchscreen},
                {name: l.t('devices.concurrent-call-limit', 'Concurrent Call Limit: '), value: this.current_device.features.sip_concurrent_calls},
                {name: l.t('devices.supports-video-calls', 'Supports Video Calls: '), value: this.current_device.features.supports_video_calls},
                {name: l.t('devices.ethernet', 'Ethernet: '), value: this.current_device.features.connections_ethernet},
                {name: l.t('devices.dect', 'Power over Ethernet (PoE): '), value: this.current_device.features.connections_poe},
                {name: l.t('devices.wi-fi', 'Wi-Fi: '), value: this.current_device.features.connections_wifi},
                {name: l.t('devices.bluetooth', 'Bluetooth: '), value: this.current_device.features.connections_bluetooth},
                {name: l.t('devices.dect', 'DECT: '), value: this.current_device.features.connections_dect},
                {name: l.t('devices.usb', 'USB: '), value: this.current_device.features.connections_usb},
                {name: l.t('devices.headset-connections', 'Headset Connections: '), value: this.current_device.features.connections_headset},
                {name: l.t('devices.dect-handsets-supported', 'DECT Handsets Supported: '), value: this.current_device.features.supports_dect_handsets},
                {name: l.t('devices.conference-microphone-range', 'Conference Microphone Range: '), value: this.current_device.features.conf_mic_range},
                {name: l.t('devices.recommended-participants', 'Recommended Participants: '), value: this.current_device.features.conf_participants},
                {name: l.t('devices.daisy-chain-support', 'Daisy Chain Support: '), value: this.current_device.features.conf_daisy_chain},
                {name: l.t('devices.expansion-microphones', 'Expansion Microphones: '), value: this.current_device.features.conf_exp_mics},
                {name: l.t('devices.hearing-aid-compatibility', 'Hearing Aid Compatibility (HAC): '), value: this.current_device.features.hearing_aid_compatible},
            ];
            for (const item of features) {
                if (item.value !== null) {
                    if (item.value === true) item.value = l.t('app.yes', 'Yes');
                    if (item.value === false) item.value = l.t('app.no', 'No');
                    filtered_features.push(item);
                }
            }
            return filtered_features;
        }
	},
	filters: {
		price(val) {
			return (val / 100).toFixed(2);
		},
	},
};
</script>

<style scoped lang="scss">
.cart-container {
	width: 140px;
	.cart-with-badge {
		position: relative;
		#cart-items-number {
			position: absolute;
			right: -6px;
			top: -5px;
			background: var(--v-primary-darken1);
			border-radius: 50%;
			color: #FFF;
			height: 20px;
			min-width: 20px;
			font-weight: 600;
			font-size: 10px;
			line-height: 20px;
			letter-spacing: -0.1 px;
			text-align: center;
			z-index: 1;
		}
	}
	.infolink-devices {
		margin-top: -5px !important;
	}
	.display-available-users {
		width: 10px;
		margin-right: 3px;
	}
}
.buy-device-no-extensions-alert {
	padding: 15px;
	max-width: 600px;
	margin: 14px auto 20px auto;
	.v-icon {
		margin-right: 6px;
	}
}
.text-field {
	max-width:250px !important;
	width: 250px !important;
}
.infotip-text {
	font-weight: 500;
	font-size: 10px;
	line-height: 14px;
}
.phone-card {
	width: 322px !important;
	height: 386px !important;
	box-shadow: 0px 4px 18px 0px rgba(0, 0, 0, 0.2) !important;
	border-radius: 10px !important;
}
.feature-container {
    height: 363px !important;
    overflow-x: hidden !important;
    overflow-y: auto;
    direction: rtl;
}
.item_direction {
    direction:ltr;
}
</style>
