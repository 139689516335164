<template>
    <div class="faxes">
      <Submenu
        :routes="{
          'fax.index': {
            title: l.t('fax.inbox', 'Fax inbox')
          },
          'fax.create': {
            title: l.t('fax.send', 'Send fax')
          }
        }"
        :key="`${l.detect()}${$route.name}`"
      />
      <FaxesFragment
        v-if="$route.name === 'fax.index'"
        @deletingStarted="deleting_in_progress = true"
        @deletingEnded="deleting_in_progress = false"
        data-test-id="faxes-index"
      />
      <FaxesCreateFragment
        v-if="$route.name === 'fax.create'"
        data-test-id="faxes-create"
      />
    </div>
</template>

<script>
  import l from '../../../libs/lang';
  import FaxesFragment from './index.vue';
  import FaxesCreateFragment from './send.vue';
  import Submenu from '../../elements/Submenu.vue';

  export default {
    components: {
      Submenu,
      FaxesFragment,
      FaxesCreateFragment,
    },
    data() {
      return {
        l,
        deleting_in_progress: false,
      };
    },
    beforeRouteLeave(to, from, next) {
      if (window.deleting_in_progress) {
        this.$emit('userRedirected', to, from, next);
      } else {
        next();
      }
    },
  };
</script>
