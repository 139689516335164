<template>
<div>
  <div class="mt-5 text-justify lr-help-text mx-auto">
      <HelpText class="py-0">
        {{ l.t('lr.setting-up-receptionist-services-intelligent-answer-bot', 'Setting up receptionist services is a two-step process. First, you will create a script that will tell your Live{} how to handle your calls. Next, you will add your script to the call-handling rules for any of your phone numbers, extensions, or menus.', [!['avo', 'avo_master'].includes($branding.data.code) ? l.t('lr.or-virtual-with-leading-space', ' or the Intelligent Answer Bot') : ''])}}
      </HelpText>
  </div>
  <DynamicFilters class="mt-10">
    <w-text-field
      v-model="la.filters.name"
      prepend-inner-icon="$vuetify.icons.filter"
      :placeholder="l.t('app.filter-by-name', 'Filter by name')"
      @input="la.apply_dynamic_filters()"
    />
  </DynamicFilters>
  <IndexPage
    :resource="la"
    :disable_filters="true"
    :disable_mass_operations="true"
    class="live-answer list-page"
  >
    <template v-slot:list>
      <v-simple-table class="list-table" data-test-id="lr-table">
        <thead data-test-id="table-head">
          <tr data-test-id="head-row">
           <th class="px-1" data-test-id="id">{{ l.t('app.id', 'ID') }}</th>
           <th data-test-id="name">{{ l.t('app.name', 'Name') }}</th>
           <th data-test-id="type">{{ l.t('live-answer.service-type', 'Service type') }}</th>
           <th data-test-id="plan">{{ l.t('live-answer.plan', 'Plan') }}</th>
           <th data-test-id="appointments">{{ l.t('live-answer.appointments', 'Appointments') }}</th>
           <th data-test-id="greting">{{ l.t('app.greeting', 'Greeting') }}</th>
           <th data-test-id="status">{{ l.t('app.status', 'Status') }}</th>
           <th data-test-id="more-options"></th>
          </tr>
        </thead>
        <tbody data-test-id="table-body">
          <tr
          v-for="(item, i) of la.items"
          :key="`${item.id}-${Number(item.enabled)}`"
          :class="{ 'selected-row grey--text': !item.enabled }"
          :data-test-id="item.id"
          >
          <template v-if="la.deleting_item !== item.id">
            <td class="px-1" :data-test-id="`lr-item-${item.id}-id-link`">
              <w-tooltip top small>
                <template v-slot:activator>
                  {{ item.id }}
                </template>
                {{ l.t('app.popup-id', 'Pop up ID') }}: {{ item.popup_id }}
              </w-tooltip>
            </td>
            <router-link
              tag="td"
              :to="{ name: 'live-answer.show', params: {id: item.id, _item: item } }"
              class="list-link"
              :data-test-id="`lr-item-${item.id}-name-link`"
            >
             {{ item.name }}
            </router-link>
            <td
              :data-test-id="`lr-item-${item.id}-vendor-link`"
            >
             {{ item.vendor ? getServiceName(item.vendor) : '—' }}
            </td>
            <td
              :data-test-id="`lr-item-${item.id}-code-link`"
            >
             {{ translate_code(item.code) }}
            </td>
            <td
              :data-test-id="`lr-item-${item.id}-app-link`"
            >
             {{ item.appointments_enabled ? l.t('app.yes', 'Yes') : l.t('app.no', 'No') }}
            </td>
            <td
              :data-test-id="`lr-item-${item.id}-greeting-link`"
            >
             {{ item.greeting || '—' }}
            </td>
            <td
              :data-test-id="`lr-item-${item.id}-status-link`"
            >
            <v-icon size="60" :color="item.enabled ? 'info base' : 'error lighten1'">
              {{item.enabled ? '$vuetify.icons.enabled' : '$vuetify.icons.disabled'}}
            </v-icon>
            </td>
            <td :key="item['id']" class="minimal-width" :data-test-id="`lr-item-${item.id}-reactivate`">
              <MoreOptions :options="more_options(item)"/>
              <EnableScriptModal
                v-if="confirm_enable_script === item.id"
                :vendor_id="item.vendor.id"
                @close="confirm_enable_script = false"
                @confirmed="reactivate(item['id'])"
                :key="`enable-${item['id']}`"
              />
              <DisableScriptModal
                v-if="confirm_disable_script === item.id"
                @close="confirm_disable_script = false"
                @confirmed="deactivate(item['id'])"
                :key="`disable-${item['id']}`"
              />
            </td>
          </template>
          <td v-else colspan="8" data-test-id="laoder">
            <w-loader size="small"/>
          </td>
          </tr>
        </tbody>
      </v-simple-table>
       <NxtRedirectionModal v-model="show_nxt_redirection_modal"/>
    </template>
  </IndexPage>
  <ConfirmDeletion
      :value="!!deleting_item_id"
      @closed="deleting_item_id = null"
      @confirmed="la.delete_item(deleting_item_id)"
    />
  </div>
</template>

<script>
  import l from '../../../libs/lang';
  import Helpers from '../../../libs/helpers';
  import Resource from '../../../models/Resource';
  import HelpText from '../../elements/HelpText.vue';
  import LiveAnswer from '../../../models/LiveAnswer';
  import IndexPage from '../../elements/IndexPage.vue';
  import MoreOptions from '../../elements/MoreOptions.vue';
  import FormInput from '../../elements/form/FormInput.vue';
  import DynamicFilters from '../../elements/DynamicFilters.vue';
  import CopyToClipboard from '../../elements/CopyToClipboard.vue';
  import ConfirmDeletion from '../../elements/modal/ConfirmDeletion.vue';
  import ConfirmationModal from '../../elements/modal/ConfirmationModal.vue';
  import EnableScriptModal from '../../fragments/live-answer/EnableScriptModal.vue';
  import DisableScriptModal from '../../fragments/live-answer/DisableScriptModal.vue';
  import NxtRedirectionModal from '../../fragments/live-answer/NxtRedirectionModal.vue';

  export default {
    components: {
      HelpText,
      FormInput,
      IndexPage,
      MoreOptions,
      DynamicFilters,
      ConfirmDeletion,
      CopyToClipboard,
      ConfirmationModal,
      EnableScriptModal,
      DisableScriptModal,
      NxtRedirectionModal,
    },
    data() {
      return {
        l,
        la: new LiveAnswer(this.$session, Helpers.emitter(this), Helpers.changeRoute(this)),
        deleting_item_id: null,
        confirm_enable_script: false,
        confirm_disable_script: false,
        show_nxt_redirection_modal: false,
        user_can_delete_script: false,
      };
    },
    async created() {
      this.$data.user_can_delete_script = await this.$feature_toggle.isFeatureEnabled('console.delete_receptionist_script');
    },
    async mounted() {
      if (
        this.$route.params
        && this.$route.params.redirection_after_creation
        && await Helpers.is_nxt(this.$session)
      ) {
        this.$data.show_nxt_redirection_modal = true;
      }
      this.$parent.$on('userRedirected', (to, from, next) => {
        this.$data.la.handleUserRedirect(to, from, next);
      });
    },
    methods: {
      more_options(item) {
        const options = [
          {
            title: l.t('lr.edit-script', 'Edit script'),
            cb: () => this.$router.push({ name: 'live-answer.show', params: {id: item.id, _item: item } })
          }
        ];
        if (!item.enabled) {
          options.push({
            cb: () => this.$data.confirm_enable_script = item.id,
            title: l.t('lr.enable-script', 'Enable script'),
          });
        } else {
          options.push({
            cb: () => this.$data.confirm_disable_script = item.id,
            title: l.t('lr.disable-script', 'Disable script'),
          });
        }
        if (this.$data.user_can_delete_script) {
          options.push({
            cb: () => this.$data.deleting_item_id = item.id,
            title: l.t('lr.delete-script', 'Delete script'),
          });
        }
        return options;
      },
      getServiceName(item) {
        return Resource.getServiceTypeName(item.id);
      },
      async reactivate(id) {
        this.$data.confirm_enable_script = false;
        await this.$data.la.reactivate(id);
        if (this.$data.la.alert.level === 'success') {
          const index = this.$data.la.items.findIndex((x) => x.id === id);
          if (index > -1) this.$data.la.items[index].enabled = true;
        }
      },
      async deactivate(id) {
        this.$data.confirm_disable_script = false;
        await this.$data.la.deactivate(id);
        if (this.$data.la.alert.level === 'success') {
          const index = this.$data.la.items.findIndex((x) => x.id === id);
          if (index > -1) this.$data.la.items[index].enabled = false;
        }
      },
      translate_code(code) {
        if (!code) return l.t('lr.no-plan', 'No plan');
        if (this.$data.la && this.$data.la.codes_translations[code]) {
          return this.$data.la.codes_translations[code].short;
        }
        if (code) return `${l.t('app.code', 'Code')} ${code}`;

        return '—';
      },
    },
  };
</script>
