<template>
	<div class="bar-wrapper" data-test-id="horizontal-bar">
		<div
			v-if="item.value"
			:style="{ background: color, width }"
			class="bar"
			@mouseover="showMetaTitle"
			@mouseleave="hideMetaTitle"
			@mousemove="mouseMoved"
		></div>
	</div>
</template>

<script>

export default {
	props: ['color', 'item', 'title', 'maxWidth', 'max'],
	methods: {
		showMetaTitle() {
			let details = document.getElementById('orange-charts-details');
			if (!details) {
				details = document.createElement('div');
				details.setAttribute('id', 'orange-charts-details');
				document.body.appendChild(details);
			}
			details.innerText = this.$props.title;
		},
		hideMetaTitle() {
			document.getElementById('orange-charts-details').innerText = '';
		},
		mouseMoved(e) {
			const details = document.getElementById('orange-charts-details');
			details.style.top = `${e.pageY}px`;
			details.style.left = `${e.pageX}px`;
		},
	},
	computed: {
		width() {
			if (!this.$props.item.display_value) return '0%';
			return `${(100 * this.$props.item.display_value) / this.$props.max}%`;
		},
	},
};
</script>

<style lang="scss" scoped>
@import "./../../assets/styles/scss/vars.scss";

.bar-wrapper {
	background: $bg;
	height: 2.2rem;
}

.bar {
	height: 100%;
	border-left: 0;
	transition: width 0.5s
}
</style>
