<template>
	<div class="billing">
		<Submenu
			:routes="{
				'billing.index': {
					title: l.t('billing.statements', 'Statements'),
					subroutes: ['billing.statement']
				},
				'billing.payment-methods': {
					title: l.t('billing.payment-methods', 'Payment methods'),
					subroutes: ['billing.payment-methods-create']
				},
			}"
			:key="`${l.detect()}${$route.name}`"
		/>

	<Invoices v-if="$route.name === 'billing.index' || $route.name === 'billing.statement'" :billing="billing" data-test-id="billing-invoices"></Invoices>
	<PaymentMethodsList
      v-if="$route.name === 'billing.payment-methods'"
      data-test-id="payment-methods-index"
    />
    <PaymentMethodsCreate
      v-if="$route.name === 'billing.payment-methods-create'"
      data-test-id="payment-methods-show"
    />
	</div>
</template>

<script>
import l from '../../../libs/lang';
import Helpers from '../../../libs/helpers';
import Billing from '../../../models/Billing';
import Invoices from './index.vue';
import Submenu from '../../elements/Submenu.vue';
import PaymentMethods from './payment-methods.vue';
import InvoiceFragment from '../../fragments/Invoice.vue';
import PaymentMethodsList from '../payment-methods/index.vue';
import PaymentMethodsCreate from '../payment-methods/create.vue';

export default {
	components: {
		Submenu,
		PaymentMethodsList,
		PaymentMethodsCreate,
		Invoices,
		PaymentMethods,
		InvoiceFragment,
	},
	data() {
		return {
			l,
			billing: new Billing(this.$session, Helpers.emitter(this), Helpers.changeRoute(this), () => this.$forceUpdate()),
		};
	},
};
</script>
