<template>
	<div class="api-requests">
		<Submenu
		  :routes="{
			'api-requests.create': {
				title: l.t('app.api-requests', 'API requests')
			},
			'api-requests.index': {
				title: l.t('api-requests.scheduled', 'Scheduled requests'),
				subroutes: ['api-requests.show']
			},
		  }"
		  :key="`${l.detect()}${$route.name}`"
		/>
		<ApiRequests v-if="$route.name === 'api-requests.create'" :api="api" data-test-id="api-req-index"/>
		<ScheduledRequests v-if="$route.name === 'api-requests.index'" :api="api" data-test-id="api-req-scheduled-reqs"/>
		<ShowRequest v-if="$route.name === 'api-requests.show'" :api="api" data-test-id="api-req-show"/>
	</div>
</template>
<script>
import l from '../../../libs/lang';
import Helpers from '../../../libs/helpers';
import ApiRequest from '../../../models/ApiRequest';
import ShowRequest from './show.vue';
import ApiRequests from './create.vue';
import ScheduledRequests from './index.vue';
import Submenu from '../../elements/Submenu.vue';

export default {
	components: {
		Submenu,
		ApiRequests,
		ShowRequest,
		ScheduledRequests,
	},
	data() {
		return {
			l,
			api: new ApiRequest(this.$session, Helpers.emitter(this), Helpers.changeRoute(this))
		};
	},
	beforeRouteLeave(to, from, next) {
	  if (window.deleting_in_progress) {
	    this.$emit('userRedirected', to, from, next);
	  } else {
	    next();
	  }
	},
};
</script>
