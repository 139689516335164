<template>
	<div v-if="address" class="w-body-1" data-test-id="address-component">
		<div>{{ address.line_1 }}</div>
		<div v-if="address.line_2">{{ address.line_2 }}</div>
		<div>{{ address.city }}, {{ address.province }}, {{ address.postal_code }}</div>
		<div>{{ country_name }}</div>
	</div>
</template>

<script>
import Helpers from '../../libs/helpers';

	export default {
		props: ['_address'],
		data() {
			return {
				address: null,
				country_name: null,
			};
		},
		created() {
			this.$data.address = this.$props._address;
			this.country_name = Helpers.country_name(this.address.country);
		}
	};
</script>
