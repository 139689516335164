<template>
  <div>
    <w-textarea
        v-if="!is_onvego"
        v-bind="$attrs"
        v-on="$listeners"
    />
    <div v-else-if="has_onvego_apointments" ref="onvego_iframe_container" :id="onvego_iframe_container_id"></div>
  </div>
</template>
<script>
  export default {
    props: {
        is_onvego: {
            type: Boolean,
            default: false,
        },
        item: {
            type: Object,
            required: true,
        }
    },
    data() {
      return {
        onvego_iframe_container_id: 'onvego-calendar',
        has_onvego_apointments: false,
        calendar_initiated: false,
        onvego_id: null,
      };
    },
    async created() {
        this.$data.has_onvego_apointments = await this.$feature_toggle.isFeatureEnabled('console.show_onvego_appointments');
    },
    mounted() {
        this.show_onvego_calendar_if_needed();
        window.addEventListener('message', (data) => {
            try {
                if (data.data) {
                    let json;
                    try {
                        json = JSON.parse(data.data);
                    } catch (e) {
                        json = data.data;
                    }
                    if (json.action && ['appointments-loaded', 'appointments-update'].includes(json.action)) {
                        if (json.action === 'appointments-update') this.$emit('change');
                        let height = 0;
                        if (json.appointments // default appointments, fired when user opens calendar
                            && json.appointments.length === 1
                            && json.appointments.every((x) => !x.appointmentType && !x.closingMessage && x.duration === 30)
                        ) {
                            height = 270;
                        } else {
                            const single_appointment_height = 50;
                            const default_elements_height = 60 + 16;
                            height = default_elements_height + json.appointments.length * single_appointment_height + (json.appointments.length + 1) * 34;
                        }
                        if (this.$refs.onvego_iframe_container) {
                            this.$refs.onvego_iframe_container.style.height = `${height}px`;
                        }
                    }
                    if (json.action && json.action === 'get-onvego-id') {
                        this.$data.onvego_id = json.onvegoId;
                    }
                }
            } catch (err) {
                console.log(err);
            }
        });
    },
    methods: {
        show_onvego_calendar_if_needed() {
            if (
                !this.$data.calendar_initiated
                && this.$props.is_onvego
            ) {
                setTimeout(() => {
                    if (this.$refs.onvego_iframe_container) {
                        window.buildAppointmentsPanel(this.$refs.onvego_iframe_container, this.$props.item.id, this.$data.onvego_id);
                    }
                }, 1);
                this.$data.calendar_initiated = true;
            }
        },
    }
  };
</script>
<style scoped lang="scss">
#onvego-calendar{
    height: 270px;
}
</style>
