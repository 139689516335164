<template>
    <div class="show-page" data-test-id="phone-numbers-show-page">
      <Submenu  v-if="($route.name !== 'phone-numbers.visualizer' && $route.name !== 'extensions.visualizer')"
        :routes="{
          'phone-numbers.show': {
            title: l.t('app.settings', 'Settings')
            },
          'phone-numbers.call-flow': {
            title: l.t('app.call-handling', 'Call handling')
            },
          'phone-numbers.make-call': {
            title: l.t('make-call.make-a-call', 'Make a call'),
            subroutes: ['phone-numbers.wizard']
            }
        }"
        :key="`${l.detect()}${$route.name}`"
      />
      <w-loader v-if="phone.loading"/>
      <div v-else-if="phone.item">
        <PageTitle v-if="title" class="nowrap">
          {{title}}
        </PageTitle>
        <w-alert
          v-if="phone.alert"
          :level="phone.alert.level"
          :message="phone.alert.message"
          :closable="phone.enable_close_alert"
          @closed="phone.alert = null"
          class="mb-6"
        />
        <div>
          <CallHandlingRules
              v-if="($route.name === 'phone-numbers.visualizer' || $route.name === 'extensions.visualizer')"
              :_route="phone.item['route']"
              :id="phone.item['route'] ? phone.item['route']['id'] : null"
              @updated="on_route_update"
              data-test-id="extensions-call-flow"
            />
            <PhoneNumberSettings
              v-if="($route.name === 'phone-numbers.show' || $route.name === 'phone-numbers.visualizer' || $route.name === 'extensions.visualizer') && phone.item"
              :_phone_number="phone.item"
              @submitted="phone.update($event)"
              data-test-id="phone-numbers-show-settings"
            />
            <CallHandlingRules
              v-else-if="$route.name === 'phone-numbers.call-flow'"
              :_route="phone.item['route']"
              :id="phone.item['route'] ? phone.item['route']['id'] : null"
              @updated="on_route_update"
              data-test-id="extensions-call-flow"
            />
            <MakeCall
              v-else-if="$route.name === 'phone-numbers.make-call'"
              :caller_id="phone.item['phone_number']"
              data-test-id="phone-numbers-show-make-call"
            />
            <Wizard
              v-else-if="$route.name === 'phone-numbers.wizard'"
              data-test-id="phone-numbers-show-wizard"
            />
        </div>
      </div>
    </div>
</template>

<script>
  import l from '../../../libs/lang';
  import Helpers from '../../../libs/helpers';
  import PhoneNumber from '../../../models/PhoneNumber';
  import Wizard from '../../fragments/Wizard.vue';
  import Submenu from '../../elements/Submenu.vue';
  import MakeCall from '../../fragments/MakeCall.vue';
  import PageTitle from '../../elements/PageTitle.vue';
  import SectionTitle from '../../elements/SectionTitle.vue';
  import PhoneNumberSettings from '../../fragments/PhoneNumberSettings.vue';
  import CallHandlingRules from '../../elements/routes/CallHandlingRules.vue';

  export default {
    props: ['id', '_phone_number'],
    components: {
      Submenu,
      Wizard,
      MakeCall,
      PageTitle,
      SectionTitle,
      CallHandlingRules,
      PhoneNumberSettings,
    },
    data() {
      return {
        l,
        phone: new PhoneNumber(this.$session, Helpers.emitter(this), Helpers.changeRoute(this)),
        title: null,
      };
    },
    async created() {
      if (this.$props._phone_number) {
        this.$data.phone.item = this.$props._phone_number;
        this.$data.phone.loading = false;
      } else {
          await this.$data.phone.getItem(this.$props.id);
      }

      if (this.$data.phone.item) {
        this.$data.title = `${l.t('app.phone-number', 'Phone number')} ${Helpers.format_phone_number(this.$data.phone.item.phone_number)}`;
      }
    },
    methods: {
      async on_route_update(data) {
        this.$data.phone.loading = true;
        try {
          if (!this.$data.phone.item.route || (this.$data.phone.item.route && this.$data.phone.item.route.id !== data.id)) {
            this.$data.phone.item.route = await this.$data.phone.update_route(data);
          }
          this.$data.phone.successfulUpdate();
        } catch (err) {
          this.$data.phone.validation_error(err);
        }
        this.$data.phone.loading = false;
      },
    }
  };
</script>
