<template>
    <div class="loader-wrapper" v-if="loading">
        <w-loader size="small" :message="false"/>
    </div>
    <div v-else class="route-rule-filter">
      <div class="py-3 mb-5 d-flex justify-space-between route-rule-title" :data-test-id="`route-conf-rule-main-title-${rule_index}`"      >
        <div class="my-auto">
          <v-icon v-if="route.rules.length > 1" class="mr-6 cursor-grab dragging-element">$vuetify.icons.two_bars</v-icon>
          <span class="w-subtitle-1 font-weight-600 info--text text--darken-1">{{ title }}</span>
        </div>
        <RemoveFromListButton v-if="route.rules.length > 1" icon class="pa-0 ma-0" @click="$emit('closed')"/>
      </div>
      <div class="w-50">
        <div class="w-subtitle-2 info--text text--darken-1 mb-4">
          1. {{ l.t('routes.select-incoming-call-type', 'Select incoming call type') }}
        </div>
        <w-select
          v-model="type"
          @change="typeChanged($event)"
          :items="type_items"
          hide-details="auto"
          class="pl-0 rule-filter-selector form-input"
          :data-test-id="`incoming-call-route-rule-filter-${rule_index}`"
        />
        <div v-if="type" class="mt-3 w-100">
            <template v-if="type === 'schedule'">
              <FormInput :label="l.t('app.schedule', 'Schedule')">
                <APIAutocomplete
                  mode="schedules"
                  v-model="action"
                  :return_object="true"
                  :rules="[$validation.required()]"
                  :data-test-id="`select-schedule-route-rule-filter-${rule_index}`"
                />
              </FormInput>
            </template>
            <template v-else-if="type === 'contact_group'">
                <template v-if="!$props._extension">
                  <FormInput :label="l.t('app.extension', 'Extension')">
                    <ExtensionSelector
                      :rules="[$validation.required()]"
                      :value="extension"
                      :placeholder="true"
                      :full_width="true"
                      @changed="extension = $event.value"
                      :data-test-id="`select-contact-group-extension-route-rule-filter-${rule_index}`"
                    />
                  </FormInput>
                  <template v-if="extension && extension.id">
                    <FormInput :label="l.t('app.group', 'Group')">
                      <APIAutocomplete
                        :key="extension.id+type"
                        :rules="[$validation.required()]"
                        mode="groups"
                        :extension="extension.id"
                        v-model="action"
                        :return_object="true"
                        :data-test-id="`select-contact-group-route-rule-filter-${rule_index}`"
                      />
                    </FormInput>
                  </template>
                </template>
                <template v-else>
                  <FormInput :label="l.t('app.group', 'Group')">
                    <APIAutocomplete
                      :key="extension.id+type"
                      mode="groups"
                      :rules="[$validation.required()]"
                      :extension="_extension.id"
                      v-model="action"
                      :return_object="true"
                      :data-test-id="`select-contact-group-route-rule-filter-${rule_index}`"
                    />
                  </FormInput>
                </template>
            </template>
            <template v-else-if="type === 'contact'">
                <template v-if="!$props._extension">
                  <FormInput :label="l.t('app.extension', 'Extension')">
                    <ExtensionSelector
                      :rules="[$validation.required()]"
                      :value="extension"
                      :placeholder="true"
                      :full_width="true"
                      @changed="extension = $event.value"
                      :data-test-id="`select-contact-extension-route-rule-filter-${rule_index}`"
                    />
                  </FormInput>
                    <template v-if="extension && extension.id">
                      <FormInput :label="l.t('app.contact', 'Contact')">
                        <APIAutocomplete
                            :key="extension.id+type"
                            mode="contacts"
                            :rules="[$validation.required()]"
                            :extension="extension.id"
                            v-model="action"
                            :return_object="true"
                            :data-test-id="`select-contact-route-rule-filter-${rule_index}`"
                        />
                      </FormInput>
                    </template>
                </template>
                <template v-else>
                  <FormInput :label="l.t('app.contact', 'Contact')">
                    <APIAutocomplete
                      v-if="extension && extension.id"
                      v-model="action"
                      :return_object="true"
                      :rules="[$validation.required()]"
                      :key="extension.id+type"
                      mode="contacts"
                      :extension="_extension.id"
                      @empty="contacts_empty = true"
                      :data-test-id="`select-contact-route-rule-filter-${rule_index}`"
                    />
                  </FormInput>
                </template>
            </template>
        </div>
      </div>
    </div>
</template>

<script>
  import l from '../../../libs/lang';
  import Contact from '../../../models/Contact';
  import APIAutocomplete from '../form/APIAutocomplete.vue';
  import FormInput from '../form/FormInput.vue';
  import RemoveFromListButton from '../RemoveFromListButton.vue';
  import ExtensionSelector from '../../fragments/ExtensionSelector.vue';

  export default {
    props: ['_filter', '_extension', 'route', 'rule_index'],
    components: {
      FormInput,
      APIAutocomplete,
      ExtensionSelector,
      RemoveFromListButton,
    },
    data() {
      return {
        loading: false,
        l,
        filter: null,
        type: null,
        action: {
          id: null,
        },
        extension: null,
        loadingContacts: false,
        type_items: [
          {value: null, text: l.t('app.all-calls', 'All calls')},
          {value: 'schedule', text: l.t('routes.received-during', 'Received during')},
          {value: 'contact_group', text: l.t('routes.from-group', 'From group')},
          {value: 'contact', text: l.t('routes.from-contact', 'From contact')},
          {value: 'unknown', text: l.t('routes.no-valid-caller-id', 'No valid caller ID')},
        ],
        title: l.t('routes.select-incoming-call-type', 'Select incoming call type'),
      };
    },
    async created() {
      this.$data.extension = this.$props._extension;
      this.$data.filter = this.$props._filter;
      if (this.$data.filter) {
        this.$data.type = this.$data.filter.type;
        if (
          ['schedule', 'contact_group', 'contact'].includes(
            this.$data.filter.type,
          )
        ) {
          if (this.$data.filter.type === 'contact_group') {
            this.$data.action = this.$data.filter['group'];
          } else {
            this.$data.action = this.$data.filter[this.$data.filter.type];
          }
          if (
            this.$data.filter.type === 'contact_group'
            && this.$data.filter.group
          ) {
            this.$data.extension = {};
            this.$data.extension.id = this.$data.filter.group.extension_id;
          } else if (
            this.$data.filter.type === 'contact'
            && this.$data.filter.contact
          ) {
            this.$data.extension = {};
            this.$data.extension.id = this.$data.filter.contact.extension_id;
          }
        }
      }
      this.update_title();
    },
    methods: {
      type_title() {
        let { type } = this.$data;
        if (type === 'group') type = 'contact_group';
        return this.$data.type_items.find((x) => x.value === type).text;
      },
      typeChanged() {
        this.$data.action = {};
        this.emitChanges();
      },
      emitChanges() {
        if (this.$data.type === null) {
          this.$emit('updated', undefined);
        } else if (this.$data.type === 'unknown') {
          this.$emit('updated', { type: 'unknown' });
        } else {
          const actionName = this.$data.type === 'contact_group' ? 'group' : this.$data.type;

          const updatedObject = { type: this.$data.type };
          updatedObject[actionName] = { id: this.$data.action && this.$data.action.id ? this.$data.action.id : null };
          this.$emit('updated', updatedObject);
          if (!this.$data.action) this.$data.action = {};
          if (['contact', 'group'].includes(actionName) && this.$data.extension && this.$data.extension.id) {
            updatedObject[actionName]['extension_id'] = this.$data.extension.id;
          }
        }
      },
      update_title() {
        this.$data.title = `${this.type_title()} `;
        if (this.type && this.$data.action && this.$data.action.id) {
          if (this.type === 'contact') {
            this.$data.title += Contact.assemble_display_name(this.$data.action);
          } else {
            this.$data.title += this.$data.action.name || this.$data.action.id;
          }
        }
      }
    },
    watch: {
      action: {
        deep: true,
        handler() {
          this.update_title();
          this.emitChanges();
        },
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import '../../../assets/styles/scss/vars.scss';
  .route-rule-filter {
    .v-input__icon--prepend {cursor: grab;}
  }
  .route-rule-title {
    border-top: thin solid var(--v-info-base);
    border-bottom: thin solid var(--v-action-lighten5);;
  }
</style>
