<template>
  <div v-if="$branding.data.code === 'default'" class="cancel-account-note">
    <v-icon size="20" color="text lighten-1">$vuetify.icons.infotip_hot</v-icon>
    <span class="text--text text--lighten-1 w-caption">
        {{l.t('app.to-cancel-your-account', 'To cancel your account contact our team at (877) 472-9245, or')}} <a href="https://support.phone.com/contact-us" target="_blank" class="primary--text text--darken-1">{{l.t('app.submit-a-service-request', 'submit a service request')}}</a>.
    </span>
  </div>
</template>
<script>
  import l from '../../libs/lang';

  export default {
    data() {
      return {
        l,
      };
    },
  };
</script>
