<template>
  <div>
    <w-radio-group
      v-if="default_type === false"
      v-model="forwarding_type"
      row
      hide-details='auto'
      data-test-id="fwd-item-xt-radio-select"
    >
      <v-radio
        value="phone_number"
        data-test-id="fwd-item-xt-radio-select-phone"
      >
        <template v-slot:label>
          <span class="text--text">{{ l.t('app.phone-number', 'Phone number') }}</span>
        </template>
      </v-radio>
      <v-radio
        value="extension"
        data-test-id="fwd-item-xt-radio-select-extension"
      >
        <template v-slot:label>
          <span class="text--text">{{ l.t('app.extension', 'Extension') }}</span>
        </template>
      </v-radio>
    </w-radio-group>
    <div class="d-flex">
      <Tel
          v-if="forwarding_type === 'phone_number'"
          :value="phone_number"
          :validation="true"
          :show_options="true"
          :required="required"
          :closable="closable"
          @changed="phone_number = $event.value"
          @show_options="show_more_options_modal = true"
          @closed="$emit('closed')"
          class="w-100"
          data-test-id="fwd-item-tel-input"
      />
      <APIAutocomplete
          v-else-if="forwarding_type === 'extension'"
          v-model="extension"
          :return_object="true"
          mode="extensions"
          key="extensions"
          :rules="required ? [$validation.required()] : []"
          :closable="closable"
          :before_list="ring_my_device_selector_option"
          @closed="$emit('closed')"
          :additional_options="additional_options"
          data-test-id="fwd-item-extension-selector"
          class="w-100"
      />
      <DefaultModal
        v-model="show_more_options_modal"
        @close="show_more_options_modal = false"
        max-width="500"
        data-test-id="fwd-item-modal"
        @submitted=""
      >
        <template v-slot:title>
          {{ l.t('app.more-options', 'More options') }}
        </template>
        <div>
          <div class="d-flex">
            <w-switch v-model="screening" :label="l.t('forwarding-item.call-screening', 'Call screening')" hide-details='auto'
              data-test-id="fwd-item-screening-switch"/>
            <InfoLink max-width="300" top no-carret data-test-id="call-screening-text" activator_classes="mt-3">
              {{ l.t(
                'live-answer.call-screening-text',
                'Call Screening provides you an audio "pre-screening" of an incoming call, telling you what number was called, what the caller\'s number is and giving you the option to accept or reject the call. The caller does not hear this message and continues to hear the ring.'
              ) }}
            </InfoLink>
          </div>
            <v-divider class="light-divider mt-1 mb-1"/>
              <div class="d-flex">
                <span class="w-body-2 info--text text--darken-1 mr-2 my-auto">
                  {{ l.t('forwarding-item.call-screening-caller-id', 'Caller ID to show me:') }}
                </span>
                <w-radio-group v-model="caller_id" row hide-details="auto" class="radio-group ml-n1">
                  <v-radio :label="l.t('app.calling-number', 'Calling number')" color="secondary" value="calling_number"
                    data-test-id="fwd-item-calling-num-radio"></v-radio>
                  <v-radio :label="l.t('app.called-number', 'Called number')" color="secondary" value="called_number"
                    data-test-id="fwd-item-called-num-radio"></v-radio>
                </w-radio-group>
              </div>
              <w-text-field
                :placeholder="l.t('forwarding-item.voice-tag', 'Add a voice tag')"
                type="text"
                class="w-100"
                maxlength="40"
                v-model="voice_tag"
                hide-details="auto"
                data-test-id="fwd-item-voice-tag-input"
              />
              <w-select
                v-if="forwarding_type === 'extension'"
                v-model="distinctive_ring"
                class="w-100 mt-5"
                :placeholderl="l.t('forwarding-item.ringing-style', 'Ringing style')"
                :items="[
                    {value: 'DEFAULT', text: l.t('app.default-ring-style', 'Default ring style')},
                    {value: 'STYLE_2', text: l.t('forwarding-item.ring-style {}', 'Ring style {}', [2]) },
                    {value: 'STYLE_3', text: l.t('forwarding-item.ring-style {}', 'Ring style {}', [3]) },
                    {value: 'STYLE_4', text: l.t('forwarding-item.ring-style {}', 'Ring style {}', [4]) },
                    {value: 'STYLE_5', text: l.t('forwarding-item.ring-style {}', 'Ring style {}', [5]) },
                    {value: 'STYLE_6', text: l.t('forwarding-item.ring-style {}', 'Ring style {}', [6]) },
                    {value: 'STYLE_7', text: l.t('forwarding-item.ring-style {}', 'Ring style {}', [7]) },
                    {value: 'STYLE_8', text: l.t('forwarding-item.ring-style {}', 'Ring style {}', [8]) },
                    {value: 'STYLE_9', text: l.t('forwarding-item.ring-style {}', 'Ring style {}', [9]) },
                ]"
                hide-details="auto"
                data-test-id="fwd-item-distinctive-ring-select"
              />
        </div>
        <template v-slot:buttons>
          <w-btn color="secondary" @click="show_more_options_modal = false" class="ml-5">{{l.t('app.cancel', 'Cancel')}}</w-btn>
          <w-btn color="primary" @click="save_conf_changes">{{l.t('app.save-notifications-settings', 'Save notifications settings')}}</w-btn>
        </template>
      </DefaultModal>
    </div>
  </div>
</template>

<script>
  import l from '../../libs/lang';
  import Tel from './Tel.vue';
  import APIAutocomplete from './form/APIAutocomplete.vue';
  import InfoLink from './InfoLink.vue';
  import FormInput from './form/FormInput.vue';
  import DefaultModal from './modal/DefaultModal.vue';

  export default {
    props: ['value', 'default_type', 'required', 'closable', 'device_extension'],
    components: {
      Tel,
      InfoLink,
      FormInput,
      DefaultModal,
      APIAutocomplete,
    },
  data() {
      return {
        l,
        radio_name: `pnf_${Math.floor(Math.random() * 100000000)}`,
        show_options: false,
        changes: {},
        forwarding_type:
          // eslint-disable-next-line no-nested-ternary
          this.$props.value && this.$props.value['type']
            ? this.$props.value['type']
            : this.$props.default_type
            ? this.$props.default_type
            : 'phone_number',
          distinctive_ring:
            this.$props.value && this.$props.value['distinctive_ring']
              ? this.$props.value['distinctive_ring']
              : 'DEFAULT',
          phone_number:
            this.$props.value && this.$props.value['number']
              ? this.$props.value['number']
              : '',
          extension:
            this.$props.value && this.$props.value['extension']
              ? this.$props.value['extension']
              : {},
          caller_id:
            this.$props.value && this.$props.value['caller_id']
              ? this.$props.value['caller_id']
              : 'calling_number',
          screening:
            this.$props.value && this.$props.value['screening']
              ? this.$props.value['screening']
              : false,
          voice_tag:
            this.$props.value && this.$props.value['voice_tag']
              ? this.$props.value['voice_tag']
            : '',
          ring_my_device_selector_option: null,
          additional_options: null,
          show_more_options_modal: false,
      };
     },
     created() {
      if (this.$props.device_extension) {
        this.$data.ring_my_device_selector_option = [
          { text: l.t('app.ring-my-device', 'Ring my device'), value: this.$props.device_extension.id },
          { divider: true }
        ];
      }
      this.add_options();
    },
    methods: {
      add_options() {
        if (this.$data.extension && this.$data.extension.id) {
          this.$data.additional_options = [{
              title: l.t('app.more-options', 'More options'),
              icons: '$vuetify.icons.gear',
              cb: () => this.$data.show_more_options_modal = true,
          }];
        } else {
          this.$data.additional_options = [];
        }
      },
        save_conf_changes() {
            const props = Object.keys(this.$data.changes);
            if (props.length) {
                for (const prop of props) {
                    this.process_changed(prop, this.$data.changes[prop]);
                }
            }
            this.$data.show_more_options_modal = false;
        },
        process_changed(param, val) {
          const value = {
            type: this.$data.forwarding_type,
            caller_id: this.$data.caller_id,
            screening: this.$data.screening,
            voice_tag: this.$data.voice_tag,
          };
          if (this.$data.forwarding_type === 'phone_number') {
            value['number'] = this.$data.phone_number;
          } else if (this.$data.forwarding_type === 'extension') {
            value['extension'] = this.$data.extension;
          }
          value[param] = val;
          this.$emit('changed', { value });
        },
    },
    watch: {
      phone_number(value) {
        this.process_changed('number', value);
      },
      extension(value) {
        this.process_changed('extension', value);
        this.add_options();
      },
      forwarding_type(v) {
        this.process_changed(v, this.$data[v]);
      },
      caller_id(value) {
        this.$data.changes.caller_id = value;
      },
      screening(value) {
        this.$data.changes.screening = value;
      },
      voice_tag(value) {
        this.$data.changes.voice_tag = value;
      },
      distinctive_ring(value) {
        this.$data.changes.distinctive_ring = value;
      },
    },
  };
</script>
