var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"live-answer"},[_c('Submenu',{key:`${_vm.l.detect()}${_vm.$route.name}`,attrs:{"routes":{
    'live-answer.index': {
      title: _vm.l.t('menu.receptionist-scripts', 'Receptionist scripts'),
      subroutes: ['live-answer.show']
    },
    'live-answer.create': {
        title: _vm.l.t('app.add-receptionist-script', 'Create a new receptionist script'),
      },
    }}}),(_vm.$route.name === 'live-answer.index')?_c('LiveAnswerListFragment',{attrs:{"data-test-id":"live-answer-index"},on:{"deletingStarted":function($event){_vm.deleting_in_progress = true},"deletingEnded":function($event){_vm.deleting_in_progress = false}}}):_vm._e(),(_vm.$route.name === 'live-answer.create')?_c('LiveAnswerCreateFragment',{attrs:{"data-test-id":"live-answer-create"}}):_vm._e(),(_vm.$route.name === 'live-answer.show')?_c('LiveAnswerShowFragment',{attrs:{"data-test-id":"live-answer-show"}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }