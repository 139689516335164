var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"api-requests"},[_c('Submenu',{key:`${_vm.l.detect()}${_vm.$route.name}`,attrs:{"routes":{
		'api-requests.create': {
			title: _vm.l.t('app.api-requests', 'API requests')
		},
		'api-requests.index': {
			title: _vm.l.t('api-requests.scheduled', 'Scheduled requests'),
			subroutes: ['api-requests.show']
		},
	  }}}),(_vm.$route.name === 'api-requests.create')?_c('ApiRequests',{attrs:{"api":_vm.api,"data-test-id":"api-req-index"}}):_vm._e(),(_vm.$route.name === 'api-requests.index')?_c('ScheduledRequests',{attrs:{"api":_vm.api,"data-test-id":"api-req-scheduled-reqs"}}):_vm._e(),(_vm.$route.name === 'api-requests.show')?_c('ShowRequest',{attrs:{"api":_vm.api,"data-test-id":"api-req-show"}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }