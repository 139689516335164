<template>
    <w-loader v-if="menu.loading"/>
    <div v-else class="create-page mt-3" data-test-id="menus-create-page">
        <w-alert
          v-if="menu.alert"
          :level="menu.alert.level"
          :message="menu.alert.message"
          :closable="menu.enable_close_alert"
          @closed="menu.alert = null"
          class="mb-6"
        />
        <MenuFragment v-if="menu.item" :_menu="menu.item" @submitted="create" @saved="create" :full_menu="true"/>
    </div>
</template>

<script>
  import Menu from '../../../models/Menu';
  import Helpers from '../../../libs/helpers';
  import l from '../../../libs/lang';
  import MenuFragment from '../../fragments/Menu.vue';

  export default {
    components: {
      MenuFragment,
    },
    data() {
      return {
        l,
        menu: new Menu(this.$session, Helpers.emitter(this), Helpers.changeRoute(this)),
        displayForm: true,
      };
    },
    methods: {
      async create(data) {
        this.$data.menu.successfulCreation('menus.index', false, false, data);
        this.$data.menu.item = null;
      },
    },
  };
</script>
