<template>
    <w-alert
        :closable="false"
        level="warning"
        class="margin-top-30"
        :message="message"
    />
</template>
<script>
import l from '../../../../libs/lang';

export default {
    data() {
        return {
            l,
            message: null,
        };
    },
    created() {
        if (['avo', 'avo_master'].includes(this.$branding.data.code)) {
            this.$data.message = l.t('lr.lr-avo-once-setup-is-complete', 'Once setup is complete, you will get calls from +1 (888) 851-1211. Add this number to your web app and mobile device address books. Do not block this number.');
        } else {
            this.$data.message = l.t('lr.lr-once-setup-is-complete', 'Once setup is complete, you will get calls from +1 (888) 851-1211. Add this number to your Phone.com and mobile device address books. Do not block this number.');
        }
    }
};
</script>
