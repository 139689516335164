var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.trunk.can_visit)?_c('IndexPage',{staticClass:"trunks list-page",attrs:{"resource":_vm.trunk,"disable_delete_all":true,"data-test-id":"trunks-list-page"},scopedSlots:_vm._u([{key:"filters",fn:function(){return [_c('FormInput',{attrs:{"label":_vm.l.t('app.name', 'Name')}},[_c('w-text-field',{attrs:{"hide-details":"auto","data-test-id":"trunks-filters-name-input"},model:{value:(_vm.trunk.filters.name),callback:function ($$v) {_vm.$set(_vm.trunk.filters, "name", $$v)},expression:"trunk.filters.name"}})],1)]},proxy:true},{key:"list",fn:function(){return [_c('v-simple-table',{staticClass:"list-table",attrs:{"data-test-id":"trunks-table"}},[_c('thead',{attrs:{"data-test-id":"table-head"}},[_c('tr',{attrs:{"data-test-id":"head-row"}},[_c('th',{attrs:{"data-test-id":"checkbox"}}),_c('th',{attrs:{"data-test-id":"id"}},[_vm._v(_vm._s(_vm.l.t('app.ID', 'ID')))]),_c('th',{attrs:{"data-test-id":"name"}},[_vm._v(_vm._s(_vm.l.t('app.name', 'Name')))]),_c('th',{attrs:{"data-test-id":"uri"}},[_vm._v(_vm._s(_vm.l.t('app.uri', 'URI')))])])]),_c('tbody',{attrs:{"data-test-id":"table-body"}},_vm._l((_vm.trunk.items),function(item,i){return _c('tr',{key:item['id'],class:{'selected-row': item.selected},attrs:{"data-test-id":`trunks-item-${i}-row`}},[_c('td',{staticClass:"select-table-item",attrs:{"data-test-id":"checkbox"}},[_c('w-checkbox',{key:`trunks-item-${item.id}-${item.selected}`,attrs:{"hide-details":"auto","data-test-id":`trunks-item-${i}-checkbox`},on:{"click":function($event){return _vm.checked($event, item)}},model:{value:(item.selected),callback:function ($$v) {_vm.$set(item, "selected", $$v)},expression:"item.selected"}})],1),_c('td',{staticClass:"nowrap overflow-hidden",attrs:{"data-test-id":`trunks-item-${i}-title`}},[_c('IndexListItem',{key:item['id'],attrs:{"route":{
                name: 'trunks.show',
                params: {
                  id: item['id'],
                  _trunk: item,
                },
              },"item":item,"title":`#${item.id}`}})],1),_c('td',{staticClass:"nowrap",attrs:{"data-test-id":`trunks-item-${i}-name`}},[_vm._v(" "+_vm._s(item.name)+" ")]),_c('td',{staticClass:"w-100",attrs:{"data-test-id":`trunks-item-${i}-uri`}},[_vm._v(_vm._s(item.uri))])])}),0)])]},proxy:true}],null,false,747256061)}):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }