import Resource from './Resource';
import setup from '../libs/resources-setups/trunks';

export default class Trunk extends Resource {
	constructor(session, emitter, changeRoute) {
		super(session, emitter, '/trunks', changeRoute);
		this.selector_mode = ['trunks'];
		this.setup = setup;
        this.filters = { name: null };
        this.item = {
          name: '',
          uri: '',
          max_concurrent_calls: 10,
          max_minutes_per_month: 750,
          max_call_duration: 10800,
          codecs: null,
        };
        this.can_visit = false;
	}

	async checkIfTrunksAreEnabled() {
		this.loading = true;
		try {
		  if (!this.session.user.account.features['trunks-enabled']) {
		    return this.changeRoute('homepage');
		  }

		  this.can_visit = true;
		} catch (err) {
		    this.show_generic_error(err);
		}
		this.loading = false;

		return true;
	}

	async loadItems(params) {
	  this.loading = true;
	  try {
	    const offset = this.checkIfUserDeletedItems(params.offset, params.page);

	    const items = await this.session.get_list(
	      this.uri, params.limit, offset,
	    );

	    this.page = params.page;
	    if (this.page > 1 && !items.items.length) {
	      this.emitter('pageChanged', 1);
	      return this.loadItems({ limit: this.limit, offset: 0, page: 1 });
	    }
	    if (this.page === 1 && !this.filters_applied && !items.items.length) {
	      return this.changeRoute('trunks.create');
	    }

	    this.items = items.items;
		this.finalizeLoadingItems(items);
	    this.emitter('itemsLoaded', JSON.parse(JSON.stringify(items)));
	  } catch (err) {
	    this.validation_error(err);
	    if (err.status === 403) this.hide_filters = true;
	  }
	  this.loading = false;

	  return this.items;
	}

	async create(trunk) {
	  this.loading = true;
	  this.item = trunk;
	  try {
	    const newTrunk = await this.session.create_item(
	      this.baseUri,
	      this.item,
	    );
	    this.addToCache(newTrunk);
	    this.successfulCreation('trunks.index');
	    this.item = null;
	  } catch (err) {
	    this.validation_error(err);
	  }
	  this.loading = false;
	}

	async update(trunk) {
	  this.loading = true;
	  this.item = trunk;
	  try {
	    await this.session.replace_item(
	      `${this.baseUri}/${this.item.id}`,
	      this.item,
	    );
	    this.updateCache(this.item);
		this.successfulUpdate('trunks.index');
	    this.item = null;
	  } catch (err) {
	    this.validation_error(err);
	  }
	  this.loading = false;
      return true;
	}

	apply_filters() {
	  this.clearMessages();
	  this.filters_applied = true;

	  try {
	    let uri = `${this.baseUri}?mode=full`;
	    if (this.filters.name) uri = `${uri}&filters[name]=contains:${this.filters.name}`;
	    this.uri = uri;
	  } catch (err) {
	    this.emitter('failed', err);
	  }
	}
}
