import Resource from './Resource';

export default class VideoConference extends Resource {
    constructor(session, emitter, changeRoute) {
        super(session, emitter, '/video', changeRoute);
        this.item = null;
        this.cache_key = `video-conference-configuration-${this.session.user.id}`;
    }

    async getItem() {
        this.loading = true;
        try {
            const cache = this.cachier.getItem(this.cache_key);
            if (cache) {
                this.item = cache;
            } else {
                this.cachier.removeItem(this.cache_key);
                this.item = await this.session.get_item(this.baseUri);
                this.cachier.setItem(this.cache_key, this.item);
            }
            if (this.item.created_at === null) {
                this.item = {
                    domain: null,
                    business_name: null,
                    plan_code: null,
                };
            }
        } catch (err) {
            this.validation_error(err);
        }
        this.loading = false;
        return this.item;
    }

    async save(video) {
        this.loading = true;
        this.item = video;
        try {
            if (this.item.enabled) {
                this.item = await this.session.patch_item(this.baseUri, {
                    business_name: this.item.business_name,
                    plan_code: this.item.plan_code,
                });
            } else {
                this.item = await this.session.create_item(this.baseUri, {
                    domain: this.item.domain,
                    business_name: this.item.business_name,
                    plan_code: this.item.plan_code,
                });
            }
            this.cachier.setItem(this.cache_key, this.item);
            this.successfulUpdate();
        } catch (err) {
            this.validation_error(err);
        }
        this.loading = false;
    }
}
