<template>
  <div class="visualizerClass">
    <v-container class="speedy-container mt-7" fluid>
      <v-row class="top-row">
        <v-col class="callFlowCol" cols="auto">
          <BackButton class="backButton" style="color: #197F9F !important;"/>
          <w-tooltip class="cursor-pointer tooltip" bottom :nudge-bottom="4" :max-width="250">
            <template v-slot:activator class="pb-5 mt-n5">
              <h2 class="callFlowHeading">
                Visual Call Flow:
              </h2>
            </template>
            Double click to open item's modal.
            <br>
            Click and hold on an item to move it.
            <br>
            Click and hold on the background to move the whole visual.
            <br>
            Scroll up and down to zoom in and out.
          </w-tooltip>
        </v-col>
        <v-col cols="auto" class="typeObjCol">
          <w-select
            v-model="filterType"
            :items="['Phone numbers', 'Extensions/Users']"
            item-text="name"
            class="mb-2"
            hide-details="auto"
            return-object
          />
        </v-col>
        <v-col cols="auto" class="chosenItemCol">
          <w-autocomplete
            v-if="filterType === 'Phone numbers'"
            v-model="chosenItem"
            :items="userPhones.items"
            :item-text="item => `${item.name}: ${$options.filters.format_phone_number(item.phone_number)}`"
            :filter="customFilter"
            class="mb-2"
            :placeholder="l.t('app.select-a-number', 'Select a number')"
            hide-details="auto"
            return-object
          />
          <w-autocomplete
            v-else-if="filterType === 'Extensions/Users'"
            v-model="chosenItem"
            :items="userExtensions.items"
            :item-text="item => `${item.extension}: ${item.name}`"
            :filter="customFilter"
            class="mb-2"
            :placeholder="l.t('app.select-an-extension', 'Select an extension')"
            hide-details="auto"
            return-object
          />
        </v-col>
      </v-row>
      <div v-if="toShow && !loading">
        <div class="visualizer-info">
          <v-icon @click="zoomClicked(-1)" size=40 class="icons plus-icon">mdi-plus-circle-outline</v-icon>
          <v-icon @click="zoomClicked(1)" size=40 class="icons minus-icon">mdi-minus-circle-outline</v-icon>
          <v-icon @click="moveScene('x', -50)" size=40 class="icons left-icon">mdi-arrow-left-bold-outline</v-icon>
          <v-icon @click="moveScene('y', -50)" size=40 class="icons up-icon">mdi-arrow-up-bold-outline</v-icon>
          <v-icon @click="moveScene('y', 50)" size=40 class="icons down-icon">mdi-arrow-down-bold-outline</v-icon>
          <v-icon @click="moveScene('x', 50)" size=40 class="icons right-icon">mdi-arrow-right-bold-outline</v-icon>
          <v-icon @click="centerScene()" size=40 class="icons center-icon">mdi-record-circle-outline</v-icon>
        </div>
      </div>
      <VueBlocksContainer
        v-if="toShow && !loading"
        ref="container"
        :blocksContent="blocks"
        :scene.sync="scene"
        :maxHeight="maxHeight"
        :maxWidth="maxWidth"
        @blockSelect="selectBlock"
        @openModal="openModal"
        @blockDeselect="deselectBlock"
        @blockRightClick="blockRightClick"
        class="container-fluid"/>
    </v-container>
    <w-loader v-if="loading" class="loader"></w-loader>
    <DefaultModal
      v-model="show_modal"
      :with_x="true"
      @close="show_modal = false"
      width="1000"
      :key="modal_key"
    >
      <Menu v-if="modal_type === 'menu'" :id="actionID" :full_menu="true" data-test-id="selector-edit-menu" @updated="update_screen"/>
      <Route v-if="modal_type === 'preset'" :id="actionID" :disable_switcher="true" data-test-id="selector-edit-route" @updated="update_screen"/>
      <Extension v-if="modal_type === 'extension'" :_extension="userExtensions.items.find((item) => item.id === actionID)" :ignore_routes="true" data-test-id="selector-edit-extension" @updated="update_screen"></Extension>
      <PhoneNumber v-if="modal_type === 'number'" :_phone_number="userPhones.items.find((item) => item.id === actionID)" data-test-id="selector-edit-phone-num" @updated="update_screen"/>
    </DefaultModal>
  </div>
</template>

<script>

  import VueBlocksContainer from './components/VueBlocksContainer.vue';
  import FormInput from '../../elements/form/FormInput.vue';
  import l from '../../../libs/lang';
  import Helpers from '../../../libs/helpers';
  import Visualizer from './helpers/visualizer-helpers';
  import PhoneNumber from '../../../models/PhoneNumber';
  import Extension from '../../../models/Extension';
  import Route from '../../../models/Route';
  import DefaultModal from '../../elements/modal/DefaultModal.vue';
  import BackButton from '../../elements/BackButton.vue';

  export default {
    name: 'visualizerApp',
    components: {
      FormInput,
      VueBlocksContainer,
      BackButton,
      DefaultModal,
      Menu: () => import('../menus/show.vue'),
      Extension: () => import('../extensions/show.vue'),
      Route: () => import('../routes/show.vue'),
      PhoneNumber: () => import('../phone-numbers/show.vue'),
    },
    mounted() {
      if (this.$route.name.toLowerCase().includes('extensions')) {
        this.filterType = 'Extensions/Users';
      }
      const element = document.getElementById('page-content');
      if (element) {
        element.classList.add('visualizer-content');
      }

      const elements = document.getElementsByClassName('v-input__slot');
      for (const el of elements) {
        el.style.backgroundColor = 'white';
      }
      this.startingX = 0;
    },
    destroyed() {
      const element = document.getElementById('page-content');
      if (element) {
        element.classList.remove('visualizer-content');
      }
    },
    data() {
      return {
        l,
        userPhones: new PhoneNumber(this.$session, Helpers.emitter(this), Helpers.changeRoute(this)),
        userExtensions: new Extension(this.$session, Helpers.emitter(this), Helpers.changeRoute(this)),
        userRoute: new Route(this.$session, Helpers.emitter(this), Helpers.changeRoute(this)),
        show_modal: false,
        modal_key: Date.now(),
        actionID: -1,
        yy: 0,
        colors: [
          {name: 'menu', color: 'rgba(234, 245, 248, 0.8)', color2: '#2A9BB7'},
          {name: 'number', color: 'rgba(255, 250, 232, 0.8)', color2: ''},
          {name: 'extension', color: 'rgba(244, 245, 246, 0.8)', color2: ''},
          {name: 'queue', color: '#EAF5F8', color2: '#2A9BB7'},
          {name: 'receptionist', color: '#F9CDC88C', color2: '#F76757'},
          {name: 'Call flow', color: 'rgba(253, 237, 235, 0.8)', color2: '#F76757'},
          {name: 'preset', color: 'rgba(210, 247, 195, 0.8)', color2: 'rgb(210 247 195)'},
        ],
        token: '', // ZCZnAENuNgRE36ZxPZiFAodNuq5rEDBaT4HMbMvVi2VRVg6p, 0zbLSuEzcpURSDVLcuAVyjpVQZ47AjxL6yNYjE0qWF3HkFxW
        voip_id: '', // 117, 832332
        loading: false,
        modal_type: null,
        filterType: 'Phone numbers',
        fullRoutes: [],
        toShow: false,
        blocks: [],
        menus: [],
        queues: [],
        extensions: [],
        chosenItem: null,
        currentExtension: null,
        currentMenu: null,
        callFlowBlockCounter: 0,
        stagger: false,
        startingX: -700,
        distanceBetween: 335,
        maxHeight: 0,
        maxWidth: 0,
        selectedBlock: null,
        scene: {
          blocks: [],
          links: [],
          container: {
            centerX: 0,
            centerY: 0,
            scale: 1
          }
        },
      };
    },
    async created() {
      this.loading = true;
      await this.userPhones.loadItems({limit: 500, offset: 0, page: 1});
      this.userPhones.items.sort((a, b) => {
        if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
        if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
        return 0;
      });
      await this.userExtensions.loadItems({limit: 500, offset: 0, page: 1});
      this.userExtensions.items.sort((a, b) => {
        if (a.extension < b.extension) return -1;
        if (a.extension > b.extension) return 1;
        return 0;
      });
      this.loading = false;
      if (this.$route.params._extension) {
        this.chosenItem = this.$route.params._extension;
        this.filterType = 'Extensions/Users';
      } else if (this.$route.params._phone_number) {
        this.chosenItem = this.$route.params._phone_number;
        this.filterType = 'Phone numbers';
      }
    },
    methods: {
      async update_screen(data) {
        setTimeout(() => {
          this.show_modal = false;
          this.loading = true;
        }, 1000);
        if (data && data.phone_number) {
          const foundIndex = this.userPhones.items.findIndex((item) => item.id === data.id);
          this.userPhones.items[foundIndex] = await this.$session.get_item(`/phone-numbers/${data.id}`);
        } else if (data && data.extension) {
          const foundIndex = this.userExtensions.items.findIndex((item) => item.id === data.id);
          this.userExtensions.items[foundIndex] = await this.$session.get_item(`/extensions/${data.id}`);
        } else if (!data) {
          this.menus = [];
          this.fullRoutes = [];
        }
        if (this.chosenItem.phone_number) {
          this.chosenItem = await this.$session.get_item(`/phone-numbers/${this.chosenItem.id}`);
        } else {
          this.chosenItem = await this.$session.get_item(`/extensions/${this.chosenItem.id}`);
        }
      },
      zoomClicked(val) {
        this.$refs.container.buttonScroll(val);
      },
      centerScene() {
        this.$refs.container.center();
      },
      moveScene(axis, amount) {
        this.$refs.container.moveScene(axis, amount);
      },
      async changeMapOption(type) {
        try {
          this.scene.container = {
            centerX: 0,
            centerY: 0,
            scale: 1
          };
          this.scene.blocks = [];
          this.scene.links = [];
          this.blocks = [];
          this.callFlowBlockCounter = 0;
          this.maxHeight = 0;
          this.maxWidth = 0;
          this.toShow = false;
          this.loading = true;
          this.stagger = false;

          console.log((type === 'number' ? 'Phone Number: ' : 'Extension: ') + this.chosenItem.id, this.chosenItem);

          if (this.chosenItem.route && this.chosenItem.route.id && !this.chosenItem.route.rules) {
            let route = this.fullRoutes.find((r) => r.id === this.chosenItem.route.id);
            if (!route) {
              route = await this.userRoute.getItem(this.chosenItem.route.id);
              this.fullRoutes.push(route);
            }
            this.chosenItem.route = route;
          }

          const route = await Visualizer.getFullRoute.call(this, this.chosenItem.route);

          if (route && route.rules && route.rules.length) {
            console.log('DRAWING FULL ROUTE FROM DOWN BELOW');
            await this.startRoute(this.chosenItem);
          } else {
            let sortedOutputs = [];
            console.log("Didn't start drawing", route);
            if (route && route.rules) {
              sortedOutputs = Visualizer.sortFilters(route.rules);
            }
            if (!sortedOutputs.length) {
              sortedOutputs.push({
                attr: 'output', label: 'All Calls', name: 'All Calls'
              },
              {
                attr: 'output', actionType: 'Disconnect', label: 'Disconnect', name: 'Disconnect'
              });
            }
            this.addDefaultBlock(sortedOutputs);
          }
          this.yy = 0;
          this.loading = false;
          this.toShow = true;
        } catch (error) {
          console.warn(error);
          // alert(error);
          this.loading = false;
        }
      },
      addBeforePresetBlock(response, sceLen, outputID = null, lastBlock = null) {
        const {id} = response;
        const {name} = response;
        const fields = [{name: 'Preset', attr: 'output', id: 0}];
        const color = this.colors.find((col) => col.name === (response.phone_number ? 'number' : 'extension'));
        const title = `${response.phone_number ? `Phone Number: ${this.$options.filters.format_phone_number(response.phone_number)}` : `Extension/User ${response.extension}`}`;

        this.addFirstBlock(title, name, color, fields, id);
        const flag = this.addSceneBlock(title, name, color, sceLen, id);
        if (outputID) {
          this.addSceneLink(outputID, flag || {id: this.scene.blocks.length}, lastBlock.id);
        }
      },
      async addPresetBlock(response, sceLen, outputID = null, lastBlock = null) {
        this.addBeforePresetBlock(response, sceLen, outputID, lastBlock);

        const route = await this.$session.get_item(`/routes/${response.route.id}`);
        response.route = route;

        const title = 'Preset';
        const {id} = response.route;
        const {name} = response.route;
        const color = this.colors.find((c) => c.name === 'preset');
        const fields = response.route.rules ? Visualizer.sortFilters(response.route.rules) : [];

        this.addFirstBlock(title, name, color, fields, id);
        this.addSceneBlock(title, name, color, sceLen + 1, id);
        this.addSceneLink(0, this.scene.blocks[this.scene.blocks.length - 1], this.scene.blocks[this.scene.blocks.length - 2].id);

        return route;
      },
      addDefaultBlock(outputs, sceLen, extension = null, outputID = null, lastBlock = null) {
        let title = '';
        const currentItem = extension || this.chosenItem;
        const fields = outputs;
        const {id} = currentItem;
        const color = this.colors.find((col) => col.name === (currentItem.phone_number ? 'number' : 'extension'));
        const {name} = currentItem;

        if (currentItem && currentItem.phone_number) {
          title = `Phone Number: ${this.$options.filters.format_phone_number(currentItem.phone_number)}`;
        } else if (currentItem && currentItem.extension) {
          title = `Extension/User ${currentItem.extension}`;
        }

        const blockName = this.addFirstBlock(title, name, color, fields, id);
        const flag = this.addSceneBlock(title, name, color, sceLen, id);
        if (outputID != null) {
          this.addSceneLink(outputID, flag || {id: this.scene.blocks.length}, lastBlock.id);
        }

        if (blockName === '' && flag === false) return true;
        return false;
      },
      async secondStartRoute(extension, sceneLength, outputID, lastBlock) {
        let exists = true;
        let sceLen = sceneLength;

        if (extension.route && extension.route.id && extension.route.name) {
          extension.route = await this.addPresetBlock(extension, sceneLength, outputID, lastBlock);
          sceLen += 2;
        } else {
          let sorted = [{
            attr: 'output', label: 'All Calls', name: 'All Calls'
          },
          {
            attr: 'output', actionType: 'Disconnect', label: 'Disconnect', name: 'Disconnect'
          }];
          if (extension.route) {
            sorted = Visualizer.sortFilters(extension.route.rules);
          }
          exists = this.addDefaultBlock(sorted, sceneLength, extension, outputID, lastBlock);
          sceLen++;
        }

        const outputs = exists ? this.blocks[this.blocks.length - 1].fields.filter((item) => item.type) : [];
        const block = this.scene.blocks[this.scene.blocks.length - 1];
        await this.configureBlocks(outputs, sceLen, block);
        if ((exists || (extension.route && extension.route.id && extension.route.name)) && block) {
          this.alignBlocks(block);
        }
      },
      async startRoute(response) {
        let sceLen = this.scene.blocks.length;
        const sorted = Visualizer.sortFilters(response.route.rules);

        if (response.route.id && response.route.name) {
          response.route = await this.addPresetBlock(response, sceLen);
          sceLen += 2;
        }
        if (!sceLen) {
          this.addDefaultBlock(sorted, sceLen);
          sceLen++;
        }

        const outputs = this.blocks[this.blocks.length - 1].fields.filter((item) => item.type);
        const block = this.scene.blocks[this.scene.blocks.length - 1];
        await this.configureBlocks(outputs, sceLen, block);
        this.alignBlocks(block);
        this.alignObjects(this.startingX);
      },
      alignObjects(amount) {
        if (this.scene.blocks.length > 0) {
          const alignBlocks = this.scene.blocks.filter((b) => b.x === amount);
          if (alignBlocks.length) {
            let total = 0;
            for (const block of alignBlocks) {
              total += block.height;
            }

            if (total > this.maxHeight) {
              this.maxHeight = total;
            }

            let newY = (total / 2) * (-1);
            for (const block of alignBlocks) {
              block.y = newY;
              newY += block.height;
            }

            return this.alignObjects(amount + this.distanceBetween);
          }
          this.maxWidth = (this.scene.blocks.length > 2) ? (amount - this.distanceBetween) : this.scene.blocks[0].x;
        }
        return true;
      },
      async configureBlocks(outputs, sceLen, block) {
        for (const output of outputs) {
          if (output.type === 'extension') {
            const extension = this.userExtensions.items.find((ext) => ext.id === output.extension_id);
            await this.secondStartRoute(extension, sceLen, output.id, block);
          } else if (output.type === 'menu') {
            await this.addMenu(output.menu_id, sceLen, [], {slot: output.id, target: block.id});
          }
        }
      },
      async addMenu(menuID, sceneLength, outputs, promise = false) {
        let menu = this.menus.find((m) => m.id === menuID);
        let sceLen = sceneLength;
        if (!menu) {
          menu = await this.$session.get_item(`/menus/${menuID}`);
          this.menus.push(menu);
        }

        const fields = [];
        const promises = [];
        let options = [];

        if (menu && (menu.options.length || (menu.timeout_handler && menu.timeout_handler.rules.length))) {
          options = menu.options.filter((o) => parseInt(o.key) > 0 && parseInt(o.key) <= 9);
          if (menu.options.find((o) => parseInt(o.key) === 0)) {
            options.push(menu.options.find((o) => parseInt(o.key) === 0));
          }
          if (menu && menu.timeout_handler && menu.timeout_handler) {
            if (menu.timeout_handler.rules && menu.timeout_handler.rules.length) {
              const {timeout_handler} = menu;
              timeout_handler.rules = timeout_handler.rules.slice(0, 1);
              options.push({
                route: timeout_handler, key: '-', expand: true, actionName: 'Timeout Handler'
              });
            }
          }
          if (menu.options.find((o) => o.key === '#')) {
            options.push(menu.options.find((o) => o.key === '#'));
          }
          let index = 0;

          for (const option of options) {
            if (option && option.route && !option.route.rules && option.route.id) {
              const response = await this.$session.get_item(`/routes/${option.route.id}`);
              option.route.rules = response.rules;
            }

            let flagFields = [];
            const sceneOutputs = Visualizer.getBlockOutputs(Visualizer.sortFilters(option.route.rules));
            if (sceneOutputs.length > 2 || option.route.name) {
              flagFields = sceneOutputs;
            } else {
              flagFields = sceneOutputs.filter((field) => !field.filter);
            }

            let name = '';
            if (option.route.name) {
              name = 'Preset';
            } else if (flagFields.length > 1) {
              name = 'Call flow';
            } else {
              name = flagFields[0].actionName || '';
            }

            fields.push({
              key: `${option.key}`, actionName: name, actionType: flagFields[0].actionType, name: `${option.key}`, attr: 'output'
            });
            if (flagFields.filter((f) => f.type).length || flagFields.length > 2 || (option.route.name && option.route.id)) {
              promises.push({
                route: option.route,
                index,
                name,
                flagFields
              });
            }
            index++;
          }
        }

        const color1 = this.colors.find((x) => x.name === 'menu');
        const blockName = this.addFirstBlock('Menu', menu.name, color1, fields.length ? fields : outputs, menu.id);
        const flag1 = this.addSceneBlock('Menu', menu.name, color1, sceLen, menu.id, blockName);

        if (!flag1) {
          sceLen++;
          this.addSceneLink(promise ? promise.slot : 0, flag1, promise ? promise.target : this.scene.blocks.length);
          const lastSceneBlock = flag1 || this.scene.blocks[this.scene.blocks.length - 1];
          await this.listPromises(promises, sceLen, lastSceneBlock);
          this.alignBlocks(lastSceneBlock);
        }
      },
      async listPromises(promises, sceLen, lastSceneBlock) {
        for (const p of promises) {
          let title = '';
          let color = null;
          let extension = null;
          let blockName = '';

          if (p.name === 'Preset') {
            title = 'Preset';
            blockName = p.route.name;
            color = this.colors.find((c) => c.name === 'preset');
          } else if (p.name === 'Call flow') {
            title = `Call flow - ${this.callFlowBlockCounter}`;
            blockName = `Call flow - ${this.callFlowBlockCounter}`;
            color = this.colors.find((c) => c.name === 'Call flow');
            this.callFlowBlockCounter++;
          } else if (p.flagFields[0].extension_id) {
            title = 'extension';
            extension = this.userExtensions.items.find((ext) => ext.id === p.flagFields[0].extension_id);
            color = this.colors.find((c) => c.name === title);
          } else if (p.flagFields[0].menu_id) {
            title = 'menu';
          }

          if (title === 'extension' && extension) {
            await this.secondStartRoute(extension, sceLen, p.index, lastSceneBlock);
          } else if (title === 'menu') {
            await this.addMenu(p.flagFields[0].menu_id, sceLen, p.flagFields, {slot: p.index, target: lastSceneBlock.id});
          } else if (title !== 'extension' && title !== 'menu') {
            const blockName1 = this.addFirstBlock(title, blockName, color, p.flagFields, color.name === 'preset' ? p.route.id : -1);
            const flag2 = this.addSceneBlock(title, blockName, color, sceLen, color.name === 'preset' ? p.route.id : -1, blockName1);
            this.addSceneLink(p.index, flag2 || {id: this.scene.blocks.length}, lastSceneBlock.id);

            const typeOutputs = this.blocks[this.blocks.length - 1].fields.filter((item) => item.type);
            const block = this.scene.blocks[this.scene.blocks.length - 1];
            await this.configureBlocks(typeOutputs, sceLen + 1, block);
          }
        }
      },
      alignBlocks(lastSceneBlock) {
        let i = 0;
        const alignBlocks = [];
        const flagLinks = this.scene.links.filter((link) => link.originID === lastSceneBlock.id);
        const links = flagLinks.filter((link, linkIndex) => linkIndex === flagLinks.findIndex((o) => link.id === o.id));
        links.sort((a, b) => a.originSlot - b.originSlot);

        for (const link of links) {
          const sceneBlock = this.scene.blocks.find((sBlock) => sBlock.id === link.targetID && sBlock.x === lastSceneBlock.x + this.distanceBetween);
          if (sceneBlock && !alignBlocks.find((b) => b === sceneBlock)) {
            alignBlocks.push(sceneBlock);
          }
        }

        let total = 0;
        let difference1 = 0;
        let difference2 = 0;
        let difference_y1 = 0;
        let difference_y2 = 0;
        for (const block of alignBlocks) {
          total += (block.outputsAmount - 1) * 21;
          if (alignBlocks.length <= 4) {
            if (block.objectType === 'Call flow') {
              total += 50;
            } else if (block.objectType === 'menu') {
              total += 60;
            } else {
              total += 70;
            }
          }
          if (block.outputsAmount - 1 === 2) {
            total += 15;
          }
          if (alignBlocks.length > 4) {
            total += 10;
          }
        }

        let newY = lastSceneBlock.y;
        if (alignBlocks.length > 4 && lastSceneBlock.height < total) {
          this.stagger = true;
          difference_y1 = alignBlocks[0].y;
          difference_y2 = alignBlocks[alignBlocks.length - 1].y + alignBlocks[alignBlocks.length - 1].height;
          if (difference_y1 < 0 && difference_y2 < 0) {
            difference1 = difference_y1 * (-1) - difference_y2 * (-1);
          } else if (difference_y1 < 0) {
            difference1 = difference_y2 + difference_y1 * (-1);
          } else {
            difference1 = difference_y2 - difference_y1;
          }

          for (const block of alignBlocks) {
            block.y = newY;
            newY += (block.outputsAmount - 1) * 21;
            if (alignBlocks.length <= 4) {
              if (block.objectType === 'Call flow') {
                newY += 50;
              } else if (block.objectType === 'menu') {
                newY += 60;
              } else {
                newY += 70;
              }
            }
            if (block.outputsAmount - 1 === 2) {
              newY += 15;
            }
            // if (alignBlocks.length > 4) {
            //   newY += 10;
            // }
            if (i % 2 !== 0 && alignBlocks.length > 4 && lastSceneBlock.height < total) {
              block.x += this.distanceBetween;
            }
            i++;
          }
          difference_y1 = alignBlocks[0].y;
          difference_y2 = alignBlocks[alignBlocks.length - 1].y + alignBlocks[alignBlocks.length - 1].height;
          if (difference_y1 < 0 && difference_y2 < 0) {
            difference2 = difference_y1 * (-1) - difference_y2 * (-1);
          } else if (difference_y1 < 0) {
            difference2 = difference_y2 + difference_y1 * (-1);
          } else {
            difference2 = difference_y2 - difference_y1;
          }
        }

        if (alignBlocks.length > 4 && lastSceneBlock.height < total) {
          for (const block of this.scene.blocks.filter((b) => b.id > lastSceneBlock.id && b.id && !links.find((li) => li.targetID === b.id))) {
            block.x += this.distanceBetween;
            block.y -= (difference1 - difference2);
            // block.fields.length * 21 + (family.name === 'Call flow' ? 30 : 60),
          }
        }

        if (alignBlocks.length > 1) {
          const first = alignBlocks[0].y;
          const last = alignBlocks[alignBlocks.length - 1].y + alignBlocks[alignBlocks.length - 1].height;
          total = 0;
          const blockFirst = lastSceneBlock.y;
          const blockLast = lastSceneBlock.y + lastSceneBlock.height;
          let blockTotal = 0;
          if (first < 0 && last < 0) {
            total = first * (-1) - last * (-1);
          } else if (first < 0) {
            total = last + first * (-1);
          } else {
            total = last - first;
          }
          if (blockFirst < 0 && blockLast < 0) {
            blockTotal = blockFirst * (-1) - blockLast * (-1);
          } else if (blockFirst < 0) {
            blockTotal = blockLast + blockFirst * (-1);
          } else {
            blockTotal = blockLast - blockFirst;
          }
          lastSceneBlock.y += total / 2 - blockTotal / 2;
        }
      },
      addFirstBlock(title, name, family, outputs, actionID = -1) {
        const found = this.blocks.find((b) => b.name === name && b.title === title);
        if (found) return found.title;

        let fields = [];
        if (outputs.find((output) => output.actions)) {
          fields = Visualizer.getBlockOutputs(outputs);
        } else {
          fields = outputs || [];
        }
        if (this.blocks.length) fields.push({name: '', attr: 'input'});

        this.blocks.push({
          color: family ? family.color : 'rgba(244, 245, 246, 1)', // rgba(133, 146, 154, 0.25)
          objectType: family ? family.name : 'number',
          title: title.toLowerCase().includes('call flow -') ? 'Call flow' : title,
          actionID,
          name,
          fields
        });

        return '';
      },
      addSceneBlock(title, name, family, sceLen = 0, actionID = -1, blockName = '') {
        // if the scene block can be found already existing just return the scene block
        const found = this.scene.blocks.find((b) => (b.name === name && b.title === title) || (b.name === blockName && b.title === title));
        if (found) return found;

        this.checkCollision(sceLen);
        const block = this.blocks.find((b) => b.name === name && b.title === (title.toLowerCase().includes('call flow -') ? 'Call flow' : title));

        this.scene.blocks.push({
          id: this.scene.blocks.length + 1,
          color: family ? family.color : 'rgba(244, 245, 246, 1)',
          objectType: family ? family.name : 'number',
          title: title.toLowerCase().includes('call flow -') ? 'Call flow' : title,
          actionID,
          name,
          x: this.startingX + sceLen * this.distanceBetween,
          y: this.yy,
          height: block.fields.length * 21 + (family.name === 'Call flow' ? 30 : 60),
          outputsAmount: block ? block.fields.length : 0,
        });

        // return false that the block can't be found
        return false;
      },
      addSceneLink(originSlot, target = null, originID = null) {
        this.scene.links.push({
          id: this.scene.links.length + 1,
          originID: originID || this.scene.blocks.length - 2,
          originSlot: parseInt(originSlot),
          targetID: target ? target.id : this.scene.blocks.length,
          targetSlot: 0
        });

        // console.log(`originID: ${originID || this.scene.blocks.length - 2} - ${originSlot}, TargetId: ${target ? target.id : this.scene.blocks.length}`);
        return target ? target.id : this.scene.blocks.length + 1;
      },
      checkCollision(sceLen) {
        const collide = this.scene.blocks.find((b) => b.x === (this.startingX + sceLen * this.distanceBetween) && (b.y - 10) <= this.yy && b.y + b.height >= this.yy);
        if (collide) {
          this.yy = collide.y + collide.height + 2;
          this.checkCollision(sceLen);
        }
      },
      customFilter(item, queryText, itemText) {
        const str = queryText.toLowerCase().replace(/[^a-zA-Z0-9]/g, '');
        const str2 = itemText.toLowerCase().replace(/[^a-zA-Z0-9]/g, '');
        return str2.includes(str) || str2.includes(str);
      },
      deselectBlock() {
        this.selectedBlock = null;
      },
      blockRightClick() {
        // console.log(block);
      },
      openModal(event) {
        if (event.actionID >= 0) {
          this.actionID = event.actionID;
          this.modal_type = event.modal_type;
          this.show_modal = true;
        }
      },
      selectBlock(block) {
        // console.log('select', block.x, block.y, block.id, block);
        this.selectedBlock = block;
      },
    },
    watch: {
      chosenItem() {
        this.changeMapOption(this.chosenItem.phone_number ? 'number' : 'extension');
      },
      show_modal() {
        this.$data.modal_key = Date.now();
      }
    }
  };
</script>

<style lang='scss' scoped>
.visualizerClass {
  height: 100%;
  margin-top: -15px;
  // font-style: normal;
}
.container-fluid {
  position: fixed;
  top: 65px;
  left: 222px;
  height: 100%;
  width: 100%;
  flex-grow: 1;
  margin-top: -8px;
  @media only screen and (max-width: 961px) {
    left: 0px;
  }
}
.loader {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 1 !important;
  background: transparent !important;
}
.loader > div {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  background: transparent !important;
}
.callFlowHeading {
  font-weight: 600;
  margin-top: -4px;
  font-size: 10px;
  display: flex;
  align-items: center;
  letter-spacing: 0.4px;
  text-transform: uppercase;
  color: #197F9F;
}
.callFlowCol {
  display: flex;
  align-items: center;
}
.speedy-container {
  display: flex;
  flex-direction: column;
  margin-left: 28px;
}
@media only screen and (max-width: 1400px) {
  .speedy-container {
    margin-left: -26px;
  }
}
.top-row {
  flex-shrink: 0;
  background-color: white;
  width: max-content;
  z-index: 2;
  border-radius: 9px;
  box-shadow: 0 0px 1px 0 rgba(0, 0, 0, 0.15), 0 1px 5px 0 rgba(0, 0, 0, 0.15);
}
.typeObjCol {
  max-width: 200px;
}
.chosenItemCol {
  max-width: 220px;
}
.backButton {
  margin-top: -4px;
  font-weight: 600 !important;
  font-size: 10px !important;
  display: flex;
  align-items: center;
  letter-spacing: 0.4px !important;
  text-transform: uppercase !important;
  color: #197F9F !important;
}
.icons {
  position: fixed !important;
  color: #197F9F !important;
  cursor: pointer;
  z-index: 2;
  outline: none !important;
  box-shadow: none !important;
}
.plus-icon {
  right: 20px;
  bottom: 75px;
}
.minus-icon {
  right: 20px;
  bottom: 27px;
}
.left-icon {
  right: 141px;
  bottom: 51px;
}
.up-icon {
  right: 105px;
  bottom: 87px;
}
.down-icon {
  right: 105px;
  bottom: 15px;
}
.right-icon {
  right: 69px;
  bottom: 51px;
}
.center-icon {
  right: 105px;
  bottom: 51px;
}
.visualizer-info {
  width: 175px;
  right: 13px;
  height: 117px;
  position: fixed !important;
  bottom: 13px;
  box-shadow: 0 0px 1px 0 rgba(0, 0, 0, 0.15), 0 1px 5px 0 rgba(0, 0, 0, 0.15);
  background-color: white;
  z-index: 2;
  border-radius: 9px;
  padding: 8px;
}
</style>
