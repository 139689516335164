<template>
    <div class="list-page payment-methods" data-test-id="billing-payment-list-page">
        <w-loader v-if="billing.loading"/>
        <div v-else>
            <w-alert v-if="billing.alert" :message="billing.alert.message" :level="billing.alert.level" :closable="billing.enable_close_alert" @closed="billing.alert = null" class="mb-3"/>
            <section v-if="billing.payment_methods && billing.payment_methods.length" id="payment-methods">
                <div>
                    <v-simple-table class="zebra" data-test-id="paymet-methods-table">
                        <thead data-test-id="table-head">
                        <tr data-test-id="head-row">
                            <th style="width: 10%;" colspan="3" data-test-id="status">{{ l.t('app.status', 'Status') }}</th>
                            <th style="width: 30%;" data-test-id="nickname">{{ l.t('app.nickname', 'Nickname') }}</th>
                            <th style="width: 10%;" data-test-id="type">{{ l.t('app.type', 'Type') }}</th>
                            <th style="width: 50%;" colspan="2" data-test-id="details">{{ l.t('app.details', 'Details') }}</th>
                        </tr>
                        </thead>
                        <tbody data-test-id="table-body">
                        <tr
                            v-for="(pm, i) of billing.payment_methods"
                            v-bind:key="pm.id"
                            :class="{
                                'grey--text': pm.status === 'hidden',
                                'primary--text': pm.status === 'primary'
                            }"
                            :data-test-id="i"
                        >
                            <td v-if="pm.status !== 'primary'" data-test-id="status">
                                <v-icon
                                    @click="show_modal(pm.id, 'primary')"
                                    :title="l.t('billing.make-primary', 'Make primary')"
                                    color="primary"
                                    :data-test-id="`pm-item-make-primary-${i}-modal-btn`"
                                >mdi-check-circle</v-icon>
                                <ConfirmationModal
                                    v-if="confirmation_modal_id === pm.id && confirmation_modal_type === 'primary'"
                                    @confirmed="billing.setStatus(pm.id, 'primary')"
                                    @close="hide_modal"
                                    :message="l.t('payment-methods.are-you-sure-set-status', 'Are you sure that you want to set status {} for this payment method?', ['primary'])"
                                    :data-test-id="`pm-item-make-primary-${i}-modal`"
                                />
                            </td>
                            <td v-else data-test-id="status"><br/></td>
                            <td :data-test-id="`pm-item-status-${i}`">{{ pm.status }}</td>
                            <td v-if="pm.status === 'hidden'" data-test-id="status-hidden">
                                <v-icon
                                    @click="show_modal(pm.id, 'onfile')"
                                    :title="l.t('billing.restore', 'Restore')"
                                    :data-test-id="`pm-item-restore-${i}-modal-btn`"
                                >mdi-arrow-up-circle</v-icon>
                                <ConfirmationModal
                                    v-if="confirmation_modal_id === pm.id && confirmation_modal_type === 'onfile'"
                                    @confirmed="billing.setStatus(pm.id, 'onfile')"
                                    @close="hide_modal"
                                    :message="l.t('payment-methods.are-you-sure-set-status', 'Are you sure that you want to set status {} for this payment method?', ['onfile'])"
                                    :data-test-id="`pm-item-restore-${i}-modal`"
                                />
                            </td>
                            <td
                                v-else-if="(pm.status === 'onfile') || !billing.payment_methods.filter(v => v.status === 'onfile').length"
                                data-test-id="status-onfile"
                            >
                                <v-icon
                                    @click="show_modal(pm.id, 'hidden')"
                                    :title="l.t('app.delete', 'Delete')"
                                    :data-test-id="`pm-item-delete-${i}-modal-btn`"
                                >mdi-close-circle</v-icon>
                                <ConfirmationModal
                                    v-if="confirmation_modal_id === pm.id && confirmation_modal_type === 'hidden'"
                                    @confirmed="billing.setStatus(pm.id, 'hidden')"
                                    @close="hide_modal"
                                    :message="l.t('payment-methods.are-you-sure-set-status', 'Are you sure that you want to set status {} for this payment method?', ['hidden'])"
                                    :data-test-id="`pm-item-delete-${i}-modal`"
                                />
                            </td>
                            <td v-else><br/></td>
                            <td :data-test-id="`pm-item-nick-${i}`">{{ pm.nickname || '—'}}</td>
                            <td :data-test-id="`pm-item-type-${i}`">{{ pm.type }}</td>
                            <template v-if="pm.type === 'cc'">
                                <td :data-test-id="`pm-item-cc-${i}`">{{ pm.cc_number }}</td>
                                <td :data-test-id="`pm-item-cc-formated-${i}`">{{ pm.cc_exp.slice(0, 2) }}/{{ pm.cc_exp.slice(2) }}</td>
                            </template>
                            <td v-else colspan="2"><br/></td>
                        </tr>
                        </tbody>
                    </v-simple-table>
                </div>
            </section>
            <w-alert v-else :message="l.t('app.no-results-found', 'No results found')" level="error" :closable="false" class="mt-3"/>
        </div>
    </div>
</template>

<script>
import l from '../../../libs/lang';
import ConfirmationModal from '../../elements/modal/ConfirmationModal.vue';

export default {
    components: {
        ConfirmationModal,
    },
    props: ['billing'],
    data() {
      return {
        l,
        confirmation_modal_id: null,
        confirmation_modal_type: null,
      };
    },
    async created() {
        await this.$props.billing.getPaymentMethods();
    },
    methods: {
        show_modal(id, type) {
            this.$data.confirmation_modal_id = id;
            this.$data.confirmation_modal_type = type;
        },
        hide_modal() {
            this.$data.confirmation_modal_id = null;
            this.$data.confirmation_modal_type = null;
        },
    }
};
</script>
