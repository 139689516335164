<template>
    <v-file-input
        v-bind="$attrs"
        color="primary"
        v-on="$listeners"
        @change="on_change"
        class="custom-file-input"
        hide-details="auto"
    >
        <!-- Pass on all named slots -->
        <slot v-for="slot in Object.keys($slots)" :name="slot" :slot="slot" />
        <!-- Pass on all scoped slots -->
        <template v-for="slot in Object.keys($scopedSlots)" :slot="slot" slot-scope="scope">
            <slot :name="slot" v-bind="scope" />
        </template>
    </v-file-input>
</template>
<script>
import EventBus from '../../../libs/EventBus';

export default {
    methods: {
        on_change(e) {
            EventBus.$emit('form_input_changed');
            this.$emit('change', e);
        }
    },
};
</script>
