<template>
    <w-loader v-if="loading" size="medium"/>
    <div v-else>
        <w-alert v-if="alert" :message="alert.message" :level="alert.level"/>
        <p data-test-id="make-call-help-text">
            {{ l.t("make-call.help-text", 'When you click the Make a call button, we call your phone number first. When you answer, we call the number you wanted to call.') }}
            <template v-if="$props.caller_id">
              <br>
              {{ l.t('phone-numbers.call-id-will-be-used-for-both', 'The phone number {} will be used as caller id for both sides, the caller and the called person.', [format_phone_number($props.caller_id)]) }}
            </template>
        </p>
        <w-form @changed="may_submit = true" @submit="call">
          <FormInput v-if="!auto_selected_extension" :label="l.t('app.extension', 'Extension')">
            <APIAutocomplete
              v-model="caller_extension"
              mode="extensions"
              key="extensions"
              data-test-id="make-call-ext-selector"
            />
          </FormInput>
          <FormInput :label="l.t('make-call.first-number', 'Your phone number')">
              <Tel
                :value="caller_phone_number"
                @changed="caller_phone_number = $event.clean_number"
                :required="true"
                data-test-id="make-call-tel-caller-input"
              />
          </FormInput>
          <FormInput :label="l.t('make-call.second-number', 'The number you want to call')">
            <Tel
              :value="callee_phone_number"
              @changed="callee_phone_number = $event.clean_number"
              :required="true"
              data-test-id="make-call-tel-callee-input"
            />
          </FormInput>
          <FormInput>
            <w-btn :disabled="!may_submit" color="primary" type="submit" data-test-id="make-call-submit">{{ l.t("make-call.make-a-call", "Make a call") }}</w-btn>
          </FormInput>
        </w-form>
    </div>
</template>

<script>
  import l from '../../libs/lang';
  import Helpers from '../../libs/helpers';
  import Cachier from '../../libs/Cachier';
  import Tel from '../elements/Tel.vue';
  import FormInput from '../elements/form/FormInput.vue';
  import APIAutocomplete from '../elements/form/APIAutocomplete.vue';

  export default {
    props: ['id', '_route', 'caller_id'],
    components: {
      Tel,
      FormInput,
      APIAutocomplete,
    },
    data() {
      const make_call_cache_key = 'make_call';
      const cachier = new Cachier(this.$session.user.id);
      const data = cachier.getItem(make_call_cache_key);
      return {
        l,
        loading: false,
        alert: null,
        make_call_cache_key,
        cachier,
        caller_phone_number: data ? data['caller_phone_number'] : '',
        callee_phone_number: '',
        caller_extension: data ? data['caller_extension'] : '',
        auto_selected_extension: false,
        may_submit: false,
      };
    },
    created() {
    },
    methods: {
      call(e) {
        if (e) e.preventDefault();
        this.$data.loading = true;
        const data = {
          caller_phone_number: this.$data.caller_phone_number,
          callee_phone_number: this.$data.callee_phone_number,
          caller_extension: this.$data.caller_extension,
        };
        if (this.$props.caller_id) {
          data['callee_caller_id'] = this.$props.caller_id;
          data['caller_caller_id'] = this.$props.caller_id;
        }
        this.$session
          .create_item('/calls', data)
          .then(() => {
            this.cachier.setItem(this.$data.make_call_cache_key, {
                caller_phone_number: this.$data.caller_phone_number,
                caller_extension: this.$data.caller_extension,
              });
            this.$data.alert = {
              message: l.t('make-call.call-initiated', 'The call was initiated'),
              level: 'success',
            };
            Helpers.hide_alert.call(this, 5);
            this.$data.loading = false;
          })
          .catch(Helpers.show_generic_error.bind(this));
      },
      format_phone_number(number) {
        if (!number) return null;
        return Helpers.format_phone_number(number);
      }
    },
  };
</script>

<style scoped></style>
