<template>
  <div v-bind="$attrs">
    <div @click="on_title_click" class="collapsable-title d-flex justify-space-between cursor-pointer">
        <div class="w-100">
            <slot name="title"/>
        </div>
        <ExpandButton v-if="!force_open" :value="open" :clickable="false" :key="open"/>
    </div>
    <div v-if="open" class="collapsable-content" ref="content">
        <slot/>
    </div>
  </div>
</template>
<script>
  import Helpers from '../../libs/helpers';
  import ExpandButton from './ExpandButton.vue';

  export default {
    props: {
        value: {
            type: Boolean,
        },
        unique_key: {
            required: false,
        },
        force_open: {
            type: Boolean,
            default: false,
        }
    },
    components: {
        ExpandButton,
    },
    data() {
        return {
            open: this.$props.force_open || !!this.$props.value,
            random_key: this.$props.unique_key || Helpers.randomNumber(),
        };
    },
    created() {
        this.$global_emitter.$on('collapsable-opened', (key) => {
            if (!this.$props.force_open && key !== this.$data.random_key) this.$data.open = false;
        });
    },
    methods: {
        on_title_click() {
            if (!this.$props.force_open) this.$data.open = !this.$data.open;
        },
    },
    watch: {
        open(v) {
            if (v) this.$global_emitter.$emit('collapsable-opened', this.$data.random_key);
        }
    },
  };
</script>

<style scoped lang="scss">
.collapsable-title {
    padding: 13.5px 0;
}
.collapsable-content {
    animation-duration: 0.5s;
    animation-name: animate-opacity;
  }

  @keyframes animate-opacity {
    from {
        transform: translateY(-4%);
        opacity: 0;
    }
    to {
        transform: translateY(0);
        opacity: 1;
    }
  }
</style>
