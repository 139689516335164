var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"subaccounts"},[_c('Submenu',{key:`${_vm.l.detect()}${_vm.$route.name}`,attrs:{"routes":{
      'subaccounts.index': {
        title: _vm.l.t('app.subaccounts', 'Subaccounts')
        },
      'subaccounts.create': {
        title: _vm.l.t('subaccounts.add-subaccount','Add subaccount')
        },
    }}}),(_vm.$route.name === 'subaccounts.index')?_c('SubaccountsFragment',{attrs:{"data-test-id":"subaccounts-index"}}):_vm._e(),(_vm.$route.name === 'subaccounts.create')?_c('SubaccountsCreateFragment',{attrs:{"data-test-id":"subaccounts-create"}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }