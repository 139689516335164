import l from '../libs/lang';
import Helpers from '../libs/helpers';
import SmartFilters from './SmartFilters';
import setup from '../libs/resources-setups/messages';
import CsvItemsDownloader from '../libs/csv-helpers/ItemsDownloader';

export default class Message extends SmartFilters {
    constructor(session, emitter, changeRoute, forceUpdate) {
        super(session, emitter, '/messages', changeRoute);
        this.setup = setup;
        this.forceUpdate = forceUpdate;
        this.filters = {
            type: 'forever',
            deleted: false,
            direction: null,
            read: false,
            from: null,
            to: null,
        };
        this.apply_type_range();
        this.config_key = 'messages-filters';
        this.empty_filters = JSON.parse(JSON.stringify(this.filters));
        this.headers = {
            id: l.t('sms.message-id', 'Message ID'),
            extension: l.t('app.extension', 'Extension'),
            conversation_id: l.t('app.conversation-id', 'Conversation ID'),
            from: l.t('app.from', 'From'),
            to: l.t('app.to', 'To'),
            text: l.t('app.text', 'Text'),
            direction: l.t('app.direction', 'Direction'),
            status: l.t('sms.delivery-status', 'Delivery status'),
            type: l.t('app.type', 'Type'),
            sent_at: l.t('app.sent-at', 'Sent at'),
            read_at: l.t('app.read-at', 'Read at'),
        };
        this.functions = {
            extension: (item) => {
                if (!item['extension']) return '—';
                return item['extension'].extension;
            },
            from: (item) => Helpers.format_phone_number(item['from']).replace(/-/g, ''),
            to: (item) => Helpers.format_phone_number(item['to']).replace(/-/g, ''),
            text: (item) => `${item['text'].replace(/,/g, ' ')}`,
            sent_at: (item) => Helpers.format_time(item['sent_at']).replace(',', ' '),
            read_at: (item) => Helpers.format_time(item['read_at']).replace(',', ' '),
        };

        this.request_all_extensions = 'request_all_extensions=1';
        this.deleting_item_id = null;
    }

    // because account level messages have to include ?request_all_extensions=1
    // set extension(val) {
    //     console.log(val);
    //     this._extension = val;
    //     this.resetDeleting();
    //     this.keep_filters_open = false;
    //     this.apply_filters(); // this will change the URI based on baseUri
    //     return this._extension;
    // }

    async loadItems(params) {
        this.loading = true;
        try {
            const offset = this.checkIfUserDeletedItems(params.offset, params.page);
            let { uri } = this;
            if (!this._extension && !uri.includes(this.request_all_extensions)) {
                uri = `${uri}?${this.request_all_extensions}`;
            }
            const items = await this.session.get_list(uri, params.limit, offset);
            this.page = params.page;
            if (this.page > 1 && !items.items.length) {
                this.emitter('pageChanged', 1);
                return this.loadItems({
                    limit: this.limit,
                    offset: 0,
                    page: 1,
                });
            }
            this.items = items.items;
            this.finalizeLoadingItems(items);
            this.emitter('itemsLoaded', JSON.parse(JSON.stringify(items)));
        } catch (err) {
            this.validation_error(err);
        }
        this.loading = false;

        return this.items;
    }

    apply_filters() {
        this.filters_applied = true;
        let uri = this.baseUri;
        if (!this._extension && !uri.includes(this.request_all_extensions)) {
            uri = `${uri}?${this.request_all_extensions}`;
        }

        uri += uri.includes('?') ? '&' : '?';
        uri += 'mode=brief';

        if (this.filters.start) {
            const start = Math.floor(Date.parse(this.filters.start) / 1000);
            uri += `&filters[created_after]=${start}`;
        }
        if (this.filters.end) {
            const end = Math.floor(Date.parse(this.filters.end) / 1000);
            uri += `&filters[created_before]=${end}`;
        }
        if (this.filters.deleted) {
            uri = `${uri}&filters[deleted]=only`;
        }
        if (this.filters.read) {
            uri = `${uri}&filters[read]=only`;
        }
        if (this.filters.from) {
            uri = `${uri}&filters[from]=${this.filters.from}`;
        }
        if (this.filters.to) {
            uri = `${uri}&filters[to]=${this.filters.to}`;
        }
        if (this.filters.direction) {
            uri = `${uri}&filters[direction]=${this.filters.direction}`;
        }
        this.uri = uri;
        this.hide_filters = false;
    }

    async deleteAll() {
        let { uri } = this;
        if (!this._extension && !uri.includes(this.request_all_extensions)) {
            uri = `${uri}?${this.request_all_extensions}`;
        }
        await super.deleteAll(uri);
    }

    async delete_m(id) {
        this.deleting_item_id = id;
        try {
            await this.session.delete_item(`${this.baseUri}/${id}`);
            this.items = this.items.filter((v) => v['id'] !== id);
        } catch (err) {
            this.validation_error(err);
        }
        this.deleting_item_id = null;
    }

    readMore(index) {
        this.items[index].read_all = true;
        this.forceUpdate();
    }

    readLess(index) {
        this.items[index].read_all = false;
        this.forceUpdate();
    }

    static prepareMessages(items) {
        const messages = [];
        for (const item of items) {
            for (const to of item.to) {
                const newItem = item;
                newItem.to = to.number;
                newItem.sent_at = to.sent_at;
                newItem.status = to.delivery_status;
                messages.push(newItem);
            }
        }

        return messages;
    }

    async generate_csv() {
        this.csv_downloader = new CsvItemsDownloader(this.session);
        this.emitter('hidePagination');
        try {
            let { uri } = this;
            if (!this._extension && !uri.includes(this.request_all_extensions)) {
                uri = `${uri}?${this.request_all_extensions}`;
            }
            const res = await this.csv_downloader.get_list_all(this.uri);
            if (res === 'aborted') {
                this.csv_downloader = null;
                return null;
            }
            if (!this.csv_downloader.stop) {
                let messages = Message.prepareMessages(res.items);
                messages = await this.attachExtension(messages);
                this.constructor.download_csv(
                    this.build_csv(messages),
                    'messages-report.csv'
                );
                this.csv_downloaded_successfully();
            }
        } catch (err) {
            this.validation_error(err);
        }
        this.csv_downloader = null;
        this.emitter('showPagination');

        return true;
    }

    async attachExtension(messages) {
        let extensions = [...new Set(messages.map((x) => x.extension_id))];
        extensions = await this.session.get_list_all(`/extensions?filters[id]=in:${extensions.join(',')}`);
        extensions.items.map((x) => {
            for (const message of messages) {
                if (message.extension_id === x.id) message.extension = x;
            }

            return x;
        });

        return messages;
    }
}
