<template>
    <w-loader v-if="extension.loading"/>
    <div v-else class="extension show-page" data-test-id="extensions-show-page">
        <div v-if="($route.name === 'extensions.call-flow' || $route.name === 'extensions.visualizer' || $route.name === 'phone-numbers.visualizer')" class="w-h6 info--text text--darken-1 mb-3">
          {{title()}}
        </div>
        <PageTitle v-else class="nowrap">
          {{title()}}
        </PageTitle>
        <w-alert
          v-if="extension.alert"
          :message="extension.alert.message"
          :level="extension.alert.level"
          :closable="extension.enable_close_alert"
          @closed="extension.alert = null"
          class="mb-6"
        />
        <CallHandlingRules
          v-if="($route.name === 'extensions.call-flow' || $route.name === 'extensions.visualizer' || $route.name === 'phone-numbers.visualizer') && extension.item"
          :_route="extension.item.route"
          :_extension="extension.item"
          :id="extension.item.route ? extension.item.route.id : null"
          :presave_cb="chr_presave_cb"
          @updated="on_route_update"
          data-test-id="extensions-call-flow"
        />
        <ExtensionFragment
          v-if="($route.name === 'extensions.show' || ignore_routes) && extension.item"
          :_extension="extension.item"
          @updateExtension="extension.update($event)"
          data-test-id="extensions-show"
        />
        <ExtensionType
          v-if="($route.name === 'extensions.type') && extension.item"
          :_extension="extension.item"
          @updated="extension.updateType($event)"
          data-test-id="extensions-type"
        />
    </div>
</template>

<script>
  import l from '../../../libs/lang';
  import Helpers from '../../../libs/helpers';
  import Submenu from '../../elements/Submenu.vue';
  import Extension from '../../../models/Extension';
  import PageTitle from '../../elements/PageTitle.vue';
  import ExtensionFragment from '../../fragments/Extension.vue';
  import ExtensionType from '../../fragments/ExtensionType.vue';
  import CallHandlingRules from '../../elements/routes/CallHandlingRules.vue';

  export default {
    props: ['id', '_extension', 'ignore_routes'],
    components: {
      Submenu,
      PageTitle,
      ExtensionType,
      ExtensionFragment,
      CallHandlingRules,
    },
    data() {
      return {
        l,
        alert: null,
        loading: true,
        extension: new Extension(this.$session, Helpers.emitter(this), Helpers.changeRoute(this)),
        last_title: '',
      };
    },
    async created() {
      if (this.$props._extension || this.$route.params._extension) {
        this.$data.extension.item = this.$props._extension || this.$route.params._extension;
      } else {
        await this.extension.getItem(this.$props.id ? this.$props.id : this.$route.params.id);
      }
    },
    methods: {
      async on_route_update(data) {
        console.log(data);
        this.$data.extension.loading = true;
        try {
          if (!this.$data.extension.item.route || (this.$data.extension.item.route && this.$data.extension.item.route.id !== data.id)) {
            console.log('updajtuje');
            this.$data.extension.item.route = await this.$data.extension.update_route(data);
          }
          console.log(2);
          this.$data.extension.successfulUpdate();
        } catch (err) {
          this.$data.extension.validation_error(err);
        }
        this.$data.extension.loading = false;
      },
      async chr_presave_cb() {
          if (this.$data.extension.item && this.$data.extension.item.device_membership && !this.$data.extension.item.bypass_hard_phone) {
            const updated_extension = await this.$session.patch_item(
              `/extensions/${this.$data.extension.item.id}`,
              { bypass_hard_phone: true }
            );
            this.$data.extension.item = updated_extension;
          }
      },
      title() {
        let title = '';
        if (this.$data.extension.item) {
          title += l.t('app.extension', 'Extension');
          title += ` #${this.$data.extension.item.extension} ${this.$data.extension.item.name ? this.$data.extension.item.name : ''}`;
          this.$data.last_title = title;
        } else {
          title = this.$data.last_title;
        }
        return title;
      }
    },
  };
</script>
