import Resource from './Resource';
import Helpers from '../libs/helpers';

export default class Account extends Resource {
	constructor(session, emitter, changeRoute) {
		super(session, emitter, '/', changeRoute);
		this.item = null;
	}

	async createContact(data) {
		this.loading = true;
		let contact = null;
		try {
		  contact = await this.session.create_item('/contacts', data);
		} catch (err) {
		  this.validation_error(err);
		}
		this.loading = false;

		return contact;
	}

	async getItem() {
	  this.loading = true;
	  try {
	    const account = await this.session.get_item('/');
	    this.item = Account.cleanAccountProperties(account);
	  } catch (err) {
	    this.validation_error(err, true);
	  }
	  this.loading = false;
	}

	static takeDifferentFields(original, changed, ignore_ids = false) {
	    if (!changed || !original) return changed;
	    for (const prop of Object.keys(changed)) {
	        if (typeof changed[prop] === 'object' && changed[prop]) {
	            if (ignore_ids) {
	                if (changed[prop] && changed[prop].id) delete changed[prop].id;
	                if (original[prop] && original[prop].id) delete original[prop].id;
	            }
	            if (
	                !ignore_ids
					&& original[prop]
					&& changed[prop]
					&& original[prop]['id']
	                && changed[prop]['id']
	                && original[prop]['id'] === changed[prop]['id']
	            ) {
	                delete changed[prop];
	            } else if (JSON.stringify(original[prop]) === JSON.stringify(changed[prop])) {
	                delete changed[prop];
	            }
	        } else if (changed[prop] === original[prop] && prop !== 'id') {
	            delete changed[prop];
	        }
	    }

	    return changed;
	}

	clean_moh_cache() {
		for (const mode of ['hold_music', 'user_hold_music', 'hold_music_premium']) {
			this.cachier.removeItem(mode);
		}
	}

	async updateSettings(dt) {
		const data = JSON.parse(JSON.stringify(dt));
        const item = {...this.item};
        for (const prop of ['contact', 'billing_contact']) {
			delete data[prop];
			delete item[prop];
        }
        this.loading = true;
		if (item.features && data.features && item.features['premium-music'] !== data.features['premium-music']) {
			this.clean_moh_cache();
		}
		const diff = Account.takeDifferentFields(item, data);
		if (data.features) {
			delete diff.features['call-recording-on'];
		}
		if (diff.features) {
			if (diff.features['premium-music']
				&& diff.features['premium-music'] !== this.item.features['premium-music']
				&& diff.hold_music
				&& diff.hold_music.id
				&& diff.hold_music.id > 11
				&& diff.hold_music.id < 24
			) {
				await this.session.patch_item(this.baseUri, {features: {'premium-music': true}});
			}
			if (
				!this.item.features['call-recording-300']
				&& !this.item.features['call-recording-1000']
				&& diff.record_all_calls
				&& (diff.features['call-recording-300'] || diff.features['call-recording-1000'])) {
					await this.session.patch_item(this.baseUri, {features: diff.features});
			}
		}
        await this.update(diff);
        this.loading = false;
	}

	async update(data) {
        try {
			if (data && data !== null && typeof (data) === 'object' && Object.keys(data).length) {
				const updated_item = await this.session.patch_item(this.baseUri, data);
				this.session.user.account = null;
				this.session.user.account = await Helpers.get_account_information(this.session, true);
				this.item = Account.cleanAccountProperties(updated_item);
				this.emitter('accountUpdated');
			}
			this.successfulUpdate();
			return this.item;
        } catch (err) {
			this.validation_error(err);
        }

        return null;
	}

	async updateContacts(contacts_obj) {
		const contacts_object = Account.leaveOnlyContactsProperties(contacts_obj);
		const item = Account.leaveOnlyContactsProperties({...this.item});
        this.loading = true;
		const diff = Account.takeDifferentFields(item, contacts_object, true);
        await this.update(diff);
        this.loading = false;
	}

	static leaveOnlyContactsProperties(obj) {
		for (const key of Object.keys(obj)) {
		  // eslint-disable-next-line no-continue
		  if (['id', 'contact', 'billing_contact'].includes(key)) continue;
		  delete obj[key];
		}

		return obj;
	}

	static cleanAccountProperties(acc) {
		const account = acc;
		const delete_properties = [
			'id',
			'name',
			'username',
			'password',
			'master_account',
		];
		for (const prop of delete_properties) delete account[prop];
		for (const feature of Object.keys(account.features)) {
			if (!['premium-music', 'call-recording-on', 'call-recording-300', 'call-recording-1000'].includes(feature)) {
				delete account.features[feature];
			}
		}
		return account;
	}
}
