<template>
    <w-loader v-if="device.loading"/>
    <div v-else class="device show-page" data-test-id="devices-show-page">
        <w-alert
          v-if="device.alert"
          :message="device.alert.message"
          :level="device.alert.level"
          :closable="device.enable_close_alert"
          @closed="device.alert = null"
          class="mb-6"
        />
        <DeviceFragment
          v-if="$route.name === 'devices.show' && device.item"
          :_device="device.item"
          @updateDevice="device.update($event)"
        />
    </div>
</template>

<script>
  import l from '../../../libs/lang';
  import Helpers from '../../../libs/helpers';
  import Device from '../../../models/Device';
  import DeviceFragment from '../../fragments/Device.vue';

  export default {
    props: ['id', '_device'],
    components: {
      DeviceFragment,
    },
    data() {
      return {
        l,
        device: new Device(this.$session, Helpers.emitter(this), Helpers.changeRoute(this)),
      };
    },
    async created() {
      try {
        if (this.$props._device || this.$route.params._device) {
          this.$data.device.item = this.$props._device || this.$route.params._device;
        } else {
          this.$data.device.item = await this.$data.device.getItem(this.$route.params.id);
        }
          // eslint-disable-next-line no-nested-ternary
          this.$data.device.item.lines.sort((a, b) => ((a.line > b.line) ? 1 : ((b.line > a.line) ? -1 : 0)));
      } catch (err) {
        console.log(err);
        if (err.status === 404) {
          this.$data.alert = {
            level: 'error',
            message: l.t('devices.cant-manage-device', 'You can\'t manage this device.'),
          };
        } else {
          this.$emit('failed', err);
        }
      }
    },
  };
</script>
