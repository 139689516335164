import l from '../../libs/lang';
import ReportConf from './ReportConf';
import Helpers from '../../libs/helpers';

export default class PerPhoneNumber extends ReportConf {
	constructor(formatters) {
		super([
			'avg_amount',
			'max_amount',
			'total_amount',
			'count_incoming',
			'count_outgoing',
			'total',
		]);
		this.formatters = formatters;
		this.numbers = [];
		this.title = ReportConf.get_title('calls', 'per-number');
		this.group_by_param = 'number';
	}

	get headers() {
		const headers = {
			number: l.t('app.number', 'Number')
		};
		Object.keys(this.fields).map(
			(x) => headers[x] = this.fields[x].translation
		);

		return headers;
	}

	prepare_items(items) {
		this.numbers = [];
		const keys = Object.keys(this.fields);
		const stats = {};
		for (const key of keys) {
			stats[key] = [];
			for (const item of items) {
				const number = Helpers.format_phone_number(item.number);
				this.numbers.push(number);
				stats[key].push({
					number,
					value: item[key],
				});
			}
		}
		this.numbers = [...new Set(this.numbers)];
		return stats;
	}

	prepare_csv_values(items) {
		const numbers = items[Object.keys(items)[0]].map((x) => x.number);
		const data = numbers.map((number) => {
			const data_item = {};
			data_item.number = number.replace(/-/g, '');
			for (const prop of Object.keys(this.fields)) {
				const item = items[prop] ? items[prop].find((x) => x.number === number) : false;
				const value = item
					? this.formatters[this.fields[prop].type](item.value)
					: '/';
				data_item[prop] = value;
			}
			return data_item;
		});

		return data;
	}
}
