<template>
  <div class="list-page conversations" data-test-id="conversations">
    <PageTitle>{{ l.t('app.conversations', 'Conversations') }}</PageTitle>
    <div>
      <w-alert v-if="conversation.alert" :message="conversation.alert.message" :level="conversation.alert.level" :closable="conversation.enable_close_alert" @closed="conversation.alert = null" class="mb-6"/>
      <div v-else class="mb-6">
        <w-alert
          v-if="conversation.successful_deletions"
          :message="conversation.successful_deletions.message"
          level="success"
          :closable="true"
        />
        <w-alert
          v-if="conversation.failed_deletions"
          :message="conversation.failed_deletions.message"
          level="error"
          :closable="true"
        />
      </div>
      <template  v-if="!one_extension">
        <FormInput :label="l.t('app.extension', 'Extension')" :tight="true">
          <ExtensionSelector
            :select_if_one="true"
            @changed="conversation.extensionChanged($event)"
            @only_one_auto_selected="one_extension = true"
            :disabled="conversation.deleting && conversation.deleting.total > conversation.deleting.status.length"
            :key="!!conversation.deleting"
            class="main-extension-selector"
            data-test-id="conversations-extension-selector"
          />
        </FormInput>
        <v-divider class="divider"></v-divider>
      </template>
      <div v-if="conversation.loading">
        <w-loader v-if="!conversation.deleting"/>
        <ProgressBar
          v-else
          :key="conversation.deleting.status.length"
          :processing="conversation.deleting"
          @stopProcess="conversation.stopDeletion = true"
        />
      </div>
      <div v-else-if="conversation.extension" class="mt-5">
        <h6 v-if="conversation.updated_at" class="w-body-2">
          {{ l.t("conversations.updated-at", "Updated at") }}:
          <span v-if="conversation.loading_refresh">{{
            l.t("conversations.refreshing", "refreshing...")
          }}</span
          ><a @click="conversation.refresh()" v-else>{{ conversation.updated_at.toLocaleString() }}</a>
        </h6>
        <v-expansion-panels class="conversations mb-3" v-model="conversation.opened_conversation">
          <v-expansion-panel>
            <v-expansion-panel-header expand-icon="mdi-message-plus-outline mdi-rotate-180" color="primary" class="white--text" data-test-id="conversations-new-conv">
              {{ l.t("conversations.new-conversation", "New conversation") }}
            </v-expansion-panel-header>
              <v-expansion-panel-content>
                <SendMessage
                  :extension_id="conversation.extension"
                  :from="conversation.caller_id"
                  @sent="conversation.created($event)"
                  class="pt-3"
                  data-test-id="conversations-send-new-mess"
                />
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel
            v-for="(c, i) in conversation.items"
            v-bind:key="c['id']"
            :class="{
              conversation: true,
              'selected-row': conversation.selected,
            }"
            @click="
              conversation.toggle_conversation($event, c['id'], c['unread_messages'])
            "
          >
            <div class="d-flex" :class="{'selected-row': c.selected}">
              <w-checkbox
                v-model="c.selected"
                @change="conversation.checkSubmission()"
                class="ml-3 select-table-item conversations-checkbox"
                :key="`conversation-item-${c.id}-${c.selected}`"
                hide-details='auto'
                :data-test-id="`conversations-${i}-checkbox`"
              />
              <v-expansion-panel-header
                :class="{'selected-row': c.selected}"
                class="pl-0"
              >
                <div class="d-md-flex justify-start">
                  <span
                    v-for="(t, index) in conversation.get_participants(c)"
                    v-bind:key="t + '-' + index"
                    :data-test-id="`conversations-${i}-participants`"
                  >
                    <template v-if="$vuetify.breakpoint.mdAndUp">
                      <span v-if="index !== 0" class="mx-1">/</span>
                      <span class="nowrap">{{ t | format_phone_number }}</span>
                    </template>
                    <div v-else class="mb-2">
                      <div class="text-caption mb-0">{{ t | format_phone_number }}</div>
                    </div>
                  </span>
                  <span class="grey--text lighten-2 fs-exclude" :class="{'ml-3': $vuetify.breakpoint.mdAndUp}" :data-test-id="`conversations-${i}-last-message`">
                    {{ conversation.opened !== c.id ? c["last_message"]["text"] : "" }}
                  </span>
                </div>
              </v-expansion-panel-header>
            </div>
            <v-expansion-panel-content>
              <SendMessage
                :extension_id="conversation.extension"
                :from="c['my_numbers'][0]"
                :to="
                  c['last_message']['to']
                    .map((v) => v['number'])
                    .concat([c['last_message']['from']])
                    .filter((v) => v !== c['my_numbers'][0])
                "
                @sent="conversation.sent($event)"
                class="pt-3"
                :data-test-id="`conversations-${i}-send-message`"
              />
              <v-list-item
                v-for="m in conversation.messages.hasOwnProperty(c['id']) ? conversation.messages[c['id']] : [c['last_message']]"
                v-bind:key="m['message_id']"
                two-line
                class="mt-3"
              >
                <v-list-item-content
                  :class="{
                    'a-lot': conversation.messages.hasOwnProperty(c['id']) && conversation.messages[c['id']].length > 5,
                    'text-left primary lighten-5 rounded-tl-0': m['direction'] === 'out',
                    'mr-15': $vuetify.breakpoint.mdAndUp && m['direction'] === 'out',
                    'text-right info lighten-5 rounded-tr-0': m['direction'] === 'in',
                    'ml-15': $vuetify.breakpoint.mdAndUp && m['direction'] === 'in',
                  }"
                  class="pa-4 rounded rounded-pill border-0 hide-scroller w-100"
                  :data-test-id="`conversations-${i}-message`"
                >
                  <v-list-item-subtitle v-if="$vuetify.breakpoint.mdAndUp">
                    <span :data-test-id="`conversations-${i}-from`">
                      {{ m["from"] | format_phone_number }}
                    </span>,
                    <span :data-test-id="`conversations-${i}-created-at`">{{ new Date(m["created_at"] * 1000).toLocaleString() }}</span>
                  </v-list-item-subtitle>
                  <div v-else class="secondary--text text--darken-4 mb-2">
                    <span :data-test-id="`conversations-${i}-from`">
                      {{ m["from"] | format_phone_number }}
                    </span>,
                    <span :data-test-id="`conversations-${i}-created-at`">{{ new Date(m["created_at"] * 1000).toLocaleString() }}</span>
                  </div>
                  <!-- <v-list-item-title> -->
                    <div class="fs-exclude" :data-test-id="`conversations-${i}-text`">
                      <p>{{ m["text"] }}</p>
                    </div>
                    <w-btn v-for="mm in m['media']" class="fs-exclude" v-bind:key="mm['url']" :href="mm['url']" target="_blank" text :data-test-id="`conversations-${i}-download-file-btn`">
                      <v-icon>mdi-attachment</v-icon>&nbsp;
                      {{ mm["filename"] }}
                    </w-btn>
                  <!-- </v-list-item-title> -->
                </v-list-item-content>
              </v-list-item>
              <v-list-item v-if="!(conversation.loading_messages.hasOwnProperty(c['id']) && conversation.loading_messages[c['id']])">
                <v-list-item-content>
                  <a
                    @click="conversation.more_messages(c['id'])"
                    v-if="['total'] - (conversation.messages.hasOwnProperty(c['id']) ? conversation.messages[c['id']] : [c['last_message']]).length > 0"
                    :data-test-id="`conversations-${i}-more-messages-btn`"
                    >{{ l.t("conversations.more-messages", "More messages") }} ({{
                      c["total"] -
                        (conversation.messages.hasOwnProperty(c["id"])
                          ? conversation.messages[c["id"]]
                          : [c["last_message"]]
                        ).length
                    }})</a
                  >
                  <w-btn
                    @click="confirmation_modal = c.id"
                    text
                    block
                    small
                    color="error"
                    :data-test-id="`conversations-${i}-delete-btn`"
                  >
                      <v-icon small>$vuetify.icons.trash</v-icon>
                      {{ l.t("conversations.delete-conversation", "Delete conversation") }}
                  </w-btn>
                  <ConfirmationModal
                    :key="c['id']"
                    v-if="confirmation_modal === c.id"
                    @confirmed="confirmed(c['id'])"
                    @close="confirmation_modal = false"
                    :message="l.t('app.are-you-sure-delete-conversation','Are you sure you want to remove this conversation? You will not be able to access it after removing.')"
                    :data-test-id="`conversations-${i}-confirm-modal`"
                  />
                </v-list-item-content>
              </v-list-item>
              <v-list-item v-else>
                <v-list-item-content>
                  <w-loader size="small"/>
                </v-list-item-content>
              </v-list-item>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
        <MassOperationButtons
          v-if="conversation.items && conversation.items.length"
          @selected="conversation.bulkDelete()"
          @all="conversation.deleteAll()"
          :selected="!conversation.disable_delete_button"
          data-test-id="conversations-mass-operations"
        >
          <w-checkbox
            v-model="conversation.check_all"
            :label="l.t('app.select-all', 'Select all')"
            class="ml-3 select-all"
            hide-details='auto'
            data-test-id="conversations-select-all"
          />
        </MassOperationButtons>
      </div>
      <NothingFound
        class="nothing-found"
        v-if="conversation.everythingDeleted"
        :alert="conversation.alert"
        :everythingDeleted="conversation.everythingDeleted"
        :closable="false"
        data-test-id="conversations-nothing-found"
      ></NothingFound>
      <NumericPagination
        v-if="conversation.uri"
        :limit="conversation.limit"
        :key="conversation.uri"
        @paramsPrepared="conversation.loadItems($event)"
        data-test-id="conversations-pagination"
      />
    </div>
  </div>
</template>

<script>
import l from '../../libs/lang';
import Helpers from '../../libs/helpers';
import Conversation from '../../models/Conversation';
import PageTitle from '../elements/PageTitle.vue';
import ProgressBar from '../elements/ProgressBar.vue';
import SendMessage from '../fragments/SendMessage.vue';
import FormInput from '../elements/form/FormInput.vue';
import NothingFound from '../elements/NothingFound.vue';
import NumericPagination from '../elements/NumericPagination.vue';
import ExtensionSelector from '../fragments/ExtensionSelector.vue';
import MassOperationButtons from '../elements/MassOperationButtons.vue';
import ConfirmationModal from '../elements/modal/ConfirmationModal.vue';

export default {
  components: {
    FormInput,
    PageTitle,
    ProgressBar,
    SendMessage,
    NothingFound,
    ConfirmationModal,
    NumericPagination,
    ExtensionSelector,
    MassOperationButtons,
  },
  data() {
    return {
      l,
      conversation: new Conversation(this.$session, Helpers.emitter(this), Helpers.changeRoute(this), () => this.$forceUpdate()),
      one_extension: false,
      confirmation_modal: false,
    };
  },
  // created() {
  //   this.$data.conversation.loading = true;
  // },
  // created: async function() {
  // this.$data.inbox_timeout = setInterval(() => {
  // await this.load_inbox(null, true)
  // }, 60000)
  // },
  beforeDestroy() {
    if (this.$data.conversation.inbox_timeout) {
      clearInterval(this.$data.conversation.inbox_timeout);
    }
  },
  beforeRouteLeave(to, from, next) {
    this.$data.conversation.handleUserRedirect(to, from, next);
  },
  methods: {
    async confirmed(id) {
      this.$data.confirmation_modal = false;
      await this.$data.conversation.delete_conversation(id);
    },
  }
};
</script>

<style lang="scss" scoped>
.a-lot { // TODO check more messages and a lot scroller (when has > 5)
  max-height: 30rem;
  overflow: scroll;
  overflow-x: auto;
}
.overflow-y-hidden .conversations {
  z-index: 0;
}
.conversations-checkbox {
  min-width: 35px;
}
</style>
