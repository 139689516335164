<template>
    <w-loader v-if="loading"/>
    <div v-else id="pdf-export">
        <!-- <LogarithmicSwitcher
            v-model="logarithmic"
            @input="logarithmic_representation"
            class="ml-5"
        /> -->
        <v-simple-table id="stats-table" data-test-id="per-num-graph-table">
            <thead data-test-id="table-head">
                <tr data-test-id="head-row">
                    <th data-test-id="number">{{ l.t('app.number', 'Number') }}</th>
                    <th v-for="(prop, i) in Object.keys(items)" :data-test-id="prop">
                        {{ call.category_conf.fields[prop].translation }}
                    </th>
                </tr>
            </thead>
            <tbody data-test-id="table-body">
                <tr
                        v-for="(item, i) in call.category_conf.numbers"
                        :data-test-id="i"
                >
                    <td
                        data-test-id="number"
                    >
                        {{ item }}
                    </td>
                    <td v-for="prop in Object.keys(items)" :data-test-id="prop">
                        <HorizontalBar
                            :color="colors[item]"
                            :item="items[prop].find(
                                (x) => x.number === item
                            )"
                            :title="`${item}: ${items[prop].find(
                                (x) => x.number === item
                            ).value}`"
                            :max="Math.max.apply(
                                Math,
                                items[prop].map((x) => x.display_value)
                            )"
                            :data-test-id="`per-num-graph-item-${i}-bar`"
                        />
                    </td>
                </tr>
                <tr data-test-id="per-num-bottom-graph">
                    <td data-test-id="empty-cell"><br/></td>
                    <td v-for="prop in Object.keys(items)" :data-test-id="prop">
                        <div class="d-flex justify-space-between">
                            <span class="min">
                                {{
                                    Math.round(
                                        call.formatters[call.category_conf.fields[prop]['type']](
                                            Math.min.apply(
                                                Math,
                                                items[prop].map((x) => x.value)
                                            )
                                        )
                                    )
                                }}
                            </span>
                            {{ l.t('app.calls-lowercase', 'calls') }}
                            <span class="max">
                                {{
                                    Math.round(
                                        call.formatters[call.category_conf.fields[prop]['type']](
                                            Math.max.apply(
                                                Math,
                                                items[prop].map((x) => x.value)
                                            )
                                        )
                                    )
                                }}
                            </span>
                        </div>
                    </td>
                </tr>
            </tbody>
        </v-simple-table>

        <v-simple-table data-test-id="botttom-table">
            <thead data-test-id="table-head">
            <tr data-test-id="head-row">
                <th data-test-id="number">{{ l.t('app.number', 'Number') }}</th>
                <th v-for="(prop, i) in Object.keys(items)" :data-test-id="prop">
                    {{ call.category_conf.fields[prop].translation }}
                </th>
            </tr>
            </thead>
            <tbody data-test-id="table-body">
            <tr v-for="(number, i) in call.category_conf.numbers" :data-test-id="i">
                <td
                    data-test-id="number"
                >
                    {{ number }}
                </td>
                <td v-for="prop in Object.keys(items)" :data-test-id="prop">
                    <div>
                        {{
                            Math.round(
                                items[prop].find((x) => x.number === number).value
                            )
                        }}
                    </div>
                </td>
            </tr>
            </tbody>
        </v-simple-table>
    </div>
</template>

<script>
  import { OrangeColor } from 'orange-colors';

  import l from '../../../../libs/lang';
  import HorizontalBar from '../../../fragments/HorizontalBar.vue';
  // import LogarithmicSwitcher from '../../../elements/logarithmic-representation-switcher.vue';

  export default {
    props: ['call', 'color'],
    components: {
      HorizontalBar,
      // LogarithmicSwitcher,
    },
    data() {
      return {
        l,
        loading: false,
        logarithmic: false,
        colors: {},
        items: null,
      };
    },
    async created() {
        if (this.$props.call.items) {
            this.$data.items = {...this.$props.call.items};
            for (const prop of Object.keys(this.$data.items)) {
                if (this.$data.items[prop].every((x) => !x.value)) delete this.$data.items[prop];
            }
            if (!Object.keys(this.$data.items).length) return null;
            this.prepareNumbers();
        }
        this.logarithmic_representation();

        return true;
    },
    methods: {
        logarithmic_representation() {
            if (!this.items) return null;
            if (this.$data.logarithmic) {
                const values = [];
                for (const key of Object.keys(this.items)) {
                    this.items[key].map((x) => values.push(x.value));
                }
              const log_values = this.$props.call.category_conf.logarithmic_values([...values]);
              for (const key of Object.keys(this.items)) {
                this.items[key] = this.items[key].map((x) => {
                    x.display_value = log_values[x.value];
                    return x;
                });
              }
            } else {
                for (const key of Object.keys(this.items)) {
                  this.items[key] = this.items[key].map((x) => {
                      x.display_value = x.value;
                      return x;
                  });
                }
            }
            this.$forceUpdate();
            return true;
        },
        async prepareNumbers() {
            for (const number of this.$props.call.category_conf.numbers) {
                let num = number.replace(/-/g, '');
                num = Math.round(Number.parseInt(num) / 10000);
                this.$data.colors[number] = this.colorNumbers(num);
            }
        },
        colorNumbers(number) {
            const c = new OrangeColor(this.$props.color);
            if (Number.parseInt(number)) {
              c.h = (Number.parseInt(number) % 18) * 20;
            } else {
              c.s = 0;
            }
            return c.html_string;
        },
    },
    filters: {
        extensionName(extension) {
            if (extension && typeof extension === 'object') {
                return `${extension.extension}: ${extension.name}`;
            }
            if (extension === l.t('app.no-extension', 'No extension')) {
                return extension;
            }

            return `${l.t('app.deleted', 'Deleted')} ${extension}`;
        }
    },
  };
</script>
