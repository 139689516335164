<template>
  <v-btn
    v-bind="$attrs"
    v-on="$listeners"
    @click="on_click"
    :ripple="ripple"
    class="custom-btn w-btn-medium"
  >
    <!-- Pass on all named slots -->
    <slot v-for="slot in Object.keys($slots)" :name="slot" :slot="slot"/>
    <!-- Pass on all scoped slots -->
    <template v-for="slot in Object.keys($scopedSlots)" :slot="slot" slot-scope="scope"><slot :name="slot" v-bind="scope"/></template>
  </v-btn>
</template>
<script>
// custom attrs: changes-form => triggers discard changes modal on click
import EventBus from '../../../libs/EventBus';

export default {
  methods: {
    on_click() {
      if (Object.prototype.hasOwnProperty.call(this.$attrs, 'changes-form')) {
        EventBus.$emit('form_input_changed');
      }
    }
  },
  computed: {
    ripple() {
      if (Object.prototype.hasOwnProperty.call(this.$attrs, 'ripple')) return this.$attrs.ripple;
      if (Object.prototype.hasOwnProperty.call(this.$attrs, 'text')) return false;

      return true;
    }
  }
};
</script>
<style lang="scss">
  @import './../../../assets/styles/scss/vars.scss';
  .custom-btn {
    letter-spacing: 0.2px !important;
    text-transform: none !important;
    box-shadow: none;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    -webkit-box-shadow: none !important;
    box-shadow:  none !important;
    &:before {
      background: transparent !important;
    }
    &:hover {
        &.v-btn:before {
            background: transparent !important;
            background-color: transparent !important;
        }
    }
    &:blur {
      background: transparent !important;
    }
    &.v-btn--text {
      .v-icon {
        margin-right: 3px;
      }
      &.primary--text {
        color: var(--v-primary-darken1) !important;
        &:hover {
          .v-btn__content{
            color: var(--v-primary-darken4) !important;
            .v-icon.primary--text {
              color: var(--v-primary-darken4) !important;
              svg {
                color: var(--v-primary-darken4) !important;
              }
            }
          }
        }
      }
      &.secondary--text:hover {
        .v-btn__content{
          color: var(--v-secondary-darken1) !important;
          .v-icon.secondary--text, .v-icon.secondary--text svg {
            color: var(--v-secondary-darken1) !important;
          }
        }
      }
      &.info--text:hover {
        .v-btn__content{
          color: var(--v-info-darken1) !important;
          .v-icon.info--text, .v-icon.info--text svg {
            color: var(--v-info-darken1) !important;
          }
        }
      }
      &.text--text:hover {
        .v-btn__content{
          color: var(--v-text-darken1) !important;
          .v-icon.text--text, .v-icon.text--text svg {
            color: var(--v-text-darken1) !important;
          }
        }
      }
      &.error--text:hover {
        .v-btn__content{
          color: var(--v-error-darken1) !important;
          .v-icon.error--text, .v-icon.error--text svg {
            color: var(--v-error-darken1) !important;
          }
        }
      }
      &.success--text:hover {
        .v-btn__content{
          color: var(--v-success-darken1) !important;
          .v-icon.success--text, .v-icon.success--text svg {
            color: var(--v-success-darken1) !important;
          }
        }
      }
      &.warning--text:hover {
        .v-btn__content{
          color: var(--v-warning-darken1) !important;
          .v-icon.warning--text, .v-icon.warning--text svg {
            color: var(--v-warning-darken1) !important;
          }
        }
      }
      &.action--text:hover {
        .v-btn__content{
          color: var(--v-action-darken1) !important;
          .v-icon.action--text, .v-icon.action--text svg {
            color: var(--v-action-darken1) !important;
          }
        }
      }
    }
    &.v-btn.v-size--default:not(.w-btn-default)::not(.w-btn-small) {
      font-size: 12px !important;
    }
    &.v-btn:not(.v-btn--round).v-size--default:not(.w-btn-default) {
      padding: 4px 18px;
      height: 32px;
    }
    &:not(.v-btn--fab):not(.v-btn--icon):not(.v-picker .v-btn) {
      min-width: 100px;
    }
    &.theme--light.v-btn--disabled {
      color: var(--v-text-lighten3) !important;
    }
    &.theme--light.v-btn.v-btn--disabled.v-btn--has-bg {
      background-color: var(--v-action-lighten2) !important;
    }
    &:not(.v-btn--round) {
      border-radius: $border-radius;
    }
  }
</style>
