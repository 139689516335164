<template>
  <v-text-field
    v-bind="$attrs"
    v-on="$listeners"
    @input="on_input"
    @focus="on_focus"
    @blur="on_blur"
    @keydown.space="prevent_leading_space"
    :clearable="show_clearable && !$attrs.hasOwnProperty('not-clearable') && $attrs['not-clearable'] !== false"
    @click:clear="on_clear"
    clear-icon="mdi-close mdi-18px"
    color="primary"
    class="custom-text-field"
    hide-details="auto"
    >
    <!-- Pass on all named slots -->
    <slot v-for="slot in Object.keys($slots)" :name="slot" :slot="slot"/>
    <!-- Pass on all scoped slots -->
    <template v-for="slot in Object.keys($scopedSlots)" :slot="slot" slot-scope="scope"><slot :name="slot" v-bind="scope"/></template>
  </v-text-field>
</template>
<script>
import EventBus from '../../../libs/EventBus';

export default {
  // custom props:
  //    not-clearable -> clearable by default, prevents default behavior
  data() {
    return {
      show_clearable: false,
    };
  },
  methods: {
      on_input() {
        EventBus.$emit('form_input_changed');
      },
      on_focus() {
        this.$data.show_clearable = true;
        this.$emit('focus');
      },
      on_blur() {
        this.$data.show_clearable = false;
        this.$emit('blur');
      },
      on_clear() {
        setTimeout(() => this.$emit('input', ''), 5);
      },
      prevent_leading_space(e) {
        if (typeof e.target.value === 'string') {
          // only prevent the keypress if the value is blank
          if (!e.target.value) {
            e.preventDefault();
          // otherwise, if the leading character is a space, remove all leading white-space
          } else if (e.target.value[0] === ' ') {
            e.target.value = e.target.value.replace(/^\s*/, '');
          }
        }
      },
    },
  };
</script>
<style lang="scss">
  .custom-text-field {
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    /* Firefox */
    input[type=number] {
      -moz-appearance: textfield;
    }
    .v-input__append-inner, .custom-text-field .v-input__prepend-inner {
      margin-top: 0px !important;
    }
  }
</style>
