import l from '../libs/lang';
import Resource from './Resource';

export default class SmartFilters extends Resource {
	constructor(session, emitter, baseUri, changeRoute) {
		super(session, emitter, baseUri, changeRoute);
		// to use this class you will have to provide:
		// property: empty_filters, ex.this.empty_filters = CallLog.buildEmptyFilters();
		// property config_key, ex. this.config_key = 'call-logs-filters';
		// property allow_any_time_filter, default: true, ex. this.allow_any_time_filter = false;
		// filters object with filter.start and filters.end as strings
		this.quick_filters = this.buildQuickFilters();
        this._allow_any_time_filter = true;
        this.filters_loading = false;
        this.saved_filter_name = null;
        this.load_saved_filters();
	}

	set allow_any_time_filter(val) {
		this._allow_any_time_filter = val;
	    if (!val) {
			this.quick_filters = this.quick_filters.filter((x) => x.value !== 'forever');
	    }
	}

	get allow_any_time_filter() {
		return this._allow_any_time_filter;
	}

	get config_url() {
        let url = '/client-config';
        if (this._extension) url = `/extensions/${this._extension}${url}`;

        return url;
    }

    apply_type_range() {
		const {start, end} = (this.quick_filters.find((x) => x.value === this.filters.type)).time();
		const filters = { ...this.display_time(this.filters, start, end) };
		this.filters.start = filters.start;
		this.filters.end = filters.end;
		if (this.forceUpdate) this.forceUpdate();

		return true;
    }

    filter_param_changed(param, value) {
		if (this.filters[param] === value) return null;
		this.filters[param] = value;
		this.restartSavedFilterName();

      return true;
    }

	generateFilterName() {
        const title = l.t('filters.filters', 'Filters');
        const currentTime = new Date();
        const filter = this.quick_filters.find((x) => x.value === this.filters.type);

        switch (this.filters.type) {
            case 'today':
                return `${title} ${(new Date()).toLocaleDateString()}`;
            case 'last_week':
                return `${title} ${this.filters.start.replace('T', ' ')} — ${this.filters.end.replace('T', ' ')}`;
            case 'prev_month':
                currentTime.setMonth(currentTime.getMonth() - 1);
                return `${title} ${currentTime.toLocaleString('default', { month: 'long' })} ${currentTime.getFullYear()}`;
            case 'custom':
                return `${title} ${this.filters.start ? this.filters.start.replace('T', ' ') : ''} — ${this.filters.end ? this.filters.end.replace('T', ' ') : ''}`;
            default:
                return filter ? filter.translation : `${title} ${this.filters.start ? this.filters.start.replace('T', ' ') : ''} — ${this.filters.end ? this.filters.end.replace('T', ' ') : ''}`;
        }
    }

    async load_saved_filters() {
        this.filters_loading = true;
        this.saved_filter_name = null;
        try {
            const saved_filters = await this.session.get_item(this.config_url);
            if (saved_filters && saved_filters.app === 'mini-cp') {
                this.client_config = saved_filters;
                if (this.forceUpdate) this.forceUpdate();
            }
        } catch (err) {
            console.error('Saved filters filters_loading failed.', err);
        }
        this.filters_loading = false;

        return this.client_config;
    }

    generateNewFilterName() {
		const generic_name = this.generateFilterName();
		return this.check_repeating_name(generic_name);
    }

    async save_filters(filters, name) {
        try {
            if (!name) return this.save_filters();
            if (!this.client_config) {
                this.client_config = { app: 'mini-cp' };
                this.client_config[this.config_key] = [];
            }
            if (!Array.isArray(this.client_config[this.config_key])) this.client_config[this.config_key] = [];
            name = this.check_repeating_name(name);
            const saved_filters = filters || JSON.parse(JSON.stringify(this.filters));
            saved_filters.name = name;
            delete saved_filters.type;
			if (!saved_filters.start) saved_filters.start = new Date(0).toISOString().substr(0, 16);
			if (!saved_filters.end) {
				const curr = new Date();
				const offset = Math.abs(curr.getTimezoneOffset() / 60);
				curr.setHours(curr.getHours() + offset);
				saved_filters.end = curr.toISOString().substr(0, 16);
			}
            this.client_config[this.config_key].push(saved_filters);
            this.filters_loading = true;
            await this.session.create_item(this.config_url, this.client_config);
            this.saved_filter_name = name;
        } catch (err) {
            this.validation_error(err);
        }
        this.filters_loading = false;
        return true;
    }

    async delete_filter() {
        this.filters_loading = true;
        try {
            this.client_config[this.config_key] = this.client_config[this.config_key].filter((x) => x.name !== this.saved_filter_name);
            this.saved_filter_name = null;
            this.filters = this.empty_filters;
            await this.session.create_item(this.config_url, this.client_config);
            this.alert = {
                level: 'success',
                message: l.t('call-logs.filter-successfully-deleted', 'Filter successfully deleted'),
            };
            if (this.forceUpdate) this.forceUpdate();
        } catch (err) {
			console.log(err);
            this.alert = {
                level: 'error',
                message: l.t('call-logs.error-deleting-filter', 'Error deleting filter'),
            };
            this.hide_alert(3);
        }
        this.hide_alert(3);
        this.filters_loading = false;
        return true;
    }

    check_repeating_name(name, index = 1) {
		if (!this.client_config) return name;
		if (!this.client_config[this.config_key]) return name;
        if (!this.client_config[this.config_key].length) return name;
        if (this.client_config[this.config_key].find((x) => x.name === name)) {
            if (name.includes(`(${index - 1})`)) {
                name = name.replace(`(${index - 1})`, `(${index})`);
            } else {
                name = `${name} (${index})`;
            }
            return this.check_repeating_name(name, index + 1);
        }

        return name;
    }

    apply_saved_filters() {
        if (!this.saved_filter_name) {
            return this.filters = this.empty_filters;
        }
        const filter = this.client_config[this.config_key].find((x) => x.name === this.saved_filter_name);
        this.filters = JSON.parse(JSON.stringify(filter));
        if (this.forceUpdate) this.forceUpdate();
        return true;
    }

    restartSavedFilterName() {
		if (!this.saved_filter_name) return null;
		this.filters.type = this.filters.type === 'forever' && this.allow_any_time_filter ? 'forever' : 'custom';
		this.saved_filter_name = null;
		if (this.forceUpdate) this.forceUpdate();
		return true;
    }

	buildQuickFilters() {
		const currentTime = new Date();

		let filters = [
		{
			value: 'today',
			translation: l.t('app.today', 'Today'),
			time: () => {
			const end = new Date();
			const start = new Date();
			end.setHours(0, 0, 0);
			start.setHours(0, 0, 0);
			end.setDate(end.getDate() + 1);
			return { start, end };
			},
		},
		{
			value: 'custom',
			translation: l.t('app.custom', 'Custom'),
			time: () => {
			const start = this.filters.start ? new Date(this.filters.start) : new Date(2000, 0, 1);
			const end = this.filters.end ? new Date(this.filters.end) : new Date();
			return { start, end };
			},
		},
		{
			value: 'forever',
			translation: l.t('app.all-time', 'All time'),
			time: () => {
				const start = new Date(2000, 0, 1);
				const tomorrow = new Date();
				tomorrow.setDate(tomorrow.getDate() + 1);
				tomorrow.setHours(0, 0, 0, 0);
				const end = tomorrow;
				return { start, end };
			},
		},
		{
			value: 'last_week',
			translation: l.t('reports.last-full-week', 'Last full week'),
			time: () => {
			const curr = new Date();
			const start = new Date(curr.getFullYear(), curr.getMonth(), curr.getDate() - curr.getDay() - 6);
			const end = new Date(curr.getFullYear(), curr.getMonth(), curr.getDate() - curr.getDay() + 1);
			start.setHours(0, 0, 0);
			end.setHours(0, 0, 0);
			return { start, end };
			},
		},
		{
			value: 'prev_month',
			translation: l.t(
			'reports.previous-full-month',
			'Previous full month'
			),
			time: () => {
			const now = new Date();
			const start = new Date(
				now.getFullYear(),
				now.getMonth() - 1,
				1
			);
			const end = new Date(now.getFullYear(), now.getMonth(), 1);
			start.setHours(0, 0, 0);
			end.setHours(0, 0, 0);

			return { start, end };
			},
		},
		];

		const quarters = [
		{
			value: 'q1',
			translation: `${l.t(
			'reports.q1',
			'1st quarter'
			)} (${currentTime.getFullYear()})`,
			time: () => {
			const now = new Date();
			const end = new Date(now.getFullYear(), 3, 1);
			const start = new Date(now.getFullYear(), 0, 1);
			end.setHours(0, 0, 0);
			start.setHours(0, 0, 0);

			return { start, end };
			},
		},
		{
			value: 'q2',
			translation: `${l.t('reports.q2', '2nd quarter')} (${
			currentTime.getMonth() < 3
				? currentTime.getFullYear() - 1
				: currentTime.getFullYear()
			})`,
			time: () => {
			const now = new Date();
			if (now.getMonth() < 3) now.setFullYear(now.getFullYear() - 1);
			const end = new Date(now.getFullYear(), 6, 1);
			const start = new Date(now.getFullYear(), 3, 1);
			end.setHours(0, 0, 0);
			start.setHours(0, 0, 0);

			return { start, end };
			},
		},
		{
			value: 'q3',
			translation: `${l.t('reports.q3', '3rd quarter')} (${
			currentTime.getMonth() < 6
				? currentTime.getFullYear() - 1
				: currentTime.getFullYear()
			})`,
			time: () => {
			const now = new Date();
			if (now.getMonth() < 6) now.setFullYear(now.getFullYear() - 1);
			const end = new Date(now.getFullYear(), 9, 1);
			const start = new Date(now.getFullYear(), 6, 1);
			end.setHours(0, 0, 0);
			start.setHours(0, 0, 0);

			return { start, end };
			},
		},
		{
			value: 'q4',
			translation: `${l.t('reports.q4', '4th quarter')} (${
			currentTime.getMonth() < 9
				? currentTime.getFullYear() - 1
				: currentTime.getFullYear()
			})`,
			time: () => {
			const now = new Date();
			if (now.getMonth() < 9) now.setFullYear(now.getFullYear() - 1);
			const end = new Date(now.getFullYear() + 1, 0, 1);
			const start = new Date(now.getFullYear(), 9, 1);
			end.setHours(0, 0, 0);
			start.setHours(0, 0, 0);

			return { start, end };
			},
		},
		];
		// because of the lang we use, all translations have to be declared litterary
		const monthsTranslations = {
			january: l.t('app.january', 'January'),
			february: l.t('app.february', 'February'),
			march: l.t('app.march', 'March'),
			april: l.t('app.april', 'April'),
			may: l.t('app.may', 'May'),
			june: l.t('app.june', 'June'),
			july: l.t('app.july', 'July'),
			august: l.t('app.august', 'August'),
			september: l.t('app.september', 'September'),
			october: l.t('app.october', 'October'),
			november: l.t('app.november', 'November'),
			december: l.t('app.december', 'December'),
		};
		const months = Object.keys(monthsTranslations);
		const monthsArray = [];

		for (let i = 0; i <= 17; i++) {
			const date = new Date();
			date.setMonth(date.getMonth() - i);
			const value = `${date.getFullYear()}-${date.getMonth()}`;
			monthsArray.push({
				value,
				translation: `${monthsTranslations[months[date.getMonth()]]} (${date.getFullYear()})`,
				time: () => {
					const start = new Date(date.getFullYear(), date.getMonth(), 1, 0, 0, 0);
					const end = new Date(date.getFullYear(), date.getMonth() + 1, 1, 0, 0, 0);
					const type = value;
					return {
						start,
						end,
						type,
					};
				},
			});
		}
		const sortingFn = (a, b) => {
			const a_start = a.time().start;
			const b_start = b.time().start;
			if (a_start > b_start) return -1;
			if (b_start > a_start) return 1;
			return 0;
		};
		quarters.sort(sortingFn);
		monthsArray.sort(sortingFn);
		filters = filters.concat(quarters);
		filters = filters.concat(monthsArray);

		return filters;
	}

	display_time(filters, start, end, ignore_offset) {
		let offset = Math.abs(end.getTimezoneOffset() / 60);
		const custom = this.filters.type === 'custom';
		const end_hours_to_add = custom && ignore_offset ? 0 : offset;
		offset = Math.abs(start.getTimezoneOffset() / 60);
		const start_hours_to_add = custom && ignore_offset ? 0 : offset;
		end.setHours(
			end.getHours() + end_hours_to_add,
			custom ? end.getMinutes() : 0,
			custom ? end.getSeconds() : 0
		);
		start.setHours(
			start.getHours() + start_hours_to_add,
			custom ? start.getMinutes() : 0,
			custom ? start.getSeconds() : 0
		);
		filters.end = end.toISOString().substr(0, 16);
		filters.start = start.toISOString().substr(0, 16);

		return filters;
	}
}
