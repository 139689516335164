<template>
  <div>
    <VueLink :lines="lines"/>
    <VueBlock v-for="block in blocks"
              :key="block.id"
              v-bind.sync="block"
              ref="blocks"
              :options="optionsForChild"
              @update="updateScene"
              @linkingStart="linkingStart(block, $event)"
              @linkingStop="linkingStop(block, $event)"
              @linkingBreak="linkingBreak(block, $event)"
              @select="blockSelect(block)"
              @delete="blockDelete(block)"
              @blockRightClick="blockRightClick(block)"
              @openModal="openModal($event)"
    />
  </div>
</template>

<script>
  /* eslint-disable no-continue */

  import merge from 'deepmerge';
  import {getMousePosition} from '../helpers/mouse';

  import VueBlock from './VueBlock.vue';
  import VueLink from './VueLink.vue';

  export default {
    name: 'VueBlockContainer',
    components: {
      VueBlock,
      VueLink
    },
    props: {
      blocksContent: {
        type: Array,
        default() {
          return [];
        }
      },
      scene: {
        type: Object,
        default: {blocks: [], links: [], container: {}}
      },
      maxHeight: {
        type: Number,
        default: 0
      },
      maxWidth: {
        type: Number,
        default: 0
      },
      options: {
        type: Object
      }
    },
    mounted() {
      document.documentElement.addEventListener('mousemove', this.handleMove, true);
      document.documentElement.addEventListener('mousedown', this.handleDown, true);
      document.documentElement.addEventListener('mouseup', this.handleUp, true);
      document.documentElement.addEventListener('touchstart', this.handleDown, { passive: false });
      document.documentElement.addEventListener('touchmove', this.handleMove, { passive: false });
      document.documentElement.addEventListener('touchend', this.handleUp, { passive: false });
      document.documentElement.addEventListener('wheel', this.handleWheel, { passive: false });
      window.addEventListener('resize', this.onResize);

      this.centerX = 30;
      // this.centerX = this.$el.clientWidth / 2 - 100;
      this.centerY = this.$el.clientHeight / 2 + 25;

      this.importBlocksContent();
      this.importScene();
    },
    beforeDestroy() {
      document.documentElement.removeEventListener('mousemove', this.handleMove, true);
      document.documentElement.removeEventListener('mousedown', this.handleDown, true);
      document.documentElement.removeEventListener('mouseup', this.handleUp, true);
      document.documentElement.removeEventListener('touchstart', this.handleDown, { passive: false });
      document.documentElement.removeEventListener('touchmove', this.handleMove, { passive: false });
      document.documentElement.removeEventListener('touchend', this.handleUp, { passive: false });
      document.documentElement.removeEventListener('wheel', this.handleWheel, { passive: false });
      window.removeEventListener('resize', this.onResize);
    },
    created() {
      this.mouseX = 0;
      this.mouseY = 0;

      this.lastMouseX = 0;
      this.lastMouseY = 0;

      this.minScale = 0.4;
      this.maxScale = 5;

      this.linking = false;
      this.linkStartData = null;

      this.inputSlotClassName = 'inputSlot';

      this.defaultScene = {
        blocks: [],
        links: [],
        container: {}
      };
    },
    data() {
      return {
        dragging: false,
        centerScreen: false,
        //
        centerX: 0,
        centerY: 0,
        scale: 1.2,
        //
        nodes: [],
        blocks: [],
        links: [],
        //
        tempLink: null,
        selectedBlock: null,
        hasDragged: false
      };
    },
    computed: {
      optionsForChild() {
        return {
          width: 230,
          scale: this.scale,
          inputSlotClassName: this.inputSlotClassName,
          center: {
            x: this.centerX,
            y: this.centerY
          }
        };
      },
      container() {
        return {
          centerX: this.centerX,
          centerY: this.centerY,
          scale: this.scale
        };
      },
      // Links calculate
      lines() {
        const lines = [];

        for (const link of this.links) {
          const originBlock = this.blocks.find((block) => block.id === link.originID);

          const targetBlock = this.blocks.find((block) => block.id === link.targetID);

          if (!originBlock || !targetBlock) {
            console.log('Remove invalid link', link);
            this.removeLink(link.id);
            continue;
          }

          if (originBlock.id === targetBlock.id) {
            // console.log('Loop detected, remove link', link)
            this.removeLink(link.id);
            continue;
          }

          if (originBlock && targetBlock) {
            if (originBlock.outputs[link.originSlot]) {
              originBlock.outputs[link.originSlot].used = true;
              originBlock.used = true;
              if (targetBlock.x < originBlock.x) {
                let {name} = targetBlock;
                if (targetBlock.title.includes('Extension/User')) {
                  name = targetBlock.title.replace('Extension/User ', 'Ext/User ');
                } else if (targetBlock.title.includes('Phone Number')) {
                  name = targetBlock.title.replace('Phone Number: ', '');
                }
                originBlock.outputs[link.originSlot].backLink = name;
                originBlock.backLink = name;
              }
            }
          }

          const originLinkPos = this.getConnectionPos(originBlock, link.originSlot, false);
          const targetLinkPos = this.getConnectionPos(targetBlock, link.targetSlot, true);

          if (!originLinkPos || !targetLinkPos) {
            console.log('Remove invalid link (slot not exist)', link);
            this.removeLink(link.id);
            continue;
          }

          const x1 = originLinkPos.x;
          const y1 = originLinkPos.y;

          const x2 = targetLinkPos.x;
          const y2 = targetLinkPos.y;

          lines.push({
            x1,
            y1,
            x2,
            y2,
            show: targetBlock.x > originBlock.x,
            name: link.name,
            style: {
              stroke: '#8C98A0',
              strokeWidth: 1.2 * this.scale,
              fill: 'none'
            },
            outlineStyle: {
              stroke: '#666',
              strokeWidth: 1.2 * this.scale,
              strokeOpacity: 0.6,
              fill: 'none'
            }
          });
        }

        if (this.tempLink) {
          this.tempLink.style = {
            stroke: '#8C98A0',
            strokeWidth: 1.2 * this.scale,
            fill: 'none'
          };

          lines.push(this.tempLink);
        }

        return lines;
      }
    },
    methods: {
      // Events
      /** @param e {MouseEvent} */
      handleMove(e) {
        const mouse = getMousePosition(this.$el, e);
        this.mouseX = mouse.x;
        this.mouseY = mouse.y;

        if (this.dragging) {
          const diffX = this.mouseX - this.lastMouseX;
          const diffY = this.mouseY - this.lastMouseY;

          this.lastMouseX = this.mouseX;
          this.lastMouseY = this.mouseY;

          this.centerX += diffX;
          this.centerY += diffY;

          this.hasDragged = true;
        }

        if (this.linking && this.linkStartData) {
          const linkStartPos = this.getConnectionPos(this.linkStartData.block, this.linkStartData.slotNumber, false);
          this.tempLink = {
            x1: linkStartPos.x,
            y1: linkStartPos.y,
            x2: this.mouseX,
            y2: this.mouseY
          };
        }
      },
      handleDown(e) {
        const target = e.target || e.srcElement;
        if ((target === this.$el || target.matches('svg, svg *'))) {
          this.dragging = true;

          const mouse = getMousePosition(this.$el, e);
          this.mouseX = mouse.x;
          this.mouseY = mouse.y;

          this.lastMouseX = this.mouseX;
          this.lastMouseY = this.mouseY;

          this.deselectAll();
          if (e.preventDefault) e.preventDefault();
        }
      },
      handleUp(e) {
        const target = e.target || e.srcElement;
        if (this.dragging) {
          this.dragging = false;

          if (this.hasDragged) {
            this.updateScene();
            this.hasDragged = false;
          }
        }

        if (this.$el.contains(target) && (typeof target.className !== 'string' || target.className.indexOf(this.inputSlotClassName) === -1)) {
          this.linking = false;
          this.tempLink = null;
          this.linkStartData = null;
        }
      },
      center() {
        this.centerX = 30;
        // this.centerX = this.$el.clientWidth / 2 - 100;
        this.centerY = this.$el.clientHeight / 2 + 25;
        this.centerScreen = !this.centerScreen;
      },
      moveScene(axis, amount) {
        if (axis === 'x') {
          this.centerX += amount;
        } else {
          this.centerY += amount;
        }
      },
      buttonScroll(val) {
        const deltaScale = 1.18 ** (val * 120 * -0.01);
        this.scale *= deltaScale;

        if (this.scale < this.minScale) {
          this.scale = this.minScale;
          return;
        } if (this.scale > this.maxScale) {
          this.scale = this.maxScale;
          return;
        }

        const zoomingCenter = {
          x: 30,
          y: this.$el.clientHeight / 2 + 25
        };

        const deltaOffsetX = (zoomingCenter.x - this.centerX) * (deltaScale - 1);
        const deltaOffsetY = (zoomingCenter.y - this.centerY) * (deltaScale - 1);

        this.centerX -= deltaOffsetX;
        this.centerY -= deltaOffsetY;

        this.updateScene();
      },
      handleWheel(e) {
        const target = e.target || e.srcElement;
        if (this.$el.contains(target)) {
          if (e.preventDefault) e.preventDefault();

          const deltaScale = 1.09 ** (e.deltaY * -0.01);
          this.scale *= deltaScale;

          if (this.scale < this.minScale) {
            this.scale = this.minScale;
            return;
          } if (this.scale > this.maxScale) {
            this.scale = this.maxScale;
            return;
          }

          const zoomingCenter = {
            x: this.mouseX,
            y: this.mouseY
          };

          const deltaOffsetX = (zoomingCenter.x - this.centerX) * (deltaScale - 1);
          const deltaOffsetY = (zoomingCenter.y - this.centerY) * (deltaScale - 1);

          this.centerX -= deltaOffsetX;
          this.centerY -= deltaOffsetY;

          this.updateScene();
        }
      },
      // onResize(e) {
      //   console.log(this.$el.clientWidth);
      //   this.centerX = this.$el.clientWidth / 2 - 100;
      //   this.centerY = this.$el.clientHeight / 2 - 100;
      // },
      // Processing
      getConnectionPos(block, slotNumber, isInput) {
        if (!block || slotNumber === -1) {
          return undefined;
        }

        let x = 0;
        let y = 0;

        x += block.x;
        y += block.y;

        if (block.objectType === 'number' || block.objectType === 'extension' || block.objectType === 'preset') {
          y += 37;
        } else {
          y += 5;
        }

        if (isInput && block.inputs.length > slotNumber) {
          x -= 2.8;
          if (block.objectType === 'menu') {
            y += block.outputs.length ? 14 : 12;
          } else if (block.objectType === 'number' || block.objectType === 'extension' || block.objectType === 'preset') {
            y -= 16;
          }
          const times = block.outputs.length ? (block.outputs.length - 1) : 0.23;
          y += times * 10.5 + 2.5;
        } else if (!isInput && block.outputs.length > slotNumber) {
          x += this.optionsForChild.width;
          x += 5.8;
          y += 5;

          if (block.objectType === 'menu') {
            y += 32.2;
          }
        } else {
          console.error(`slot ${slotNumber} not found, is input: ${isInput}`, block);
          return undefined;
        }

        // (height / 2 + blockBorder + padding)
        y += (16 / 2 + 1 + 2);
        //  + (height * slotNumber)
        const amount = block.objectType === 'menu' ? 21 : 21;
        y += (slotNumber * amount);
        // if margin is increased or ioHeight just change e.g. ioHeight * slotNumber

        x *= this.scale;
        y *= this.scale;

        x += this.centerX;
        y += this.centerY;

        return {x, y};
      },
      // Linking
      linkingStart(block, slotNumber) {
        this.linkStartData = {block, slotNumber};
        const linkStartPos = this.getConnectionPos(this.linkStartData.block, this.linkStartData.slotNumber, false);
        this.tempLink = {
          x1: linkStartPos.x,
          y1: linkStartPos.y,
          x2: this.mouseX,
          y2: this.mouseY
        };

        this.linking = true;
      },
      linkingStop(targetBlock, slotNumber) {
        if (this.linkStartData && targetBlock && slotNumber > -1) {
          // duplicate
          // this.links = this.links.filter(value => {
          //   return !(value.targetID === targetBlock.id && value.targetSlot === slotNumber)
          // })

          const maxID = Math.max(0, ...this.links.map((o) => o.id));

          // skip if looping
          if (this.linkStartData.block.id !== targetBlock.id) {
            this.links.push({
              id: maxID + 1,
              originID: this.linkStartData.block.id,
              originSlot: this.linkStartData.slotNumber,
              targetID: targetBlock.id,
              targetSlot: slotNumber
            });
            this.updateScene();
          }
        }

        this.linking = false;
        this.tempLink = null;
        this.linkStartData = null;
      },
      linkingBreak(targetBlock, slotNumber) {
        if (targetBlock && slotNumber > -1) {
          const findLink = this.links.find((value) => value.targetID === targetBlock.id && value.targetSlot === slotNumber);

          if (findLink) {
            const findBlock = this.blocks.find((value) => value.id === findLink.originID);

            this.links = this.links.filter((value) => !(value.targetID === targetBlock.id && value.targetSlot === slotNumber));

            this.linkingStart(findBlock, findLink.originSlot);

            this.updateScene();
          }
        }
      },
      removeLink(linkID) {
        this.links = this.links.filter((value) => !(value.id === linkID));
      },
      // Blocks
      addNewBlock(nodeName, x, y) {
        const maxID = Math.max(0, ...this.blocks.map((o) => o.id));
        let [blockX, blockY] = [x, y];

        const node = this.nodes.find((n) => n.name === nodeName);

        if (!node) {
          return;
        }
        const block = this.createBlock(node, maxID + 1);

        // if x or y not set, place block to center
        if (x === undefined || y === undefined) {
          blockX = (this.$el.clientWidth / 2 - this.centerX) / this.scale;
          blockY = (this.$el.clientHeight / 2 - this.centerY) / this.scale;
        } else {
          blockX = (x - this.centerX) / this.scale;
          blockY = (y - this.centerY) / this.scale;
        }

        block.x = blockX;
        block.y = blockY;
        this.blocks.push(block);

        this.updateScene();
      },
      createBlock(node, id) {
        const inputs = [];
        const outputs = [];
        const values = {};

        node.fields.forEach((field) => {
          if (field.attr === 'input') {
            inputs.push({
              name: field.name,
              label: field.label || field.name
            });
          } else if (field.attr === 'output') {
            outputs.push({
              name: field.name,
              actionType: field.actionType ? field.actionType : null,
              actionName: field.actionName ? field.actionName : null,
              key: field.key ? field.key : null,
              used: field.used,
              backLink: field.backLink,
              label: field.label || field.name
            });
          } else {
            if (!values[field.attr]) {
              values[field.attr] = {};
            }

            const newField = merge({}, field);
            delete newField['name'];
            delete newField['attr'];

            if (!values[field.attr][field.name]) {
              values[field.attr][field.name] = {};
            }

            values[field.attr][field.name] = newField;
          }
        });

        return {
          id,
          x: 0,
          y: 0,
          selected: false,
          name: node.name,
          title: node.title,
          inputs,
          outputs,
          values
        };
      },
      deselectAll(withoutID = null) {
        this.blocks.forEach((value) => {
          if (value.id !== withoutID && value.selected) {
            this.blockDeselect(value);
          }
        });
      },
      // Events
      blockSelect(block) {
        block.selected = true;
        this.selectedBlock = block;
        this.deselectAll(block.id);
        this.$emit('blockSelect', block);
      },
      blockRightClick(block) {
        block.selected = true;
        this.selectedBlock = block;
        this.$emit('blockRightClick', block);
      },
      openModal(event) {
        this.$emit('openModal', event);
      },
      blockDeselect(block) {
        block.selected = false;

        if (block
          && this.selectedBlock
          && this.selectedBlock.id === block.id
        ) {
          this.selectedBlock = null;
        }

        this.$emit('blockDeselect', block);
      },
      blockDelete(block) {
        if (block.selected) {
          this.blockDeselect(block);
        }
        this.links.forEach((l) => {
          if (l.originID === block.id || l.targetID === block.id) {
            this.removeLink(l.id);
          }
        });
        this.blocks = this.blocks.filter((b) => b.id !== block.id);
        this.updateScene();
      },
      prepareBlocks(blocks) {
        return blocks.map((block) => {
          const node = this.nodes.find((n) => n.name === block.name && n.title === block.title);

          if (!node) {
            return null;
          }

          let newBlock = this.createBlock(node, block.id);

          newBlock = merge(newBlock, block, {
            arrayMerge: (d, s) => (s.length === 0 ? d : s)
          });

          return newBlock;
        }).filter((b) => !!b);
      },
      prepareBlocksLinking(blocks, links) {
        if (!blocks) {
          return [];
        }

        const newBlocks = [];

        blocks.forEach((block) => {
          const inputs = links.filter((link) => link.targetID === block.id);

          const outputs = links.filter((link) => link.originID === block.id);

          block.inputs.forEach((s, index) => {
            // is linked
            block.inputs[index].active = inputs.some((i) => i.targetSlot === index);
          });

          block.outputs.forEach((s, index) => {
            // is linked
            block.outputs[index].active = outputs.some((i) => i.originSlot === index);
          });

          newBlocks.push(block);
        });

        return newBlocks;
      },
      importBlocksContent() {
        if (this.blocksContent) {
          this.nodes = merge([], this.blocksContent);
        }
      },
      importScene() {
        const scene = merge(this.defaultScene, this.scene);

        let blocks = this.prepareBlocks(scene.blocks);
        blocks = this.prepareBlocksLinking(blocks, scene.links);

        // set last selected after update blocks from props
        if (this.selectedBlock) {
          const block = blocks.find((b) => this.selectedBlock.id === b.id);
          if (block) {
            block.selected = true;
          }
        }

        this.blocks = blocks;
        this.links = merge([], scene.links);

        const {container} = scene;
        if (container.centerX) {
          this.centerX = container.centerX;
        }
        if (container.centerY) {
          this.centerY = container.centerY;
        }
        if (container.scale) {
          this.scale = container.scale;
          const widthAmount = this.$el.clientWidth < 960 ? 150 : this.$el.clientWidth / 3;
          if (this.scale > (this.$el.clientHeight - 200) / this.maxHeight) {
            this.scale = (this.$el.clientHeight - 200) / this.maxHeight;
          }
          if (this.scale > (this.$el.clientWidth - widthAmount) / this.maxWidth) {
            this.scale = (this.$el.clientWidth - widthAmount) / this.maxWidth;
          }
          if (this.minScale > this.scale) {
            this.minScale = this.scale / 1.1;
          }
        }
      },
      exportScene() {
        const clonedBlocks = merge([], this.blocks);
        const blocks = clonedBlocks.map((value) => {
          delete value['inputs'];
          delete value['outputs'];
          delete value['selected'];

          return value;
        });

        return {
          blocks,
          links: this.links,
          container: this.container
        };
      },
      updateScene() {
        // this.$emit('update:scene', this.exportScene());
      }
    },
    watch: {
      blocksContent() {
        this.importBlocksContent();
      },
      centerScreen() {
        this.importScene();
      },
      scene() {
        this.importScene();
      }
    }
  };
</script>

<style lang="less" scoped>
  // .vue-container {
  //   position: relative;
  //   overflow: hidden;
  //   box-sizing: border-box;
  // }
</style>
