<template>
	<div class="simple-progressbar" data-test-id="simple-progressbar">
		<div class="progress" data-test-id="simple-progressbar-progress">
			<div
				:class="{'smooth-transition': loaded, 'stop-animation': percentage === 100}"
				:style="{ width: `${percentage}%` }"
				class="loader success"
			>
			</div>
			<span id="simple-progressbar-text" data-test-id="simple-progressbar-text">{{ Math.round(percentage) }}%</span>
		</div>
	</div>
</template>

<script>
import l from '../../libs/lang';

export default {
	props: {
		'current': {
			'required': false,
			'type': Number,
		},
		'total': {
			'required': false,
			'type': Number,
		},
		'pervious': {
			'default': 0,
			'type': Number,
		},
	},
	data() {
		return {
			l,
			percentage: 0,
			loaded: false,
		};
	},
	mounted() {
		let percentage = 0;
		if (this.$props.pervious && this.$props.total) {
			percentage = (this.$props.pervious * 100) / this.$props.total;
		}

		this.$data.percentage = percentage;
		if (this.$props.current) {
			setInterval(() => {
				this.$data.percentage = (this.$props.current * 100) / this.$props.total;
				this.$data.loaded = true;
			}, 100);
		}
		return percentage;
	},
	watch: {
		percentage(percentage) {
			if (percentage < 0) this.$data.percentage = 0;
			if (percentage > 100) this.$data.percentage = 100;
		}
	}
};
</script>

<style lang="scss" scoped>
@import "./../../assets/styles/scss/vars.scss";
.progress {
	height: 2rem;
	width: 100%;
	background-color: $border-color;
	position: relative;
	#simple-progressbar-text {
		color: #FFF;
		position: absolute;
		text-align: center;
		top: 0.5rem;
		left: 0;
		right: 0;
	}
}
.progress .loader {
	margin: 0;
	display: flex;
	justify-content: space-arround;
}
.smooth-transition {
	transition: width 1s ease;
}
.stop-animation {
	 &:before {
		background: none;
		animation: none;
	}
}

.loader {
   height: 2rem;
}
</style>
