<template>
	<div class="devices-buy-final-table">
		<w-alert v-if="has_errors()" level="error" :message="has_errors()" />
		<div v-else>
			<div class="w-h5 text-center step-title">
				{{ l.t('devices.review-following-details', 'Review the following details before placing your order')}}
			</div>
			<div class="shipping-payment-info">
				<div class="shipping-info">
					<div class="w-subtitle-1 final-table-subtitle">
						{{l.t('devices.shipping-address', 'Shipping address')}}
					</div>
					<div class="w-body-2">
						{{ shipping_address.name }}
						<br>
						{{ shipping_address.address.line_1 }}
						<br>
						{{ shipping_address.address.city }}, {{ shipping_address.address.province }} {{
						shipping_address.address.postal_code }}
						<br/>
						{{ country_name(shipping_address.address.country) }}
						<br/>
						{{format_phone_number(shipping_address.phone)}}
					</div>
				</div>
				<div class="payment-info">
					<div class="w-subtitle-1 final-table-subtitle">
						{{l.t('devices.payment-info', 'Payment info')}}
					</div>
					<div class="w-body-2">
						{{l.t('app.ending-in', 'ending in')}} {{ payment_method.cc_number.substr(payment_method.cc_number.length -
						4) }}
					</div>
				</div>
			</div>
			<div class="w-subtitle-1 final-table-subtitle">
				{{ l.t('dsevices.order-details', 'Order details')}}
			</div>
			<v-simple-table data-test-id="order-table">
				<thead data-test-id="table-head">
					<tr data-test-id="head-row">
						<th data-test-id="item">{{l.t('app.item', 'Item')}}</th>
						<th data-test-id="qty">{{l.t('app.qty', 'QTY')}}</th>
						<th data-test-id="charges">{{l.t('app.charges', 'Charges')}}</th>
					</tr>
				</thead>
				<tbody data-test-id="table-body">
					<tr
						v-for="(item, i) of Object.values(current_cart)"
						:class="{'t-row-bottom-bold': i === Object.values(current_cart).length - 1}"
						:data-test-id="i"
					>
						<td data-test-id="device-name">{{ item.device.manufacturer }} {{item.device.name}} ({{price(item.device.price.price) }}
							{{l.t('app.each', 'each')}})</td>
						<td data-test-id="qty">{{item.quantity}}</td>
						<td data-test-id="price">{{ price(item.quantity * item.device.price.price) }}</td>
					</tr>
					<tr data-test-id="subtotal-row">
						<td class="font-weight-bold" data-test-id="subtotal">{{l.t('app.sub-total', 'Sub-total')}}</td>
						<td data-test-id="empty-cell"></td>
						<td class="font-weight-bold" data-test-id="price-subtotal">
							{{ price(calculateTotal('price')) }}
						</td>
					</tr>
					<tr data-test-id="shipping-and-handling">
						<td data-test-id="shipping-handling">{{l.t('devices.shipping-handling', 'Shipping & handling')}}</td>
						<td data-test-id="empty-cell"></td>
						<td data-test-id="shipping-handling-total">
							{{ price(calculateTotalShipping())}}
						</td>
					</tr>
					<tr v-for="(tax, i) in taxes_other_than_sales" :data-test-id="`taxes-rows-${i}`">
						<td class="nowrap" data-test-id="tax-name">{{taxes_translations[tax] || tax}}</td>
						<td data-test-id="empty-cell"></td>
						<td data-test-id="tax-total">
							{{price(calculate_tax(tax))}}
						</td>
					</tr>
					<!-- <tr>
						<td>{{l.t('devices.taxes-and-fees', 'Taxes & fees')}}</td>
						<td></td>
						<td>
							{{ price(calculateTotal('taxes'))}}
						</td>
					</tr> -->
					<tr class="t-row-bottom-bold" data-test-id="sales-tax">
						<td class="nowrap" data-test-id="sales-tax-title">{{l.t('app.sales-tax', 'Sales tax')}}</td>
						<td data-test-id="empty-cell"></td>
						<td data-test-id="sales-total">{{ price(calculate_total_sales_tax()) }}</td>
					</tr>
					<tr class="t-row-bottom-bold" data-test-id="total-row">
						<td class="font-weight-bold" data-test-id="total-title">{{l.t('app.total', 'Total')}}</td>
						<td data-test-id="empty-cell"></td>
						<td class="font-weight-bold" data-test-id="total">{{ price(cartTotalPrice()) }}</td>
					</tr>
				</tbody>
			</v-simple-table>
			<div class="final-table-info-note" data-test-id="final-note">
				<div>
					{{l.t('orders.once-you-click-place-order', 'Once you click the “Place order” button below, the total shown above will be charged to your credit card immediately.')}}
				</div>
				<div>
					<span>{{l.t('orders.shippment-arrival', 'Shipment Arrival')}}</span>: {{l.t('orders.please-allow-up-to-two-business-days', 'Please allow up to two business days for processing (including configuration and testing), unless an item is backordered. Once your hardware ships, refer to your chosen shipping method for transit time.')}}
				</div>
				<div>
					<span>{{l.t('orders.cancelations', 'Cancellations')}}</span>: {{l.t('orders.orders-can-be-canceled', 'Orders can be canceled before they have shipped, and are subject to a $15 restocking fee.')}}
				</div>
				<div>
					<span>{{l.t('orders.returns', 'Returns')}}</span>: {{l.t('orders.returns-are-accepted', 'Returns are accepted within 30 days of purchase. Phone.com can provide a return shipping label at customer’s expense; restocking or replacement part fees will be deducted from refund.')}}
				</div>
				<div>
					<span>
						{{l.t('orders.wranity-claims', 'Warranty Claims')}}
					</span> : {{l.t('orders.hardware-is-typically-covered','Hardware is typically covered by a one-year warranty; see manufacturer for details. Please contact Phone.com customer support if you suspect a defect; do not contact the manufacturer.')}}
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import l from '../../../../libs/lang';
import Helpers from '../../../../libs/helpers';

  export default {
    props: {
		cart: {
			type: Object,
			required: true,
		},
		shipping: {
			required: false,
			type: Object,
		},
		shipping_address: {
			required: false,
			type: Object,
		},
		payment_method: {
			required: false,
			type: Object,
		},
    },
    data() {
      return {
		l,
		current_cart: null,
		taxes_translations: {
			'District Tax': l.t('orders.district-tax', 'District tax'),
		},
      };
    },
	created() {
		this.$data.current_cart = this.$props.cart;
    },
    methods: {
		totalPrice(item) {
		  let price = item.device.price.total * item.quantity;
		  if (this.$props.shipping) {
		    price += (this.$props.shipping.total || 0) * item.quantity;
		  }
		  return price;
		},
		checkItemQuantity(id) {
		  if (!this.$data.current_cart[id]) return null;
		  if (this.$data.current_cart[id].quantity <= 0) this.$data.current_cart[id].quantity = 0;
		  this.$data.current_cart[id].quantity = Number.parseInt(this.$data.current_cart[id].quantity);
		  return true;
		},
		calculateTotal(type) {
		  let price = 0;
		  if (type === 'shipping') {
		    return this.calculateTotalShipping();
		  }
		  if (type === 'total-price') {
		    return this.cartTotalPrice();
		  }
		  Object.values(this.$data.current_cart).map((x) => {
		    price += Number.parseInt(x.device.price[type]) * Number.parseInt(x.quantity);

		    return x;
		  });
		  return price;
		},
		calculateTotalShipping() {
		  if (!this.$props.shipping) return 0;
		  let price = 0;
			Object.values(this.$data.current_cart).map((x) => {
		    price += (this.$props.shipping.total || 0) * Number.parseInt(x.quantity);

		    return x;
		  });

		  return price;
		},
		cartTotalPrice() {
		  let price = 0;
		  for (const item of Object.values(this.$data.current_cart)) {
		    price += this.totalPrice(item);
		  }

		  return price;
		},
		calculate_total_sales_tax() {
			let price = 0;
			for (const item of Object.values(this.$data.current_cart)) {
				const sales_taxes = item.device.price.details.filter((x) => x.title === 'Sales Tax');
				const tax = sales_taxes.reduce((a, c) => a + c.amount, 0);
				price += item.quantity * tax;
			}

			return price;
		},
		calculate_tax(type) {
			let amount = 0;
			for (const item of Object.values(this.$data.current_cart)) {
				if (item.device && item.device.price && item.device.price.details) {
					const type_details = item.device.price.details.filter((x) => x.title === type);
					const item_taxes_amount = type_details.reduce((a, c) => {
						a += c.amount;
						return a;
					}, 0);
					amount += item_taxes_amount * item.quantity;
				}
			}
			return amount;
		},
		price(val) {
			return `$${(val / 100).toFixed(2)}`;
		},
		has_errors() {
			const message = [];
			if (!Object.keys(this.$data.current_cart).length) {
				message.push(l.t('devices.select-devices', 'select devices'));
			}
			if (!this.$props.shipping) {
				message.push(l.t('devices.select-shipping', 'select shipping'));
			}
			if (!this.$props.shipping_address) {
				message.push(l.t('devices.select-shipping-address', 'select shipping address'));
			}
			if (!this.$props.payment_method) {
				message.push(l.t('devices.select-payment-method', 'select payment method'));
			}
			const invalid_set_up = (setup) => {
				if (!setup.address) return true;
				const { address } = setup;
				if (!Object.keys(address).filter((x) => x !== 'line_2').every((key) => address[key])) return true;
				if (!setup.lines.length) return true;
				if (setup.lines.find((x) => !x.extension || (x.extension && typeof x.extension === 'object' && !x.extension.id))) return true;

				return false;
			};
			if (Object.values(this.$data.current_cart).find((x) => invalid_set_up(x.device._custom_setup))) {
				message.push(l.t('devices.select-address-and-extension', 'select address and extension for every device'));
			}
			if (message.length) {
				return `In order to see final table you need to: ${message.join(', ')}`;
			}

			return false;
		},
		country_name(country_code) {
			return Helpers.country_name(country_code);
		},
		format_phone_number(num) {
			return Helpers.format_phone_number(num);
		}
	},
	computed: {
		taxes_other_than_sales() {
			const taxes = Object.values(this.$data.current_cart).reduce((a, c) => {
				if (c.device && c.device.price && c.device.price.details) {
					const types = c.device.price.details.filter((x) => x.title.includes('Tax') && x.title !== 'Sales Tax');
					a = a.concat(types.map((x) => x.title));
				}
				return a;
			}, []);

			return [...new Set(taxes)];
		}
	},
	filters: {
       upperFirstCase(val) {
        return val.charAt(0).toUpperCase() + val.slice(1).split('_').join(' ').split('-')
        .join(' ');
      },
      price(val) {
       return `$${(val / 100).toFixed(2)}`;
      }
    },
  };
</script>

<style lang="scss" scoped>
$first-column-width: 30%;
.devices-buy-final-table {
	.shipping-payment-info {
		margin: 30px 0;
		display: flex;
		justify-content: start;
		.shipping-info{
			margin-right: 50px;
		}
	}
	.final-table-subtitle {
		color: var(--v-info-darken1);
		margin-bottom: 10px;
	}
	table {
		thead th {
			height: 34px !important;
			&:first-child {
				width: $first-column-width;
				padding-left: 0;
				width: 100%;
			}
			&:nth-child(2), &:nth-child(3) {
				width: 50px;
			}
		}
		tr td:first-child {
			padding-left: 0;
		}
		.t-row-bottom-bold td{
			border-bottom: 1px solid var(--v-secondary-lighten3) !important;
		}
		.border-bottom-0 {
			border-bottom: none !important;
		}
	}
	.final-table-info-note {
		margin-top:40px;
		font-size: 11px;
		line-height: 16px;
		letter-spacing: 0.1px;
		color: var(--v-text-lighten1);
		div {
			margin-bottom: 20px;
		}
		span {
			font-size: 10.5px;
			font-weight: 700;
			line-height: 16px;
			letter-spacing: 0.1px;
		}
	}
}
</style>
