var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"videos"},[_c('Submenu',{key:`${_vm.l.detect()}${_vm.$route.name}`,attrs:{"routes":{
      'video.index': {
        title: _vm.l.t('app.video-conferencing', 'Video conferencing')
        },
      'video.users.index': {
        title: _vm.l.t('app.users', 'Users')},
      'video.users.create': {
        title: _vm.l.t('video.add-user', 'Add user')
        },
    }}}),(_vm.$route.name === 'video.index')?_c('VideoFragment',{attrs:{"data-test-id":"video-index"}}):_vm._e(),(_vm.$route.name === 'video.users.index')?_c('VideoUsersFragment',{attrs:{"data-test-id":"video-users-index"}}):_vm._e(),(_vm.$route.name === 'video.users.create')?_c('VideoUserFragment',{attrs:{"data-test-id":"video-users-create"}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }