<template>
  <div>
    <PageTitle class="do-not-print">
      {{ title }}
    </PageTitle>
    <IndexPage
      :resource="sms"
      :disable_mass_operations="true"
      :disable_add_new="true"
      :withExtensionSelector="true"
      :extensionCb="'load_saved_filters'"
      :nullable_extension="false"
      :label_cols="label_cols"
      class="sms list-page"
      data-test-id="reports-sms-page"
    >
        <template v-slot:filters>
          <SmartFilters
            :log="sms"
            :label_cols="label_cols"
            :key="JSON.stringify(sms.client_config)"
            data-test-id="sms-smart-filters"
          />
          <FormInput
            :label="l.t('reports.call-direction', 'Call direction')"
            :label_cols="label_cols"
          >
            <w-select
              v-model="sms.filters.direction"
              @change="sms.restartSavedFilterName()"
              :items="[
                {value: null,text: l.t('app.all-calls', 'All calls')},
                {value: 'in',text: l.t('app.inbound', 'Inbound')},
                {value: 'out',text: l.t('app.outbound', 'Outbound')},
              ]"
              hide-details="auto"
              data-test-id="call-reports-filters-group-bu-select"
            />
          </FormInput>
          <FormInput :label="l.t('app.from-number', 'From number')" :label_cols="label_cols">
            <Tel
              @changed="sms.filter_param_changed('from', $event.clean_number)"
              :value="sms.filters.from"
              :key="sms.saved_filter_name"
              data-test-id="sms-filters-from-input"
            />
          </FormInput>
          <FormInput :label="l.t('app.to-number', 'To number')" :label_cols="label_cols">
            <Tel
              @changed="sms.filter_param_changed('to', $event.clean_number)"
              :value="sms.filters.to"
              :key="sms.saved_filter_name"
              data-test-id="sms-filters-to-input"
            />
          </FormInput>
        </template>
        <template v-slot:additionalFilterButtons v-if="sms.items.length">
          <SaveFilter
            v-if="sms.filters_applied && !sms.saved_filter_name"
            :generate_name_callback="sms.generateNewFilterName.bind(sms)"
            @confirmed="sms.save_filters(null, $event)"
            data-test-id="sms-save-filter"
          />
          <w-btn type="button" @click="sms.generate_csv()" color="primary" class="primary--text text--darken-1 primary lighten-5 mr-5" data-test-id="sms-get-csv-btn">
              {{ l.t("app.get-csv", "Get CSV") }}
          </w-btn>
        </template>
        <template v-slot:list>
          <v-simple-table data-test-id="sms-table">
            <thead data-test-id="table-head">
              <tr data-test-id="head-row">
                  <th data-test-id="id">{{ l.t("sms.message-id", "Message ID") }}</th>
                  <th data-test-id="extension">{{ l.t("app.extension", "Extension") }}</th>
                  <th data-test-id="from">{{ l.t("app.from", "From") }}</th>
                  <th data-test-id="to">{{ l.t("app.to", "To") }}</th>
                  <th data-test-id="direction">{{ l.t("app.direction", "Direction") }}</th>
                  <th data-test-id="status">{{ l.t("sms.delivery-status", "Delivery status") }}</th>
                  <th data-test-id="sent-at">{{ l.t("app.sent-at", "Sent at") }}</th>
                  <!-- <th>{{ l.t('app.segments', 'Segments') }}</th> -->
              </tr>
            </thead>
            <tbody data-test-id="table-body">
              <tr v-for="(message, i) in sms.items" :data-test-id="`sms-item-${message.id}-row`">
                  <td data-test-id="id">{{ message.id }}</td>
                  <td data-test-id="extension">{{ message.extension ? `#${message.extension.extension}` : "—" }}</td>
                  <td data-test-id="from">{{ message.from | format_phone_number }}</td>
                  <td data-test-id="to">{{ message.to | format_phone_number }}</td>
                  <td :data-test-id="`sms-item-${message.id}-direction`">
                    {{ message.direction === 'in' ? l.t('app.inbound', 'Inbound') : l.t('app.outbound', 'Outbound')}}
                  </td>
                  <td :data-test-id="`sms-item-${message.id}-status`">{{ message.status | status }}</td>
                  <td data-test-id="sent-at">{{ message.sent_at | format_date }}</td>
                  <!-- <td>{{ message.segments }}</td> -->
              </tr>
            </tbody>
          </v-simple-table>
        </template>
    </IndexPage>
  </div>
</template>

<script>
  import l from '../../../libs/lang';
  import SMS from '../../../models/SMS';
  import ReportConf from '../../../models/reports/ReportConf';
  import Helpers from '../../../libs/helpers';
  import Tel from '../../elements/Tel.vue';
  import IndexPage from '../../elements/IndexPage.vue';
  import PageTitle from '../../elements/PageTitle.vue';
  import FormInput from '../../elements/form/FormInput.vue';
  import SmartFilters from '../../elements/SmartFilters.vue';
  import SaveFilter from '../../elements/modal/SaveFilter.vue';
  import ExtensionSelector from '../../fragments/ExtensionSelector.vue';

  export default {
    components: {
      Tel,
      FormInput,
      PageTitle,
      IndexPage,
      SaveFilter,
      SmartFilters,
      ExtensionSelector,
    },
    data() {
      return {
        l,
        sms: new SMS(this.$session, Helpers.emitter(this), Helpers.changeRoute(this)),
        label_cols: 2,
        title: ReportConf.get_title('sms'),
      };
    },
    filters: {
      format_date(val) {
        return Helpers.format_time(val).replace(',', ' ');
      },
      status(val) {
        if (val === 'delivered') {
          return l.t('app.delivered', 'Delivered');
        }
        if (val === 'failed') {
          return l.t('app.failed', 'Failed');
        }

        return val;
      }
    },
  };
</script>
