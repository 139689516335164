import l from '../libs/lang';
// eslint-disable-next-line import/no-cycle
import Resource from './Resource';
import Cachier from '../libs/Cachier';
import Helpers from '../libs/helpers';
import SelectorPromisesQueueSingleton from '../libs/SelectorPromisesQueueSingleton';

// TODO
// cache handlers

export default class Autocomplete {
    /**
     * @param {string} mode - mode (phone_numbers, extensions, queues etc.)
     * @param {object} session - Phoenix object
     * @param {number} extension - extension id, so we will search on extension level
     * @param {object} conf - configuration parameters, it contains
     {
       "value": number - id of preselected value,
       "reload": boolean - ignores cache, reloads items
       "multiple": boolean - if component has multiple attr
       "value_prop" - prop of the object that should be returned as value
       "return_object" - puts the whole object as selector value; higher priority that value_prop
     }
     * @param {function} component_helpers - object, like $emit
     */
    constructor(mode, session, extension, conf = {}, component_helpers) {
        this.component_helpers = component_helpers;
        this.session = session;
        this.extension = extension;
        this.conf = conf;
        this.mode = this.choose_mode(mode);
        if (!this.mode) {
            throw new Error('Unsupported mode for autocomplete component');
        }
        this.cachier = new Cachier(this.session.user.id);
        this.response = null;
        this.loading = false;
        this.search_loading = false;
        this.search_timer = null;
    }

    set uri(val) {
        this.mode.uri = val;
    }

    get uri() {
        return this.extension ? `/extensions/${this.extension}${this.mode.uri}` : this.mode.uri;
    }

    get cache_key() {
        return `${this.mode.name}-${this.extension}-autocomplete`; // used in resource
    }

    get value_object() {
        if (!this.response) return null;
        if (!this.conf.return_object) {
            return this.response.items.find((x) => x[this.mode.value_prop] === this.conf.value);
        }
        if (this.conf.value_prop === 'id') {
            return this.response.items.find((x) => x.id === this.conf.value);
        }
        const value_stringified = JSON.stringify(this.conf.value);
        return this.response.items.find((x) => JSON.stringify(x) === value_stringified);
    }

    get autocomplete_items() {
        return this.response.items.map((x) => ({
            id: x.id,
            text: this.mode.text(x),
            value: x[this.conf.value_prop],
        }));
    }

    async load_items() {
        this.loading = true;
        try {
            if (this.conf.reload) {
                this.cachier.removeItem(this.cache_key);
            }
            let response;
            const cache = this.cachier.getItem(this.cache_key);
            if (cache) {
                response = cache;
            } else {
                response = await SelectorPromisesQueueSingleton.enqueue(
                    this.mode.uri,
                    () => this.retrieve_items(this.uri)
                );
                if (typeof this.mode.filter === 'function') {
                    response = this.mode.filter(response);
                }
                if (typeof this.mode.sort === 'function') {
                    response.items.sort(this.mode.sort);
                }
            }
            const passed_value = await this.search_for_passed_value(response);
            if (passed_value) response.items.push(passed_value);
            if (!cache) {
                this.cachier.setItem(this.cache_key, response);
            }
            this.response = response;
            this.loading = false;

            return response;
        } catch (err) {
            console.error('Error loading autocomplete items');
            this.loading = false;
            throw err;
        }
    }

    async search_for_passed_value(response) {
        if (
            this.conf.value
            && this.mode.search_missing_value
            && !response.items.find((x) => x[this.conf.value_prop] === this.conf.value)
            && this.conf.value_prop === 'id'
        ) {
            try {
                const search_response = await this.session.get_item(
                    `${this.uri}/${this.conf.value}`
                );
                if (search_response) {
                    return search_response;
                }
            } catch (err) {
                console.log('Default value not found.');
            }
        }

        return null;
    }

    async search(data) {
        if (!data) return null;
        if (this.search_timer) clearTimeout(this.search_timer);
        if (!this.search_loading) {
            this.search_timer = setTimeout(async () => {
                this.search_loading = true;
                const search_results = await this.session.get_list(this.mode.search_uri(data));
                if (search_results.items.length) {
                    this.response.items = this.response.items.concat(search_results.items);
                }
                this.search_loading = false;
            }, 500);
        }

        return true;
    }

    async retrieve_items(uri) {
        let res = null;
        if (this.mode.load_all) {
            res = await this.session.get_list_all(uri);
        } else {
            const limit = this.mode.limit || 100;
            res = await this.session.get_list(uri, limit);
        }

        if (this.mode.response_callback) {
            res = await this.mode.response_callback(res);
        }

        return res;
    }

    choose_mode(mode) {
        // ex. of possible values
        // {
            // uri: '/media?filters[type]=hold_music', => uri with filters to retrieve items
            // search_uri: function, accepts search_term, '/routes', => uri for search functionality, ex. /routes?filters[name]=not-empty' retrieves presets, but will break the name search
            // text: (value) => `${value['id'] ? `#${value['id']} ` : ''}${value['name']}`, => display name cb
            // sort: (a, b) => (a['name'] === '(none)' ? -1 : a['name'] - b['name']), => sort cb
            // load_all: false, => careful. this will load all rss from the account
            // model: new Resource(this.session, this.emitter, '/media', null), => needed for edit modal
            // edit_route: 'media.show', // if route ius not allowed for the client, wont display the modal popup
            // placeholder: l.t('selector.select-a-music-on-hold', 'Select a music on hold'), => selectgor placeholder
            // playable: '/media', => for rss that can be played
            // filter: (res) => res.items.filter((x) => !x.name.toLowerCase().startsWith('lrdn-')); => filters loaded items
            // search_missing_value: set as false, prevents send api requetst to search for the value of selector and putting it to cahce; can be set as condition: this.value_v > 24 - for hold music not to search for the system built in values
            // response_callback: async (api_response) => return api_response; => api response modifier, ex. if contact-groups are empty create default groups
            // limit: limits the number of items returned by the API, default 100
        // }
        const default_mode_conf = {
            text: (value) => value.name || `#${value.id}`,
            limit: 100,
            load_all: false,
            sort: (a, b) => a['name'].localeCompare(b['name']),
            search_missing_value: true,
        };
        const extensions_conf = {
            ...default_mode_conf,
            uri: '/extensions',
            search_uri: (search_term) => `/extensions?filters[name-or-extension]=contains:${search_term}`,
            text: (value) => `${value['extension']}: ${value['name']}`,
            sort: (a, b) => a['extension'] - b['extension'],
            model: new Resource(this.session, this.emitter, '/extensions', null),
            edit_route: 'extensions.show',
            placeholder: l.t('selector.select-an-extension', 'Select an extension'),
            limit: 200,
        };
        const media_conf = {
            ...default_mode_conf,
            uri: '/media',
            search_uri: (search_term) => `/media?filters[name]=contains:${search_term}`,
            text: (value) => `${value.name} ${value['id'] ? `#${value['id']} ` : ''}`,
            sort: (a, b) => (a['name'] === '(none)' ? -1 : a['name'] - b['name']),
            model: new Resource(this.session, this.emitter, '/media', null),
            edit_route: 'media.show',
            placeholder: l.t('selector.select-a-media', 'Select a media'),
            playable: '/media',
            modal_title: l.t('app.media', 'Media'),
            search_missing_value: !Number.isNaN(this.conf.value) && this.conf.value > 24,
        };
        const modes = {
            phone_numbers: {
                ...default_mode_conf,
                uri: '/phone-numbers',
                search_uri: (search_term) => `/phone-numbers?filters[name-or-did]=contains:${search_term}`,
                text: (v) => `${Helpers.format_phone_number(v.phone_number)} / ${v.name}`,
                sort: (a, b) => a['phone_number'].localeCompare(b['phone_number']),
                placeholder: this.conf.multiple ? l.t('app.select-your-phone-numbers', 'Select your phone numbers') : l.t('app.select-a-phone-number', 'Select a phone number'),
                model: new Resource(this.session, this.emitter, '/phone-numbers', null),
                edit_route: 'phone-numbers.show',
            },
            schedules: {
                ...default_mode_conf,
                uri: '/schedules',
                search_uri: (search_term) => `/schedules?filters[name]=contains:${search_term}`,
                text: (value) => `${value['name']}`,
                placeholder: this.conf.multiple ? l.t('selector.select-your-schedules', 'Select your schedules') : l.t('selector.select-a-schedule', 'Select a schedule'),
                model: new Resource(this.session, this.emitter, '/schedules', null),
                edit_route: 'schedules.show',
            },
            extensions: extensions_conf,
            virtualExtensions: {
                ...extensions_conf,
                uri: '/extensions?filters[virtual]=0',
                search_uri: (search_term) => `/extensions?filters[virtual]=0&filters[name-or-extension]=contains:${search_term}`,

            },
            hold_music: {
                ...media_conf,
                uri: '/media?filters[type]=hold_music',
                search_uri: (search_term) => `/media?filters[type]=hold_music&filters[name]=contains:${search_term}`,
                placeholder: l.t('selector.select-a-music-on-hold', 'Select a music on hold'),
                modal_title: l.t('app.music-on-hold', 'Music on hold'),
            },
            user_hold_music: {
                ...media_conf,
                uri: '/media?filters[type]=hold_music&filters[ownership]=user',
                search_uri: (search_term) => `/media?filters[type]=hold_music&filters[ownership]=user&filters[name]=contains:${search_term}`,
                placeholder: l.t('selector.select-a-music-on-hold', 'Select a music on hold'),
                modal_title: l.t('app.music-on-hold', 'Music on hold'),
            },
            greetings: {
                ...media_conf,
                uri: '/media?filters[type]=greeting&&filters[ownership]=user',
                search_uri: (search_term) => `/media?filters[type]=greeting&filters[ownership]=user&filters[name]=contains:${search_term}`,
                placeholder: l.t('selector.select-a-greeting', 'Select a greeting'),
                modal_title: l.t('app.greeting', 'Greeting'),
            },
            queues: {
                ...default_mode_conf,
                uri: '/queues',
                search_uri: (search_term) => `/queues?filters[name]=contains:${search_term}`,
                model: new Resource(this.session, this.emitter, '/queues', null),
                edit_route: 'queues.show',
                placeholder: l.t('selector.select-a-queue', 'Select a queue'),
                modal_title: l.t('app.queue', 'Queue'),
            },
            menus: {
                ...default_mode_conf,
                uri: '/menus',
                search_uri: (search_term) => `/menus?filters[name]=contains:${search_term}`,
                sort: (a, b) => {
                    if (!a['name']) {
                        return 0;
                    }
                    return a['name'].localeCompare(b['name']);
                },
                model: new Resource(this.session, this.emitter, '/menus', null),
                edit_route: 'menus.show',
                placeholder: l.t('selector.select-a-menu', 'Select a menu'),
                modal_title: l.t('app.menu', 'Menu'),
            },
            presets: {
                ...default_mode_conf,
                uri: '/routes',
                search_uri: (search_term) => `/routes?filters[name]=contains:${search_term}`,
                sort: (a, b) => {
                    if (!a['name']) {
                        return -1;
                    }
                    return a['name'].localeCompare(b['name']);
                },
                model: new Resource(this.session, this.emitter, '/routes', null),
                edit_route: 'routes.show',
                placeholder: l.t('selector.select-a-preset', 'Select a preset'),
                modal_title: l.t('app.preset', 'Preset'),
            },
            live_answer: {
                ...default_mode_conf,
                uri: '/live-answer',
                search_uri: (search_term) => `/live-answer?filters[name]=contains:${search_term}`,
                text: (value) => {
                    let {name} = value;
                    if (!value.enabled) {
                        name += ` (${l.t('app.disabled', 'Disabled')})`;
                    }

                    return name;
                },
                model: new Resource(this.session, this.emitter, '/live-answer', null),
                edit_route: 'live-answer.show',
                placeholder: l.t('selector.select-a-script', 'Select a script'),
                modal_title: l.t('app.receptionist-script', 'Receptionist script'),
            },
            contacts: {
                ...default_mode_conf,
                uri: '/contacts',
                search_uri: (search_term) => `/contacts?filters[name]=contains:${search_term}`,
                text: (value) => `${[value['first_name'], value['middle_name'], value['last_name']].filter((x) => x).join(' ')}`,
                sort: (a, b) => a['id'] - b['id'],
                model: new Resource(this.session, this.emitter, '/contacts', null),
                edit_route: 'contacts.show',
                placeholder: l.t('selector.select-a-contact', 'Select a contact'),
                modal_title: l.t('app.contact', 'Contact'),
            },
            groups: {
                ...default_mode_conf,
                uri: '/contact-groups',
                search_uri: (search_term) => `/contact-groups?filters[name]=contains:${search_term}`,
                model: new Resource(this.session, this.emitter, '/contact-groups', null),
                edit_route: 'groups.index',
                placeholder: l.t('selector.select-a-group', 'Select a group'),
                modal_title: l.t('app.group', 'Group'),
                response_callback: async (res) => {
                    if (!res.items.length) {
                        res.items = await Helpers.create_default_contact_groups(this.session, this.extension);
                    }
                    return res;
                },
            }
        };
        if (this.session.user && this.session.user.account && this.session.user.account.features && this.session.user.account.features['trunks-enabled']) {
            modes.trunks = {
                ...default_mode_conf,
                uri: '/trunks',
                model: new Resource(this.session, this.emitter, '/trunks', null),
                edit_route: 'trunks.show',
                placeholder: l.t('selector.select-a-trunk', 'Select a trunk'),
                modal_title: l.t('app.trunk', 'Trunk'),
            };
        }
        if (modes[mode]) {
            return {
                name: mode,
                ...modes[mode],
            };
        }
        return null;
    }

    add_to_cache(new_value) {
        const cache = this.cachier.getItem(this.cache_key);
        if (cache) {
            cache.items.push(new_value);
            if (typeof this.mode.sort === 'function') {
                cache.items.sort(this.mode.sort);
            }
            this.cachier.setItem(this.cache_key, cache);
            return true;
        }
        return false;
    }

    update_cache(new_value) {
        if (!new_value.id) return null;
        const cache = this.cachier.getItem(this.cache_key);
        if (cache) {
            const index = cache.items.findIndex((x) => x.id === new_value.id);
            if (index > -1) {
                cache.items[index] = new_value;
                this.cachier.setItem(this.cache_key, cache);
                return true;
            }
        }
        return false;
    }

    remove_from_cache(item) {
        if (!item.id) return null;
        const cache = this.cachier.getItem(this.cache_key);
        if (cache) {
            const index = cache.items.findIndex((x) => x.id === item.id);
            if (index > -1) {
                cache.items.splice(index, 1);
                this.cachier.setItem(this.cache_key, cache);

                return true;
            }
        }

        return false;
    }
}
