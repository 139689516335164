<template>
    <div id="pdf-export" class="mt-2">
        <div>
            <div class="d-flex justify-center">
              <div id="total-chart" ref="chart" class="chart-container" data-test-id="total-chart"></div>
            </div>
            <ChartLegend :items="legend"/>
            <!-- <LogarithmicSwitcher v-model="logarithmic"/> -->
        </div>
        <v-simple-table v-if="filter" class="mt-3 nowrap" data-test-id="total-table">
            <thead data-test-id="table-head">
              <tr data-test-id="head-row">
                  <th data-test-id="incoming">{{ l.t('app.incoming', 'Incoming') }}</th>
                  <th style="width: 100%" data-test-id="outgoing">{{ l.t('app.outgoing', 'Outgoing') }}</th>
                  <th data-test-id="fitler">{{ upperFirstCase(filter) }}</th>
              </tr>
            </thead>
            <tbody data-test-id="table-body">
              <tr v-for="(item, i) in call.items" :data-test-id="`total-item-${i}-row`">
                  <td data-test-id="incoming">{{ item.incoming }}</td>
                  <td data-test-id="outgoing">{{ item.outgoing }}</td>
                  <td data-test-id="value">
                    {{ call.category_conf.formatTime(item[filter]) }}
                    <span v-if="filter === 'extension' && typeof filter === 'string' && item[filter][0] === '#'">
                      <br/>
                      {{ l.t('app.(deleted)', '(deleted)') }}
                    </span>
                  </td>
              </tr>
            </tbody>
        </v-simple-table>
    </div>
</template>

<script>
  import { OrangeChartBars } from 'orange-charts';
  import { OrangeColor } from 'orange-colors';
  import l from '../../../../libs/lang';
  import ChartLegend from '../../../fragments/ChartLegend.vue';
  // import LogarithmicSwitcher from '../../../elements/logarithmic-representation-switcher.vue';

  export default {
    props: ['call', 'color'],
    components: {
      ChartLegend,
      // LogarithmicSwitcher,
    },
    data() {
      const c = new OrangeColor(this.$props.color);
      const incoming = c.html_string;
      c.h = (c.h + 120) % 360;
      const outgoing = c.html_string;
      return {
        l,
        original_incoming_color: incoming,
        colors: {incoming, outgoing},
        filter: null,
        logarithmic: false,
      };
    },
    mounted() {
      this.display_chart();
    },
    methods: {
      // ====================================
      // logarithmic representation removed for now. Partially functional, it counts it for incoiming+outgoing, shows it only as incoming. Now, we should make a proportion for every column, and display proportionally incoming and outgoing.
      // ====================================
      // logarithmic_representation() {
      //   this.$refs.chart.innerHTML = '';
      //   if (this.$data.logarithmic) {
      //     const values = this.$props.call.items.map((x) => x.incoming + x.outgoing);
      //     const log_values = this.$props.call.category_conf.logarithmic_values([...values]);
      //     for (let i = 0; i < this.call.items.length; i++) {
      //       this.call.items[i].display_incoming = log_values[this.call.items[i].incoming + this.call.items[i].outgoing];
      //       this.call.items[i].display_outgoing = 0;
      //     }
      //     this.$data.colors.incoming = '#F66B16';
      //   } else {
      //     this.call.items = this.call.items.map((x) => {
      //       x.display_incoming = x.incoming;
      //       x.display_outgoing = x.outgoing;
      //       return x;
      //     });
      //     this.$data.colors.incoming = this.$data.original_incoming_color;
      //   }
      //   this.display_chart();
      // },
      display_chart() {
        this.$refs.chart.innerHTML = '';
        this.$data.filter = this.$props.call.filters.group_by;
        const max_y_value = Math.max(
          ...this.$props.call.items.map(
            (x) => x.display_outgoing + x.display_incoming
          )
        );
        let counter = 4;
        new OrangeChartBars(this.$props.call.items)
          .assignSource('display_outgoing', this.$data.colors['outgoing'])
          .assignSource('display_incoming', this.$data.colors['incoming'])
          .axis('x', {
            label: (v) => {
              if (['npa', 'direction', 'extension'].includes(this.$props.call.filters.group_by)) return v;
              if (['hour', 'minute'].includes(this.$props.call.filters.group_by)) {
                const d = new Date(v * 1000);
                counter++;
                if (counter % 5 === 0) {
                return d.toLocaleString(undefined, {
                  month: 'short',
                  day: 'numeric',
                  hour: ['hour', 'minute'].includes(this.$props.call.filters.group_by) ? 'numeric' : undefined,
                  minute: this.$props.call.filters.group_by === 'minute' ? 'numeric' : undefined,
                });
              }
              return '';
              }
              const d = new Date(v * 1000);
              return d.toLocaleString(undefined, {
                month: 'short',
                day: 'numeric',
                hour: ['hour', 'minute'].includes(this.$props.call.filters.group_by) ? 'numeric' : undefined,
                minute: this.$props.call.filters.group_by === 'minute' ? 'numeric' : undefined,
              });
            },
            source: this.$props.call.filters.group_by,
            min: 0,
          })
          .axis('y', {
            cumulative: true,
            min: 0,
            lines: true,
            log: this.$data.logarithmic,
            label: (v) => {
              const label = Math.round(v);
              if (v * 1 === max_y_value) return l.t('x-calls', '{} calls', [label]);
              return label;
            },
          })
          .setDataCallback('mouseover', (_, data) => {
            let details = document.getElementById('orange-charts-details');
            if (!details) {
              details = document.createElement('div');
              details.setAttribute('id', 'orange-charts-details');
              document.body.appendChild(details);
            }
            let title = data.property;
            if (title === 'display_incoming') {
              title = l.t('app.incoming-calls', 'Incoming calls');
            } else if (title === 'display_outgoing') {
              title = l.t('app.outgoing-calls', 'Outgoing calls');
            }
            details.innerText = `${title.charAt(0)
              .toUpperCase()
            + title.slice(1)}: ${data.value}`;
          })
          .setDataCallback('mousemove', (e) => {
            const details = document.getElementById(
              'orange-charts-details',
            );
            details.style.top = `${e.pageY}px`;
            details.style.left = `${e.pageX}px`;
          })
          .setDataCallback('mouseleave', () => {
            document.getElementById('orange-charts-details').innerText = '';
          })
          .render('total-chart', {
            width: 768,
            height: 384,
          });
      },
      upperFirstCase(val) {
        return (
          val.charAt(0)
            .toUpperCase()
          + val
            .slice(1)
            .split('_')
            .join(' ')
        );
      },
    },
    computed: {
      legend() {
        const obj = {};
        obj[
          l.t('app.incoming-calls', 'Incoming calls')
          ] = this.$data.colors.incoming;
        obj[
          l.t('app.outgoing-calls', 'Outgoing calls')
          ] = this.$data.colors.outgoing;
        return obj;
      },
    },
    watch: {
      logarithmic() {
        this.display_chart();
      },
    },
  };
</script>
