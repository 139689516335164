import l from '../libs/lang';
import Resource from './Resource';
import setup from '../libs/resources-setups/contacts';
import CsvItemsDownloader from '../libs/csv-helpers/ItemsDownloader';
import CONTACT_HEADERS from '../libs/csv-helpers/contact-headers.json';
import ContactCsvSetup from '../libs/csv-helpers/ContactsCsvTransformer';

class Contact extends Resource {
	constructor(session, emitter, changeRoute) {
		super(session, emitter, '/contacts', changeRoute);
		this.selector_mode = ['contacts'];
		this.setup = setup;

		this.loading = false;
		this.file = null;
		this.importing = null;
		this.stopImporting = false;
		this.importing_wizard = 1;

		this.filters = {
			name: null,
			phone: null,
			group_id: null,
			exact: false,
		};
		this.item = {
			prefix: null,
			first_name: null,
			middle_name: null,
			last_name: null,
			suffix: null,
			nickname: null,
			company: null,
			phonetic_first_name: null,
			phonetic_middle_name: null,
			phonetic_last_name: null,
			department: null,
			job_title: null,
			emails: [],
			phone_numbers: [],
			addresses: [],
			group: null,
		};

		this.functions = {
			email_primary: (v) => {
				if (!v.emails.length) return null;
				const primary = v.emails.find((x) => x.type === 'primary');
				if (!primary) return null;
				return primary.email;
			},
			email_alternate: (v) => {
				if (!v.emails.length) return null;
				const alternate = v.emails.find((x) => x.type === 'alternate');
				if (!alternate) return null;
				return alternate.email;
			},
			phone_number_business: (v) => {
				if (!v.phone_numbers.length) return null;
				const business = v.phone_numbers.find(
					(x) => x.type === 'business',
				);
				if (!business) return null;
				return business.number.replace(/-/g, '');
			},
			phone_number_home: (v) => {
				if (!v.phone_numbers.length) return null;
				const home = v.phone_numbers.find((x) => x.type === 'home');
				if (!home) return null;
				return home.number.replace(/-/g, '');
			},
			phone_number_mobile: (v) => {
				if (!v.phone_numbers.length) return null;
				const mobile = v.phone_numbers.find((x) => x.type === 'mobile');
				if (!mobile) return null;
				return mobile.number.replace(/-/g, '');
			},
			phone_number_fax: (v) => {
				if (!v.phone_numbers.length) return null;
				const fax = v.phone_numbers.find((x) => x.type === 'fax');
				if (!fax) return null;
				return fax.number.replace(/-/g, '');
			},
			phone_number_pager: (v) => {
				if (!v.phone_numbers.length) return null;
				const pager = v.phone_numbers.find((x) => x.type === 'pager');
				if (!pager) return null;
				return pager.number.replace(/-/g, '');
			},
			address_home: (v) => {
				if (!v.addresses.length) return null;
				const home = v.addresses.find((x) => x.type === 'home');
				if (!home) return null;
				return `${home.street ? home.street : ''}/-/${
					home.street2 ? home.street2 : ''
				}/-/${home.city ? home.city : ''}/-/${
					home.state ? home.state : ''
				}/-/${home.zip ? home.zip : ''}/-/${
					home.country ? home.country : ''
				}`;
			},
			address_business: (v) => {
				if (!v.addresses.length) return null;
				const business = v.addresses.find((x) => x.type === 'business');
				if (!business) return null;
				return `${business.street ? business.street : ''}/-/${
					business.street2 ? business.street2 : ''
				}/-/${business.city ? business.city : ''}/-/${
					business.state ? business.state : ''
				}/-/${business.zip ? business.zip : ''}/-/${
					business.country ? business.country : ''
				}`;
			},
			group: (v) => {
				if (!v.group) return null;
				return v.group.name;
			},
		};
		this.headers = CONTACT_HEADERS.default;
	}

	async loadItems(params) {
		if (!this.extension) return null;
		this.loading = true;
		try {
			const offset = this.checkIfUserDeletedItems(params.offset, params.page);
			const items = await this.session.get_list(this.uri, params.limit, offset);
			this.page = params.page;
			if (this.page > 1 && !items.items.length) {
				this.emitter('pageChanged', 1);
				return this.loadItems({
					limit: this.limit,
					offset: 0,
					page: 1,
				});
			}

			this.items = items.items;
			this.finalizeLoadingItems(items);
			this.emitter('itemsLoaded', JSON.parse(JSON.stringify(items)));
		} catch (err) {
			this.validation_error(err);
		}
		this.loading = false;

		return this.items;
	}

	async loadAddresses() {
        const items = await this.session.get_list_all(`/contacts?filters[require_address]=1&filters[contact_source]=account&filters[contact_source]=payment-methods&filters[contact_source]=device-emergency`);
        return items.items;
    }

	async create(contact) {
		this.loading = true;
		try {
			this.item = contact;
			const newContact = await this.session.create_item(this.baseUri, this.item);
			this.addToCache(newContact);
			this.successfulCreation('contacts.index');
			this.item = null;
		} catch (err) {
			this.validation_error(err);
		}
	}

	async update(contact) {
		await super.update(contact);
		this.updateCache(contact);
		this.item = null;
		this.successfulUpdate('contacts.index');
	}

	async getContact(extension_id, id, item) {
		this.loading = true;
		try {
			this.extension = extension_id;
			this.item = item || await this.session.get_item(`${this.baseUri}/${id}`);
			this.groups = await this.loadGroups();
		} catch (err) {
			this.validation_error(err, true);
		}
		this.loading = false;
	}

	async extensionChanged(value) {
		this.extension = value;
		this.keep_filters_open = false;
		if (value) {
			await this.load_list();
		} else {
			this.items = [];
		}
	}

	async load_list() {
		try {
			this.groups = await this.loadGroups();
			this.emitter('extensionChanged', this.groups);
		} catch (err) {
			console.log(err);
			this.emitter('failed', err);
		}
	}

	async apply_filters() {
		let uri = `${this.baseUri}?mode=full`;
		if (this.filters['name']) {
			if (this.filters['exact']) {
				uri = `${uri}&filters[name]=${this.filters['name']}`;
			} else {
				uri = `${uri}&filters[name]=contains:${this.filters['name']}`;
			}
		}
		if (this.filters['phone']) {
			uri = `${uri}&filters[phone]=${this.filters['phone']}`;
		}
		if (this.filters['group_id'] || this.filters['group_id'] === 0) {
			uri = `${uri}&filters[group_id]=${this.filters['group_id']}`;
		}
		this.filters_applied = true;
		this.uri = uri;
		await this.load_list();
	}

	async filterByGroup(groupId) {
		this.filters.group_id = groupId;
		await this.apply_filters();
	}

	async bulkDelete() {
		await super.bulkDelete();
		this.disable_delete_button = true;
	}

	async deleteAll() {
		await super.deleteAll();
		this.disable_delete_button = true;
	}

	async generate_csv() {
		this.csv_downloader = new CsvItemsDownloader(this.session);
		this.emitter('hidePagination');
		try {
			const res = await this.csv_downloader.get_list_all(this.uri);
			if (res === 'aborted') {
			    this.csv_downloader = null;
			    return null;
			}
			if (!this.csv_downloader.stop) {
				Resource.download_csv(
					this.build_csv(res.items),
					`addressbook-extension-${this.extension}.csv`,
				);
				this.csv_downloaded_successfully();
			}
		} catch (err) {
			this.validation_error(err);
		}
		this.csv_downloader = null;
		this.emitter('showPagination');
		return true;
	}

	async import_csv(e) {
		if (e) e.preventDefault();
		if (!this.file) return null;
		this.loading = true;
		this.items = [];
		try {
			this.csv_trasnformer = new ContactCsvSetup(this.session, this.file, this.extension);
			const contacts = this.csv_trasnformer.csvToJson();
			this.items = await this.csv_trasnformer.create_contacts_objects(contacts);
			this.check_all = true;
			this.importing_wizard = 2;
		} catch (err) {
			this.importing_wizard = null;
			this.validation_error(err);
		}

		this.loading = false;

		return true;
	}

	reset_importing() {
		this.file = null;
		this.items = [];
		this.importing = false;
		this.importing_wizard = 1;
		this.csv_incorrect_lines = [];
	}

	async do_import_contacts() {
		this.loading = true;
		try {
			this.importing = {
				total: 0,
				status: [],
			};
			let contacts_to_import = this.items.filter((x) => x.selected);
			this.importing.total = contacts_to_import.length;
			try {
				contacts_to_import = await this.prepare_groups(contacts_to_import);
			} catch (err) {
				this.validation_error(err);
			}
			for (const contact of contacts_to_import) {
				try {
					if (this.stopImporting) {
						break;
					}
					await this.session.create_item(this.baseUri, contact);
					this.importing.status.push({
						name: this.constructor.assemble_display_name(contact),
						status: 'success',
						message: l.t('app.successfully-imported', 'Successfully imported'),
					});
				} catch (err) {
					this.importing.status.push({
						name: this.constructor.assemble_display_name(contact),
						status: 'failed',
						message: Resource.findError(err, contact),
					});
				}
			}

			this.file = null;
			this.items = [];
			this.item = null;
			this.importing_wizard = 3;
			window.scrollTo(0, 0);
		} catch (err) {
			this.reset_importing();
			this.validation_error(err);
		}
		this.loading = false;

		return true;
	}

	async prepare_groups(contacts) {
		const groups = {};
		for (const c of contacts) {
			if (typeof c.group === 'object' && c.group) {
				if (groups[c.group.name]) {
					c.group = groups[c.group.name];
				} else if (c.group.id) {
					groups[c.group.name] = c.group;
				} else {
					const group = await this.session.create_item(
						`/extensions/${this.extension}/contact-groups`, c.group
					);
					groups[group.name] = group;
					c.group = group;
					this.addToCache(group, 'groups');
				}
			}
		}
		for (const c of contacts) {
			if (c.group) c.group = {id: c.group.id};
		}
		return contacts;
	}

	static assemble_display_name(data) {
		const name = [];
		for (const key of ['prefix', 'first_name', 'middle_name', 'last_name', 'suffix']) {
		  if (data[key]) name.push(data[key].trim());
		}
		if (name.length) return name.join(' ');
		if (data.nickname) return data.nickname.trim();

		if (data.company) return data.company.trim();

		return '—';
	}
}

export default Contact;
