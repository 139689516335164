import l from '../libs/lang';
import Helpers from '../libs/helpers';
import SmartFilters from './SmartFilters';
import CsvItemsDownloader from '../libs/csv-helpers/ItemsDownloader';

export default class SMS extends SmartFilters {
	constructor(session, emitter, changeRoute) {
		super(session, emitter, '/sms', changeRoute);
		this.filters = {
			from: null,
			to: null,
			direction: null,
			type: 'forever',
		};
        this.apply_type_range();
		this.setup = {};
		this.headers = {
			id: l.t('sms.message-id', 'Message ID'),
			extension: l.t('app.extension', 'Extension'),
			from: l.t('app.from', 'From'),
			to: l.t('app.to', 'To'),
			direction: l.t('app.direction', 'Direction'),
			status: l.t('sms.delivery-status', 'Delivery status'),
			sent_at: l.t('app.sent-at', 'Sent at'),
		};
		this.config_key = 'sms-filters';
		this.empty_filters = JSON.parse(JSON.stringify(this.filters));
		this.functions = {
			extension: (item) => {
				if (!item['extension']) return '—';
				return item['extension'].extension;
			},
			from: (item) => Helpers.format_phone_number(item['from']).replace(/-/g, ''),
			to: (item) => Helpers.format_phone_number(item['to']).replace(/-/g, ''),
			sent_at: (item) => Helpers.format_time(item['sent_at']).replace(',', ' '),
		};
	}

	async loadItems(params) {
		this.loading = true;
		let { uri } = this;
		if (!this.extension) {
			if (uri.includes('?')) {
				uri = `${uri}&request_all_extensions=1`;
			} else {
				uri = `${uri}?request_all_extensions=1`;
			}
		}
		try {
			const items = await this.session.get_list(
				uri, params.limit, params.offset,
			);
			this.items = SMS.prepareMessages(items.items);
			this.hideFiltersIfNeeded(items.items);
			this.emitter('itemsLoaded', JSON.parse(JSON.stringify(items)));
			this.filters_applied = true; // to open filters on page loading, to be consistent with other reports
		} catch (err) {
			this.validation_error(err);
		}
		this.loading = false;
	}

	static prepareMessages(items) {
		const messages = [];
		for (const item of items) {
			for (const to of item.to) {
				const newItem = item;
				newItem.to = to.number;
				newItem.sent_at = to.sent_at;
				newItem.status = to.status;
				messages.push(newItem);
			}
		}

		return messages;
	}

	apply_filters() {
		let uri = `${this.baseUri}?mode=full`;
		if (this.filters.from) {
			uri += `&filters[from]=${this.filters.from}`;
		}
		if (this.filters.to) {
			uri += `&filters[to]=${this.filters.to}`;
		}
		if (this.filters.direction) {
			uri += `&filters[direction]=${this.filters.direction}`;
		}
		if (this.filters.start) {
			const start = Math.floor(Date.parse(this.filters.start) / 1000);
			uri += `&filters[created_at]=gte:${start}`;
		}
		if (this.filters.end) {
			const end = Math.floor(Date.parse(this.filters.end) / 1000);
			uri += `&filters[created_at]=lte:${end}`;
		}
		this.filters_applied = true;
		this.uri = uri;
	}

	async generate_csv() {
		this.emitter('hidePagination');
		try {
			this.csv_downloader = new CsvItemsDownloader(this.session);
			const res = await this.csv_downloader.get_list_all(this.uri);
			if (res === 'aborted') {
			    this.csv_downloader = null;
			    return null;
			}
			if (!this.csv_downloader.stop) {
				SMS.download_csv(
					this.build_csv(SMS.prepareMessages(res.items)),
					'sms-report.csv'
				);
				this.csv_downloaded_successfully();
			}
		} catch (err) {
			this.validation_error(err);
		}
		this.csv_downloader = null;
		this.emitter('showPagination');

		return true;
	}
}
