<template>
    <w-autocomplete
        v-if="provinces"
        v-bind="$attrs"
        v-on="$listeners"
        name="canada-provinces-selector"
        :items="provinces"
        :placeholder="l.t('app.province', 'Province')"
        autocomplete="new-password"
        hide-details="auto"
        data-test-id="canada-provinces-selector"
    >
    </w-autocomplete>
</template>
<script>
import PROVINCES from '../../../libs/geo/canada_provinces.json';
import l from '../../../libs/lang';

export default {
    props: [],
    data() {
        return {
            l,
            provinces: null,
        };
    },
    created() {
        this.$data.provinces = PROVINCES.map((x) => ({value: x.short, text: x.name}));
    },
};
</script>

<style scoped>

</style>
