<template>
  <v-tabs
    :value="current_route"
    grow
    centered
    center-active
    :ripple="false"
    slider-size="4"
    :optional="true"
    :show-arrows="true"
    class="submenu mx-auto do-not-print"
    data-test-id="submenu-links"
    >
    <v-tab
      v-for="route in tabs"
      :to="{ name: route.name, params: route_params }"
      :class="{'v-tab--active': current_route === route.path}"
      :ripple="false"
      exact-path
      :data-test-id="`submenu-link-${route.name}`"
      >
      {{ route.title }}
    </v-tab>
  </v-tabs>
</template>

<script>
import Helpers from '../../libs/helpers';

export default {
  components: {},
  props: {
    'routes': {
      type: Object,
      required: true,
    },
    'route_params': {
      type: Object,
    },
  },
  data() {
    return {
      tabs: [],
      current_route: null,
    };
  },
  async created() {
    this.$data.tabs = await this.verify_tabs();

    this.$data.current_route = this.take_current_route();
  },
  methods: {
    async verify_tabs() {
      const routes = this.$router.options.routes.filter((x) => Object.keys(this.$props.routes).includes(x.name));
      const allowed_routes = [];
      for (const route of routes) {
        if (await Helpers.user_can_access_page_middleware(route, this.$feature_toggle, this.$branding)) {
          allowed_routes.push({
            ...this.routes[route.name],
            title: this.$props.routes[route.name].title,
            name: route.name,
          });
        }
      }

      return allowed_routes;
    },
    take_current_route() {
      const current_route_name = this.$route.name;
      let route;
      for (const tab of this.$data.tabs) {
        if (tab.name === current_route_name) {
          route = tab;
        }
      }
      if (!route) {
        for (const tab of this.$data.tabs) {
          if (tab.subroutes && tab.subroutes.find((y) => y === current_route_name)) {
            route = tab;
            tab.path = this.take_route_path(route);
          }
        }
        if (route) this.$data.current_route = this.take_route_path(route);
      }
      if (route) {
        return this.take_route_path(route);
      }

      return null;
    },
    take_route_path(route) {
      return this.$router.options.routes.find((x) => x.name === route.name)['path'];
    }
  }
};
</script>
