<template>
    <div>
        <div v-if="withExtensionSelector && !one_extension" class="mb-5">
          <FormInput
            :label="extension_select_if_one ? l.t('app.extension', 'Extension') : l.t('app.extension-s', 'Extension(s)')"
            :tight="true"
            class="mt-0"
          >
            <ExtensionSelector
              :select_if_one="extension_select_if_one"
              @changed="extension = $event.id"
              @only_one_auto_selected="one_extension = true"
              :disabled="
                (
                  resource.deleting
                  && resource.deleting.total > resource.deleting.status.length
                  && !resource.deleting_finished
                )
                  ||
                (
                  resource.csv_downloader && !resource.csv_downloader.finished
                )
                || resource.progress
              "
              :key="!!resource.deleting"
              class="main-extension-selector"
              data-test-id="index-page-extension-selector"
            />
          </FormInput>
          <v-divider class="divider"></v-divider>
        </div>
        <div>
          <w-alert
            v-if="resource.alert"
            :message="resource.alert.message"
            :level="resource.alert.level"
            :closable="resource.enable_close_alert"
            @closed="resource.alert = null"
            :class="withExtensionSelector && !one_extension ? 'mt-6' : 'mb-6'"
            data-test-id="index-page-alert"
          ></w-alert>
          <template v-else>
              <w-alert
                v-if="resource.successful_deletions"
                :message="resource.successful_deletions.message"
                level="success"
                :closable="true"
                class="mb-3"
                data-test-id="index-page-successfull-deletions"
              />
              <w-alert
                v-if="resource.failed_deletions"
                :message="resource.failed_deletions.message"
                level="error"
                :closable="true"
                class="mb-3"
                data-test-id="index-page-failed-deletions"
              />
          </template>
          <div v-if="resource.loading">
              <w-loader v-if="!resource.deleting"></w-loader>
              <ProgressBar
                v-else
                :key="resource.deleting.status.length"
                :processing="resource.deleting"
                :label="progressbar_label"
                @stopProcess="resource.stopDeletion = true"
                data-test-id="index-page-progressbar"
              />
          </div>
          <div v-else-if="resource.progress">
            <CommonProgress :progress="resource.progress" @closed="resource.progress = null"/>
          </div>
          <div v-else-if="resource.csv_downloader">
            <CsvDownloader :downloader="resource.csv_downloader" class="mt-5" data-test-id="index-page-csv-downlaoder"/>
          </div>
          <div v-else>
              <div>
                  <Filters
                    v-if="!resource.hide_filters && !disable_filters"
                    @filtersApplied="resource.apply_filters()"
                    @clearFilters="resource.clear_filters()"
                    @toggled="$emit('filtersToggled', $event)"
                    :label_cols="label_cols"
                    :showFilters="resource.filters_applied || resource.keep_filters_open"
                    data-test-id="index-page-filters"
                  >
                    <template v-slot:additionalOptions>
                        <slot name="additionalFilterOptions"></slot>
                    </template>
                    <template v-slot:fields>
                      <slot name="filters"></slot>
                    </template>
                    <template v-slot:additionalButtons>
                        <slot name="additionalFilterButtons"></slot>
                    </template>
                  </Filters>
                  <slot name="beforeList"></slot>

                  <div v-if="resource.items && resource.items.length">

                    <slot name="list"></slot>

                    <MassOperationButtons
                      v-if="!disable_mass_operations && resource.items.length"
                      @selected="resource.bulkDelete()"
                      @all="resource.deleteAll()"
                      :selected="!resource.disable_delete_button"
                      :filters_applied="resource.filters_applied"
                      :disable_delete_all="disable_delete_all"
                      :key="resource.items && resource.items.length ? resource.items.filter((x) => x.selected).length : 'mass-operations'"
                      data-test-id="index-page-mass-operations"
                    >
                        <slot name="additionalMassOperations"></slot>
                        <w-checkbox
                          v-model="resource.check_all"
                          :label="l.t('app.select-all', 'Select all')"
                          class="ml-4 select-all"
                          hide-details='auto'
                          data-test-id="index-page-select-all"
                        />
                    </MassOperationButtons>
                  </div>
                  <NothingFound
                    v-else
                    :alert="resource.alert"
                    :everythingDeleted="resource.everythingDeleted"
                    :filters_applied="resource.filters_applied"
                    :disable_add_new="disable_add_new"
                    :create_route="create_route"
                    data-test-id="index-page-nothing-found"
                  >
                    <template v-slot:emptyArray>
                      <slot name="emptyArrayAction"></slot>
                    </template>
                  </NothingFound>
                  <slot name="additionalData"></slot>
              </div>
          </div>
          <ResourceInUseWarning
            :value="!!resource.route_using_resource"
            :resource="resource"
            @input="on_in_use_warning_input"
          />
          <NumericPagination
            v-if="resource.uri"
            :hide="!!resource.csv_downloader || !!resource.progress"
            :limit="resource.limit"
            :everythingDeleted="resource.everythingDeleted"
            :key="resource.uri"
            @paramsPrepared="resource.loadItems($event)"
            data-test-id="index-page-pagination"
          />
        </div>
    </div>
</template>

<script>
  import l from '../../libs/lang';
  import Helpers from '../../libs/helpers';
  import Filters from './Filters.vue';
  import ProgressBar from './ProgressBar.vue';
  import FormInput from './form/FormInput.vue';
  import NothingFound from './NothingFound.vue';
  import CsvDownloader from './CsvDownloader.vue';
  import CommonProgress from './CommonProgress.vue';
  import NumericPagination from './NumericPagination.vue';
  import MassOperationButtons from './MassOperationButtons.vue';
  import ExtensionSelector from '../fragments/ExtensionSelector.vue';
  import ResourceInUseWarning from './modal/ResourceInUseWarning.vue';

  export default {
    props: [
      'create_route',
      'disable_filters',
      'disable_delete_all',
      'disable_mass_operations',
      'disable_add_new',
      'extensionCb',
      'nullable_extension',
      'progressbar_label',
      'resource',
      'withExtensionSelector',
      'label_cols',
    ],
    components: {
      Filters,
      FormInput,
      ProgressBar,
      NothingFound,
      CsvDownloader,
      CommonProgress,
      ExtensionSelector,
      NumericPagination,
      ResourceInUseWarning,
      MassOperationButtons,
    },
    data() {
      return {
        l,
        extension: null,
        one_extension: false,
        extension_select_if_one: true,
      };
    },
    created() {
      this.$data.extension_select_if_one = this.$props.nullable_extension === false ? this.$props.nullable_extension : true;
      this.resource.emitter = Helpers.emitter(this);
    },
    mounted() {
       this.$parent.$on('forceUpdate', () => this.$forceUpdate());
       this.$parent.$on('close_modal', (data) => this.$emit('close_modal', data));
    },
    methods: {
      on_in_use_warning_input(v) {
        if (!v) this.$props.resource.route_using_resource = null;
      },
    },
    watch: {
      async extension(value) {
        this.resource.extension = value;
        if (this.$props.extensionCb) {
          await this.resource[this.$props.extensionCb](value);
        }
      },
    },
  };
</script>
