<template>
    <w-loader v-if="loading"/>
    <div v-else>
        <w-form @submit="update" @changed="may_submit = true" data-discard="true" data-test-id="device-form">
          <FormInput
            :label="l.t('app.device-name', 'Device name')"
          >
            <w-text-field
              v-model="device.name"
              hide-details='auto'
              data-test-id="device-name-input"
            />
          </FormInput>
          <SectionTitle data-test-id="device-lines-title">
              {{ l.t("devices.lines", "Lines") }}
          </SectionTitle>
          <div v-for="(line, i) in device['lines']" :key="i">
            <FormInput
              :label="l.t('app.line-number', 'Line number')"
            >
              <div class="d-flex">
                <w-text-field
                  v-model="device['lines'][i]['line']"
                  type="number"
                  min="1"
                  max="8"
                  hide-details='auto'
                  :data-test-id="`device-line-${i}-line-num-input`"
                />
                <RemoveFromListButton @click="$delete(device['lines'], i)" icon color="error" class="ml-3 my-auto"/>
              </div>
            </FormInput>
            <FormInput
              :label="l.t('app.extension', 'Extension')"
            >
                <ExtensionSelector
                  :value="device['lines'][i]['extension']"
                  :options_list="['call-flow-link']"
                  :placeholder="true"
                  :filtered_values="filtered_extensions"
                  @changed="extensionChanged(i, $event)"
                  :data-test-id="`device-line-${i}-extensions-selector`"
                />
            </FormInput>
          </div>
          <FormInput>
            <AddToListButton @click="addNewLine" data-test-id="device-add-new-line-btn">
              {{ l.t("devices.add-line", "Add line") }}
            </AddToListButton>
          </FormInput>
          <FormInput class="mt-3">
            <w-btn type="submit" :disabled="!may_submit" class="primary" data-test-id="device-submit-btn">
              {{ l.t("app.save", "Save") }}
            </w-btn>
          </FormInput>
        </w-form>
    </div>
</template>

<script>
  import l from '../../libs/lang';
  import Helpers from '../../libs/helpers';
  import FormInput from '../elements/form/FormInput.vue';
  import SectionTitle from '../elements/SectionTitle.vue';
  import ExtensionSelector from './ExtensionSelector.vue';
  import AddToListButton from '../elements/AddToListButton.vue';
  import RemoveFromListButton from '../elements/RemoveFromListButton.vue';

  export default {
    props: ['_device'],
    components: {
      FormInput,
      SectionTitle,
      AddToListButton,
      ExtensionSelector,
      RemoveFromListButton,
    },
    data() {
      return {
        l,
        loading: false,
        device: null,
        may_submit: false,
        company_inbox_extension: null,
        filtered_extensions: [],
      };
    },
    async created() {
      this.$data.device = this.$props._device;
      this.$data.device.loading = false;
      const nxt_details = await Helpers.nxt_details(this.$session);
      if (nxt_details && nxt_details.company_inbox_extension) {
        this.$data.company_inbox_extension = nxt_details.company_inbox_extension;
        this.$data.filtered_extensions = [this.$data.company_inbox_extension];
      }
    },
    methods: {
      extensionChanged(i, event) {
        this.$data.device['lines'][i]['extension'] = { id: event.id };
      },
      async update(e) {
        if (e) e.preventDefault();
        this.prepareDeviceForUpdate();
        this.$emit('updateDevice', this.$data.device);
      },
      prepareDeviceForUpdate() {
        for (const line of this.$data.device.lines) {
          line.line = parseInt(line.line);
        }
      },
      generateNewLine() {
        const lineNumber = this.generateLineNumber();
        return {
          line: lineNumber,
          extension: null,
        };
      },
      generateLineNumber(number = 1) {
        if (this.$data.device.lines.find((x) => x.line === number)) {
          return this.generateLineNumber(number + 1);
        }
        return number;
      },
      addNewLine() {
        this.$data.device['lines'].push(this.generateNewLine());
      },
    },
  };
</script>
