<template>
    <w-loader v-if="loading"/>
    <div v-else>
        <w-alert
            v-if="alert"
            :message="alert.message"
            :level="alert.level"
            class="mb-6"
        />
        <w-form
            v-if="account"
            @submit="submitted"
            @changed="may_submit = true"
            class="account-settings"
            data-discard="true"
        >
            <div class="w-h6 info--text text--darken-1" data-test-id="account-settings-general-title">
                {{ l.t("app.general", "General") }}
            </div>
            <FormInput :label="l.t('app.timezone', 'Timezone')">
                <TimezoneSelector
                    v-model="account.timezone"
                    data-test-id="account-settings-timezone-selector"
                />
            </FormInput>
            <FormInput :label="l.t('accounts.prefix-dialing-out', 'Prefix for dialing out')">
                <w-select
                    v-model="account.dial_prefix"
                    :items="[{value: '', text: l.t('app.none-lowercase', 'none')}, '2', '3', '4', '5', '6', '7', '8', '9']"
                    hide-details="auto"
                    data-test-id="account-settings-prefix-select"
                />
            </FormInput>
            <FormInput :label="l.t('accounts.directory-type', 'Dial-by-name directory type')">
                <w-select
                    v-model="account.directory_type"
                    :items="[
                        {text: l.t('app.last-name', 'Last name'), value: 'last_name'},
                        {text: l.t('app.first-name', 'First name'), value: 'first_name'}
                    ]"
                    hide-details="auto"
                    data-test-id="account-settings-dial-by-name-select"
                />
            </FormInput>
            <div class="mt-5 info--text text--darken-1 w-subtitle-1 mt-7" data-test-id="account-settings-default-ext-title">
                {{ l.t("accounts.default-extension", "Default extension") }}
            </div>
            <FormInput :label="l.t('app.extension', 'Extension')">
                <ExtensionSelector :select_if_one="true" :value="{'id': account.default_voip_phone_id}"
                    @changed="account.default_voip_phone_id = $event.id" @only_one_auto_selected="one_extension = true"
                    data-test-id="account-settings-default-ext-selector" />
            </FormInput>
            <ClassicOrNxt user="classic" tag="div">
                <div class="info--text text--darken-1 w-subtitle-1 mt-8">
                    {{ l.t('app.restricted-outbound-caller-id', 'Restricted Outbound Caller ID')}}
                </div>
                <div class="w-caption mt-4">
                    {{ l.t('accounts.if-enabled-all-extensions', 'If enabled, all extensions on the account will only have the option to display caller ID numbers configured to send calls to their extension.') }}
                </div>
                <div class="recording-option">
                    <div class="shorter-settings-label w-body-2">
                    {{ l.t('accounts.restricted', 'Restricted')}}
                    </div>
                    <w-switch v-model="account.is_restricted" :label="account.is_restricted ? l.t('app.on', 'On') : l.t('app.off', 'Off')"/>
                </div>
            </ClassicOrNxt>

            <div class="d-flex mt-3">
                <div class="info--text text--darken-1 w-subtitle-1 moh-advanced-label">
                    {{l.t('app.hold-music', 'Hold music')}}
                </div>
                <div class="premium-moh-selector">
                    <w-radio-group
                        v-model="account.features['premium-music']"
                        @change="check_if_premium_is_disabled"
                        row
                        hide-details="auto"
                        data-test-id="premium-moh-selector"
                    >
                        <v-radio
                            v-for="t of types"
                            color="text lighten-1"
                            :label="t.text"
                            type="radio"
                            :value="t.value"
                            :data-test-id="`premium-moh-selector-${t.value}`"
                        />
                    </w-radio-group>
                    <div class="text--text text--lighten-1 w-helper-text type-radio-selector-helper-text">
                        {{ l.t('app.premium-music-includes', 'Premium music includes hundreds of licensed songs by popular artists. Configure different genres for different call handling rules and change them at any time.') }}
                    </div>
                </div>
            </div>
            <div class="d-flex">
                <div class="w-body-2 shorter-settings-label">{{l.t('account.default-hold-music', 'Default hold music')}}</div>
                <MOHSelector
                    v-model="account.hold_music"
                    :nullable="true"
                    :show_premium="true"
                    :force_enable_premium="!account.features['premium-music']"
                    :force_disable_premium="!account.features['premium-music']"
                    :key="`moh-selector-${account.features['premium-music']}`"
                />
            </div>
            <ClassicOrNxt user="classic" tag="div">
                <div class="mt-7 mb-1 info--text text--darken-1 w-subtitle-1" id="call-recording" data-test-id="account-settings-call-recording-title">
                    {{ l.t("accounts.call-recording", "Call recording") }}
                </div>
                <HelpText class="pt-1 pb-1" data-test-id="account-settings-cr-help-text">
                    {{ l.t('account.different-laws-warning', 'There are different laws that apply to call recording. Please check your current state and other party’s state and Federal laws before using this feature.') }}
                </HelpText>
                <div class="recording-option">
                    <div class="shorter-settings-label w-body-2">
                        {{ l.t('accounts.recording-status', 'Recording status')}}
                    </div>
                    <div>
                        <!-- <w-radio-group v-model="account.features['call-recording-on']" disabled row hide-details="auto" data-test-id="callrecording-status">
                            <v-radio
                                :value="false"
                                color="text lighten-1"
                                :label="l.t('app.off', 'Off')"
                                data-test-id="disable-call-recording"
                            />
                            <v-radio
                                :value="true"
                                color="text lighten-1"
                                :label="l.t('app.on','On')"
                                data-test-id="enable-call-recording"
                            />
                        </w-radio-group> -->
                        <w-select :items="[
                                { value: 0, text: l.t('app.off', 'Off') },
                                { value: 300, text: l.t('account.300min-4-95', '300 minutes - $4.95 per month') },
                                { value: 1000, text: l.t('account.1000min-14-95', '1000 minutes - $14.95 per month') },
                            ]"
                            :value="recording_plan_value"
                            @change="on_plan_change"
                        />
                        <div class="w-helper-text text--text text--lighten-1 recording-helper-text">
                            <span v-if="recording_plan_value">{{ l.t('accounts.if-recording-exceed', 'If recordings exceed the allotted number of minutes, there will be an additional charge of 3 cents per minute.')}}</span>
                        </div>
                    </div>
                </div>

                <div class="recording-option">
                    <div class="shorter-settings-label w-body-2">
                        {{ l.t('accounts.recording-mode', 'Recording mode')}}
                    </div>
                    <div class="w-helper-text text--text text--lighten-1">
                        <w-select
                            v-model="account.record_all_calls"
                            :placeholder="!account.features['call-recording-300'] && !account.features['call-recording-1000'] ? l.t('app.none', 'None') : l.t('accounts.please-choose-calls-to-record', 'Please choose calls to record')"
                            :items="[
                                { value: 'incoming', text: l.t('app.incoming', 'Incoming') },
                                { value: 'outgoing', text: l.t('app.outgoing', 'Outgoing') },
                                { value: 'all', text: l.t('app.all', 'All') },
                                { value: 'custom', text: l.t('app.custom', 'Custom') },
                            ]"
                            :disabled="!account.features['call-recording-300'] && !account.features['call-recording-1000']"
                        />
                        <div class="w-helper-text text--text text--lighten-1 recording-helper-text">
                            {{ l.t('account.record-incomin-and-outgoing', 'Record incoming and outgoing calls based on my per number settings')}}
                        </div>
                    </div>
                </div>
                <div class="recording-option">
                    <div class="shorter-settings-label w-body-2">
                        {{ l.t('accounts.recording-beep', 'Recording beep')}}
                    </div>
                    <div>
                        <w-switch v-model="account.call_recording_beep"
                            :label="account.call_recording_beep ? l.t('app.on', 'On') : l.t('app.off', 'Off')" hide-details='auto'
                            data-test-id="account-settings-beep-switch"
                        />
                        <div class="w-helper-text text--text text--lighten-1 mt-0  mb-0 recording-helper-text">
                            {{ l.t('accounts.a-beep=will-sound-every-15', 'A beep will sound every 15 seconds. Learn more on the legality surrounding Call recording and reasons for the beep.')}}
                        </div>
                    </div>
                </div>
            </ClassicOrNxt>
            <div class="d-flex justify-center mt-11">
                <CancelChanges @cancel="$emit('cancel')"/>
                <w-btn
                    :disabled="!may_submit"
                    color="primary"
                    type="submit"
                    class="ml-5"
                    data-test-id="account-settings-submit-btn"
                >
                    {{ l.t('app.save', 'Save') }}
                </w-btn>
            </div>
        </w-form>
        <DefaultModal
            v-model="show_premium_modal"
            :with_x="true"
            @close="show_premium_modal = false"
            width="500"
            :key="`selector-create-modal-${show_premium_modal}`"
        >
            <div class="error--text w-h5 mb-4">{{l.t('app.alert', 'Alert')}}!</div>
            {{l.t('account.premium-change-alert', 'If saving this setting as "disabled", please check any Queue or Call Handling Rule that is currently using any Premium Hold Music, as that setting will be set to a default Standard Hold Music')}}
        </DefaultModal>
    </div>
</template>

<script>
import l from '../../libs/lang';
import HelpText from '../elements/HelpText.vue';
import MOHSelector from '../elements/MOHSelector.vue';
import FormInput from '../elements/form/FormInput.vue';
import ExtensionSelector from './ExtensionSelector.vue';
import SectionTitle from '../elements/SectionTitle.vue';
import ClassicOrNxt from '../elements/ClassicOrNxt.vue';
import CancelChanges from '../elements/CancelChanges.vue';
import DefaultModal from '../elements/modal/DefaultModal.vue';
import TimezoneSelector from '../elements/TimezoneSelector.vue';

export default {
    props: ['_account', 'scroll_to'],
    components: {
        HelpText,
        FormInput,
        MOHSelector,
        SectionTitle,
        ClassicOrNxt,
        DefaultModal,
        CancelChanges,
        TimezoneSelector,
        ExtensionSelector,
    },
    data() {
        return {
            l,
            loading: null,
            alert: null,
            account: null,
            types: [
                { value: false, text: l.t('app.basic', 'Basic') },
                { value: true, text: l.t('app.premium-299', 'Premium  $2.99/mo') },
            ],
            may_submit: false,
            original_premium_moh_enabled: false,
            show_premium_modal: false,
            recording_plan_value: null,
        };
    },
    created() {
        this.$data.account = this.$props._account;
        this.$data.original_premium_moh_enabled = !!this.$data.account.features['premium-music'];
        this.$data.recording_plan_value = this.calculate_recording_plan_value();
        this.scroll_if_needed();
    },
    methods: {
        scroll_if_needed() {
            if (this.$props.scroll_to) {
                setTimeout(() => {
                    const el = document.getElementById(this.$props.scroll_to);
                    if (el) {
                    el.scrollIntoView({behavior: 'smooth', block: 'start'});
                    }
                }, 10);
            }
        },
        on_plan_change(data) {
            if (data !== 0 && !this.$data.account.features['call-recording-300'] && !this.$data.account.features['call-recording-1000']) {
                this.account.record_all_calls = 'all';
            }
            if (data === 1000) {
                this.$data.account.features['call-recording-1000'] = true;
                delete this.$data.account.features['call-recording-300'];
            } else if (data === 300) {
                delete this.$data.account.features['call-recording-1000'];
                this.$data.account.features['call-recording-300'] = true;
            } else if (data === 0) {
                this.account.record_all_calls = 'none';
                this.$data.account.features['call-recording-300'] = false;
                this.$data.account.features['call-recording-1000'] = false;
            }
            this.$data.recording_plan_value = this.calculate_recording_plan_value();
        },
        submitted(e) {
            if (e) e.preventDefault();
            this.$emit('submitted', this.$data.account);
            this.$data.may_submit = false;
        },
        check_if_premium_is_disabled() {
            if (!this.$data.account.features['premium-music'] && this.$data.original_premium_moh_enabled) {
                this.show_premium_modal = true;
            }
        },
        calculate_recording_plan_value() {
            if (this.$data.account.features['call-recording-1000']) {
                return 1000;
            }
            if (this.$data.account.features['call-recording-300']) {
                return 300;
            }
            return 0;
        }
    },
};
</script>
<style lang="scss">
.account-settings {
    .shorter-settings-label {
        width: 150px;
        margin-top: 16px;
    }
    .moh-advanced-label {
        @extend .shorter-settings-label;
        margin-top: 11px;
    }
    .recording-option {
        display: flex;
        margin-top: 9px;
        .custom-select {
            width: 300px;
        }
        .recording-helper-text {
            max-width: 375px;
            margin-top: 12px;
                margin-bottom: 12px;
        }
    }
    .premium-moh-selector {
        .type-radio-selector-helper-text {
            margin-top: -3px;
            margin-bottom: 11px;
            max-width: 325px;
        }
    }
    .selector-wrapper .v-input {
        width: 300px;
    }
}
</style>
