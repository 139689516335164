/* eslint array-callback-return : "off" */
import l from './lang';
import Helpers from './helpers';
import router from '../router';

class SidebarRouter {
	constructor(session, feature_toggle, branding) {
		this.session = session;
		this.feature_toggle = feature_toggle;
		this.branding = branding;
		this._routes = null;
		this.dev_mode = null;
		this.dev_feature = 'console.show_developer_feature';
		this.default_routes = {
			categories: {
			  'my-account': {
			    title: l.t('menu.my-account', 'My account'),
			    icon: '$vuetify.icons.person',
			    routes: SidebarRouter.take_category_routes('my-account'),
			    submenusRoutes: SidebarRouter.take_category_subroutes('my-account'),
			  },
			  'inbox': {
			    title: l.t('app.inbox', 'Inbox'),
			    icon: '$vuetify.icons.inbox',
			    routes: SidebarRouter.take_category_routes('inbox'),
			    submenusRoutes: SidebarRouter.take_category_subroutes('inbox'),
			  },
			  'people': {
				title: l.t('app.people', 'People'),
				icon: '$vuetify.icons.two_users',
			    routes: SidebarRouter.take_category_routes('people'),
			    submenusRoutes: SidebarRouter.take_category_subroutes('people'),
			  },
			  'calls-handling': {
			    title: l.t('app.call-handling', 'Call handling'),
			    icon: '$vuetify.icons.calls_handling',
			    routes: SidebarRouter.take_category_routes('calls-handling'),
			    submenusRoutes: SidebarRouter.take_category_subroutes('calls-handling'),
			  },
			  'conferencing': {
			    title: l.t('app.conferencing', 'Conferencing'),
			    icon: '$vuetify.icons.screen',
			    routes: SidebarRouter.take_category_routes('conferencing'),
			    submenusRoutes: SidebarRouter.take_category_subroutes('conferencing'),
			  },
			  'reports': {
			    title: l.t('app.reports', 'Reports'),
			    icon: '$vuetify.icons.paper_file',
			    routes: [
			        {
			          name: 'reports.show',
			          long_title: l.t('reports.sms-delivery-status-report', 'SMS delivery status report'),
			          title: l.t('reports.sms-delivery-status', 'SMS delivery status'),
			          params: {
			            type: 'sms',
			          },
			        },
			        {
			          name: 'reports.show',
			          title: l.t('reports.call-summary', 'Call summary'),
			          long_title: l.t('reports.call-summary-report', 'Call summary report'),
			          params: {
			            type: 'calls',
			            category: 'summary',
			          },
			        },
			        {
			          name: 'reports.show',
			          title: l.t('reports.call-types', 'Call types'),
			          long_title: l.t('reports.call-types-report', 'Call types report'),
			          params: {
			            type: 'calls',
			            category: 'types',
			          },
			        },
			        {
			          name: 'reports.show',
			          title: l.t('reports.calls-by-date', 'Calls by date'),
			          long_title: l.t('reports.calls-by-date-report', 'Calls by date report'),
			          params: {
			            type: 'calls',
			            category: 'traffic',
			          },
			        },
			        {
			          name: 'reports.show',
			          title: l.t('reports.calls-geo-distribution', 'Calls geo distribution'),
			          long_title: l.t('reports.calls-geo-distribution-report', 'Calls geo distribution report'),
			          params: {
			            type: 'calls',
			            category: 'geo-distribution',
			          },
			        },
			        {
			          name: 'reports.show',
			          title: l.t('reports.total-calls', 'Total calls'),
			          long_title: l.t('reports.total-calls-report', 'Total calls report'),
			          params: {
			            type: 'calls',
			            category: 'total',
			          },
			        },
			        {
			          name: 'reports.show',
			          title: l.t('reports.calls-per-extension', 'Calls per extension'),
			          long_title: l.t('reports.calls-per-extension-report', 'Calls per extension report'),
			          params: {
			            type: 'calls',
			            category: 'per-extension',
			          },
			        },
			        {
			          name: 'reports.show',
			          title: l.t('reports.calls-per-number', 'Calls per number'),
			          long_title: l.t('reports.calls-per-number-report', 'Calls per number report'),
			          params: {
			            type: 'calls',
			            category: 'per-number',
			          },
			        },
			      ],
			    submenusRoutes: [],
			  },
			  'tool-box': {
			    title: l.t('app.toolbox', 'Toolbox'),
			    icon: '$vuetify.icons.opened_box',
			    routes: SidebarRouter.take_category_routes('tool-box'),
			    submenusRoutes: SidebarRouter.take_category_subroutes('tool-box'),
			  },
			},
			bottom_routes: SidebarRouter.take_category_routes('bottom-route'),
			single_routes: SidebarRouter.take_category_routes('single-route'),
		};
	}

	static take_category_routes(category) {
		return router.reduce((a, c) => {
			if (c && c.meta && c.meta.category === category) {
				a.push({
					name: c.name,
					title: c.meta.title(),
					icon: c.meta.icon,
					features: c.meta.features,
				});
			}
			return a;
	    }, []);
	}

	static take_category_subroutes(category) {
		return router.reduce((a, c) => {
			if (c && c.meta && c.meta.submenu === category) {
				a.push(c.name);
			}
			return a;
	    }, []);
	}

	async get_routes() {
		if (this._routes) return this._routes;
		await this.checkAccountFeatures();
		let routes = {};
		if (this.branding.data.code === 'lapro') {
			const blocked_calls = this.default_routes.categories.people.routes.find((x) => x.name === 'blockedCalls.index');
			const addressbook = this.default_routes.categories.people.routes.find((x) => x.name === 'contacts.index');
			routes = {
				categories: {
					'my-account': {
						...this.default_routes.categories['my-account'],
						icon: '$vuetify.icons.calls_handling',
						routes: this.default_routes.categories['my-account'].routes.filter(
							(x) => x.name === 'call-logs'
						),
					},
					reports: {...this.default_routes.categories.reports},
				},
				single_routes: [
					{
						...blocked_calls,
						icon: 'mdi-block-helper',
					},
					{
						...addressbook,
						icon: '$vuetify.icons.persons',
					},
				],
				bottom_routes: this.default_routes.bottom_routes,
			};
		} else {
			routes = this.default_routes;
		}
		routes = await this.checkFeatureToggles(routes);
		this._routes = this.syncWithBraningRoutes(routes);

		return this._routes;
	}

	async checkFeatureToggles(routes) {
		if (!this.feature_toggle) return routes;
		this.dev_mode = await this.feature_toggle.isFeatureEnabled(this.dev_feature);
		if (this.dev_mode) return routes;
		if (routes.categories) {
			for (const cat of Object.keys(routes.categories)) {
				if (routes.categories[cat].routes && routes.categories[cat].routes.length) {
					routes.categories[cat].routes = await this.filterByToggles(routes.categories[cat].routes);
				}
			}
		}
		if (routes.bottom_routes && routes.bottom_routes.length) {
			routes.bottom_routes = await this.filterByToggles(routes.bottom_routes);
		}
		if (routes.single_routes && routes.single_routes.length) {
			routes.single_routes = await this.filterByToggles(routes.single_routes);
		}

		return routes;
	}

	async filterByToggles(routes) {
		const res = await Helpers.async_filter(routes, async (route) => {
				if (route.features && route.features.length) {
					for (const f of route.features) {
						if (await this.feature_toggle.isFeatureEnabled(f)) {
							return true;
						}
					}
					return false;
				}
				return true;
			});

		return res;
	}

	syncWithBraningRoutes(routes) {
	  for (const c in routes.categories) {
	    if (routes.categories[c]) {
	      for (const route of routes.categories[c].routes) {
	        if (!this.branding.data.routes.includes(route.name)) {
	          routes.categories[c].routes = routes.categories[c].routes.filter((x) => x.name !== route.name);
	        }
	      }
	    }
	  }
	  for (const c of Object.keys(routes.categories)) {
	    if (routes.categories[c].routes && !routes.categories[c].routes.length) {
	      delete routes.categories[c];
	    }
	  }
		if (this.branding.data.code === 'lapro') {
			for (const c of Object.keys(routes.categories)) {
			  if (routes.categories[c].routes && routes.categories[c].routes.length === 1) {
				routes.single_routes.unshift({
				  ...routes.categories[c].routes[0],
				  icon: routes.categories[c].icon,
				});
				delete routes.categories[c];
			  }
			}
		}
	  for (const single_r of routes.single_routes) {
	    if (!this.branding.data.routes.includes(single_r.name)) {
	      routes.single_routes = routes.single_routes.filter((x) => x.name !== single_r.name);
	    }
	  }
	  for (const bottom_r of routes.bottom_routes) {
	    if (!this.branding.data.routes.includes(bottom_r.name)) {
	      routes.bottom_routes = routes.bottom_routes.filter((x) => x.name !== bottom_r.name);
	    }
	  }

	  return routes;
	}

	async checkAccountFeatures() {
	  try {
	    if (this.branding.data.routes.includes('subaccounts.index')) {
	      if (!this.session.user.account.features['subaccounts-enabled']) {
	        this.default_routes.single_routes = this.default_routes.single_routes.filter((x) => x.name !== 'subaccounts.index');
	      }
	    }
	    if (this.branding.data.routes.includes('trunks.index') && !this.session.user.account.features['trunks-enabled']) {
	      this.default_routes.categories['my-account'].routes = this.default_routes.categories['my-account'].routes.filter((x) => x.name !== 'trunks.index');
	    }
	  } catch (err) {
	    console.log('Something went wrong while loading account data.');
	  }
	  return true;
	}
}

export default SidebarRouter;
