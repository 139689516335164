<template>
  <div class="media">
    <Submenu
      :routes="{
        'media.index': {
          title : l.t('app.greetings', 'Greetings'),
          subroutes: ['media.show']
        },
        'media.hold-music': {
          title: l.t('app.music-on-hold', 'Music on hold'),
          subroutes: ['media.music-on-hold-show']
        },
        'media.create': {
          title: l.t('media.add-greetings-music', 'Add greetings & music')
        },
        'media.loader': {
          title: l.t('media.loader', 'Mass loader')
        },
      }"
      :key="`${l.detect()}${$route.name}`"
    />
    <MediaFragment
      v-if="$route.name === 'media.index'"
      @deletingStarted="deleting_in_progress = true"
      @deletingEnded="deleting_in_progress = false"
      type="greetings"
      data-test-id="greetings"
    />
    <MediaFragment
      v-if="$route.name === 'media.hold-music'"
      @deletingStarted="deleting_in_progress = true"
      @deletingEnded="deleting_in_progress = false"
      type="hold_music"
      data-test-id="hold-music"
    />
    <AddMediaFragment
      v-if="$route.name === 'media.create'"
      data-test-id="media-create"
    />
    <ShowMediaFragment v-if="$route.name === 'media.show' || $route.name === 'media.music-on-hold-show'" data-test-id="media-show"/>
    <LoaderFragment v-if="$route.name === 'media.loader'" data-test-id="media-loader"/>
  </div>
</template>

<script>
import l from '../../../libs/lang';
import MediaFragment from './index.vue';
import LoaderFragment from './loader.vue';
import ShowMediaFragment from './show.vue';
import AddMediaFragment from './create.vue';
import Submenu from '../../elements/Submenu.vue';

export default {
  components: {
    Submenu,
    MediaFragment,
    LoaderFragment,
    AddMediaFragment,
    ShowMediaFragment,
  },
  data() {
    return {
      l,
    };
  },
  beforeRouteLeave(to, from, next) {
    if (window.deleting_in_progress) {
      this.$emit('userRedirected', to, from, next);
    } else {
      next();
    }
  },
};
</script>
