import Vue from 'vue';
import Rollbar from 'rollbar';
import FeatureTogglePlugin from '@dev.phone.com/pdc-feature-toggle-vue';
import App from './App.vue';
import Helpers from './libs/helpers';
import EventBus from './libs/EventBus';
import vuetify from './plugins/vuetify';
import version from '../version.json';
// eslint-disable-next-line no-unused-vars
import components from './libs/components'; // loads all global components

Vue.prototype.GOOGLE_TRACKING_CODE = process.env.VUE_APP_GOOGLE;
Vue.prototype.$global_emitter = EventBus;

// rollbar - https://docs.rollbar.com/docs/vue-js
const rollbar_payload = {
  environment: process.env.VUE_APP_STAGE,
  client: {
    javascript: {
      code_version: version.latest_version,
    }
  },
};
Vue.prototype.$rollbar = new Rollbar({
  autoInstrument: { log: false },
  accessToken: process.env.VUE_APP_ROLLBAR_TOKEN,
  captureUncaught: true,
  captureUnhandledRejections: true,
  payload: rollbar_payload,
});
// eslint-disable-next-line no-unused-vars
Vue.config.errorHandler = (err, vm, info) => {
  if (err && err.message && err.message.includes('Avoided redundant navigation to current location')) return null;
  vm.$rollbar.configure({
		payload: {
      ...rollbar_payload,
      person: {
        id: vm.$session && vm.$session.user ? vm.$session.user.id : null,
        name: vm.$session && vm.$session.user && vm.$session.user.account ? vm.$session.user.account.name : null,
        email: vm.$session && vm.$session.user && vm.$session.user.account && vm.$session.user.account.contact ? vm.$session.user.account.contact.primary_email : null,
      },
      fullstory_url: (window.FS && typeof window.FS.getCurrentSessionURL === 'function') ? window.FS.getCurrentSessionURL() : 'Not available',
		},
	});
  vm.$rollbar.error(err);
  throw err; // rethrow
};
Vue.config.productionTip = false;

Vue.use(FeatureTogglePlugin);

Vue.filter('format_duration', Helpers.format_duration);
Vue.filter('format_phone_number', Helpers.format_phone_number);

new Vue({
  render: (h) => h(App),
  vuetify,
}).$mount('#app');
