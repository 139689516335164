<template>
    <w-loader v-if="loading"/>
    <div v-else id="pdf-export">
        <!-- <LogarithmicSwitcher
            v-model="logarithmic"
            @input="logarithmic_representation"
            class="ml-5"
        /> -->
        <v-simple-table id="stats-table" data-test-id="per-ext-graph-table">
            <thead data-test-id="table-head">
                <tr data-test-id="head-row">
                    <th data-test-id="extension">{{ l.t('app.extension', 'Extension') }}</th>
                    <th v-for="(prop, i) in Object.keys(items)" :data-test-id="i">
                        {{ call.category_conf.fields[prop].translation }}
                    </th>
                </tr>
            </thead>
            <tbody data-test-id="table-body">
            <tr
                v-for="(extension, i) in call.category_conf.extensions"
                :data-test-id="`per-ext-graph-item-${i}-row`"
            >
                <td
                    :class="{ disabled: extension && typeof extension !== 'object' }"
                    :title="false"
                    data-test-id="extension"
                >
                    {{ extension | extensionName }}
                </td>
                <td v-for="prop in Object.keys(items)" :data-test-id="prop">
                    <HorizontalBar
                        :color="colors[findExtensionId(extension)]"
                        :item="items[prop].find(
                            (x) => x.extension === extension
                        )"
                        :title="title(items[prop].find(
                            (x) => x.extension === extension
                        ), prop)"
                        :max="Math.max.apply(
                            Math,
                            items[prop].map((x) => x.display_value)
                        )"
                        :data-test-id="`per-ext-graph-item-${i}-bar`"
                    />
                </td>
            </tr>
            <tr data-test-id="per-ext-bottom-graph">
                <td data-test-id="empty-cell"><br/></td>
                <td v-for="prop in Object.keys(items)" :data-test-id="prop">
                    <div class="d-flex justify-space-between">
                        <span class="min">
                            {{
                                Math.round(
                                    call.formatters[call.category_conf.fields[prop]['type']](
                                        Math.min.apply(
                                            Math,
                                            items[prop].map((x) => x.value)
                                        )
                                    )
                                )
                            }}
                        </span>
                        {{ l.t('app.calls-lowercase', 'calls') }}
                        <span class="max">
                            {{
                                Math.round(
                                    call.formatters[call.category_conf.fields[prop]['type']](
                                        Math.max.apply(
                                            Math,
                                            items[prop].map((x) => x.value)
                                        )
                                    )
                                )
                            }}
                        </span>
                    </div>
                </td>
            </tr>
            </tbody>
        </v-simple-table>
        <v-simple-table data-test-id="per-ext-table">
            <thead data-test-id="table-head">
                <tr data-test-id="head-row">
                    <th data-test-id="extension">{{ l.t('app.extension', 'Extension') }}</th>
                    <th v-for="(prop, i) in Object.keys(items)" :data-test-id="prop">
                        {{ call.category_conf.fields[prop].translation }}
                    </th>
                </tr>
            </thead>
            <tbody data-test-id="table-body">
                <tr v-for="(extension, i) in call.category_conf.extensions" :data-test-id="i">
                    <td
                        :class="{ disabled: extension && typeof extension !== 'object'}"
                        :title="false"
                        data-test-id="extension"
                    >
                        {{ extension | extensionName}}
                    </td>
                    <td v-for="prop in Object.keys(items)" :data-test-id="prop">
                        <div>
                            {{
                                Math.round(
                                    items[prop].find((x) => x.extension === extension).value
                                )
                            }}
                        </div>
                    </td>
                </tr>
            </tbody>
        </v-simple-table>
    </div>
</template>

<script>
  import { OrangeColor } from 'orange-colors';
  // import LogarithmicSwitcher from '../../../elements/logarithmic-representation-switcher.vue';

  import l from '../../../../libs/lang';
  import HorizontalBar from '../../../fragments/HorizontalBar.vue';

  export default {
    props: ['call', 'color'],
    components: {
      HorizontalBar,
      // LogarithmicSwitcher,
    },
    data() {
      return {
        logarithmic: false,
        l,
        loading: false,
        colors: {},
        items: null,
      };
    },
    async created() {
        if (this.$props.call.items) {
            this.$data.items = {...this.$props.call.items};
            for (const prop of Object.keys(this.$data.items)) {
                if (this.$data.items[prop].every((x) => !x.value)) delete this.$data.items[prop];
            }
            if (!Object.keys(this.$data.items)) return null;
            this.prepareExtensions();
        }
        this.logarithmic_representation();
        return true;
    },
    methods: {
        logarithmic_representation() {
            if (!this.items) return null;
            if (this.$data.logarithmic) {
                const values = [];
                for (const key of Object.keys(this.items)) {
                    this.items[key].map((x) => values.push(x.value));
                }
              const log_values = this.$props.call.category_conf.logarithmic_values([...values]);
              for (const key of Object.keys(this.items)) {
                this.items[key] = this.items[key].map((x) => {
                    x.display_value = log_values[x.value];
                    return x;
                });
              }
            } else {
                for (const key of Object.keys(this.items)) {
                  this.items[key] = this.items[key].map((x) => {
                      x.display_value = x.value;
                      return x;
                  });
                }
            }
            this.$forceUpdate();
            return true;
        },
      async prepareExtensions() {
        for (const extension of this.$props.call.category_conf.extensions) {
            this.$data.colors[this.findExtensionId(extension)] = this.colorByExtensionNumber(extension);
        }
      },
      colorByExtensionNumber(n) {
        let number = n;
        if (number && typeof number === 'object') number = `${number.extension}`;
        const c = new OrangeColor(this.$props.color);
        if (Number.parseInt(number)) {
          c.h = (Number.parseInt(number) % 18) * 20;
        } else {
          c.s = 0;
        }
        return c.html_string;
      },
      findExtensionId(extension) {
        if (extension && typeof extension === 'object') return extension.id;
        return extension;
      },
      title(item, prop) {
        return `${
                this.$options.filters.extensionName(item.extension)
            }: ${this.$props.call.formatters[this.$props.call.category_conf.fields[prop]['type']](item.value)}`;
      }
    },
    filters: {
        extensionName(extension) {
            if (extension && typeof extension === 'object') {
                return `${extension.extension}: ${extension.name}`;
            }
            if (extension === l.t('app.no-extension', 'No extension')) {
                return extension;
            }

            return `${l.t('app.deleted', 'Deleted')} ${extension}`;
        }
    },
  };
</script>
