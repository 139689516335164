<template>
    <MainTab
      v-if="link"
      class="submenu-category-title"
      data-test-id="bottom-navigation-route-lapro-help"
      :href="link"
    >
    <v-icon class="submenu-list-icon" color="text">$vuetify.icons.question</v-icon>{{ l.t('app.help', 'Help') }}
    </MainTab>
</template>
<script>
import l from '../../../libs/lang';
import MainTab from './MainTab.vue';
import Helpers from '../../../libs/helpers';

  export default {
    components: {
      MainTab,
    },
    data() {
      return {
        l,
        link: null,
      };
    },
    async created() {
      // we can't put links in branding setup because lapro is based on feature flag, not branding
      this.$data.link = await Helpers.get_help_link(this.$feature_toggle, this.$branding);
    },
  };
</script>
