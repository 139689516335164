<template>
  <div class="queues">
    <Submenu
      :routes="{
        'queues.index': {
          title: l.t('app.queues', 'Queues'),
          subroutes: ['queues.show']
          },
        'queues.create': {
          title: l.t('queues.add-queue', 'Add queue')
          },
      }"
      :key="`${l.detect()}${$route.name}`"
    />
    <QueuesFragment
      v-if="$route.name === 'queues.index'"
      @deletingStarted="deleting_in_progress = true"
      @deletingEnded="deleting_in_progress = false"
      data-test-id="queues-index"
    />
    <QueuesCreateFragment
      v-if="$route.name === 'queues.create'"
      data-test-id="queues-create"
    />
    <QueuesShowFragment
      v-if="$route.name === 'queues.show'"
      data-test-id="queues-show"
    />
  </div>
</template>

<script>
import l from '../../../libs/lang';
import QueuesFragment from './index.vue';
import QueuesShowFragment from './show.vue';
import QueuesCreateFragment from './create.vue';
import Submenu from '../../elements/Submenu.vue';

export default {
  components: {
    Submenu,
    QueuesFragment,
    QueuesCreateFragment,
    QueuesShowFragment,
  },
  data() {
    return {
      l,
      deleting_in_progress: false,
    };
  },
  beforeRouteLeave(to, from, next) {
    if (window.deleting_in_progress) {
      this.$emit('userRedirected', to, from, next);
    } else {
      next();
    }
  },
};
</script>
