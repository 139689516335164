import { parsePhoneNumber, AsYouType } from 'libphonenumber-js';
import l from './lang';
import Cachier from './Cachier';
import Countries from './geo/countries-codes.json';
import Version from '../../version.json';

const cachier = new Cachier();

const country_codes = [
  '93',
  '355',
  '213',
  '1',
  '376',
  '244',
  '672',
  '54',
  '374',
  '297',
  '61',
  '43',
  '994',
  '973',
  '880',
  '375',
  '32',
  '501',
  '229',
  '975',
  '591',
  '387',
  '267',
  '55',
  '246',
  '673',
  '359',
  '226',
  '257',
  '855',
  '237',
  '238',
  '236',
  '235',
  '56',
  '86',
  '57',
  '269',
  '682',
  '506',
  '385',
  '53',
  '599',
  '357',
  '420',
  '243',
  '45',
  '253',
  '670',
  '593',
  '20',
  '503',
  '240',
  '291',
  '372',
  '251',
  '500',
  '298',
  '679',
  '358',
  '33',
  '689',
  '241',
  '220',
  '995',
  '49',
  '233',
  '350',
  '30',
  '299',
  '502',
  '44',
  '224',
  '245',
  '592',
  '509',
  '504',
  '852',
  '36',
  '354',
  '91',
  '62',
  '98',
  '964',
  '353',
  '972',
  '39',
  '225',
  '81',
  '962',
  '7',
  '254',
  '686',
  '383',
  '965',
  '996',
  '856',
  '371',
  '961',
  '266',
  '231',
  '218',
  '423',
  '370',
  '352',
  '853',
  '389',
  '261',
  '265',
  '60',
  '960',
  '223',
  '356',
  '692',
  '222',
  '230',
  '262',
  '52',
  '691',
  '373',
  '377',
  '976',
  '382',
  '212',
  '258',
  '95',
  '264',
  '674',
  '977',
  '31',
  '687',
  '64',
  '505',
  '227',
  '234',
  '683',
  '850',
  '47',
  '968',
  '92',
  '680',
  '970',
  '507',
  '675',
  '595',
  '51',
  '63',
  '48',
  '351',
  '974',
  '242',
  '40',
  '250',
  '590',
  '290',
  '508',
  '685',
  '378',
  '239',
  '966',
  '221',
  '381',
  '248',
  '232',
  '65',
  '421',
  '386',
  '677',
  '252',
  '27',
  '82',
  '211',
  '34',
  '94',
  '249',
  '597',
  '268',
  '46',
  '41',
  '963',
  '886',
  '992',
  '255',
  '66',
  '228',
  '690',
  '676',
  '216',
  '90',
  '993',
  '688',
  '256',
  '380',
  '971',
  '598',
  '998',
  '678',
  '379',
  '58',
  '84',
  '681',
  '967',
  '260',
  '263',
];

const hide_alert = function (seconds, type) {
  const alertType = type || 'alert';
  setTimeout(() => {
    this.$data[alertType] = null;
  }, seconds * 1000);
};

const show_generic_error = function () {
  this.$data.alert = {
    message: this.$data.l.t('app.generic-error', 'Something went wrong'),
    level: 'error',
  };
  hide_alert.call(this, 10);
  window.scrollTo(0, 0);
  this.$data.loading = false;
};

const find_validation_message = function (err) {
  console.log(err);
  let message = null;
  if (err && err['@error'] && err['@error'].fields) {
    const { fields } = err['@error'];
    for (const field of Object.keys(fields)) {
      let key = field.replace('_', ' ').split('.');
      key = key.map((x) => x.charAt(0).toUpperCase() + x.slice(1));
      key = key.join('/');
      const keyMessage = Array.isArray(fields[field]) ? fields[field].join('. ') : fields[field];
      message = `${key}: ${keyMessage}`;
    }
  } else if (
    err
    && err['@error']
    && err['@error']['@message']
  ) {
    message = err['@error']['@message'];
  }

  return message;
};

const validation_error = function (err) {
  window.scrollTo(0, 0);
  try {
    const message = find_validation_message(err);

    if (message) {
      this.$data.alert = {
        message,
        level: 'error',
      };
      hide_alert.call(this, 10);
      this.$data.loading = false;
    } else {
      show_generic_error.call(this);
    }
  } catch (e) {
    show_generic_error.call(this);
  }
};

const country_name = function (country_code) {
  if (!country_code) {
    return '—';
  }

  const country = Countries.find((x) => x.alpha2 === country_code);
  if (country) return country.name;

  return '—';
};

const format_time = function (timestamp) {
  if (!timestamp || timestamp === -Infinity || timestamp === Infinity) return '—';
  if (Date.parse(timestamp)) return (new Date(Date.parse(timestamp))).toLocaleString();

  return (new Date(timestamp * 1000)).toLocaleString();
};

const format_date = function (val) {
  if (!val) return null;
  const date = new Date(val * 1000);
  return date.toLocaleString('en-US', {
      day: 'numeric',
      month: 'numeric',
      year: 'numeric'
  });
};
const format_phone_number = (phoneNumber) => {
  if (!phoneNumber) return '';
  let ret;
  if (phoneNumber.startsWith('+88351000')) { // iNum
    return `${phoneNumber.slice(0, 4)} (${phoneNumber.slice(4, 8)}) ${phoneNumber.slice(8)}`;
  }
  try {
      const parsedNum = parsePhoneNumber(phoneNumber, 'US');
      if (phoneNumber.includes('+1') || phoneNumber[0] !== '+') ret = parsedNum.formatNational();
      else ret = parsedNum.formatInternational();
  } catch (error) {
      ret = phoneNumber;
  }
  return ret;
};

const format_phone_number_as_you_type = (number) => {
  let phone_number = number;
  if (!phone_number) return '';
  if (!phone_number.startsWith('+') && phone_number[0] !== '1') {
    phone_number = `1${phone_number}`;
  }
  phone_number = phone_number.toString().replace(/\D/g, '');
  phone_number = `+${phone_number}`;

  if (phone_number.startsWith('+88351000')) { // iNum
    return `${phone_number.slice(0, 4)} (${phone_number.slice(4, 8)}) ${phone_number.slice(8)}`;
  }

  try {
    const pn = new AsYouType().input(phone_number);
    if (pn) {
      const parts = pn.split(' ');
      if (parts.length > 2) {
        return `${parts.shift()} (${parts.shift()}) ${parts.join('-')}`;
      }
    }
  } catch (err) {
    return phone_number;
  }

  return phone_number;
};

const format_phone_number_with_text = (number, no_code, text_value, text_start) => {
  let phone_number = number;
  if (!phone_number) return '';
  if (!text_value) return format_phone_number(number);
    phone_number = `${phone_number.slice(
      0,
      text_start,
    )}${text_value.toUpperCase()}${phone_number.slice(
      text_start + text_value.length,
    )}`;
  const r = /^(\+1)([0-9]{0,3})([0-9]{0,3})([0-9]{0,3})([0-9]{0,4})([A-Z]+)([0-9]{0,3})([0-9]{0,3})([0-9]{0,4})$/;
  let p = phone_number.match(r);
  if (p) {
    p = p
      .slice(no_code ? 2 : 1)
      .filter((v) => v.length);
      if (p.length) {
        let formatted_value = `${p[0]}`;
        if (p[1]) {
          formatted_value += ` (${p[1]})`;
        }
        if (p[2]) {
          formatted_value += ` ${p.slice(2).join('-')}`;
        }
        return formatted_value.trim();
      }
  }
  return format_phone_number(number);
};

const sessionAccountCacheKey = function (session) {
  return `user-account-${session.user.id}`;
};

const get_account_information = async function (session, ignore) {
  const ignoreCache = ignore || false;
  let account;
  if (session.user) {
    if (!session.user.account) {
      const session_cachier = new Cachier(session.user.id);
      if (!ignoreCache && session_cachier.getItem(sessionAccountCacheKey(session))) {
        account = session_cachier.getItem(sessionAccountCacheKey(session));
      } else {
        session_cachier.removeItem(sessionAccountCacheKey(session));
        account = await session.get_item('');
        if (account.default_voip_phone_id) {
          try {
            account.default_voip_phone = await session.get_item(
              `/extensions/${account.default_voip_phone_id}`
            );
          } catch (err) {
            if (err.status === 404) {
              account.default_voip_phone = null;
            } else {
              throw new Error(err);
            }
          }
        }
        session_cachier.setItem(sessionAccountCacheKey(session), account);
      }
    } else {
      // eslint-disable-next-line prefer-destructuring
      account = session.user.account;
    }
    return JSON.parse(JSON.stringify(account));
  }

  return null;
};

const token_details = async function (session, ignore) {
  const ignore_cache = ignore || false;
  if (!session) throw new Error('Session not available');
  let token_info_cache_key;
  const token_cachier = new Cachier(session.user.id);
  const token_info_cache_key_cache_key = 'token-details';
  if (!ignore_cache && token_cachier.getItem(token_info_cache_key_cache_key)) {
    token_info_cache_key = token_cachier.getItem(token_info_cache_key_cache_key).value;
  } else {
    token_cachier.removeItem(token_info_cache_key_cache_key);
    const response = await session.call_api('get', '/v4/oauth/access-token', null, true);
    token_info_cache_key = response;
    token_cachier.setItem(token_info_cache_key_cache_key, { value: token_info_cache_key });
  }
  return token_info_cache_key;
};

const nxt_details = async function (session, ignore) {
  const ignore_cache = ignore || false;
  if (!session) throw new Error('Session not found');
  let user_nxt_details;
  const nxt_cachier = new Cachier(session.user.id);
  const is_user_next_cache_key = 'user-is-nxt';
  if (!ignore_cache && nxt_cachier.getItem(is_user_next_cache_key)) {
    user_nxt_details = nxt_cachier.getItem(is_user_next_cache_key).value;
  } else {
    nxt_cachier.removeItem(is_user_next_cache_key);
    const token_info = await token_details(session, ignore_cache);
    user_nxt_details = token_info.nxt_details;
    nxt_cachier.setItem(is_user_next_cache_key, { value: user_nxt_details });
  }
  return user_nxt_details;
};

const nxt_company_inbox_extension = async function (session, ignore) {
  const user_nxt_details = await nxt_details(session, ignore);
  if (user_nxt_details) {
    if (user_nxt_details.company_inbox_extension) return user_nxt_details.company_inbox_extension;
    // if (Array.isArray(user_nxt_details.user_extensions) && user_nxt_details.user_extensions.length) {
    //   return user_nxt_details.user_extensions[0];
    // }
  }

  return null;
};

const nxt_user_extensions = async function (session, ignore) {
  const user_nxt_details = await nxt_details(session, ignore);
  if (user_nxt_details) {
    if (Array.isArray(user_nxt_details.user_extensions) && user_nxt_details.user_extensions.length) {
      return user_nxt_details.user_extensions;
    }
  }

  return null;
};

const is_nxt = async function (session, ignore) {
  const user_nxt_details = await nxt_details(session, ignore);
  return !!user_nxt_details;
};

const is_csr = async function (session, ignore) {
  const token_info = await token_details(session, ignore);
  const scopes = token_info.scope.split(' ');
  return !!scopes.find((x) => x.startsWith('csr'));
};

const has_scope = async function (session, scope, ignore) {
  const token_info = await token_details(session, ignore);
  const scopes = token_info.scope.split(' ');
  return !!scopes.find((x) => x.startsWith(scope));
};

const format_duration = (duration) => {
  if (duration === 0) return '00:00:00';
  let hours = 0;
  if (!duration || duration === Infinity || duration === -Infinity) return '—';
  let min = Math.floor(duration / 60);
  if (min >= 60) {
    hours = Math.floor(min / 60);
    min %= 60;
  }
  const sec = (Math.round(duration % 60)).toFixed(1);
  return `${hours < 10 ? `0${hours}` : hours}:${min < 10 ? `0${min}` : min}:${sec < 10 ? '0' : ''}${sec}`;
};

const format_duration_non_numeric = function (duration) {
    if (duration === 0) return `${0}${l.t('app.sec', 'sec')}`;
    let days = 0;
    let hours = 0;
    if (!duration || duration === Infinity || duration === -Infinity) return '—';
    let min = Math.floor(duration / 60);
    if (min >= 60 * 24) {
      days = Math.floor(min / (60 * 24));
      min %= (60 * 24);
    }
    if (min >= 60) {
      hours = Math.floor(min / 60);
      min %= 60;
    }
    const sec = Math.round((duration % 60) * 10) / 10;
    return `${days ? `${days}${l.t('app.d', 'd')} ` : ''}${hours ? `${hours}${l.t('app.h', 'h')} ` : ''}${min ? `${min}${l.t('app.mi', 'min')} ` : ''} ${sec ? `${sec}${l.t('app.sec', 'sec')}` : ''}`;
};

const is_numeric = (value) => /^-?\d+$/.test(value);

const formFileToBase64 = (file) => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => resolve(reader.result.split(',').slice(-1).join(','));
  reader.onerror = (error) => reject(error);
});

const emitter = function (obj) {
  return (...args) => {
    obj.$emit(...args);
  };
};

const changeRoute = function (obj) {
  return (name, params) => {
    obj.$router.push({ name, params });
  };
};
const session_main_account_cache_key = 'main_session_account_user_id';

const use_subaccount = function (session, item, subaccounts_owner_mode) {
  cachier.setItem(session_main_account_cache_key, session.user);
  cachier.removeItem(sessionAccountCacheKey(session));
  session.user.id = item.id;
  if (subaccounts_owner_mode) {
    session.user.subaccounts_owner_is_subaccount = true;
  } else {
    session.user.is_subaccount = true;
  }
  session.user.account = item.account;

  return session;
};

const use_main_account = function (session) {
  const main_user = cachier.getItem(session_main_account_cache_key);
  if (main_user) {
    session.user = main_user;
    return session;
  }
  throw new Error('Main account could not be found');
};

const randomNumber = function () {
  return Math.floor(Math.random() * 1000000000);
};

const does_element_parent_has_class = function (e, classname) {
  let element = e;
  do {
    if (!element) return true;
    if (element.classList && element.classList.contains(classname)) {
      return true;
    }
    element = element.parentNode;
  } while (element !== document.body); // vue removes element faster than this function runs and it happens that it closes the modal when you remove element from it. So IF we check for the existence of an element, it's not reliable, we have to go to the top
  return false;
};

const get_app_version = () => {
  try {
    let version = '';
    let env = process.env.VUE_APP_STAGE;
    env = env.charAt(0).toUpperCase() + env.slice(1);
    version = `${Version.latest_version} (${env})`;
    return {
     long: `${l.t('app.version', 'Version')}: ${version}`,
     short: version,
    };
  } catch (err) {
    return null;
  }
};

const async_filter = async (arr, asyncCb) => {
  const results = await Promise.all(arr.map(asyncCb));

  return arr.filter((_v, index) => results[index]);
};

const prevent_double_space = (e) => {
  if (e.keyCode === 32 && e.target && e.target.value) {
    const position = e.target.selectionStart;
    if (position > -1) {
      const prev_char = e.target.value[position - 1];
      if (prev_char === ' ') {
         e.preventDefault();
        return true;
      }
      const next_char = e.target.value[position];
      if (next_char === ' ') {
         e.preventDefault();
        return true;
      }
    }
  }

  return null;
};

const prevent_number = (e) => {
  const numeric_codes = [
    48, 49, 50, 51, 52, 53, 54, 55, 56, 57,
    96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 109, 110, 111, // numberic pad
  ];
  const key_code = e.keyCode || e.which;
  if (numeric_codes.includes(key_code)) {
    e.preventDefault();
    return true;
  }

  return null;
};

const create_default_contact_groups = async (session, ext) => {
  const url = `/extensions/${ext}/contact-groups`;
  const groups = [];
  for (const name of ['Friends', 'Family', 'Co-Workers']) {
    try {
        groups.push(await session.create_item(url, {name}));
    } catch (err) {
        console.log(`Error while creating default contact group: ${name}`);
    }
  }

  return groups;
};

const always_allowed_keys = [
    'Backspace',
    'Tab',
    'Enter',
    'ArrowUp',
    'ArrowDown',
    'ArrowLeft',
    'ArrowRight',
    'Home',
    'End',
    'PageUp',
    'PageDown',
    'Delete',
    'NumLock',
    'CapsLock'
];

const empty_route_rule = () => ({
  actions: [{
    action: 'disconnect',
  }],
});

const get_first_and_last_name = (name) => {
  const white_space_index = name.indexOf(' ');
  let first_name = '';
  let last_name = '';
  if (white_space_index > -1) {
    const regex = /[^a-zA-Z0-9_ '-]/g;
    first_name = name.substring(0, white_space_index).replace(regex, '');
    last_name = name.substring(white_space_index).replace(regex, '');
  } else {
    first_name = name;
    last_name = name;
  }

  return {first_name: first_name.trim(), last_name: last_name.trim()};
};

const submit_hubspot_form = async (account, form_id, form_config = {}) => {
  if (!account) throw new Error('Error submitting hubspot form. Account not available.');

  const default_config = {
    portal_id: '6860053',
    sfdc_campaign_id: '7011K0000014F5mQAE',
    page_uri: `${document.location.protocol}//${document.location.hostname}`,
    page_name: 'Phone.com > Console',
  };
  const config = {
    ...default_config,
    ...form_config,
  };

  const {primary_email, company, phone} = account.contact;
  const {first_name, last_name} = get_first_and_last_name(account.name);

  const response = await fetch(
    `https://api.hsforms.com/submissions/v3/integration/submit/${config.portal_id}/${form_id}`,
    {
      method: 'POST',
      headers: {
          'Content-Type': 'application/json'
      },
      body: JSON.stringify({
          fields: [
              {
                  objectTypeId: '0-1',
                  name: 'email',
                  value: primary_email,
              },
              {
                  objectTypeId: '0-1',
                  name: 'firstname',
                  value: first_name,
              },
              {
                  objectTypeId: '0-1',
                  name: 'lastname',
                  value: last_name,
              },
              {
                  objectTypeId: '0-1',
                  name: 'company',
                  value: company,
              },
              {
                  objectTypeId: '0-1',
                  name: 'phone',
                  value: phone,
              }
          ],
          context: {
              pageUri: config.page_uri,
              pageName: config.page_name,
              sfdcCampaignId: config.sfdc_campaign_id,
          }
      })
    }
  );

  // catch error if response is not ok
  if (!response.ok) {
      throw new Error('Error submitting hubspot form');
  }
  return response.json();
};

const validate_address = async function (session, address) {
  const valid_addresses_cache_key = 'valid-addresses';
  try {
    const address_cachier = new Cachier(session.user.id);
    const valid_addresses = address_cachier.getItem(valid_addresses_cache_key) || [];
    let validated_address = {address};
		if (!valid_addresses.includes(JSON.stringify(address).toLowerCase())) {
			validated_address = await session.create_item(
				'/contacts?validation=usps&validation_only=1', { address }
			);
			valid_addresses.push(JSON.stringify(validated_address.address).toLowerCase());
			address_cachier.setItem(valid_addresses_cache_key, [...new Set(valid_addresses)]);
		}

    return { success: true, validated_address: validated_address.address };
  } catch (err) {
    console.log(err);
    let message = '';
		if (err && err && err['@error'] && err['@error'].fields) {
			message = err['@error'].fields;
		}

    return { success: false, message };
  }
};

const stringifyAddress = (data) => {
    if (!data) return '—';
    let lines = data.line_1 || '';
    if (data.line_2) lines += ` ${data.line_2}`;
    lines = lines.trim();
    const parts = [
      lines,
      data.postal_code,
      data.city,
      data.province,
      country_name(data.country)
    ].filter((x) => x);
    if (parts.length) return parts.join(', ');

    return '—';
};

const get_help_link = async (feature_toggle, branding) => {
  const is_preprod = process.env.VUE_APP_STAGE === 'preprod';
  if (branding.data.code === 'idseal') {
    return 'https://www.idseal.com/support';
  }
  if (branding.data.code === 'tailor') {
    return 'https://support.tailorbrands.com/hc/en-us';
  }
  const has_lepro_help_link = await feature_toggle.isFeatureEnabled('console.show_lapro_help_link');
  const has_avo_help_link = await feature_toggle.isFeatureEnabled('console.show_avo_help_link');
  if (!['avo', 'avo_master'].includes(branding.data.code) && !has_avo_help_link && !has_lepro_help_link) {
    return is_preprod ? 'https://dev.www.phone.com/support/' : 'https://www.phone.com/support/';
  }
  if (has_avo_help_link) {
    return is_preprod ? 'https://dev.www.phone.com/splash-pages/reccp-faq/' : 'https://www.phone.com/splash-pages/reccp-faq/';
  }
  if (['avo', 'avo_master'].includes(branding.data.code)) {
    return 'https://www.alliancevirtualoffices.com/faq';
  }

  return null;
};

const user_can_access_page_middleware = async (route, feature_toggle, branding) => {
  if (route && route.meta && route.meta.features && feature_toggle) {
    if (!route.meta.features.length) return true;
    for (const feature of route.meta.features) {
      if (await feature_toggle.isFeatureEnabled(feature)) {
        return true;
      }
    }

    return false;
  }

  if (
    branding
    && branding.data
    && branding.data.routes
    && branding.data.routes.length
    && !branding.data.routes.includes(route.name)
  ) {
    return false;
  }

  return true;
};

export default {
  emitter,
  changeRoute,
  hide_alert,
  show_generic_error,
  validation_error,
  country_name,
  format_phone_number,
  format_phone_number_as_you_type,
  format_phone_number_with_text,
  format_duration,
  format_duration_non_numeric,
  format_time,
  format_date,
  get_account_information,
  formFileToBase64,
  use_subaccount,
  use_main_account,
  randomNumber,
  does_element_parent_has_class,
  get_app_version,
  async_filter,
  always_allowed_keys,
  prevent_double_space,
  prevent_number,
  nxt_details,
  is_nxt,
  empty_route_rule,
  is_numeric,
  get_first_and_last_name,
  submit_hubspot_form,
  token_details,
  is_csr,
  has_scope,
  create_default_contact_groups,
  validate_address,
  stringifyAddress,
  get_help_link,
  nxt_company_inbox_extension,
  nxt_user_extensions,
  country_codes,
  user_can_access_page_middleware,
};
