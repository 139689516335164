<template>
    <w-loader v-if="device.loading"/>
    <div v-else class="create-page" data-test-id="devices-create-page">
        <w-alert
          v-if="device.alert"
          :message="device.alert.message"
          :level="device.alert.level"
          :closable="device.enable_close_alert"
          @closed="device.alert = null"
          class="mb-6"
        />
        <DeviceFragment
          v-if="device.item"
          :_device="device.item"
          @updateDevice="device.create($event)"
        />
    </div>
</template>

<script>
  import Helpers from '../../../libs/helpers';
  import Device from '../../../models/Device';
  import DeviceFragment from '../../fragments/Device.vue';

  export default {
    components: {
      DeviceFragment,
    },
    data() {
      return {
        device: new Device(this.$session, Helpers.emitter(this), Helpers.changeRoute(this)),
      };
    },
  };
</script>
