<template>
    <v-menu
      top
      :offset-x="true"
      v-bind="$attrs"
      v-on="$listeners"
      content-class="menu-options-list"
    >
      <template v-slot:activator="{ on, attrs }">
        <w-btn
          :height="32"
          :width="32"
          icon
          :ripple="false"
          v-bind="attrs"
          v-on="on"
          class="more-options-button"
          :class="activator_classes"
          data-test-id="more-options-trigger"
        >
          <v-icon color="text" size="18">{{icon || '$vuetify.icons.more_vert'}}</v-icon>
        </w-btn>
      </template>
      <div
          v-for="(option, i) in options"
          @click="option.cb()"
          class="w-body-2 menu-list-item"
          :data-test-id="`more-option-item-${i}`"
      >
          {{option.title}}
      </div>
    </v-menu>
</template>
<script>
  export default {
    props: {
        options: {
            required: true,
            type: Array,
            validator: (v) => v.every((x) => x && typeof x === 'object' && typeof x.cb === 'function' && x.title),
        },
        activator_classes: {
          type: String,
          required: false,
        },
        icon: {
          type: String,
        }
    },
  };
</script>
