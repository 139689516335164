<template>
    <div v-bind="$attrs" v-on="$listeners" class="d-flex submenu-category-title">
        <v-icon size="22" class="submenu-list-icon" color="text">{{ icon }}</v-icon>
        <div class="w-body-1 my-auto">
        <div v-if="title">{{title}}</div>
        <slot v-else/>
        </div>
    </div>
</template>
<script>
  export default {
    props: ['icon', 'title'],
  };
</script>
