<template>
    <w-loader v-if="billing.loading"/>
    <div v-else class="list-page billings">
        <w-alert
            v-if="billing.alert"
            :message="billing.alert.message"
            :level="billing.alert.level"
            :closable="billing.enable_close_alert"
            @closed="billing.alert = null"
            class="mb-6"
        />
        <v-container fluid>
            <v-row v-if="billing.invoices && billing.invoices.length">
                <v-col md="12" class="do-not-print">
                    <div class="w-body-1">
                        {{ l.t('billing.blling-periods', 'Billing periods') }}
                    </div>
                </v-col>
                <v-col md="6" class="pb-7">
                    <div class="d-flex w-print-100">
                        <w-select :items="billing_dates" v-model="current_invoice" @change="invoice_changed" class="mr-6" hide-details="auto" />
                        <w-btn v-if="billing.invoice" @click="billing.exportPdf()" color="primary" id="save-pdf"
                            class="primary--text text--darken-1 primary lighten-5 my-auto do-not-print" data-test-id="invoice-export-pdf-btn">
                            <v-icon color="primary" class="mr-1" size="18">
                                $vuetify.icons.download_cloud_inverted
                            </v-icon>
                            {{ l.t('app.get-pdf', 'Get PDF') }}
                        </w-btn>
                    </div>
                </v-col>
                <v-col md="6" class="pb-7"></v-col>
                <v-col md="12" class="w-print-100">
                    <w-loader v-if="billing.loading_invoice" class="loading-invoice"/>
                    <Invoice
                        v-if="billing.invoice && billing.invoice.id === current_invoice"
                        :billing="billing"
                        data-test-id="billing-invoice-item"
                    />
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
  import l from '../../../libs/lang';
  import Invoice from '../../fragments/Invoice.vue';
  import FormInput from '../../elements/form/FormInput.vue';

  export default {
    components: {
      Invoice,
      FormInput,
    },
    props: ['billing'],
    data() {
      return {
        l,
        show_invoices: true,
        show_payment_methods: true,
        current_invoice: null,
      };
    },
    async created() {
      this.$data.current_invoice = Number.parseInt(this.$route.params.id);
      await this.$props.billing.loadInvoices();
      if (this.$route.name === 'billing.statement') {
        await this.$props.billing.showInvoice(this.$route.params.id);
      } else if (this.$route.name === 'billing.index' && this.$props.billing.invoices && this.$props.billing.invoices.length) {
        this.$router.push({ name: 'billing.statement', 'params': {'id': this.$props.billing.invoices[0].id} });
      }
    },
    methods: {
        format_date(val) {
            if (val === 0) return l.t('app.recent-activity', 'Recent activity');
            if (!val) return false;
            const date = new Date(val * 1000);
            return date.toLocaleString('en-US', {
                day: 'numeric',
                month: 'long',
                year: 'numeric',
            });
        },
        invoice_changed(data) {
            this.$router.push({
                'name': 'billing.statement',
                'params': {'id': data},
            });
        },
    },
    computed: {
        billing_dates() {
            return this.$props.billing.invoices.map(
                (x) => ({value: x.id, text: this.format_date(x.closed_at)})
            ).filter((x) => x.text);
        },
    },
  };
</script>
