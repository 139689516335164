<template>
  <w-loader v-if="trunk.loading"/>
  <div v-else class="create-page" data-test-id="trunks-create-page">
      <w-alert
        v-if="trunk.alert"
        :message="trunk.alert.message"
        :level="trunk.alert.level"
        :closable="trunk.enable_close_alert"
        @closed="trunk.alert = null"
        class="mb-6"
      />
        <TrunkFragment
          v-if="this.trunk.can_visit && (!trunk.alert || (trunk.alert && trunk.alert.level !== 'success'))"
          :_trunk="trunk.item"
          @submited="trunk.create($event)"
        />
  </div>
</template>

<script>
  import l from '../../../libs/lang';
  import Trunk from '../../../models/Trunk';
  import Helpers from '../../../libs/helpers';
  import TrunkFragment from '../../fragments/Trunk.vue';

  export default {
    components: {
      TrunkFragment,
    },
    async created() {
      await this.$data.trunk.checkIfTrunksAreEnabled();
    },
    data() {
      return {
        l,
        trunk: new Trunk(this.$session, Helpers.emitter(this), Helpers.changeRoute(this)),
      };
    },
  };
</script>
