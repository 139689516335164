import Resource from './Resource';
import setup from '../libs/resources-setups/schedules';

export default class Schedule extends Resource {
    constructor(session, emitter, changeRoute) {
        super(session, emitter, '/schedules', changeRoute);
        this.selector_mode = ['schedules'];
        this.setup = setup;
        this.filters = {
            name: null,
        };
        this.days = ['S', 'Su', 'M', 'T', 'W', 'Th', 'F'];
        this.item = {
            name: null,
            timezone: '',
            items: this.days.map((x) => ({
                day: x,
                holiday_id: null,
                days_before: 0,
                days_after: 0,
                start_date: 0,
                end_date: 0,
                start_hour: null,
                start_minute: null,
                end_hour: null,
                end_minute: null,
                voip_destination_id: 0,
                use_once: null,
                expiration_date: null,
            })),
        };
    }

    async loadItems(params) {
        this.loading = true;
        try {
            const offset = this.checkIfUserDeletedItems(params.offset, params.page);
            const items = await this.session.get_list(
                this.uri, params.limit, offset,
            );

            this.page = params.page;
            if (this.page > 1 && !items.items.length) {
                this.emitter('pageChanged', 1);
                return this.loadItems({
                    limit: this.limit,
                    offset: 0,
                    page: 1,
                });
            }
            if (this.page === 1 && !this.filters_applied && !items.items.length) {
                return this.changeRoute('schedules.create');
            }
            this.items = items.items;
            this.finalizeLoadingItems(items);
            this.emitter('itemsLoaded', JSON.parse(JSON.stringify(items)));
        } catch (err) {
            this.validation_error(err);
        }
        this.loading = false;

        return this.items;
    }

    async update(schedule) {
        this.loading = true;
        try {
            this.item = schedule;
            this.item = await this.session.replace_item(
                `${this.baseUri}/${this.item.id}`,
                this.item,
            );
            this.updateCache(this.item);
            this.item = null;
            this.successfulUpdate('schedules.index');
        } catch (err) {
            this.validation_error(err);
        }
        this.loading = false;
    }

    async create(schedule) {
        this.loading = true;
        try {
            const newSchedule = await this.session.create_item('/schedules', schedule);
            this.item = null;
            this.addToCache(newSchedule);
            this.successfulCreation('schedules.index');
        } catch (err) {
            this.validation_error(err);
        }
    }

    apply_filters() {
        this.loading = true;
        this.filters_applied = true;
        try {
            let uri = `${this.baseUri}?mode=full`;
            if (this.filters.name) uri = `${uri}&filters[name]=contains:${this.filters.name}`;
            this.uri = uri;
        } catch (err) {
            this.emitter('failed', err);
        }
        this.loading = false;
    }
}
