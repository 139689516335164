<template>
    <div class="devices">
      <Submenu
        :routes="{
          'devices.index': {
            title: l.t('app.devices', 'Devices'),
            subroutes: ['devices.show']
          },
          'devices.create': {
            title: l.t('menu.add-generic-device', 'Add generic device')
          },
          'devices.buy': {
            title: l.t('menu.buy-device', 'Buy device')
          },
          'devices.my-orders': {
            title: l.t('menu.my-orders', 'My orders'),
            subroutes: ['devices.my-orders.show']
          },
        }"
        :key="`${l.detect()}${$route.name}`"
      />
      <DevicesFragment
        v-if="$route.name === 'devices.index'"
        @deletingStarted="deleting_in_progress = true"
        @deletingEnded="deleting_in_progress = false"
        data-test-id="devices-list"
      />
      <DevicesCreateFragment
        v-if="$route.name === 'devices.create'"
        data-test-id="devices-create"
      />
      <DevicesShowFragment
        v-if="$route.name === 'devices.show'"
        data-test-id="devices-show"
      />
      <DevicesBuyFragment
        v-if="$route.name === 'devices.buy'"
        data-test-id="devices-buy"
      />
      <DevicesMyOrdersFragment
        v-if="$route.name === 'devices.my-orders'"
        data-test-id="devices-orders"
      />
      <DevicesOrderFragment
        v-if="$route.name === 'devices.my-orders.show'"
        data-test-id="devices-orders-show"
      />
    </div>
</template>

<script>
  import l from '../../../libs/lang';
  import DevicesFragment from './index.vue';
  import DevicesShowFragment from './show.vue';
  import DevicesCreateFragment from './create.vue';
  import DevicesBuyFragment from './buy.vue';
  import Submenu from '../../elements/Submenu.vue';
  import DevicesOrderFragment from './orders/show.vue';
  import DevicesMyOrdersFragment from './orders/index.vue';

  export default {
    components: {
      Submenu,
      DevicesFragment,
      DevicesBuyFragment,
      DevicesShowFragment,
      DevicesOrderFragment,
      DevicesCreateFragment,
      DevicesMyOrdersFragment,
    },
    data() {
      return {
        l,
        deleting_in_progress: false,
      };
    },
    beforeRouteLeave(to, from, next) {
      if (window.deleting_in_progress) {
        this.$emit('userRedirected', to, from, next);
      } else {
        next();
      }
    },
  };
</script>
