<template>
    <w-form
        v-if="item && la.item"
        @submit.prevent="submitted"
        @changed="may_submit = true"
        class="live-receptionist mx-auto"
        data-discard="true"
        data-test-id="lr"
        autocomplete="off"
        data-lpignore="true"
    >
        <HelpText v-if="!['avo', 'avo_master'].includes($branding.data.code)" class="py-0">
            {{ l.t('la.pdc-offers-both-asnwer-bot', 'Phone.com offers both live reception services and our virtual reception service, the Intelligent Answer Bot.  Live receptionist service is perfect for giving your callers a white glove experience.  The Answer Bot is ideal for greeting customers and routing calls to the right person or team.') }}
        </HelpText>
        <div v-if="!['avo', 'avo_master'].includes($branding.data.code)" class="m-left-45">
            <div :class="classes.field_titles" class="mb-2" data-test-id="lr-provider-title">
                {{ l.t('app.select-service-type', 'Select your service type') }}
            </div>
            <VendorsSelector
                :value="item.vendor"
                @input="vendor_changed($event)"
                :disabled="!!item.id || !item.enabled"
                class="provider-selector"
                data-test-id="lr-vendor-selector"
            />
            <template v-if="item.vendor && item.vendor.id === 1">
                <LRCallWarning />
                <ExpertCallButton class="mt-1"/>
            </template>
            <template v-if="item.vendor && item.vendor.id === la.onvego_id">
                <OnvegoCallWarning />
                <HelpText v-if="item.vendor && item.vendor.id === la.onvego_id">
                    {{
                        l.t('la.onvego-great-choice-intelligent-answer-bot-applicable-taxes', 'Great choice! When you apply this script to a user, number, or extension, our Intelligent Answer Bot will manage your calls 24x7.  Applicable taxes will be added.')
                    }}
                </HelpText>
            </template>
            <div v-if="(item.vendor && item.vendor.id !== la.onvego_id)">
                <template v-if="codes && codes.length > 1">
                    <div :class="`${classes.field_titles} mt-0`" data-test-id="lr-plan-title">
                        {{ l.t('lr.select-plan', 'Select a plan') }}
                        <InfoLink
                            max-width="300"
                            key="live-answer.plan-help-text"
                            data-test-id="lr-plan-info-link"
                        >
                            <template v-slot:icon>
                                <v-icon size="19" class="pb-1" color="#6E7A82">
                                $vuetify.icons.infotip_stylized
                                </v-icon>
                            </template>
                            {{ l.t(
                                'live-answer.plan-help-text',
                                'There is no setup fee, no contract, and you can cancel any time. You are only billed for the time your live receptionist is on the phone with you or your customers. Billed minutes are rounded up per call. Overage minutes are billed at $1.20 per minute.'
                            ) }}
                        </InfoLink>
                    </div>
                    <div :class="classes.field_explanation">
                        {{l.t('lr.how-many-minutes-per-month', 'How many minutes per month are you expecting calls received by your Live receptionist?')}}
                    </div>
                    <w-select
                        :disabled="!item.enabled"
                        v-model="item.code"
                        :placeholder="l.t('lr.select-your-plan', 'Select your plan')"
                        :rules="[$validation.required()]"
                        :items="codes"
                        hide-details="auto"
                        data-test-id="lr-plan-select"
                    />
                </template>
                <w-alert
                    v-else-if="!codes && (Array.isArray(codes) && !codes.length)"
                    level="error"
                    :message="l.t('lr.no-codes-for-vendor', 'Currently there is no plans for this vendor, please select another one.')"
                    class="my-3"
                />
            </div>
        </div>
        <template v-if="item.code">
            <div class="m-left-45">
                <v-divider v-if="!['avo', 'avo_master'].includes($branding.data.code)" class="light-divider provider-divider" />
                <div id="lr-title" class="w-h6 info--text text--darken-1">{{ title || l.t('lr.your-script', 'Your script') }}</div>
                <div class="w-body-2 mb-7 mt-2" data-test-id="lr.tiitle-help-text">
                    <template v-if="item.vendor.id === 4">
                        {{ l.t('lr.configure-how-intelligent-answer-bot-will-great-calls', 'Configure how the Intelligent Answer Bot will present your company and redirect calls')}}
                    </template>
                    <template v-else>
                        {{ l.t('lr.configure-how-ive-receptionist', 'Configure how Live receptionist will present your company and redirect calls.') }}
                    </template>
                </div>
                <LRCallWarning v-if="['avo', 'avo_master'].includes($branding.data.code) && !item.id" />
            </div>
            <div class="max-width-630 mx-auto">
                <div :class="classes.field_titles" class="mb-3" data-test-id="create-new-script-title">
                {{ l.t('lr.give-name', 'Give your script a name') }}
            </div>
            <w-text-field v-model="item.name" :disabled="!item.enabled" :placeholder="l.t('lr.enter-script-name', 'Enter a name for your script')"
                type="text" :rules="[$validation.required()]" class="w-100" hide-details="auto" data-test-id="lr-name-input" />
            <template v-if="not_visible_for_onvego">
                <div :class="classes.field_titles" class="mb-2" data-test-id="lr-bus-profile-title">
                    {{ l.t('lr.business-profile', 'Business profile') }}
                </div>
                <w-text-field
                    v-model="item.business_name"
                    :placeholder="l.t('app.business-name', 'Business name')"
                    :disabled="!item.enabled"
                    :rules="[$validation.required()]"
                    class="w-100"
                    hide-details="auto" data-test-id="lr-businesss-name-input"
                    autocomplete="new-password" data-lpignore="true"
                />
            </template>
            <template v-if="not_visible_for_onvego">
                <div :class="classes.ordinary_field_title" data-test-id="lr-business-description-title">
                    {{ l.t('lr.business-description', 'Business description') }}
                    <InfoLink max-width="300">
                        {{ l.t('lr.business-description-help', 'Please provide the phonetic spelling of your business name. (Example: For Phone.com you would write ‘Fone Dot Com’). Briefly describe what callers will likely be asking about, such as services provided, work hours and/or scheduling availability. Provide a contact number where we can call you to resolve any service matters that might occur.') }}
                    </InfoLink>
                </div>
                <TextEditor v-model="item.business_description" :disabled="!item.enabled" id="business_description"
                        data-test-id="lr-business-description-input" />
                <div :class="classes.ordinary_field_title" data-test-id="lr-address-title">
                    {{ l.t('lr.business-address', 'The physical address you allow to disclose to callers') }}
                </div>
                <v-container fluid class="pa-0 w-100">
                    <v-row>
                        <v-col md="12">
                            <w-text-field
                                v-model="item.business_contact.address.line_1"
                                :disabled="!item.enabled"
                                :placeholder="l.t('app.street-address', 'Street address')"
                                hide-details="auto"
                                autocomplete="new-password"
                                data-lpignore="true"
                                data-test-id="lr-street-address-input"
                            />
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col md="12">
                            <w-text-field
                                v-model="item.business_contact.address.line_2"
                                :disabled="!item.enabled"
                                :placeholder="l.t('addressbook.address-line-2', 'Address line 2')"
                                hide-details="auto"
                                autocomplete="new-password"
                                data-lpignore="true"
                                data-test-id="lr-street-address-2-input"
                            />
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col class="d-md-flex pr-0 w-100">
                            <w-text-field
                                v-model="item.business_contact.address.city"
                                :disabled="!item.enabled"
                                :placeholder="l.t('app.city', 'City')"
                                hide-details="auto"
                                autocomplete="new-password"
                                data-lpignore="true"
                                data-test-id="lr-city-input"
                                :class="{'w-60': $vuetify.breakpoint.mdAndUp}"
                            />
                            <w-text-field
                                v-model="item.business_contact.address.province"
                                :palceholder="l.t('app.state', 'State')"
                                :disabled="!item.enabled"
                                hide-details="auto"
                                autocomplete="new-password"
                                data-lpignore="true"
                                data-test-id="lr-state-input"
                                :class="{'w-40 ml-3': $vuetify.breakpoint.mdAndUp}"
                            />
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col class="d-md-flex pr-0">
                            <w-text-field
                            v-model="item.business_contact.address.postal_code"
                            :disabled="!item.enabled"
                            :placeholder="l.t('addressbook.zip', 'ZIP')"
                            hide-details="auto"
                            autocomplete="new-password"
                            data-lpignore="true"
                            data-test-id="lr-zip-input"
                            :class="{'w-40': $vuetify.breakpoint.mdAndUp}"
                            />
                            <CountrySelector
                                :disabled="!item.enabled"
                                :value="item.business_contact.address.country || 'US'"
                                @input="item.business_contact.address.country = $event"
                                data-test-id="lr-country-selector"
                                :class="{'w-60 ml-3': $vuetify.breakpoint.mdAndUp}"
                            />
                        </v-col>
                    </v-row>
                </v-container>
                <div :class="classes.ordinary_field_title" data-test-id="lr-fax-num-title">
                    {{ l.t('app.fax-number', 'Fax number') }}
                </div>
                <Tel
                    :disabled="!item.enabled"
                    :value="item.business_contact.fax"
                    @changed="item.business_contact.fax = $event.clean_number"
                    :required="false"
                    data-test-id="lr-tel-input"
                />
            </template>
            <template v-if="not_visible_for_onvego || has_onvego_apointments">
                <div :class="classes.ordinary_field_title" data-test-id="lr-appointments-title">
                    {{ l.t('lr.business-would-you-like-to-take-appointments', 'Would you like to take appointments?') }}
                </div>
                <!-- @change="show_onvego_calendar_if_needed" -->
                <w-radio-group
                    v-model="item.appointments_enabled"
                    :disabled="!item.enabled"
                    hide-details="auto"
                    row
                    class="mt-n4"
                    data-test-id="lr-appointments-radios"
                >
                    <v-radio color="secondary" type="radio" :label="l.t('app.yes', 'Yes')" :value="true" data-test-id="lr-take-appointments-yes"/>
                    <v-radio color="secondary" type="radio" :label="l.t('app.no', 'No')" :value="false" data-test-id="lr-take-appointments-no"/>
                </w-radio-group>
                <div v-if="item.appointments_enabled">
                    <div :class="classes.ordinary_field_title" data-test-id="lr-calendar-title" id="calendar">
                        {{ l.t('app.calendar', 'Calendar') }}
                    </div>
                    <Appointments
                        :is_onvego="!not_visible_for_onvego"
                        v-model="item.appointments_description"
                        :disabled="!item.enabled"
                        :item="item"
                        :placeholder="l.t('lr.business-appointment-help', 'Provide a link to your calendar service. Not all calendar services are supported. If needed, we can help you set up Appointed, a scheduling service that should be able to sync to your existing calendar.')"
                        :rows="!item.appointments_description && browser === 'firefox' ? check_break_point(4, 4, 5, 3, 7) : 1"
                        @change="may_submit = true;"
                        class="w-100"
                        auto-grow
                        hide-details="auto"
                        data-test-id="lr-calendar-input"
                        :key="`calendar-${item.vendor.id}-${item.appointments_enabled}`"
                    />
                </div>
            </template>
            <div :class="item.vendor.id === la.onvego_id ? classes.field_titles : classes.ordinary_field_title" data-test-id="lr-greeting-title">
                {{ l.t('app.greeting', 'Greeting') }}
            </div>
            <div v-if="item.vendor.id === la.onvego_id" :class="classes.field_explanation">
                {{l.t('lr.greetings-subtitle-v2', 'Enter the greeting for your callers. You can include information about your business.')}}
            </div>
            <w-textarea
                v-model="item.greeting"
                :disabled="!item.enabled"
                :rows="!item.greeting && browser === 'firefox' ? check_break_point(4, 4, 5, 3, 7) : 1"
                auto-grow
                @input="on_greeting_input"
                :rules="[$validation.required(), $validation.maxlength(256)]"
                :placeholder="l.t('la.greeting-placeholder', 'Enter the greeting your receptionist will read to your callers. Please use phonetic spelling where needed.  (Example: For Phone.com you would write ‘Fone Dot Com’)')"
                hide-details="auto"
                class="w-100"
                data-test-id="lr-greeting-input"
            />
            <OnvegoGreeting v-if="item.vendor && item.vendor.id === la.onvego_id" :greeting="item.greeting" :contacts_length="item.contacts.length" :key="`onvego-greeting-${item.greeting}-${item.contacts.length}`"/>
            <!-- <GreetingInput
                v-model="item.greeting"
                :rows="!item.greeting && browser === 'firefox' ? check_break_point(4, 4, 5, 3, 7) : 1"
                :item="item"
                :prompt_title_classes="classes.field_titles"
                :prompt_explanation_classes="classes.field_explanation"
            /> -->
            <template v-if="not_visible_for_onvego">
                <div :class="classes.ordinary_field_title" data-test-id="lr-chi-title">
                    {{ l.t('lr.business-call-handling-instructions', 'Call handling instructions') }}
                    <InfoLink max-width="300">
                        {{ l.t('la.business-call-handling-notes-help', 'Example: When transferring a call, always conduct using ‘warm’ transfer. If no one answers, direct caller to recipient’s voicemail.') }}
                    </InfoLink>
                </div>
                <TextEditor
                    v-model="item.call_handling_notes"
                    :disabled="!item.enabled"
                    id="call_handling_notes"
                    data-test-id="lr-chi-input"
                />
            </template>
            <div :class="classes.field_titles" data-test-id="lr-contacts-title">
                {{ l.t('app.contacts-and-routing', 'Contacts and routing') }}
                 <InfoLink
                    key="bottom-help-text"
                    data-test-id="lr-contacts-info-link"
                    max-width="300"
                >
                    <template v-slot:icon>
                        <v-icon size="19" class="pb-1" color="#6E7A82">
                          $vuetify.icons.infotip_stylized
                        </v-icon>
                    </template>
                    {{ l.t(
                        'live-answer.contacts-info-text',
                        'Please provide the name and best way to reach any contacts mentioned under your call handling instructions so we know who to contact and how to reach them. You can add more than one way to reach each contact.'
                    ) }}
                </InfoLink>
            </div>
            <div :class="classes.field_explanation">
                 {{ l.t('lr.contacts-help-text-1-answer-bot', 'Contacts are the people or teams to which the service will send your calls. You can set them up using a Phone.com phone number, extension, or an outside number.')}}
            </div>
            <div :class="classes.field_explanation">
                {{ l.t('lr.contacts-help-text-2-answer-bot', 'By default, calls will be handled with a “Warm transfer.” The caller will provide their name, and it will be announced to the recipient.  The recipient can accept or reject the call.  If the call is not answered, it will be directed to the next destination (Phone.com voicemail by default).')}}
            </div>
            <div :class="classes.field_explanation">
                {{ l.t('lr.contacts-help-text-3-answer-bot', 'If you want the service to send the call to its destination without any screening, uncheck the “Warm transfer” box.  Do this if you want callers to leave voicemail messages on your mobile device voicemail rather than your Phone.com voicemail.')}}
            </div>
            <div class="live-answer-contacts-container">
                <Contact
                    v-for="(_, i) in item.contacts"
                    :key="item.contacts[i]._unique_key + i"
                    :disabled="!item.enabled"
                    :_contact="item.contacts[i]"
                    :index="i + 1"
                    :deletable="item.contacts.length > 1"
                    @addDestination="addDestination(i)"
                    @remove="removeContact(i)"
                    @changed="item.contacts[i] = $event"
                    class="contacts-item"
                    :class="item.contacts.length === i + 1 ? 'last-contact-item' : ''"
                    :data-test-id="`lr-contact-${i}`"
                />
                <w-btn
                    v-if="item.enabled"
                    @click="addContact"
                    color="primary"
                    class="font-weight-bold pl-0 mt-4"
                    text
                    :disabled="!item.contacts.every((c) => c.first_name && c.last_name && c.destinations.every((d) => d.type && d.destination))"
                    data-test-id="lr-add-contact-btn"
                >
                    <v-icon class="mr-1">mdi-plus-circle</v-icon> {{ l.t('contacts.add-new-contact', 'Add new contact') }}
                </w-btn>
                <DefaultContact
                    v-if="has_IDN_feature && item && item.vendor && item.vendor.id === ONVEGO.id"
                    :contacts="item.contacts"
                    :value="default_contact_value"
                    :enable_value="!item.id ? true : (!!default_contact_value || default_contact_value === 0)"
                    :disabled="!item.enabled"
                    @input="on_default_contact_input"
                    :key="default_contact_value"
                />
            </div>
            <DeleteDefaultContactModal
                v-if="delete_default_contact !== null"
                :value="true"
                :contacts="item.contacts"
                :deleting_contact_index="delete_default_contact"
                @input="on_delete_default_contact_modal_input"
                @confirmed="do_remove_contact"
                @changed="on_default_contact_input"
            />
            <div class="m-top-50 d-flex justify-center">
                <template v-if="item.id">
                    <w-btn
                        v-if="!item.enabled"
                        @click.prevent="reactivate_confirmation_modal = true"
                        color="success"
                        class="white--text"
                        data-test-id="lr-reactivate-btn"
                    >
                        {{ l.t('lr.enable-script', 'Enable script') }}
                    </w-btn>
                    <w-btn
                        v-if="item.enabled"
                        @click.prevent="deactivate_confirmation_modal = true"
                        color="error base"
                        data-test-id="lr-deactivate-btn"
                    >
                        {{ l.t('app.disable-script', 'Disable script') }}
                    </w-btn>
                    <div class="buttons-vertical-divider mx-5"></div>
                </template>
                <CancelChanges class="mr-5" @cancel="$emit('cancel')"/>
                <w-btn v-if="item.enabled" :disabled="!may_submit" type="submit" color="success" class="white--text" data-test-id="lr-add-submit-btn">
                    {{ l.t('app.save-changes', 'Save changes') }}
                </w-btn>
            </div>
            </div>
        </template>
        <DisableScriptModal
            v-if="deactivate_confirmation_modal"
            :vendor_id="item && item.vendor ? item.vendor.id : null"
            @confirmed="deactivate_script"
            @close="deactivate_confirmation_modal = false"
        />
        <EnableScriptModal
            v-if="reactivate_confirmation_modal"
            :vendor_id="item && item.vendor ? item.vendor.id : null"
            @confirmed="reactivate_script"
            @close="reactivate_confirmation_modal = false"
        />
        <ConfirmAdditionalChargesModal
            v-model="show_confirm_additional_charges_modal"
            :item="item"
            @continue="on_confirm"
            @close="on_not_confirmed"
        />
        <DefaultModal
            v-if="duplicated_contacts_names.length"
            @close="duplicated_contacts_names = []"
            :value="true"
            max-width="400"
            data-test-id="lr-duplicated-contact-modal"
        >
            <div class="w-body-2">
                {{ l.t('lr.issue-saving', 'There was an issue saving')}}:
                <div class="error--text" v-html="duplicated_contacts_names.join('<br/>')" />
                <div class="mt-5">
                    {{ l.t('lr.due-response-system', 'Due to our voice response system, there cannot be more than one contact with the same first and last name. Please update your contacts so they each have a different name.')}}
                </div>
            </div>
            <div class="mt-8 d-flex justify-center">
                <w-btn @click="duplicated_contacts_names = []" color="primary" class="white--text"
                    data-test-id="lr-reactivate-modal-cancel-btn">
                    {{ l.t('app.continue', 'Continue') }}
                </w-btn>
            </div>
        </DefaultModal>
    </w-form>
</template>

<script>
    import l from '../../../libs/lang';
    import Helpers from '../../../libs/helpers';
    import FormValidation from '../../../libs/validation/form-rules';

    import Contact from './Contact.vue';
    import Tel from '../../elements/Tel.vue';
    import Appointments from './Appointments.vue';
    // import GreetingInput from './GreetingInput.vue';
    import DefaultContact from './DefaultContact.vue';
    import OnvegoGreeting from './OnvegoGreeting.vue';
    import InfoLink from '../../elements/InfoLink.vue';
    import HelpText from '../../elements/HelpText.vue';
    import LRCallWarning from './call-warnings/lr.vue';
    import VendorsSelector from './VendorsSelector.vue';
    import ExpertCallButton from './ExpertCallButton.vue';
    import EnableScriptModal from './EnableScriptModal.vue';
    import DisableScriptModal from './DisableScriptModal.vue';
    import FormInput from '../../elements/form/FormInput.vue';
    import OnvegoCallWarning from './call-warnings/onvego.vue';
    import SectionTitle from '../../elements/SectionTitle.vue';
    import CancelChanges from '../../elements/CancelChanges.vue';
    import TextEditor from '../../elements/form/text-editor.vue';
    import CountrySelector from '../../elements/CountrySelector.vue';
    import DefaultModal from '../../elements/modal/DefaultModal.vue';
    import DeleteDefaultContactModal from './DeleteDefaultContactModal.vue';
    import ConfirmationModal from '../../elements/modal/ConfirmationModal.vue';
    import ConfirmAdditionalChargesModal from './ConfirmAdditionalChargesModal.vue';

    export default {
    props: [
      '_la',
      'title',
    ],
    components: {
        Tel,
        Contact,
        InfoLink,
        HelpText,
        FormInput,
        TextEditor,
        Appointments,
        DefaultModal,
        SectionTitle,
        LRCallWarning,
        CancelChanges,
        OnvegoGreeting,
        // GreetingInput,
        DefaultContact,
        CountrySelector,
        VendorsSelector,
        ExpertCallButton,
        OnvegoCallWarning,
        ConfirmationModal,
        EnableScriptModal,
        DisableScriptModal,
        DeleteDefaultContactModal,
        ConfirmAdditionalChargesModal,
    },
    data() {
        return {
            l,
            la: null,
            item: null,
            codes: null,
            form_validation: FormValidation,
            deactivate_confirmation_modal: false,
            reactivate_confirmation_modal: false,
            duplicated_contacts_names: [],
            provider_explanation: true,
        may_submit: false,
        ONVEGO: {
            id: 4,
            prefered_plan: 19087,
        },
        AVO: {
            id: 1,
            prefered_plan: 19086,
        },
        classes: {
            main_titles: 'w-body-1 info--text text--darken-1 field-title',
            field_titles: 'w-body-1 info--text text--darken-1 field-title',
            field_explanation: 'w-body-2 field-explanation',
            ordinary_field_title: 'w-body-2 field-title-ordinary',
        },
        show_confirm_additional_charges_modal: false,
        confirmed: false,
        original_code: null,
        account_has_dev_feature: false,
        has_onvego_apointments: false,
        has_IDN_feature: false,
        delete_default_contact: null,
      };
    },
    beforeMount() {
        const allInputs = document.querySelectorAll('input');
        for (const input of allInputs) {
            input.dataset.lpignore = true; // this should prevent lastPass chrome ext to work on these field, but it doesnt for now
        }
    },
    async created() {
        this.$data.la = this.$props._la;
        this.$data.item = JSON.parse(JSON.stringify(this.$data.la.item));
        this.$data.original_code = this.$data.item ? this.$data.item.code : null;
        if (this.$data.item && this.$data.item.contacts && this.$data.item.contacts.length) {
            this.$data.item.contacts = this.$data.item.contacts.map((x) => {
                x._unique_key = Helpers.randomNumber();
                if (x.destinations && x.destinations.length) {
                    x.destinations = x.destinations.map((d) => {
                        d._unique_key = Helpers.randomNumber();
                        return d;
                    });
                }
                return x;
            });
        }

        if (
            this.$data.item
            && ['avo', 'avo_master'].includes(this.$branding.data.code)
            // && (!this.$data.item.vendor || (this.$data.item.vendor && this.$data.item.vendor.id === this.$data.AVO.id))
            // && (!this.$data.item.code || this.$data.item.code === this.$data.AVO.prefered_plan)
        ) {
            this.$data.item.vendor = {id: this.$data.AVO.id};
            this.$data.item.code = this.$data.AVO.prefered_plan;
            this.prefill_script_name(1);
        }
        this.$data.account_has_dev_feature = await this.$feature_toggle.isFeatureEnabled('console.show_developer_feature');
        this.$data.has_onvego_apointments = await this.$feature_toggle.isFeatureEnabled('console.show_onvego_appointments');
        this.$data.has_IDN_feature = await this.$feature_toggle.isFeatureEnabled('console.i_dont_know');
        if (this.$data.item) this.$data.item.has_onvego_apointments = this.$data.has_onvego_apointments;
    },
    methods: {
        on_confirm() {
            this.$data.confirmed = true;
            this.submitted();
        },
        on_not_confirmed() {
            this.$data.show_confirm_additional_charges_modal = false;
            this.$data.confirmed = false;
        },
        on_default_contact_input(contact_index) {
            if (typeof contact_index === 'number') {
                for (let i = 0; i < this.$data.item.contacts.length; i++) {
                    if (i === contact_index) {
                        this.$data.item.contacts[contact_index].is_default = true;
                        if (this.$data.item.id && this.$data.item.contacts[contact_index].id) {
                            this.$data.item.default_contact_id = this.$data.item.contacts[contact_index].id;
                        }
                    } else {
                        this.$data.item.contacts[i].is_default = false;
                    }
                }
            } else {
                for (let i = 0; i < this.$data.item.contacts.length; i++) {
                    this.$data.item.contacts[i].is_default = false;
                }
                this.$data.item.default_contact_id = null;
            }
        },
        on_delete_default_contact_modal_input(v) {
            if (!v) this.$data.delete_default_contact = null;
        },
        on_greeting_input(val) {
            this.$data.item.greeting = val.replace(/\n/g, ' ');
        },
        is_form_valid() {
            this.$data.la.alert = null;
            if (!this.$data.item.greeting) {
                this.$data.la.alert = {
                    level: 'error',
                    message: l.t('lr.greeting-required', 'Greeting field is required.'),
                };
            }
            const contacts_error = {
                level: 'error',
                message: l.t('lr.contact-destination-required', 'At least one contact and one destination required.'),
            };
            if (!this.$data.item.contacts) {
                this.$data.la.alert = contacts_error;
            }
            if (!this.$data.item.contacts.length) {
                this.$data.la.alert = contacts_error;
            }
            for (let i = 0; i < this.$data.item.contacts.length; i++) {
                const c = this.$data.item.contacts[i];
                if (!c.first_name || !c.last_name) {
                    this.$data.la.alert = {
                        message: l.t('lr.contacts-requiredmust-have-first-and-last-name', 'Contact #{} should have first and last name.', [i + 1]),
                        level: 'error',
                    };
                }
                if (!this.$data.la.alert && !c.destinations.length) {
                    return this.$data.la.alert = {
                        message: l.t('lr.contacts-destinations-required', 'Contact #{} requires at least one destination.', [i + 1]),
                        level: 'error',
                    };
                }
            }
            const invalid_destinations_index = this.$data.item.contacts.findIndex((x) => !x.destinations.every((y) => y.type && y.destination));
            if (!this.$data.la.alert && invalid_destinations_index > -1) {
                this.$data.la.alert = {
                    level: 'error',
                    message: l.t('lr.contacts-destination-type-required', 'Contact #{} has an incomplete destination.', [invalid_destinations_index + 1]),
                };
            }
            if (!this.$data.la.alert) {
                for (let i = 0; i < this.$data.item.contacts.reverse().length; i++) {
                    for (let j = 0; j < this.$data.item.contacts[i].destinations.length; j++) {
                        const d = this.$data.item.contacts[i].destinations[j];
                        if (d.type === 'email') {
                            if (this.$validation.email()(d.destination) !== true) {
                                this.$data.la.alert = {
                                    level: 'error',
                                    message: l.t('lr.invalid-email-for-destination', 'Contact #{} / destination #{} has invalid email format.', [i + 1, j + 1]),
                                };
                            }
                        }
                        if (d.type === 'phone_number') {
                            if (this.$validation.phone_number()(d.destination) !== true) {
                                this.$data.la.alert = {
                                    level: 'error',
                                    message: l.t('lr.invalid-phone-for-destination', 'Contact #{} / destination #{} has invalid phone number.', [i + 1, j + 1]),
                                };
                            }
                        }
                    }
                }
            }

            if (this.$data.la.alert) {
                window.scrollTo({ top: 0, behavior: 'smooth' });
                this.$data.la.hide_alert(5);

                return false;
            }
            return true;
        },
        submitted() {
            if (!this.not_visible_for_onvego) {
                this.$data.item.business_description = '';
                this.$data.item.business_contact.address = null;
                this.$data.item.business_contact.fax = '';
                this.$data.item.call_handling_notes = '';
            }
            this.$data.item.contacts = this.$data.item.contacts.map((contact) => {
                contact.first_name = contact.first_name.trim().replace(/  +/g, ' ');
                contact.last_name = contact.last_name.trim().replace(/  +/g, ' ');
                return contact;
            });
            if (!this.is_form_valid()) {
                return null;
            }

            const contacts_names = this.$data.item.contacts.map((x) => `${x.first_name} ${x.last_name}`);
            const duplicated_contact_names = contacts_names.filter((x, index) => contacts_names.indexOf(x) !== index);
            if (duplicated_contact_names.length) {
                return this.$data.duplicated_contacts_names = duplicated_contact_names;
            }
            if (
                !['avo', 'avo_master'].includes(this.$branding.data.code)
                && this.$data.item
                && this.$data.item.vendor
                && this.$data.item.vendor.id === 1
                && this.$data.item.code
                && this.$data.original_code !== this.$data.item.code
                && !this.$data.confirmed
            ) {
                return this.$data.show_confirm_additional_charges_modal = true;
            }
            this.$emit('submitted', this.$data.item);

            return true;
        },
        addContact() {
            const c = JSON.parse(JSON.stringify(this.$data.la.empty_contact));
            c._unique_key = Helpers.randomNumber();
            this.$data.item.contacts.push(c);
        },
        addDestination(index) {
            const d = JSON.parse(JSON.stringify(this.$data.la.empty_destination));
            d._unique_key = Helpers.randomNumber();
            this.$data.item.contacts[index].destinations.push(d);
            this.$forceUpdate();
        },
        removeContact(index) {
            if (index > -1) {
                if (this.$data.item.id) {
                    if (
                        this.$data.item.contacts[index]
                        && (
                           this.$data.item.contacts[index].is_default
                            || (
                                this.$data.item.contacts[index].id
                                && this.$data.item.default_contact_id === this.$data.item.contacts[index].id
                            )
                        )
                    ) {
                        this.$data.delete_default_contact = index;
                    }
                }
                if (this.$data.delete_default_contact === null) {
                    this.do_remove_contact(index);
                }
                this.$forceUpdate();
            }
        },
        do_remove_contact(index) {
            this.$data.item.contacts.splice(index, 1);
            this.$data.delete_default_contact = null;
            this.$forceUpdate();
        },
        translate_code(code) {
            if (this.$data.la.codes_translations[code.code]) {
                return this.$data.la.codes_translations[code.code].long;
            }
            return l.t('live-receptionist.no-decription-available', 'No description available. Code: {}', [code.code]);
        },
        vendor_changed(data) {
            this.$data.item.vendor = { 'id': data.id };
            this.$data.codes = [];
            this.prefill_script_name(data.id);
            if (data.codes && data.codes.length) {
                data.codes.map((code) => this.$data.codes.push(
                    {
                        value: code.code,
                        text: this.translate_code(code)
                    }
                ));
                if (data.id === this.$data.AVO.id && ['avo', 'avo_master'].includes(this.$branding.data.code)) {
                    this.$data.codes = [{
                        text: this.translate_code(this.$data.la.avo_plan),
                        value: this.$data.la.avo_plan.code,
                    }];
                    this.$data.item.code = this.$data.la.avo_plan.code;
                }
                if (data.id === this.$data.ONVEGO.id) {
                    if (this.codes.find((x) => x.code === this.$data.ONVEGO.prefered_plan)) {
                        this.$data.item.code = this.$data.ONVEGO.prefered_plan;
                    }
                    this.$data.item.code = data.codes[0].code;
                }
                if (data.codes.length === 1) {
                    this.$data.item.code = data.codes[0].code;
                }
            }
            if (!this.$data.codes.map((x) => x.value).includes(this.$data.item.code)) {
                this.$data.item.code = null;
            }

            if (!this.$data.item.id) {
                if (this.$data.item.vendor.id === this.$data.la.onvego_id) {
                    this.$data.item.greeting = l.t('lr.thank-you-for-calling-company-name', 'Thank you for calling {}', [this.$session.user.account.contact.company]);
                } else if (
                    this.$session.user.account
                    && this.$session.user.account.contact
                    && this.$session.user.account.contact.company
                ) {
                    this.$data.item.greeting = l.t(
                        'lr.greeting-prefilled-text-',
                        'Thank you for calling {}. I\'m happy to connect your call. Which person or team would you like to reach today?',
                        [this.$session.user.account.contact.company]
                    );
                }
            }

            this.$forceUpdate();

            return true;
        },
        prefill_script_name(vendor_id) {
            if (!this.$data.item.name || [this.$data.la.virtual_generic_name, this.$data.la.live_generic_name].includes(this.$data.item.name)) {
                let name = '';
                if (vendor_id === 4) {
                    name = this.$data.la.virtual_generic_name;
                } else if (vendor_id === 1) {
                    name = this.$data.la.live_generic_name;
                }
                this.$data.item.name = name;
            }
        },
        async deactivate_script() {
            this.$data.deactivate_confirmation_modal = false;
            await this.$data.la.deactivate();
        },
        async reactivate_script() {
            this.$data.reactivate_confirmation_modal = false;
            await this.$data.la.reactivate();
        },
        check_break_point(xl, lg, md, sm, xs) { // only for firefox that doesnt support visible placeholder for v-textarea
            if (this.$vuetify.breakpoint.xl) return xl;
            if (this.$vuetify.breakpoint.lg) return lg;
            if (this.$vuetify.breakpoint.md) return md;
            if (this.$vuetify.breakpoint.sm) return sm;
            if (this.$vuetify.breakpoint.xs) return xs;
            return 14;
        },
    },
    watch: {
        reactivate_confirmation_modal(v) {
            if (!v) this.$data.confirmed = false;
        }
    },
    computed: {
        browser() {
            const {userAgent} = navigator;
            let browserName;
            if (userAgent.match(/chrome|chromium|crios/i)) {
                browserName = 'chrome';
            } else if (userAgent.match(/firefox|fxios/i)) {
                browserName = 'firefox';
            } else if (userAgent.match(/safari/i)) {
                browserName = 'safari';
            } else if (userAgent.match(/opr\//i)) {
                browserName = 'opera';
            } else if (userAgent.match(/edg/i)) {
                browserName = 'edge';
            } else {
                browserName = 'No browser detection';
            }
            return browserName;
        },
        not_visible_for_onvego() {
            return !(this.$data.item.vendor && this.$data.item.vendor.id && this.$data.item.vendor.id === this.$data.ONVEGO.id);
        },
        default_contact_value() {
            const default_contact_index = this.$data.item.contacts.findIndex((x) => x.is_default);
            if (default_contact_index > -1) return default_contact_index;
            let value = null;
            if (this.$data.item.default_contact_id) {
                const script_default_contact_index = this.$data.item.contacts.findIndex((x) => x.id === this.$data.item.default_contact_id);
                if (script_default_contact_index > -1) {
                    value = script_default_contact_index;
                }
            }
            return value;
        }
    },
  };
</script>
<style lang="scss">
.live-receptionist {
    #lr-title {
        font-size: 19px !important;
    }
    #provider-explanation {
        position: relative;
        #close-provider {
            position: absolute;
            top: 8px;
            right: 8px;
        }
    }
    .provider-selector {
        margin-bottom: 30px;
    }
    .provider-divider {
        margin-top: 30px !important;
        margin-bottom: 30px !important;
    }

    .field-title {
        margin-top: 30px;
    }
    .field-title-ordinary {
        margin-top: 30px;
        margin-bottom: 15px;
    }
    .field-explanation {
        margin-top: 5px;
        margin-bottom: 10px;
    }
    .section-title {padding-left: 0};
    .live-answer-contacts-container {
        border: 1px solid rgba(133, 146, 154, 0.65);
        border-radius: 10px;
        padding: 15px 15px 30px 15px;
        margin-top: 20px;
    }
    .lr-subtitle {
        margin-top: 25px;
        margin-bottom: 10px;
    }
    .text-card-title {
        font-size: 16px !important;
    }
    .card-text {
        font-size: 12px !important;
    }
}
</style>
