<template>
  <IndexPage
    :resource="subaccount"
    :disable_mass_operations="true"
    :create_route="{name: 'subaccounts.create'}"
    data-test-id="subaccounts-list-page"
  >
      <template v-slot:filters>
        <FormInput :label="l.t('app.voip-id', 'VoIP ID')">
          <w-text-field type="number" v-model="subaccount.filters.voip_id" hide-details='auto'/>
        </FormInput>
        <FormInput :label="l.t('app.name', 'Name')">
          <w-text-field type="text" v-model="subaccount.filters.name" hide-details='auto'/>
        </FormInput>
        <FormInput :label="l.t('app.username', 'Username')">
          <w-text-field type="text" v-model="subaccount.filters.username" hide-details='auto'/>
        </FormInput>
        <FormInput :label="l.t('app.email', 'Email')">
          <w-text-field type="text" v-model="subaccount.filters.email" hide-details='auto'/>
        </FormInput>
        <FormInput :label="l.t('app.phone-number', 'Phone number')">
          <w-text-field type="text" @keyup="checkNumberInput" v-model="subaccount.filters.phone_number" hide-details='auto'/>
        </FormInput>
      </template>
      <template v-slot:list>
        <v-simple-table data-test-id="subaccount-table">
          <thead data-test-id="table-head">
            <tr data-test-id="head-row">
              <th data-test-id="voip-id">{{ l.t('app.voip-id', 'VoIP ID') }}</th>
              <th data-test-id="name">{{ l.t('app.name', 'Name') }}</th>
              <th data-test-id="username">{{ l.t('app.username', 'Username') }}</th>
              <th data-test-id="number">{{ l.t('app.phone-number', 'Phone number') }}</th>
              <th data-test-id="email">{{ l.t('app.email', 'Email') }}</th>
              <th style="width:20%" data-test-id="switch"><br/></th>
            </tr>
          </thead>
          <tbody data-test-id="table-body">
            <tr v-for="item in subaccount.items" :data-test-id="item.id">
              <td data-test-id="voip-id">{{ item.id }}</td>
              <td class="nowrap" data-test-id="name">{{ item.name }}</td>
              <td data-test-id="username">{{ item.username }}</td>
              <td data-test-id="phone">{{ item.contact.phone | phoneFormat }}</td>
              <td data-test-id="email">{{ item.contact.primary_email }}</td>
              <td class="form" data-test-id="switch">
                <a
                  v-if="$session.user.id === item.id"
                  :class="{'in-use': $session.user.id === item.id}"
                  data-test-id="in-use"
                >
                  {{ l.t('route.in-use', 'In use') }}
                </a>
                <a v-else-if="item.id" @click="on_switch_to_subaccount(item)" data-test-id="switch-to-this-subaccount">
                  {{ l.t('subaccounts.switch-to-this-subaccount', 'Switch to this subaccount') }}
                </a>
              </td>
            </tr>
          </tbody>
        </v-simple-table>
      </template>
  </IndexPage>
</template>
<script>
  import l from '../../../libs/lang';
  import Helpers from '../../../libs/helpers';
  import Subaccount from '../../../models/Subaccount';
  import IndexPage from '../../elements/IndexPage.vue';
  import FormInput from '../../elements/form/FormInput.vue';

  export default {
    props: {
      subaccounts_owner_mode: {
        default: false,
        type: Boolean,
      }
    },
    components: {
      IndexPage,
      FormInput,
    },
    data() {
      return {
        l,
        subaccount: new Subaccount(this.$session, Helpers.emitter(this), Helpers.changeRoute(this)),
      };
    },
    methods: {
      on_switch_to_subaccount(item) {
        if (this.$props.subaccounts_owner_mode) {
          this.$global_emitter.$emit('subaccounts_owner_use_subaccount', item);
        } else {
          this.$global_emitter.$emit('use_subaccount', item);
        }
      },
      checkNumberInput() {
        this.$data.subaccount.filters.phone_number = this.$data.subaccount.filters.phone_number ? this.$data.subaccount.filters.phone_number.replace(/[^0-9+/-]+/g, '') : this.$data.subaccount.filters.phone_number;
      },
    },
    filters: {
      phoneFormat(val) {
        return Helpers.format_phone_number(val);
      },
    },
  };
</script>
