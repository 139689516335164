<template>
	<div v-if="contact">
        <div v-if="title" class="info--text text--darken-1 w-subtitle-1 mb-5" data-test-id="acc-contact-title">
            {{ title }}
        </div>
		<w-text-field
			v-model="contact.name"
			:disabled="!!disabled"
			:rules="[$validation.required()]"
            :label="l.t('app.first-and-last-name', 'First and last name')"
            class="w-100 mb-5"
			hide-details='auto'
			data-test-id="acc-contact-name-input"
		/>
		<w-text-field
			v-model="contact.company"
			:disabled="!!disabled"
            :label="l.t('app.company-name-optional', 'Company name (optional)')"
            class="w-100 mb-5"
			hide-details='auto'
			data-test-id="acc-contact-company-input"
		></w-text-field>
        <CountrySelector
            v-model="contact.address.country"
            :available_countries="countries"
            @change="on_country_change"
            :label="l.t('addressbook.country', 'Country')"
            :required="true"
            class="w-100 mb-5"
            data-test-id="acc-contact-country-selector"
        ></CountrySelector>
        <w-text-field
            v-model="contact.address.line_1"
            :disabled="!!disabled"
            :rules="[$validation.required()]"
            :label="l.t('app.street-address', 'Street address')"
            class="w-100 mb-5"
            hide-details='auto'
            data-test-id="acc-contact-street-add-input"
        ></w-text-field>
		<w-text-field
			v-model="contact.address.line_2"
			:disabled="!!disabled"
            :label="l.t('app.apt-suite-etc', 'Apt, Suite, etc')"
            class="w-100 mb-5"
			hide-details='auto'
			data-test-id="acc-contact-street-add-2-input"
		></w-text-field>
		<div class="d-flex mb-5">
            <w-text-field
                v-model="contact.address.city"
                :disabled="!!disabled"
                :label="l.t('app.city', 'City')"
                class="w-100 mr-5"
                hide-details='auto'
                :rules="[$validation.required()]"
                data-test-id="acc-contact-city-input"
            ></w-text-field>
            <template v-if="contact.address && ['US', 'CA'].includes(contact.address.country)">
                <USStatesSelector
                    v-if="contact.address.country === 'US'"
                    v-model="contact.address.province"
                    :rules="[$validation.required()]"
                    class="w-100 mr-5"
                    :label="province_label"
                    required
                />
                <CanadaProvincesSelector
                    v-else
                    v-model="contact.address.province"
                    :rules="[$validation.required()]"
                    :label="province_label"
                    class="w-100 mr-5"
                    required
                />
            </template>
            <w-text-field
                v-else
                v-model="contact.address.province"
                :disabled="!!disabled"
                :label="province_label"
                class="w-100 mr-5"
                hide-details='auto'
                data-test-id="acc-contact-province-input"
            ></w-text-field>
            <template v-if="contact.address && ['US', 'CA'].includes(contact.address.country)">
                <USZipCode
                    v-if="contact.address.country === 'US'"
                    v-model="contact.address.postal_code"
                    :label="postal_code_label"
                    class="w-100"
                    :validation="true"
                    required
                />
                <CanadianPostalCode
                    v-else-if="contact.address.country === 'CA'"
                    v-model="contact.address.postal_code"
                    :validation="true"
                    :label="postal_code_label"
                    class="w-100"
                    required
                />
            </template>
            <w-text-field
                v-else
                v-model="contact.address.postal_code"
                min="1"
                :label="postal_code_label"
                class="w-100"
                :disabled="!!disabled"
                :rules="[$validation.required()]"
                hide-details='auto'
                data-test-id="acc-contact-postal-code-input"
            ></w-text-field>
        </div>
		<div class="d-flex mb-5">
            <Tel
                :value="contact.phone"
                :disabled="!!disabled"
                :label="l.t('app.phone-number', 'Phone number')"
                @changed="contact.phone = $event.clean_number"
                :required="true"
                :validation="true"
                class="w-100 mr-5"
                data-test-id="acc-contact-phone-input"
            />
            <Tel
                :value="contact.fax"
                :disabled="!!disabled"
                @changed="contact.fax = $event.clean_number"
                :required="false"
                :label="l.t('app.fax-number-optional', 'Fax number (optional)')"
                class="w-100"
                data-test-id="acc-contact-fax-input"
            />
        </div>
		<w-text-field
			type="email"
			v-model="contact.primary_email"
			:disabled="!!disabled"
			:rules="[$validation.required(), $validation.email()]"
            :label="l.t('app.email-address', 'Email address')"
            class="w-100 mb-5"
			hide-details='auto'
			data-test-id="acc-contact-email-input"
		></w-text-field>
		<w-text-field
			type="email"
			v-model="contact.alternate_email"
			:disabled="!!disabled"
            :label="l.t('app.secondary-email-address-optional', 'Secondary email address (optional)')"
            class="w-100"
			hide-details='auto'
			data-test-id="acc-contact-secondary-email-input"
		></w-text-field>
	</div>
</template>

<script>
import l from '../../libs/lang';
import Tel from './Tel.vue';
import FormInput from './form/FormInput.vue';
import SectionTitle from './SectionTitle.vue';
import USZipCode from './address/USZipCode.vue';
import CountrySelector from './CountrySelector.vue';
import USStatesSelector from './address/USStatesSelector.vue';
import CanadianPostalCode from './address/CanadianPostalCode.vue';
import CanadaProvincesSelector from './address/CanadaProvincesSelector.vue';

export default {
	props: ['_contact', 'title', 'address_only', 'disabled', 'submittable', 'countries', 'reset_address_on_country_change'],
	components: {
		Tel,
		USZipCode,
		FormInput,
		SectionTitle,
		CountrySelector,
		USStatesSelector,
		CanadianPostalCode,
		CanadaProvincesSelector,
	},
	created() {
		this.$data.contact = this.$props._contact;
	},
	data() {
		return {
			l,
			contact: null,
		};
	},
	methods: {
		on_country_change(data) {
			if (data !== this.$data.contact.address.country) {
				for (const key of Object.keys(this.$data.contact.address)) {
					this.$data.contact.address[key] = '';
				}
			}
			this.$data.contact.address.country = data;
		}
	},
	computed: {
		province_label() {
			let label;
			if (this.$data.contact && this.$data.contact.address && this.$data.contact.address.country === 'US') {
				label = l.t('app.state', 'State');
			} else {
				label = l.t('app.province', 'Province');
			}

			return label;
		},
		postal_code_label() {
			let label;
			if (this.$data.contact && this.$data.contact.address && this.$data.contact.address.country === 'US') {
				label = l.t('app.zip-code', 'Zip code');
			} else {
				label = l.t('app.postal-code', 'Postal code');
			}

			return label;
		}
	},
	watch: {
		contact: {
			deep: true,
			handler(value) {
				const val = JSON.parse(JSON.stringify(value));
				if (!val.address.postal_code) val.address.postal_code = null;
				if (!val.alternate_email) val.alternate_email = null;

				this.$emit('changed', val);
			},
		},
	},
};
</script>
