<template>
    <div id="pdf-export" class="mt-5">
        <div>
            <div class="d-flex justify-center">
              <div id="types-chart" class="chart-container" data-test-id="types-chart"></div>
            </div>
            <ChartLegend :items="legend"/>
        </div>
        <v-simple-table data-test-id="types-table">
            <thead data-test-id="table-head">
              <tr data-test-id="head-row">
                  <th style="width: 100%" data-test-id="type">{{ l.t('app.type', 'Type') }}</th>
                  <th data-test-id="total">{{ l.t('reports.total-calls', 'Total calls') }}</th>
              </tr>
            </thead>
            <tbody data-test-id="table-body">
              <tr v-for="(item, i) in call.items" :data-test-id="`types-item-${i}-row`">
                  <td data-test-id="name">{{ item.name }}</td>
                  <td data-test-id="count">{{ item.count }}</td>
              </tr>
            </tbody>
        </v-simple-table>
    </div>
</template>

<script>
  import { OrangeChartPie } from 'orange-charts';
  import { OrangeColor } from 'orange-colors';
  import ChartLegend from '../../../fragments/ChartLegend.vue';
  import l from '../../../../libs/lang';

  export default {
    props: ['call', 'color'],
    components: { ChartLegend },
    data() {
      const c = new OrangeColor(this.$props.color);
      const incoming = c.html_string;
      c.h = (c.h + 120) % 360;
      const outgoing = c.html_string;
      return {
        l,
        colors: {incoming, outgoing},
      };
    },
    mounted() {
      const chart = new OrangeChartPie(this.$props.call.items);
      chart
        .assignSource('count', {
          property: 'name',
          mapping: this.legend,
        })
        .setDataCallback('mouseover', (_, data) => {
          let details = document.getElementById('orange-charts-details');
          if (!details) {
            details = document.createElement('div');
            details.setAttribute('id', 'orange-charts-details');
            document.body.appendChild(details);
          }
          details.innerText = `${data.label}: ${data.value}`;
        })
        .setDataCallback('mousemove', (e) => {
          const details = document.getElementById(
            'orange-charts-details',
          );
          details.style.top = `${e.pageY}px`;
          details.style.left = `${e.pageX}px`;
        })
        .setDataCallback('mouseleave', () => {
          document.getElementById('orange-charts-details').innerText = '';
        })
        .render('types-chart', {
          label: 5,
          diameter: 300,
          fair: false,
        });
    },
    computed: {
      legend() {
        const obj = {};
        obj[
          l.t('app.incoming-calls', 'Incoming calls')
          ] = this.$data.colors.incoming;
        obj[
          l.t('app.outgoing-calls', 'Outgoing calls')
          ] = this.$data.colors.outgoing;
        return obj;
      },
    },
  };
</script>
