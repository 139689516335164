<template>
	<div class="address-picker" data-test-id="contact-address-picker">
		<w-radio-group
			v-if="contacts && contacts.length"
			v-model="selected"
			@click="selected = contact.id"
			hide-details="auto"
			class="pa-3"
			:key="`selected-${selected}`"
		>
	      <v-radio v-for="(contact, i) in contacts" color="secondary" type="radio" :value="contact.id" :data-test-id="`contact-address-picker-${i}-radio`" :key="`contact-radio-input-${contact.id}`">
	        <template v-slot:label>
				<div :class="{'selected-address': contact.id === selected}" class="light-border address-as-label">
					<Address :_address="contact.address"></Address>
				</div>
	        </template>
	      </v-radio>
		</w-radio-group>
		<div v-if="selected !== 'new_address'">
			<div class="d-flex justify-space-between mt-3">
				<w-btn
					@click="selected = 'new_address'"
					text
					color="primary"
					data-test-id="contact-address-picker-add-new-address-btn"
				>
					<v-icon>mdi-plus</v-icon>
					{{ l.t('orders.add-new-address', 'Add new address') }}
				</w-btn>
              <slot name="additionalButton"></slot>
			</div>
		</div>
		<transition v-else name="slide-fade-right-left" mode="out-in">
			<div>
				<w-alert v-if="alert_message" :message="alert_message" ref="alert_message" level="error" id="address-alert-message" />
				<AccountContact
					:_contact="{...new_contact}"
					:address_only="true"
					:countries="countries"
					:submittable="true"
					class="w-100"
					:title="l.t('app.new-address', 'New address')"
					@submitted="contact_submitted"
					data-test-id="contact-address-picker-new-address-inputs"
				/>
			</div>
		</transition>
	</div>
</template>
<script>
import l from '../../libs/lang';
import Helpers from '../../libs/helpers';
import AccountContact from './AccountContact.vue';
import Address from './Address.vue';

let alertTimer = null;
export default {
	props: ['_contacts', '_selected', 'countries'],
	components: { AccountContact, Address },
	data() {
		return {
			l,
			contacts: null,
			selected: null,
			alert_message: null,
			new_contact: {
			  'name': '',
			  'company': '',
			  'phone': '',
			  'address': {
			    'city': '',
			    'country': 'US',
			    'line_1': '',
			    'line_2': '',
			    'postal_code': '',
			    'province': '',
			  },
			},
		};
	},
	created() {
		this.$data.contacts = this.$props._contacts;
		if (this.$data.contacts.length) {
			this.$data.selected = this.$props._selected ? this.$props._selected : this.$data.contacts[0].id;
			window.scrollTo(0, 0);
		}
	},
	methods: {
		async contact_submitted(contact) {
			const alert_mess = await this.validate(contact);
			if (alert_mess) return this.showAlert(alert_mess);
			this.$emit('new_contact_created', contact);
			this.$forceUpdate();

			return true;
		},
		showAlert(mess) {
			clearTimeout(alertTimer);
			this.$data.alert_message = mess;
			const element = document.getElementById('address-alert-message');
			if (element) {
				element.scrollIntoView({behavior: 'smooth', block: 'center'});
			}
			alertTimer = setTimeout(() => {
				this.$data.alert_message = null;
			}, 3000);
		},
		scrollToTheAddress(val) {
			setTimeout(() => {
				const element = document.querySelector(`input[value='${val}']`);
				if (element) {
					element.scrollIntoView({behavior: 'smooth', block: 'center'});
				}
			}, 10);
		},
		async validate(contact) {
			if (!contact || (contact && !contact.address)) {
				return l.t('contacts.missing-address', 'Missing address');
			}
			for (const field of ['city', 'country', 'line_1', 'postal_code', 'province']) {
				if (!contact.address[field]) {
					return l.t('contacts.please-provide--complete-address', 'Please provide complete address');
				}
			}
			const is_address_valid = await Helpers.validate_address(this.$session, contact.address);
			if (!is_address_valid.success) {
				return l.t('contacts.invalid-address', 'Invalid address');
			}

			return null;
		}
	},
	watch: {
		// eslint-disable-next-line object-shorthand
		selected: function (val) {
			this.$emit('selected', val);
			const el = document.querySelector(`input[value='${val}']`);
			if (!el) return this.scrollToTheAddress(val);
			const rect = el.getBoundingClientRect();
			const elemTop = rect.top;
			const elemBottom = rect.bottom;
			const isVisible = (elemTop >= 0) && (elemBottom <= window.innerHeight);
			if (!isVisible) this.scrollToTheAddress(val);

			return true;
		},
		alert_message(val) {
			if (val) {
				setTimeout(() => {
					this.$refs.alert_message.$el.scrollIntoView({behavior: 'smooth', block: 'center'});
				}, 10);
			}
		}
	},
};
</script>
<style scoped lang="scss">
.address-picker {
	.address-as-label{
		padding: 20px 10px;
		border-radius: 5px;
		width: 100%;
	}
	.selected-address {
		border: 2px solid var(--v-text-lighten1) !important;
	}

}
</style>
