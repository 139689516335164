import {getOffsetRect} from './dom';

/**
 * @param event {MouseEvent}
 * @param element {HTMLElement}
 * @return {{x: number, y: number}}
 */
export function getMousePosition(element, event) {
  let mouseX = 0;
  let mouseY = 0;

  if (event.type === 'touchstart' || event.type === 'touchmove' || event.type === 'touchend' || event.type === 'touchcancel') {
    const touch = event.changedTouches[0];
    mouseX = touch.pageX + document.documentElement.scrollLeft;
    mouseY = touch.pageY + document.documentElement.scrollTop;
  } else {
    mouseX = event.pageX || event.clientX + document.documentElement.scrollLeft;
    mouseY = event.pageY || event.clientY + document.documentElement.scrollTop;
  }

  // mouseX = event.pageX || event.clientX + document.documentElement.scrollLeft
  // mouseY = event.pageY || event.clientY + document.documentElement.scrollTop

  const offset = getOffsetRect(element);
  const x = mouseX - offset.left;
  const y = mouseY - offset.top;

  return {
    x,
    y
  };
}

export default {
  getMousePosition
};
