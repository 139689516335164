<template>
  <div>
    <w-loader v-if="la.loading" />
    <template v-else>
      <w-alert
        v-if="la.alert"
        :message="la.alert.message"
        :level="la.alert.level"
        :closable="la.enable_close_alert"
        @closed="la.alert = null"
        class="mb-6"
      />
      <LiveAnswer
        :_la="la"
        @submitted="createLa"
        :title="l.t('lr.create-new-script', 'Create new script')"
        class="create-page"
      />
    </template>
  </div>
</template>

<script>
  import l from '../../../libs/lang';
  import LA from '../../../models/LiveAnswer';
  import Helpers from '../../../libs/helpers';
  import LiveAnswer from '../../fragments/live-answer/LiveAnswer.vue';

  export default {
    components: {
      LiveAnswer,
    },
    data() {
      return {
        l,
        la: new LA(this.$session, Helpers.emitter(this), Helpers.changeRoute(this)),
      };
    },
    async created() {
      await this.$data.la.prepoluate_business_profile();
      this.$data.la.loading = true;
      try {
        this.$session.user.account = await Helpers.get_account_information(this.$session);
        await this.$data.la.prepopulate_contacts();
        await this.$data.la.prepare_generic_names();
        this.$data.la.loading = false;
      } catch (e) {
        console.log('error while prepopulating a contact.', e);
        this.$data.la.item.contacts = [JSON.parse(JSON.stringify(this.$data.la.empty_contact))];
        this.$data.la.loading = false;
      }
    },
    methods: {
      async createLa(data) {
        await this.$data.la.create(data);
      }
    }
  };
</script>
