import Contact from './Contact';
import l from '../libs/lang';

const TOTAL = 500;

class ContactDuplicate extends Contact {
	constructor(session, emitter, changeRoute) {
		super(session, emitter, changeRoute);
		this.items = [];
		this._mode = null;
		this.total = 0;
		this.edit_to_merge = null;
		this.merging_loading = false;
		this.modes = [
			{
				value: 'intersection',
				name: l.t('contacts.intersection', 'Intersection'),
				explanation: l.t('contacts.intersection-explanation', 'any match (name, email or number)'),
				skip_fields: [],
			},
			{
				value: 'number-or-email',
				name: l.t('contacts.number-or-email', 'Number or email'),
				explanation: l.t('contacts.number-or-email-explanation', 'number and/or email are the same'),
				skip_fields: [],
			},
			{
				value: 'name',
				name: l.t('app.name', 'Name'),
				explanation: l.t('contacts.name-explanation', 'name is the same'),
				skip_fields: ['name'],
			},
			{
				value: 'email',
				name: l.t('app.email', 'Email'),
				explanation: l.t('contacts.email-explanation', 'email is the same'),
				skip_fields: ['email'],
			},
			{
				value: 'number',
				name: l.t('app.number', 'Number'),
				explanation: l.t('contacts.number-explanation', 'phone number is the same'),
				skip_fields: [],
			},
		];
		this.pairs = [];
		this.offset = 0;
	}

	set mode(val) {
		this._mode = val;
		if (val) this.findDuplications();
	}

	get mode() {
		return this._mode;
	}

	set activePair(val) {
		this.pairs[0] = val;
	}

	get activePair() {
		return this.pairs[0];
	}

	async findDuplications() {
		if (!this.mode) return null;

		this.loading = true;
		const uri = `${this.baseUri}?filters[duplications]=${this.mode}`;
		try {
			const duplicates = await this.session.get_list(
				uri, TOTAL, this.offset,
			);

			this.items = this.items.concat(duplicates.items);
			if (duplicates.total > this.items.length) {
				this.total = duplicates.total;
				this.offset += TOTAL;
			} else {
				this.makePairs();
			}
		} catch (err) {
			this.validation_error(err);
		}

		this.loading = false;

		return true;
	}

	makePairs() {
		this.loading = true;
		this.pairs = [];
		try {
			for (let i = 0; i < this.items.length; i++) {
				for (let j = (i + 1); j < this.items.length; j++) {
					const pair = this.compareContact(this.items[i], this.items[j]);
					if (pair) this.pairs.push([this.items[i], this.items[j]]);
				}
			}
			this.total = null;
		} catch (err) {
			this.validation_error(err);
		}
		this.loading = false;
	}

	async useThis(keepIndex) {
		this.merging_loading = true;
		try {
			const deleteIndex = keepIndex === 1 ? 0 : 1;
			const pair = this.pairs[0];
			const toBeDeleted = pair[deleteIndex];
			await this.session.delete_item(`${this.baseUri}/${toBeDeleted.id}`);
			this.rebuildItems(toBeDeleted.id);
			this.successfulUpdate();
		} catch (err) {
			this.validation_error(err);
		}

		this.merging_loading = false;
	}

	async mergeIntoThis(keepIndex) {
		this.loading = true;
		try {
			const deleteIndex = keepIndex === 1 ? 0 : 1;
			const pair = this.pairs[0];
			let mergeIntoThis = JSON.parse(JSON.stringify(pair[keepIndex]));
			const toBeDeleted = JSON.parse(JSON.stringify(pair[deleteIndex]));
			mergeIntoThis = ContactDuplicate.mergeContactObjects(mergeIntoThis, toBeDeleted);
			mergeIntoThis.merged_contact_id = toBeDeleted.id;
			this.groups = await this.loadGroups();
			this.edit_to_merge = mergeIntoThis;
		} catch (err) {
			this.validation_error(err);
		}

		this.loading = false;
	}

	static mergeContactObjects(target, source) {
		for (const prop in target) {
			if ((!target[prop] || target[prop] === '-') && source[prop]) {
				target[prop] = source[prop];
			} else if (Array.isArray(target[prop]) && source[prop]) {
				target[prop] = ContactDuplicate.mergeArraysProperies(
					target[prop], source[prop], prop,
				);
			}
		}

		return target;
	}

	async mergeContact(contact) {
		this.merging_loading = true;
		try {
			const toBeDeleted = contact.merged_contact_id;
			delete contact.merged_contact_id;
			this.edit_to_merge = null;
			await this.session.replace_item(`${this.baseUri}/${contact.id}`, contact);
			await this.session.delete_item(`${this.baseUri}/${toBeDeleted}`);
			this.updateContactInPairs(contact);
			this.rebuildItems(toBeDeleted);
			this.alert = {
				level: 'success',
				message: l.t('contacts.successfully-merged', 'Successfully merged'),
			};
			this.hide_alert(3);
		} catch (err) {
			this.validation_error(err);
		}
		this.merging_loading = false;
	}

	static mergeArraysProperies(target, source, prop) {
		let property = prop;
		if (!source.length || property === 'tags') return target;
		if (property === 'phone_numbers') property = 'number';
		if (property === 'emails') property = 'email';
		if (property === 'addresses') property = ['street', 'street2', 'city', 'state'];
		for (const src of source) {
			if (typeof property === 'string') {
				if (!target.find(
					(x) => x[property] === src[property],
				)) {
					target.push(src);
				}
			} else if (Array.isArray(property)) {
				for (const p of property) {
					if (!target.find(
						(x) => x[p] === src[p],
					)) {
						target.push(src);
						break;
					}
				}
			}
		}
		return target;
	}

	extensionChanged(extension_id) {
		this.extension = extension_id;
		this.reset();
	}

	reset() {
		this.pairs = [];
		this.mode = null;
		this.total = 0;
		this.edit_to_merge = null;
		this.items = [];
		this.offset = 0;
	}

	updateContactInPairs(contact) {
		for (const pair of this.pairs) {
			if (pair) {
				for (let item of pair) {
					if (item.id === contact.id) {
						item = contact;
					}
				}
			}
		}
	}

	rebuildItems(excludedId) {
		const alreadyIncluded = [];
		const duplications = [];
		for (const pair of this.pairs) {
			pair.map((x) => {
				if (x.id !== excludedId && !alreadyIncluded.includes(x.id)) {
					alreadyIncluded.push(x.id);
					duplications.push(x);
				}

				return true;
			});
		}
		this.items = duplications;
		this.makePairs();
	}

	compareNames(contact1, contact2) {
		const name1 = this.composeName(contact1);
		const name2 = this.composeName(contact2);
		if (![name1, name2].every((x) => x && x !== '-')) return false;
		return (name1 === name2);
	}

	// eslint-disable-next-line class-methods-use-this
	composeName(contact) {
		let name = '';
		if (contact.first_name) name += `${contact.first_name} `;
		if (contact.middle_name) name += `${contact.middle_name} `;
		if (contact.last_name) name += contact.last_name;
		name = name.trim();

		return name;
	}

	// eslint-disable-next-line consistent-return
	static comparePhonesOrMails(contact1, contact2, mode) {
		const phonesOrMails = mode === 'number' ? 'phone_numbers' : 'emails';
		if (!contact1[phonesOrMails].length || !contact2[phonesOrMails].length) {
			return false;
		}
			for (const item of contact1[phonesOrMails]) {
				if (contact2[phonesOrMails].find((x) => x[mode] === item[mode])) {
					return true;
				}
			}
	}

	compareContact(contact1, contact2) {
		const { mode } = this;
		if (mode === 'name') {
			if (this.compareNames(contact1, contact2)) return true;
		} else if (mode === 'number' || mode === 'email') {
			if (ContactDuplicate.comparePhonesOrMails(contact1, contact2, mode)) {
				return true;
			}
		} else if (mode === 'number-or-email') {
			if (ContactDuplicate.comparePhonesOrMails(contact1, contact2, 'email')) {
				return true;
			} if (ContactDuplicate.comparePhonesOrMails(contact1, contact2, 'number')) {
				return true;
			}
		} else if (mode === 'intersection') {
			if (this.compareNames(contact1, contact2)) {
				return true;
			} if (ContactDuplicate.comparePhonesOrMails(contact1, contact2, 'email')) {
				return true;
			} if (ContactDuplicate.comparePhonesOrMails(contact1, contact2, 'number')) {
				return true;
			}
		} else {
			return this.alert = {
				level: 'error',
				message: l.t('contacts.please-select-the-mode', 'Please select the mode from the list.'),
			};
		}

		return false;
	}

	formatedMode() {
		return this.modes.find((x) => x.value === this.mode).name.toLowerCase();
	}
}

export default ContactDuplicate;
