<template>
  <div v-if="has_feature" id="onvego-greeting-helper-container" class="onvego-greeting-helper-container">
    <div class="w-h6">
        {{ greeting }}
        <span class="primary--text">
            <span v-if="contacts_length === 1">
                {{l.t('lr.may-i-have-your-name-pls', 'May I have your name, please?')}}
            </span>
            <span v-else>
                {{l.t('lt.im-happy-to-connect-you', 'I\'m happy to connect you. Just say the name of the person or team you\'d like to reach. If you don\'t know, say: "I don\'t know".')}}
            </span>
        </span>
    </div>
  <div class="iab-response">
    <v-icon color="info" size="18">$vuetify.icons.transcript</v-icon>
    <span class="iab-response-text w-btn-small info--text">{{l.t('lr.iab-response', 'Intelligent Answer Bot response')}}</span>
  </div>
  </div>
</template>
<script>
    import l from '../../../libs/lang';

    export default {
    props: {
        greeting: {
            type: String,
        },
        contacts_length: {
            type: Number,
        }
    },
    data() {
      return {
        l,
        has_feature: false,
    };
    },
    async created() {
        this.$data.has_feature = await this.$feature_toggle.isFeatureEnabled('console.1.1_call_flow');
    },
  };
</script>

<style lang="scss" scoped>
    $carret-height: 20px;
    .onvego-greeting-helper-container {
        position: relative;
        background: #E6F7E3;
        filter:
            drop-shadow(0px 2px 0px #9ECE95)
            drop-shadow(0px -1px 0px #9ECE95)
            drop-shadow(1px 0px 0px #9ECE95)
            drop-shadow(-1px 0px 0px #9ECE95);
        margin-top: calc($carret-height + 11px);
        border-radius: 10px;
        padding: 20px;
    }
    .onvego-greeting-helper-container:after, .onvego-greeting-helper-container:before {
        bottom: 100%;
        left: 10%;
        border: solid transparent;
        content: "";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
    }

    .onvego-greeting-helper-container:after {
        border-bottom-color: #E6F7E3;
        border-width: $carret-height;
        margin-left: -$carret-height;
    }
    .onvego-greeting-helper-container:before {
        border-width: 36px;
        margin-left: -36px;
    }
    .iab-response {
        margin-top: 18px;
        .iab-response-text {
            margin-left: 6px;
        }
    }
</style>
