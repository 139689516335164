<template>
  <div v-if="items.length">
    <w-select :label="label" :items="selector_items" :value="val" @input="on_input" :key="`${!!val}-${show_modal}`"/>
    <ValidationError v-if="error" class="mt-2">
      {{ error }}
    </ValidationError>
    <AddressModal
      v-model="show_modal"
      :prefill_with_cache="false"
      :validation="true"
      :confirm_btn_text="l.t('app.add-address', 'Add address')"
      @address_input="on_new_address_input"
			@validation_failed="on_validation_error"
      :key="`address-selector-modal-${val}`"
      >
      <template v-slot:title>
      {{ l.t('app.enter-a-new-shipping-address', 'Enter a new shipping address') }}
    </template>
  </AddressModal>
  </div>
</template>
<script>
  import l from '../../../libs/lang';
  import Helpers from '../../../libs/helpers';
  import AddressModal from '../modal/AddressModal.vue';
  import ValidationError from './FieldValidationError.vue';

  export default {
    props: {
      value: {
        type: Object,
        required: false,
      },
      items: {
        type: Array,
        required: true,
        validator: ((v) => v.every((x) => typeof x === 'object')),
      },
      label: {
        type: String,
      },
      enter_address_label: {
        type: String,
      }
    },
    components: {AddressModal, ValidationError},
    data() {
      return {
        l,
        val: this.$props.value,
        show_modal: false,
        new_address_value: '_enter_new_address',
        error: null,
      };
    },
    methods: {
        on_input(val) {
          this.$data.error = null;
            if (val === this.$data.new_address_value) {
                this.$data.show_modal = true;
              this.$data.val = null;
            } else {
              this.$data.val = val;
            }
            this.$emit('input', this.$data.val);
        },
        on_new_address_input(val) {
          this.$props.items.push(val);
          this.$data.val = val;
          this.on_input(this.$data.val);
          this.$emit('new_address_added', val);
        },
        on_validation_error(err) {
          console.log(err);
          this.$data.val = null;
          this.$data.error = l.t('app.invalid-address', 'Invalid address');
        },
    },
    computed: {
      selector_items() {
        const items = [];
        if (this.$props.items.length) {
          const unique_addresses = [];
          const stringified_addresses = [];
          for (const address of this.$props.items) {
            const str_addr = JSON.stringify(address).toLowerCase();
            if (!stringified_addresses.includes(str_addr)) {
              unique_addresses.push(address);
              stringified_addresses.push(str_addr);
            }
          }
          for (const addr of unique_addresses) {
            items.push({
              text: Helpers.stringifyAddress(addr),
              value: addr,
            });
          }
        }
        items.push({
            text: this.$props.enter_address_label ? this.$props.enter_address_label : l.t('app.enter-a-new-address', 'Enter a new address'),
            value: this.$data.new_address_value,
        });
        return items;
      }
    }
  };
</script>
