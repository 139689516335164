<template>
  <v-icon
    v-bind="$attrs"
    v-on="$listeners"
    :color="!['avo', 'avo_master'].includes($branding.data.code) ? 'primary' : undefined"
  >
    {{ icon }}
  </v-icon>
</template>

<script>
  export default {
    computed: {
      icon() {
        if (this.$branding.data.icon && this.$branding.data.icon !== 'default') {
          return `$vuetify.icons.${this.$branding.data.icon}`;
        }

        return '$vuetify.icons.phone_com';
      }
    }
  };
</script>
