import l from '../libs/lang';
import Cachier from '../libs/Cachier';
import Helpers from '../libs/helpers';
import EventBus from '../libs/EventBus';
// eslint-disable-next-line import/no-cycle
import Autocomplete from './Autocomplete';

class Resource {
    constructor(session, emitter, baseUri, changeRoute) {
        this.session = session;
        this.cachier = new Cachier(this.session.user.id);
        this.emitter = emitter;
        this.baseUri = baseUri;
        this.uri = this.baseUri;
        this.changeRoute = changeRoute;
        this._loading = false;
        this._alert = null;
        this.limit = 25;
        this.page = 1;
        this.items = [];
        this.filters_applied = false;
        this._filters = {};
        this.hide_filters = false;
        this.disable_delete_button = true;
        this.successful_deletions = null;
        this.failed_deletions = null;
        this.deleting = null;
        this.deleting_item = null;
        this.deleting_finished = false;
        this._stopDeletion = false;
        this.everythingDeleted = false;
        this._check_all = false;
        this.timeUntilRedirects = 2500;
        this.empty_filters = null;
        this.functions = {};
        this.headers = {};
        this.csv_incorrect_lines = [];
        this.possibleCsvMemeTypes = [
            'text/plain',
            'text/x-csv',
            'application/vnd.ms-excel',
            'application/csv',
            'application/x-csv',
            'text/csv',
            'text/comma-separated-values',
            'text/x-comma-separated-values',
            'text/tab-separated-values',
        ];
        this.alert_timer = null;
        this.csv_downloader = null;
        this.keep_filters_open = false;
        this.dynamic_filters_delay = 500;
        this.dynamic_filters_timer = null;
        this.progress = null;
        this.route_using_resource = null;
        this._setup = null;
    }

    get setup() {
        if (this._setup) return this._setup;
        console.error('No setup file provided for the resource');
        return {};
    }

    set setup(val) {
        this._setup = val;
    }

    get filters() {
        return this._filters;
    }

    get enable_close_alert() {
        return this.alert && this.alert.status_code && this.alert.status_code <= 500 && ![404, 201, 204].includes(this.alert.status_code);
    }

    set filters(val) {
        if (!this.empty_filters) {
            this.empty_filters = { ...val };
        }
        this._filters = val;
    }

    get check_all() {
        return this._check_all;
    }

    set check_all(val) {
        this._check_all = val;
        if (this._check_all) {
            return this.checkAll();
        }

        return this.uncheckAll();
    }

    set alert(val) {
        if (val) {
            window.scrollTo({ top: 0 });
        }
        if (val === null) clearTimeout(this.alert_timer);
        this._alert = val;
    }

    get alert() {
        return this._alert;
    }

    set loading(val) {
        this._loading = val;
        window.scrollTo({ top: 0 });
        if (val) {
            if (typeof this.emitter === 'function') this.emitter('hidePagination');
            this.resetDeleting();
        } else if (typeof this.emitter === 'function') {
            this.emitter('showPagination');
        }
    }

    get loading() {
        return this._loading;
    }

    set extension(val) {
        if (val === this._extension) return false;
        this._extension = val;
        this.resetDeleting();
        let uri = this.baseUri.split('/');
        uri = uri.filter((x) => x);
        if (uri[0] === 'extensions') {
            if (val) {
                uri[0] = '/extensions';
                uri[1] = val;
            } else {
                uri[0] = null;
                uri[1] = null;
                uri = uri.filter((x) => x);
                uri[0] = `/${uri[0]}`;
            }
            this.baseUri = uri.join('/');
        } else {
            this.baseUri = `/extensions/${val}/${uri.join('/')}`;
        }
        this.keep_filters_open = false;
        if (typeof this.apply_filters === 'function') this.apply_filters(); // this will change the URI based on baseUri

        return this._extension;
    }

    get extension() {
        return this._extension;
    }

    set stopDeletion(val) {
        this._stopDeletion = val;
        if (val) window.deleting_in_progress = false;
    }

    get stopDeletion() {
        return this._stopDeletion;
    }

    static empty_route_rule() {
        return Helpers.empty_route_rule();
    }

    async getItem(id) {
        this.loading = true;
        try {
            this.item = await this.session.get_item(`${this.baseUri}/${id}`);
            this.loading = false;
            return this.item;
        } catch (err) {
            this.loading = false;
            return this.validation_error(err, true);
        }
    }

    async update(item) {
        this.loading = true;
        try {
            this.item = item;
            await this.session.replace_item(
                `${this.baseUri}/${this.item.id}`, this.item,
            );
            this.successfulUpdate();
        } catch (err) {
            this.validation_error(err);
        }
        this.loading = false;
    }

    checkIfUserDeletedItems(off, page) {
        let offset = off;
        if (
            page === this.page + 1
            && this.items
            && this.items.length < this.limit
        ) {
            offset -= (this.limit - this.items.length);
            if (offset < 0) offset = 0;
        }

        return offset;
    }

    finalizeLoadingItems(items) {
        this.check_all = false;
        this.hideFiltersIfNeeded(items.items);
        this.checkSubmission();
        if (!this.are_filters_applied()) {
            this.keep_filters_open = false;
            this.filters_applied = false;
        }
    }

    static getServiceTypeName(item) {
        if (item === 1) {
            return l.t('app.live-receptionist-service', 'Live Receptionist service');
        }
        if (item === 4) {
            return l.t('app.intelligent-answer-bot-for-499-per-month', 'Intelligent Answer Bot for $4.99 per month');
        }
        return '-';
    }

    checked(e, rss) {
        let item = rss;
        if (e) e.preventDefault();
        item = this.items.find((x) => x.id === item.id);
        setTimeout(() => {
            e.target.checked = item.selected;
        }, 1);
        this.disable_delete_button = !this.items.find((x) => x.selected);
    }

    hideFiltersIfNeeded() {
        for (const key of Object.keys(this.filters)) {
            if (this.filters[key] || this.filters[key] === 0) return (this.hide_filters = false);
        }

        this.hide_filters = !this.items.length;

        return this.hide_filters;
    }

    clear_filters(close_filters = false) {
        this.keep_filters_open = !close_filters;
        this.filters = { ...this.empty_filters };
        this.filters_applied = false;
        if (this.saved_filter_name) this.saved_filter_name = null;
        this.uri = this.baseUri;
    }

    checkAll() {
        if (!this.items.length) return null;

        for (const item of this.items) {
            item.selected = true;
        }
        this.disable_delete_button = false;

        return true;
    }

    uncheckAll() {
        if (!this.items.length) return null;

        for (const item of this.items) {
            item.selected = false;
        }
        this.disable_delete_button = true;

        return true;
    }

    checkSubmission() {
        this.disable_delete_button = !this.items.filter(
            (x) => x.selected,
        ).length;
        if (this.forceUpdate) this.forceUpdate();
    }

    async bulkDelete() {
        this.deleting_finished = false;
        this.loading = true;
        window.deleting_in_progress = true;
        this.deleting = {
            total: 0,
            status: [],
        };
        if (typeof this.emitter === 'function') this.emitter('hidePagination');
        let deleteItems = this.items.filter((x) => x.selected && x.id);
        if (typeof this.pre_delete_selected_filter === 'function') deleteItems = await this.pre_delete_selected_filter(deleteItems);
        await this.delete_items(deleteItems);
        if (!this.items.length) {
            this.everythingDeleted = true;
        } else {
            this.uncheckAll();
        }
        // this.loading = false;
    }

    async deleteAll(uri) {
        let searchUri = uri;
        this.deleting_finished = false;
        this.loading = true;
        window.deleting_in_progress = true;
        searchUri = searchUri || this.uri;
        this.deleting = {
            total: 0,
            status: [],
        };
        if (typeof this.emitter === 'function') this.emitter('hidePagination');
        let deleteItems = await this.session.get_list_all(searchUri);
        deleteItems = deleteItems.items;
        if (typeof this.pre_delete_all_filter === 'function') deleteItems = await this.pre_delete_all_filter(deleteItems);

        const deleted_items = await this.delete_items(deleteItems);
        if (!this.items.length) {
            this.everythingDeleted = true;
        }

        if (this.everythingDeleted && !deleted_items['failed']) {
            if (typeof this.emitter === 'function') this.emitter('hidePagination');
            if (!this.filters_applied) {
                this.hide_filters = true;
            }
        }
    }

    async delete_items(deleteItems) {
        this.deleting = {
            total: deleteItems.length,
            status: [],
        };

        for (const item of deleteItems) {
            if (this.stopDeletion) {
                break;
            }
            try {
                if (typeof this.pre_delete === 'function') await this.pre_delete(item);
                await this.session.delete_item(`${this.baseUri}/${item.id}`);
                this.deleting.status.push({
                    status: 'success',
                    message: l.t('app.successfully-deleted', 'Successfully deleted'),
                    name: this.composeName(item),
                });
                if (typeof this.post_delete === 'function') await this.post_delete(item);
                this.removeFromCache(item);
                const index = this.items.findIndex((x) => x.id === item.id);
                if (index > -1) {
                    this.items.splice(index, 1);
                }
            } catch (err) {
                this.deleting.status.push({
                    status: 'failed',
                    message: Resource.findError(err, item),
                    name: this.composeName(item),
                });
            }
            this.check_all = false;
        }
        this.stopDeletion = false;
        window.scroll(0, 0);

        window.deleting_in_progress = false;
        setTimeout(() => {
            this.generateDeletionsAlerts();
            if (typeof this.emitter === 'function') this.emitter('showPagination');
            this.deleting_finished = true;
            this.loading = false;
        }, 1000);

        return {
            success: this.deleting.status.filter((x) => x.status === 'success').length,
            failed: this.deleting.status.filter((x) => x.status === 'failed').length,
        };
    }

    composeName(item) {
        if (item.name) return item.name;
        if (this.baseUri.includes('/contacts')) {
            return [item.first_name, item.middle_name, item.last_name]
                .filter((x) => x).join(' ');
        }
        if (item.id) return `#${item.id}`;

        return '';
    }

    handleUserRedirect(to, from, next) {
        if (window.deleting_in_progress) {
            const answer = window.confirm(
                l.t('app.stop-deletion', 'Do you want to leave the page and stop deletion?'),
            );
            if (answer) {
                this.stopDeletion = true;
                setTimeout(() => {
                    next();
                }, 3000);
            } else {
                next(false);
            }
        } else {
            next();
        }
    }

    cache_key(key) {
        return `${key}${this.extension ? `_extension_${this.extension}` : ''}`;
    }

    addToCache(item, mode) {
        if (!mode) {
            if (this.selector_mode && this.selector_mode.length) {
                for (const s_mode of this.selector_mode) {
                    this.cachier.addToCache(this.cache_key(s_mode), item, item.id);
                    const ac = new Autocomplete(s_mode, this.session, this.extension);
                    ac.add_to_cache(item);
                }
            }
        } else {
            this.cachier.addToCache(this.cache_key(mode), item, item.id);
            const ac = new Autocomplete(mode, this.session, this.extension);
            ac.add_to_cache(item);
        }
    }

    updateCache(item, mode) {
        if (!mode) {
            if (this.selector_mode && this.selector_mode.length) {
                for (const s_mode of this.selector_mode) {
                    this.cachier.updateCache(this.cache_key(s_mode), item, item.id);
                    const ac = new Autocomplete(s_mode, this.session, this.extension);
                    ac.update_cache(item);
                }
            }
        } else {
            this.cachier.updateCache(this.cache_key(mode), item, item.id);
            const ac = new Autocomplete(mode, this.session, this.extension);
            ac.update_cache(item);
        }
    }

    removeFromCache(item) {
        if (this.selector_mode && this.selector_mode.length) {
            for (const mode of this.selector_mode) {
                this.cachier.removeFromCache(this.cache_key(mode), item, item.id);
                const ac = new Autocomplete(mode, this.session, this.extension);
                ac.remove_from_cache(item);
            }
        }
    }

    successfulCreation(name, params, redirect_countdown, item) {
        this.alert = {
            level: 'success',
            message: l.t('app.successfully-created', 'Successfully created.'),
            status_code: 201,
        };
        const modals = document.querySelectorAll('.v-dialog--active');
        if (!modals.length) {
            window.scrollTo(0, 0);
            window.successfulAlertTimeout = setTimeout(() => {
                let parameters = { redirection_after_creation: true };
                if (params) {
                    parameters = { ...parameters, ...params, };
                }
                if (this.changeRoute) this.changeRoute(name, parameters);
            }, redirect_countdown || this.timeUntilRedirects);
        }
        if (typeof this.emitter === 'function') this.emitter('created', item);
        this.loading = false;
    }

    successfulAlert(message) {
        this.alert = {
            level: 'success',
            message: message || l.t('app.success', 'Success'),
        };

        this.hide_alert(3);
    }

    successfulUpdate(route_name, params, redirect_countdown) {
        this.alert = {
            level: 'success',
            message: `${l.t('app.changes-saved', 'Changes saved')}.`,
            status_code: route_name ? 204 : 200,
        };
        this.hide_alert(2.5);
        const modals = document.querySelectorAll('.v-dialog--active');
        if (!modals.length) {
            window.scrollTo(0, 0);
            if (route_name) {
                window.successfulAlertTimeout = setTimeout(() => {
                    let parameters = { redirection_after_update: true };
                    if (params) {
                        parameters = { ...parameters, ...params, };
                    }
                    this.changeRoute(route_name, parameters);
                }, redirect_countdown || this.timeUntilRedirects);
            }
        }
        if (typeof this.emitter === 'function') this.emitter('updated', this.item);
        EventBus.$emit('item_updated');
        this.loading = false;
    }

    apply_dynamic_filters(ms) {
        if (this.dynamic_filters_timer) clearTimeout(this.dynamic_filters_timer);
        const delay = ms || this.dynamic_filters_delay;
        if (typeof this.do_apply_dynamic_filters !== 'function') {
            throw new Error('You need to define do_apply_dynamic_filters method');
        }
        this.dynamic_filters_timer = setTimeout(() => {
            this.clearMessages();
            this.filters_applied = true;
            this.do_apply_dynamic_filters();
        }, delay);
    }

    csv_downloaded_successfully(s = 5) {
        this.alert = {
            message: l.t('app.csv-downloaded', 'Csv downloaded'),
            level: 'success'
        };
        this.hide_alert(s);
    }

    hide_alert(seconds, type) {
        clearTimeout(this.alert_timer);
        const alertType = type || 'alert';
        this.alert_timer = setTimeout(() => {
            this[alertType] = null;
        }, seconds * 1000);
    }

    show_generic_error() {
        this.alert = {
            message: l.t('app.generic-error', 'Something went wrong'),
            level: 'error',
            status_code: 500,
        };
        this.hide_alert(10);
        window.scrollTo(0, 0);
        this.loading = false;
    }

    static find_validation_message(err) {
        console.log(err);
        const controled_exceptions = ['ValidationException'];
        let message = null;
        if (err && err['@error'] && err['@error']['fields'] && Object.keys(err['@error']['fields']).length > 1) {
            const { fields } = err['@error'];
            for (const field of Object.keys(fields)) {
                let key = field
                    .replace(/:/g, '')
                    .replace(/@(\w+)/g, '')
                    .replace('_', ' ').split('.');
                key = key.map((x) => x.charAt(0).toUpperCase() + x.slice(1)).filter((x) => x);
                key = key.join(' / ');
                const keyMessage = Array.isArray(fields[field]) ? fields[field].join('. ') : fields[field];
                message = `${key}: ${keyMessage}`;
            }
        } else if (
            err
            && err['@error']
            && err['@error']['@message']
        ) {
            message = err['@error']['@message'];
        } else if (controled_exceptions.includes(err.constructor.name) && err.message) {
            // eslint-disable-next-line prefer-destructuring
            message = err.message;
        }
        if (message) message = message.split('.').join(' ');
        return message;
    }

    generateDeletionsAlerts() {
        this.clearMessages();
        if (!this.deleting.status.find((x) => x.status === 'failed')) {
            if (!this.everythingDeleted) {
                if (this.deleting.total === 0) {
                    this.alert = {
                        message: l.t(
                            'app.no-items-are-deleted',
                            'No items are deleted',
                        ),
                        level: 'success',
                    };
                } else if (this.deleting.total === 1) {
                    this.alert = {
                        message: l.t(
                            'app.item-successfully-deleted',
                            '{} item successfully deleted', [this.deleting.status.length],
                        ),
                        level: 'success',
                    };
                } else {
                    this.alert = {
                        message: l.t(
                            'app.multiple-successfully-deleted',
                            '{} items successfully deleted', [this.deleting.status.length],
                        ),
                        level: 'success',
                    };
                }
                this.hide_alert(20);
            }
        } else if (!this.deleting.status.find((x) => x.status === 'success')) {
            this.alert = {
                message: l.t(
                    'app.failed-deletions',
                    '{} items not deleted. Errors:', [this.deleting.status.length]
                ),
                level: 'error',
            };
            this.alert.message += this.constructor.group_messages_by_name(this.deleting.status.filter((x) => x.status === 'failed'));
            this.hide_alert(20);
        } else {
            this.successful_deletions = {
                message: l.t(
                    'app.multiple-successfully-deleted',
                    '{} items successfully deleted', [this.deleting.status.filter((x) => x.status === 'success').length],
                ),
                level: 'success',
            };
            this.failed_deletions = {
                message: l.t(
                    'app.failed-deletions',
                    '{} items not deleted. Errors:', [this.deleting.status.filter((x) => x.status === 'failed').length],
                ),
                level: 'error',
            };
            this.failed_deletions.message += this.constructor.group_messages_by_name(this.deleting.status.filter((x) => x.status === 'failed'));
            this.hide_alert(20, 'successful_deletions');
            this.hide_alert(20, 'failed_deletions');
        }
    }

    async delete_item(id) {
        this.deleting_item = id;
        try {
            await this.session.delete_item(`${this.baseUri}/${id}`);
            this.removeFromCache(this.items.find((x) => x.id === id));
            this.items = this.items.filter((x) => x.id !== id);
        } catch (err) {
            console.log(err);
            if (
                err['@error']
                && err['@error']['@httpStatusCode'] === 409
                && err['@error'].usage
                && err['@error'].usage.length
            ) {
                this.route_using_resource = err['@error'].usage[0].id;
            } else {
                this.validation_error(err);
            }
        }
        this.deleting_item = null;
    }

    static group_messages_by_name(messages) {
        const mess_object = {};
        messages.map((x) => mess_object[x.name] = x.message);
        const helper = Object.entries(mess_object).reduce((helperObj, item) => {
            const key = item[0];
            const val = item[1];
            if (Object.prototype.hasOwnProperty.call(helperObj, val)) {
                helperObj[val].push(key);
            } else {
                helperObj[val] = [key];
            }
            return helperObj;
        }, {});

        const res = Object.entries(helper).reduce((theRes, item) => {
            const name = item[0];
            const message = item[1];
            const resKey = message.join(', ');
            theRes[resKey] = name;
            return theRes;
        }, {});
        let messages_string = Object.entries(res).map((m) => `${m[1]}`);
        messages_string = `\n${messages_string.join('\n')}`;
        return messages_string;
    }

    static findError(err, item) {
        let message = Resource.find_validation_message(err);
        if (message) {
            message = `${item.name ? `${item.name}:` : ''} ${message}`;
        } else {
            message = l.t('app.generic-error', 'Something went wrong');
        }

        return message;
    }

    clearMessages() {
        this.alert = null;
        this.successful_deletions = null;
        this.failed_deletions = null;
    }

    validation_error(err, redirect_404 = false) {
        window.scrollTo(0, 0);
        if (
            err.status === 404
            && redirect_404
            && !document.querySelector('.default-modal-content')
            && typeof this.changeRoute === 'function'
        ) {
            return this.changeRoute('not-found');
        }
        try {
            const message = Resource.find_validation_message(err);
            if (message) {
                this.alert = {
                    message,
                    level: 'error',
                    status_code: err.status,
                };
                if (err.status <= 500 && err.status !== 404) {
                    this.hide_alert(10);
                }
                this.loading = false;
                this.csv_downloader = null;
            } else {
                this.show_generic_error();
            }
        } catch (e) {
            this.show_generic_error();
        }

        return true;
    }

    build_csv(data, f, h) {
        const functions = f || this.functions;
        const headers = h || this.headers;
        let lines = [`${Object.values(headers).map((v) => this.constructor.csv_format_value(v)).join(',')}`];
        lines = lines.concat(
            data.map((item) => {
                let v;
                const line = [];
                for (const key of Object.keys(headers)) {
                    if (headers[key]) {
                        v = Object.prototype.hasOwnProperty.call(functions, key)
                            ? functions[key](item)
                            : item[key];
                        line.push(this.constructor.csv_format_value(v));
                    }
                }
                return line.join(',');
            }),
        );
        return lines.join('\n');
    }

    static csv_format_value(v) {
        // eslint-disable-next-line newline-per-chained-call
        return typeof v === 'string' ? `"${v.split('\n').join('\\n').split(',').join(' ').split('"').join('\\"')}"` : v;
    }

    static download_csv(csv, name) {
        const file = new Blob([csv], {
            type: 'text/csv',
        });
        const a = document.createElement('a');
        const url = URL.createObjectURL(file);
        a.href = url;
        a.download = name;
        document.body.appendChild(a);
        a.click();
        setTimeout(() => {
            document.body.removeChild(a);
            window.URL.revokeObjectURL(url);
        }, 0);
    }

    async loadGroups() {
        const cache_key = this.cache_key('groups');
        const cachedGroups = this.cachier.getItem(cache_key);
        let groups;
        if (cachedGroups) {
            groups = cachedGroups;
        } else {
            const url = `/extensions/${this.extension}/contact-groups`;
            const apiGroups = await this.session.get_list_all(url);
            if (!apiGroups.items.length) {
                apiGroups.items = await Helpers.create_default_contact_groups(this.session, this.extension);
            }
            groups = {};
            for (const g of apiGroups.items) {
                groups[g.id] = g;
            }
            this.cachier.setItem(cache_key, groups);
        }
        return groups;
    }

    csvFileUploaded(event, property) {
        this.alert = null;
        if (event.target.files.length) {
            if (!event.target.files[0].name.endsWith('.csv') && !this.possibleCsvMemeTypes.includes(event.target.files[0].type)) {
                return (this.alert = {
                    message: l.t('validation.csv-file', 'Uploaded file must be a CSV file'),
                    level: 'error',
                });
            }
            const file = event.target.files[0];
            const reader = new FileReader();
            reader.onload = (e) => {
                this[property] = e.target.result;
            };
            reader.onerror = () => {
                this.alert = {
                    message: l.t('validation.error-reading-file', 'Error reading file.'),
                    level: 'error',
                };
            };
            reader.readAsText(file);

            return true;
        }

        return false;
    }

    resetDeleting() {
        this.successful_deletions = null;
        this.failed_deletions = null;
        this.stopDeletion = false;
        this.everythingDeleted = false;
        this.deleting = null;
    }

    are_filters_applied() {
        const filters = Object.keys(this.filters).filter((x) => this.filters[x]);
        if (!filters.length) return false;
        if (filters.length === 1 && filters[0] === 'type' && this.filters.type === 'forever') return false;
        return true;
    }
}

export default Resource;
