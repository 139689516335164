<template>
	<w-tooltip
		:max-width="$attrs.hasOwnProperty('max-width') ? $attrs['max-width'] : 156"
		v-bind="$attrs"
		v-on="$listeners"
		z-index="203"
		data-test-id="info-link"
	>
      <template v-slot:activator>
			<div class="cursor-pointer d-inline info-link-activator">
				<slot name="icon">
					<v-icon size="19" :class="activator_classes" color="#6E7A82">
					  $vuetify.icons.infotip_stylized
					</v-icon>
				</slot>
			</div>
      </template>
      <slot></slot>
    </w-tooltip>
</template>

<script>
export default {
	props: {
		activator_classes: String,
	}
};
</script>
<style>
	.info-link-activator {
		margin-left:  5px !important;
	}
</style>
