var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"routes"},[_c('Submenu',{key:`${_vm.l.detect()}${_vm.$route.name}`,attrs:{"routes":{
      'routes.index': {
        title: _vm.l.t('app.presets', 'Presets'),
        subroutes: ['routes.show']
        },
      'routes.create': {
        title: _vm.l.t('routes.add-preset', 'Add preset')
        },
    }}}),(_vm.$route.name === 'routes.index')?_c('RoutesFragment',{attrs:{"data-test-id":"routes-index"},on:{"deletingStarted":function($event){_vm.deleting_in_progress = true},"deletingEnded":function($event){_vm.deleting_in_progress = false}}}):_vm._e(),(_vm.$route.name === 'routes.create')?_c('RoutesCreateFragment',{attrs:{"data-test-id":"routes-create"}}):_vm._e(),(_vm.$route.name === 'routes.show')?_c('RoutesShowFragment',{attrs:{"data-test-id":"routes-show"}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }