var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"billing"},[_c('Submenu',{key:`${_vm.l.detect()}${_vm.$route.name}`,attrs:{"routes":{
				'billing.index': {
					title: _vm.l.t('billing.statements', 'Statements'),
					subroutes: ['billing.statement']
				},
				'billing.payment-methods': {
					title: _vm.l.t('billing.payment-methods', 'Payment methods'),
					subroutes: ['billing.payment-methods-create']
				},
			}}}),(_vm.$route.name === 'billing.index' || _vm.$route.name === 'billing.statement')?_c('Invoices',{attrs:{"billing":_vm.billing,"data-test-id":"billing-invoices"}}):_vm._e(),(_vm.$route.name === 'billing.payment-methods')?_c('PaymentMethodsList',{attrs:{"data-test-id":"payment-methods-index"}}):_vm._e(),(_vm.$route.name === 'billing.payment-methods-create')?_c('PaymentMethodsCreate',{attrs:{"data-test-id":"payment-methods-show"}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }