<template>
    <div id="chart-legend" class="d-flex justify-center mt-3" data-test-id="chart-legend">
        <div v-for="title in Object.keys(items)" class="d-flex justify-center ml-3">
            <span class="square" :style="{'background': items[title]}"></span>
            <span class="ml-1">{{ title }}</span>
        </div>
    </div>
</template>

<script>
  export default {
    props: ['items'], // items => obj format {translation: color}
  };
</script>

<style lang="scss" scoped>
    .square {
        height: 1rem;
        width: 1rem;
        display: inline-block;
        vertical-align: middle;
    }
</style>
