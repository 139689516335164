import { render, staticRenderFns } from "./LongTable.vue?vue&type=template&id=a0527010&scoped=true&"
import script from "./LongTable.vue?vue&type=script&lang=js&"
export * from "./LongTable.vue?vue&type=script&lang=js&"
import style0 from "./LongTable.vue?vue&type=style&index=0&id=a0527010&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a0527010",
  null
  
)

export default component.exports