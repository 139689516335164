<template>
  <div class="voicemail list-page">
    <PageTitle>{{ l.t('app.voicemail', 'Voicemail') }}</PageTitle>
    <IndexPage
      :resource="voicemail"
      :withExtensionSelector="true"
      :disable_add_new="true"
      :label_cols="label_cols"
      :extensionCb="'load_saved_filters'"
      data-test-id="voicemail-page"
    >
    <template v-slot:filters>
      <SmartFilters
        :log="voicemail"
        :key="JSON.stringify(voicemail.client_config)"
        :label_cols="label_cols"
      />
      <FormInput :label="l.t('app.new', 'New')" :label_cols="label_cols">
        <w-switch
          v-model="voicemail.filters.is_new"
          @change="voicemail.restartSavedFilterName()"
          :label="l.t('app.new', 'New')"
          hide-details='auto'
          data-test-id="voicemail-filters-new-switch"
        />
      </FormInput>
      <FormInput :label="l.t('app.from-number', 'From number')" :label_cols="label_cols">
        <Tel
          :value="voicemail.filters.from"
          @changed="voicemail.filter_param_changed('from', $event.clean_number)"
          :key="voicemail.saved_filter_name"
          data-test-id="voicemail-filters-from-input"
        />
      </FormInput>
      <FormInput :label="l.t('app.to-number', 'To number')" :label_cols="label_cols">
        <APIAutocomplete
          mode="phone_numbers"
          :value="voicemail.filters['to']"
          @input="voicemail.filter_param_changed('to', $event)"
          :before_list="[{value: null, text: ''}]"
          data-test-id="voicemail-filters-to-selector"
        />
      </FormInput>
    </template>
    <template v-slot:list>
      <v-simple-table class="list-table" data-test-id="voicemail-table">
        <thead data-test-id="table-head">
          <tr data-test-id="head-row">
              <th class="select-table-item" data-test-id="checkbox"><br/></th>
              <th data-test-id="reveiced-at">{{ l.t("app.received-at", "Received at") }}</th>
              <th data-test-id="from">{{ l.t("app.from", "From") }}</th>
              <th data-test-id="location">{{ l.t("app.location", "Location") }}</th>
              <th data-test-id="caller">{{ l.t("app.caller", "Caller") }}</th>
              <th data-test-id="duration">{{ l.t("app.duration", "Duration") }}</th>
              <th data-test-id="player"><br/></th>
          </tr>
        </thead>
        <tbody class="nowrap" data-test-id="table-body">
          <tr
            v-for="(m, i) in voicemail.items"
            :key="`${m['id']}-${Number(m.selected)}`"
            :class="{ 'font-weight-black': m['is_new'], 'selected-row': m.selected }"
            :data-test-id="m.id"
          >
            <td v-if="voicemail.deleting_item_id === m.id" colspan="10" data-test-id="deleting">
              <w-loader size="small" :no_margins="true"/>
            </td>
            <template v-else>
              <td class="select-table-item" data-test-id="checkbox">
                <w-checkbox
                  v-model="m.selected"
                  @change="checkChanged"
                  class="multiple-select-checkbox"
                  hide-details="auto"
                  :data-test-id="`voicemail-item-${i}-checkbox`"
                />
              </td>
              <td data-test-id="created-at">
                  {{ new Date(m["created_at"] * 1000).toLocaleString() }}
              </td>
              <td data-test-id="from">{{ m["from"]["number"] | format_phone_number }}</td>
              <td data-test-id="location">
                  {{ m["from"]["city"] }}{{ m["from"]["city"] && m["from"]["state"] ? ", " : "" }}{{ m["from"]["state"] }}
              </td>
              <td data-test-id="caller">{{ m["from"]["name"] }}</td>
              <td>{{ m["duration"] | format_duration }}</td>
              <td data-test-id="options">
                <MoreOptions
                  :options="more_options(m)"
                  :icon="loading_recording_id === m.id ? '$vuetify.icons.spinning_loader' : m.player && m.player.playing ? '$vuetify.icons.play' : null"
                  :key="`${playing_id}-${m.player && m.player.playing}`"
                />
                <ConfirmationModal
                  :key="m['id']"
                  v-if="confirmation_modal === m.id"
                  @confirmed="confirmed(m['id'])"
                  @close="confirmation_modal = false"
                  :message="l.t('app.are-you-sure-delete-message','Are you sure you want to remove this message? You will not be able to access it after removing.')"
                  :data-test-id="`voicemail-item-${i}-conformation-modal`"
                />
              </td>
            </template>
          </tr>
        </tbody>
      </v-simple-table>
      <DefaultModal :value="!!show_message_id" @input="on_close_info_modal" max-width="425">
        <template v-slot:title>{{l.t('voicemail.ID', 'Voicemail ID')}}</template>
        {{ show_message_id }}
        <CopyToClipboard
          v-if="show_message_id"
          :copy="show_message_id"
          class="ml-3"
          :data-test-id="`copy-voicemail-id-copy-to-clipboard`"
        />
        <template v-slot:buttons>
          <w-btn @click="show_message_id = null" color="secondary">{{l.t('app.close', 'Close')}}</w-btn>
        </template>
      </DefaultModal>
      <DefaultModal :value="!!error" @input="on_close_error_modal" max-width="425">
        {{error}}
        <template v-slot:buttons>
          <w-btn @click="error = null" color="secondary">{{l.t('app.close', 'Close')}}</w-btn>
        </template>
      </DefaultModal>
    </template>
    </IndexPage>
  </div>
</template>

<script>
  import l from '../../libs/lang';
  import Helpers from '../../libs/helpers';
  import Voicemail from '../../models/Voicemail';
  import FileHandler from '../../libs/FileHandler';
  import Tel from '../elements/Tel.vue';
  import PageTitle from '../elements/PageTitle.vue';
  import IndexPage from '../elements/IndexPage.vue';
  import MoreOptions from '../elements/MoreOptions.vue';
  import FormInput from '../elements/form/FormInput.vue';
  import SmartFilters from '../elements/SmartFilters.vue';
  import DefaultModal from '../elements/modal/DefaultModal.vue';
  import CopyToClipboard from '../elements/CopyToClipboard.vue';
  import APIAutocomplete from '../elements/form/APIAutocomplete.vue';
  import ConfirmationModal from '../elements/modal/ConfirmationModal.vue';

  export default {
    components: {
      Tel,
      FormInput,
      IndexPage,
      PageTitle,
      MoreOptions,
      SmartFilters,
      DefaultModal,
      APIAutocomplete,
      CopyToClipboard,
      ConfirmationModal,
    },
    data() {
      return {
        l,
        voicemail: new Voicemail(this.$session, Helpers.emitter(this), Helpers.changeRoute(this)),
        playing: null,
        one_extension: false,
        confirmation_modal: false,
        label_cols: 2,
        playing_id: null,
        loading_recording_id: null,
        show_message_id: null,
        error: null,
      };
    },
    created() {
      this.$data.voicemail.loading = true;
    },
    beforeRouteLeave(to, from, next) {
      return this.$data.voicemail.handleUserRedirect(to, from, next);
    },
    beforeDestroy() {
      for (const item of this.$data.voicemail.items) {
        if (item.player) {
          item.player.revoke_urls();
        }
      }
    },
    methods: {
      more_options(item) {
        item.player = item.player || new FileHandler(
          `/extensions/${this.$data.voicemail.extension}/voicemail/${item.id}/download`,
          this.$session,
          {
            stopped_callback: () => this.$data.playing_id = null,
          }
        );
        let options = [{
          cb: async () => {
            this.$data.loading_recording_id = item.id;
            try {
              await item.player.download();
              this.$data.loading_recording_id = false;
              if (item.is_new) await this.$data.voicemail.opened(item.id);
            } catch (err) {
              console.log(err);
              this.$data.error = l.t('app.something-went-wrong-while-downlading', 'Something went wrong ');
            }
            this.$data.loading_recording_id = false;
          },
          title: l.t('app.download-recording', 'Download recording')
        }];
        if (this.$data.playing_id === item.id) {
          options.push({
            title: l.t('app.stop-recording', 'Stop recording'),
            cb: async () => {
              try {
                await item.player.stop();
              } catch (err) {
                console.log(err);
                this.$data.error = l.t('app.generic-error', 'Something went wrong');
              }
              this.$data.playing_id = null;
            }
          });
        } else {
          options.push({
            title: l.t('app.play-recording', 'Play recording'),
            cb: async () => {
              this.$data.loading_recording_id = item.id;
              try {
                this.$data.playing_id = item.id;
                await item.player.play();
                if (item.is_new) await this.$data.voicemail.opened(item.id);
              } catch (err) {
                console.log(err);
                this.$data.error = l.t('app.something-went-wrong-while-downlading', 'Something went wrong ');
              }
              this.$data.loading_recording_id = false;
            }
          });
        }
        options = options.concat([
          {
            cb: async () => this.$data.confirmation_modal = item.id,
            title: l.t('app.delete-recording', 'Delete recording'),
          },
          {
            cb: () => this.$data.show_message_id = item.id,
            title: l.t('app.info', 'Info'),
          },
        ]);
        return options;
      },
      more_options_icon(item) {
        if (this.$data.loading_recording_id === item.id) return '$vuetify.icons.spinning_loader';
        if (item.player && item.player.playing) return '$vuetify.icons.play';

        return null;
      },
      on_close_info_modal(val) {
        if (!val) this.$data.show_message_id = null;
      },
      on_close_error_modal(val) {
        if (!val) this.$data.error = null;
      },
      async confirmed(id) {
        this.$data.confirmation_modal = false;
        await this.$data.voicemail.delete_m(id);
      },
      checkChanged() {
        this.$data.voicemail.checkSubmission();
        this.$emit('forceUpdate');
      },
    }
  };
</script>
