import l from '../libs/lang';
import Helpers from '../libs/helpers';
import Resource from './Resource';
import setup from '../libs/resources-setups/live-receptionist';

class LiveAnswer extends Resource {
  constructor(session, emitter, changeRoute) {
    super(session, emitter, '/live-answer', changeRoute);
    this.selector_mode = ['live_answer'];
    this.setup = setup;
    this.filters = {
      'name': null,
    };
    this.empty_destination = {
        'type': null,
        'destination': '',
        'is_warm_transfer': false,
    };
    this.empty_contact = {
        'first_name': '',
        'last_name': '',
        'title': '',
        'call_handling_notes': '',
        'destinations': [JSON.parse(JSON.stringify(this.empty_destination))],
    };
    this.empty_business_contact = {
      'fax': '',
      'address': {
        'line_1': '',
        'line_2': '',
        'city': '',
        'province': '',
        'zip': '',
        'country': '',
      },
    };
    this.original_item = null;
    this.item = {
      'code': null,
      'name': '',
      'business_name': '',
      'enabled': true,
      'business_contact': JSON.parse(JSON.stringify(this.empty_business_contact)),
      'business_description': '',
      'greeting': '',
      'call_handling_notes': '',
      'appointments_enabled': false,
      'appointments_description': '',
      'contacts': [JSON.parse(JSON.stringify(this.empty_contact))],
      'vendor': null,
    };
    this.codes_translations = {
      19074: {
        'short': l.t('lr.plan-text-short', '{} mins', [50]),
        'long': l.t('lr.plan-text', '{} minutes for ${} per month', [50, 90]),
        'price': 90,
      },
      19075: {
        'short': l.t('lr.plan-text-short', '{} mins', [100]),
        'long': l.t('lr.plan-text', '{} minutes for ${} per month', [100, 140]),
        'price': 140,
      },
      19076: {
        'short': l.t('lr.plan-text-short', '{} mins', [200]),
        'long': l.t('lr.plan-text', '{} minutes for ${} per month', [200, 220]),
        'price': 220,
      },
      19077: {
        'short': l.t('lr.plan-text-short', '{} mins', [350]),
        'long': l.t('lr.plan-text', '{} minutes for ${} per month', [350, 400]),
        'price': 400,
      },
      19078: {
        'short': l.t('lr.plan-text-short', '{} mins', [500]),
        'long': l.t('lr.plan-text', '{} minutes for ${} per month', [500, 550]),
        'price': 550,
      },
      19079: {
        'short': l.t('lr.plan-text-short', '{} mins', [750]),
        'long': l.t('lr.plan-text', '{} minutes for ${} per month', [750, 800]),
        'price': 800,
      },
      19080: {
        'short': l.t('lr.plan-text-short', '{} mins', [1000]),
        'long': l.t('lr.plan-text', '{} minutes for ${} per month', [1000, 1000]),
        'price': 1000,
      },
      19086: {
        'short': 'Alliance Virtual Offices - English',
        'long': 'Alliance Virtual Offices - English'
      },
      19087: {
        'short': l.t('lr.unlimited-24-7', 'Unlimited 24/7'),
        'long': l.t('lr.unlimited-24-7', 'Unlimited 24/7'),
      },
    };
    this.onvego_id = 4;
    this.avo_plan = {
      'id': 551,
      'code': 19086,
      'type': 'periodic',
      'retail': 0
    };
    this.virtual_generic_name = '';
    this.live_generic_name = '';
  }

  async loadItems(params) {
    this.loading = true;
    try {
      const offset = this.checkIfUserDeletedItems(params.offset, params.page);
      const uri = `${this.uri}${this.uri.includes('?') ? '&' : '?'}sort[id]=desc`;
      const items = await this.session.get_list(uri, params.limit, offset);
      this.page = params.page;
      if (this.page > 1 && !items.items.length) {
        this.emitter('pageChanged', 1);
        return this.loadItems({
          limit: this.limit,
          offset: 0,
          page: 1,
        });
      }
      if (this.page === 1 && !this.filters_applied && !items.items.length) return this.changeRoute('live-answer.create');

      this.items = items.items;
      this.finalizeLoadingItems(items);

      this.emitter('itemsLoaded', JSON.parse(JSON.stringify(items)));
    } catch (err) {
      this.validation_error(err);
    }
    this.loading = false;

    return this.items;
  }

  static prepareData(data) {
    if (!data.appointments_enabled) {
      data.appointments_description = '';
    }
    if (data.contacts && data.contacts.length) {
      data.contacts = data.contacts.map((x) => {
        if (x._unique_key) delete x._unique_key;
        if (x.destinations && x.destinations.length) {
            x.destinations = x.destinations.map((d) => {
                if (d._unique_key) delete d._unique_key;
                return d;
            });
        }
        return x;
      });
    }

    return data;
  }

  async prepoluate_business_profile() {
    this.loading = true;
    try {
      const profile = JSON.parse(JSON.stringify(this.session.user.account));
      const contact = profile ? profile.contact : null;
      if (contact) {
        this.item.business_name = contact.company;
        this.item.business_contact.fax = contact.fax;
        this.item.business_contact.address = contact.address;
      }
    } catch (err) {
      console.log(err);
    }
    this.loading = false;
  }

  async prepopulate_contacts() {
    if (/\d/.test(this.session.user.account.name)) {
      return null;
    }
    const {first_name, last_name} = Helpers.get_first_and_last_name(this.session.user.account.name);
    this.item.contacts[0].first_name = first_name;
    this.item.contacts[0].last_name = last_name;
    let default_extension = null;
    if (this.session.user.account.default_voip_phone) {
      default_extension = this.session.user.account.default_voip_phone.id;
    } else {
      const nxt_details = await Helpers.nxt_details(this.session);
      if (nxt_details && nxt_details.user_extensions && nxt_details.user_extensions.length) {
        default_extension = nxt_details.user_extensions[0];
      } else {
        const extensions = await this.session.get_list('/extensions', 1);
        if (extensions && extensions.items && extensions.items.length) {
          default_extension = extensions.items[0].id;
        }
      }
    }
    if (default_extension) {
      this.item.contacts[0].destinations = [
        {
          'type': 'extension',
          'destination': `${default_extension}`,
          'is_warm_transfer': true,
        },
        {
          'type': 'voicemail',
          'destination': `${default_extension}`,
          'is_warm_transfer': false,
        }
      ];
    }

    return true;
  }

  async deactivate(id) {
    this.loading = true;
    try {
      const updated_item = await this.session.patch_item(
          `${this.baseUri}/${id || this.original_item.id}`, { enabled: false },
      );
      this.item = {...updated_item};
      this.original_item = JSON.parse(JSON.stringify(updated_item));
      this.updateCache(updated_item);
      this.successfulAlert(l.t('app.successfully-disabled', 'Successfully disabled.'));
    } catch (err) {
      this.validation_error(err, true);
    }
    this.loading = false;
  }

  async reactivate(id) {
    this.loading = true;
    try {
      const updated_item = await this.session.patch_item(
          `${this.baseUri}/${id || this.original_item.id}`, { enabled: true },
      );
      this.item = {...updated_item};
      this.original_item = JSON.parse(JSON.stringify(updated_item));
      this.updateCache(updated_item);
      this.successfulAlert(l.t('app.successfully-enabled', 'Successfully enabled.'));
    } catch (err) {
      this.validation_error(err, true);
    }
    this.loading = false;
  }

  async create(item) {
    this.loading = true;
    try {
      const data = this.constructor.prepareData({...item});
      const newLiveAnswer = await this.session.create_item(this.baseUri, data);
      let onvego_success = true;
      if (
        item.vendor
        && item.vendor.id === this.onvego_id
        && item.appointments_enabled
        && item.has_onvego_apointments
      ) {
        console.log('submitAppointments');
        try {
          await window.submitAppointments(newLiveAnswer.id);
        } catch (err) {
          onvego_success = false;
        }
      }
      this.successfulCreation('live-answer.index', null, onvego_success ? null : 4000, newLiveAnswer);
      if (!onvego_success) {
        this.alert = {
          level: 'warning',
          message: l.t('lr.something-went-wrong-with-calendar', 'Something went wrong while saving calendar settings. Please edit the script and try saving it again'),
        };
      }
      this.addToCache(newLiveAnswer);
      this.item = null;
    } catch (err) {
      this.loading = false;
      this.item = item;
      return this.validation_error(err);
    }

    return true;
  }

  async update(item) {
    const data = this.prepare_patch_data(this.constructor.prepareData(JSON.parse(JSON.stringify(item))));
    this.loading = true;
    let onvego_success = true;
    try {
      if (item.vendor && item.vendor.id === this.onvego_id && item.appointments_enabled && item.has_onvego_apointments) {
        console.log('submitAppointments');
        try {
          await window.submitAppointments(item.id);
        } catch (err) {
          onvego_success = false;
        }
      }
      if (Object.keys(data).length) {
        const updated_item = await this.session.patch_item(
            `${this.baseUri}/${this.original_item.id}`, data,
        );
        this.item = {...updated_item};
        this.original_item = JSON.parse(JSON.stringify(updated_item));
        this.updateCache(updated_item);
      } else {
        this.item = {...item};
        this.original_item = JSON.parse(JSON.stringify(item));
      }
      this.successfulUpdate('live-answer.index', null, onvego_success ? null : 4000);
      if (!onvego_success) {
        this.alert = {
          level: 'warning',
          message: l.t('lr.something-went-wrong-with-calendar', 'Something went wrong while saving calendar settings. Please edit the script and try saving it again'),
        };
      }
      this.item = null;
    } catch (err) {
        this.item = {...item};
        this.validation_error(err);
    }
    this.loading = false;
  }

  prepare_patch_data(data) {
    delete data.has_onvego_apointments;
    for (const prop of ['selected', 'enabled', 'id', 'popup_id', 'did', 'created_at', 'updated_at']) {
      delete data[prop];
    }
    if (data.business_contact) {
      for (const prop of ['id', 'primary_email', 'alternate_email', 'phone', 'selected']) {
        delete data.business_contact[prop];
      }
    }
    if (this.original_item) {
      for (const prop of ['code', 'business_description', 'call_handling_notes', 'greeting', 'name', 'business_name', 'appointments_enabled', 'appointments_description']) {
        if (this.original_item[prop] === data[prop]) {
          delete data[prop];
        }
      }
      if (this.original_item.business_contact
        && data.business_contact
        && (JSON.stringify(this.original_item.business_contact) === JSON.stringify(data.business_contact))
      ) {
          delete data.business_contact;
      }
      if (this.original_item.contacts
        && data.contacts
        && (JSON.stringify(this.original_item.contacts) === JSON.stringify(data.contacts))
      ) {
        delete data.contacts;
      }

      if (this.original_item.vendor && data.vendor && this.original_item.vendor.id === data.vendor.id) {
        delete data.vendor;
      }
    }
    return data;
  }

  apply_filters() {
    this.clearMessages();
    this.filters_applied = true;
    try {
      let uri = this.baseUri;
      const filters = [];
      if (this.filters.name) filters.push(`filters[name]=contains:${this.filters.name}`);
      if (filters.length) uri = `${uri}?${filters.join('&')}`;
      this.uri = uri;
    } catch (err) {
      this.emitter('failed', err);
    }
  }

  do_apply_dynamic_filters() {
    try {
      let uri = this.baseUri;
      const filters = [];
      if (this.filters.name) filters.push(`filters[name]=contains:${this.filters.name}`);
      if (filters.length) uri = `${uri}?${filters.join('&')}`;
      this.uri = uri;
    } catch (err) {
      this.emitter('failed', err);
    }
  }

  async getItem(id) {
      this.loading = true;
      try {
          const item = await this.session.get_item(`${this.baseUri}/${id}`);
          this.item = item;
          this.original_item = JSON.parse(JSON.stringify(item));
          this.loading = false;
          return this.item;
      } catch (err) {
          this.item = null;
          this.validation_error(err, true);
      }
      this.loading = false;
      return false;
  }

  async prepare_generic_names() {
    try {
      const live_name = l.t('lr.prefilled-script-name-virual', 'Live Receptionist script for {}', [this.session.user.account.contact.company]);
      const virtual_name = l.t('lr.prefilled-script-name-live', 'Intelligent Answer Bot script for {}', [this.session.user.account.contact.company]);
      const virtual_names = await this.find_genericly_named_scripts(virtual_name);
      const live_names = await this.find_genericly_named_scripts(live_name);
      this.virtual_generic_name = this.constructor.generate_script_name(virtual_name, virtual_names);
      this.live_generic_name = this.constructor.generate_script_name(live_name, live_names);
    } catch (err) {
      console.log('An error occured while preparing generic script names', err);
    }
  }

  async find_genericly_named_scripts(name) {
    const scripts = await this.session.get_list_all(`/live-answer?filters[name]=contains:${name}`);
    const lowercase_name = name.toLowerCase();

    return scripts.items.filter((x) => x.name.toLowerCase().startsWith(lowercase_name)).map((x) => x.name);
  }

  static generate_script_name(base_name, names) {
    const lowercase_names = names.map((x) => x.toLowerCase());
    let valid_name = null;
    let i = 1;
    do {
      const name = `${base_name} ${i}`;
      if (!lowercase_names.includes(name.toLowerCase())) {
        valid_name = name;
      }
      i++;
    } while (!valid_name);
    return valid_name;
  }
}

export default LiveAnswer;
