<template>
    <w-btn color="primary" class="w-btn-small page-back-btn" text @click="on_click" data-test-id="go-back-btn">
        <v-icon size="16">$vuetify.icons.chevron_backward</v-icon>{{l.t('app.back-lowercase', 'back')}}
    </w-btn>
  </template>
  <script>
  import l from '../../libs/lang';

  export default {
      data() {
          return { l };
      },
      methods: {
          on_click() {
              this.$router.go(-1);
          },
      }
  };
  </script>
