<template>
    <div>
          <w-alert
            v-if="alert"
            :message="alert.message"
            :level="alert.level"
            class="mt-5"
          />
        <div v-if="contact">
            <section>
              <SectionTitle data-test-id="contact-info-title">
                {{ l.t('app.contact-info', 'Contact info') }}
                <w-btn v-if="enable_canceling" @click="$emit('canceled')" :title="l.t('app.cancel', 'Cancel')" icon>
                  <v-icon color="error">
                    mdi-close-circle
                  </v-icon>
                </w-btn>
              </SectionTitle>
              <w-form @changed="may_submit = true" @submit="submitted" data-discard="true">
                <FormInput :label="l.t('app.first-name', 'First name')">
                  <w-text-field v-model="contact.first_name" hide-details='auto' data-test-id="contact-first-name-input" :rules="[$validation.required()]"/>
                </FormInput>
                <FormInput :label="l.t('addressbook.middle-name', 'Middle name')" >
                  <w-text-field v-model="contact.middle_name" hide-details='auto' data-test-id="contact-middle-name-input"/>
                </FormInput>
                <FormInput :label="l.t('app.last-name', 'Last name')" >
                  <w-text-field v-model="contact.last_name" hide-details='auto' data-test-id="contact-last-name-input"/>
                </FormInput>
                <FormInput :label="l.t('app.nickname', 'Nickname')" >
                  <w-text-field v-model="contact.nickname" hide-details='auto' data-test-id="contact-nickname-input"/>
                </FormInput>
                <FormInput :label="l.t('app.prefix', 'Prefix')" >
                  <w-text-field v-model="contact.prefix" hide-details='auto' data-test-id="contact-prefix-input"/>
                </FormInput>
                <FormInput :label="l.t('addressbook.suffix', 'Suffix')" >
                  <w-text-field v-model="contact.suffix" hide-details='auto' data-test-id="contact-suffix-input"/>
                </FormInput>
                <FormInput
                  v-if="extension_id"
                  :label="l.t('app.group', 'Group')"
                >
                  <APIAutocomplete
                    :key="extension_id"
                    mode="groups"
                    :before_list="[{text: l.t('app.no-group', 'No group'), value: null}]"
                    :extension="extension_id"
                    :value="contact.group ? contact.group.id : null"
                    @changed="contact.group = $event.value ? $event.value : null"
                    data-test-id="contact-groups-select"
                  />
                </FormInput>
                <FormInput :label="l.t('app.company', 'Company')">
                  <w-text-field v-model="contact.company" hide-details='auto' data-test-id="contact-company-input"/>
                </FormInput>
                <FormInput :label="l.t('addressbook.department', 'Department')">
                  <w-text-field v-model="contact.department" hide-details='auto' data-test-id="contact-department-input"/>
                </FormInput>
                <FormInput :label="l.t('app.title', 'Title')">
                  <w-text-field v-model="contact.job_title" hide-details='auto' data-test-id="contact-title-input"/>
                </FormInput>
                <SectionTitle data-test-id="contact-emails-title">
                  {{ l.t('app.emails', 'Emails') }}
                </SectionTitle>
                <FormInput v-for="(email, i) in contact.emails" :key="i+'email'">
                  <template v-slot:label>
                    <w-select
                      v-model="contact.emails[i].type"
                      :rules="[$validation.required()]"
                      :placeholder="l.t('addressbook.choose-type', 'Choose type')"
                      :items="[
                        {value: 'primary', text: l.t(`addressbook.primary-email`, 'Primary email')},
                        {value: 'alternate', text: l.t('app.secondary-email', 'Secondary email')}
                      ]"
                      hide-details="auto"
                      :data-test-id="`contact-emails-${i}-type-selector`"
                    />
                  </template>
                  <div class="d-flex">
                    <w-text-field
                      v-model="email.email"
                      type="email"
                      :rules="[$validation.required(), $validation.email()]"
                      hide-details='auto'
                      :data-test-id="`contact-emails-${i}-email-input`"
                    />
                    <RemoveFromListButton @click="$delete(contact.emails, i)" icon color="error" class="ml-3 my-auto"/>
                  </div>
                </FormInput>
                <FormInput>
                  <AddToListButton @click="contact.emails.push({
                      iteration_key: contact.emails.length+1+Math.random(),
                      email: null,
                      type: null
                    })"
                    data-test-id="contact-add-email-btn"
                  >
                    {{ l.t('app.phone-email', 'Add email') }}
                  </AddToListButton>
                </FormInput>
                <SectionTitle data-test-id="contact-phones-title">
                  {{ l.t('app.phone-numbers', 'Phone numbers') }}
                </SectionTitle>
                <FormInput v-for="(phone, i) in contact.phone_numbers" :key="phone + i">
                  <template v-slot:label>
                    <w-select
                      v-model="contact.phone_numbers[i].type"
                      :placeholder="l.t('addressbook.choose-type', 'Choose type')"
                      :items="[
                        {value: 'business', text: l.t('app.business', 'Business')},
                        {value: 'home', text: l.t('addressbook.home', 'Home')},
                        {value: 'mobile', text: l.t('addressbook.mobile', 'Mobile')},
                        {value: 'fax', text: l.t('app.fax', 'Fax')},
                        {value: 'pager', text: l.t('addressbook.pager', 'Pager')},
                      ]"
                      hide-details="auto"
                      required
                      :data-test-id="`contact-phones-${i}-type-selector`"
                    />
                  </template>
                  <Tel
                    :required="true"
                    @changed="contact.phone_numbers[i].number = $event.value"
                    :value="contact.phone_numbers[i].number"
                    :key="contact.phone_numbers[i].iteration_key"
                    :closable="true"
                    @closed="$delete(contact.phone_numbers, i)"
                    :data-test-id="`contact-emails-${i}-phone-input`"
                  />
                </FormInput>
                <FormInput>
                  <AddToListButton
                    v-if="contact.phone_numbers.length < 5"
                    @click="contact.phone_numbers.push({
                      iteration_key: contact.phone_numbers.length+1+Math.random(),
                      type: 'mobile',
                      number: null
                    })"
                    key="phones"
                    data-test-id="contact-add-phone-btn"
                  >
                      {{ l.t('app.phone-add', 'Add phone number') }}
                  </AddToListButton>
                </FormInput>
                <SectionTitle data-test-id="contact-addresses-title">
                  {{ l.t('app.addresses', 'Addresses') }}
                </SectionTitle>
                <template v-for="(address, i) in contact.addresses">
                  <h4 class="w-body-2 default--text my-3">
                    {{ l.t('app.address', 'Address') }}
                    <RemoveFromListButton @click="$delete(contact.addresses, i)" icon color="error" class="ml-3 my-auto" />
                  </h4>
                  <FormInput :label="l.t('app.type', 'Type')">
                    <w-select
                      v-model="address.type"
                      required
                      :items="[{value: 'home', text: l.t('addressbook.home', 'Home')}, {value: 'business', text: l.t('app.business', 'Business')}]"
                      hide-details="auto"
                      :data-test-id="`contact-addresses-${i}-type-selector`"
                    />
                  </FormInput>
                  <FormInput :label="l.t('app.street-address', 'Street address')">
                    <w-text-field v-model="address.street" hide-details='auto' :data-test-id="`contact-addresses-${i}-street-input`"/>
                  </FormInput>
                  <FormInput :label="l.t('app.secondary-street-address', 'Secondary street address')">
                    <w-text-field v-model="address.street2" hide-details='auto' :data-test-id="`contact-addresses-${i}-street-2-input`"/>
                  </FormInput>
                  <FormInput :label="l.t('app.city', 'City')">
                    <w-text-field v-model="address.city" hide-details='auto' :data-test-id="`contact-addresses-${i}-city-input`"/>
                  </FormInput>
                  <FormInput :label="l.t('app.state', 'State')">
                    <w-text-field v-model="address.state" hide-details='auto' :data-test-id="`contact-addresses-${i}-state-input`"/>
                  </FormInput>
                  <FormInput :label="l.t('addressbook.zip', 'ZIP')">
                    <w-text-field v-model="address.zip" hide-details='auto' :data-test-id="`contact-addresses-${i}-zip-input`"/>
                  </FormInput>
                  <FormInput :label="l.t('addressbook.country', 'Country')">
                    <CountrySelector
                      :additional_value="address.country"
                      v-model="address.country"
                      _type="name"
                      :data-test-id="`contact-addresses-${i}-country-input`"
                    />
                  </FormInput>
                </template>
                <FormInput>
                  <AddToListButton @click="contact.addresses.push({
                      type: null,
                      city: null,
                      country: null,
                      state: null,
                      street: null,
                      stret2: null,
                      zip: null,
                    })"
                    data-test-id="contact-add-address-btn"
                  >
                    {{ l.t('addressbook.add-address', 'Add address') }}
                  </AddToListButton>
                </FormInput>
                <FormInput>
                  <w-btn :disabled="!may_submit" class="primary mt-5" type="submit" data-test-id="contact-submit-btn">{{ l.t('app.save', 'Save') }}</w-btn>
                </FormInput>
              </w-form>
            </section>
        </div>
    </div>
</template>
<script>
  import l from '../../libs/lang';
  import Tel from '../elements/Tel.vue';
  import FormInput from '../elements/form/FormInput.vue';
  import SectionTitle from '../elements/SectionTitle.vue';
  import CountrySelector from '../elements/CountrySelector.vue';
  import AddToListButton from '../elements/AddToListButton.vue';
  import APIAutocomplete from '../elements/form/APIAutocomplete.vue';
  import RemoveFromListButton from '../elements/RemoveFromListButton.vue';

  export default {
    props: ['_contact', 'enable_canceling', 'extension_id'],
    components: {
      Tel,
      FormInput,
      SectionTitle,
      AddToListButton,
      APIAutocomplete,
      CountrySelector,
      RemoveFromListButton,
    },
    data() {
      return {
        l,
        loading: false,
        alert: null,
        contact: null,
        group: null,
        disable_submit: false,
        may_submit: false,
      };
    },
    created() {
      this.$data.contact = this.$props._contact;
      this.preparePhoneNumbers();
    },
    methods: {
      submitted(e) {
        if (e) e.preventDefault();
        this.prepareDataForSubmission();
        this.$emit('contactSubmited', this.$data.contact);
      },
      prepareDataForSubmission() {
        for (const phone of this.$data.contact.phone_numbers) {
          delete phone.iteration_key;
        }
      },
      preparePhoneNumbers() {
        for (const i in this.$data.contact.phone_numbers) {
          if (this.$data.contact.phone_numbers[i]) {
            this.$data.contact.phone_numbers[i]['iteration_key'] = i + Math.random();
          }
        }
      },
    },
    watch: {
      group(val) {
        this.$data.contact.group = val ? {id: val} : null;
      },
    }
  };
</script>
