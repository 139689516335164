var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"data-test-id":"csv-imported"}},[_c('div',{staticClass:"status-alerts mb-6"},[(_vm.items.find((x) => x.status === 'success'))?_c('w-alert',{attrs:{"level":"success","message":_vm.l.t(
				'app.csv-successes',
				'Successes: {}/{}',
				[_vm.items.filter((x) => x.status === 'success').length, _vm.items.length]
			),"closable":false,"data-test-id":"csv-imported-success-alert"}}):_vm._e(),(_vm.items.find((x) => x.status === 'failed'))?_c('w-alert',{attrs:{"level":"error","message":_vm.l.t(
				'app.csv-fails',
				'Failed: {}/{}',
				[_vm.items.filter((x) => x.status !== 'success').length, _vm.items.length]
			),"closable":false,"data-test-id":"csv-imported-error-alert"}}):_vm._e()],1),_c('LongTable',{attrs:{"items":_vm.items,"data-test-id":"csv-imported-table"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('th',[_vm._v(_vm._s(_vm.l.t('app.name', 'Name')))]),_c('th',[_vm._v(_vm._s(_vm.l.t('app.message', 'Message')))]),_c('th',{staticClass:"text-center"},[_vm._v(_vm._s(_vm.l.t('app.success', 'Success')))])]},proxy:true},{key:"body",fn:function(){return _vm._l((_vm.items),function(item){return _c('tr',[_c('td',[_vm._v(_vm._s(item.name))]),_c('td',[_vm._v(_vm._s(item.message))]),_c('td',{staticClass:"text-center",class:{'text-success': item.status === 'success', 'text-error': item.status !== 'success'}},[_vm._v(" "+_vm._s(item.status === 'success' ? '✓' : '✖')+" ")])])})},proxy:true}])}),_c('div',{staticClass:"bottom-buttons",attrs:{"data-test-id":"csv-imported-btns"}},[_vm._t("commands")],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }