<template>
    <div class="phone-numbers">
      <Submenu
        v-if="$route.name !== 'phone-numbers.visualizer'"
        :routes="{
          'phone-numbers.index': {
            title: l.t('app.phone-numbers', 'Phone numbers')
          },
          'phone-numbers.create': {
            title: l.t('app.phone-add', 'Add phone number')
          },
          'phone-numbers.delete-csv': {
            title: l.t('phone-numbers.delete-phone-numbers', 'Delete phone numbers')
          },
           'phone-numbers.visualizer': {
            title: l.t('phone-numbers.sisualizer', 'Visualizer')
          },
        }"
        :key="`${l.detect()}${$route.name}`"
      />
      <PhoneNumbersFragment
        v-if="$route.name === 'phone-numbers.index'"
        ref="indexPage"
        data-test-id="phone-numbers-index"
      />
      <PhoneNumbersCreateFragment
        v-if="$route.name === 'phone-numbers.create'"
        data-test-id="phone-numbers-create"
      />
      <PhoneNumbersDeleteCsvFragment
        v-if="$route.name === 'phone-numbers.delete-csv'"
        data-test-id="phone-numbers-delete-csv"
      />
      <Visualizer
        v-if="$route.name === 'phone-numbers.visualizer'"
        data-test-id="phone-numbers-visualizer"
      />
    </div>
</template>

<script>
  import l from '../../../libs/lang';
  import PhoneNumbersFragment from './index.vue';
  import Visualizer from '../visualizer/index.vue';
  import PhoneNumbersCreateFragment from './create.vue';
  import PhoneNumbersDeleteCsvFragment from './delete-csv.vue';
  import Submenu from '../../elements/Submenu.vue';

  export default {
    components: {
      Visualizer,
      Submenu,
      PhoneNumbersFragment,
      PhoneNumbersCreateFragment,
      PhoneNumbersDeleteCsvFragment,
    },
    data() {
      return {
        l,
        deleting_in_progress: false,
      };
    },
    beforeRouteLeave(to, from, next) {
        if (this.$refs.indexPage && this.$refs.indexPage.IsNumberSelected() && to.name === 'phone-numbers.show') {
          return null;
        }

        return next();
    },
  };
</script>
