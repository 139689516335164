import l from '../lang';

export default {
	'routes': {
		'create': 'media.create',
		'show': 'media.show',
		'index': 'media.index',
	},
	'delete-single': l.t('media.delete-single', 'Are you sure you want to delete this media?'),
	'delete-filtered': l.t('media.delete-filtered', 'Are you sure you want to delete all filtered media?'),
	'delete-all': l.t('media.delete-all', 'Are you sure you want to delete all media?'),
	'successfull-creation': l.t('media.successfully-created', 'Your media has been added'),
	'successfull-update': l.t('app.successful-update', 'Your changes have been saved'),
	'failed-creation': l.t('media.failed-creation', 'There was a problem creating your media'),
	'failed-update': l.t('app.update-error', 'An error occurred while attempting to save your changes. Please try again.'),
	'currently-in-use': l.t('media.this-media-is-currently-in-use', 'This media is currently in use'),
	'to-delete-used': l.t('media.to-delete-this-media-remove-it', 'To delete this media, remove it from your call handling rules first, then try again.'),
	'available_voices': [
		'Mads / Danish',
		'Naja / Danish',
		'Lotte / Dutch',
		'Ruben / Dutch',
		'Nicole / English (Australia)',
		'Russell / English (Australia)',
		'Amy / English (Great Britain)',
		'Brian / English (Great Britain)',
		'Emma / English (Great Britain)',
		'Raveena / English (India)',
		'Ivy / English (US)',
		'Joanna / English (US)',
		'Joey / English (US)',
		'Justin / English (US)',
		'Kendra / English (US)',
		'Kimberly / English (US)',
		'Salli / English (US)',
		'Geraint / English (Wales)',
		'Celine / French',
		'Mathieu / French',
		'Chantal / French (Canada)',
		'Hans / German',
		'Marlene / German',
		'Vicki / German',
		'Dora / Icelandic',
		'Karl / Icelandic',
		'Carla / Italian',
		'Giorgio / Italian',
		'Mizuki / Liv',
		'Liv / Norwegian',
		'Jacek / Polish',
		'Jan / Polish',
		'Ewa / Polish',
		'Maja / Polish',
		'Ricardo / Portuguese',
		'Vitoria / Portuguese',
		'Cristiano / Portuguese (Brazil)',
		'Ines / Portuguese (Brazil)',
		'Carmen / Romanian',
		'Maxim / Russian',
		'Tatyana / Russian',
		'Conchita / Spanish',
		'Enrique / Spanish',
		'Miguel / Spanish (Latin America)',
		'Penelope / Spanish (Latin America)',
		'Astrid / Swedish',
		'Filiz / Turkish',
		'Gwyneth / Welsh',
	  ],
};
