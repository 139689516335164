<template>
  <div class="text-editor-wrapper">
    <VueEditor
      v-model="content"
      :editor-toolbar="toolbar"
      v-bind="$attrs"
      v-on="$listeners"
      class="text-editor w-100"
      :class="{ 'has-error': error }"
    />
    <v-input v-model="content" v-bind="$attrs" v-on="$listeners" class="hidden-input" @update:error="(v) => error = v" hide-details="auto"/>
  </div>
</template>

<script>
import * as DOMPurify from 'dompurify';
import { VueEditor } from 'vue2-editor';
import EventBus from '../../../libs/EventBus';

export default {
  props: {
    value: {
      type: String,
      default: '',
    },
    toolbar: {
      // full options here: https://github.com/davidroyer/vue2-editor/blob/master/src/helpers/fullToolbar.js
      type: Array,
      default: (() => [
        ['bold'], // toggled buttons
        [{ size: ['small', false, 'large', 'huge'] }],
        [{ list: 'bullet' }],
        [{ color: [] }, { background: [] }], // dropdown with defaults from theme
        ['link'],
        ['clean'] // remove formatting button
      ]),
    },
  },
  components: { VueEditor },
  data() {
    return {
      original_value: this.$props.value,
      content: this.$props.value,
      error: false,
    };
  },
  watch: {
    content(val) {
      this.$emit('input', DOMPurify.sanitize(val));
      if (val !== this.$data.original_value) {
        EventBus.$emit('form_input_changed');
      }
    },
  }
};
</script>
<style lang="scss">
.text-editor-wrapper {
  .ql-container {
    height: inherit !important;
  }
  .hidden-input {
    .v-input__slot {
      display: none !important;
    }
    .v-messages.error--text {
      margin-top: 8px;
    }
  }
  .has-error {
    background: var(--v-action-darken2) !important;
  }
}
</style>
