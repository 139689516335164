<template>
  <div v-if="autocomplete" class="api-autocomplete-container">
    <w-loader v-if="autocomplete.loading" :message="false" :no_margins="true"/>
    <div v-else class="d-flex">
        <w-autocomplete
            v-model="val"
            v-bind="$attrs"
            ref="autocomplete"
            :items="items"
            :disabled="$attrs.disabed || autocomplete.loading || autocomplete.search_loading"
            :loading="autocomplete.search_loading"
            :search-input.sync="search"
            :placeholder="placeholder"
            @change="(v) => $emit('change', v)"
            class="api-autocomplete"
            hide-details="auto"
            data-test-id="autocomplete"
        >
            <template v-slot:no-data>
                <w-loader class="pa-3" v-if="autocomplete.search_loading" size="small" :message="false" />
                <div class="pa-3" v-else>{{l.t('app.no-items-available', 'No items available')}}</div>
            </template>
        </w-autocomplete>
        <slot></slot>
        <MoreOptions
            v-if="items.length && more_options.length > 1"
            :options="more_options"
            :activator_classes="'input-helper-btn'"
            data-test-id="api-autocomplete-actions"
        />
        <w-btn
            v-else-if="more_options.length === 1"
            :height="helper_button_size"
            :width="helper_button_size"
            :class="`${more_options[0].icon !== '$vuetify.icons.trash' ? 'action lighten-1' : ''}`"
            @click="more_options[0].cb()"
            class="input-helper-btn"
            icon
        >
          <v-icon :color="more_options[0].icon === '$vuetify.icons.trash' ? 'error' : 'text'" size="18">{{more_options[0].icon}}</v-icon>
        </w-btn>
        <Player
              v-if="val && autocomplete.mode.playable && !no_play && val !== 11"
              :just_player="true"
              :url="`${autocomplete.mode.playable}/${val}/download`"
              :key="val"
              :gray="true"
              :btn_size="32"
              :icon_size="18"
              class="my-auto"
              data-test-id="api-autocomplete-player"
        />
        <DefaultModal
            v-if="user_may_edit_resource"
            v-model="show_modal"
            :with_x="true"
            @close="show_modal = false"
            width="1000"
            :key="modal_key"
        >
        <div v-if="show_modal">
            <Media
                v-if="['hold_music', 'greetings', 'user_hold_music'].includes(mode)"
                :id="edit_id_val()"
                @updated="update_screen"
                :withPlayer="true"
                data-test-id="autocomplete-edit-media"
            />
            <Extension v-if="['extensions', 'virtualExtensions'].includes(mode)"
                :id="edit_id_val()"
                :ignore_routes="true"
                @updated="update_screen"
                data-test-id="autocomplete-edit-extension"
            />
            <Trunk
                v-if="mode === 'trunks'"
                :id="edit_id_val()"
                @updated="update_screen"
                data-test-id="autocomplete-edit-trunk"
            />
            <Queue
                v-if="mode === 'queues'"
                :id="edit_id_val()"
                @updated="update_screen"
                data-test-id="autocomplete-edit-queue"
            />
            <Schedule
                v-if="mode === 'schedules'"
                :id="edit_id_val()"
                @updated="update_screen"
                data-test-id="autocomplete-edit-schedule"
            />
            <Menu
                v-if="mode === 'menus'"
                :id="edit_id_val()"
                @updated="update_screen"
                :full_menu="true"
                data-test-id="autocomplete-edit-menu"
            />
            <Route
                v-if="['routes', 'presets'].includes(mode)"
                :id="edit_id_val()"
                :disable_switcher="true"
                @updated="update_screen"
                data-test-id="autocomplete-edit-route"
            />
            <PhoneNumber
                v-if="mode === 'phone_numbers'"
                :id="edit_id_val()"
                @submitted="update"
                data-test-id="autocomplete-edit-phone-num"
            />
            <LiveAnswer
                v-if="val === 'live_answer'"
              :_id="edit_id_val()"
              @updated="update_screen"
              data-test-id="autocomplete-edit-lr"
            />
            <Contact
                v-if="mode === 'contacts' && autocomplete.extension"
                :id="edit_id_val()"
                :extension_id="autocomplete.extension"
                @updated="update_screen"
                data-test-id="autocomplete-edit-contact"
            />
            <ContactGroup
                v-if="mode === 'groups' && autocomplete.extension"
                :id="edit_id_val()"
                :extension_id="autocomplete.extension"
                @updated="update_screen"
                data-test-id="autocomplete-edit-group"
            />
          </div>
        </DefaultModal>
    </div>
  </div>
</template>
<script>
import l from '../../../libs/lang';
import Helpers from '../../../libs/helpers';
import Autocomplete from '../../../models/Autocomplete';
import MoreOptions from '../MoreOptions.vue';
import DefaultModal from '../modal/DefaultModal.vue';
import Player from '../Player.vue';

export default {
    props: {
        value: {
            required: false,
        },
        mode: {
            requried: true,
        },
        value_prop: {
            type: String,
            default: 'id',
        },
        return_object: {
            type: Boolean,
            default: false,
        },
        extension: {
            type: Number,
        },
        closable: {
            type: Boolean,
            default: false,
        },
        no_update: {
            type: Boolean,
            default: false,
        },
        no_play: {
            type: Boolean,
            default: false,
        },
        before_list: {
            type: Array,
        },
        after_list: {
            type: Array,
        },
        additional_options: {
            type: Array,
        },
        filtered_values: {
            type: Array,
        }
    },
    components: {
        MoreOptions,
        DefaultModal,
        Player,
        Menu: () => import('../../screens/menus/show.vue'),
        Media: () => import('../../screens/media/show.vue'),
        Trunk: () => import('../../screens/trunks/show.vue'),
        Queue: () => import('../../screens/queues/show.vue'),
        Route: () => import('../../screens/routes/show.vue'),
        Contact: () => import('../../screens/contacts/show.vue'),
        Schedule: () => import('../../screens/schedules/show.vue'),
        ContactGroup: () => import('../../screens/groups/show.vue'),
        Extension: () => import('../../screens/extensions/show.vue'),
        LiveAnswer: () => import('../../screens/live-answer/show.vue'),
        PhoneNumber: () => import('../../fragments/PhoneNumberSettings.vue'),
    },
    data() {
      return {
        l,
        val: this.$props.value,
        items: [],
        search: '',
        autocomplete: null,
        show_modal: false,
        helper_button_size: 32,
        modal_key: Date.now(),
      };
    },
    async created() {
        if (this.$data.val && this.$props.return_object) {
            this.$data.val = this.$data.val[this.$props.value_prop];
        }
        this.$data.autocomplete = new Autocomplete(
            this.$props.mode,
            this.$session,
            this.$props.extension,
            {
                multiple: this.multiple,
                value: this.$data.val,
                reload: this.$props.reload,
                value_prop: this.$props.value_prop,
                return_object: this.$props.return_object,
            },
            {
                emit: Helpers.emitter(this),
            },
        );
        await this.$data.autocomplete.load_items();
        this.$data.items = this.$data.autocomplete.autocomplete_items;
        if (Array.isArray(this.$props.before_list)) {
            this.$data.items = this.$props.before_list.concat(this.$data.items);
        }
        if (Array.isArray(this.$props.after_list)) {
            this.$data.items = this.$data.items.concat(this.$props.after_list);
        }
        if (Array.isArray(this.$props.filtered_values) && this.$props.filtered_values.length) {
          this.$data.items = this.$data.items.filter((x) => !this.$props.filtered_values.includes(x.value));
        }
    },
    methods: {
        edit_id_val() {
            const value = this.multiple ? this.$data.val[0] : this.$data.val;

            return this.items.find((x) => x.value === value).id; // value_prop can be different so ID is not always the value
        },
        async update(data) {
            this.$data.autocomplete.loading = true;
            this.$data.show_modal = false;
            if (this.autocomplete.extension) this.autocomplete.mode.model.extension = this.autocomplete.extension;
            await this.autocomplete.mode.model.update(data);
            if (this.autocomplete.mode.model.alert) {
                this.$data.autocomplete.loading = false;
                this.alert = this.autocomplete.mode.model.alert;
                setTimeout(() => this.alert = null, 3000);
                if (this.alert.level === 'error') {
                    this.$data.autocomplete.loading = false;
                    return false;
                }
            }
            this.update_screen(data);
            return this.$data.autocomplete.loading = false;
        },
        update_screen(data) {
            if (data && this.$data.autocomplete) {
                const target_index = this.$data.autocomplete.response.items.findIndex((x) => x.id === data.id);
                if (target_index > -1) this.$data.autocomplete.response.items[target_index] = data;
                this.$data.autocomplete.mode.model.updateCache(data, this.autocomplete.mode_name);
            }
            setTimeout(() => {
                this.$data.show_modal = false;
            }, 1000);
            this.$data.items = this.$data.autocomplete.autocomplete_items;
        },
    },
    watch: {
        show_modal() {
            this.$data.modal_key = Date.now();
        },
        search(v) {
            if (v) {
                const texts = this.items.map((x) => x.text).filter((x) => x);
                if (!texts.find((x) => x.includes(v))) {
                    this.$data.autocomplete.search(v);
                }
            }
        },
        val(v) {
            if (this.$refs.autocomplete) {
                const el = this.$refs.autocomplete.$el.querySelector('input');
                if (el) el.blur();
            }
            if (this.$data.autocomplete && this.items.length) {
                let value;
                if (this.$props.before_list && this.$props.before_list.find((x) => x.value === v)) {
                    value = v;
                } else if (this.$props.after_list && this.$props.after_list.find((x) => x.value === v)) {
                    value = v;
                } else if (this.$props.return_object) {
                    value = this.autocomplete.response.items.find((x) => x[this.$props.value_prop] === v);
                    if (!value) {
                        value = {};
                        value[this.$props.value_prop] = v;
                    }
                } else {
                    value = v;
                }
                this.$emit('input', value);
            }
        }
    },
    computed: {
        multiple() {
            return !!Object.prototype.hasOwnProperty.call(this.$attrs, 'multiple');
        },
        user_may_edit_resource() {
            const may_edit = this.$data.val
                && !this.$props.no_update
                && (!this.multiple || (this.multiple && this.$data.val.length === 1))
                && !this.$attrs.disabled
                && this.$data.autocomplete.mode.edit_route
                && this.$branding.data.routes.includes(this.$data.autocomplete.mode.edit_route)
                && this.$data.autocomplete.mode.model;
            if (!may_edit) return false;
            if (['hold_music', 'greetings', 'user_hold_music'].includes(this.$data.autocomplete.mode_name)) {
                return this.$data.val && this.$data.val > 24;
            }
            return this.$data.val !== 'create';
        },
        more_options() {
            let options = [];
            if (this.user_may_edit_resource) {
                options.push({
                    title: l.t('app.edit', 'Edit'),
                    icon: '$vuetify.icons.edit',
                    cb: () => this.$data.show_modal = true,
                });
            }
            if (Array.isArray(this.$props.additional_options)) {
                options = [
                ...options,
                ...this.$props.additional_options
                ];
            }
            if (this.$props.closable) {
                options.push({
                    title: l.t('app.remove', 'Remove'),
                    icon: '$vuetify.icons.trash',
                    cb: () => this.$emit('closed'),
                });
            }
            return options;
        },
        placeholder() {
            if (
                !this.$attrs.label
                && (this.$attrs.placeholder || this.$data.autocomplete.mode.placeholder)
                && this.items.length
                && (!this.multiple || (this.multiple && !this.$data.val.length))
            ) {
                if (this.$attrs.placeholder && typeof this.$attrs.placeholder === 'string') return this.$attrs.placeholder;

                return this.$data.autocomplete.mode.placeholder;
            }

            return '';
        },
    },
  };
</script>
