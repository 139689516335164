<template>
  <DefaultModal v-model="show_splash" max-width="1280" @close="on_close">
        <iframe
            src="https://www.phone.com/splash-pages/reception-services/"
            title="Receptionist Services"
            width="100%"
            height="674px"
            frameborder="0"
        />
        <div class="splash-buttons">
            <w-btn
                v-if="!user_contacted"
                @click="on_contact_me"
                class="splash-button"
                color="text"
                data-test-id="splash-screen-contact-me-btn"
            >
                <v-icon size="18" color="white">$vuetify.icons.call</v-icon>
                <span class="w-subtitle-2 white--text ml-2">
                    {{l.t('app.contact-for-more-info', 'Contact me with more information')}}
                </span>
            </w-btn>
                <w-btn v-else class="splash-button" :ripple="false" color="action lighten-4" data-test-id="splash-screen-thank-you-btn">
                <span class="w-btn-large">
                    {{l.t('app.thanks-will-be-in-touch', 'Thank you! We will be in touch with you shortly.')}}
                </span>
            </w-btn>
        </div>
  </DefaultModal>
</template>
<script>
import l from '../../libs/lang';
import Helpers from '../../libs/helpers';
import CookieManager from '../../libs/cookie-manager';
import DefaultModal from './modal/DefaultModal.vue';

export default {
    components: { DefaultModal },
    data() {
        return {
            l,
            show_splash: false,
            cookie_name: `${this.$session.user.id}-hjFirstSeen`,
            user_contacted: false,
        };
    },
    async created() {
        // CookieManager.remove(this.$data.cookie_name, true, 365 * 10);
        this.$data.show_splash = !CookieManager.get(this.$data.cookie_name);
    },
    methods: {
        on_close() {
            this.hide_splash_for_the_user();
        },
        async on_contact_me() {
            try {
                const FORM_ID = 'fef49b4f-552d-4743-be09-d300496ce6da';
                await Helpers.submit_hubspot_form(this.$session.user.account, FORM_ID);
                this.$data.user_contacted = true;
            } catch (err) {
                this.$data.user_contacted = false;
            }
        },
        hide_splash_for_the_user() {
            CookieManager.set(this.$data.cookie_name, true, 365 * 10);
            this.$data.show_splash = false;
        }
    }
};
</script>

<style lang="scss">
.splash-buttons {
    display: flex;
    justify-content: center;
}
</style>
