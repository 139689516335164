<template>
  <div class="rule-action-action">
    <template v-if="action.action === 'forward'">
      <div class="w-subtitle-3 font-weight-600 mt-2 mb-4">
        {{ l.t('app.fwd-calls-to-phone-ext', 'Forward calls to the following phone numbers or extensions') }}
      </div>
      <ForwardingItem
        v-for="(item, k) in action.items"
        v-bind:key="`action-items-${k}-${action['items'].length}`"
        :default_type="false"
        :value="item"
        @changed="action['items'][k] = $event.value"
        :required="true"
        :closable="action.items.length > 1"
        :device_extension="extension && extension.device_membership ? extension : undefined"
        @closed="delete_fwd_item(rule_index, action_index, k)"
        data-test-id="route-conf-rule-fwd-item"
        class="w-100"
      />
      <w-btn
        @click="add_destination"
        text
        :ripple="false"
        color="primary"
        class="mt-3 pl-0 mb-6"
        data-test-id="route-conf-add-to-list"
      >
        <v-icon color="primary">mdi-plus-circle</v-icon>{{ l.t('app.add-another-destination', 'Add another destination') }}
      </w-btn>
      <RingingDuration
        name="ringingtime"
        v-model="action.timeout"
        :placeholder="l.t('forwarding.timeout', 'Ringing time')"
        update:search-input
        class="mb-2"
        hide-details="auto"
        data-test-id="`route-conf-rule-timeout-input`"
      />
      <APIAutocomplete
        v-model="action.hold_music"
        :return_object="true"
        :before_list="[{value: null, text: l.t('hold-music.default', 'Default ringtone')}]"
        mode="hold_music"
        :key="`hold_music-${action.hold_music ? action.hold_music.id : action_index}`"
        data-test-id="route-conf-rule-hold-music-select"
        :placeholder="l.t('app.hold-music', 'Hold music')"
      />
    </template>
    <ExtensionSelector
      v-else-if="['voicemail', 'fax'].includes(action.action)"
      :key="action.action"
      :rules="[$validation.required()]"
      :value="action.extension ? action.extension : null"
      :options_list="['notifications']"
      :select_if_one="null"
      :placeholder="true"
      :full_width="true"
      @changed="action.extension = {id: $event.id}"
      data-test-id="route-conf-rule-extension-selector"
    />
    <template v-else-if="action.action === 'hold'">
      <APIAutocomplete
        v-model="action.hold_music"
        :return_object="true"
        :before_list="[{value: null, text: l.t('hold-music.default', 'Default ringtone')}]"
        mode="hold_music"
        :key="`hold__music-${action.hold_music ? action.hold_music.id : action_index}`"
        data-test-id="route-conf-rule-default-ringtone-selector"
      />
      <RingingDuration
        v-model="action.duration"
        :placeholder="l.t('app.duration', 'Duration')"
        class="mt-3"
        :data-test-id="`route-conf-rule-${rule_index}-duration-input`"
      />
    </template>
    <GreetingsSelector
      v-else-if="action.action === 'greeting'"
      :value="action.greeting"
      @changed="on_selector_change('greeting', $event)"
      :required="true"
      data-test-id="route-conf-rule-greeting-selector"
    />
    <SelectorWithCreate
      v-else-if="action.action === 'menu'"
      :value="action.menu"
      :rules="[$validation.required()]"
      mode="menus"
      :key="`menus-${action.menu ? action.menu.id : `${action_index}-${!!action.menu}`}`"
      @input="on_selector_change('menu', $event)"
      data-test-id="route-conf-rule-menus-selector"
    />
    <SelectorWithCreate
      v-else-if="action.action === 'queue'"
      :value="action.queue"
      :rules="[$validation.required()]"
      mode="queues"
      :key="`queues-${action.queue ? action.queue.id : action_index}`"
      @input="on_selector_change('queue', $event)"
      data-test-id="route-conf-rule-queues-selector"
    />
    <SelectorWithCreate
      v-else-if="action.action === 'live_answer'"
      :value="action.script"
      mode="live_answer"
      :rules="[$validation.required()]"
      :key="`live-answer-${action.script ? action.script.id : `${action_index}-${!!action.script}`}`"
      @input="on_selector_change('script', $event)"
      data-test-id="route-conf-rule-lr-selector"
    />
    <APIAutocomplete
      v-else-if="action.action === 'trunk'"
      v-model="action.trunk"
      :return_object="true"
      :rules="[$validation.required()]"
      mode="trunks"
      :required="true"
      :key="`trunks-${action.trunk ? action.trunk.id : action_index}`"
      data-test-id="route-conf-rule-trunks-selector"
    />
    <span v-else></span>
  </div>
</template>

<script>
  import l from '../../../libs/lang';
  import ForwardingItem from '../ForwardingItem.vue';
  import DefaultModal from '../modal/DefaultModal.vue';
  import RingingDuration from '../form/RingingDuration.vue';
  import APIAutocomplete from '../form/APIAutocomplete.vue';
  import SelectorWithCreate from '../SelectorWithCreate.vue';
  import GreetingsSelector from '../../fragments/GreetingsSelector.vue';
  import ExtensionSelector from '../../fragments/ExtensionSelector.vue';

  export default {
    props: {
      action: {
        type: Object,
        required: true,
      },
      route: {
        type: Object,
        required: true,
      },
      action_index: {
        type: Number,
        required: true,
      },
      rule_index: {
        type: Number,
        required: true,
      },
      extension: {
        type: Object,
        required: false,
      }
    },
    components: {
      DefaultModal,
      ForwardingItem,
      APIAutocomplete,
      RingingDuration,
      ExtensionSelector,
      GreetingsSelector,
      SelectorWithCreate,
    },
    data() {
      return {
        l,
      };
    },
    methods: {
      on_selector_change(mode, data) {
        this.action[mode] = data;
        this.$forceUpdate();
      },
      add_destination() {
        this.$props.route.rules[this.$props.rule_index].actions[this.$props.action_index].items = this.$props.route.rules[this.$props.rule_index].actions[this.$props.action_index].items || [];
        this.$props.route.rules[this.$props.rule_index].actions[this.$props.action_index].items.push({ type: 'phone_number' });
        this.$forceUpdate();
      },
      delete_fwd_item(rule_index, action_index, item_index) {
        if (
          this.$props.route.rules[rule_index]
          && this.$props.route.rules[rule_index].actions
          && this.$props.route.rules[rule_index].actions[action_index]
          && this.$props.route.rules[rule_index].actions[action_index].items
          && this.$props.route.rules[rule_index].actions[action_index].items.length
        ) {
          this.$props.route.rules[this.$props.rule_index].actions[this.$props.action_index].items.splice(item_index, 1);
          this.$forceUpdate();
        }
      },
    },
  };
</script>
