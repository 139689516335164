<template>
    <w-loader v-if="extension.loading"/>
    <div v-else class="create-page" data-test-id="extensions-create-page">
        <w-alert
          v-if="extension.alert"
          :message="extension.alert.message"
          :level="extension.alert.level"
          :closable="extension.enable_close_alert"
          @closed="extension.alert = null"
          class="mb-6"
        />
        <ExtensionFragment
          v-if="extension.item"
          :_extension="extension.item"
          :generic_device_option="true"
          @updateExtension="extension.create($event)"
        />
    </div>
</template>

<script>
  import Helpers from '../../../libs/helpers';
  import Extension from '../../../models/Extension';
  import ExtensionFragment from '../../fragments/Extension.vue';

  export default {
    components: {
      ExtensionFragment,
    },
    data() {
      return {
        extension: new Extension(this.$session, Helpers.emitter(this), Helpers.changeRoute(this)),
      };
    },
    async created() {
      await this.$data.extension.prepareEmptyExtension();
    },
  };
</script>
