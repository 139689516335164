<template>
	<div>
		<SectionTitle data-test-id="media-loader-results-title">{{ l.t('media.loader.results-title', 'Update media results') }}</SectionTitle>
		<v-simple-table data-test-id="media-loader-results-table">
			<thead data-test-id="head">
				<tr data-test-id="head-row">
					<th data-test-id="acc-id">{{ l.t('app.account-id', 'Account ID') }}</th>
					<th data-test-id="acc-type">{{ l.t('app.account-type', 'Account type') }}</th>
					<th data-test-id="acc-name">{{ l.t('media.account-name', 'Account name') }}</th>
					<th data-test-id="media-id">{{ l.t('media.id', 'Media ID') }}</th>
					<th data-test-id="media-name">{{ l.t('media.name', 'Media name') }}</th>
					<th data-test-id="media-type">{{ l.t('media.type', 'Media type') }}</th>
					<th data-test-id="origin">{{ l.t('media.origin', 'Origin') }}</th>
					<th data-test-id="extenison">{{ l.t('app.extension', 'Extension') }}</th>
					<th data-test-id="status">{{ l.t('app.status', 'Status') }}</th>
					<th data-test-id="message">{{ l.t('app.message', 'Message') }}</th>
				</tr>
			</thead>
			<tbody data-test-id="table-body">
				<template v-for="(account, a) in media.accounts">
					<tr v-for="(item, i) in account.items" :data-test-id="item.id">
						<td data-test-id="acc-id">{{ account.id }}</td>
						<td data-test-id="acc-type">{{ account.main ? l.t('app.main-account', 'Main account') : l.t('app.subaccount', 'Subaccount') }}</td>
						<td data-test-id="acc-name">{{ account.name }}</td>
						<td data-test-id="item-id">
						    {{ item.id }}
						</td>
						<td data-test-id="item-name">
						    {{ item.name ? item.name : "&mdash;" }}
						</td>
						<td data-test-id="item-type">
						    {{ item.type === 'hold_music' ? l.t('app.music-on-hold', 'Music on hold') : l.t('app.greeting', 'Greeting'), }}
						</td>
						<td data-test-id="origin">
						    {{ item.origin === "file" ? l.t("app.file", "File") : l.t("media.tts", "Text to Speech") }}
						</td>
						<td data-test-id="extension">{{ item.extension ? `#${item.extension.extension}` : '&mdash;' }}</td>
						<td :data-test-id="`media-loader-results-${a}-${i}-item-status`">{{ item.status ? $options.filters.upperFirstCase(item.status.level) : "&mdash;"}}</td>
						<td :data-test-id="`media-loader-results-${a}-${i}-item-message`">{{ item.status ? item.status.message : "&mdash;"}}</td>
					</tr>
				</template>
			</tbody>
		</v-simple-table>
		<div class="d-flex justify-end mt-3">
			<w-btn @click="media.reset()" data-test-id="media-loader-results-import-another-btn">
				{{ l.t('media-loader.import-another', 'Import another') }}
			</w-btn>
			<w-btn @click="media.exportResultsReport()" color="primary" class="ml-3" data-test-id="media-loader-results-get-csv-btn">
				{{ l.t('app.get-csv', 'Get CSV') }}
			</w-btn>
		</div>
	</div>
</template>

<script>
	import l from '../../../libs/lang';
	import SectionTitle from '../../elements/SectionTitle.vue';

	export default {
		props: ['media'],
		components: { SectionTitle, },
		data() {
			return {
				l,
			};
		},
		filters: {
			upperFirstCase(val) {
				return val.charAt(0).toUpperCase() + val.slice(1).split('_').join(' ').split('-')
.join(' ');
			},
		},
	};
</script>
