<template>
    <w-alert
        :closable="false"
        level="warning"
        class="margin-top-30 margin-bottom-30"
        :message="l.t('lr.onvego-once-setup-is-complete', 'Once setup is complete, you will get calls from +1 (810) 627-7327. Add this number to your Phone.com and mobile device address books. Do not block this number.')"
    />
</template>
<script>
import l from '../../../../libs/lang';

export default {
    data() {
        return {
            l,
        };
    },
};
</script>
