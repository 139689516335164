<template>
    <w-autocomplete v-bind="$attrs" v-on="$listeners" :items="items" />
</template>
<script>
import l from '../../../libs/lang';
import media_setup from '../../../libs/resources-setups/media';

export default {
    data() {
        return {
            items: [],
      };
    },
    created() {
        const items = [];
        const voices = media_setup.available_voices;
        voices.sort((a, b) => a.split(' / ')[1].localeCompare(b.split(' / ')[1]));
        for (const voice of voices) {
            items.push({
                text: `${voice.split(' / ')[1]} - ${l.t('media.voice', 'Voice')}: ${voice.split(' / ')[0]}`,
                value: voice,
            });
        }
        this.items = items;
    },
};
</script>
