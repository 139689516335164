<template>
  <div class="lr-default-contact">
    <w-checkbox
        v-model="enable_default_contact"
        color="primary"
        class="mx-auto"
        :disabled="disabled"
    >
        <template v-slot:label>
            <span class="text--text">
                {{l.t('lr.designated-contact-for-unmatched-callers', 'Designated contact for unmatched callers')}}
            </span>
        </template>
    </w-checkbox>
    <div class="w-caption mt-3 text--text text--lighten-1">
        {{l.t('lr.some-callers-might-not-know-who-they-want-to-reach-or-their', 'Some callers might not know who they want to reach, or their')}}
        &nbsp;<span @click="show_explanation_modal = true" class="primary--text text--darken-1 cursor-pointer">{{l.t('lr.request-might-not-be-recognized', 'request might not be recognized')}}</span>.
        <br/>
        {{l.t('lr.select-whom-should-direct', 'Select the contact to whom we should direct these calls.')}}
    </div>
    <w-select
        v-model="default_contact"
        :items="items"
        :disabled="!enable_default_contact || disabled"
        class="default-contact-select mx-auto"
    />
    <DefaultModal v-model="show_explanation_modal" max-width="350" :with_x="false">
        <div class="w-caption">
            {{l.t('lr.i-dont-know', 'To filter out SPAM traffic but still connect your live callers, the Intelligent Answer Bot detects some common words and phrases that a caller might use if they don’t know for whom they should ask.  These are words like “representative, I don’t know, I need help,” and others.  When the system recognizes a human caller but can not match them with one of your contacts, it will treat the call as if it was matched with your designated contact. If there is no designated contact, calls will only go through if they are matched with one of your contacts. For this reason, we recommend that all Intelligent Answer Bot scripts include a designated contact for unmatched callers.')}}
        </div>
        <template v-slot:buttons>
            <w-btn @click="show_explanation_modal = false" color="primary">{{l.t('app.close', 'Close')}}</w-btn>
        </template>
    </DefaultModal>
  </div>
</template>
<script>
  import l from '../../../libs/lang';
  import DefaultModal from '../../elements/modal/DefaultModal.vue';

  export default {
    // it can work only with indexes because, on update as on create,
    // user can add a contact that has no id yet
    props: {
        contacts: {
            type: Array,
            required: true,
        },
        enable_value: {
            type: Boolean,
            required: true,
        },
        value: {
            type: Number,
            required: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    components: {
        DefaultModal,
    },
    data() {
      return {
        l,
        default_contact: null,
        enable_default_contact: true,
        show_explanation_modal: false,
      };
    },
    created() {
        this.$data.enable_default_contact = this.$props.enable_value || this.$props.enable_value === 0;
        if (this.$props.value || this.$props.value === 0) {
            this.$data.default_contact = this.$props.value;
        } else {
            const {items} = this;
            this.$data.default_contact = items.length ? items[0].value : null;
        }
    },
    computed: {
        items() {
            const items = [];
            for (let i = 0; i < this.$props.contacts.length; i++) {
                const c = this.$props.contacts[i];
                if (c.first_name && c.last_name && Array.isArray(c.destinations) && c.destinations.length) {
                    items.push({
                        text: `${c.first_name} ${c.last_name}`,
                        value: i,
                    });
                }
            }

            return items;
        },
    },
    watch: {
        enable_default_contact(v) {
            if (v) {
                this.$emit('input', this.$data.default_contact);
            } else {
                this.$emit('input', null);
            }
        },
        default_contact(v) {
            if (this.$data.enable_default_contact) {
                this.$emit('input', v);
            }
        },
        contacts: {
            deep: true,
            handler() {
                const {items} = this;
                if (
                    items.length
                    && !this.$data.default_contact
                    && this.$data.default_contact !== 0
                ) {
                    this.$data.default_contact = items[0].value;
                }
            },
        }
    }
  };
</script>
<style scoped lang="scss">
    .lr-default-contact {
        text-align: center;
        .default-contact-select {
            margin-top: 15px;
            width: 300px;
        }
    }
</style>
