<template>
<v-dialog @keydown.esc="on_close" @click:outside="on_close" v-bind="$attrs" v-on="$listeners" data-test-id="default-modal">
  <div class="default-modal-content">
    <div v-if="$slots['title']" class="default-modal-title">
      <slot name="title" class="dialog-title"/>
    </div>
    <v-icon v-if="with_x" @click="on_close" class="close-default-modal-x" size="20" color="secondary"
      data-test-id="close-default-modal-x">
      $vuetify.icons.close
    </v-icon>
    <div class="w-body-2"><slot /></div>
    <div v-if="$slots['buttons']" class="default-modal-buttons">
      <slot name="buttons" class="dialog-buttons"/>
    </div>
  </div>
  <template slot="activator" slot-scope="scope">
    <slot name="activator" v-bind="scope" />
  </template>
</v-dialog>
</template>
<script>
  export default {
    props: {
      with_x: {
        type: Boolean,
        default: true,
      }
    },
    data() {
      return {
      };
    },
    methods: {
      on_close() {
        this.$emit('input', false);
        this.$emit('close');
      }
    }
  };
</script>
