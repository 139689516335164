<template>
    <div class="routes">
      <Submenu
        :routes="{
          'routes.index': {
            title: l.t('app.presets', 'Presets'),
            subroutes: ['routes.show']
            },
          'routes.create': {
            title: l.t('routes.add-preset', 'Add preset')
            },
        }"
        :key="`${l.detect()}${$route.name}`"
      />
      <RoutesFragment
        v-if="$route.name === 'routes.index'"
        @deletingStarted="deleting_in_progress = true"
        @deletingEnded="deleting_in_progress = false"
        data-test-id="routes-index"
      />
      <RoutesCreateFragment
        v-if="$route.name === 'routes.create'"
        data-test-id="routes-create"
      />
      <RoutesShowFragment
        v-if="$route.name === 'routes.show'"
        data-test-id="routes-show"
      />
    </div>
</template>

<script>
  import l from '../../../libs/lang';
  import RoutesFragment from './index.vue';
  import RoutesShowFragment from './show.vue';
  import RoutesCreateFragment from './create.vue';
  import Submenu from '../../elements/Submenu.vue';

  export default {
    components: {
      Submenu,
      RoutesFragment,
      RoutesCreateFragment,
      RoutesShowFragment,
    },
    data() {
      return {
        l,
        deleting_in_progress: false,
      };
    },
    beforeRouteLeave(to, from, next) {
      if (window.deleting_in_progress) {
        this.$emit('userRedirected', to, from, next);
      } else {
        next();
      }
    },
  };
</script>
