<template>
    <DefaultModal v-if="show_route_conf" v-bind="$attrs" v-on="$listeners" @input="on_input"  max-width="880">
        <Route
            v-if="resource.route_using_resource"
            :id="resource.route_using_resource"
            :disable_switcher="true"
            @updated="update_screen"
            @cancel="on_input(false)"
            :key="edit_modal_key"
            data-test-id="in-use-warning-edit-route"
        />
    </DefaultModal>
    <DefaultModal v-else v-bind="$attrs" v-on="$listeners" @input="on_input" max-width="400">
        <template v-slot:title>
            <w-btn fab width="24" height="24" :ripple="false" color="warning" class="mr-1">
                <v-icon color="white">$vuetify.icons.exclamation</v-icon>
            </w-btn>
            {{ resource.setup['currently-in-use'] }}
        </template>
        <div class="w-body">
            {{resource.setup['to-delete-used']}}
        </div>
        <template v-slot:buttons>
            <w-btn @click="on_input(false)" color="secondary">{{l.t('app.cancel', 'Cancel')}}</w-btn>
            <w-btn @click="show_route_conf = true" color="primary">{{l.t('app.edit-call-handling-rules', 'Edit call handing rules')}}</w-btn>
        </template>
    </DefaultModal>
</template>
<script>
    import l from '../../../libs/lang';
    import DefaultModal from './DefaultModal.vue';
    import Route from '../../screens/routes/show.vue';

  export default {
    props: {
        resource: {
            required: true,
            type: Object,
            validator: (v) => !!v.setup,
        }
    },
    components: {
        Route,
        DefaultModal,
    },
    data() {
      return {
        l,
        show_route_conf: false,
        edit_modal_key: Date.now(),
      };
    },
    methods: {
        on_input(v) {
            if (!v) {
                this.$data.show_route_conf = false;
                this.$data.edit_modal_key = Date.now();
            }
            this.$emit('input', v);
        },
        update_screen(data) {
            if (data) {
                this.$props.resource.updateCache(data);
            }
            setTimeout(() => {
                this.$emit('input', false);
                this.$data.show_route_conf = false;
                this.$data.edit_modal_key = Date.now();
            }, 1000);
        },
    }
  };
</script>
