/* eslint-disable class-methods-use-this */
import Resource from './Resource';
import l from '../libs/lang';
import orders_setup from '../libs/resources-setups/live-receptionist';
import Helpers from '../libs/helpers';

class Order extends Resource {
    constructor(session, emitter, changeRoute) {
        super(session, emitter, '/orders', changeRoute);
        this.filters = {
        };
        this.setup = orders_setup;
        this.item = null;
        this.devices_types_translations = {
            'desk': l.t('orders.desk-phones', 'Desk phones'),
            'cordless': l.t('orders.cordless-phones', 'Cordless phones'),
            'ata': l.t('orders.atas', 'Analog Telephone Adapters (ATAs)'),
            'conference': l.t('orders.conference-phones', 'Conference phones'),
        };
        this.devices_types = [];
        this.shipping_methods = null;
        this.status_translations = {
          new: l.t('app.new', 'New'),
        };
    }

    async loadShippingMethods() {
        try {
            const methods = await this.session.get_list_all('/shipping-price-list');
            this.shipping_methods = {};
            methods.items.map((x) => {
                this.shipping_methods[x.value] = x;
                return x;
            });
        } catch (err) {
            this.shipping_methods = null;
        }
    }

    async create(data) {
        this.loading = true;
        try {
            this.item = await this.session.create_item(this.uri, data);
            // this.successfulCreation('devices.my-orders');
        } catch (err) {
            this.validation_error(err);
        }
        this.loading = false;
        return this.item;
    }

    async sync_devices_with_setup(dvcs, order) {
      const devices = JSON.parse(JSON.stringify(dvcs));
      this.loading = true;
      const ordered_devices = order.devices;
      const results = [];
      for (const device of ordered_devices) {
        const setup_index = devices.findIndex((x) => x.code === device.code && (x._custom_setup.lines.length || x._custom_setup.address));
        if (setup_index > -1) {
          const setup = devices[setup_index]._custom_setup;
          devices.splice(setup_index, 1);
          try {
            await this.session.patch_item(`/devices/${device.id}`, setup);
            results.push(this.generate_result_object(device, true, setup));
          } catch (err) {
            results.push(this.generate_result_object(device, false, setup, err));
          }
        } else {
          results.push(this.generate_result_object(device, true));
        }
      }
      this.loading = false;
      return results;
    }

    generate_create_order_alert(items) {
      const success = items.filter((x) => x.success);
      const errors = items.filter((x) => !x.success);
      const order_created = l.t('orders.order-created', 'Your order is created.');

      if (success.length === items.length) {
        let message;
        if (success.length === 1) {
          message = `${order_created} ${l.t('orders.device-is-set-up', 'Device is set up.')}`;
        } else {
          message = `${order_created} ${l.t('orders.all-devices-set-up', 'All devices are set up.')}`;
        }
        return {
          level: 'success',
          message
        };
      }

      if (errors.length === items.length) {
        let message;
        if (errors.length === 1) {
          message = `${order_created} ${l.t(
            'orders.device-not-configured',
            'We encountered problems configuring your device.'
          )}`;
        } else {
          message = `${order_created} ${l.t(
            'orders.devices-not-configured',
            'We encountered problems configuring your devices.'
          )}`;
        }
        return {
          level: 'warning',
          message,
        };
      }

      return {
        level: 'warning',
        message: `${order_created} ${l.t(
          'orders.final-results',
          'Devices configured successfully: {}. Devices with an error while configuring: {}',
          [success.length, errors.length]
        )}`,
      };
    }

    generate_result_object(device, success, setup, err) {
      const result = {
        id: device.id,
        success,
      };
      if (setup) {
        Object.assign(result, setup);
      }
      if (err) {
        result.error_message = Resource.find_validation_message(err) || l.t('order.error-while-updating-device-setup', 'Error while updating device setup');
      }
      return result;
    }

    formatShippingMethod(val) {
      if (!val) return '—';
      if (!this.shipping_methods) return val;

      if (this.shipping_methods[val]) {
        return this.shipping_methods[val].name || val;
      }

      return val;
    }

    async loadItems(params) {
        this.loading = true;
        try {
            const offset = this.checkIfUserDeletedItems(params.offset, params.page);
            const items = await this.session.get_list(this.uri, params.limit, offset);

            this.page = params.page;
            if (this.page > 1 && !items.items.length) {
                this.emitter('pageChanged', 1);
                return this.loadItems({
                    limit: this.limit,
                    offset: 0,
                    page: 1,
                });
            }
            this.items = items.items;
            this.finalizeLoadingItems(items);
            this.emitter('itemsLoaded', JSON.parse(JSON.stringify(items)));
        } catch (err) {
            this.validation_error(err);
        }
        this.loading = false;

        return this.items;
    }

    async loadDevicesPriceList() {
        // this.loading = true;
        try {
            const items = await this.session.get_list('/devices-price-list', 500);
            this.devices_types = [];
            items.items = items.items.map((x) => {
                if (!this.devices_types.find((type) => type.value === x.type)) {
                    this.devices_types.push({
                        value: x.type,
                        text: this.devices_types_translations[x.type] || x.type,
                    });
                }
                x.device_type_translation = this.devices_types_translations[x.type] || x.type;
                return x;
            });
            this.devices = items.items;
        } catch (err) {
            this.validation_error(err);
        }
        // this.loading = false;

        return this.devices;
    }

    transform_cart_array_to_object(cart_array) {
      const cart = {};
      for (const item of cart_array) {
        if (!cart[item.id]) {
          cart[item.id] = {
            quantity: 1,
            device: item,
          };
        } else {
          cart[item.id].quantity++;
        }
      }

      return cart;
    }

    static stringifyAddress(data) {
      return Helpers.stringifyAddress(data);
    }
}

export default Order;
