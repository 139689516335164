/* eslint prefer-destructuring : "off" */
import l from '../libs/lang';
import Helpers from '../libs/helpers';
import PDFExporter from './PDFExporter';
import SmartFilters from './SmartFilters';
import Summary from './reports/Summary';
import GeoDistribution from './reports/GeoDistribution';
import PerExtension from './reports/PerExtension';
import PerPhoneNumber from './reports/PerPhoneNumber';
import Total from './reports/Total';
import Traffic from './reports/Traffic';
import Type from './reports/Type';

export default class CallReport extends SmartFilters {
	constructor(session, emitter, changeRoute, category, forceUpdate) {
		super(session, emitter, '/call-reports', changeRoute);
        this.allow_any_time_filter = false;
		this.formatters = {
			price(x) {
				if (!x || x === Infinity || x === -Infinity) return 0;

				return x;
			},
			duration(x) {
				return Helpers.format_duration(x);
			},
			time(x) {
				return Helpers.format_time(x);
			},
			number: (x) => x,
		};
		this._api_items = null;
		this.category = category;
		this.category_conf = this.select_proper_category_conf();
		this.forceUpdate = forceUpdate;
		this.reports_filters_cache_key = 'call-reports-filters';
		this.exclude_hour_filter = ['prev_month', 'q1', 'q2', 'q3', 'q4', 'january', 'february', 'march', 'april', 'may', 'june', 'july', 'august', 'september', 'october', 'november', 'december'];
		this.items = [];
		this.config_key = 'call-reports-filters';
		this.filters = this.buildDefaultFilters();
		this.empty_filters = JSON.parse(JSON.stringify(this.filters));
	}

	set api_items(val) {
		this._api_items = val;
		this.category_conf.api_items = val;
	}

	get api_items() {
		return this._api_items;
	}

	get filters() {
	  return super.filters;
	}

	set filters(val) {
	  super.filters = val;
	  this.category_conf.filters = val;
	}

	select_proper_category_conf() {
		switch (this.category) {
			case 'summary':
				return new Summary(this.formatters);
			case 'types':
				return new Type();
			case 'traffic':
				return new Traffic(this.filters);
			case 'geo-distribution':
				return new GeoDistribution(this.api_items);
			case 'total':
				return new Total(this.filters, this.session);
			case 'per-extension':
				return new PerExtension(this.filters, this.session, this.formatters);
			case 'per-number':
				return new PerPhoneNumber(this.formatters);
			default:
				throw new Error('Unsupported report type.');
		}
	}

	async loadItems() {
		this.loading = true;
		try {
			const url = this.buildUrlWithFilters();
			const items = await this.session.get_list_all(url);
			this.filters_applied = true;
			this.api_items = JSON.parse(JSON.stringify(items.items));
			this.items = items.items.length
				? await this.category_conf.prepare_items(items.items)
				: null;
		} catch (err) {
			this.validation_error(err);
		}
		this.loading = false;
	}

	buildDefaultFilters() {
		const filters = {
			group_by: this.category_conf.group_by_param,
			show_all_extensions: false,
			show_deleted_extensions:
				this.category === 'per-extension' ? false : undefined,
		};
		const {
			start, end, type, group_by
		} = this.dissabmleFilters();
		filters.end = end;
		filters.start = start;
		filters.type = type;
		filters.direction = null;
		if (group_by) filters.group_by = group_by;
		return filters;
	}

	dissabmleFilters() {
		this.loading = true;
		let start;
		let end;
		const cache = this.cachier.getItem(this.reports_filters_cache_key);
		let filters = cache || null;
		let ignore_offset = false;
		if (filters && filters.type) {
			if (filters.type === 'custom') {
				start = new Date(parseInt(filters.start) * 1000);
				end = new Date(parseInt(filters.end) * 1000);
				ignore_offset = true;
			} else {
				const time = this.quick_filters.find((x) => x.value === filters.type).time();
				start = time.start;
				end = time.end;
			}
			// the case where we have traffic report and more than month period, filter's type has to be date, types must not be cached
			if (this.category === 'traffic' && this.exclude_hour_filter.includes(filters.type)) filters.group_by = 'date';
		} else {
			filters = this.generateStartAndEndTime(true);
			start = filters.start;
			end = filters.end;
			filters.type = 'today';
		}
		filters = { ...this.display_time(filters, start, end, ignore_offset) };
		return filters;
	}

	generateStartAndEndTime(today = false) {
		let type;
		if (today) {
			type = 'today';
		} else if (this.saved_filter_name) {
			type = 'custom';
		} else {
			type = this.filters && this.filters.type ? this.filters.type : 'today';
		}
		const filter = this.quick_filters.find((x) => x.value === type);
		return filter.time();
	}

	apply_type_range() {
		if (this.category === 'traffic' && this.exclude_hour_filter.includes(this.filters.type)) {
			this.filters.group_by = 'date';
		}
		const {start, end} = (this.quick_filters.find((x) => x.value === this.filters.type)).time();
		const filters = { ...this.display_time(this.filters, start, end) };
		this.filters.start = filters.start;
		this.filters.end = filters.end;
		if (this.forceUpdate) this.forceUpdate();
	}

	formatValue(type) {
		return this.formatters[type];
	}

	apply_saved_filters() {
	    if (!this.saved_filter_name) {
	        return this.filters = this.empty_filters;
	    }
	    const filter = this.client_config[this.config_key].find((x) => x.name === this.saved_filter_name);
	    this.filters = JSON.parse(JSON.stringify(filter));
	    if (this.filters && this.filters.group_by) {
			switch (this.category) {
				case 'traffic':
					if (!['hour', 'date', 'week'].includes(this.filters.group_by)) this.filters.group_by = this.category_conf.group_by_param;
					break;
				case 'total':
					if (!['minute', 'hour', 'date', 'week', 'extension', 'direction'].includes(this.filters.group_by)) {
						this.filters.group_by = this.category_conf.group_by_param;
					}
					break;
				default:
					this.filters.group_by = this.category_conf.group_by_param;
			}
	    } else {
			this.filters.group_by = this.category_conf.group_by_param;
	    }
	    if (this.forceUpdate) this.forceUpdate();
	    return true;
	}

	buildUrlWithFilters() {
		const filters = this.generateStartAndEndTime();
		let { start, end } = filters;
		start = Math.floor(new Date(start).getTime() / 1000);
		end = Math.floor(new Date(end).getTime() / 1000);
		if (this.filters.type === 'today') {
			this.cachier.removeItem(this.reports_filters_cache_key);
		} else {
			const stored_filters = {
				start,
				end,
				type: this.filters.type,
			};
			this.cachier.setItem(this.reports_filters_cache_key, stored_filters);
		}
		let url = `/call-reports?filters[created_at]=between:${start},${end}`;
		if (this.filters.group_by) {
			url += `&group_by=${this.filters.group_by}`;
		}
		if (this.filters.direction && this.category === 'geo-distribution') { // only available on geo-reports
			url += `&filters[direction]=${this.filters.direction}`;
		}
		url += `&fields=${Object.keys(this.category_conf.fields).join(',')}`;
		const timezone_offset = new Date().getTimezoneOffset() * 60;
		if (timezone_offset) url = `${url}&tz_offset=${timezone_offset}`;

		return url;
	}

	clear_filters() {
        this.keep_filters_open = true;
		this.saved_filter_name = null;
		const {start, end} = (this.quick_filters.find((x) => x.value === 'today')).time();
		const filters = { ...this.display_time(this.filters, start, end) };
		this.filters = {
			type: 'today',
			start: filters.start,
			end: filters.end
		};
		this.loadItems();
	}

	exportCsv() {
		try {
			const filename = this.generateFileName();
			const items = typeof this.category_conf.prepare_csv_values === 'function' ? this.category_conf.prepare_csv_values(this.items) : JSON.parse(JSON.stringify(this.items));
			const csv = super.build_csv(items, {}, this.category_conf.headers);
			CallReport.download_csv(csv, `${filename}.csv`);
            this.csv_downloaded_successfully();
		} catch (err) {
			console.log(err);
			this.alert = {
				message: l.t(
					'reports.error-exporting-csv',
					'An error occurred exporting .csv file.'
				),
				level: 'error',
			};
			this.hide_alert(5);
		}
	}

	generateFileName() {
		const title = `${this.category_conf.title}  ${l.t('grouped-by', 'grouped by')} ${this.filters.group_by}`;
		if (!['custom', 'today'].includes(this.filters.type)) {
			let type = this.quick_filters.find((x) => x.value === this.filters.type);
			if (type) {
				type = type['translation'];
				if (!type.match(/(\(|\))/)) type = type.toLowerCase(); // has no '(year)' => last week, previous month
				return `${title} ${l.t('app.for', 'for')} ${type}`;
			}
		}
		return `${title} ${l.t('app.from-lowercase', 'from')} ${this.filters.start} ${l.t('app.to-lowercase', 'to')} ${this.filters.end}`;
	}

	async exportPdf() {
		try {
			const filename = this.generateFileName();
			const exporter = new PDFExporter(
				'pdf-export',
				filename,
				filename,
				this.session
			);
			await exporter.printWindowAsPDF();
		} catch (err) {
			this.alert = {
				message: err,
				level: 'error',
			};
			this.hide_alert(3);
			window.scrollTo(0, 0);
			return false;
		}

		return true;
	}
}
