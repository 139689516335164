<template>
  <w-loader v-if="schedule.loading"/>
  <div v-else class="show-page" data-test-id="video-user-schedules-show-page">
      <w-alert
        v-if="schedule.alert"
        :message="schedule.alert.message"
        :level="schedule.alert.level"
        :closable="schedule.enable_close_alert"
        @closed="schedule.alert = null"
        class="mb-6"
      />
      <UserScheduleFragment
        v-if="schedule.item"
        :user="schedule.user"
        :_schedule="schedule.item"
        :extension="extension"
        @submitted="schedule.update($event)"
      />
  </div>
</template>

<script>
  import l from '../../../../../libs/lang';
  import Helpers from '../../../../../libs/helpers';
  import UserSchedule from '../../../../../models/UserSchedule';
  import UserScheduleFragment from '../../../../fragments/UserSchedule.vue';

  export default {
    props: ['user', 'extension'],
    components: {
      UserScheduleFragment,
    },
    data() {
      return {
        l,
        schedule: new UserSchedule(this.$props.user, this.$session, Helpers.emitter(this), Helpers.changeRoute(this)),
      };
    },
    async created() {
      await this.$data.schedule.getItem(this.$route.params.id);
    },
  };
</script>
