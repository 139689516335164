import l from '../lang';

const generate_error_message = (default_message, message) => {
    if (message) return message;
    return default_message;
};
export default {
    all_calls_required: (route, message) => () => {
        if (route.rules && !route.rules.find((x) => !x.filter)) {
            return generate_error_message(
                l.t('routes.all-call-is-required', 'To insure all calls are received properly, a rule for \'All calls\' has been added as your last call handling rule. This helps account for possible incoming call types that may have been overlooked. You can now save your changes.'),
                message,
            );
        }

        return true;
    },
};
