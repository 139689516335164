<template>
  <w-loader v-if="menu.loading"/>
  <div v-else class="show-page mt-3" data-test-id="menus-show-page">
    <PageTitle class="nowrap">
      {{title()}}
    </PageTitle>
      <w-alert
        v-if="menu.alert"
        :level="menu.alert.level"
        :message="menu.alert.message"
        :closable="menu.enable_close_alert"
        @closed="menu.alert = null"
        class="mb-6"
      />
      <MenuFragment v-if="menu.item && displayForm" :_menu="menu.item" @saved="updated" @cancel="$emit('cancel')" :full_menu="true"/>
  </div>
</template>

<script>
  import l from '../../../libs/lang';
  import Menu from '../../../models/Menu';
  import Helpers from '../../../libs/helpers';
  import MenuFragment from '../../fragments/Menu.vue';
  import PageTitle from '../../elements/PageTitle.vue';

  export default {
    props: ['id', '_menu'],
    components: {
      MenuFragment,
      PageTitle,
    },
    data() {
      return {
        l,
        menu: new Menu(this.$session, Helpers.emitter(this), Helpers.changeRoute(this)),
        displayForm: true,
        last_title: '',
      };
    },
    async created() {
      if (this.$props._menu || this.$route.params._menu) {
        this.$data.menu.item = this.$props._menu || this.$route.params._menu;
      } else {
        await this.$data.menu.getItem(this.$props.id ? this.$props.id : this.$route.params.id);
      }
    },
    methods: {
      title() {
        let title = '';
        if (this.$data.menu.item) {
          title += l.t('app.menu', 'Menu:');
          title += ` ${this.$data.menu.item.name ? this.$data.menu.item.name : ''}`;
          this.$data.last_title = title;
        } else {
          title = this.$data.last_title;
        }
        return title;
      },
      updated() {
        this.$data.menu.item = null;
        this.$data.menu.successfulUpdate('menus.index');
      },
    },
  };
</script>
