var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"faxes"},[_c('Submenu',{key:`${_vm.l.detect()}${_vm.$route.name}`,attrs:{"routes":{
      'fax.index': {
        title: _vm.l.t('fax.inbox', 'Fax inbox')
      },
      'fax.create': {
        title: _vm.l.t('fax.send', 'Send fax')
      }
    }}}),(_vm.$route.name === 'fax.index')?_c('FaxesFragment',{attrs:{"data-test-id":"faxes-index"},on:{"deletingStarted":function($event){_vm.deleting_in_progress = true},"deletingEnded":function($event){_vm.deleting_in_progress = false}}}):_vm._e(),(_vm.$route.name === 'fax.create')?_c('FaxesCreateFragment',{attrs:{"data-test-id":"faxes-create"}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }