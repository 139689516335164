<template>
    <div id="pdf-export">
        <v-simple-table data-test-id="summary-table">
            <tbody data-test-id="table-body">
            <template v-for="(item, i) in items">
                <tr
                    v-for="key in Object.keys(item)"
                    v-if="!excluded_fields.includes(key)"
                    :data-test-id="`summary-item-${i}-row`"
                >
                    <th style="width:100%" :data-test-id="key">
                        {{ call.category_conf.fields[key].translation }}
                    </th>
                    <td class="value" :data-test-id="`${key}-value`">{{ formatValue(item, key) }}</td>
                </tr>
            </template>
            </tbody>
        </v-simple-table>
    </div>
</template>

<script>
  import l from '../../../../libs/lang';

  export default {
    props: ['call'],
    data() {
      return {
        l,
        excluded_fields: ['date', 'extension'],
        items: [],
      };
    },
    created() {
        this.$data.items = [...this.$props.call.items];
        if (this.$data.items && this.$data.items.length) {
            // eslint-disable-next-line array-callback-return
            Object.keys(this.$data.items[0]).map((x) => {
                 if (!['min_call_duration', 'min_call_duration', 'total_call_duration'].includes(x)) {
                     if (!this.$data.items[0][x]) delete this.$data.items[0][x];
                 }
            });
        }
    },
    methods: {
        formatValue(item, key) {
            if (!item[key] && item[key] !== 0) return '—';
            if (['total', 'count_incoming', 'count_outgoing'].includes(key)) {
                return `${item[key]} ${item[key] === 1 ? l.t('app.call', 'call') : l.t('app.calls-lowercase', 'calls')}`;
            }
            if (['min_amount', 'avg_amount', 'max_amount', 'total_amount'].includes(key)) {
                return `$${(item[key] / 100).toFixed(2)}`;
            }

            return item[key];
        },
    }
  };
</script>

<style scoped>
    .value {
        white-space: nowrap;
        text-align: right;
    }
</style>
