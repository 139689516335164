<template>
	<div class="create-page" data-test-id="menus-create-page">
		<FormInput v-if="!one_extension" :label="l.t('app.extension', 'Extension')">
			<ExtensionSelector
				:select_if_one="true"
				@changed="extension = $event.value"
				@only_one_auto_selected="one_extension = true"
				data-test-id="message-extension-selector"
			/>
		</FormInput>
		<w-alert v-if="alert" :level="alert.level" :message="alert.message" class="my-6"/>
		<div v-if="!alert || (alert && alert.level !== 'success')" class="mt-3">
			<SendMessage
				v-if="extension"
				:key="messageSent"
				:extension_id="extension['id']"
				:from="extension['caller_id'] && extension['caller_id'] !== 'private' ? extension['caller_id'] : null"
				@sent="sent"
				data-test-id="message-send-mess"
			/>
		</div>
		<div v-else>
			<w-btn @click="sendMore" color="primary" data-test-id="message-send-another-btn">
				{{ l.t('messages.send-another', 'Send another one?') }}
			</w-btn>
		</div>
	</div>
</template>

<script>
import l from '../../../libs/lang';
import SendMessage from '../../fragments/SendMessage.vue';
import ExtensionSelector from '../../fragments/ExtensionSelector.vue';
import FormInput from '../../elements/form/FormInput.vue';

export default {
	components: {
		FormInput,
		SendMessage,
		ExtensionSelector,
	},
	data() {
		return {
			l,
			alert: null,
			extension: null,
			one_extension: null,
			messageSent: false,
		};
	},
	methods: {
		sent() {
			this.$data.alert = {
				level: 'success',
				message: l.t('messages.successfully-sent', 'Successfully sent'),
			};
			this.$data.messageSent = true;
		},
		sendMore() {
			this.$data.alert = null;
			this.$data.messageSent = false;
		},
	},
};
</script>
