<template>
  <IndexPage
    v-if="trunk.can_visit"
    :resource="trunk"
    :disable_delete_all="true"
    class="trunks list-page"
    data-test-id="trunks-list-page"
  >
    <template v-slot:filters>
      <FormInput :label="l.t('app.name', 'Name')">
        <w-text-field v-model="trunk.filters.name" hide-details='auto' data-test-id="trunks-filters-name-input"/>
      </FormInput>
    </template>
    <template v-slot:list>
      <v-simple-table class="list-table"  data-test-id="trunks-table">
        <thead data-test-id="table-head">
          <tr data-test-id="head-row">
            <th data-test-id="checkbox"></th>
            <th data-test-id="id">{{l.t('app.ID', 'ID')}}</th>
            <th data-test-id="name">{{l.t('app.name', 'Name')}}</th>
            <th data-test-id="uri">{{l.t('app.uri', 'URI')}}</th>
          </tr>
        </thead>
        <tbody data-test-id="table-body">
          <tr
            v-for="(item, i) in trunk.items"
            :key="item['id']"
            :class="{'selected-row': item.selected}"
             :data-test-id="`trunks-item-${i}-row`"
          >
            <td class="select-table-item" data-test-id="checkbox">
              <w-checkbox
                v-model="item.selected"
                @click="checked($event, item)"
                :key="`trunks-item-${item.id}-${item.selected}`"
                hide-details="auto"
                :data-test-id="`trunks-item-${i}-checkbox`"
              />
            </td>
            <td class="nowrap overflow-hidden" :data-test-id="`trunks-item-${i}-title`">
              <IndexListItem
                :route="{
                  name: 'trunks.show',
                  params: {
                    id: item['id'],
                    _trunk: item,
                  },
                }"
                :item="item"
                :title="`#${item.id}`"
                :key="item['id']"
            />
            </td>
            <td class="nowrap" :data-test-id="`trunks-item-${i}-name`">
              {{ item.name }}
            </td>
            <td class="w-100" :data-test-id="`trunks-item-${i}-uri`">{{ item.uri }}</td>
          </tr>
        </tbody>
      </v-simple-table>
    </template>
  </IndexPage>
</template>

<script>
  import l from '../../../libs/lang';
  import Trunk from '../../../models/Trunk';
  import Helpers from '../../../libs/helpers';
  import IndexPage from '../../elements/IndexPage.vue';
  import FormInput from '../../elements/form/FormInput.vue';
  import IndexListItem from '../../elements/IndexListItem.vue';

  export default {
    components: {
      IndexPage,
      FormInput,
      IndexListItem,
    },
    async created() {
      await this.$data.trunk.checkIfTrunksAreEnabled();
    },
    data() {
      return {
        l,
        trunk: new Trunk(this.$session, Helpers.emitter(this), Helpers.changeRoute(this)),
      };
    },
    methods: {
      checked(event, item) {
        this.$data.trunk.checked(event, item);
        this.$emit('forceUpdate');
      }
    },
    mounted() {
      this.$parent.$on('userRedirected', (to, from, next) => {
        this.$data.trunk.handleUserRedirect(to, from, next);
      });
    },
  };
</script>
