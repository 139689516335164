<template>
  <div>
    <IndexPage :resource="blocked" :create_route="{ name: 'blockedCalls.create' }"
      @filtersToggled="blocked.filtersToggled($event)" class="blocked-calls list-page" data-test-id="bc-list-page">
      <template v-slot:additionalFilterOptions>
        <w-btn type="button" class="d-inline mb-3 primary--text text--darken-1 mr-3" color="primary lighten-5"
          @click="blocked.show_test_number = !blocked.show_test_number" data-test-id="bc-test-number-collapse-btn">
          {{ l.t('blocked-calls.test-number', 'Test number') }}
        </w-btn>
      </template>
      <template v-slot:filters>
        <FormInput :label="l.t('blocked-calls.pattern-type', 'Pattern type')">
          <w-radio-group v-model="blocked.filters.pattern_type" row hide-details='auto'>
            <v-radio :label="l.t('blocked-calls.equals', 'Equals')" value="equals" hide-details='auto'
              data-test-id="bc-fitlers-quals-radio"></v-radio>
            <v-radio :label="l.t('app.starts-with', 'Starts with')" value="starts_with" hide-details='auto'
              data-test-id="bc-filters-starts-with-radio"></v-radio>
          </w-radio-group>
        </FormInput>
        <FormInput :label="l.t('app.pattern', 'Pattern')">
          <Tel @changed="blocked.filters.pattern = $event.clean_number" :value="blocked.filters.pattern"
            data-test-id="bc-pattern-input" />
        </FormInput>
      </template>
      <template v-slot:beforeList>
        <transition name="collapsable">
          <div v-if="blocked.show_test_number" @submit="blocked.test_number()" class="filters pa-3">
            <FormInput :label="l.t('blocked-calls.test-number', 'Test number')">
              <Tel @changed="blocked.test_number = $event.clean_number" :value="blocked.test_number"
                data-test-id="bc-test-number-tel-input" />
            </FormInput>
            <div id="filters-buttons" class="d-md-flex justify-center">
              <w-btn @click.prevent="blocked.clear_test_number()" class="primary--text primary lighten-5 mr-5"
                data-test-id="bc-clear-test-number-btn">
                {{ l.t("blocked-calls.clear-test-number", "Clear test number") }}
              </w-btn>
              <w-btn @click.prevent="blocked.do_test_number()" color="primary" data-test-id="bc-test-number-submit-btn">
                {{ l.t("blocked-calls.test-number", "Test number") }}
              </w-btn>
            </div>
          </div>
        </transition>
      </template>
      <template v-slot:list>
        <v-simple-table class="list-table" data-test-id="bc-table">
          <thead data-test-id="table-head">
            <tr data-test-id="head-row">
              <th class="select-table-item" data-test-id="checkbox">
                <br />
              </th>
              <th data-test-id="id">{{ l.t("app.id", "ID") }}</th>
              <th data-test-id="pattern">{{ l.t('app.pattern', 'Pattern') }}</th>
              <th class="w-100" data-test-id="notes">{{ l.t('app.notes', 'Notes') }}</th>
              <th data-test-id="more-options"><br></th>
            </tr>
          </thead>
          <tbody data-test-id="table-body">
            <tr
              v-for="(call, i) in blocked.items" :key="`${call['id']}_${call['editable']}`"
              :class="{ 'selected-row': call.selected }"
              :data-test-id="call.id"
            >
              <template v-if="blocked.deleting_item !== call.id">
                <td class="select-table-item" data-test-id="checkbox">
                  <w-checkbox v-model="call.selected" @change="checkChanged"
                    :key="`extensions-item-${call.id}-${call.selected}`" hide-details="auto"
                    :data-test-id="`bc=item-${i}-checkbox`" />
                </td>
                <td data-test-id="id">
                  {{ call.id }}
                </td>
                <td class="nowrap" v-if="call.pattern_type === 'equals'" data-test-id="pattern">
                  {{ call.pattern | format_phone_number }}
                </td>
                <td v-else class="nowrap" data-test-id="pattern">
                  {{ call.pattern | format_phone_number }}...
                </td>
                <td data-test-id="notes">
                  {{ call.notes ? call.notes : '—' }}
                </td>
                <td data-test-id="more-options">
                  <MoreOptions :options="more_options(call)" />
                </td>
              </template>
              <td v-else colspan="6" data-test-id="loader">
                <w-loader size="small" :no_margins="true" />
              </td>
            </tr>
          </tbody>
        </v-simple-table>
      </template>
    </IndexPage>
    <ConfirmDeletion
      :value="!!deleting_item_id"
      @closed="deleting_item_id = null"
      @confirmed="blocked.delete_item(deleting_item_id)"
    />
  </div>
</template>

<script>
import Helpers from '../../../libs/helpers';
import l from '../../../libs/lang';
import BlockedCall from '../../../models/BlockedCall';
import Tel from '../../elements/Tel.vue';
import IndexPage from '../../elements/IndexPage.vue';
import FormInput from '../../elements/form/FormInput.vue';
import MoreOptions from '../../elements/MoreOptions.vue';
import ConfirmDeletion from '../../elements/modal/ConfirmDeletion.vue';

export default {
  components: {
    Tel,
    IndexPage,
    FormInput,
    MoreOptions,
    ConfirmDeletion
  },
  data() {
    return {
      l,
      blocked: new BlockedCall(this.$session, Helpers.emitter(this), Helpers.changeRoute(this)),
      deleting_item_id: null,
    };
  },
  mounted() {
    this.$parent.$on('userRedirected', (to, from, next) => {
      this.$data.blocked.handleUserRedirect(to, from, next);
    });
  },
  methods: {
    more_options(item) {
      return [
        {
          cb: () => this.$data.deleting_item_id = item.id,
          title: l.t('app.delete', 'Delete'),
        }
      ];
    },
    checkChanged() {
      this.$data.blocked.checkSubmission();
      this.$emit('forceUpdate');
    },
  },
  filters: {
    upperFirstCase(val) {
      if (!val) return '—';
      return val.charAt(0).toUpperCase() + val.slice(1).split('_').join(' ');
    },
    format_phone_number(val) {
      return Helpers.format_phone_number(val);
    },
    format_date(stamp) {
      if (!stamp) return '—';
      return new Date(stamp * 1000).toISOString().split('T')[0];
    },
  },
};
</script>
