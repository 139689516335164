<template>
    <v-container
        v-if="show"
        data-test-id="alert"
        fluid
    >
        <v-row
          justify="center"
          class="pa-0 alert"
        >
            <div
                :class="{
                    'info--text text--darken-1 info lighten-5': level === 'success',
                    'error--text text--darken-1 error lighten-5': level === 'error',
                    'warning--text text--darken-1 warning-focus': level === 'warning',
                }"
                class="d-flex alert-container"
            >
                <w-btn
                    icon
                    :class="icon_classes"
                    class="alert-icon"
                    width="24"
                    height="24"
                >
                    <v-icon>
                        {{ icon }}
                    </v-icon>
                </w-btn>
                <div
                    class="alert-message my-auto"
                >
                    {{ message }}
                </div>
                <span v-if="closable && level !== 'success'" class="close-alert cursor-pointer ml-8" @click="show = false">
                    <v-icon :color="`${icon_color} lighten-1`">mdi mdi-close</v-icon>
                </span>
            </div>
        </v-row>
    </v-container>
</template>

<script>
import l from '../../libs/lang';

export default {
    props: {
        'message': {
            'type': String,
            'required': true,
        },
        'level': {
            'type': String,
            'required': false,
            validator: (val) => ['error', 'warning', 'success'].includes(val),
        },
        'closable': {
            type: Boolean,
            default: true,
        }
    },
    data() {
        return {
            l,
            show: true,
        };
    },
    computed: {
        icon() {
            if (this.$props.level === 'success') {
                return '$vuetify.icons.check';
            }
            if (this.$props.level === 'warning') {
                return '$vuetify.icons.infotip_stylized';
            }

            return '$vuetify.icons.exclamation';
        },
        icon_classes() {
            const classes = {
                'my-auto white--text': this.$props.level !== 'warning',
            };
            classes[this.icon_color] = true;
            return classes;
        },
        icon_color() {
            if (this.$props.level === 'success') {
                return 'info';
            }
            if (this.level === 'warning') {
                return 'warning--text text--darken1';
            }
            return this.$props.level;
        }
    },
    watch: {
        show: {
            // eslint-disable-next-line object-shorthand
            handler: function (val) {
                if (!val) {
                    this.$emit('closed');
                }
            },
        },
    },
};
</script>
<style lang="scss" scoped>
.alert {
    .alert-container {
            border-radius: 6px;
            padding: 16px 16px 16px 14px;
            .alert-message {
                font-size: 12px;
                font-style: normal;
                font-weight: 500;
                line-height: 19px; /* 158.333% */
            }
            .alert-icon {
                margin-right: 6px;
            }
        }
        .warning-focus {
            background-color: rgba(255, 204, 177, 0.55)
        }
    }
</style>
