<template>
	<div v-if="media.loading" data-test-id="media-loader">
		<w-loader v-if="media.loading" :message="media.loading_message"/>
		<div v-if="media.updating_in_progress" class="abort-process d-flex justify-center">
			<w-btn
				@click="media.stop_the_process = true"
				:disabled="media.stop_the_process"
				color="error"
				class="mt-3 white--text"
				data-test-id="media-loader-abort-btn"
			>
				{{ media.stop_the_process ? l.t('app.aborting-process', 'Aborting the process...') : l.t('app.abort-the-process', 'Abort the process') }}
			</w-btn>
		</div>
	</div>
	<div v-else>
		<w-alert
			v-if="media.alert"
			:level="media.alert.level"
			:message="media.alert.message"
			:closable="media.enable_close_alert"
			@closed="media.alert = null"
			class="mb-5"
		/>
		<AccountsList v-if="media.screen === 'accounts-list'" :media="media" @submitted="media.screen = 'search-media'"></AccountsList>
		<ImportCsvForm v-else-if="media.screen === 'show-import-csv-form'" :media="media"></ImportCsvForm>
		<CsvImportingResults v-if="media.screen === 'results-of-csv-importing'" :media="media"></CsvImportingResults>
		<SearchMedia v-else-if="media.screen === 'search-media'" :media="media" @submitted="media.apply_filters()"></SearchMedia>
		<MediaList v-else-if="media.accounts.some(x => x.items.length) && media.screen === 'media-list'" :media="media" @submitted="media.show_update_form()"></MediaList>
		<template v-else-if="media.accounts.every(x => !x.items.length) && media.screen === 'media-list'">
			<w-alert :message="l.t('media-loader.no-items', 'We couldn\'t find any items for your search.')" level="warning" :closable="false" class="my-3"/>
			<div>
				<w-btn @click="media.screen = 'search-media'">{{ l.t('media-loader.back-to-search', 'Back to search') }}</w-btn>
			</div>
		</template>
		<MediaFragment
			v-else-if="media.screen === 'update-media'"
			:_media="media.item"
			:disable_extension_level="true"
			:just_media_part="true"
			@submitted="showConfirmation"
			@back="media.screen = 'media-list'"
		/>
		<UpdateResults v-else-if="media.screen === 'update-results'" :media="media"></UpdateResults>
		<ConfirmationModal
			v-if="confirmation_modal"
			@confirmed="updateMedia"
			@close="confirmation_modal = false"
			:message="l.t('app.are-you-sure','Are you sure?')"
		/>
	</div>
</template>

<script>
	import l from '../../../libs/lang';
	import Helpers from '../../../libs/helpers';
	import Media from '../../../models/MediaLoader';
	import MediaFragment from '../../fragments/Media.vue';
	import MediaList from '../../fragments/media-loader/media-list.vue';
	import SearchMedia from '../../fragments/media-loader/search-media.vue';
	import AccountsList from '../../fragments/media-loader/accounts-list.vue';
	import ConfirmationModal from '../../elements/modal/ConfirmationModal.vue';
	import UpdateResults from '../../fragments/media-loader/update-results.vue';
	import ImportCsvForm from '../../fragments/media-loader/import-csv-form.vue';
	import CsvImportingResults from '../../fragments/media-loader/import-csv-results.vue';

	export default {
		components: {
			MediaList,
			SearchMedia,
			AccountsList,
			MediaFragment,
			UpdateResults,
			ImportCsvForm,
			ConfirmationModal,
			CsvImportingResults
		},
		data() {
			return {
				l,
				media: new Media(this.$session, null, Helpers.emitter(this), Helpers.changeRoute(this)),
				update_data: null,
				confirmation_modal: false,
			};
		},
		async created() {
			await this.$data.media.listAccountsAndSubaccounts();
		},
		methods: {
			showConfirmation(media) {
				this.$data.update_data = media;
				this.$data.confirmation_modal = true;
			},
			async updateMedia() {
				this.$data.confirmation_modal = false;
				this.$data.media.update({...this.$data.update_data});
				this.$data.update_data = null;
			}
		},
		watch: {
			// eslint-disable-next-line object-shorthand
			confirmation_modal: function (val) {
				if (!val) {
					this.$data.update_data = null;
				}
			}
		}
	};
</script>
