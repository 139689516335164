import l from '../../libs/lang';
import ReportConf from './ReportConf';

export default class Type extends ReportConf {
	constructor() {
		super([
			'count_incoming',
			'count_outgoing',
			'total',
		]);
		this.title = ReportConf.get_title('calls', 'types');
		this.group_by_param = 'date';
		this.headers = {
			name: l.t('app.type', 'Type'),
			count: l.t('app.total', 'Total'),
		};
	}

	// eslint-disable-next-line class-methods-use-this
	prepare_items(items) {
		const incoming = items.reduce((a, c) => a + c.count_incoming, 0);
		const outgoing = items.reduce((a, c) => a + c.count_outgoing, 0);
		return [
			{
				name: l.t('app.incoming-calls', 'Incoming calls'),
				count: incoming,
			},
			{
				name: l.t('app.outgoing-calls', 'Outgoing calls'),
				count: outgoing,
			},
		];
	}
}
