<template>
    <w-loader v-if="group.loading"/>
    <div v-else class="show-page" data-test-id="groups-show-page">
        <w-alert
          v-if="group.alert"
          :message="group.alert.message"
          :level="group.alert.level"
          :closable="group.enable_close_alert"
          @closed="group.alert = null"
          class="mb-3"
        />
        <GroupFragment
          v-if="group.item"
          :_group="group.item"
          @submitted="group.updateGroup($event)"
        />
    </div>
</template>

<script>
  import Helpers from '../../../libs/helpers';
  import Group from '../../../models/Group';
  import GroupFragment from '../../fragments/ContactGroup.vue';

  export default {
    props: ['id', 'extension_id', '_group'],
    components: {
      GroupFragment,
    },
    data() {
      return {
        group: new Group(this.$session, Helpers.emitter(this), Helpers.changeRoute(this)),
      };
    },
    async created() {
      this.$data.group.extension = this.$props.extension_id ? this.$props.extension_id : this.$route.params.extension_id;
      if (this.$props._group) {
        this.$data.group.item = this.$props._group;
      } else {
        await this.$data.group.getItem(this.$props.id ? this.$props.id : this.$route.params.id);
      }
    },
  };
</script>
