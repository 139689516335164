<template>
    <w-loader v-if="media.loading"/>
    <div v-else class="show-page media-fragment-size" data-test-id="media-show-page">
      <PageTitle class="nowrap">
        {{title()}}
      </PageTitle>
        <w-alert
          v-if="media.alert"
          :message="media.alert.message"
          :level="media.alert.level"
          :closable="media.enable_close_alert"
          @closed="media.alert = null"
          class="mb-6"
        />
        <w-alert
          v-if="media.item && media.item.id < 24"
          :message="l.t('media.built-in-media-error', 'You can\'t update built in media.')"
          :level="'warning'"
          :closable="false"
          class="mb-6"
        />
        <MediaFragment
          v-else-if="media.item"
          :_media="media.item"
          @submitted="media.update($event)"
          :withPlayer="true"
        />
    </div>
</template>

<script>
  import l from '../../../libs/lang';
  import Media from '../../../models/Media';
  import Helpers from '../../../libs/helpers';
  import MediaFragment from '../../fragments/Media.vue';
  import PageTitle from '../../elements/PageTitle.vue';

  export default {
    props: ['id', '_media'],
    components: {
      MediaFragment,
      PageTitle,
    },
    data() {
      return {
        l,
        last_title: '',
        media: new Media(this.$session, null, Helpers.emitter(this), Helpers.changeRoute(this)),
      };
    },
    async created() {
      if (this.$props._media || this.$route.params._media) {
        this.$data.media.item = Media.checkTts(this.$props._media || this.$route.params._media);
      } else {
        await this.$data.media.getItem(this.$props.id ? this.$props.id : this.$route.params.id);
      }
    },
    methods: {
      title() {
        let title = '';
        if (this.$data.media.item) {
          title += this.$data.media.item.type === 'greeting' ? l.t('app.greeting', 'Greeting:') : l.t('app.music-on-hold', 'Music on hold:');
          title += ` ${this.$data.media.item.name}`;
          this.$data.last_title = title;
        } else {
          title = this.$data.last_title;
        }
        return title;
      },
    }
  };
</script>
