var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.order.loading)?_c('w-loader'):_c('div',{staticClass:"order show-page",attrs:{"data-test-id":"orders-show-page"}},[_c('BackButton'),_c('div',{staticClass:"d-flex justify-center w-h5 mb-1"},[_vm._v(" "+_vm._s(_vm.l.t('app.order', 'Order'))+" #"+_vm._s(_vm.l.t('app.order-ID', 'Order ID'))+"-"+_vm._s(_vm.item.id)+" ")]),_c('div',{staticClass:"d-flex justify-center w-caption"},[_vm._v(" "+_vm._s(_vm.l.t('orders.tracking-number', 'Tracking number'))+":  "),(_vm.item.tracking_number)?_c('a',{staticClass:"w-subtitle-3",attrs:{"href":`https://www.fedex.com/fedextrack/?tracknumbers=${_vm.item.tracking_number}`,"target":"_blank"}},[_vm._v(" "+_vm._s(_vm.item.tracking_number)+" ")]):_c('span',{staticClass:"text--text text--lighten-1"},[_vm._v(_vm._s(_vm.l.t('orders.available-after-shipping', 'Available after shipping')))])]),(_vm.order.alert)?_c('w-alert',{staticClass:"mb-6",attrs:{"message":_vm.order.alert.message,"level":_vm.order.alert.level,"closable":_vm.order.enable_close_alert},on:{"closed":function($event){_vm.order.alert = null}}}):_vm._e(),(_vm.item)?_c('div',{staticClass:"mt-9",class:{'d-flex justify-center': _vm.$vuetify.breakpoint.mdAndUp}},[_c('VerticalTable',{style:({width: _vm.$vuetify.breakpoint.mdAndUp ? '350px' : '100%', marginRight: _vm.$vuetify.breakpoint.mdAndUp ? '37px' : 0}),attrs:{"items":[
        {text: _vm.l.t('app.voIP-id', 'VoIP ID'), value: _vm.item.voip_id},
        {text: _vm.l.t('app.status', 'Status'), value: _vm.order.status_translations[_vm.item.status] ? _vm.order.status_translations[_vm.item.status] : _vm.item.status},
        {text: _vm.l.t('orders.shipping-method', 'Shipping method'), value: _vm.order.formatShippingMethod(_vm.item.shipping_method)},
        {text: _vm.l.t('orders.shipping-address', 'Shipping address'), value: _vm.order.constructor.stringifyAddress(_vm.item.contact.address)},
        {text: _vm.l.t('app.name', 'Name'), value: _vm.item.contact.name},
        {text: _vm.l.t('app.phone', 'Phone'), value: _vm.format_phone_number(_vm.item.contact.phone)},
        {text: _vm.l.t('app.email-address', 'Email address'), value: _vm.item.contact.primary_email ? _vm.item.contact.primary_email : _vm.primary_email},
        {text: _vm.l.t('orders.signature-required', 'Signature required'), value: _vm.item.signature_required ? _vm.l.t('app.yes', 'Yes') : _vm.l.t('app.no', 'No')},
      ],"title":_vm.l.t('orders.details-contact-information', 'Details & Contact information')}}),_c('VerticalTable',{style:({width: _vm.$vuetify.breakpoint.mdAndUp ? '350px' : '100%', marginTop: !_vm.$vuetify.breakpoint.mdAndUp ? '40px' : 0}),attrs:{"items":_vm.devices_items(),"title":_vm.l.t('app.devices', 'Devices')}})],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }