import { render, staticRenderFns } from "./VueBlock.vue?vue&type=template&id=c3698702&scoped=true&"
import script from "./VueBlock.vue?vue&type=script&lang=js&"
export * from "./VueBlock.vue?vue&type=script&lang=js&"
import style0 from "./VueBlock.vue?vue&type=style&index=0&id=c3698702&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c3698702",
  null
  
)

export default component.exports