var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.listener.loading)?_c('w-loader'):_c('div',[(_vm.listener.alert)?_c('w-alert',{staticClass:"mb-6",attrs:{"message":_vm.listener.alert.message,"level":_vm.listener.alert.level,"closable":_vm.listener.enable_close_alert},on:{"closed":function($event){_vm.listener.alert = null}}}):_vm._e(),(_vm.listener.item)?_c('w-form',{attrs:{"data-discard":"true"},on:{"changed":function($event){_vm.may_submit = true},"submit":function($event){return _vm.listener.submitted($event)}}},[_c('FormInput',{attrs:{"label":_vm.l.t('app.application', 'Application')}},[_c('w-select',{attrs:{"items":[
            {value: 'google-analytics', text: _vm.l.t('listeners.google-analytics', 'Google analytics') },
            {value: 'sms-autoresponder', text: _vm.l.t('listeners.sms-autoresponder', 'SMS autoresponder') },
          ],"hide-details":"auto","data-test-id":"app-application-select"},model:{value:(_vm.listener.application),callback:function ($$v) {_vm.$set(_vm.listener, "application", $$v)},expression:"listener.application"}})],1),(!_vm.disable_extension_selector)?[_c('FormInput',{attrs:{"label":_vm.l.t('app.level', 'Level')}},[_c('w-radio-group',{attrs:{"row":"","hide-details":"auto"},model:{value:(_vm.listener.level),callback:function ($$v) {_vm.$set(_vm.listener, "level", $$v)},expression:"listener.level"}},[_c('v-radio',{attrs:{"label":_vm.l.t('app.account', 'Account'),"value":"account","data-test-id":"apps-account-radio"}}),_c('v-radio',{attrs:{"label":_vm.l.t('app.extension', 'Extension'),"value":"extension","data-test-id":"apps-extension-radio"}})],1)],1),(_vm.listener.level === 'extension')?[_c('FormInput',{attrs:{"label":_vm.l.t('app.extension', 'Extension')}},[_c('ExtensionSelector',{attrs:{"select_if_one":true,"value":{ id: _vm.listener.extension },"data-test-id":"apps-extension-selector"},on:{"changed":function($event){_vm.listener.extension = $event.id}}})],1)]:_vm._e()]:_vm._e(),(
          _vm.listener.application &&
            Object.keys(_vm.listener.subscriptions[_vm.listener.application])
              .length > 1
        )?[_c('FormInput',{attrs:{"label":_vm.l.t('listeners.trigger', 'Trigger')}},[_c('w-radio-group',{attrs:{"rules":[_vm.$validation.required()],"row":"","hide-details":"auto"},model:{value:(_vm.listener.subscriptionsType),callback:function ($$v) {_vm.$set(_vm.listener, "subscriptionsType", $$v)},expression:"listener.subscriptionsType"}},_vm._l((_vm.listener.subscriptions[
              _vm.listener.application
            ]),function(tags,value){return _c('v-radio',{attrs:{"value":value,"label":tags.label,"data-test-id":`apps-${value}-radio`}})}),1)],1)]:_vm._e(),(_vm.listener.callback.config)?[(_vm.listener.application === 'google-analytics')?_c('GoogleAnalytics',{attrs:{"_config":_vm.listener.callback.config,"data-test-id":"apps-google-analytics"},on:{"changed":function($event){_vm.listener.callback.config = $event}}}):_vm._e(),(_vm.listener.application === 'sms-autoresponder')?_c('div',[_c('ListenerFilters',{model:{value:(_vm.listener[':filters']),callback:function ($$v) {_vm.$set(_vm.listener, ':filters', $$v)},expression:"listener[':filters']"}}),_c('SMSAutoresponder',{attrs:{"_config":_vm.listener.callback.config,"data-test-id":"apps-sms-autoresponder"},on:{"changed":function($event){_vm.listener.callback.config = $event}}})],1):_vm._e()]:_vm._e(),_c('FormInput',[_c('w-btn',{staticClass:"primary mt-3",attrs:{"type":"submit","disabled":!_vm.may_submit || !_vm.listener.application,"data-test-id":"apps-submit-btn"}},[_vm._v(" "+_vm._s(_vm.l.t("app.save", "Save"))+" ")])],1)],2):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }