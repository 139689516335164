<template>
    <v-combobox
    :key="l.detect()"
        v-bind="$attrs"
        color="primary"
        v-on="$listeners"
        @input="input"
        :no-data-text="l.t('app.no-data-available', 'No data available')"
        append-icon="$vuetify.icons.dropdown_arrow_black"
        class="custom-combobox"
        hide-details="auto"
    >
        <template v-slot:no-data>
            <div class="px-4">YOUR TEXT</div>
        </template>
        <!-- Pass on all named slots -->
        <slot v-for="slot in Object.keys($slots)" :name="slot" :slot="slot" />
        <!-- Pass on all scoped slots -->
        <template v-for="slot in Object.keys($scopedSlots)" :slot="slot" slot-scope="scope">
            <slot :name="slot" v-bind="scope" />
        </template>
    </v-combobox>
</template>
<script>
import EventBus from '../../../libs/EventBus';
import l from '../../../libs/lang';

export default {
    data() {
        return {
            l,
        };
    },
    methods: {
        input(e) {
            EventBus.$emit('form_input_changed');
            this.$emit('input', e);
        }
    }
};
</script>
<style lang="scss">
.custom-combobox {
    .v-input__icon--append {
        margin-right: 9px;
    }
}
</style>
