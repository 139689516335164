<template>
    <div
        class="loader-container"
        :class="{
            small: size === 'small',
            medium: size === 'medium',
            large: size === 'large' || !size,
            no_margins: no_margins,
        }"
        ref="loader"
        data-test-id="loader"
    >
        <div class="d-flex justify-center">
            <v-icon class="loader-spinner">$vuetify.icons.spinning_loader</v-icon>
        </div>
        <div v-if="message !== false" class="d-flex justify-center">
            {{ message || (size === "small" ? "" : `${l.t("app.loading", "Loading")}...`) }}
        </div>
    </div>
</template>

<script>
  import l from '../../libs/lang';

  export default {
    props: ['size', 'message', 'no_margins'],
    data() {
      return {
        l,
      };
    },
    mounted() {
        if (this.$props.size === 'large' || !this.$props.size) {
            const parent_modal = this.get_parent_modal();
            if (parent_modal) {
                const modal_width = parent_modal.offsetWidth;
                const modal_height = parent_modal.offsetHeight;
                this.$refs.loader.style.marginTop = `${(modal_height - this.$refs.loader.offsetHeight - 57) / 2}px`;
                this.$refs.loader.style.marginRight = `${(modal_width - this.$refs.loader.offsetWidth - 226) / 2}px`;
            } else {
                const screen_width = document.getElementById('app').offsetWidth;
                const screen_height = window.innerHeight;
                // $headerHeight: 57px;
                // $sidebar-width: 226px;
                this.$refs.loader.classList.add('absolute-loader');
                this.$refs.loader.style.top = `${(screen_height - this.$refs.loader.offsetHeight - 57) / 2}px`;
                this.$refs.loader.style.right = `${(screen_width - this.$refs.loader.offsetWidth - 226) / 2}px`;
            }
        }
    },
    methods: {
        get_parent_modal() {
            let a = this.$refs.loader;
            while (a) {
                a = a.parentNode;
                if (
                    a
                    && a.parentNode
                    && a.parentNode.classList
                    && a.parentNode.classList.contains('v-dialog--active')
                ) {
                    return a.parentNode;
                }
            }

            return null;
        }
    },
  };
</script>

 <style scoped>
    .loader-container.large {
        margin-top:  40vh;
    }
    .loader.absolute-loader {
        margin-top:  0 !important;
        position:  absolute;
    }
    .loader-spinner {
        color:  #8C98A0 !important;
        font-size:  30px !important;
    }
    .no_margins{
         margin: 0 !important;
    }
</style>
