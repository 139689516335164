<template>
    <div class="extensions">
      <Submenu
        v-if="routes && $route.name !== 'extensions.visualizer'"
        :routes="routes"
        :key="`${l.detect()}${$route.name}`"
      />
      <ExtensionsFragment
        v-if="$route.name === 'extensions.index'"
        @deletingStarted="deleting_in_progress = true"
        @deletingEnded="deleting_in_progress = false"
        data-test-id="extensions-index"
      />
      <ExtensionsCreateFragment
        v-if="$route.name === 'extensions.create'"
        data-test-id="extensions-create"
      />
      <ExtensionsShowFragment
        v-if="Object.keys(show_routes).includes($route.name)"
        data-test-id="extensions-show"
      />
      <Visualizer
        v-if="$route.name === 'extensions.visualizer'"
        data-test-id="extensions-visualizer"
      />
    </div>
</template>

<script>
  import l from '../../../libs/lang';
  import ExtensionsFragment from './index.vue';
  import ExtensionsShowFragment from './show.vue';
  import Submenu from '../../elements/Submenu.vue';
  import ExtensionsCreateFragment from './create.vue';
  import Visualizer from '../visualizer/index.vue';

  export default {
    components: {
      Submenu,
      ExtensionsFragment,
      ExtensionsCreateFragment,
      ExtensionsShowFragment,
      Visualizer,
    },
    data() {
      const show_routes = {
        'extensions.show': { title: l.t('app.settings', 'Settings')},
        'extensions.call-flow': { title: l.t('call-handling.call-handling', 'Call Handling')},
        'extensions.type': { title: l.t('app.type', 'Type')},
      };
      let routes = {
        'extensions.index': {
          title: l.t('app.extensions', 'Extensions')
          },
        'extensions.create': {
          title: l.t('app.add-extension', 'Add extension')
          },
        'extensions.visualizer': {
          title: l.t('app.visualizer', 'Visualizer')
          }
      };
      if (Object.keys(show_routes).includes(this.$route.name)) {
        routes = {...show_routes};
      }
      return {
        l,
        show_routes,
        routes,
        deleting_in_progress: false,
      };
    },
    beforeRouteLeave(to, from, next) {
      if (window.deleting_in_progress) {
        this.$emit('userRedirected', to, from, next);
      } else {
        next();
      }
    },
  };
</script>
