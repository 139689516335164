<template>
    <v-radio-group
        v-bind="$attrs"
        v-on="$listeners"
        :value="val"
        color="primary"
        @change="on_change"
        class="custom-radio-group"
        hide-details="auto"
    >
        <!-- Pass on all named slots -->
        <slot v-for="slot in Object.keys($slots)" :name="slot" :slot="slot" />
        <!-- Pass on all scoped slots -->
        <template v-for="slot in Object.keys($scopedSlots)" :slot="slot" slot-scope="scope">
            <slot :name="slot" v-bind="scope" />
        </template>
    </v-radio-group>
</template>
<script>
import EventBus from '../../../libs/EventBus';

export default {
    props: ['value'],
    data() {
        return {
            val: this.$props.value,
        };
    },
    methods: {
        on_change(e) {
            EventBus.$emit('form_input_changed');
            this.$emit('input', e);
        },
    }
};
</script>
