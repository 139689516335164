var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"trunks"},[_c('Submenu',{key:`${_vm.l.detect()}${_vm.$route.name}`,attrs:{"routes":{
      'trunks.index': {
        title: _vm.l.t('app.trunks', 'Trunks'),
        subroutes: ['trunks.show']
        },
      'trunks.create': {
        title: _vm.l.t('trunks.add-trunk', 'Add trunk')
        },
    }}}),(_vm.$route.name === 'trunks.index')?_c('TrunksFragment',{attrs:{"data-test-id":"trunks-index"},on:{"deletingStarted":function($event){_vm.deleting_in_progress = true},"deletingEnded":function($event){_vm.deleting_in_progress = false}}}):_vm._e(),(_vm.$route.name === 'trunks.create')?_c('TrunksCreateFragment',{attrs:{"data-test-id":"trunks-create"}}):_vm._e(),(_vm.$route.name === 'trunks.show')?_c('TrunksShowFragment',{attrs:{"data-test-id":"trunks-show"}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }