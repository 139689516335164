import { loadStripe } from '@stripe/stripe-js';
import l from '../libs/lang';
import Resource from './Resource';

export default class PaymentMethod extends Resource {
	constructor(session, emitter, changeRoute) {
		super(session, emitter, '/payment-methods', changeRoute);
        this.item = {
          account_id: null,
          status: null, // Can be 'primary' = primary card used for billing, 'onfile' = card on file, 'hidden' = deleted card
          nickname: null,
          type: 'cc', // 'cc' for credit card
          cc_token: null, // Encrypted credit card token to be used for billing
		};
		this.stripe = null;
		this.processing_card = false;
		this.processing_status = false;
		this.primary_cc_id = null;
	}

	async load_stripe() {
		if (this.stripe) return this.stripe;
		const provider_data = await this.get_provider_data();
		if (provider_data.provider !== 'stripe') {
			return this.alert = {
				level: 'error',
				message: l.t('pm.bad-provider', 'Bad provider'),
			};
		}

		this.stripe = await loadStripe(provider_data.public_key);
		// this.stripe = await loadStripe('pk_test_qblFNYngBkEdjEZ16jxxoWSM'); // cant work without apropriate server side secret
		return this.stripe;
	}

	async get_provider_data() {
		const json_data = await fetch(`${this.session._phoenix_url('/v4/credit-cards/provider', true)}`);
		return json_data.json();
	}

	/* eslint-disable-next-line class-methods-use-this */
	async load_payment_methods() {
		let items = await this.session.get_list_all(`${this.baseUri}?with_details=1`);
		// let items = {
		// 	"filters": {},
		// 	"sort": {
		// 		"id": "desc"
		// 	},
		// 	"total": 1,
		// 	"limit": 500,
		// 	"offset": 0,
		// 	"items": [
		// 		{
		// 			"id": 781826,
		// 			"status": "onfile",
		// 			"nickname": "",
		// 			"type": "cc",
		// 			"created_at": 0,
		// 			"contact": {
		// 				"id": 6671937,
		// 				"name": "Oksana Pryymak",
		// 				"company": "Test Order phones Oksana",
		// 				"address": {
		// 					"line_1": "11 George St",
		// 					"line_2": "",
		// 					"city": "Summit",
		// 					"province": "NJ",
		// 					"postal_code": "07901",
		// 					"country": "US"
		// 				},
		// 				"phone": "(908) 608-4739",
		// 				"fax": "",
		// 				"primary_email": "opryymak+paid_nov8@phone.com",
		// 				"alternate_email": ""
		// 			},
		// 			"details": {
		// 				"brand": "American Express",
		// 				"exp_month": 9,
		// 				"exp_year": 2024,
		// 				"last4": "2018",
		// 				"name": null,
		// 				"customer_name": "Oksana Pryymak"
		// 			},
		// 			"decline_count": 0,
		// 			"next_charge_date": 0,
		// 			"updated_at": 1670680307,
		// 			"cc_token": "cus_MlXpiYwfl7us1E",
		// 			"cc_exp": "0924",
		// 			"cc_number": "3xxxxxxxxxx2018"
		// 		},
		// 		{
		// 			"id": 781827,
		// 			"status": "primary",
		// 			"nickname": "",
		// 			"type": "cc",
		// 			"created_at": 0,
		// 			"contact": {
		// 				"id": 6671937,
		// 				"name": "Oksana Pryymak",
		// 				"company": "Test Order phones Oksana",
		// 				"address": {
		// 					"line_1": "11 George St",
		// 					"line_2": "",
		// 					"city": "Summit",
		// 					"province": "NJ",
		// 					"postal_code": "07901",
		// 					"country": "US"
		// 				},
		// 				"phone": "(908) 608-4739",
		// 				"fax": "",
		// 				"primary_email": "opryymak+paid_nov8@phone.com",
		// 				"alternate_email": ""
		// 			},
		// 			"details": {
		// 				"brand": "Visa",
		// 				"exp_month": 9,
		// 				"exp_year": 2024,
		// 				"last4": "2018",
		// 				"name": null,
		// 				"customer_name": "Oksana Pryymak"
		// 			},
		// 			"decline_count": 0,
		// 			"next_charge_date": 0,
		// 			"updated_at": 1670680307,
		// 			"cc_token": "cus_MlXpiYwfl7us1E",
		// 			"cc_exp": "0924",
		// 			"cc_number": "3xxxxxxxxxx2018"
		// 		},
		// 		{
		// 			"id": 781828,
		// 			"status": "onfile",
		// 			"nickname": "",
		// 			"type": "cc",
		// 			"created_at": 0,
		// 			"contact": {
		// 				"id": 6671937,
		// 				"name": "Boris TEST",
		// 				"company": "Test Order phones Oksana",
		// 				"address": {
		// 					"line_1": "11 George St",
		// 					"line_2": "",
		// 					"city": "Summit",
		// 					"province": "NJ",
		// 					"postal_code": "07901",
		// 					"country": "US"
		// 				},
		// 				"phone": "(908) 608-4739",
		// 				"fax": "",
		// 				"primary_email": "opryymak+paid_nov8@phone.com",
		// 				"alternate_email": ""
		// 			},
		// 			"details": {
		// 				"brand": "MasterCard",
		// 				"exp_month": 9,
		// 				"exp_year": 2024,
		// 				"last4": "2018",
		// 				"name": null,
		// 				"customer_name": "Boris test"
		// 			},
		// 			"decline_count": 0,
		// 			"next_charge_date": 0,
		// 			"updated_at": 1670680307,
		// 			"cc_token": "cus_MlXpiYwfl7us1E",
		// 			"cc_exp": "0924",
		// 			"cc_number": "3xxxxxxxxxx2018"
		// 		}
		// 	]
		// };
		// eslint-disable-next-line prefer-destructuring
		items = items.items;
		items.sort((a, b) => {
			if (a.status === 'primary' && b.status !== 'primary') {
				return -1;
			  }
			  return 0;
		});
	    return items;
	}

	async loadItems() {
	  this.loading = true;
	  try {
	    this.items = await this.load_payment_methods();
	  } catch (err) {
	    this.validation_error(err);
	    if (err.status === 403) this.hide_filters = true;
	  }
	  this.loading = false;

	  return this.items;
	}

	async create(card_element, opts = {}, is_primary, nickname) {
		const options = { currency: 'usd', ...opts };
		this.processing_card = true;
		try {
			const result = await this.stripe.createToken(card_element, options);
			if (result.error) {
				this.alert = {
					level: 'error',
					message: result.error.message,
				};
			} else {
				const temp_token = await this.create_temporary_CC_token(result.token.id);
				if ('@error' in temp_token) {
					this.alert = {
						level: 'error',
						message: temp_token['@error']['@message'],
					};
					this.processing_card = false;
					return null;
				}
				const p_method = await this.session.create_item(
					'/payment-methods',
					{
						type: 'cc',
						cc_token: temp_token.token,
						status: is_primary ? 'primary' : undefined,
						nickname: nickname || '',
					}
				);
				this.successfulCreation('billing.payment-methods', null, null, p_method);
			}
		} catch (e) {
			this.validation_error(e);
		}
		this.processing_card = false;
		return true;
	}

	create_temporary_CC_token(token) {
		return new Promise((resolve) => {
			fetch(`${this.session._phoenix_url('/v4/credit-cards', true)}`, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json'
				},
				body: JSON.stringify({ source: token })
			})
				.then((r) => r.json())
				.then((j) => resolve(j));
		});
	}

	async set_status(id, status) {
	  this.processing_status = true;
	  try {
	    await this.session.patch_item(`/payment-methods/${id}`, {status});
		this.items = await this.load_payment_methods();
	  } catch (err) {
	    this.validation_error(err);
	  }
	  this.processing_status = false;
	  return true;
	}

	async delete_item(id) {
	  this.loading = true;
	  if (this.items && this.items.length < 2) {
		this.alert = {
			message: l.t('pm.at-least-1-payment-method', 'You must have at least 1 payment method on file.'),
			level: 'error',
		};
		this.hide_alert(5);

		return this.loading = false;
	  }
		try {
			await this.session.delete_item(`/payment-methods/${id}`);
			this.items = this.items.filter((x) => x.id !== id);
			this.alert = {
				level: 'success',
				message: l.t('app.successfully-deleted', 'Successfully deleted'),
			};
			this.hide_alert(3);
		} catch (err) {
	    this.validation_error(err);
		}
		this.loading = false;

		return true;
	}
}
