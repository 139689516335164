<template>
    <w-loader v-if="route.loading"/>
    <div v-else>
      <w-alert v-if="route.alert" :level="route.alert.level" :message="route.alert.message" :closable="route.alert.closable" class="mb-12" />
      <w-form
        :validation="[form_validation.all_calls_required(route.item)]"
        @invalid_form="on_invalid_form"
        @changed="may_submit = true"
        @submit.prevent="on_save"
        data-discard="true"
      >
        <w-radio-group
          v-model="route_type"
          @change="on_route_type_change"
          class="mb-8"
          hide-details="auto"
        >
          <v-radio
            color="secondary"
            type="radio"
            value="regular_route"
            data-test-id="chr-regular-route-radio-input"
            :label="l.t('routes.apply-advanced-rules', 'Apply advanced rules')"
          />
          <v-radio
            color="secondary"
            type="radio"
            value="preset"
            data-test-id="chr-preset-radio-input"
            :label="l.t('routes.apply-a-preset', 'Apply a preset')"
          />
        </w-radio-group>
        <APIAutocomplete
          v-if="route_type === 'preset'"
          mode="presets"
          :value="route.item"
          @input="presets_selector_changed"
          :return_object="true"
          :before_list="[{value: 'create', text: l.t('routes.create-a-new-preset', 'Create a new preset')}, {divider: true}]"
          class="mb-12"
          data-test-id="route-conf-presets-selector"
        />
        <RouteConfiguration
          v-if="route_type === 'regular_route' || show_conf"
          :_route="route.item"
          :_extension="extension"
          @changed="on_route_configuration_change"
          data-test-id="route-configuration"
        />
        <div class="mt-12 d-flex justify-center">
          <SaveAsNewPreset
            :get_route="() => route.item"
            :disabled="(route.item && route.item.hasOwnProperty('name')) || route_type === 'preset'"
            :key="`save-as-preset-${(route.item && !route.item.hasOwnProperty('name')) || route_type === 'preset'}`"
            data-test-id="chr-save-as-preset"
          />
          <CancelChanges class="mr-5"/>
          <w-btn
              :disabled="!may_submit"
              type="submit"
              color="primary"
              data-test-id="route-save"
          >
            {{ l.t("app.save-changes", "Save changes") }}
          </w-btn>
        </div>
      </w-form>
      <SameFiltersModal
        v-if="same_filters"
        :value="true"
        @input="same_filters = null"
        :filter="same_filters"
        @confirmed="save"
        :key="`same-filters-${same_filters}`"
      />
    </div>
</template>

<script>
  import l from '../../../libs/lang';
  import Route from '../../../models/Route';
  import Helpers from '../../../libs/helpers';
  import FormValidation from '../../../libs/validation/form-rules';
  import SaveAsNewPreset from './SaveAsPreset.vue';
  import CancelChanges from '../CancelChanges.vue';
  import RouteConfiguration from './RouteConfiguration.vue';
  import APIAutocomplete from '../form/APIAutocomplete.vue';
  import SameFiltersModal from './SameFiltersModal.vue';

  export default {
    props: {
      'id': {
        type: Number,
        required: false,
      },
      '_route': {
        type: Object,
        required: false,
      },
      '_extension': {
        type: Object,
        required: false,
      },
      'presave_cb': {
        type: Function,
        required: false,
      },
    },
    components: {
      CancelChanges,
      SaveAsNewPreset,
      APIAutocomplete,
      SameFiltersModal,
      RouteConfiguration,
    },
    data() {
      return {
        l,
        extension: null,
        route_type: null,
        show_conf: false,
        may_submit: false,
        original_route: null,
        save_route_as_preset: false,
        form_validation: FormValidation,
        route: new Route(this.$session, Helpers.emitter(this), Helpers.changeRoute(this)),
        same_filters: false,
      };
    },
    async created() {
      this.$data.route.loading = true;
      try {
        this.$data.extension = this.$props._extension;
        if (this.$data.extension) {
          this.$data.route.extension = this.$data.extension.id;
        }
        this.$session.user.account = await Helpers.get_account_information(this.$session);
        if (this.$props._route) {
          this.$data.route.item = this.$props._route;
        } else if (this.$props._id) {
          this.$data.route.item = await this.$data.route.get_item(this.$props.id);
        } else {
          this.$data.route.item = {'rules': [Helpers.empty_route_rule()]};
        }
        this.$data.original_route = JSON.parse(JSON.stringify(this.$data.route.item));
        this.$data.route_type = Object.prototype.hasOwnProperty.call(this.$data.route.item, 'name') ? 'preset' : 'regular_route';
      } catch (err) {
        Helpers.validation_error.bind(this)(err);
      }
      this.$data.route.loading = false;
    },
    methods: {
      on_route_configuration_change(data) {
        this.$data.route.item = data;
      },
      presets_selector_changed(data) {
        if (data.id === 'create') {
          this.$data.show_conf = true;
          this.$data.route.item = { name: Route.generic_preset_name(), rules: [Helpers.empty_route_rule()] };
        } else {
          this.$data.route.item = data;
          this.$data.show_conf = false;
        }
      },
      on_route_type_change(data) {
        this.$data.show_conf = data !== 'preset';
        if (data === 'regular_route') {
          delete this.$data.route.item.id;
          delete this.$data.route.item.name;
        } else {
          this.$data.may_submit = false;
        }
      },
      async on_save() {
        this.$data.same_filters = Route.has_the_same_filters(this.$data.route.item);
        if (!this.$data.same_filters) await this.save();
      },
      async save() {
        this.$data.route.loading = true;
        this.$data.same_filters = null;
        try {
          if (this.$props.presave_cb) await this.$props.presave_cb();
          // if its not a preset and has id, it exists, we can update it
          // if regular route and has id we can update it
          // no id, we create it
          if (this.$data.route_type !== 'preset' && this.$data.route.item.id) {
            this.$data.route.item = await this.$data.route.update_route(this.$data.route.item);
          } else if (!this.$data.route.item.id) {
            if (this.$data.extension) this.$data.route.item.extension = {id: this.extension.id};
            this.$data.route.item = await this.$data.route.create_route(this.$data.route.item);
            this.$data.route.successfulCreation();
          }

          this.$emit('updated', this.$data.route.item);
        } catch (err) {
          this.$data.route.validation_error(err);
        }
        this.$data.route.loading = false;
        return true;
      },
      on_invalid_form(rule_index) {
        if (rule_index === 0) this.$data.route.item.rules.push(Helpers.empty_route_rule());
      },
    },
  };
</script>
