import Resource from './Resource';
import setup from '../libs/resources-setups/subaccounts';

export default class Subaccount extends Resource {
    constructor(session, emitter, changeRoute) {
        super(session, emitter, '/subaccounts', changeRoute);
        this.setup = setup;
        const empty_contact = {
            name: null,
            company: null,
            address: {
                line_1: null,
                line_2: null,
                city: null,
                province: null,
                postal_code: null,
                country: null,
            },
            phone: null,
            fax: null,
            primary_email: null,
            alternate_email: null,
        };
        this.item = {
            username: null,
            password: null,
            // billing_day: null,
            // agent_id: null,
            contact: empty_contact,
            billing_contact: empty_contact,
        };
        this.filters = {
            voip_id: null,
            name: null,
            username: null,
            phone_number: null,
            email: null,
        };
    }

    async loadItems(params) {
        this.loading = true;
        try {
            const items = await this.session.get_list(
                this.uri, params.limit, params.offset,
            );
            this.items = items.items;
            this.hideFiltersIfNeeded(items.items);
            this.emitter('itemsLoaded', JSON.parse(JSON.stringify(items)));
        } catch (err) {
            this.validation_error(err);
        }
        this.loading = false;
    }

    async create(subaccount) {
        this.loading = true;
        try {
            await this.session.create_item(this.baseUri, subaccount);
            this.item = null;
            this.successfulCreation('subaccounts.index');
        } catch (err) {
            this.validation_error(err);
        }
        this.loading = false;
    }

    apply_filters() {
        this.loading = true;
        try {
            let uri = `${this.baseUri}?mode=full`;
            if (this.filters.voip_id) uri = `${uri}&filters[id]=${this.filters.voip_id}`;
            if (this.filters.name) uri = `${uri}&filters[name]=contains:${this.filters.name}`;
            if (this.filters.email) uri = `${uri}&filters[email]=contains:${this.filters.email}`;
            if (this.filters.phone_number) uri = `${uri}&filters[phone]=contains:${this.filters.phone_number.replace(/[^0-9+]+/g, '')}`;
            if (this.filters.username) uri = `${uri}&filters[username]=contains:${this.filters.username}`;
            this.uri = uri;
        } catch (err) {
            this.validation_error(err);
        }
        this.filters_applied = true;
        this.loading = false;
        return true;
    }
}
