var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"queues"},[_c('Submenu',{key:`${_vm.l.detect()}${_vm.$route.name}`,attrs:{"routes":{
      'queues.index': {
        title: _vm.l.t('app.queues', 'Queues'),
        subroutes: ['queues.show']
        },
      'queues.create': {
        title: _vm.l.t('queues.add-queue', 'Add queue')
        },
    }}}),(_vm.$route.name === 'queues.index')?_c('QueuesFragment',{attrs:{"data-test-id":"queues-index"},on:{"deletingStarted":function($event){_vm.deleting_in_progress = true},"deletingEnded":function($event){_vm.deleting_in_progress = false}}}):_vm._e(),(_vm.$route.name === 'queues.create')?_c('QueuesCreateFragment',{attrs:{"data-test-id":"queues-create"}}):_vm._e(),(_vm.$route.name === 'queues.show')?_c('QueuesShowFragment',{attrs:{"data-test-id":"queues-show"}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }