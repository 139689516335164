<template>
  <w-loader v-if="route.loading"/>
  <div v-else class="create-page" data-test-id="routes-create-page">
      <w-alert
        v-if="route.alert"
        :message="route.alert.message"
        :level="route.alert.level"
        :closable="route.enable_close_alert"
        @closed="route.alert = null"
        class="mb-6"
      />
      <w-form
        v-if="!route.alert || (route.alert && route.alert.level !== 'success')"
        @changed="may_submit = true"
        @submit.prevent="on_submit"
        @invalid_form="on_invalid_form"
        :validation="[form_validation.all_calls_required(route.item)]"
        data-discard="true"
      >
        <RouteConfiguration
          :_route="route.item"
          @changed="route.item=$event"
        />
        <div class="d-flex justify-center my-12">
          <CancelChanges @cancel="$emit('cancel')" class="mr-5"/>
          <w-btn :disabled="!may_submit" type="submit" color="primary" data-test-id="route-submit-btn">{{ l.t('app.save', 'Save') }}</w-btn>
        </div>
      </w-form>
      <SameFiltersModal
        v-if="same_filters"
        :value="true"
        @input="same_filters = null"
        :filter="same_filters"
        @confirmed="create_route"
        :key="`same-filters-${same_filters}`"
      />
  </div>
</template>

<script>
  import l from '../../../libs/lang';
  import Helpers from '../../../libs/helpers';
  import Route from '../../../models/Route';
  import FormValidation from '../../../libs/validation/form-rules';
  import CancelChanges from '../../elements/CancelChanges.vue';
  import RouteConfiguration from '../../elements/routes/RouteConfiguration.vue';
  import SameFiltersModal from '../../elements/routes/SameFiltersModal.vue';

  export default {
    components: {
      CancelChanges,
      SameFiltersModal,
      RouteConfiguration,
    },
    data() {
      return {
        l,
        route: new Route(this.$session, Helpers.emitter(this), Helpers.changeRoute(this)),
        may_submit: false,
        form_validation: FormValidation,
        same_filters: false,
      };
    },
    methods: {
      async create_route() {
        this.$data.same_filters = null;
        await this.$data.route.create();
      },
      async on_submit() {
        this.$data.same_filters = Route.has_the_same_filters(this.$data.route.item);
        if (!this.$data.same_filters) await this.$data.route.create();
      },
      on_invalid_form(rule_index) {
        if (rule_index === 0) this.$data.route.item.rules.push(Route.empty_route_rule());
      },
    }
  };
</script>
