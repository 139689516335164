<template>
<div>
  <IndexPage
    :resource="device"
    class="list-page devices"
    data-test-id="devices-list-page"
  >
    <template v-slot:filters>
      <FormInput :label="l.t('app.name', 'Name')">
        <w-text-field v-model="device.filters.name" hide-details='auto' data-test-id="devices-filters-name-input"/>
      </FormInput>
      <FormInput :label="l.t('app.type', 'Type')">
        <w-select
          v-model="device.filters.type"
          :items="[
            {text: l.t('devices-all-types', 'All types'), value: null},
            {text: l.t('devices.generic', 'Generic'), value: 'generic'},
            {text: l.t('devices.softphone', 'Softphone'), value: 'softphone'},
            {text: l.t('devices.iphone', 'iPhone'), value: 'iphone'},
            {text: l.t('devices.communicator', 'Communicator'), value: 'communicator'},
            {text: l.t('devices.other', 'Other'), value: 'other'},
          ]"
          hide-details="auto"
          data-test-id="devices-filters-type-seelct"
        />
      </FormInput>
      <FormInput :label="l.t('devices.sip-username', 'SIP username')">
        <w-text-field
          v-model="device.filters.sip_username"
          type="number"
          hide-details='auto'
          data-test-id="devices-filters-sip-input"
        />
      </FormInput>
      <FormInput :label="l.t('app.extension-nickname', 'Extension nickname')">
        <w-text-field
          v-model="device.filters.extension_name"
          hide-details='auto'
          data-test-id="devices-filters-ext-nick-input"
        />
      </FormInput>
      <FormInput :label="l.t('app.extension-number', 'Extension number')">
        <w-text-field
           type="number"
           v-model="device.filters.extension_number"
           hide-details='auto'
           data-test-id="devices-filters-ext-num-input"
        />
      </FormInput>
    </template>
    <template v-slot:list>
      <v-simple-table v-if="device.items && device.items.length" class="list-table without-hover-effect nowrap">
        <thead data-test-id="table-head">
          <tr data-test-id="head-row">
            <th class="select-table-item" data-test-id="checkbox"><br/></th>
            <th data-test-id="device">{{ l.t('app.device', 'Device') }}</th>
            <th data-test-id="name">{{ l.t('devices.device-name', 'Device name') }}</th>
            <th data-test-id="ext-num">{{ l.t('app.ext-number', 'Ext number') }}</th>
            <th data-test-id="ext-nick">{{ l.t('app.ext-user-nickname', 'Ext/User nickname') }}</th>
            <th data-test-id="mac">{{ l.t('app.mac-address', 'MAC address') }}</th>
            <th data-test-id="serial">{{ l.t('app.serial-number', 'Serial number') }}</th>
            <th data-test-id="type">{{ l.t('app.type', 'Type') }}</th>
            <th class="minimal-width" data-test-id="more-options"><br/></th>
          </tr>
        </thead>
        <tbody data-test-id="table-body">
          <tr
          v-for="(item, i) in device.items"
          :key="`${item.id}-${parseInt(item.selected)}`"
          :class="{
            'link': item.type === 'generic', 'not-a-link': item.type !== 'generic',
            'selected-row': item.selected,
          }"
          :data-test-id="item.id"
          >
            <td v-if="device.deleting_item === item.id" colspan="9" data-test-id="loader">
              <w-loader size="small"/>
            </td>
            <template v-else>
              <td class="select-table-item" data-test-id="checkbox">
                <w-checkbox
                  v-model="item.selected"
                  @click="checked($event, item)"
                  hide-details="auto"
                  :disabled="!device.constructor.device_is_deletable(item, nxt_user_extensions)"
                  :key="`selected-${item.id}-${item.selected}`"
                  :data-test-id="`devices-item-${i}-checkbox`"
                />
              </td>
              <td data-test-id="device">{{device_name(item)}}</td>
              <router-link
                v-if="item.type === 'generic' && allow_show_route"
                tag="td"
                :to="{name: 'devices.show', params: { 'id': item.id, '_device': item }}"
                class="list-link"
                data-test-id="link-name"
              >
                {{ item.name || '–' }}
              </router-link>
              <td v-else data-test-id="name">{{ item.name || '–' }}</td>
              <td data-test-id="lines">
                <span v-if="!item.lines.length">&mdash;</span>
                <span v-else>{{ item.lines[0].extension ? item.lines[0].extension.extension : '–' }}</span>
              </td>
              <td data-test-id="ext">
                <span v-if="!item.lines.length">&mdash;</span>
                <span v-else>{{ item.lines[0].extension ? item.lines[0].extension.name : '–' }}</span>
              </td>
              <td data-test-id="mac">{{item.mac_address || '&mdash;'}}</td>
              <td data-test-id="serial">{{item.serial_number || '&mdash;'}}</td>
              <td>{{ item.type }}</td>
              <td data-test-id="more-options">
                <MoreOptions :options="more_options(item)" />
              </td>
            </template>
          </tr>
        </tbody>
        </v-simple-table>
        <DefaultModal
          v-if="opened_device"
          :value="true"
          @close="opened_device = null"
          :width="500"
          data-test-id="modal-with-trigger"
        >
          <template v-slot:title>
            {{sip_can_be_visible(opened_device.code, opened_device.type) ? l.t('devices.devide-details-sip', 'Device details/SIP') : l.t('devices.devide-details', 'Device details')}}
          </template>
          <VerticalTable
            :items="details_and_sip(opened_device)"
          >
        </VerticalTable>
        <template v-slot:buttons>
          <w-btn color="secondary" @click="opened_device = null">{{l.t('app.cancel', 'Cancel')}}</w-btn>
          <w-btn text>
            <CopyToClipboard
                :copy="text_to_be_copied(opened_device)"
                :data-test-id="`devices-item-${opened_device.id}-copy-to-clipboard`"
              />
          </w-btn>
        </template>
      </DefaultModal>
    </template>
  </IndexPage>
  <AddressModal
      v-if="edit_address"
      :value="true"
      @input="on_address_modal_input"
      :validation="true"
      :confirm_btn_text="add_or_edit_address(edit_address)"
      @address_input="on_address_input"
      @confirmed="on_address_confirmation"
			@validation_failed="(v) => address_errors = v"
      @sends_value="address_errors = null"
      :prefill_with_cache="false"
      :_address="edit_address.address"
      :key="`edit-address-modal-${!!edit_address}`"
      >
      <template v-slot:title>
        {{ add_or_edit_address(edit_address) }}
        <div class="validation mt-3 mb-n1" v-if="address_errors">
        <ValidationError v-for="error of Object.values(address_errors)">
          {{ error }}
        </ValidationError>
      </div>
    </template>
  </AddressModal>
  <ConfirmDeletion
      :value="!!deleting_item_id"
      @closed="deleting_item_id = null"
      @confirmed="device.delete_item(deleting_item_id)"
    />
  </div>
</template>

<script>
  import l from '../../../libs/lang';
  import Device from '../../../models/Device';
  import Helpers from '../../../libs/helpers';
  import IndexPage from '../../elements/IndexPage.vue';
  import MoreOptions from '../../elements/MoreOptions.vue';
  import FormInput from '../../elements/form/FormInput.vue';
  import VerticalTable from '../../elements/VerticalTable.vue';
  import AddressModal from '../../elements/modal/AddressModal.vue';
  import CopyToClipboard from '../../elements/CopyToClipboard.vue';
  import DefaultModal from '../../elements/modal/DefaultModal.vue';
  import ConfirmDeletion from '../../elements/modal/ConfirmDeletion.vue';
  import ValidationError from '../../elements/form/FieldValidationError.vue';

  export default {
    components: {
      IndexPage,
      FormInput,
      MoreOptions,
      DefaultModal,
      AddressModal,
      VerticalTable,
      ConfirmDeletion,
      ValidationError,
      CopyToClipboard,
    },
    data() {
      return {
        l,
        device: new Device(this.$session, Helpers.emitter(this), Helpers.changeRoute(this)),
        allow_show_route: false,
        opened_device: null,
        deleting_item_id: null,
        codes_that_can_see_sip: [17349, 17390, 17392, 17393, 17394, 17396, 17397],
        user_is_csr: false,
        nxt_user_extensions: null,
        edit_address: null,
        address_errors: null,
      };
    },
    async created() {
      this.$data.allow_show_route = this.$branding.data.routes.includes('devices.show');
      this.$data.user_is_csr = await Helpers.is_csr(this.$session);
      this.$data.nxt_user_extensions = await Helpers.nxt_user_extensions(this.$session);
      this.$global_emitter.$on('form_input_changed', () => this.$data.address_errors = null);
    },
    mounted() {
     this.$parent.$on('userRedirected', (to, from, next) => {
       this.$data.device.handleUserRedirect(to, from, next);
     });
    },
    methods: {
      device_name(item) {
        if (item.model) {
          return `${item.model.manufacturer} ${item.model.name}`;
        }
        if (item.type === 'generic') return l.t('device.generic-device', 'Generic device');

        return '—';
      },
      details_and_sip(item) {
        let items = [
          {text: l.t('app.ID', 'ID'), value: item.id},
        ];
        for (const line of item.lines) {
          items.push({
            text: `${l.t('app.line', 'Line')} ${line.line}`,
            value: `${l.t('app.extension', 'Extension')} ${line.extension.extension}`,
          });
        }
        if (this.sip_can_be_visible(item.code, item.type)) {
          items = items.concat([
            {text: l.t('devices.sip-proxy', 'SIP proxy'), value: item.sip_authentication.host},
            {text: l.t('app.port', 'Port'), value: item.sip_authentication.port},
            {text: l.t('app.username', 'Username'), value: item.sip_authentication.username},
            {text: l.t('app.password', 'Password'), value: item.sip_authentication.password},
          ]);
        }

        return items;
      },
      more_options(item) {
        const items = [
          {
            cb: () => this.$router.push({
              name: 'devices.show',
              params: {
                id: item.id,
                _queue: item,
              },
            }),
            title: l.t('app.edit', 'Edit')
          },
          {
            cb: () => this.$data.opened_device = item,
            title: this.sip_can_be_visible(item.code, item.type) ? l.t('devices.device-details-SIP', 'Device details/SIP') : l.t('devices.device-details', 'Device details'),
          },
          {
            cb: () => this.$data.edit_address = item,
            title: this.add_or_edit_address(item),
          },
        ];

        if (Device.device_is_deletable(item, this.$data.nxt_user_extensions)) {
          items.push({
            cb: () => this.$data.deleting_item_id = item.id,
            title: l.t('app.delete', 'Delete'),
          });
        }

        return items;
      },
      on_address_input(v) {
        this.$data.edit_address.address = v;
      },
      add_or_edit_address(item) {
        return item.address ? l.t('app.edit-address', 'Edit address') : l.t('app.add-address', 'Add address');
      },
      sip_can_be_visible(code, type) {
        return this.$data.user_is_csr || this.codes_that_can_see_sip.includes(code) || type === 'softphone';
      },
      checked(event, item) {
        this.$data.device.checked(event, item);
        this.$emit('forceUpdate');
      },
      text_to_be_copied(device) {
        const text = [];
        const details = this.details_and_sip(device);
        for (const detail of details) {
          text.push(`${detail.text}: ${detail.value}`);
        }
        return text.join('\n');
      },
      async on_address_confirmation(data) {
        await this.$data.device.update_address(this.edit_address.id, data);
        if (this.$data.device.alert === null) this.$data.edit_address = null;
        this.$forceUpdate();
      },
      on_address_modal_input(v) {
        if (!v) {
          setTimeout(() => this.$data.edit_address = null, 10);
        }
      },
    },
  };
</script>

<style lang="scss">
  @import '../../../assets/styles/scss/vars.scss';

  .devices {
    .copy-cell {
      white-space: nowrap;
    }
    .list-table {
      .active-row {
        td {
          border-bottom: 0 !important;
          height: 28px;
        }
        .partially-bordered-td:before {
          border-left:1px solid var(--v-secondary-lighten1) !important;
          padding-top: 17px !important;
          padding-bottom: 17px !important;
        }
        &:last-child {
          td {
            border-bottom: 3px solid black !important;
          }
        }
      }
      .first-active-row {
        .partially-bordered-td:before {
          padding-top: 7px !important;
        }
      }
      .last-active-row {
        .partially-bordered-td:before {
          padding-bottom: 7px !important;
        }
        td {
          border-bottom: $table-border !important;
        }
      }
    }
  }
</style>
