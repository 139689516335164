<template>
    <div class="d-flex">
        <w-btn
            @click="ask_for_name"
            color="secondary"
            v-bind="$attrs"
            v-on="$listeners"
        >
            {{ l.t("routes.save-as-new-preset", "Save as new preset") }}
        </w-btn>
        <div class="buttons-vertical-divider mx-5"></div>
        <DefaultModal
            v-model="show_modal"
            :with_x="true"
            @close="show_modal = false"
            width="350"
            :key="`chr-save-as-preset-${show_modal}`"
        >
            <div class="w-subtitle-1">{{l.t('app.create-a-preset', 'Create a preset')}}</div>
            <w-loader v-if="loading" class="my-5" size="small"/>
            <w-alert v-if="route.alert" class="mt-5" :message="route.alert.message" :level="route.alert.level" />
            <div v-if="!loading && show_fields" class="mt-4 w-body-2">
                {{l.t('route.name-the-preset-youre-creating', 'Name the preset you\'re creating.')}}.
            </div>
            <w-text-field v-if="preset_obj && !loading" v-model="preset_obj.name" :placeholder="l.t('routes.give-your-preset-a-name', 'Give your preset a name')" class="mt-4"/>
            <template v-if="!loading && show_fields" v-slot:buttons>
                <w-btn color="secondary" @click="show_modal = false">{{l.t('app.cancel', 'Cancel')}}</w-btn>
                <w-btn color="primary" @click="save_as_preset">{{l.t('app.create', 'Create')}}</w-btn>
            </template>
        </DefaultModal>
    </div>
</template>
<script>
    import l from '../../../libs/lang';
    import Route from '../../../models/Route';
    import Helpers from '../../../libs/helpers';
    import DefaultModal from '../modal/DefaultModal.vue';

    export default {
        props: {
            get_route: {
                type: Function,
                required: true,
            },
        },
        components: {
            DefaultModal,
        },
        data() {
            return {
                l,
                preset_obj: null,
                show_modal: false,
                loading: false,
                route: new Route(this.$session, Helpers.emitter(this)),
                show_fields: true,
            };
        },
        methods: {
            async save_as_preset() {
                this.$data.loading = true;
                try {
                    await this.$data.route.create_route(this.$data.preset_obj);
                    this.$data.route.successfulCreation();
                    this.$data.preset_obj = null;
                } catch (err) {
                    this.$data.route.validation_error(err);
                }
                this.$data.route.hide_alert(3);
                this.$data.show_fields = false;
                this.$data.loading = false;
                setTimeout(() => this.$data.show_modal = false, 3000);
            },
            ask_for_name() {
                const data = JSON.parse(JSON.stringify(this.$data.route.item));
                this.$data.route.item = this.get_route();
                data.name = Route.generic_preset_name();
                this.$data.preset_obj = data;
                this.$data.show_modal = true;
            },
        },
        watch: {
            show_modal(v) {
                if (!v) {
                    this.$data.preset_obj = null;
                } else {
                    this.$data.show_fields = true;
                }
            },
        },
    };
</script>
