<template>
    <div class="calls-reports">
        <PageTitle v-if="call.category && call.category_conf" class="do-not-print">
          {{ call.category_conf.title }}
        </PageTitle>
        <w-loader v-if="call.loading"/>
        <template v-else>
            <CallReportsFilters :call="call" data-test-id="call-reports-filter"/>
            <w-alert
              v-if="call.alert"
              :message="call.alert.message"
              :level="call.alert.level"
              :closable="call.enable_close_alert"
              @closed="call.alert = null"
              class="mb-6"
            />
            <div v-else-if="(call.items)" id="call-reports-container">
                <Summary v-if="call.category === 'summary'" :call="call" data-test-id="call-reports-summary"/>
                <Types v-else-if="call.category === 'types'" :call="call" :color="primary_color" data-test-id="call-reports-types"/>
                <Total v-else-if="call.category === 'total'" :call="call" :color="primary_color" data-test-id="call-reports-total"/>
                <Traffic v-else-if="call.category === 'traffic'" :call="call" :color="primary_color" data-test-id="call-reports-traffic"/>
                <PerExtension v-else-if="call.category === 'per-extension'" :call="call" :color="primary_color" data-test-id="call-reports-ext"/>
                <PerNumber v-else-if="call.category === 'per-number'" :call="call" :color="primary_color" data-test-id="call-reports-number"/>
                <GeoDistribution v-else-if="call.category === 'geo-distribution'" :call="call" :color="primary_color" data-test-id="call-reports-geo"/>
            </div>
            <NothingFound
              v-else
              :alert="call.alert"
              :everythingDeleted="false"
              :filters_applied="false"
              :disable_add_new="true"
              data-test-id="call-reports-nothing-found"
            >
            </NothingFound>
        </template>
    </div>
</template>

<script>
  import l from '../../../../libs/lang';
  import Helpers from '../../../../libs/helpers';
  import CallReport from '../../../../models/CallReport';
  import Types from './types.vue';
  import Total from './total.vue';
  import Summary from './summary.vue';
  import Traffic from './traffic.vue';
  import GeoDistribution from './geo.vue';
  import PerNumber from './per-number.vue';
  import PerExtension from './per-extension.vue';
  import NothingFound from '../../../elements/NothingFound.vue';
  import PageTitle from '../../../elements/PageTitle.vue';
  import CallReportsFilters from '../../../elements/CallReportsFilters.vue';

  export default {
    components: {
      Types,
      Total,
      Traffic,
      Summary,
      PerNumber,
      PageTitle,
      PerExtension,
      NothingFound,
      GeoDistribution,
      CallReportsFilters,
    },
    data() {
      return {
        l,
        call: new CallReport(
          this.$session,
          Helpers.emitter(this),
          Helpers.changeRoute(this),
          this.$route.params.category,
          () => this.$forceUpdate()
        ),
      };
    },
    async created() {
      await this.$data.call.loadItems();
      this.$forceUpdate();
    },
    computed: {
      primary_color() {
        if (!this.$vuetify.theme.defaults.light.primary) return 'e5176e';
        if (typeof this.$vuetify.theme.defaults.light.primary === 'string') return this.$vuetify.theme.defaults.light.primary;
        return this.$vuetify.theme.defaults.light.primary.base;
      }
    },
    filters: {
      format_date(val) {
        return Helpers.format_time(val)
          .replace(',', ' ');
      },
    },
  };
</script>
<style>
  #call-reports-container {
  }
</style>
