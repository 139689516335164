<template>
    <div data-test-id="extension-selector-container">
        <w-alert v-if="alert" :message="alert.message" :level="alert.level"/>
        <w-loader v-if="loading" :message="false" :no_margins="true"/>
        <template v-else>
          <APIAutocomplete
            v-model="extension"
            v-bind="$attrs"
            v-on="$listeners"
            :before_list="!select_if_null_v && !placeholder ? [{ value: null, text: null_text || l.t('extensions.all-extensions', 'All extensions') }] : []"
            :select_if_one="select_if_one_v"
            mode="extensions"
            :return_object="true"
            :placeholder="placeholder"
            :no_update="no_update"
            :key="!!disabled"
            :disabled="!!disabled"
            class="extension-selector"
            :additional_options="additional_options"
            :filtered_values="filtered_values"
            data-test-id="extension-selector"
          />
          <DefaultModal
              v-model="show_notification_modal"
              @close="show_notification_modal = false"
              max-width="500"
              data-test-id="extension-selector-notification-modal"
            >
            <w-form @submit.prevent="save">
              <div v-if="extension">
                  <div class="mb-3">
                    <div class="font-weight-bold">
                      {{ l.t("extension.notification-label-sms", "Phone number for SMS forwarding and Voicemail notifications") }}
                    </div>
                    <Tel
                       :value="sms"
                       @changed="sms = $event.clean_number"
                       :required="!emails.length"
                       class="w-100"
                       data-test-id="extension-selector-notification-tel-input"
                    />
                  </div>
                  <div :key="emails.length" class="mt-5">
                    <div class="font-weight-bold">
                      {{ l.t("extension.notification-label", "Emails for SMS, voicemail and fax notifications") }}
                    </div>
                    <Email
                      v-for="(email, i) in emails"
                      v-model="emails[i]"
                      :rules="[$validation.required()]"
                      :key="i"
                      :closable="true"
                      @closed="$delete(emails, i)"
                      :class="{'mb-2': i < emails.length -1}"
                      class="w-100"
                      data-test-id="extension-selector-notification-email-input"
                    />
                    <w-btn
                      @click="emails.push('')"
                      class="mt-3 pl-0"
                      color="primary"
                      text
                      changes-form
                      min-width="100px"
                      data-test-id="extension-selector-notification-add-email"
                    >
                        <v-icon small>$vuetify.icons.add</v-icon>{{ l.t("app.email-add", "Add email address") }}
                    </w-btn>
                  </div>
              </div>
              <div class="default-modal-buttons">
                <w-btn class="mr-5" color="secondary" @click="show_notification_modal = false">{{l.t('app.cancel', 'Cancel')}}</w-btn>
                <w-btn color="primary" type="submit">{{l.t('app.save-notifications-settings', 'Save notifications settings')}}</w-btn>
              </div>
            </w-form>
            </DefaultModal>
            <DefaultModal
                v-model="show_call_flow_modal"
                :with_x="true"
                @close="show_call_flow_modal = false"
                width="1000"
                :key="modal_key"
              >
                <RouteShow
                  v-if="extension && extension.route && extension.route.id"
                  :id="extension.route.id"
                  :disable_switcher="true"
                  @updated="update_screen"
                  :key="extension.id"
                  data-test-id="extension-selector-show-route-modal"
                />
                <RouteCreate  v-else
                :extension="extension"
                @created="created_route"
                @updated="on_route_update"
                />
            </DefaultModal>
        </template>
    </div>
</template>

<script>
  import l from '../../libs/lang';
  import Cachier from '../../libs/Cachier';
  import Extension from '../../models/Extension';
  import Autocomplete from '../../models/Autocomplete';
  import Tel from '../elements/Tel.vue';
  import Helpers from '../../libs/helpers';
  import Email from '../elements/Email.vue';
  import AddToListButton from '../elements/AddToListButton.vue';
  import DefaultModal from '../elements/modal/DefaultModal.vue';
  import APIAutocomplete from '../elements/form/APIAutocomplete.vue';

  export default {
    name: 'ExtensionSelector',
    props: [
      'value',
      'select_if_one',
      'options_list',
      'disabled',
      'placeholder',
      'no_update',
      'full_width',
      'null_text',
      'filtered_values',
    ],
    components: {
      Email,
      Tel,
      DefaultModal,
      AddToListButton,
      APIAutocomplete,
      RouteShow: () => import('../screens/routes/show.vue'),
      RouteCreate: () => import('../screens/routes/create.vue'),
    },
  data() {
      const select_if_one = !!this.$props.select_if_one;
      const loading = select_if_one && !this.$props.value;
      return {
        l,
        select_if_one_v: select_if_one,
        select_if_null_v: select_if_one,
        alert: null,
        loading,
        modal_key: Date.now(),
        extension: this.$props.value ? this.$props.value : null,
        extModel: new Extension(this.$session, Helpers.emitter(this), Helpers.changeRoute(this)),
        emails:
          this.$props.value
          && this.$props.value['voicemail']
          && this.$props.value['voicemail']['notifications']
          && this.$props.value['voicemail']['notifications']['emails']
            ? this.$props.value['voicemail']['notifications']['emails']
            : [],
        sms:
          this.$props.value
          && this.$props.value['voicemail']
          && this.$props.value['voicemail']['notifications']
            ? this.$props.value['voicemail']['notifications']['sms']
            : '',
        auto_selected_extension: false,
        show_notifications: false,
        cachier: new Cachier(this.$session.user.id),
        additional_options: null,
        show_notification_modal: false,
        show_call_flow_modal: false,
      };
    },
    async created() {
      if (this.$data.loading) {
        const cache_key = 'default_extension';
        let extension_id;
        const default_extension = this.$data.cachier.getItem(cache_key);
        if (default_extension !== null) {
          extension_id = default_extension;
        } else if (this.$session.user.account && this.$session.user.account.default_voip_phone_id) {
          extension_id = this.$session.user.account.default_voip_phone_id;
        } else {
          const first_extension = await this.$session.get_list('/extensions', 1);
          if (first_extension.items.length) {
            extension_id = first_extension.items[0].id;
          } else {
            const rd = () => `${Math.floor(Math.random() * 10)}`;
            const data = {
              name: 'Default extension',
              voicemail: {
                password: `${rd()}${rd()}${rd()}${rd()}${rd()}${rd()}`,
              },
            };
            const created_extension = await this.$session.create_item('/extensions', data);
            extension_id = created_extension.id;
          }
        }
        extension_id = typeof extension_id !== 'number' ? Number.parseInt(extension_id) : extension_id;
        if (extension_id) {
          this.$data.extension = {
            id: extension_id,
          };
        }
        this.cachier.setItem(cache_key, extension_id || 0);
      }
      if (
        typeof this.$data.extension === 'object'
        && this.$data.extension
        && !Object.prototype.hasOwnProperty.call(this.$data.extension, 'voicemail')
        && this.$data.extension.id
      ) { // brief representation for devices lines ex
        this.$data.loading = true;
        try {
          this.$data.extension = await this.$session.get_item(`/extensions/${this.$data.extension.id}`);
        } catch (err) {
          console.log('Error while loading extension', err);
        }
      }
      this.add_options();
      this.$data.loading = false;
    },
    watch: {
      show_call_flow_modal() {
        this.$data.modal_key = Date.now();
      },
      extension(value) {
        this.$data.extModel.item = value;
        this.$data.emails = value
          && value['voicemail']
          && value['voicemail']['notifications']
          && value['voicemail']['notifications']['emails']
            ? value['voicemail']['notifications']['emails']
            : [];
        this.$data.sms = value
          && value['voicemail']
          && value['voicemail']['notifications']
          && value['voicemail']['notifications']['sms']
            ? value['voicemail']['notifications']['sms']
            : '';
        if (value && this.$data.select_if_null_v) {
          const cache_key = 'default_extension';
          this.cachier.setItem(cache_key, (value['id'] ? value['id'] : 0).toString());
        }
        this.add_options();
        this.$emit('changed', {
          id: value ? value['id'] : null,
          value: value || {},
        });
      },
    },
    methods: {
      created_route(val) {
        const params = {name: val.name, id: val.id};
        this.on_route_update(params);
      },
      async on_route_update(data) {
        this.$data.extension.loading = true;
        try {
          if (!this.$data.extension.route || (this.$data.extension.route && this.$data.extension.route.id !== data.id)) {
            this.$data.extension.route = await this.$data.extModel.update_route(data);
          }
          this.$data.extModel.successfulUpdate();
        } catch (err) {
          this.$data.extModel.validation_error(err);
        }
        this.$data.extension.loading = false;
      },
      update_screen(data) {
        if (data) {
          this.extension.route = data;
          const ac = new Autocomplete(
            this.$props.mode,
            this.$session,
            this.$props.extension,
            {
                value: this.$data.extension.route.id,
            },
            {
                emit: Helpers.emitter(this),
            },
          );
          ac.mode.model.updateCache(data, ac.mode_name);
        }
        setTimeout(() => {
          this.$data.show_call_flow_modal = false;
        }, 1000);
      },
      add_options() {
        if (this.$data.extension && this.$props.options_list) {
          this.$data.additional_options = [];
          if (this.$props.options_list.includes('notifications')) {
            this.$data.additional_options.push({
              title: l.t('app.notifications', 'Notifications'),
              icons: '$vuetify.icons.alert',
              cb: () => this.$data.show_notification_modal = true,
            });
          }
          if (this.$data.extension.id && this.$props.options_list && this.$props.options_list.includes('call-flow-link')) {
            this.$data.additional_options.push({
              title: l.t('app.show-call-flow', 'Show call flow'),
              icons: '$vuetify.icons.gear',
              cb: () => this.$data.show_call_flow_modal = true,
            });
          }
        }
      },
      async save(e) {
        if (e) e.preventDefault();
        const cache_key = 'extensions';
        this.cachier.removeItem(cache_key);
        this.$data.loading = true;
        try {
          if (!this.$data.extension['voicemail']) {
            this.$data.extension['voicemail'] = {};
          }
          if (!this.$data.extension['voicemail']['notifications']) {
            this.$data.extension['voicemail']['notifications'] = {};
          }
          this.$data.extension['voicemail']['notifications'][
            'emails'
            ] = this.$data.emails;
          this.$data.extension['voicemail']['notifications']['sms'] = this.$data.sms || null;
          await this.$session.replace_item(`/extensions/${this.$data.extension['id']}`, this.$data.extension);
          this.$data.show_notification_modal = false;
          } catch (err) {
            Helpers.show_generic_error.bind(this);
          }
          this.$data.loading = false;
      },
    },
  };
</script>
