<template>
	<div class="do-not-print app-header">
		<header
		 class="d-flex do-not-print"
		 id="large-header"
		 data-test-id="header"
		>
			<slot>
				<AccountBox
					v-if="$session.user && $session.user.account && $vuetify.breakpoint.mdAndUp"
					:nudge_top="-63"
					v-bind:key="$session.user.account && $session.user.account.id"
					@signOut="$emit('sign_out')"
					@language_changed="$emit('language_changed', $event)"
					:key="`header-account-box-${!!$session.user.account}`"
					data-test-id="sidebar-account-box"
				/>
			</slot>
			<CsrWarning/>
		</header>
		<div class="header-spacer"></div>
   </div>
</template>

<script>
	import l from '../../libs/lang';
	import AccountBox from '../fragments/AccountBox.vue';
	import CsrWarning from './CsrWarning.vue';

	export default {
	    props: {
			is_large_page: {
				type: Boolean,
				default: false,
			},
	    },
	    components: { AccountBox, CsrWarning },
	    data() {
	      return {
			l,
	      };
	    },
  };
</script>
