<template>
    <div id="pdf-export" class="mt-2">
        <div>
            <div class="d-flex justify-center">
              <div id="traffic-chart" class="chart-container" data-test-id="traffic-chart"></div>
            </div>
            <ChartLegend :items="legend"/>
        </div>
        <v-simple-table class="mt-3" v-if="filter" data-test-id="traffic-table">
            <thead data-test-id="table-head">
              <tr data-test-id="head-row">
                  <th style="width: 100%" data-test-id="filter">
                      {{ translated_filter }}
                  </th>
                  <th data-test-id="incoming">{{ l.t('app.incoming', 'Incoming') }}</th>
                  <th data-test-id="outgoing">{{ l.t('app.outgoing', 'Outgoing') }}</th>
                  <th data-test-id="total">{{ l.t('reports.total-calls', 'Total calls') }}</th>
              </tr>
            </thead>
            <tbody data-test-id="table-body">
              <tr v-if="item.total" v-for="(item, i) in call.items" :data-test-id="`traffic-item-${i}-row`">
                  <td data-test-id="value">{{ call.category_conf.formatTime(item[filter]) }}</td>
                  <td data-test-id="incoming">{{ item.count_incoming }}</td>
                  <td data-test-id="outgoing">{{ item.count_outgoing }}</td>
                  <td data-test-id="total">{{ item.total }}</td>
              </tr>
            </tbody>
        </v-simple-table>
    </div>
</template>

<script>
  import { OrangeChartLines } from 'orange-charts';
  import { OrangeColor } from 'orange-colors';
  import ChartLegend from '../../../fragments/ChartLegend.vue';
  import l from '../../../../libs/lang';

  export default {
    props: ['call', 'color'],
    components: { ChartLegend },
    data() {
      const c = new OrangeColor(this.$props.color);
      const count_incoming = c.html_string;
      c.h = (c.h + 120) % 360;
      const count_outgoing = c.html_string;
      return {
        l,
        colors: {count_incoming, count_outgoing},
        filter: null,
      };
    },
    mounted() {
      this.$data.filter = this.$props.call.filters.group_by;
      const max_y_value = Math.max(
        ...this.$props.call.items.reduce((a, c) => {
          a.push(c.count_incoming);
          a.push(c.count_outgoing);
          return a;
        }, [])
      );
      const chart = new OrangeChartLines(this.$props.call.items);
      chart
        .assignSource('count_incoming', this.$data.colors['count_incoming'])
        .assignSource('count_outgoing', this.$data.colors['count_outgoing'])
        .axis('x', {
          lines: true,
          source: this.$props.call.filters.group_by,
          label: (v) => {
            const d = new Date(v * 1000);
            return d.toLocaleString(undefined, {
              month: 'short',
              day: 'numeric',
              hour: ['hour', 'minute'].includes(this.$props.call.filters.group_by) ? 'numeric' : undefined,
            });
          },
          dynamic: true,
        })
        .axis('y', {
          lines: true,
          label: (v) => {
            if (v * 1 === max_y_value) return l.t('x-calls', '{} calls', [v]);
            return v;
          },
          min: 0,
        })
        .render('traffic-chart', {
          width: 900,
          height: 384,
        });
    },
    methods: {
      upperFirstCase(val) {
        return (
          val.charAt(0)
            .toUpperCase()
          + val
            .slice(1)
            .split('_')
            .join(' ')
        );
      },
    },
    computed: {
      legend() {
        const obj = {};
        obj[l.t('app.incoming-calls', 'Incoming calls')] = this.$data.colors.count_incoming;
        obj[l.t('app.outgoing-calls', 'Outgoing calls')] = this.$data.colors.count_outgoing;
        return obj;
      },
      translated_filter() {
        if (!this.$data.filter) return '';
        if (this.$data.filter === 'hour') return l.t('reports.hour', 'Hour');
        if (this.$data.filter === 'date') return l.t('app.date', 'Date');
        if (this.$data.filter === 'week') return l.t('reports.week', 'Week');

        return this.$data.filter;
      }
    },
  };
</script>

<style lang="scss" scoped>
    #traffic-chart {
        font-size: 0.75rem;
    }
</style>
