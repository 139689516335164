<template>
	<div>
		<DefaultModal
			v-bind="$attrs"
			v-on="$listeners"
			@input="on_modal_input"
			max-width="550px"
		>
			<div v-if="$slots['title']" class="w-subtitle-1 info--text text--darken-1">
				<slot name="title"/>
			</div>

			<w-loader v-if="validating" size="small" :message="l.t('app.validating-address', 'Validating address')"/>
			<AddressForm v-else v-model="address" />
			<template v-if="!validating" v-slot:buttons>
				<w-btn
					@click="$emit('input', false)"
					color="secondary"
				>
					{{ l.t('app.close', 'Close') }}
				</w-btn>
				<w-btn
					color="primary"
					:disabled="disable_submit()"
					@click="send_value(true)"
				>
					{{ confirm_btn_text || l.t('app.save', 'Save') }}
				</w-btn>
			</template>
		</DefaultModal>
		<DefaultModal v-if="corrected_address" :value="true" max-width="550" persistent :with_x="false">
			<div class="address-update-alert">
				<v-icon size="24" color="warning darken-1">$vuetify.icons.infotip_stylized</v-icon>
				<div class="w-body-1 warning--text text--darken-1">
					{{l.t('app.we-have-updated-address-entered', 'We have updated the address entered. If correct, please use the suggested address to ensure accuracy.')}}
				</div>
			</div>
			<w-radio-group
				class="address-radio-selector"
				hide-details="auto"
				data-test-id="address-radio-selector"
				v-model="address_select"
			>
				<v-radio
					v-for="type of ['input', 'corrected_address']"
					color="secondary"
					type="radio"
					:value="type"
					:data-test-id="`user-${type}-address`"
					>
					<template v-slot:label>
						<div class="w-body-2">
							<span class="font-weight-bold">
								{{type === 'input' ? l.t('app.entered-address', 'Entered address') : l.t('app.suggested-address', 'Suggested address')}}
							</span>
							<div v-if="$data[type].line_1">{{format_address($data[type])}}</div>
						</div>
					</template>
				</v-radio>
			</w-radio-group>
			<template v-slot:buttons>
				<w-btn @click="on_address_select" color="primary" text>{{l.t('app.use-selected-address', 'Use selected address')}} <v-icon size="18">$vuetify.iocons.chevron_forward</v-icon></w-btn>
			</template>
		</DefaultModal>
	</div>
</template>
<script>
    import l from '../../../libs/lang';
    import Helpers from '../../../libs/helpers';
    import Cachier from '../../../libs/Cachier';
    import DefaultModal from './DefaultModal.vue';
    import AddressForm from '../form/AddressForm.vue';

    export default {
      props: {
        _address: {
            type: [Object],
        },
        validation: {
			type: Boolean,
			default: false,
		},
		prefill_with_cache: {
			type: Boolean,
			default: true,
		},
		confirm_btn_text: {
			type: String,
		},
      },
      components: {
        AddressForm,
        DefaultModal,
      },
      data() {
        const last_address_cache_key = 'last-address-used';
		const empty_address = {
			line_1: '',
			line_2: '',
			city: '',
			province: '',
			postal_code: '',
			country: 'US',
		};
		const cachier = new Cachier(this.$session.user.id);
		const last_address_used = cachier.getItem(last_address_cache_key);
		let address = this.$props._address;
		if (!address && this.$props.prefill_with_cache && last_address_used) {
			address = last_address_used;
		}
		if (!address) {
			address = JSON.parse(JSON.stringify(empty_address));
		}
        return {
            l,
            validating: false,
            empty_address,
			address,
			input: {},
			last_address_cache_key,
			cachier,
			corrected_address: null,
			address_select: 'corrected_address',
        };
      },
      async created() {
		if (this.$props._address && Object.keys(this.$props._address).filter((x) => x !== 'line_2').every((key) => this.$props._address[key])) {
			this.$data.input = this.$props._address;
		} else {
			this.$data.input = {};
		}
		if (Object.keys(this.$data.input).length) this.send_value(false, true);
		const user = await Helpers.get_account_information(this.$session, true);
		if (JSON.stringify(this.$data.address).toLowerCase() === JSON.stringify(this.$data.empty_address).toLowerCase()) {
           this.$data.address = user.contact.address;
        }
	},
      methods: {
		on_address_select() {
			this.$data.input = JSON.parse(JSON.stringify(this.$data[this.$data.address_select]));
			this.$data.corrected_address = null;
			this.$emit('address_input', this.$data.input);
			this.$emit('confirmed', this.$data.input);
			this.$emit('input', false);
			this.cachier.setItem(this.$data.last_address_cache_key, this.$data.input);
		},
        async send_value(form_confirmation = false, prevent_address_comparation = false) {
			this.$emit('sends_value');
			let input;
			let valid = true;
			if (Object.values(this.$data.address).every((x) => !x)) {
				input = null;
			} else {
				input = Object.assign(JSON.parse(JSON.stringify(this.$data.empty_address)), this.$data.address);
			}
			if (this.$props.validation && input.country === 'US') {
				this.$data.validating = true;
				const is_address_valid = await Helpers.validate_address(this.$session, input);
				if (is_address_valid.success) {
					this.$emit('address_input', input);
					this.cachier.setItem(this.$data.last_address_cache_key, input);
					this.$data.input = input;
					if (!prevent_address_comparation && is_address_valid.validated_address && JSON.stringify(is_address_valid.validated_address).toLowerCase() !== JSON.stringify(input).toLowerCase()) {
						this.$data.corrected_address = is_address_valid.validated_address;
					}
				} else {
					this.$emit('validation_failed', is_address_valid.message);
					valid = false;
				}
				this.$data.validating = false;
			} else {
				this.$emit('address_input', input);
				this.$data.input = input;
				this.cachier.setItem(this.$data.last_address_cache_key, input);
			}
			if (form_confirmation) {
				if (valid && !this.$data.corrected_address) {
					this.$emit('confirmed', this.$data.input);
					this.$emit('input', false);
				}
			}
		},
        disable_submit() {
			for (const prop of ['line_1', 'city', 'country', 'postal_code', 'province']) {
				if (!this.$data.address[prop]) return true;
			}
			return false;
		},
        on_modal_input(v) {
            const last_address_used = this.$data.cachier.getItem(this.$data.last_address_cache_key);
			if ( // first time opened
				this.$props.prefill_with_cache
				&& last_address_used
				&& !['line_1', 'city', 'postal_code', 'province'].find((x) => this.$data.address[x])
				&& !Object.keys(this.$data.input).length
			) {
				this.$data.address = last_address_used;
			} else {
				this.$data.address = {...this.$data.address, ...this.$data.input};
			}
			this.$emit('input', v);
        },
		format_address(address) {
			return Helpers.stringifyAddress(address);
		}
      },
	  watch: {
		corrected_address() {
			this.$data.address_select = 'corrected_address';
		}
	  }
    };
</script>

<style lang="scss">
.address-update-alert {
	display: flex !important;
	justify-content: start;
	padding: 0 10px;
	margin-bottom: 30px;
	.v-icon {
		margin-right: 6px;
	}
}
.address-radio-selector {
	min-width: 100%;
	.v-radio{
		align-items: start;
		border-radius: 10px;
		border: 1px solid var(--v-secondary-base);
		padding: 15px 15px 15px 20px;
		width: 46%;
		min-height: 110px !important;
	}
	.v-item--active {
		border-radius: 10px;
		border: 2px solid var(--v-text-primary, #364047);
	}
	.v-input--radio-group__input {
		display: flex;
		flex-direction: row !important;
		justify-content: space-between;
		width: 100%;
		.v-radio:nd-of-child(2) {
			text-align: right;
		}
	}
}
</style>
