<template>
    <w-loader v-if="media.loading"/>
    <div v-else class="create-page media-fragment-size mt-3" data-test-id="media-create-page">
        <w-alert
          v-if="media.alert"
          :message="media.alert.message"
          :level="media.alert.level"
          :closable="media.enable_close_alert"
          @closed="media.alert = null"
          class="mb-6"
        />
        <MediaFragment
          v-if="media.alert === null || (media.alert && media.alert.level !== 'success')"
          :_media="media.item"
          :force_type="force_type"
          @submitted="media.create($event)"
        />
    </div>
</template>

<script>
  import l from '../../../libs/lang';
  import Media from '../../../models/Media';
  import Helpers from '../../../libs/helpers';
  import MediaFragment from '../../fragments/Media.vue';

  export default {
    props: {
      force_type: {
        type: String,
        required: false,
        validator: (val) => ['greeting', 'hold_music'].includes(val),
      }
    },
    components: {
      MediaFragment,
    },
    data() {
      return {
        l,
        media: new Media(this.$session, null, Helpers.emitter(this), Helpers.changeRoute(this)),

      };
    },
  };
</script>
